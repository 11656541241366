<template>
  <div class="candidates p-5">
    <h3 class="intern-title mb-4 pb-2">
      <back-arrow />
      <span class="pagetitle pl-2">Analytics do Monitor</span>
    </h3>

    <div class="name-box">
      <div class="monitor-box">
        <img
          class="subtitle-icon"
          src="../../assets/img/userIcon.png"
          alt="img"
        />
        <span class="nome-monitor">{{ nome.nomeMonitor }}</span>
      </div>
      <div class="monitor-box">
        <img
          class="subtitle-icon"
          src="../../assets/img/emailIcon.png"
          alt="img"
        />
        <span class="nome-monitor">{{ email.emailMonitor }}</span>
      </div>
    </div>

    <div v-if="show" class="graphBox">
      <span class="graphTitle">Eficiência da medição</span>
      <LineChart :newGraphY="barY" :newGraphX="barX" />
      <span class="graphTitle">Avaliações do fiscal</span>
    </div>

    <div>
      <Table :fields="fields" :hasActions="true">
        <TableRow v-for="execucao in execucoes" :key="execucao + Math.random()">
          <TableCell
            v-for="field in fields"
            :key="field.key"
            :name="field.key"
            :style="field._style"
            :sort-direction="field.sortDirection"
          >
            <template v-slot:[field.key]>
              <span class="table-row-text">{{
                execucao[field.key] || "--"
              }}</span>
            </template>
            <template v-slot:bairro_nome>
              <div>
                {{ execucao.bairro_nome }}
              </div>
            </template>
            <template v-slot:zeladoria_nome>
              <div>
                {{ execucao.zeladoria_nome }}
              </div>
            </template>
            <template v-slot:ciclo_1>
              {{
                execucao.dados_ciclos[0]
                  .execucao_avaliacao_prefeitura_percentual_final
              }}
            </template>
            <template v-slot:ciclo_2>
              {{
                execucao.dados_ciclos[1]
                  .execucao_avaliacao_prefeitura_percentual_final
              }}
            </template>
            <template v-slot:ciclo_3>
              {{
                execucao.dados_ciclos[2]
                  .execucao_avaliacao_prefeitura_percentual_final
              }}
            </template>
            <template v-slot:ciclo_4>
              {{
                execucao.dados_ciclos[3]
                  .execucao_avaliacao_prefeitura_percentual_final
              }}
            </template>
            <template v-slot:ciclo_5>
              {{
                execucao.dados_ciclos[4]
                  .execucao_avaliacao_prefeitura_percentual_final
              }}
            </template>
          </TableCell>
        </TableRow>
        <template v-if="execucoes.length === 0" v-slot:no-results>
          <NoResults :message="$t('noResults')" />
        </template>
      </Table>
    </div>
    <Loading v-if="loadingData" />
  </div>
</template>

  <script>
import services from "../../services";
import { formatDate } from "../../utils/date";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Table from "../../components/table/Table.vue";
import LineChart from "../../components/LineChart.vue";
import NoResults from "../../components/table/NoResults.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BackArrow,
    Loading,
    Table,
    TableRow,
    TableCell,
    LineChart,
    NoResults,
  },
  computed: {
    ...mapGetters({
      nomeMonitor: "global/nomeMonitor",
    }),
  },
  data() {
    return {
      show: false,
      barY: [
        {
          label: "Média de execuções por dia",
          backgroundColor: "#003063",
          borderColor: "#003063",
          data: [],
        },
      ],
      barX: [],
      graph: [],
      execucoes: [],
      fields: [
        {
          key: "bairro_nome",
          label: "Bairro",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladoria_nome",
          label: "Zeladoria",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ciclo_1",
          label: "Ciclo 1",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ciclo_2",
          label: "Ciclo 2",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ciclo_3",
          label: "Ciclo 3",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ciclo_4",
          label: "Ciclo 4",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ciclo_5",
          label: "Ciclo 5",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
      ],
      loading: false,
      loadingData: false,
      formatDate,
      nome: null,
      email: null,
    };
  },
  mounted() {
    this.getDadosMonitor();
    this.getDadosExecucoes();
    this.setMonitor();
  },

  methods: {
    async setMonitor() {
      this.nome = this.$store.state.global.nomeMonitor;
      this.email = this.$store.state.global.emailMonitor;
    },
    async handleChangeTab(value) {
      this.currentTab = value;
      this.tabs.map((tab) => {
        if (tab.value === value) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
    },
    async getDadosMonitor() {
      const { data, errors } = await services.analytics.getMonitor({
        usuario_gestor_id: this.$route.params.id,
      });
      if (!errors) {
        this.graph = data;
        this.barX = this.graph.map((response) => {
          return (
            this.formatDate(response.execucao_avaliacao_inicio) +
            " a " +
            this.formatDate(response.execucao_avaliacao_fim)
          );
        });
        this.barY[0].data = this.graph.map((response) => {
          return response.avaliacao_media;
        });
        this.show = true;
      }
    },

    async getDadosExecucoes() {
      const { data, errors } = await services.analytics.getMonitorExecucoes({
        usuario_gestor_id: this.$route.params.id,
      });
      if (!errors) {
        this.execucoes = data;
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
.form-class {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lista {
  width: 80%;
}
.button {
  color: white;
  padding: 8px 24px;
  background: #003063;
  border-radius: 8px;
  margin: 0px 8px;
  cursor: pointer;
}
.grafico {
  height: 500px;
}
.position-relative {
  position: relative;
}
.selecionar-ano {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  width: 260px;
  height: 447px;
  border: 1px solid lightgray;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 10;
}
.flex-row {
  display: flex;
  flex-direction: column;
}
.coluna {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.duas-colunas {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.display-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filtrar {
  font-weight: bolder;
  color: white;
  padding: 5px 12px;
  background: #61b2e5;
  border: none;
  border-radius: 8px;
}
.limpar-filtro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 8px;
  width: 100%;
}
.nome-mes {
  color: #565e73;
  font-size: 14px;
}
.checkbox-mes {
  background: #c6c9d0;
  width: 15px;
  height: 15px;
  margin: 0px 8px;
}
fieldset {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.titulo-select {
  margin-bottom: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #565e73;
}
.selecionar-mes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  width: 260px;
  height: 447px;
  border: 1px solid lightgray;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 10;
}
label {
  margin: 0px;
  cursor: pointer;
}
.selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 8px 16px;
  width: 152px;
  height: 32px;
  background: #003063;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  margin: 0px 8px;
}
.display {
  display: none;
}
.titulo-tab {
  margin: 32px 0px;
}

.candidates {
  background-color: #fff;
  border-radius: 8px;
  padding-top: 40px !important;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}

.actions {
  cursor: pointer;
}

.tabs {
  display: flex;
  color: #838998;
}

.tab {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
  cursor: pointer;
  color: #838998;
  &:hover {
    background: #eee;
  }
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.expired {
  color: tomato;
}
.form {
  input {
    width: 170px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    color: #747474;
  }
  label {
    margin-right: 10px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.atividades {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 40px;
  border: 1px solid #dddee3;
  border-radius: 16px;
  margin: 16px 0px;
}
.atividade {
  display: flex;
  flex-direction: row;
  margin: 0px 5px;
  align-items: center;
}
.atividade-switches {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px;
  gap: 40px;
}
.atividade-nome {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #565e73;
  margin-left: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.atividade-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #565e73;
}
.selecionar-todos {
  margin-right: 64px;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 24px;
}
#alert1 {
  color: red;
  font-size: 14px;
}
#alert2 {
  color: red;
  font-size: 14px;
}
#alert3 {
  color: red;
  font-size: 14px;
}
#alert4 {
  color: red;
  font-size: 14px;
}
#alert5 {
  color: red;
  font-size: 14px;
}
.hidden {
  display: none;
}
.name-box {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  border-bottom: 1px solid #dddee3;
}
.subtitle-icon {
  width: 12px;
  margin-right: 10px;
}
.nome-monitor {
  color: #565e73;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 14px;
}
.graphTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #565e73;
  margin: 32px 0px;
}
.graphBox {
  display: flex;
  flex-direction: column;
}
</style>

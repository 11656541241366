<template>
  <td class="table-cell-header">
    <div class="header-wrapper" @click="toggleSort(field)">
      <div class="header-wrapper__label">
        {{ field.label }}
      </div>
      <div class="header-wrapper__sort" v-if="sortable">
        <span
          v-if="!sortDirection || sortDirection === 'asc'"
          class="material-icons pointer"
          :style="`color: ${sortDirection === 'asc' ? '#003366' : '#bbb'};`"
        >
          arrow_drop_up
        </span>
        <span
          :style="`color: ${sortDirection === 'desc' ? '#003366' : '#bbb'};`"
          v-if="sortDirection === 'desc'"
          class="material-icons pointer"
          >arrow_drop_down
        </span>
      </div>
    </div>
  </td>
</template>
<script>
export default {
  name: "table-cell-header",
  props: {
    field: {
      default: null,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: null,
    },
  },
  emits: ["sort"],
  data() {
    return {
      direction: this.sortDirection,
    };
  },
  methods: {
    setDirection() {
      if (this.sortDirection === null) {
        this.direction = "asc";
        return this.direction;
      }
      if (this.sortDirection === "asc") {
        this.direction = "desc";
        return this.direction;
      }
      if (this.sortDirection === "desc") {
        this.direction = null;
        return this.direction;
      }
    },
    toggleSort(field) {
      if (this.sortable) {
        this.$emit("sort", {
          key: field.key,
          sortDirection: this.setDirection(),
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #565e73;
  padding: 0px;
}

.header-wrapper__label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 215%;
  text-transform: uppercase;
  color: white;
  width: 150px;
}

.header-wrapper__sort {
  margin-left: 1rem;
  span {
    margin: 0px 0;
    background: none;
    font-weight: bold;
    font-size: 1rem;
  }
}
.table-cell-header {
  padding: 1px;
}
</style>

<template>
  <button class="login-btn w-100" @click="$emit('clicked')">
    <span class="btn-name">{{ name }}</span>
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.login-btn {
  background: none;
  color: #fff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 304px;
  height: 48px;
  background: #003366;
  border-radius: 12px;
}

.btn-name {
  font-size: 12px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
}
</style>

<template>
  <div>
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("userCalendar") }}</span>
    </h3>

    <div class="ml-3 mr-2">
      <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import BackArrow from "../../components/BackArrow.vue";

import services from "../../services";

export default {
  name: "DetailsZelador",
  components: {
    FullCalendar,
    BackArrow,
  },
  data() {
    return {
      selectedNeighborhood: null,
      neighborhoods: [],
      selectedTag: null,
      tags: [],
      selectedInstitution: null,
      institutions: [],
      calendarOptions: {
        contentHeight: "auto",
        allDaySlot: false,
        timeZone: "America/Brasilia",
        initialView: "dayGridMonth",
        locale: "pt-BR",
        datesSet: this.handleMonthChange,
        eventClick: this.handleEventClick,
        slotMinTime: "06:00:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
        },

        events: [],
        headerToolbar: {
          left: "",
          center: "prev,title,next",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        buttonText: {
          today: "Hoje",
          year: "Ano",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          list: "lista",
        },
      },
    };
  },
  mounted: async function () {
    this.fullCalendar = this.$refs.fullCalendar.getApi();
  },
  methods: {
    cleanAgenda() {
      if (this.fullCalendar != null) this.fullCalendar.removeAllEvents();
      this.agenda = [];
    },
    async filterExecutions() {
      this.cleanAgenda();

      let payload = {
        usuario_id: this.$route.params.id,
        data_inicio: this.startDate,
        data_fim: this.endDate,
      };

      await this.requestExecutions(payload);

      this.fillCalendarEvents();
    },
    async requestExecutions(payload) {
      const { data, errors } = await services.executions.executionFilter(
        payload
      );
      if (!errors) {
        this.agenda = data;
      }
    },
    fillCalendarEvents() {
      this.agenda.forEach((element) => {
        this.fullCalendar.addEvent({
          id: element.execucao_uuid,
          title: `${element.atividade_nome}`,
          start: element.execucao_data_criacao,
          end: element.execucao_data_criacao,
          startEditable: false,
          durationEditable: false,
        });
      });
    },
    handleEventClick: function (clickInfo) {
      this.$router.push({
        name: "DetalheExecucao",
        params: { execucao_uuid: clickInfo.event.id },
      });
    },
    handleMonthChange(payload) {
      this.startDate = payload.startStr.replaceAll("T", " ").substring(0, 10);
      this.endDate = payload.endStr.replaceAll("T", " ").substring(0, 10);
      this.filterExecutions();
    },
    onSelectBairro(selectedOption) {
      this.selectedNeighborhood = selectedOption;
      this.filterExecutions();
    },
    onSelectTag(selectedOption) {
      this.selectedTag = selectedOption;
      this.filterExecutions();
    },
    onSelectInstituicao(selectedOption) {
      this.selectedInstitution = selectedOption;
      this.filterExecutions();
    },
    onRemoveBairro() {
      this.selectedNeighborhood = null;
      this.filterExecutions();
    },
    onRemoveTag() {
      this.selectedTag = null;
      this.filterExecutions();
    },
    onRemoveInstituicao() {
      this.selectedInstitution = null;
      this.filterExecutions();
    },
    invertArrayLatLng(coordinates) {
      let invertedCoordinates = [];
      coordinates.map((item) => {
        let arrayLatLng = [item[1], item[0]];
        invertedCoordinates.push(arrayLatLng);
      });
      return invertedCoordinates;
    },
  },
};
</script>

<style lang="scss">
.conteudoslide {
  max-width: 535px;
  overflow: hidden;
  img {
    height: 350px;
    margin: 0 auto;
    display: block;

    &:hover {
      cursor: pointer;
    }
  }
}

.descricaoslide {
  margin-top: 1rem;

  p {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 0;

    span {
      background: #4f0dcc;
      padding: 0.2rem 0.5rem;
      color: #fff;
      margin: 0.2rem;
      border-radius: 1rem;
      font-size: 12px;
      text-transform: uppercase;
      display: inline-block;
    }
  }
}
</style>

<template>
  <div>
    <h3 class="intern-title">
      <back-arrow />
      <span class="page-nav">Cadastros de tags</span>
    </h3>
    <div class="tag-types">
      <div class="card-type">
        <div class="icon">
          <svg
            width="44"
            height="45"
            viewBox="0 0 44 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.5" width="44" height="44" rx="22" fill="#F3F4F5" />
            <path
              d="M31 13.5V15.5C31 25.127 25.627 29.5 19 29.5H15.243C15.08 30.412 15 31.407 15 32.5H13C13 31.137 13.116 29.9 13.346 28.768C13.116 27.474 13 25.718 13 23.5C13 17.977 17.477 13.5 23 13.5C25 13.5 27 14.5 31 13.5ZM23 15.5C18.582 15.5 15 19.082 15 23.5C15 23.862 15.003 24.211 15.01 24.546C16.264 22.568 18.101 21.005 20.504 19.632L21.496 21.368C18.641 23 16.747 24.854 15.776 27.5H19C25.015 27.5 28.871 23.527 28.997 15.888C27.625 16.021 26.35 15.936 24.777 15.7C23.627 15.527 23.401 15.5 23 15.5Z"
              fill="#838998"
            />
          </svg>
        </div>
        <div class="content">
          <div class="title">Tags de atividades</div>
          <div class="description">
            Nesta área você pode criar, editar e atribuir tags de atividades à
            instituições da cidade de forma simples e eficiente.
          </div>
          <Button
            class="button"
            @clicked="handleRedirect('web')"
            size="small"
            name="Ver"
          />
        </div>
      </div>
      <div class="card-type">
        <div class="icon">
          <svg
            width="44"
            height="45"
            viewBox="0 0 44 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.5" width="44" height="44" rx="22" fill="#F3F4F5" />
            <path
              d="M28.031 27.117L32.314 31.399L30.899 32.814L26.617 28.531C25.0237 29.8082 23.042 30.5029 21 30.5C16.032 30.5 12 26.468 12 21.5C12 16.532 16.032 12.5 21 12.5C25.968 12.5 30 16.532 30 21.5C30.0029 23.542 29.3082 25.5237 28.031 27.117ZM26.025 26.375C27.2941 25.0699 28.0029 23.3204 28 21.5C28 17.632 24.867 14.5 21 14.5C17.132 14.5 14 17.632 14 21.5C14 25.367 17.132 28.5 21 28.5C22.8204 28.5029 24.5699 27.7941 25.875 26.525L26.025 26.375ZM22.178 17.676C21.8831 17.8092 21.625 18.0119 21.4257 18.2669C21.2264 18.5218 21.0919 18.8212 21.0338 19.1395C20.9757 19.4578 20.9957 19.7855 21.0921 20.0943C21.1885 20.4032 21.3583 20.6841 21.5871 20.9129C21.8159 21.1417 22.0968 21.3115 22.4057 21.4079C22.7145 21.5043 23.0422 21.5243 23.3605 21.4662C23.6788 21.4081 23.9782 21.2736 24.2331 21.0743C24.4881 20.875 24.6908 20.6169 24.824 20.322C25.0868 21.1756 25.0563 22.0926 24.7375 22.927C24.4186 23.7613 23.8297 24.4648 23.0646 24.9256C22.2994 25.3864 21.4021 25.5778 20.5155 25.4694C19.629 25.3609 18.8042 24.9589 18.1726 24.3274C17.5411 23.6958 17.1391 22.871 17.0306 21.9845C16.9222 21.0979 17.1136 20.2006 17.5744 19.4354C18.0352 18.6703 18.7387 18.0814 19.573 17.7625C20.4074 17.4437 21.3244 17.4132 22.178 17.676Z"
              fill="#838998"
            />
          </svg>
        </div>
        <div class="content">
          <div class="title">Tags do app fiscal</div>
          <div class="description">
            Nesta área você pode criar, editar e atribuir tags para diferentes
            tipos de fiscais de forma simples e eficiente.
          </div>
          <Button
            class="button"
            @clicked="handleRedirect('supervisor')"
            size="small"
            name="Ver"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import BackArrow from "../../components/BackArrow.vue";
export default {
  components: {
    Button,
    BackArrow,
  },
  methods: {
    handleRedirect(type) {
      if (type === "web") {
        this.$router.push("/tags");
      }
      if (type === "supervisor") {
        this.$router.push("/tags-fiscal");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tag-types {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  .card-type {
    border: 1px solid #dddee3;
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1;
    padding: 32px 24px;
    border-radius: 8px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.006em;
      text-align: left;
      color: #565e73;
      .title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: left;
        color: #131e3b;
        margin-bottom: 24px;
      }
      .button {
        width: fit-content;
      }
    }
  }
}
</style>

<script>
import Sidebar from "../../components/Sidebar.vue";
import Button from "../../components/Button.vue";
import GoogleMaps from "./partials/GoogleMaps.vue";
import ExecutionCard from "./partials/ExecutionCard.vue";
import DateLabel from "../../components/DateLabel.vue";
import Multiselect from "vue-multiselect";
import { dateToText, formatDate, formatTime } from "../../utils/date";
import { decrypt } from "../../utils/decrypt";
import services from "../../services";
import Loading from "../../components/IndexComponents/Loading.vue";

export default {
  name: "MapaDashboard",
  components: {
    Sidebar,
    GoogleMaps,
    ExecutionCard,
    DateLabel,
    Loading,
    Button,
    Multiselect,
  },
  data() {
    return {
      tagOptions: [],
      selectedTags: [],
      timelineView: true,
      lastDaysButtonLabel: "",
      bounds: [],
      supervisors: [],
      pins: [],
      showSidebar: false,
      fullscreenMap: true,
      loadingData: true,
      startDate: null,
      endDate: null,
      firstLoading: true,
      urlLogo: "",
      showMarkers: true,
      calendarModal: false,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      range: {
        start: null,
        end: null,
      },
      bigNumbers: {
        photos: 0,
        neighborhoods: 0,
        executions: 0,
      },
      formatDate,
      formatTime,
    };
  },
  mounted: async function () {
    document.addEventListener("click", (e) => {
      if (
        e.target.closest("#sidebar") === null &&
        e.target.closest(".sidebar-toggler") === null
      ) {
        this.showSidebar = false;
      }
    });

    await this.setDefaultDates();
    await this.logoUrl();
    await this.initAllData();
  },
  methods: {
    async clearTags() {
      this.selectedTags = [];
      await this.getSupervisors();
      await this.getSupervisorBigNumbers();
    },
    async initAllData() {
      this.loadingData = true;
      this.firstLoading = false;
      await this.getSupervisorTags();
      await this.getSupervisorBigNumbers();
      await this.getSupervisors();
      this.loadingData = false;
    },

    async getSupervisorBigNumbers() {
      const { data, errors } =
        await services.UsersSupervisors.getSupervisorBigNumbers({
          data_inicio: this.range.start || this.startDate,
          data_fim: this.range.end || this.endDate,
          fiscal_tag_id: this.selectedTags.length
            ? this.selectedTags.map((item) => item.value)
            : [],
        });
      if (!errors) {
        this.bigNumbers = {
          photos: data.total_fiscal_registro_foto,
          executions: data.total_fiscal_registro,
          neighborhoods: data.total_bairros,
        };
      }
    },

    async getSupervisors() {
      const { data, errors } =
        await services.UsersSupervisors.requestFiscalData({
          data_inicio: this.range.start || this.startDate,
          data_fim: this.range.end || this.endDate,
          fiscal_tag_id: this.selectedTags.length
            ? this.selectedTags.map((item) => item.value)
            : [],
        });
      if (!errors) {
        this.supervisors = data.map((item) => {
          return {
            ...item,
            date: this.formatDate(item.fiscal_registro_data),
          };
        });

        this.pins = this.supervisors.map((item) => {
          return {
            lat: item.fiscal_registro_lat,
            lng: item.fiscal_registro_lng,
            id: item.fiscal_registro_id,
            uuid: item.fiscal_registro_uuid,
            center: {
              lat: item.fiscal_registro_lat,
              lng: item.fiscal_registro_lng,
            },
          };
        });

        this.bounds = this.pins;
        this.fitBounds();
      }
    },

    async getSupervisorTags() {
      const response = await services.tags.getSupervisorTags();
      this.tagOptions = response.data.map((item) => {
        return {
          value: item.fiscal_tag_id,
          name: item.fiscal_tag_descricao,
        };
      });
    },

    clickSupervisor(data) {
      this.$router.push(`/fiscal/${data.fiscal_registro_uuid}`);
    },

    async setDefaultDates() {
      if (this.dates) {
        this.range = {
          start: this.dates[0],
          end: this.dates[1],
        };
      } else {
        this.startDate = new Date(
          new Date().setDate(new Date().getDate() - 30)
        ).toISOString();
        this.endDate = new Date().toISOString();
      }

      this.lastDaysButtonLabel = `${dateToText(this.startDate)} - ${dateToText(
        this.endDate
      )}`;
    },

    async filterByDate() {
      // checa se data está em format UTC para transformat em ISO
      let end = this.range.end.toString();
      let start = this.range.start.toString();

      if (start.includes("GMT") && end.includes("GMT")) {
        start = this.range.start.toISOString().slice(0, -5);
        end = this.range.end.toISOString().slice(0, -5);
      } else {
        start = this.range.start;
        end = this.range.end;
      }

      this.lastDaysButtonLabel = dateToText(start) + " - " + dateToText(end);

      await this.getSupervisors();
      await this.getSupervisorBigNumbers();

      this.calendarModal = false;
    },

    async filterByTags() {
      await this.getSupervisors();
      await this.getSupervisorBigNumbers();
    },

    async logoUrl() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      this.urlLogo = user.cidade_logo;
    },

    homeRoute() {
      window.location.reload();
    },

    fitBounds() {
      setTimeout(() => {
        this.$refs.childMap.fitBoundsGoogle();
      }, 10);
    },
  },
};
</script>
<template>
  <div style="overflow-x: hidden">
    <Transition>
      <div v-if="showSidebar" class="sidebar sidebar-toggler">
        <Sidebar @clicked="showSidebar = !showSidebar" />
      </div>
    </Transition>

    <div style="max-height: 100% !important">
      <header class="c-header c-header-light px-3 position-relative">
        <button
          class="c-header-toggler sidebar-toggler"
          type="button"
          @click="showSidebar = !showSidebar"
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z"
              fill="#003366"
            />
          </svg>
        </button>
        <div class="d-flex align-items-center position-welcome pl-1"></div>
        <span class="pointer py-3 brand-position" @click="homeRoute"
          ><img :src="urlLogo" alt="logo" width="120"
        /></span>
      </header>
      <CRow
        v-if="!loadingData || firstLoading"
        class="d-flex flex-md-row bg-white"
      >
        <CCol
          xl="4"
          class="order-2 order-md-last mt-2 pl-4 asideRelatory"
          v-if="fullscreenMap"
        >
          <section class="m-1 mt-4 mb-4">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h1 class="title ml-3 pb-1">Florianópolis</h1>
            </div>
            <Loading v-if="loadingData" />
            <div v-else>
              <CRow class="m-1 mb-4">
                <CCol sm="12" class="d-flex align-items-center">
                  <span
                    @click="calendarModal = !calendarModal"
                    class="new-icons pointer btn-relatory mr-3 mb-1"
                    id="calendar-btn"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="-1 1 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                        fill="white"
                      />
                    </svg>
                  </span>

                  <Transition name="filter">
                    <div
                      class="absolute-box w-50"
                      v-if="calendarModal"
                      id="calendar"
                    >
                      <v-date-picker v-model="range" is-range>
                        <template v-slot:footer>
                          <div class="d-flex justify-content-between mt-3">
                            <button
                              class="mb-3 w-100 ml-2 cancel-button"
                              @click="calendarModal = !calendarModal"
                            >
                              {{ $t("cancel") }}
                            </button>
                            <button
                              class="mb-3 mr-2 ml-2 w-100 send-button"
                              @click="filterByDate"
                            >
                              {{ $t("filter") }}
                            </button>
                          </div>
                        </template>
                      </v-date-picker>
                    </div>
                  </Transition>
                  <DateLabel :name="lastDaysButtonLabel" class="no-pointer" />
                </CCol>
              </CRow>

              <CRow class="m-1">
                <CCol sm="12" md="12" lg="12">
                  <div class="d-flex align-items-center">
                    <multiselect
                      class="mr-3"
                      :multiple="true"
                      noOptions="Nenhuma tag encontrada"
                      selectLabel="Selecionar"
                      selectedLabel="Selecionado"
                      v-model="selectedTags"
                      track-by="name"
                      label="name"
                      placeholder="Filtre por tags"
                      :options="tagOptions"
                    />
                    <Button
                      style="max-height: 44px"
                      class="mr-2"
                      name="Filtrar"
                      @clicked="filterByTags"
                      >Filtrar</Button
                    >
                    <Button
                      type="outline"
                      style="max-height: 44px"
                      name="Limpar"
                      @clicked="clearTags"
                      >Limpar filtros</Button
                    >
                  </div>
                </CCol>
              </CRow>

              <CRow class="m-1">
                <CCol sm="12" md="12" lg="12">
                  <div class="big-numbers">
                    <div class="big-numbers__box">
                      <div class="big-numbers__box--info">
                        <div class="big-numbers__label">Bairros</div>
                        <div class="big-numbers__counter">
                          {{ bigNumbers.neighborhoods }}
                        </div>
                      </div>
                      <img
                        src="../../assets/img/big-number-keeping-sectors.svg"
                        alt=""
                      />
                    </div>

                    <div class="big-numbers__box">
                      <div class="big-numbers__box--info">
                        <div class="big-numbers__label">Execuções</div>
                        <div class="big-numbers__counter">
                          {{ bigNumbers.executions }}
                        </div>
                      </div>
                      <img
                        src="../../assets/img/big-number-execution.svg"
                        alt=""
                      />
                    </div>

                    <div class="big-numbers__box">
                      <div class="big-numbers__box--info">
                        <div class="big-numbers__label">Fotos</div>
                        <div class="big-numbers__counter">
                          {{ bigNumbers.photos }}
                        </div>
                      </div>
                      <img
                        src="../../assets/img/big-number-photos.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow class="m-1">
                <CCol sm="12" md="12" class="supervisor-list">
                  <div v-if="supervisors.length > 0">
                    <div
                      v-for="supervisor of supervisors"
                      :key="supervisor.fiscal_registro_uuid"
                      class="mb-3"
                    >
                      <ExecutionCard
                        :title="`#${supervisor.fiscal_registro_id} - ${supervisor.usuario_nome} | ${supervisor.date}`"
                        :open="true"
                      >
                        <template v-slot:content>
                          <div class="w-100 d-flex justify-content-end">
                            <span
                              @click="clickSupervisor(supervisor)"
                              class="pointer"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Fill=no, Size=16px">
                                  <path
                                    id="Vector"
                                    d="M6.66667 4V5.33333H3.33333V12.6667H10.6667V9.33333H12V13.3333C12 13.5101 11.9298 13.6797 11.8047 13.8047C11.6797 13.9298 11.5101 14 11.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V4.66667C2 4.48986 2.07024 4.32029 2.19526 4.19526C2.32029 4.07024 2.48986 4 2.66667 4H6.66667ZM14 2V8L11.4707 5.47133L7.47133 9.47133L6.52867 8.52867L10.528 4.52867L8 2H14Z"
                                    fill="black"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div
                            v-if="supervisor.bairro_nome"
                            class="neighborhood"
                          >
                            <span class="mr-1">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Fill=no, Size=16px">
                                  <path
                                    id="Vector"
                                    d="M3.99984 12.6664H11.9998V6.10445L7.99984 2.46845L3.99984 6.10445V12.6664ZM12.6665 13.9998H3.33317C3.15636 13.9998 2.98679 13.9295 2.86177 13.8045C2.73674 13.6795 2.6665 13.5099 2.6665 13.3331V7.33312H0.666504L7.55117 1.07445C7.67391 0.962769 7.83389 0.900879 7.99984 0.900879C8.16578 0.900879 8.32576 0.962769 8.4485 1.07445L15.3332 7.33312H13.3332V13.3331C13.3332 13.5099 13.2629 13.6795 13.1379 13.8045C13.0129 13.9295 12.8433 13.9998 12.6665 13.9998ZM4.99984 8.66645H6.33317C6.33317 9.10848 6.50876 9.5324 6.82133 9.84496C7.13389 10.1575 7.55781 10.3331 7.99984 10.3331C8.44186 10.3331 8.86579 10.1575 9.17835 9.84496C9.49091 9.5324 9.6665 9.10848 9.6665 8.66645H10.9998C10.9998 9.4621 10.6838 10.2252 10.1212 10.7878C9.55855 11.3504 8.79549 11.6664 7.99984 11.6664C7.20419 11.6664 6.44113 11.3504 5.87852 10.7878C5.31591 10.2252 4.99984 9.4621 4.99984 8.66645Z"
                                    fill="black"
                                  />
                                </g>
                              </svg>
                            </span>
                            {{ supervisor.bairro_nome }}
                          </div>
                          <div
                            v-if="supervisor.fotos && supervisor.fotos.length"
                            class="photos mt-3"
                          >
                            <div
                              class="d-flex justify-content-between align-items-start"
                            >
                              <div class="evidences-title">
                                <svg
                                  class="mr-1"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="Fill=no, Size=16px">
                                    <path
                                      id="Vector"
                                      d="M6.55216 3.33333L5.21883 4.66667H2.66683V12.6667H13.3335V4.66667H10.7815L9.44816 3.33333H6.55216ZM6.00016 2H10.0002L11.3335 3.33333H14.0002C14.177 3.33333 14.3465 3.40357 14.4716 3.5286C14.5966 3.65362 14.6668 3.82319 14.6668 4V13.3333C14.6668 13.5101 14.5966 13.6797 14.4716 13.8047C14.3465 13.9298 14.177 14 14.0002 14H2.00016C1.82335 14 1.65378 13.9298 1.52876 13.8047C1.40373 13.6797 1.3335 13.5101 1.3335 13.3333V4C1.3335 3.82319 1.40373 3.65362 1.52876 3.5286C1.65378 3.40357 1.82335 3.33333 2.00016 3.33333H4.66683L6.00016 2ZM8.00016 12C7.0277 12 6.09507 11.6137 5.40744 10.9261C4.7198 10.2384 4.3335 9.30579 4.3335 8.33333C4.3335 7.36087 4.7198 6.42824 5.40744 5.74061C6.09507 5.05297 7.0277 4.66667 8.00016 4.66667C8.97262 4.66667 9.90525 5.05297 10.5929 5.74061C11.2805 6.42824 11.6668 7.36087 11.6668 8.33333C11.6668 9.30579 11.2805 10.2384 10.5929 10.9261C9.90525 11.6137 8.97262 12 8.00016 12ZM8.00016 10.6667C8.619 10.6667 9.21249 10.4208 9.65008 9.98325C10.0877 9.54566 10.3335 8.95217 10.3335 8.33333C10.3335 7.7145 10.0877 7.121 9.65008 6.68342C9.21249 6.24583 8.619 6 8.00016 6C7.38132 6 6.78783 6.24583 6.35025 6.68342C5.91266 7.121 5.66683 7.7145 5.66683 8.33333C5.66683 8.95217 5.91266 9.54566 6.35025 9.98325C6.78783 10.4208 7.38132 10.6667 8.00016 10.6667Z"
                                      fill="black"
                                    />
                                  </g>
                                </svg>
                                Evidências fotográficas
                              </div>
                              <div class="evidences-view">
                                <span
                                  v-if="!timelineView"
                                  class="material-icons pointer"
                                  @click="timelineView = !timelineView"
                                  >show_chart</span
                                >
                                <span
                                  v-else
                                  class="material-icons pointer"
                                  style="background-color: #00b1eb"
                                  @click="timelineView = !timelineView"
                                  >collections</span
                                >
                              </div>
                            </div>
                            <div v-if="timelineView">
                              <div
                                class="photo"
                                v-for="p in supervisor.fotos"
                                :key="p.fiscal_registro_uuid"
                              >
                                <img :src="p.fiscal_registro_foto" alt="" />
                                <div class="tag">
                                  {{ p.fiscal_tag_descricao }}
                                </div>
                                <div class="time mb-1">
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="Fill=no, Size=12px">
                                      <path
                                        id="Vector"
                                        d="M6.00004 11C5.40909 11 4.82393 10.8836 4.27796 10.6575C3.73199 10.4313 3.23592 10.0999 2.81805 9.68201C2.40019 9.26415 2.06872 8.76807 1.84258 8.22211C1.61643 7.67615 1.50004 7.09098 1.50004 6.50003C1.50004 5.90909 1.61643 5.32392 1.84258 4.77796C2.06872 4.23199 2.40019 3.73592 2.81805 3.31805C3.23592 2.90019 3.73199 2.56872 4.27796 2.34258C4.82393 2.11643 5.40909 2.00004 6.00004 2.00004C7.19351 2.00004 8.3381 2.47414 9.18202 3.31805C10.0259 4.16197 10.5 5.30656 10.5 6.50003C10.5 7.69351 10.0259 8.8381 9.18202 9.68201C8.3381 10.5259 7.19351 11 6.00004 11ZM6.00004 10C6.45966 10 6.91479 9.9095 7.33943 9.73361C7.76407 9.55772 8.1499 9.29991 8.47491 8.97491C8.79991 8.6499 9.05772 8.26407 9.23361 7.83943C9.40951 7.41479 9.50004 6.95966 9.50004 6.50003C9.50004 6.04041 9.40951 5.58528 9.23361 5.16064C9.05772 4.736 8.79991 4.35017 8.47491 4.02516C8.1499 3.70016 7.76407 3.44235 7.33943 3.26646C6.91479 3.09057 6.45966 3.00003 6.00004 3.00003C5.07178 3.00004 4.18154 3.36878 3.52516 4.02516C2.86878 4.68154 2.50004 5.57178 2.50004 6.50003C2.50004 7.42829 2.86878 8.31853 3.52516 8.97491C4.18154 9.63128 5.07178 10 6.00004 10ZM6.50004 6.50003H8.00004V7.50003H5.50004V4.00004H6.50004V6.50003ZM0.873535 3.14103L2.64104 1.37354L3.34854 2.08053L1.58004 3.84853L0.873535 3.14103ZM9.35853 1.37354L11.1265 3.14103L10.4195 3.84853L8.65154 2.08053L9.35904 1.37354H9.35853Z"
                                        fill="black"
                                      />
                                    </g>
                                  </svg>
                                  {{ formatTime(p.fiscal_registro_foto_data) }}
                                </div>
                                <div class="location">
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="Fill=no, Size=12px">
                                      <path
                                        id="Vector"
                                        d="M6 11.864L2.818 8.68196C2.18867 8.05262 1.76009 7.2508 1.58646 6.37789C1.41284 5.50497 1.50195 4.60018 1.84255 3.77791C2.18315 2.95565 2.75992 2.25285 3.49994 1.75838C4.23996 1.26392 5.10999 1 6 1C6.89002 1 7.76004 1.26392 8.50006 1.75838C9.24008 2.25285 9.81686 2.95565 10.1575 3.77791C10.4981 4.60018 10.5872 5.50497 10.4135 6.37789C10.2399 7.2508 9.81133 8.05262 9.182 8.68196L6 11.864ZM8.475 7.97496C8.96445 7.48546 9.29776 6.86182 9.43278 6.1829C9.5678 5.50397 9.49846 4.80026 9.23355 4.16074C8.96863 3.52122 8.52002 2.97461 7.94446 2.59005C7.3689 2.20548 6.69222 2.00021 6 2.00021C5.30778 2.00021 4.63111 2.20548 4.05554 2.59005C3.47998 2.97461 3.03138 3.52122 2.76646 4.16074C2.50154 4.80026 2.43221 5.50397 2.56723 6.1829C2.70225 6.86182 3.03555 7.48546 3.525 7.97496L6 10.45L8.475 7.97496ZM6 6.49996C5.73479 6.49996 5.48043 6.3946 5.2929 6.20707C5.10536 6.01953 5 5.76518 5 5.49996C5 5.23474 5.10536 4.98039 5.2929 4.79285C5.48043 4.60532 5.73479 4.49996 6 4.49996C6.26522 4.49996 6.51957 4.60532 6.70711 4.79285C6.89464 4.98039 7 5.23474 7 5.49996C7 5.76518 6.89464 6.01953 6.70711 6.20707C6.51957 6.3946 6.26522 6.49996 6 6.49996Z"
                                        fill="black"
                                      />
                                    </g>
                                  </svg>

                                  {{ p.foto_registro_lat }},
                                  {{ p.foto_registro_lng }}
                                </div>
                              </div>
                            </div>
                            <div class="photo-grid" v-else>
                              <div
                                v-for="p in supervisor.fotos"
                                :key="p.fiscal_registro_uuid"
                              >
                                <div class="mb-2">
                                  <img :src="p.fiscal_registro_foto" alt="" />
                                  <div class="tag mb-1">
                                    {{ p.fiscal_tag_descricao }}
                                  </div>
                                  <div class="time mb-1">
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g id="Fill=no, Size=12px">
                                        <path
                                          id="Vector"
                                          d="M6.00004 11C5.40909 11 4.82393 10.8836 4.27796 10.6575C3.73199 10.4313 3.23592 10.0999 2.81805 9.68201C2.40019 9.26415 2.06872 8.76807 1.84258 8.22211C1.61643 7.67615 1.50004 7.09098 1.50004 6.50003C1.50004 5.90909 1.61643 5.32392 1.84258 4.77796C2.06872 4.23199 2.40019 3.73592 2.81805 3.31805C3.23592 2.90019 3.73199 2.56872 4.27796 2.34258C4.82393 2.11643 5.40909 2.00004 6.00004 2.00004C7.19351 2.00004 8.3381 2.47414 9.18202 3.31805C10.0259 4.16197 10.5 5.30656 10.5 6.50003C10.5 7.69351 10.0259 8.8381 9.18202 9.68201C8.3381 10.5259 7.19351 11 6.00004 11ZM6.00004 10C6.45966 10 6.91479 9.9095 7.33943 9.73361C7.76407 9.55772 8.1499 9.29991 8.47491 8.97491C8.79991 8.6499 9.05772 8.26407 9.23361 7.83943C9.40951 7.41479 9.50004 6.95966 9.50004 6.50003C9.50004 6.04041 9.40951 5.58528 9.23361 5.16064C9.05772 4.736 8.79991 4.35017 8.47491 4.02516C8.1499 3.70016 7.76407 3.44235 7.33943 3.26646C6.91479 3.09057 6.45966 3.00003 6.00004 3.00003C5.07178 3.00004 4.18154 3.36878 3.52516 4.02516C2.86878 4.68154 2.50004 5.57178 2.50004 6.50003C2.50004 7.42829 2.86878 8.31853 3.52516 8.97491C4.18154 9.63128 5.07178 10 6.00004 10ZM6.50004 6.50003H8.00004V7.50003H5.50004V4.00004H6.50004V6.50003ZM0.873535 3.14103L2.64104 1.37354L3.34854 2.08053L1.58004 3.84853L0.873535 3.14103ZM9.35853 1.37354L11.1265 3.14103L10.4195 3.84853L8.65154 2.08053L9.35904 1.37354H9.35853Z"
                                          fill="black"
                                        />
                                      </g>
                                    </svg>
                                    {{
                                      formatTime(p.fiscal_registro_foto_data)
                                    }}
                                  </div>
                                  <div class="location">
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g id="Fill=no, Size=12px">
                                        <path
                                          id="Vector"
                                          d="M6 11.864L2.818 8.68196C2.18867 8.05262 1.76009 7.2508 1.58646 6.37789C1.41284 5.50497 1.50195 4.60018 1.84255 3.77791C2.18315 2.95565 2.75992 2.25285 3.49994 1.75838C4.23996 1.26392 5.10999 1 6 1C6.89002 1 7.76004 1.26392 8.50006 1.75838C9.24008 2.25285 9.81686 2.95565 10.1575 3.77791C10.4981 4.60018 10.5872 5.50497 10.4135 6.37789C10.2399 7.2508 9.81133 8.05262 9.182 8.68196L6 11.864ZM8.475 7.97496C8.96445 7.48546 9.29776 6.86182 9.43278 6.1829C9.5678 5.50397 9.49846 4.80026 9.23355 4.16074C8.96863 3.52122 8.52002 2.97461 7.94446 2.59005C7.3689 2.20548 6.69222 2.00021 6 2.00021C5.30778 2.00021 4.63111 2.20548 4.05554 2.59005C3.47998 2.97461 3.03138 3.52122 2.76646 4.16074C2.50154 4.80026 2.43221 5.50397 2.56723 6.1829C2.70225 6.86182 3.03555 7.48546 3.525 7.97496L6 10.45L8.475 7.97496ZM6 6.49996C5.73479 6.49996 5.48043 6.3946 5.2929 6.20707C5.10536 6.01953 5 5.76518 5 5.49996C5 5.23474 5.10536 4.98039 5.2929 4.79285C5.48043 4.60532 5.73479 4.49996 6 4.49996C6.26522 4.49996 6.51957 4.60532 6.70711 4.79285C6.89464 4.98039 7 5.23474 7 5.49996C7 5.76518 6.89464 6.01953 6.70711 6.20707C6.51957 6.3946 6.26522 6.49996 6 6.49996Z"
                                          fill="black"
                                        />
                                      </g>
                                    </svg>

                                    {{ p.foto_registro_lat }}<br />
                                    {{ p.foto_registro_lng }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </ExecutionCard>
                    </div>
                  </div>
                  <div class="no-data d-flex justify-content-center" v-else>
                    Nenhuma execução encontrada
                  </div>
                </CCol>
              </CRow>
            </div>
          </section>
        </CCol>

        <CCol
          :xl="!fullscreenMap ? 12 : 8"
          class="order-1 order-xl-last breath-map"
          :class="!fullscreenMap ? 'fullscreenMapOpen' : 'fullscreenMapClose'"
        >
          <GoogleMaps
            ref="childMap"
            :dates="range.start + range.end"
            :center="center"
            :tracking="[]"
            :pins="pins"
            :polygons="[]"
            :bounds="bounds"
          />

          <div
            class="map-options p-0 ml-0"
            :class="!fullscreenMap && 'shift-map-options'"
          >
            <button
              class="fullscreen-map"
              @click="fullscreenMap = !fullscreenMap"
            >
              <span class="new-icons pointer m-0">
                <svg
                  width="16"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.13664 4L5.12251 2.05307L5.24997 1.9281L5.12251 1.80314L4.18251 0.881573L4.06013 0.761594L3.93762 0.88144L0.877624 3.8749L0.749887 3.99986L0.877488 4.12496L3.93749 7.12496L4.06043 7.2455L4.18295 7.12453L5.12295 6.19643L5.24953 6.07146L5.12251 5.94693L3.13664 4ZM10.8634 4L8.87749 5.94693L8.75003 6.0719L8.87749 6.19686L9.81749 7.11843L9.93987 7.23841L10.0624 7.11856L13.1224 4.1251L13.2501 4.00014L13.1225 3.87504L10.0625 0.875038L9.93957 0.754503L9.81705 0.875471L8.87705 1.80358L8.75048 1.92855L8.87749 2.05307L10.8634 4Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.35"
                  />
                </svg>
              </span>
            </button>
          </div>
        </CCol>
      </CRow>

      <div v-else style="height: calc(100vh - 66px)" class="bg-white pb-4">
        <div
          class="d-flex h-100 w-100 justify-content-center align-items-center mb-4 pb-4"
        >
          <img
            :src="require(`@/assets/img/loading.gif`)"
            class="mb-4 pb-4"
            alt="AMA carregando"
            width="150"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #29334e;
}

.no-data {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #29334e;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 12px;
  left: 30px;
}

.send-button {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px 24px;
  background: #003063;
  border-radius: 8px;
  margin: 0px 8px;
  outline: none;
  border: none;

  &:hover {
    background: #002a5a;
  }

  &:active {
    background: #002a5a;
  }
}

.cancel-button {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #565e73;
  margin: 0px 8px;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 8px;

  &:hover {
    background: #f5f5f5;
  }

  &:active {
    background: #e5e5e5;
  }
}

.separator-calendar {
  border: 1px solid #ececec;
  width: 85%;
  margin-top: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s !important;
}

.v-enter-from,
.v-leave-to {
  opacity: 0 !important;
}
.sidebar {
  z-index: 99999999 !important;
}
.c-header {
  box-shadow: 0px 8px 12px rgba(160, 168, 189, 0.24);
  border: 0;

  .c-header-toggler-icon {
    background-image: url("../../assets/img/toggle.svg");
    height: 18px;
  }
}

.position-welcome {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #131e3b;
}

.breath-map {
  padding-top: 2px;
  padding-left: 0px;
}

.brand-position {
  position: absolute;
  right: 32px;
  margin-top: -10px;
}

.fullscreen-map {
  background: #fff;
  margin-bottom: calc(13vh);
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 60px;
  height: 60px;
  border-radius: 0px 50% 50% 0px;
}

.fullscreenMapOpen {
  height: calc(100vh - 56px);
}

.fullscreenMapClose {
  height: calc(100vh - 56px);
}
.asideRelatory {
  height: calc(100vh - 64px);
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.map-options {
  position: absolute;
  bottom: 30px;
  left: 0px;
  z-index: 500;

  button {
    background: #fff;
    border: 0;
    display: block;
    clear: both;
    margin-bottom: 0.5rem;
  }
}

.shift-map-options {
  left: 16px !important;
}

@media (max-width: 1200px) {
  .fullscreenMapOpen {
    height: calc(100vh - 66px);
  }

  .fullscreenMapClose {
    height: calc(55vh - 66px);
  }
}

@media (max-width: 700px) {
  .position-welcome {
    display: none !important;
  }
}

.new-icons {
  width: 32px;
  height: 32px;
}

.supervisor-list {
  .photos {
    img {
      max-width: 192px;
      border-radius: 12px;
      margin-bottom: 8px;
    }
    .tag {
      font-size: 12px;
      width: fit-content;
      background: #dddee3;
      color: #131e3b;
      border-radius: 4px;
      font-weight: bold;
      padding: 2px 8px;
      margin-bottom: 8px;
    }
    .photo {
      position: relative;
      margin-top: 12px;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      &::before {
        position: absolute;
        top: -20px;
        content: " ";
        left: 6px;
        width: 2px;
        background: #5ccdf2;
        height: calc(100% + 16px);
      }
    }
  }
  .photo-grid {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    img {
      max-width: 150px;
      margin-bottom: 8px;
    }
    .tag {
      margin-bottom: 0;
    }
    .location,
    .time {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.big-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;

  .big-numbers__box {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #c6c9d0;

    .big-numbers__label {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #565e73;
    }

    .big-numbers__counter {
      font-family: "OpenSans-Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #29334e;
    }

    img {
      padding: 12px;
      border-radius: 80px;
      border: 1px solid #c6c9d0;
    }
  }
}
</style>

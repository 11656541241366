<template>
  <div class="bg-white users">
    <h3 class="d-flex justify-content-between intern-title mb-4 pb-2">
      <div>
        <div class="d-inline pr-2">
          <img
            @click="goToHome"
            class="pointer"
            src="../../assets/img/arrow_back.png"
          />
        </div>
        <span class="pagetitle pl-2">{{ user.usuario_nome }}</span>
      </div>
      <Button
        :disabled="loadingData || hasActiveContract"
        @clicked="createContractModal = true"
        name="Novo contrato"
      />
    </h3>
    <transition name="bounce">
      <div v-if="!loadingData" class="pl-2">
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in userTurnovers" :key="item.turnover_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
              <template v-slot:turnover_data_inicio_contrato>
                {{ formatDate(item.turnover_data_inicio_contrato) }}
              </template>
              <template v-slot:turnover_data_fim_contrato>
                {{ formatDate(item.turnover_data_fim_contrato) || "--" }}
              </template>
              <template v-slot:turnover_contrato_ativo>
                {{ item.turnover_contrato_ativo === 1 ? "Ativo" : "Inativo" }}
              </template>
            </TableCell>
            <TableCell>
              <div class="d-flex justify-content-end">
                <Button
                  @clicked="openContractDetailsModal(item)"
                  name="Detalhes"
                  class="ml-3"
                ></Button>
                <Button
                  :disabled="item.turnover_contrato_ativo === 0"
                  @clicked="openFinishContractModal(item)"
                  name="Finalizar Contrato"
                  class="ml-3"
                ></Button>
              </div>
            </TableCell>
          </TableRow>
          <template v-if="userTurnovers.length === 0" v-slot:no-results>
            <NoResults message="Nenhum contrato encontrado" />
          </template>
        </Table>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <!-- CREATE CONTRACT -->
    <Modal v-show="createContractModal" :width="400">
      <template v-slot:header>
        <p class="contract-modal-title">Novo contrato</p>
        <span
          style="cursor: pointer"
          @click="closeCreateContractModal"
          class="material-icons"
          >close</span
        >
      </template>
      <template v-slot:body>
        <form>
          <Input
            label="Nome"
            disabled
            v-model="user.usuario_nome"
            placeholder="Nome"
          />
          <Input
            label="CPF"
            disabled
            v-model="user.usuario_cpf"
            placeholder="CPF"
          />
          <Input
            label="Número do contrato"
            v-model="contractForm.contractNumber"
            placeholder="Número do contrato"
          />
          <Input
            label="Data início *"
            type="date"
            v-model="contractForm.start_date"
            placeholder="Data de início"
          />
        </form>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :disabled="!contractForm.start_date"
            @clicked="createContract"
            class="w-100"
            name="Salvar"
          />
        </div>
      </template>
    </Modal>

    <!-- FINISH CONTRACT -->
    <Modal v-show="finishContractModal" :width="400">
      <template v-slot:header>
        <p class="contract-modal-title">Finalizar contrato</p>
        <span
          style="cursor: pointer"
          @click="closeFinishContractModal"
          class="material-icons"
          >close</span
        >
      </template>
      <template v-slot:body>
        <form>
          <Input
            label="Nome"
            disabled
            v-model="user.usuario_nome"
            placeholder="Nome"
          />
          <Input
            label="Data início"
            disabled
            :value="
              formatDate(finishContractForm.turnover_data_inicio_contrato)
            "
            placeholder="Data início"
          />
          <Input
            label="Data fim"
            type="date"
            v-model="finishContractForm.turnover_data_fim_contrato"
            placeholder="Data fim"
          />
        </form>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :disabled="!finishContractForm.turnover_data_fim_contrato"
            @clicked="finishContractConfirmationModal = true"
            class="w-50"
            name="Finalizar"
          />
        </div>
      </template>
    </Modal>
    <!-- FINISH CONTRACT CONFIRMATION -->
    <Modal v-show="finishContractConfirmationModal" :width="400">
      <template v-slot:header>
        <p class="contract-modal-title">
          Confirmação de Finalização do contrato
        </p>
        <span
          style="cursor: pointer"
          @click="
            () => {
              finishContractConfirmationModal = false;
              finishContractConfirmationString = '';
            }
          "
          class="material-icons"
          >close</span
        >
      </template>
      <template v-slot:body>
        <div style="color: #003366; text-align: center; font-size: 1rem">
          <p>
            Tem certeza que deseja finalizar o contrato de Almir Freitas? A
            finalização é permanente e esta ação não poderá ser desfeita.
          </p>
          <p>Confirme digitando <b>finalizar</b> abaixo</p>
        </div>
        <form @submit="createContract">
          <Input v-model="finishContractConfirmationString" />
        </form>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :disabled="finishContractConfirmationString !== 'finalizar'"
            @clicked="finishContract"
            class="w-50"
            name="Finalizar"
          />
        </div>
      </template>
    </Modal>
    <!-- CONTRACT DETAILS -->
    <Modal class="contract-details-modal" v-show="contractDetailsModal" :width="400">
      <template v-slot:header>
        <p class="contract-modal-title">
          {{ user.usuario_nome }}
        </p>
        <span
          style="cursor: pointer"
          @click="closeContractDetailsModal"
          class="material-icons"
          >close</span
        >
      </template>
      <template v-slot:body>
        <Input label="Nome" disabled v-model="user.usuario_nome" />
        <Input label="CPF" disabled v-model="user.usuario_cpf" />
        <Input label="CNPJ" disabled v-model="user.usuario_cnpj" />
        <Input
          label="Data início"
          disabled
          :value="formatDate(contractDetails.initial_date)"
        />
        <div v-if="user.usuario_contrato_url" class="contract">
          <a target="_blank" :href="user.usuario_contrato_url">Contrato para download</a>
          <span class="material-icons">download</span>
        </div>
        <Input
          label="Data fim"
          disabled
          :value="formatDate(contractDetails.final_date) || '--'"
        />
        <Input
          label="Status"
          disabled
          :value="contractDetails.status === 1 ? 'Ativo' : 'Inativo'"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import services from "../../services";
import { decrypt } from "../../utils/decrypt";
import { successToast, validationToast } from "../../utils/toast";

// import { successToast } from "../../utils/toast";
// import { debounce } from "../../utils/debounce";
import Modal from "../../components/Modal.vue";
// import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import NoResults from "../../components/table/NoResults.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Input from "../../components/form/Input.vue";

const fields = [
  {
    key: "turnover_data_inicio_contrato",
    label: "Data inicio",
    _style: "min-width:80px;",
    sortable: false,
    sortDirection: null,
  },
  {
    key: "turnover_data_fim_contrato",
    label: "Data fim",
    _style: "min-width:80px;",
    sortable: false,
    sortDirection: null,
  },
  {
    key: "turnover_contrato_ativo",
    label: "Status do contrato",
    _style: "min-width:80px;",
    sortable: false,
    sortDirection: null,
  },
];
export default {
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Button,
    // BackArrow,
    Loading,
    Input,
    Modal,
  },
  data() {
    return {
      fields,
      userTurnovers: [],
      createContractModal: false,
      finishContractModal: false,
      hasActiveContract: false,
      contractForm: {
        start_date: null,
        contractNumber: null,
      },
      finishContractForm: {
        turnover_id: null,
        usuario_gestor_termina_contrato_id: null,
        turnover_data_inicio_contrato: null,
        turnover_data_fim_contrato: null,
      },
      contractDetails: {
        cpf: null,
        cnpj: null,
        contractNumber: null,
        initial_date: null,
        final_date: null,
        status: false,
      },
      finishContractConfirmationString: null,
      finishContractConfirmationModal: false,
      contractDetailsModal: false,
      loadingData: false,
      user: {},
      manager: {
        id: null,
        name: null,
      },
    };
  },
  mounted: async function () {
    await this.getContracts();
    await this.requestUserById();
    await this.userData();
  },
  methods: {
    async userData() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      this.manager.id = user.usuario_gestor_id;
      this.manager.name = user.usuario_gestor_nome;
    },

    async createContract() {
      const { errors } = await services.users.createUserTurnover({
        usuario_id: this.user.usuario_id,
        turnover_data_inicio_contrato: this.contractForm.start_date,
        usuario_gestor_id: this.manager.id,
        turnover_numero_contrato: this.contractForm.contractNumber,
      });
      if (!errors) {
        await this.getContracts();
        successToast("Contrato criado com sucesso!");
        this.createContractModal = false;
      } else {
        validationToast("Erro ao criar contrato");
      }
    },
    async finishContract() {
      const { errors } = await services.users.finishUserTurnover({
        turnover_id: this.finishContractForm.turnover_id,
        usuario_gestor_termina_contrato_id: this.manager.id,
        turnover_data_fim_contrato:
          this.finishContractForm.turnover_data_fim_contrato,
      });
      if (!errors) {
        successToast("Contrato finalizado com sucesso!");
      } else {
        validationToast("Erro ao finalizar contrato");
      }
      await this.getContracts();
      this.closeFinishContractModal();
      this.finishContractConfirmationModal = false;
    },
    async requestUserById() {
      const { data, errors } = await services.users.getUserById({
        userId: this.$route.params.id,
      });
      if (!errors) {
        this.user = data;
      }
    },
    async getContracts() {
      this.loadingData = true;
      const response = await services.users.getUserTurnovers(
        this.$route.params.id
      );
      if (response.data) {
        this.userTurnovers = response.data;
        this.userTurnovers.forEach((turnOver) => {
          if (turnOver.turnover_contrato_ativo === 1) {
            this.hasActiveContract = true;
          } else {
            this.hasActiveContract = false;
          }
        });
      }
      this.loadingData = false;
    },
    closeCreateContractModal() {
      this.createContractModal = false;
      this.contractForm = {
        usuario_id: null,
        turnover_data_inicio_contrato: null,
        usuario_gestor_id: null,
        turnover_numero_contrato: null,
      };
    },
    closeFinishContractModal() {
      this.finishContractModal = false;
      this.finishContractForm = {
        turnover_id: null,
        usuario_gestor_termina_contrato_id: null,
        turnover_data_fim_contrato: null,
      };
    },
    closeFinishContractConfirmationModal() {
      this.finishContractConfirmationModal = false;
      this.finishContractForm = {
        turnover_id: null,
        usuario_gestor_termina_contrato_id: null,
        turnover_data_fim_contrato: null,
      };
    },
    openContractDetailsModal(details) {
      this.contractDetails = {
        cpf: details.usuario_cpf,
        cnpj: details.usuario_cnpj,
        contractNumber: details.turnover_numero_contrato,
        initial_date: details.turnover_data_inicio_contrato,
        final_date: details.turnover_data_fim_contrato,
        status: details.turnover_contrato_ativo,
      };
      this.contractDetailsModal = true;
    },
    openFinishContractModal(data) {
      this.finishContractModal = true;
      this.finishContractConfirmationString = "";
      this.finishContractForm = {
        turnover_id: data.turnover_id,
        usuario_gestor_termina_contrato_id: this.user.usuario_gestor_id,
        turnover_data_fim_contrato: data.turnover_data_fim_contrato,
        turnover_data_inicio_contrato: data.turnover_data_inicio_contrato,
      };
    },
    closeContractDetailsModal() {
      this.contractDetailsModal = false;
      this.contractDetails = {
        cpf: null,
        cnpj: null,
        contractNumber: null,
        initial_date: null,
        final_date: null,
        status: false,
      };
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    goToHome() {
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-modal-title {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #003366;
}

.contract-details-modal {
  .contract {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 8px;
    font-size: 1rem;
    margin: 20px 0  10px 0;
    border: 1px solid #9E9E9E;
    border-radius: 12px;
    background-color: #ddd;
    cursor: pointer;
    a {
      color: #003366 ;
      text-decoration: none;
      text-transform: uppercase;
    }
    span {
      background-color: #003366;
    }
    &:hover {
      background-color: white;
    }
  }
}
</style>

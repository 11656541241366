<template>
  <div>
    <div class="print-container bg-white">
      <div v-if="!loadingData">
        <header class="d-flex justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <div class="arrow-delay">
              <back-arrow color="#565E73" />
            </div>

            <div class="page-title ml-3">
              Laudo de Medição
              <p class="print-subtitle">
                {{ cityName.slice(0, -3) }}
              </p>
            </div>
          </div>
          <div class="mr-2">
            <img :src="urlLogo" alt="logo" width="154" />
          </div>
        </header>

        <div class="legal-text mr-2">
          {{ legalText }}
        </div>

        <div v-if="engineers.length > 0 || totals.length > 0" class="mt-3">
          <div class="engineers-title mb-2">Responsável Técnico:</div>
          <div class="d-flex flex-wrap">
            <div
              class="mt-2 mb-2 mr-2"
              v-for="engineer in engineers"
              :key="engineer.engenheiro_id"
            >
              <div class="pr-5 engineer-card">
                <div class="mb-1 engineer-info">
                  {{ engineer.engenheiro_nome }}
                </div>
                <div class="engineer-info">
                  CREA: {{ engineer.engenheiro_crea }} | ART:
                  {{ engineer.engenheiro_art }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="filters">
          <div class="select-month-wrapper mt-4 mb-3">
            <div class="mb-3 engineers-title">Selecione o Mês</div>
            <div class="d-flex">
              <month-picker-input
                :key="defaultMonth"
                date-format="%n"
                :default-month="defaultMonth + 1"
                @input="handleChangeMonth"
                lang="pt"
              ></month-picker-input>
            </div>
          </div>
        </div>

        <div v-if="measurementsTable.length > 0" class="totalization-container">
          <div class="totals-card mr-3">
            <p>Total de áreas com zeladores</p>
            <span> {{ keepingSectorsWithKeepers }}</span>
          </div>
          <div class="totals-card mr-3">
            <p>Áreas com execuções</p>
            <span>
              {{ keepingSectorsWithExecutions }}
            </span>
          </div>
          <div class="totals-card mr-3">
            <p>Avaliação do Monitor</p>
            <span>
              {{ somaMonitor / 100 }}
            </span>
          </div>
          <div class="totals-card mr-3">
            <p>Avaliação do Fiscal</p>
            <span>
              {{ somaFiscal / 100 }}
            </span>
          </div>
        </div>

        <div class="tabs mt-4 mb-4 mr-3">
          <div class="d-flex">
            <div
              :class="`tab ${tab.active ? 'active' : ''}`"
              v-for="(tab, index) in tabs"
              :key="index"
              @click="handleChangeTab(tab.value)"
            >
              {{ tab.label }}
            </div>
          </div>

          <div class="switch-container">
            <div class="d-flex align-items-center mt-2">
              <div
                class="d-flex mt-4 mb-4 ml-1"
                v-if="
                  measurementsSourceOfTruth.length > 0 &&
                  currentTab === 0 &&
                  userType == 1
                "
              >
                <div class="divergent-label">
                  <span>Mostrar avaliações divergentes</span>
                </div>
                <div class="ml-3 mb-1">
                  <BaseSwitch
                    :value="divergentEvaluationsCheck"
                    @input="changeSwitch"
                  />
                </div>
              </div>
              <div @click="print" class="new-icons print-button ml-4">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66665 10.3333H10.3333V13.6666H3.66665V10.3333ZM11.6666 12.3333V8.99992H2.33331V12.3333H0.99998C0.823169 12.3333 0.653599 12.263 0.528575 12.138C0.403551 12.013 0.333313 11.8434 0.333313 11.6666V4.99992C0.333313 4.82311 0.403551 4.65354 0.528575 4.52851C0.653599 4.40349 0.823169 4.33325 0.99998 4.33325H13C13.1768 4.33325 13.3464 4.40349 13.4714 4.52851C13.5964 4.65354 13.6666 4.82311 13.6666 4.99992V11.6666C13.6666 11.8434 13.5964 12.013 13.4714 12.138C13.3464 12.263 13.1768 12.3333 13 12.3333H11.6666ZM2.33331 5.66659V6.99992H4.33331V5.66659H2.33331ZM3.66665 0.333252H10.3333C10.5101 0.333252 10.6797 0.40349 10.8047 0.528514C10.9297 0.653538 11 0.823108 11 0.999919V2.99992H2.99998V0.999919C2.99998 0.823108 3.07022 0.653538 3.19524 0.528514C3.32027 0.40349 3.48984 0.333252 3.66665 0.333252Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <section
          id="content"
          class="mr-3 mt-3"
          v-show="currentTab === 0 && measurementsSourceOfTruth.length > 0"
        >
          <Transition name="bounce">
            <div
              v-if="measurementsTable.length > 0 && divergentEvaluationsCheck"
              class="d-flex mb-4"
            >
              <div class="totals-card mr-3">
                <p>Zeladorias divergentes</p>
                <span>
                  {{ measurementsTable.length }}
                </span>
              </div>
              <div class="totals-card mr-3">
                <p>Avaliação do Monitor divergente</p>
                <span>
                  {{ somaMonitorDivergent / 100 }}
                </span>
              </div>
              <div class="totals-card">
                <p>Avaliação do Fiscal divergente</p>
                <span>
                  {{ somaFiscalDivergent / 100 }}
                </span>
              </div>
            </div>
          </Transition>
          <div v-if="measurements.length > 0">
            <input
              v-model="searchMeasurement"
              class="input-search"
              placeholder="Buscar por área de zeladoria..."
            />
          </div>

          <table
            v-if="measurementsSourceOfTruth.length > 0"
            id="table-measurement"
          >
            <thead>
              <tr id="table-header">
                <th>ÁREA DE ZELADORIA</th>
                <th>
                  <div class="position-relative">
                    <FilterNeighborhood
                      class="modal-filter"
                      v-show="filterNeighborhood"
                      @clean="cleanFilterNeighborhood"
                      @filter="filterNeighborhoods"
                      @close="filterNeighborhood = false"
                    />

                    <span class="ml-4 pl-4">
                      BAIRRO
                      <span
                        class="pointer ml-4"
                        @click="filterNeighborhood = !filterNeighborhood"
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.800293"
                            width="24"
                            height="24"
                            rx="12"
                            fill="#B0B4BD"
                          />
                          <path
                            d="M18.8003 6.66602V7.99935H18.1336L14.1336 13.9993V18.666H11.467V13.9993L7.46696 7.99935H6.80029V6.66602H18.8003Z"
                            fill="#565E73"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </th>
                <th>EXECUÇÕES / FOTOS</th>
                <th>
                  <div class="position-relative">
                    <FilterEvaluations
                      v-if="filterMonitorModal"
                      class="modal-filter"
                      @close="filterMonitorModal = false"
                      @filter="filterByMonitor"
                      @clean="cleanFilterMonitor"
                      :evaluations="monitorEvaluationsFilter"
                    />

                    <span class="ml-4 pl-2">
                      AVALIAÇÃO DO MONITOR

                      <span
                        class="pointer ml-4"
                        @click="filterMonitorModal = !filterMonitorModal"
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.800293"
                            width="24"
                            height="24"
                            rx="12"
                            fill="#B0B4BD"
                          />
                          <path
                            d="M18.8003 6.66602V7.99935H18.1336L14.1336 13.9993V18.666H11.467V13.9993L7.46696 7.99935H6.80029V6.66602H18.8003Z"
                            fill="#565E73"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </th>
                <th>
                  <div class="position-relative">
                    <FilterEvaluations
                      v-if="filterFiscalModal"
                      class="modal-filter"
                      @close="filterFiscalModal = false"
                      @filter="filterByFiscal"
                      @clean="cleanFilterFiscal"
                      :evaluations="fiscalEvaluationsFilter"
                      name="Fiscal"
                    />

                    <span class="ml-4 pl-3"
                      >AVALIAÇÃO DO FISCAL
                      <span
                        class="pointer ml-4"
                        @click="filterFiscalModal = !filterFiscalModal"
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.800293"
                            width="24"
                            height="24"
                            rx="12"
                            fill="#B0B4BD"
                          />
                          <path
                            d="M18.8003 6.66602V7.99935H18.1336L14.1336 13.9993V18.666H11.467V13.9993L7.46696 7.99935H6.80029V6.66602H18.8003Z"
                            fill="#565E73"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tr
              v-for="measurement of measurementsTable"
              :key="measurement.execucao_avaliacao_id"
              :class="measurement.zeladoria_nome"
            >
              <td @click="redirectToIndex(measurement)" class="keeping-label">
                <span class="keeping-sector-name">{{
                  measurement.zeladoria_nome || "-"
                }}</span>
                <svg
                  class="pointer mb-1"
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.76664 2V3.33333H1.43331V10.6667H8.76664V7.33333H10.1V11.3333C10.1 11.5101 10.0297 11.6797 9.90471 11.8047C9.77969 11.9298 9.61012 12 9.43331 12H0.766642C0.589831 12 0.420262 11.9298 0.295238 11.8047C0.170213 11.6797 0.0999756 11.5101 0.0999756 11.3333V2.66667C0.0999756 2.48986 0.170213 2.32029 0.295238 2.19526C0.420262 2.07024 0.589831 2 0.766642 2H4.76664ZM12.1 0V6L9.57064 3.47133L5.57131 7.47133L4.62864 6.52867L8.62798 2.52867L6.09998 0H12.1Z"
                    fill="#00B1EB"
                  />
                </svg>
              </td>
              <td>{{ measurement.bairro_nome || "-" }}</td>
              <td>
                {{
                  measurement.qtd_execucao + " / " + measurement.qtd_fotos ||
                  "-"
                }}
              </td>
              <td>
                {{ measurement.execucao_avaliacao_percentual_final + "%" }}
              </td>
              <td>
                {{
                  measurement.execucao_avaliacao_prefeitura_percentual_final +
                  "%"
                }}
              </td>
            </tr>
          </table>
          <NoResults v-else />
        </section>

        <section
          class="no-results"
          v-show="currentTab === 0 && measurementsSourceOfTruth.length === 0"
        >
          Nenhuma avaliação foi realizada neste período.
        </section>

        <section v-show="currentTab === 1" class="mr-3">
          <div v-if="totals.length > 0">
            <input
              type="text"
              v-model="searchAnalytics"
              placeholder="Buscar por área de zeladoria..."
              class="input-search"
            />

            <AnalyticsTable
              ref="analytics"
              :totals="totals"
              :measurements="measurements"
              :activities="activities"
              :searchAnalytics="searchAnalytics"
              @clicked="redirectToIndex"
            />
          </div>
        </section>
      </div>
      <Loading v-else />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import services from "../../services";
import { infoToast } from "../../utils/toast";
import { decrypt } from "../../utils/decrypt";

import AnalyticsTable from "./components/AnalyticsTable.vue";
import FilterNeighborhood from "./components/FilterNeighborhood.vue";
import FilterEvaluations from "./components/FilterEvaluations.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import NoResults from "../../components/table/NoResults.vue";
import BackArrow from "../../components/BackArrow.vue";
import BaseSwitch from "../../components/BaseSwitch.vue";
import { MonthPickerInput } from "vue-month-picker";

const initialMonitor = [
  {
    id: 100,
    check: false,
  },
  {
    id: 75,
    check: false,
  },
  {
    id: 50,
    check: false,
  },
  {
    id: 25,
    check: false,
  },
  {
    id: 0,
    check: false,
  },
];

const initialFiscal = [
  {
    id: 100,
    check: false,
  },
  {
    id: 75,
    check: false,
  },
  {
    id: 50,
    check: false,
  },
  {
    id: 25,
    check: false,
  },
  {
    id: 0,
    check: false,
  },
];

export default {
  name: "MonthReport",
  components: {
    Loading,
    BaseSwitch,
    NoResults,
    FilterEvaluations,
    BackArrow,
    AnalyticsTable,
    FilterNeighborhood,
    MonthPickerInput,
  },
  watch: {
    searchMeasurement: {
      handler: function (val) {
        this.filterTable(val);
      },
      immediate: true,
    },
    filter: {
      handler: async function (val) {
        this.measurementsTable = this.measurementsSourceOfTruth;
        this.allMeasurements = this.measurementsSourceOfTruth;
        if (val.neighborhood.length) {
          const result = this.measurementsTable.filter((item) => {
            return val.neighborhood.some((neighborhood) => {
              if (item.bairro_id === neighborhood) {
                return item;
              }
            });
          });
          this.measurementsTable = result;
          this.allMeasurements = result;
        }
        if (val.monitor.length) {
          const result = this.measurementsTable.filter((item) => {
            return val.monitor.some((evaluation) => {
              if (item.execucao_avaliacao_percentual_final === evaluation) {
                return item;
              }
            });
          });
          this.measurementsTable = result;
          this.allMeasurements = result;
        }
        if (val.fiscal.length) {
          const result = this.measurementsTable.filter((item) => {
            return val.fiscal.some((evaluation) => {
              if (
                item.execucao_avaliacao_prefeitura_percentual_final ===
                evaluation
              ) {
                return item;
              }
            });
          });
          this.measurementsTable = result;
          this.allMeasurements = result;
        }
        if (this.divergentEvaluationsCheck) {
          this.filterByDivergentEvaluations();
        }
        await this.getKeepingSectorsWithKeepers();
        this.calculateSums();
      },
      deep: true,
    },
  },
  mounted: async function () {
    if (!this.range) {
      infoToast("Nenhum período foi selecionado");
    }
    this.$store.dispatch("global/setUser").then(() => {
      const user = this.$store.state.global.user;
      this.userType = user.usuario_gestor_tipo_id;
    });
    await this.initAllData();
  },
  created: function () {
    const currentDate = new Date();
    this.defaultMonth = currentDate.getMonth();
  },
  data() {
    return {
      defaultMonth: null,
      loadingData: true,
      cityName: "",
      measurementsTable: [],
      sortOrderKeepingSector: "asc",
      sortOrderNeighborhood: "asc",
      userType: "",
      sortOrderUser: "asc",
      divergentEvaluationsCheck: false,
      keepingSectorsWithKeepers: 0,
      searchAnalytics: "",
      keepingSectorsWithExecutions: 0,
      allMeasurements: [],
      searchMeasurement: "",
      measurements: [],
      measurementsSourceOfTruth: [],
      monitorEvaluationsFilter: initialMonitor,
      fiscalEvaluationsFilter: initialFiscal,
      selectedMonth: moment().month(),
      legalText: "Lorem ipsum",
      engineers: [],
      cycles: [],
      filterMonitorModal: false,
      filterFiscalModal: false,
      currentCycle: {},
      totals: [],
      activities: [],
      currentTab: 0,
      filterNeighborhood: false,
      range: {
        start: null,
        end: null,
      },
      tabs: [
        { active: true, label: "Resumo", value: 0, show: false },
        {
          active: false,
          label: "Analítico",
          value: 1,
          show: true,
        },
      ],
      somaMonitor: 0,
      somaFiscal: 0,
      somaMonitorDivergent: 0,
      somaFiscalDivergent: 0,
      filter: {
        neighborhood: [],
        monitor: [],
        fiscal: [],
      },
    };
  },
  methods: {
    async loggedUser() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      if (user) {
        this.cityName = user.usuario_cidade;
        this.urlLogo = user.cidade_logo;
      }
    },

    async initCounters() {
      if (this.range) {
        const { data, errors } =
          await services.keepingSectors.relatoryKeepingSectors({
            data_inicio: this.range.start,
            data_fim: this.range.end,
            bairro_id: null,
          });
        if (!errors) {
          this.measurements = data;
        }
      }
    },

    filterTable(val) {
      const rows = document.querySelectorAll("#table-measurement tr");

      rows.forEach((row) => {
        const cells = row.querySelectorAll("td.keeping-label");
        let found = false;
        cells.forEach((cell) => {
          if (cell.textContent.toLowerCase().indexOf(val.toLowerCase()) > -1) {
            found = true;
          }
        });
        if (found) {
          row.style.display = "table-row";
        } else {
          row.style.display = "none";
        }

        if (row.id === "table-header") {
          row.style.display = "table-row";
        }
      });
    },

    async initAllData() {
      await this.loggedUser();
      await this.getLegalText();
      await this.getActivities();
      this.getActualRange();

      await this.requestExecutionEngineers();
      await this.requestReport();

      this.loadingData = false;
    },

    async getActivities() {
      await services.activities.getActivities().then(({ data }) => {
        data.forEach((item) => {
          this.activities.push({
            id: item.atividade_id,
            label: item.atividade_nome,
          });
        });
      });
    },

    getActualRange() {
      const actualMonth = moment().month();
      const actualYear = moment().year();
      const start = moment()
        .month(actualMonth)
        .year(actualYear)
        .startOf("month")
        .toISOString();
      const end = moment()
        .month(actualMonth)
        .year(actualYear)
        .endOf("month")
        .toISOString();
      this.range = {
        start,
        end,
      };
    },

    async getKeepingSectorsWithKeepers() {
      const response =
        await services.executions.getTotalKeepingSectorsWithUsers(
          this.range.start,
          []
        );
      if (response) {
        this.keepingSectorsWithKeepers =
          response.dataInterval.total_zeladorias_com_zeladores;
        this.keepingSectorsWithExecutions =
          response.dataInterval.total_zeladorias_com_execucoes;
      }
    },

    changeSwitch(evaluation) {
      this.divergentEvaluationsCheck = evaluation;
      if (evaluation) {
        this.filterByDivergentEvaluations();
      } else {
        this.measurementsTable = this.allMeasurements;
      }
      this.calculateSums();
      this.getKeepingSectorsWithKeepers();
    },

    calculateSums() {
      this.somaMonitor = 0;
      this.somaFiscal = 0;
      this.somaMonitorDivergent = 0;
      this.somaFiscalDivergent = 0;

      this.allMeasurements.forEach((measurement) => {
        this.somaMonitor += measurement.execucao_avaliacao_percentual_final;

        this.somaFiscal +=
          measurement.execucao_avaliacao_prefeitura_percentual_final;
      });

      this.measurementsTable.forEach((measurement) => {
        this.somaMonitorDivergent +=
          measurement.execucao_avaliacao_percentual_final;

        this.somaFiscalDivergent +=
          measurement.execucao_avaliacao_prefeitura_percentual_final;
      });
    },

    filterByDivergentEvaluations() {
      this.measurementsTable = this.measurementsTable.filter(
        (measurement) =>
          measurement.execucao_avaliacao_percentual_final !==
          measurement.execucao_avaliacao_prefeitura_percentual_final
      );
    },

    cleanFilterNeighborhood() {
      this.filterNeighborhood = false;
      this.filter.neighborhood = [];
    },
    cleanFilterMonitor() {
      this.filterMonitorModal = false;
      this.filter.monitor = [];
      this.monitorEvaluationsFilter = this.monitorEvaluationsFilter.map(
        (evaluation) => {
          evaluation.check = false;
          return evaluation;
        }
      );
    },
    cleanFilterFiscal() {
      this.filterFiscalModal = false;
      this.filter.fiscal = [];
      this.fiscalEvaluationsFilter = this.fiscalEvaluationsFilter.map(
        (evaluation) => {
          evaluation.check = false;
          return evaluation;
        }
      );
    },

    filterNeighborhoods(neighborhoods) {
      if (neighborhoods.length) {
        this.filter.neighborhood = neighborhoods.map((item) => item.bairro_id);
      }
      this.filterNeighborhood = false;
    },

    filterByMonitor(evaluations) {
      if (evaluations.length) {
        this.filter.monitor = evaluations.map((item) => item);
      }
      this.filterMonitorModal = false;
    },

    filterByFiscal(evaluations) {
      if (evaluations.length) {
        this.filter.fiscal = evaluations.map((item) => item);
      }
      this.filterFiscalModal = false;
    },

    async handleChangeTab(value) {
      this.currentTab = value;
      this.tabs.map((tab) => {
        if (tab.value === value) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
    },

    async handleChangeMonth(ev) {
      this.measurementsTable = [];
      this.totals = [];
      this.divergentEvaluationsCheck = false;

      this.range.start = ev.from.toISOString().slice(0, -5);
      this.range.end = ev.to.toISOString().slice(0, -5);

      await this.requestExecutionEngineers();
      await this.requestReport();

      this.defaultMonth = new Date(this.range.start).getMonth();
      this.loadingData = false;
    },

    async getLegalText() {
      const { data, errors } = await services.importData.getLegalText(202);
      if (!errors) {
        this.legalText = data.config_valor;
      }
    },

    async requestExecutionEngineers() {
      if (this.range) {
        const { data, errors } =
          await services.executions.getExecutionsEngineers({
            data_inicio: this.range.start,
            data_fim: this.range.end,
          });
        if (!errors) this.engineers = data;
      }
    },

    async requestReport() {
      this.loadingData = true;

      const { dataInterval } = await services.executions.getMonthReport({
        execucao_avaliacao_inicio: this.range.start,
        execucao_avaliacao_fim: this.range.end,
      });

      this.measurementsTable = dataInterval.map((data) => {
        return {
          ...data,
          execucao_avaliacao_prefeitura_percentual_final:
            data.execucao_avaliacao_prefeitura_percentual_final,
          execucao_avaliacao_percentual_final:
            data.execucao_avaliacao_percentual_final,
          zelador:
            data.zelador && data.zelador.length
              ? data.zelador[0].usuario_nome
              : "-",
        };
      });

      const { data } =
        await services.keepingSectors.relatoryKeepingSectorsTotal({
          data_inicio: this.range.start,
          data_fim: this.range.end,
          bairro_id: null,
        });

      this.totals = data;

      await this.initCounters();
      await this.getKeepingSectorsWithKeepers();

      this.loadingData = false;
      this.allMeasurements = this.measurementsTable;
      this.measurementsSourceOfTruth = this.measurementsTable;

      this.calculateSums();

      setTimeout(() => {
        this.$refs.analytics.appendSubheaders();
        this.$refs.analytics.appendData();
      }, 100);
    },

    print() {
      setTimeout(function () {
        window.print();
      }, 0);
    },

    redirectToIndex(measurement) {
      if (!measurement.bairro_id) {
        const neighborhoodId = this.measurementsTable.find(
          (item) => item.zeladoria_id === measurement.zeladoria_id
        ).bairro_id;

        measurement.bairro_id = neighborhoodId;
      }

      localStorage.setItem("initialDate", this.range.start.slice(0, 10));
      localStorage.setItem("finalDate", this.range.end.slice(0, 10));

      let route = this.$router.resolve({
        path: `/dashboard/${measurement.bairro_id}/${measurement.zeladoria_id}`,
      });
      window.open(route.href, "_blank");
    },

    goToHome() {
      this.$router.push({
        name: "Laudos",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
  margin-top: 4px;
}

@media print {
  @page {
    size: A4 landscape !important;
    page-break-inside: avoid;
  }

  table {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .divergent-label {
    display: none;
  }

  .table-header-shift,
  .neighborhood-label-shift {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .tabs {
    display: none !important;
  }

  .form-wrapper {
    display: none;
  }

  .pointer {
    display: none !important;
  }

  .print-button {
    display: none;
  }

  .input-search {
    display: none;
  }

  .back-arrow {
    display: none !important;
  }

  .totals-card {
    margin: 10px 0;
  }

  .current-cycle {
    display: block !important;
  }
}

.print-container {
  padding: 32px 36px;
  min-height: 100vh;
}

.print-button {
  cursor: pointer;
  margin-bottom: 11px;
}

.neighborhood-label-shift {
  margin-left: 110px;
}

table,
td,
th {
  border: 1px solid #e4e4e4;
  text-align: center;
}

th,
td {
  height: 48px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-size: 12px;
  padding: 12px;
  line-height: 156%;
}

.print-subtitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
  margin-top: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.arrow-delay {
  margin-bottom: 40px;
}

tr:nth-child(odd) {
  background-color: #f3f4f5;
}

td {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: center;
  color: #838998;
}

thead > tr > th {
  background: #565e73;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.no-results {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  text-align: center;
  color: #838998;
  margin-top: 16px;
}

.legal-text {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 32px;
}

.engineers-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

.input-search {
  width: 400px;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 16px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 32px;
  background: url("../../assets/img/search.svg") no-repeat right 16px center;

  &::placeholder {
    color: #b0b4bd;
  }

  &:focus {
    outline: none;
    border: 1px solid #61b2e5;
  }
}

.engineer-card {
  border: 1px solid #dddee3;
  border-radius: 16px;
  padding: 16px 24px;

  .engineer-info {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 13px;
    color: #747474;
  }
}

.cycles {
  display: flex;
  align-items: center;
}

.cycle-input {
  label {
    margin-left: 5px;
  }
}

.totals-container {
  display: flex;
  flex-wrap: wrap;

  .totals-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    line-height: 132%;
    color: #838998;
    width: 100%;
    padding: 4px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .totals-card-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 220px;
    border: 1px solid #dddee3;
    text-align: center;
  }

  .totals-label {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    line-height: 132%;
    color: #565e73;
    padding: 4px;
    border-top: 1px solid #dddee3;
  }

  .totals-counter {
    font-family: "OpenSans-Bold";
    font-weight: 400;
    font-size: 18px;
    line-height: 132%;
    color: #838998;
    background-color: #d2d2d2;
    padding: 4px;
  }
}

.keeping-sector-name {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #00b1eb;
  margin-right: 4px;
}

.totalization-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.totals-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: auto;
  padding: 19px 20px;
  border: 1px solid #c6c9d0;
  border-radius: 16px;

  p {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.014em;
    color: #565e73;
  }

  span {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #29334e;
  }
}

.modal-filter {
  position: absolute;
  top: -60px;
}

.date-label-input {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #131e3b;
}

.divergent-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 162%;
  text-transform: uppercase;
  color: #838998;
}

.current-cycle {
  display: none;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  line-height: 132%;
  color: #003366;
  margin: 10px 0;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.switch-container {
  position: absolute;
  right: 0;
}

.tab {
  display: flex;
  flex-direction: row;
  padding: 4px 2px 8px 2px;
  margin-right: 24px;
  cursor: pointer;
  text-align: left;
  color: #838998;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #131e3b;

  &:hover {
    background: #eee;
  }
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}

.bounce-leave-active {
  animation: bounce-in 0.6s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
</style>

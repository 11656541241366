<template>
  <div>
    <div class="print-container bg-white">
      <header class="d-flex justify-content-between align-items-center mb-4">
        <div>
          <div class="d-flex align-items-center">
            <div>
              <div class="pr-2 pb-5">
                <span @click="goToHome" class="pointer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.828 6.99992H16V8.99992H3.828L9.192 14.3639L7.778 15.7779L0 7.99992L7.778 0.221924L9.192 1.63592L3.828 6.99992Z"
                      fill="#565E73"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="ml-2 d-flex flex-column align-items-start">
              <h3 class="print-title">{{ $t("accessReport") }}</h3>
              <p class="print-subtitle">
                {{ cityName.slice(0, -3) }}
              </p>
            </div>
          </div>
        </div>
        <div class="">
          <img :src="urlLogo" alt="logo" width="220" />
        </div>
      </header>

      <h5 class="form-title mb-4">Filtrar por data específica</h5>
      <div class="d-flex align-items-center">
        <form class="d-flex align-center form" @submit="requestReport">
          <span
            @click="calendarModal = !calendarModal"
            class="new-icons pointer btn-relatory mr-3 mb-1"
          >
            <svg
              width="14"
              height="14"
              viewBox="-1 1 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                fill="white"
              />
            </svg>
          </span>
          <div class="absolute-box w-50" v-if="calendarModal">
            <v-date-picker
              v-model="dates"
              is-range
              @input="calendarModal = false"
            />
          </div>
          <DateLabel
            :name="formatDates()"
            class="no-pointer mr-3"
            v-if="dates.start && dates.end"
          />

          <Button
            type="primary"
            size="small"
            :width="400"
            class="btn-position generate-report"
            name="Gerar Relatório"
            :disabled="loadingData"
          />
        </form>

        <span class="new-icons pointer btn-relatory ml-3" @click="print">
          <svg
            width="17"
            height="17"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.66659 10.334H10.3333V13.6673H3.66659V10.334ZM11.6666 12.334V9.00065H2.33325V12.334H0.999919C0.823108 12.334 0.653538 12.2637 0.528514 12.1387C0.40349 12.0137 0.333252 11.8441 0.333252 11.6673V5.00065C0.333252 4.82384 0.40349 4.65427 0.528514 4.52925C0.653538 4.40422 0.823108 4.33398 0.999919 4.33398H12.9999C13.1767 4.33398 13.3463 4.40422 13.4713 4.52925C13.5963 4.65427 13.6666 4.82384 13.6666 5.00065V11.6673C13.6666 11.8441 13.5963 12.0137 13.4713 12.1387C13.3463 12.2637 13.1767 12.334 12.9999 12.334H11.6666ZM2.33325 5.66732V7.00065H4.33325V5.66732H2.33325ZM3.66659 0.333984H10.3333C10.5101 0.333984 10.6796 0.404222 10.8047 0.529246C10.9297 0.654271 10.9999 0.82384 10.9999 1.00065V3.00065H2.99992V1.00065C2.99992 0.82384 3.07016 0.654271 3.19518 0.529246C3.32021 0.404222 3.48977 0.333984 3.66659 0.333984Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
      <div class="flex" v-if="!loadingData && usersCount > 0">
        <div class="totalCard">
          <div class="icon">
            <svg
              width="31"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 18C22.6522 18 25.1957 19.0536 27.0711 20.9289C28.9464 22.8043 30 25.3478 30 28V40H10V28C10 25.3478 11.0536 22.8043 12.9289 20.9289C14.8043 19.0536 17.3478 18 20 18ZM6.576 24.012C6.25772 25.0856 6.0697 26.1935 6.016 27.312L6 28V40H1.82634e-07V31C-0.000393614 29.2752 0.636057 27.6109 1.78727 26.3264C2.93848 25.042 4.5234 24.2278 6.238 24.04L6.578 24.012H6.576ZM33.424 24.012C35.2038 24.1205 36.8752 24.9039 38.0972 26.2025C39.3192 27.501 39.9997 29.2169 40 31V40H34V28C34 26.614 33.8 25.276 33.424 24.012ZM7 12C8.32608 12 9.59785 12.5268 10.5355 13.4645C11.4732 14.4022 12 15.6739 12 17C12 18.3261 11.4732 19.5979 10.5355 20.5355C9.59785 21.4732 8.32608 22 7 22C5.67392 22 4.40215 21.4732 3.46447 20.5355C2.52678 19.5979 2 18.3261 2 17C2 15.6739 2.52678 14.4022 3.46447 13.4645C4.40215 12.5268 5.67392 12 7 12ZM33 12C34.3261 12 35.5979 12.5268 36.5355 13.4645C37.4732 14.4022 38 15.6739 38 17C38 18.3261 37.4732 19.5979 36.5355 20.5355C35.5979 21.4732 34.3261 22 33 22C31.6739 22 30.4022 21.4732 29.4645 20.5355C28.5268 19.5979 28 18.3261 28 17C28 15.6739 28.5268 14.4022 29.4645 13.4645C30.4022 12.5268 31.6739 12 33 12ZM20 0C22.1217 0 24.1566 0.842855 25.6569 2.34315C27.1571 3.84344 28 5.87827 28 8C28 10.1217 27.1571 12.1566 25.6569 13.6569C24.1566 15.1571 22.1217 16 20 16C17.8783 16 15.8434 15.1571 14.3431 13.6569C12.8429 12.1566 12 10.1217 12 8C12 5.87827 12.8429 3.84344 14.3431 2.34315C15.8434 0.842855 17.8783 0 20 0Z"
                fill="#003063"
              />
            </svg>
          </div>
          <div class="text">
            <p class="heading-3 neutral-700 title">
              {{ $t("users") }}
            </p>
            <h3 class="heading-1 neutral-950 value">{{ usersCount }}</h3>
          </div>
        </div>

      </div>
      <section id="content" class="mt-5">
        <div v-if="!loadingData">
          <div class="current-dates">
            Período selecionado: {{ formatDate(dates.start) }} -
            {{ formatDate(dates.end) }}
          </div>
          <table v-if="contracts.length > 0">
            <thead>
              <tr>
                <th>
                  Zelador
                  <span
                    class="pointer pl-2 pb-2"
                    @click="
                      sortBy('usuario_nome', sortByUser),
                        (sortByUser = sortByUser === 'asc' ? 'desc' : 'asc')
                    "
                    ><svg
                      :style="{
                        transform: sortByUser === 'asc' ? 'rotate(180deg)' : '',
                      }"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                  ></span>
                </th>
                <th>Telefone</th>
                <th>CPF</th>
                <th>Bairro</th>
                <th>Zeladoria</th>

              </tr>
            </thead>
            <tr v-for="contract of contracts" :key="Math.random() + contract">
              <td>{{ contract.usuario_nome || "--" }}</td>
              <td>{{ formataTelefone(contract.usuario_telefone) || "--" }}</td>
              <td>{{ formataCPF(contract.usuario_cpf) || "CPF" }}</td>
              <td>{{ contract.bairro_nome || "--" }}</td>
              <td>{{ contract.zeladoria_nome || "--" }}</td>
            </tr>
          </table>
          <NoResults v-else />
        </div>
        <Loading v-else />
      </section>
    </div>
  </div>
</template>

<script>
import services from "../../services";
import NoResults from "../../components/table/NoResults.vue";
import DateLabel from "../../components/DateLabel.vue";
import Button from "../../components/Button.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import { decrypt } from "../../utils/decrypt";
import { dateToText } from "../../utils/date";

export default {
  data() {
    return {
      loadingData: false,
      cityName: "",
      urlLogo: "",
      calendarModal: false,
      sortByUser: "asc",
      sortByInitialDate: "asc",
      sortByFinalDate: "asc",
      sortByStatus: "asc",
      engineers: [],
      contracts: [],
      dates: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      requestApi: false,
    };
  },
  components: {
    NoResults,
    Button,
    DateLabel,
    Loading,
  },
  mounted: async function () {
    await this.loggedUser();
    await this.requestReport();
  },
  created() {
    window.addEventListener("click", (e) => {
      if (
        e.target.id !== "calendar" &&
        e.target.className.toString() !== "[object SVGAnimatedString]"
      ) {
        this.calendarInitial = false;
        this.calendarFinal = false;
      }
    });
  },
  computed: {
    usersCount() {
      return this.contracts.length;
    },
  },
  methods: {
    formataTelefone(telefone) {
    if (!telefone) return "--";

    telefone = telefone.replace(/[^\d]/g, ""); // Remove tudo que não é número

    if (telefone.length === 11) {
      // Formato (XX) XXXXX-XXXX
      return telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (telefone.length === 10) {
      // Formato (XX) XXXX-XXXX
      return telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }

    return telefone;
  },
    formataCPF(cpf) {
      if (!cpf) return "--";

      if (cpf.length === 11) {
        cpf = cpf.replace(/[^\d]/g, "");
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }

      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    async loggedUser() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      if (user) {
        this.cityName = user.usuario_cidade;
        this.urlLogo = user.cidade_logo;
      }
    },
    formatDates() {
      if (this.dates.start && this.dates.end) {
        return `${dateToText(this.dates.start.toISOString())} - ${dateToText(
          this.dates.end.toISOString()
        )}`;
      }
    },
    async requestReport(ev) {
      this.loadingData = true;
      this.calendarFinal = false;
      this.calendarInitial = false;
      if (ev) {
        ev.preventDefault();
      }
      if (this.dates) {
      await this.requestExecutionEngineers();
      const { data, errors } = await services.users.getAccessRelatory({
        data_inicio: this.dates.start,
        data_fim: this.dates.end,
      });
        if (!errors) {
          this.contracts = data;
        }
      }
      this.loadingData = false;
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    sortBy(key, order) {
      this.contracts.sort((a, b) => {
        if (a[key] < b[key]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    },
    convertDate(date) {
      if (date) {
        return date
          .toISOString()
          .slice(0, -5)
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      return "";
    },
    async requestExecutionEngineers() {
      if (this.range) {
        const { data, errors } =
          await services.executions.getExecutionsEngineers({
            data_inicio: this.dates.start,
            data_fim: this.dates.end,
          });
        if (!errors) this.engineers = data;
      }
    },
    goToHome(ev) {
      ev.preventDefault();
      this.$router.go(-1);
    },
    print() {
      setTimeout(function () {
        window.print();
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .print-button {
    display: none;
  }
  .generate-report {
    display: none;
  }
  .pointer {
    display: none;
  }
  .form-wrapper {
    display: none !important;
  }
  .form-title {
    display: none !important;
  }
  .back-arrow {
    display: none !important;
  }
  .current-dates {
    display: block !important;
  }
  .icon {
      background: none;
      height: 40px;
      svg {
        path {
          fill: black;
        }
      }
    }
  .totalCard {
    color: black;
    margin-top: 2rem !important;
  }
}

.print-container {
  padding: 32px;
  min-height: 100vh;
}

.form-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

.print-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
}

.print-subtitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 136%;
  color: #565e73;
}

.input-date-custom {
  width: 100%;
  min-width: 160px;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 10px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.btn-position {
  margin-top: 3px;
}

.btn-relatory {
  width: 32px;
  height: 32px;
}

.form-wrapper {
  display: flex;
  align-items: center;
}

table,
td,
th {
  border: 1px solid #e4e4e4;
  text-align: center;
  color: #838998;
}

th,
td {
  height: 33px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-size: 16px;
  padding: 12px;
}

th {
  background-color: #838998;
  font-family: "OpenSans-Regular";
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 16px;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr > .date {
  background-color: #003366;
  color: #fff;
  font-family: "OpenSans-Bold";
  font-size: 14px;
}

tr:nth-child(odd) {
  background-color: #f3f4f5;
}

td {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: center;
  color: #838998;
}

.legal-text {
  color: #747474;
}
.engineers {
  color: #747474;
}

.form {
  position: relative;

  .print-button {
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.current-dates {
  display: none;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 11px;
  line-height: 145%;
  color: #747474;
  margin: 10px 16px;
}

.flex {
  display: flex;
  gap: 20px;
}

.totalCard {
  margin-top: 1rem;
  $radius: 16px;
  border-radius: $radius;
  padding: 6px 12px;
  min-width: 150px;
  border: 2px solid lightgray;
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 18px;

  .icon {
    background: var(--blue-100);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: calc($radius / 1);
    svg {
      height: 60%;
    }
  }

  p {
    font-weight: 400;
    margin-bottom: 5px !important;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 5px !important;
    }
  }
</style>

<template>
  <div>
    <Transition>
      <div v-if="showSidebar" class="sidebar sidebar-toggler">
        <Sidebar @clicked="showSidebar = !showSidebar" />
      </div>
    </Transition>

    <div style="overflow-x: hidden">
      <header class="c-header c-header-light px-3 position-relative">
        <button
          class="c-header-toggler sidebar-toggler"
          type="button"
          @click="showSidebar = !showSidebar"
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z"
              fill="#003366"
            />
          </svg>
        </button>
        <div class="d-flex align-items-center position-welcome pl-1"></div>
        <span class="pointer py-3 brand-position" @click="homeRoute"
          ><img :src="urlLogo" alt="logo" width="120"
        /></span>
      </header>

      <CRow
        v-if="!loadingData || firstLoading"
        class="d-flex flex-md-row bg-white"
      >
        <CCol
          xl="4"
          class="order-2 order-md-last mt-2 pl-4 asideRelatory"
          v-if="fullscreenMap"
        >
          <RelatoryCity
            v-if="stepMap === 0"
            :checkAllCity="checkAll"
            :show="fullscreenMap"
            :dates="[startDate, endDate]"
            :lastDaysButtonLabel="lastDaysButtonLabel"
            @filter-by-tag="filterTag"
            :relatoryCity="relatoryCity"
            @request-activity-data="requestActivityData"
            @desactivate-pins="desactivatePins"
            @filter-by-date="filterByDate"
          />
          <RelatoryNeighborhood
            v-if="stepMap === 1"
            :checkAllNeighborhood="checkAll"
            :relatoryCity="relatoryCity"
            :dates="[startDate, endDate]"
            @back="backToCityView"
            :lastDaysButtonLabel="lastDaysButtonLabel"
            :show="fullscreenMap"
            @filter-by-tag="filterTag"
            :relatoryNeighborhood="relatoryNeighborhood"
            @request-activity-data="requestActivityData"
            @desactivate-pins="desactivatePins"
            @filter-by-date="filterByDate"
          />
          <RelatoryKeepingSector
            ref="keepingSector"
            v-if="stepMap === 2"
            @back="backToNeighborhoodView"
            :lastDaysButtonLabel="lastDaysButtonLabel"
            :activities="activities"
            :dates="[startDate, endDate]"
            :activeActivitiesFilter="activeActivitiesFilter"
            :stars-filter="starsFilter"
            @hide-photos="hidePhotos"
            @markers="syncMarker"
            @heatmap="syncHeatmap"
            @show-photos="showPhotos"
            @filter-by-activity="filterActivity"
            @filter-by-stars="filterStars"
            :show="fullscreenMap"
            :relatoryNeighborhood="relatoryNeighborhood"
            :relatoryCity="relatoryCity"
            :relatoryKeepingSector="relatoryKeepingSector"
            @filter-by-date="filterByDate"
            @filter-by-tag="filterPinsByTag"
          />
        </CCol>

        <CCol
          :xl="!fullscreenMap ? 12 : 8"
          class="order-1 order-xl-last breath-map"
          :class="!fullscreenMap ? 'fullscreenMapOpen' : 'fullscreenMapClose'"
        >
          <GoogleMaps
            ref="childMap"
            :stepMap="stepMap"
            @click-pin="pinClicked"
            @click-pin-research="pinClickedResearch"
            :dates="startDate + endDate"
            :tracking="tracking"
            :pins="pins"
            :polygons="polygons"
            :bounds="bounds"
            :keepingSectorId="relatoryKeepingSector.keepingSectorId"
            @change-step="changeStepMap"
          />

          <div
            class="map-options p-0 ml-0"
            :class="!fullscreenMap && 'shift-map-options'"
          >
            <button
              class="fullscreen-map"
              @click="fullscreenMap = !fullscreenMap"
            >
              <span class="new-icons pointer m-0">
                <svg
                  width="16"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.13664 4L5.12251 2.05307L5.24997 1.9281L5.12251 1.80314L4.18251 0.881573L4.06013 0.761594L3.93762 0.88144L0.877624 3.8749L0.749887 3.99986L0.877488 4.12496L3.93749 7.12496L4.06043 7.2455L4.18295 7.12453L5.12295 6.19643L5.24953 6.07146L5.12251 5.94693L3.13664 4ZM10.8634 4L8.87749 5.94693L8.75003 6.0719L8.87749 6.19686L9.81749 7.11843L9.93987 7.23841L10.0624 7.11856L13.1224 4.1251L13.2501 4.00014L13.1225 3.87504L10.0625 0.875038L9.93957 0.754503L9.81705 0.875471L8.87705 1.80358L8.75048 1.92855L8.87749 2.05307L10.8634 4Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.35"
                  />
                </svg>
              </span>
            </button>
          </div>
        </CCol>
      </CRow>

      <div v-else style="height: calc(100vh - 66px)" class="bg-white pb-4">
        <div
          class="d-flex h-100 w-100 justify-content-center align-items-center mb-4 pb-4"
        >
          <img
            :src="require(`@/assets/img/loading.gif`)"
            class="mb-4 pb-4"
            alt="AMA carregando"
            width="150"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import RelatoryCity from "../../components/IndexComponents/RelatoryCity.vue";
import GoogleMaps from "../../components/IndexComponents/GoogleMaps.vue";
import RelatoryNeighborhood from "../../components/IndexComponents/RelatoryNeighborhood.vue";
import RelatoryKeepingSector from "../../components/IndexComponents/RelatoryKeepingSector.vue";

import services from "../../services";
import { actualMonth, dateToText } from "../../utils/date";
import { infoToast, validationToast } from "../../utils/toast";
import { toIsoString } from "../../utils/timeZone";

import { decrypt } from "../../utils/decrypt";
import { formatForGoogleMaps, geoJsonToMaps } from "../../utils/geoMaps";
import { generateColorByIndex } from "../../utils/color";

export default {
  name: "MapaDashboard",
  components: {
    RelatoryCity,
    RelatoryNeighborhood,
    RelatoryKeepingSector,
    Sidebar,
    GoogleMaps,
  },
  data() {
    return {
      stepMap: 0,
      polygons: [],
      bounds: [],
      pins: [],
      checkAll: false,
      showSidebar: false,
      activities: [],
      tracking: [],
      fullscreenMap: true,
      circlesInfo: [],
      loadingData: true,
      intervals: null,
      relatoryCity: {
        keepingSectorCount: 0,
        percentual: 0,
        activities: [],
        activitiesCount: 0,
        activityCount: 0,
        quantidade_foto: 0,
      },
      relatoryNeighborhood: {
        keepingSectorCount: 0,
        percentual: 0,
        activities: [],
        activitiesCount: 0,
        neighborhoodName: "",
        neighborhoodId: 0,
        activityCount: 0,
        quantidade_foto: 0,
        calledOnce: false,
      },
      relatoryKeepingSector: {
        totalExecutionTime: 0,
        neighborhoodName: "",
        percentual: 0,
        neighborhoodId: 0,
        activities: [],
        keepingSectorName: "",
        keepingSectorId: 0,
        activityCount: 0,
        activitiesCount: 0,
        executions: [],
        quantidade_foto: 0,
        zeladoria_incluida: 1,
      },
      tags: [],
      selectedTags: [],
      startDate: null,
      endDate: null,
      photoCounter: 1,
      firstLoading: true,
      urlLogo: "",
      lastDaysButtonLabel: actualMonth(),
      showHeatmap: false,
      showMarkers: true,
      activeActivitiesFilter: [],
      starsFilter: [],
    };
  },
  watch: {
    async startDate() {
      if (this.endDate !== null && !this.searchStoreKeepingSector) {
        if (this.relatoryKeepingSector.keepingSectorId) {
          this.loadingData = true
          await this.requestExecutionsCount();
          await this.requestExecutionFilter();
          this.loadingData = false
          return;
        } else if (this.relatoryNeighborhood.neighborhoodId) {
          await this.requestExecutionsCount();
          await this.requestActivityCount();
          return;
        } else {
          await this.requestKeepingSectorCount();
          await this.requestExecutionsCount();
          return;
        }
      }
    },
  },
  mounted: async function () {
    document.addEventListener("click", (e) => {
      if (
        e.target.closest("#sidebar") === null &&
        e.target.closest(".sidebar-toggler") === null
      ) {
        this.showSidebar = false;
      }
    });

    await this.getCurrentCycle();
    await this.logoUrl();

    if (this.searchStoreKeepingSector) {
      this.searchStoreForKeepingSector();
      return;
    }
    if (this.searchStoreNeighborhood) {
      this.searchStoreForNeighborhood();
      return;
    }
    if (this.searchStoreCity) {
      this.searchStoreForCity();
      return;
    }
    await this.initAllData();

    this.setPageToKeepingSector();
  },
  props: {
    searchStoreKeepingSector: {
      type: Boolean,
      default: false,
    },
    searchStoreNeighborhood: {
      type: Boolean,
      default: false,
    },
    searchStoreCity: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async initAllData() {
      this.loadingData = true;
      await this.requestTags();
      await this.requestNeighborhood();
      // await this.requestKeepingSectorCount();
      // await this.requestExecutionsCount();
      await this.requestActivityCount();

      this.firstLoading = false;
      this.fitBounds();
      this.loadingData = false;
    },

    async setPageToKeepingSector() {
      if (
        this.$route.params.keepingSectorId &&
        this.$route.params.neighborhoodId
      ) {
        this.startDate = localStorage.getItem("initialDate") + "T00:00:00";
        this.endDate = localStorage.getItem("finalDate") + "T00:00:00";

        localStorage.removeItem("initialDate");
        localStorage.removeItem("finalDate");

        let polygon = this.polygons.find(
          (polygon) =>
            polygon.bairro_id.toString() === this.$route.params.neighborhoodId
        );

        await this.changeStepMap(polygon);

        polygon = this.polygons.find(
          (polygon) =>
            polygon.zeladoria_id.toString() ===
            this.$route.params.keepingSectorId
        );

        this.lastDaysButtonLabel = `${dateToText(
          this.startDate
        )} - ${dateToText(this.endDate)}`;

        this.changeStepMap(polygon);
      }
    },

    async getCurrentCycle() {
      const date = new Date();
      const { dataInterval } = await services.executions.getCurrentCycle({
        data: toIsoString(date),
      });

      if (dataInterval) {
        this.startDate = new Date(
          new Date().setDate(new Date().getDate() - 7)
        ).toISOString();
        this.endDate = new Date().toISOString();
      } else {
        this.startDate = new Date(
          new Date().setDate(new Date().getDate() - 7)
        ).toISOString();
        this.endDate = new Date().toISOString();
      }
      this.lastDaysButtonLabel = `${dateToText(this.startDate)} - ${dateToText(
        this.endDate
      )}`;
    },

    async searchStoreForKeepingSector() {
      let backup = this.$store.state.global.printBackup;
      this.relatoryKeepingSector = backup.relatoryKeepingSector;
      this.relatoryNeighborhood = backup.relatoryNeighborhood;
      this.relatoryCity = backup.relatoryCity;
      this.endDate = backup.dates[1];
      this.startDate = backup.dates[0];
      this.activeActivitiesFilter = backup.activeActivitiesFilter;
      this.starsFilter = backup.starsFilter;
      let index = 1;
      this.relatoryKeepingSector.executions.forEach((execution) => {
        execution.photos.forEach((photo) => {
          photo.counter = index;
          index++;
        });
      });
      this.stepMap = 2;
      await this.requestKeepingSector(
        this.relatoryKeepingSector.keepingSectorId
      );
      // await this.requestExecutionsCount();
      await this.requestExecutionFilter();
      this.fitBounds();
    },

    async searchStoreForNeighborhood() {
      let backup = this.$store.state.global.printNeighborhood;
      this.relatoryNeighborhood = backup.relatoryNeighborhood;
      this.relatoryCity = backup.relatoryCity;
      this.endDate = backup.dates[1];
      this.startDate = backup.dates[0];

      this.stepMap = 1;
      await this.requestExecutionsCount();
      await this.requestKeepingSectorsByNeighborhood(
        this.relatoryNeighborhood.neighborhoodId
      );
      this.fitBounds();
    },

    async searchStoreForCity() {
      let backup = this.$store.state.global.printNeighborhood;
      this.relatoryCity = backup.relatoryCity;
      this.endDate = backup.dates[1];
      this.startDate = backup.dates[0];
      this.stepMap = 0;
      await this.requestExecutionsCount();
      await this.requestNeighborhood();
      this.fitBounds();
    },

    async getCityPolygon() {
      const { data, errors } = await services.neighborhoods.getCity();
      if (!errors) {
        return data;
      }
    },

    async filterPinsByTag() {
      this.photoCounter = 1;
      this.pins = [];
      this.tracking = [];
      if (this.relatoryKeepingSector.executions) {
        await Promise.all(
          this.relatoryKeepingSector.executions.map(async (item) => {
            await this.requestExecutionTimeline(item.execucao_uuid);
          })
        );
        this.setheatMAP();
        setTimeout(() => {
          this.$refs.childMap.generateCircles(this.circlesInfo);
        }, 1000);
      } else {
        this.syncHeatmap();
      }
    },

    async requestCity() {
      const city = await this.getCityPolygon();
      const polygons = JSON.parse(city.cidade_limite).coordinates[0];
      const formattedPolygons = geoJsonToMaps(polygons);
      let cityPolygon = {
        latlngs: formattedPolygons,
        options: {
          strokeColor: "#aaa",
          fillColor: "#bbb",
        },
        center: {
          lat: 0,
          lng: 0,
        },
        cidade_id: city.cidade_id,
        cidade_nome: city.cidade_nome,
      };
      cityPolygon.latlngs.map((item) => {
        this.bounds.push(item);
      });

      this.polygons.push(cityPolygon);
      this.relatoryCity.percentual = 0;
      return;
    },

    async requestNeighborhood() {
      let { data, errors } =
        await services.neighborhoods.getNeighborhoodMapWithEvaluation();
      if (!errors) {
        if (data.length === 0) {
          this.requestCity();
          return;
        }
        await this.formatNeighborhoodToRender(data);
      }
    },

    async requestTags() {
      const { data, errors } = await services.activities.getActivitiesTag();
      if (!errors) {
        this.tags = data;
      }
    },

    async requestKeepingSectorsByNeighborhood(id) {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorsWithEvaluationByNeighborhoodWithStatus(
          {
            neighborhoodId: id,
            initialDate: this.startDate,
            finalDate: this.endDate,
          }
        );
      if (!errors) {
        if (data) {
          if (data.length) {
            this.checkIfHasUsers(data);
            await this.formatKeepingSectorToRender(data);
            return true;
          } else {
            return false;
          }
        }
      }
    },

    async requestKeepingSector(id) {
      const { data, errors } =
        await services.keepingSectors.getDetailsKeepingSectorWithStatus({
          keepingSectorId: id,
          initialDate: this.startDate,
          finalDate: this.endDate,
        });
      if (!errors) {
        await this.formatKeepingSectorToRender(data);
      }
    },

    async requestKeepingSectorCount() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorCount();
      if (!errors) {
        this.relatoryCity.keepingSectorCount = data.qtd.toString();
        this.relatoryNeighborhood.keepingSectorCount = data.qtd.toString();
      }
    },

    async logoUrl() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      this.urlLogo = user.cidade_logo;
    },

    async requestActivityCount() {
      const { data, errors } = await services.activities.getActivityCount();
      if (!errors) {
        this.relatoryCity.activityCount = data.qtd.toString();
        this.relatoryNeighborhood.activityCount = data.qtd.toString();
        this.relatoryKeepingSector.activityCount = data.qtd.toString();
      }
    },

    setCityData(data) {
      if (this.relatoryCity.activities.length) {
        let backup = this.relatoryCity.activities;
        this.relatoryCity.activities = data.atividades;
        this.relatoryCity.activities.forEach(async (activity) => {
          if (
            backup.find(
              (backupActivity) =>
                backupActivity.atividade_id === activity.atividade_id &&
                backupActivity.active
            )
          ) {
            activity.active = true;
            this.pins = [];
            await this.requestActivityData(activity.atividade_id, "tag");
          }
        });
      }
      this.relatoryCity.activities = data.atividades;
    },

    setNeighborhoodData(data) {
      if (this.relatoryNeighborhood.activities.length) {
        let backup = this.relatoryNeighborhood.activities;
        this.relatoryNeighborhood.activities = data.atividades;
        this.relatoryNeighborhood.activities.forEach(async (activity) => {
          if (
            backup.find(
              (backupActivity) =>
                backupActivity.atividade_id === activity.atividade_id &&
                backupActivity.active
            )
          ) {
            activity.active = true;
            this.pins = [];
            await this.requestActivityData(activity.atividade_id);
          }
        });
      }
      this.relatoryNeighborhood.activities = data.atividades;
    },

    setKeepingSectorData(data) {
      this.relatoryKeepingSector.activities = data.atividades;
      if (this.activeActivitiesFilter.length) {
        this.relatoryKeepingSector.activities.forEach((activity) => {
          this.activeActivitiesFilter.forEach((requestActivity) => {
            if (activity.atividade_id === requestActivity) {
              activity.active = true;
            }
          });
        });
      }
    },

    setActivities(data) {
      data.atividades.forEach((activity) => {
        activity.active = false;
        activity.tags = [];
        if (activity.quantidade === null) {
          activity.quantidade = 0;
        }
        this.tags.map((tag) => {
          if (tag.atividade_id === activity.atividade_id) {
            activity.tags.push({
              ...tag,
              active: false,
            });
          }
        });
      });
    },

    setActivitiesCounts(data) {
      this.relatoryCity.activitiesCount = data.total || 0;
      this.relatoryNeighborhood.activitiesCount = data.total || 0;
      this.relatoryKeepingSector.activitiesCount = data.total || 0;

      this.relatoryCity.quantidade_foto = data.quantidade_foto || 0;
      this.relatoryNeighborhood.quantidade_foto = data.quantidade_foto || 0;
      this.relatoryKeepingSector.quantidade_foto = data.quantidade_foto || 0;
    },

    setKeepingSectorIncluded(data) {
      this.relatoryKeepingSector.zeladoria_incluida = data.zeladoria_incluida;
    },

    async requestExecutionsCount() {
      const { data, errors } =
        await services.executions.getExecutionsCountByDate({
          zeladoria_id: this.relatoryKeepingSector.keepingSectorId || 0,
          bairro_id: this.relatoryNeighborhood.neighborhoodId || 0,
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });
      if (!errors) {
        this.setActivitiesCounts(data);
        this.setActivities(data);

        if (this.relatoryKeepingSector.keepingSectorId) {
          this.setKeepingSectorData(data);
        } else if (this.relatoryNeighborhood.neighborhoodId) {
          this.setNeighborhoodData(data);
        } else {
          this.setCityData(data);
        }
      }
    },

    syncMarker() {
      this.showMarkers = !this.showMarkers;
    },

    pinClicked(pin) {
      if (this.stepMap === 2) {
        this.$refs.keepingSector.receiveClickPin(pin);
      }
    },

    pinClickedResearch(pin) {
      if (this.stepMap === 2) {
        this.$refs.keepingSector.receiveClickPin(pin);
      }
    },

    syncHeatmap() {
      this.showHeatmap = !this.showHeatmap;
      if (!this.showHeatmap) {
        setTimeout(() => {
          this.$refs.childMap.cleanHeatmap();
        }, 0);
      } else {
        this.setheatMAP();
      }
    },

    async formatExecutionTimeline(execution) {
      this.relatoryKeepingSector.totalExecutionTime = 0;

      this.relatoryKeepingSector.executions.forEach((item) => {
        if (item.execucao_uuid === execution.execucao_uuid) {
          item.hasTracking = execution.atividade_ativa_tracking;
          item.photos = execution.execucao_timeline;
          item.executionTime = execution.tempo_execucao_ms;
          this.relatoryKeepingSector.totalExecutionTime +=
            execution.tempo_execucao_ms || 0;

          let executionTracking = {
            tracking: this.convertTrackingToLatLng(
              execution.execucao_tracking ? execution.execucao_tracking : []
            ),
            id: execution.execucao_uuid,
            active: true,
          };
          this.tracking.push(executionTracking);

          item.photos.forEach((photo, index) => {
            if (!item.hasTracking) {
              const circleInfo = {
                center: {
                  lat: photo.execucao_timeline_lat,
                  lng: photo.execucao_timeline_lng,
                },
                radius: photo.marker_circle_size,
                color: generateColorByIndex(index + 1),
              };
              this.circlesInfo.push(circleInfo);
            }

            this.pins.push({
              center: {
                lat: photo.execucao_timeline_lat,
                lng: photo.execucao_timeline_lng,
              },
              tag_nome: photo.tags[0] ? photo.tags[0].tag_descricao : "",
              tag_id: photo.tags[0] ? photo.tags[0].tag_id : 0,
              atividade_id: photo.execucao_timeline_uuid,
              atividade_nome: item.atividade_nome,
              quantidade: this.photoCounter,
              id: item.execucao_uuid,
            });
            photo.counter = this.photoCounter;
            photo.atividade_nome = item.atividade_nome;
            this.photoCounter++;
          });
        }
      });
    },

    async requestExecutionTimeline(id) {
      this.loadingData = true;
      this.photoCounter = 1;
      this.relatoryKeepingSector.totalExecutionTime = 0;
      const tag_id = this.$store.state.map.stateTags;
      const { data, errors } = await services.executions.postExecutionTimeline({
        execucao_uuid: [id],
        intervalo: this.intervals,
        tag_id,
      });
      if (!errors && data) {
        this.relatoryKeepingSector.executions.forEach((item) => {
          if (item.execucao_uuid === id) {
            item.hasTracking = data.atividade_ativa_tracking;
            item.photos = data.execucao_timeline;
            item.executionTime = data.tempo_execucao_ms;
            this.relatoryKeepingSector.totalExecutionTime +=
              data.tempo_execucao_ms || 0;

            let executionTracking = {
              tracking: this.convertTrackingToLatLng(
                data.execucao_tracking ? data.execucao_tracking : []
              ),
              id: data.execucao_uuid,
              active: true,
            };
            this.tracking.push(executionTracking);

            item.photos.forEach((photo, index) => {
              if (!item.hasTracking) {
                const circleInfo = {
                  center: {
                    lat: photo.execucao_timeline_lat,
                    lng: photo.execucao_timeline_lng,
                  },
                  radius: photo.marker_circle_size,
                  color: generateColorByIndex(index + 1),
                };
                this.circlesInfo.push(circleInfo);
              }

              this.pins.push({
                center: {
                  lat: photo.execucao_timeline_lat,
                  lng: photo.execucao_timeline_lng,
                },
                tag_nome: photo.tags[0] ? photo.tags[0].tag_descricao : "",
                tag_id: photo.tags[0] ? photo.tags[0].tag_id : 0,
                atividade_id: photo.execucao_timeline_uuid,
                atividade_nome: item.atividade_nome,
                quantidade: this.photoCounter,
                id: item.execucao_uuid,
              });
              photo.counter = this.photoCounter;
              photo.atividade_nome = item.atividade_nome;
              this.photoCounter++;
            });
          }
        });
      }
      this.loadingData = false;
      this.fitBounds();
    },

    setheatMAP() {
      let point = [];

      this.tracking.forEach((item) => {
        if (item.tracking !== undefined) {
          item.tracking.forEach((track) => {
            point.push({
              location: this.googlePoints(track),
              weight: 100,
            });
          });
        }
      });

      setTimeout(() => {
        this.$refs.childMap.generateHeatmap(point);
      }, 1000);
    },

    googlePoints(value) {
      let map = new window.google.maps.LatLng(value.lat, value.lng);
      return map;
    },

    convertTrackingToLatLng(arrayOfXAndY) {
      let arrayOfLatLng = [];
      if (
        arrayOfXAndY === null ||
        arrayOfXAndY.length === 0 ||
        arrayOfXAndY === undefined
      ) {
        return [];
      }
      arrayOfXAndY.forEach((item) => {
        arrayOfLatLng.push({
          lat: item.x,
          lng: item.y,
        });
      });
      return arrayOfLatLng;
    },

    async requestExecutionFilter() {
      let executions;

      const { data, errors } = await services.executions.executionFilter({
        data_inicio: this.startDate,
        data_fim: this.endDate,
        zeladoria_id: +this.relatoryKeepingSector.keepingSectorId,
      });
      if (!errors) {
        this.relatoryKeepingSector.executions = data.map((item) => {
          return {
            ...item,
            active: true,
            data_inicio: item.execucao_data_criacao
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/"),
            hora_inicio: item.execucao_data_criacao
              .split("T")[1]
              .split(":")
              .slice(0, 2)
              .join(":"),
            hora_fim: item.execucao_data_fim
              .split("T")[1]
              .split(":")
              .slice(0, 2)
              .join(":"),
          };
        });

        let arrayOfUuids = [];

        data.forEach((item) => {
          arrayOfUuids.push(item.execucao_uuid);
        });

        const { data: executionsData, errors } =
          await services.executions.postExecutionTimelineAggregated({
            execucoes: arrayOfUuids,
            tag_id: this.$store.state.map.stateTags,
          });
        if (!errors) {
          executions = executionsData;
        }
      }

      if (this.activeActivitiesFilter.length) {
        this.relatoryKeepingSector.executions =
          this.relatoryKeepingSector.executions.filter((item) => {
            return this.activeActivitiesFilter.find(
              (filter) => filter === item.atividade_id
            );
          });
      }

      if (this.starsFilter.length) {
        this.relatoryKeepingSector.executions =
          this.relatoryKeepingSector.executions.filter((item) => {
            return this.starsFilter.find(
              (filter) => filter.value === item.execucao_nota
            );
          });
      }

      this.photoCounter = 1;
      this.pins = [];
      this.tracking = [];
      if (this.relatoryKeepingSector.executions.length) {
        executions.sort(function (a, b) {
          var c = new Date(a.execucao_data_criacao);
          var d = new Date(b.execucao_data_criacao);
          return c - d;
        });

        executions.sort(function (a, b) {
          var c = new Date(a.execucao_data_criacao);
          var d = new Date(b.execucao_data_criacao);
          return c - d;
        });

        executions.forEach((execution) => {
          this.formatExecutionTimeline(execution);
        });

        this.setheatMAP();
        this.fitBounds();
        this.fitBounds();
        setTimeout(() => {
          this.$refs.childMap.generateCircles(this.circlesInfo);
        }, 1000);
      } else {
        this.syncHeatmap();
      }
    },

    async requestActivityData(id, isTag) {
      if (isTag !== "tag") this.selectedTags = [];
      if (this.stepMap === 1) {
        await this.requestActivitiesByKeepingSector(id);
        return;
      }

      const { data, errors } =
        await services.activities.activityCountByNeighborhood({
          atividade_id: id,
          tags: this.selectedTags.length > 0 ? this.selectedTags : null,
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });

      if (!errors) {
        this.pins = this.pins.filter((pin) => {
          return pin.atividade_id !== id;
        });

        let activityName = this.relatoryCity.activities.find(
          (activity) => activity.atividade_id === id
        ).atividade_nome;

        data.map((item) => {
          this.pins.push({
            ...item,
            atividade_nome: activityName,
            //Fix position para Vila do Povo
            center: {
              lat:
                item.lat === -25.53810285475366 ? item.lat - 0.001 : item.lat,
              lng:
                item.lng === -48.54613002916752 ? item.lng - 0.0015 : item.lng,
            },
          });
        });
      }
    },

    async requestActivitiesByKeepingSector(id) {
      const { data, errors } =
        await services.activities.activityCountByKeepingSector({
          atividade_id: id,
          tags: this.selectedTags.length > 0 ? this.selectedTags : null,
          data_inicio: this.startDate,
          data_fim: this.endDate,
          bairro_id: this.relatoryNeighborhood.neighborhoodId,
        });
      if (!errors) {
        this.pins = this.pins.filter((pin) => {
          return pin.atividade_id !== id;
        });

        let activityName = this.relatoryCity.activities.find(
          (activity) => activity.atividade_id === id
        ).atividade_nome;

        data.map((item) => {
          this.pins.push({
            ...item,
            atividade_nome: activityName,
            center: {
              lat: item.lat,
              lng: item.lng,
            },
          });
        });
      }
    },

    showPhotos(id) {
      this.requestExecutionTimeline(id);
      this.setheatMAP();
      setTimeout(() => {
        this.$refs.childMap.generateCircles(this.circlesInfo);
      }, 1000);
    },

    hidePhotos(id) {
      this.tracking = this.tracking.filter((item) => item.id !== id);
      this.pins = this.pins.filter((item) => item.id !== id);
      setTimeout(() => {
        this.$refs.childMap.cleanHeatmap();
      }, 0);
      this.setheatMAP();
    },

    async desactivatePins(id) {
      if (this.stepMap === 0) {
        await this.requestNeighborhood();
      } else if (this.stepMap === 1) {
        this.relatoryNeighborhood.calledOnce = true;

        if (!this.relatoryNeighborhood.calledOnce) {
          this.requestKeepingSectorsByNeighborhood(
            this.relatoryNeighborhood.neighborhoodId
          );
        }
      }
      this.pins = this.pins.filter((item) => item.atividade_id !== id);
      this.fitBounds();
    },

    filterByDate(value) {
      this.endDate = null;
      this.endDate = value.end;
      this.startDate = value.start;
      this.lastDaysButtonLabel =
        dateToText(this.startDate) + " - " + dateToText(this.endDate);
    },

    filterTag(tags, activityId) {
      this.selectedTags = [];

      tags.forEach((tag) => {
        if (tag.active) {
          this.selectedTags.push(tag.tag_id);
          this.$store.commit("map/addTags", tag.tag_id);
        } else {
          this.$store.commit("map/removeTags", tag.tag_id);
        }
      });

      this.requestActivityData(activityId, "tag");
    },

    async filterActivity(activities) {
      this.activeActivitiesFilter = [];
      activities.forEach((item) => {
        if (item.active) {
          this.activeActivitiesFilter.push(item.atividade_id);
        }
      });

      await this.requestExecutionFilter();
    },

    async filterStars(stars) {
      this.starsFilter = stars;
      await this.requestExecutionFilter();
    },

    async backToNeighborhoodView() {
      this.starsFilter = [];
      this.loadingData = true;
      this.tracking = [];
      this.pins = [];
      this.circlesInfo = [];
      this.stepMap = 1;
      this.relatoryKeepingSector = {
        neighborhoodName: "",
        neighborhoodId: 0,
        keepingSectorName: "",
        keepingSectorId: 0,
        activities: [],
        activityCount: 0,
        executions: [],
      };
      await this.requestTags();
      await this.requestExecutionsCount();
      setTimeout(() => {
        this.$refs.childMap.cleanHeatmap();
      }, 0);
      this.selectedTags = this.$store.state.map.stateTags;
      await this.verifySwitchesNeighborhood();
      await this.requestKeepingSectorsByNeighborhood(
        this.relatoryNeighborhood.neighborhoodId
      );
      this.photoCounter = 1;
      this.loadingData = false;
      this.fitBounds();
    },

    async backToCityView() {
      this.loadingData = true;
      this.pins = [];
      this.tracking = [];
      this.photoCounter = 1;
      this.loadingData = false;
      this.stepMap = 0;
      await this.verifySwitchesCity();
      this.relatoryNeighborhood = {
        activities: [],
        neighborhoodName: "",
        percentual: 0,
        neighborhoodId: 0,
        keepingSectorCount: 0,
        activityCount: 0,
      };
      await this.initAllData();
    },

    async formatNeighborhoodToRender(data) {
      this.bounds = [];
      this.polygons = [];

      data.map((item, index) => {
        if (item.bairro_limite !== "" && item.bairro_limite !== null) {
          const bairro = {
            latlngs: formatForGoogleMaps(item.bairro_limite[0]),
            //Fix position para Vila do Povo
            center: {
              lat:
                item.bairro_nome === "Vila do Povo"
                  ? item.bairro_lat - 0.001
                  : item.bairro_lat,
              lng:
                item.bairro_nome === "Vila do Povo"
                  ? item.bairro_lng - 0.0015
                  : item.bairro_lng,
            },
            options: {
              strokeColor: item.avaliacao_quadra_cor,
              fillColor: item.avaliacao_quadra_cor,
            },
            bairro_id: item.bairro_id,
            bairro_nome: item.bairro_nome,
            id: index,
            display_name: false,
            qtd_usuarios_zeladoria: item.qtd_usuarios_zeladoria,
          };

          bairro.latlngs.map((item) => {
            this.bounds.push(item);
          });

          this.polygons.push(bairro);
        }
      });
    },

    async formatKeepingSectorToRender(data) {
      this.bounds = [];
      this.polygons = [];
      let keepingSectors = [];

      if (data.length > 0) {
        data.forEach((item) => {
          keepingSectors.push(item);
        });
      } else {
        keepingSectors.push(data);
      }

      const { dataInterval } = await services.executions.countAggregated({
        bairro_id: this.relatoryNeighborhood.neighborhoodId,
        data_inicio: this.startDate,
        data_fim: this.endDate,
      });
      const countAggregated = dataInterval.atividades;

      keepingSectors.map(async (item, index) => {
        if (item.zeladoria_limite !== "" && item.zeladoria_limite !== null) {
          const zeladoria = {
            latlngs: formatForGoogleMaps(item.zeladoria_limite[0]),
            center: {
              lat: item.zeladoria_lat,
              lng: item.zeladoria_lng,
            },
            options: {
              strokeColor: item.avaliacao_quadra_cor,
              fillColor: item.avaliacao_quadra_cor,
            },
            zeladoria_id: item.zeladoria_id,
            zeladoria_nome: item.zeladoria_nome,
            zeladoria_incluida: item.zeladoria_incluida,
            qtd_usuarios_zeladoria: item.qtd_usuarios_zeladoria,
            id: index,
            display_name: false,
            no_executions: this.checkIfKeepingSectorHasUserAndNoExecutions(
              item.qtd_usuarios_zeladoria,
              item.zeladoria_id,
              countAggregated
            ),
            canPartcipate: item.zeladoria_incluida === 1,
            avaliated: item.execucao_avaliacao_concluida === 1,
          };

          zeladoria.latlngs.map((item) => {
            this.bounds.push(item);
          });

          this.polygons.push(zeladoria);
          this.relatoryNeighborhood.keepingSectorCount = this.polygons.length;
          this.relatoryKeepingSector.zeladoria_incluida =
            item.zeladoria_incluida;
        }
      });
    },

    checkIfKeepingSectorHasUserAndNoExecutions(
      usersCount,
      keepingSectorId,
      countAggregated
    ) {
      if (usersCount > 0) {
        const index = countAggregated.findIndex(
          (item) => item.zeladoria_id === keepingSectorId
        );

        if (index !== -1) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },

    async verifySwitchesNeighborhood() {
      this.checkAll = false;
      let countSwitches = 0;
      if (this.stepMap === 2) {
        this.relatoryKeepingSector.activities.map((item) => {
          if (item.active) {
            this.relatoryNeighborhood.activities.map(async (item2) => {
              if (item.atividade_id === item2.atividade_id) {
                item2.active = true;
                countSwitches++;
                await this.requestActivitiesByKeepingSector(item2.atividade_id);
              }
            });
          }
        });
      } else {
        this.relatoryCity.activities.map((item) => {
          if (item.active) {
            this.relatoryNeighborhood.activities.map(async (item2) => {
              if (item.atividade_id === item2.atividade_id) {
                item2.active = true;
                countSwitches++;
                await this.requestActivitiesByKeepingSector(item2.atividade_id);
              }
            });
          }
        });
      }
      if (countSwitches === this.relatoryNeighborhood.activities.length) {
        this.checkAll = true;
      }
    },

    async verifySwitchesCity() {
      this.checkAll = false;
      let countSwitches = 0;
      this.selectedTags = this.$store.state.map.stateTags;
      this.relatoryNeighborhood.activities.map((item) => {
        if (item.active) {
          this.relatoryCity.activities.map(async (item2) => {
            if (item.atividade_id === item2.atividade_id) {
              item2.active = true;
              countSwitches++;
              await this.requestActivityData(item2.atividade_id, "tag");
            }
          });
        } else {
          this.relatoryCity.activities.map(async (item2) => {
            if (item.atividade_id === item2.atividade_id) {
              item2.active = false;
            }
          });
        }
      });
      if (countSwitches === this.relatoryCity.activities.length) {
        this.checkAll = true;
      }
    },

    async setCityMap(polygon) {
      this.relatoryNeighborhood.neighborhoodName = polygon.bairro_nome;
      this.relatoryNeighborhood.neighborhoodId = polygon.bairro_id;
      const hasData = await this.requestKeepingSectorsByNeighborhood(
        polygon.bairro_id
      );
      if (!hasData) {
        this.loadingData = false;
        validationToast("Nenhum dado cadastrado para o bairro selecionado");
        this.fitBounds();
        return;
      }
      this.pins = [];
      await this.requestExecutionsCount();
      this.stepMap = 1;
      this.selectedTags = this.$store.state.map.stateTags;
      this.verifySwitchesNeighborhood();
    },

    async setNeighborhoodMap(polygon) {
      this.pins = [];
      this.stepMap = 2;
      this.activeActivitiesFilter = [];
      this.relatoryKeepingSector = {
        ...this.relatoryKeepingSector,
        neighborhoodName: this.relatoryNeighborhood.neighborhoodName,
        neighborhoodId: this.relatoryNeighborhood.neighborhoodId,
        keepingSectorName: polygon.zeladoria_nome,
        keepingSectorId: polygon.zeladoria_id,
      };
      this.relatoryNeighborhood.activities.map((item) => {
        item.active
          ? this.activeActivitiesFilter.push(item.atividade_id)
          : undefined;
      });
      await this.requestKeepingSector(polygon.zeladoria_id);
      await this.requestExecutionsCount();
      await this.requestExecutionFilter();
    },

    async changeStepMap(polygon) {
      this.loadingData = true;
      if (this.stepMap === 0) await this.setCityMap(polygon);
      else if (this.stepMap === 1) await this.setNeighborhoodMap(polygon);
      else if (this.stepMap === 2) {
        this.loadingData = false;
        return;
      }
      this.loadingData = false;
      this.fitBounds();
    },

    homeRoute() {
      window.location.reload();
    },
    fitBounds() {
      setTimeout(() => {
        this.$refs.childMap.fitBoundsGoogle();
      }, 10);
    },
    checkIfHasUsers(data) {
      let leads = 0;
      data.forEach((keepingSector) => {
        leads += keepingSector.qtd_usuarios_zeladoria;
      });
      if (leads === 0) {
        infoToast("Não há zeladores cadastrados para este bairro");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s !important;
}

.v-enter-from,
.v-leave-to {
  opacity: 0 !important;
}
.sidebar {
  z-index: 99999999 !important;
}
.c-header {
  box-shadow: 0px 8px 12px rgba(160, 168, 189, 0.24);
  border: 0;

  .c-header-toggler-icon {
    background-image: url("../../assets/img/toggle.svg");
    height: 18px;
  }
}

.position-welcome {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #131e3b;
}

.notification {
  background-color: rgba(97, 178, 229, 0.1);
  padding: 10px;
  border-radius: 8px 8px 0px 0px;
  width: 250px;
  font-family: "Roboto-Regular";
  font-size: 14px;
  line-height: 22px;
  color: #61b2e5;
}

.position-notification {
  right: 35px;
  top: 16px;
}

.breath-map {
  padding-top: 2px;
  padding-left: 0px;
}

.brand-position {
  position: absolute;
  right: 32px;
  margin-top: -10px;
}

.fullscreen-map {
  background: #fff;
  margin-bottom: calc(13vh);
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 60px;
  height: 60px;
  border-radius: 0px 50% 50% 0px;
}

.fullscreenMapOpen {
  height: calc(100vh - 56px);
}

.fullscreenMapClose {
  height: calc(100vh - 56px);
}

.asideRelatory {
  height: calc(100vh - 64px);
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.map-options {
  position: absolute;
  bottom: 30px;
  left: 0px;
  z-index: 500;

  button {
    background: #fff;
    border: 0;
    display: block;
    clear: both;
    margin-bottom: 0.5rem;
  }
}

.shift-map-options {
  left: 16px !important;
}

@media (max-width: 1200px) {
  .fullscreenMapOpen {
    height: calc(100vh - 66px);
  }

  .fullscreenMapClose {
    height: calc(55vh - 66px);
  }
}

@media (max-width: 700px) {
  .position-welcome {
    display: none !important;
  }
}

.new-icons {
  width: 32px;
  height: 32px;
}
</style>

export function formatPhone(number) {
  if (number) {
    const numberString = number.toString()

    const has11Digits = numberString.length === 11

    if (has11Digits) {
      return `(${numberString.substring(0,2)}) ${numberString.substring(2,3)} ${numberString.substring(3,7)}-${numberString.substring(7,11)}`
    }
    return `(${numberString.substring(0,2)}) ${numberString.substring(2,6)}-${numberString.substring(6,10)}`
  }

  return 'Não informado'
}

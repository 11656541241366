<template>
  <div class="candidates ml-3">
    <h3 class="intern-title">
      <back-arrow />
      <span class="page-nav">{{ $t("candidates") }}</span>
    </h3>
    <div class="resume">
      <div class="section-title">Resumo</div>
      <div v-if="bigNumbers" class="candidate-cards">
        <div class="candidate-card">
          <div class="title">Ocupação atual / Ocupação máxima</div>
          <div class="description">
            {{ bigNumbers.total_zelador }} / {{ bigNumbers.limite_zelador }}
          </div>
        </div>
        <div class="candidate-card">
          <div class="title">Disponibilidade</div>
          <div class="description">
            {{ bigNumbers.prospeccao_em_aberto }}
          </div>
        </div>
        <div class="candidate-card">
          <div class="title">Vagas de reserva permitida</div>
          <div class="description">{{ bigNumbers.vagas_reserva }}</div>
        </div>
      </div>
    </div>
    <div class="users">
      <div class="section-title">Candidatos</div>
      <div class="d-flex justify-content-between">
        <div style="width: 500px; margin-bottom: 32px">
          <Input
            v-model="searchString"
            :placeholder="$t('searchCandidates')"
            :search="true"
          />
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <div class="tabs">
        <div
          :class="`tab ${tab.active ? 'active' : ''}`"
          v-for="(tab, index) in tabs"
          :key="index"
          @click="handleChangeTab(tab.value)"
        >
          {{ tab.label }}
          <div class="counter">{{ tab.qtd }}</div>
        </div>
      </div>
      <span style="cursor: pointer" @click="boxInfo = true">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 27.3333C6.63596 27.3333 0.666626 21.364 0.666626 14C0.666626 6.63596 6.63596 0.666626 14 0.666626C21.364 0.666626 27.3333 6.63596 27.3333 14C27.3333 21.364 21.364 27.3333 14 27.3333ZM12.6666 12.6666V20.6666H15.3333V12.6666H12.6666ZM12.6666 7.33329V9.99996H15.3333V7.33329H12.6666Z"
            fill="#003063"
          />
        </svg>
      </span>
    </div>
    <transition name="bounce">
      <div v-if="!loadingData" class="table">
        <BoxInfo
          style="margin: 24px 0"
          type="info"
          @close="closeBoxInfo"
          v-if="boxInfo"
          title=""
          :description="loadBoxInfoText()"
        />
        <Table :fields="fields" :hasActions="false">
          <TableRow v-for="item in candidates" :key="item.usuario_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{
                  item[field.key] || "--"
                }}</span>
              </template>
              <template v-slot:usuario_data_entrevista>
                <div
                  :class="
                    checkIfDateIsExpired(item.usuario_data_limite_entrevista)
                      ? 'expired'
                      : ''
                  "
                >
                  {{ formatDate(item.usuario_data_limite_entrevista) || "--" }}
                </div>
              </template>
              <template v-slot:usuario_data_limite_visita>
                <div
                  :class="
                    checkIfDateIsExpired(item.usuario_data_limite_visita)
                      ? 'expired'
                      : ''
                  "
                >
                  {{ formatDate(item.usuario_data_limite_visita) || "--" }}
                </div>
              </template>
              <template v-slot:usuario_data_criacao>
                {{ formatDate(item.usuario_data_criacao) || "--" }}
              </template>
              <template v-slot:usuario_data_visita>
                {{ formatDate(item.usuario_data_visita) || "--" }}
              </template>
              <template v-slot:usuario_telefone>
                {{ formatPhone(item.usuario_telefone) || "--" }}
              </template>
              <template v-slot:zeladoria_nome>
                <div v-if="!item.zeladoria_nome && currentTab === 102">
                  <Button name="Vincular" @clicked="openModal(item)"
                    >Open</Button
                  >
                </div>
                <div v-else>
                  {{ item.zeladoria_nome || "--" }}
                </div>
              </template>
              <template v-slot:acoes>
                <div
                  title="Detalhes"
                  @click="handleDetailsClick(item.usuario_id)"
                  class="actions"
                >
                  <svg
                    v-if="item.usuario_status_id !== 102"
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 16H1V8H6V16ZM4 14V10H3V14H4ZM10 14V6H9V14H10ZM12 16H7V4H12V16ZM16 14V2H15V14H16ZM18 16H13V0H18V16ZM19 20H0V18H19V20Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.60803 21 2.12203 17.12 1.18103 12C2.12103 6.88 6.60803 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2074 15.7635 20.3229 13.9883 20.777 12C20.3213 10.0133 19.205 8.24 17.6107 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.96173 5.00853 7.98372 5.70005 6.38941 6.97003C4.79509 8.24 3.6788 10.0133 3.22303 12C3.67713 13.9883 4.7927 15.7635 6.38717 17.0352C7.98164 18.3068 9.96056 18.9996 12 19ZM12 16.5C10.8066 16.5 9.66196 16.0259 8.81805 15.182C7.97414 14.3381 7.50003 13.1935 7.50003 12C7.50003 10.8065 7.97414 9.66193 8.81805 8.81802C9.66196 7.97411 10.8066 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.6631 14.5 13.299 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.299 9.76339 12.6631 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2323 10.2322C9.76342 10.7011 9.50003 11.337 9.50003 12C9.50003 12.663 9.76342 13.2989 10.2323 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                </div>
              </template>
            </TableCell>
          </TableRow>
          <template v-if="candidates.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <Modal v-show="linkModal" :width="400" :height="300">
      <template v-slot:body>
        <p class="remove-modal-title">Escolha uma zeladoria para o candidato</p>
        <multiselect
          v-model="keepingSector"
          noOptions="Nenhuma Zeladoria encontrada"
          selectLabel="Selecionar"
          selectedLabel="Selecionado"
          :options="keepingSectors"
          track-by="name"
          placeholder="Selecione"
          :preselect-first="false"
          :multiple="false"
          id="multiselect"
          label="name"
        ></multiselect>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            @clicked="linkModal = false"
            type="outline"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('save')" @clicked="saveCandidate" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import services from "../../services";
import { formatPhone } from "../../utils/phone";
import { formatDate } from "../../utils/date";
import { debounce } from "../../utils/debounce";
import BackArrow from "../../components/BackArrow.vue";
import NoResults from "../../components/table/NoResults.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Input from "../../components/form/Input.vue";
import Modal from "../../components/Modal.vue";
import Multiselect from "vue-multiselect";
import Button from "../../components/Button.vue";
import BoxInfo from "../../components/BoxInformation.vue";

export default {
  components: {
    BackArrow,
    NoResults,
    Table,
    TableRow,
    TableCell,
    Loading,
    Input,
    Modal,
    Multiselect,
    Button,
    BoxInfo,
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 1;
      this.pagination.filter = newVal;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);

      if (this.pagination.filter) {
        await this.requestCandidatesByName();
      } else {
        await this.requestCandidates();
      }
    }, 500),
  },
  data() {
    return {
      candidates: [],
      candidate: null,
      searchString: null,
      keepingSectors: [],
      boxInfo: true,
      bigNumbers: null,
      keepingSector: null,
      linkModal: false,
      loadingData: false,
      pagination: {
        page: 1,
        limit: 25,
        filter: "",
        status: 100,
      },
      tabs: [],
      currentTab: 100,
      fields: [
        {
          key: "usuario_data_criacao",
          label: this.$t("registerDate"),
          _style: "min-width:180px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_data_entrevista",
          label: this.$t("firstContactLimit"),
          _style: "min-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_nome",
          label: this.$t("name"),
          _style: "min-width:180px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_telefone",
          label: this.$t("phone"),
          _style: "min-width:140px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "bairro_nome",
          label: this.$t("neighborhood"),
          _style: "min-width:140px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladoria_nome",
          label: this.$t("keepingSector"),
          _style: "max-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "acoes",
          label: "Ações",
          _style: "min-width: 100px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      formatPhone,
      formatDate,
    };
  },
  async created() {
    await this.requests();
  },
  methods: {
    closeBoxInfo() {
      this.boxInfo = false;
    },
    loadBoxInfoText() {
      if (this.currentTab === 100) {
        return "Nesta aba encontram-se os usuários que clicaram no banner e ainda não foram entrevistados pelo monitor.";
      }
      if (this.currentTab === 101) {
        return "Nesta aba encontram-se os candidatos entrevistados pelo monitor que estão aguardando vaga para reserva de zeladoria.";
      }
      if (this.currentTab === 102) {
        return "Nesta aba encontra-se o candidato que não atende os requisitos para tornar-se zelador.";
      }
      if (this.currentTab === 105) {
        return "Nesta aba encontra-se o candidato triado, com vaga garantida e em processo de abertura de MEI.";
      }
    },
    async loadTabs() {
      const { data, errors } = await services.candidates.getTabs();
      if (!errors) {
        data.forEach((item) => {
          this.tabs.push({
            active: false,
            label: item.usuario_status_descricao,
            value: item.usuario_status_id,
            qtd: item.total,
          });
        });
        this.tabs[0].active = true;
      }
    },
    async loadBigNumbers() {
      const response = await services.candidates.getBigNumbers();
      this.bigNumbers = response.data[0];
    },
    async requests() {
      await this.requestCandidates();
      await this.requestFreeKeepingSectors();
      await this.loadTabs();
      await this.loadBigNumbers();
    },
    async requestCandidates() {
      this.loadingData = true;
      const { data, errors } = await services.candidates.getCandidates(
        this.pagination
      );
      if (!errors) {
        this.candidates = data.usuario;
      }
      this.loadingData = false;
    },
    openModal(item) {
      this.linkModal = true;
      this.keepingSector = null;
      this.candidate = item;
    },
    async saveCandidate() {
      this.loadingData = true;
      const { errors } = await services.candidates.vinculateCandidate({
        usuario_id: this.candidate.usuario_id,
        zeladoria_id: this.keepingSector.value,
      });
      if (!errors) {
        this.loadingData = false;
        this.keepingSector = null;
        this.candidate = null;
        this.linkModal = false;
        await this.requests();
      }
      this.loadingData = false;
    },
    async requestFreeKeepingSectors() {
      this.loadingData = true;
      const { data, errors } = await services.candidates.requestFreeKS();
      if (!errors) {
        data.forEach((item) => {
          this.keepingSectors.push({
            value: item.zeladoria_id,
            name: item.zeladoria_nome,
          });
        });
      }
      this.loadingData = false;
    },
    async requestCandidatesByName() {
      this.loadingData = true;
      const { data, errors } = await services.candidates.getCandidatesByName(
        this.pagination
      );
      if (!errors) {
        this.candidates = data.usuario;
      }
      this.loadingData = false;
    },

    setTableColumns() {
      if (this.currentTab === 100) {
        this.fields = [
          {
            key: "usuario_data_criacao",
            label: this.$t("registerDate"),
            _style: "min-width:80px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_data_entrevista",
            label: this.$t("firstContactLimit"),
            _style: "min-width:80px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_nome",
            label: this.$t("name"),
            _style: "min-width:180px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_telefone",
            label: this.$t("phone"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "bairro_nome",
            label: this.$t("neighborhood"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "zeladoria_nome",
            label: this.$t("keepingSector"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "acoes",
            label: "Ações",
            _style: "min-width:30px;",
            sortable: false,
            sortDirection: null,
          },
        ];
      }
      if (this.currentTab === 101) {
        this.fields = [
          {
            key: "usuario_data_limite_visita",
            label: this.$t("interviewDate"),
            _style: "min-width:80px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_nome",
            label: this.$t("name"),
            _style: "min-width:180px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_telefone",
            label: this.$t("phone"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "bairro_nome",
            label: this.$t("neighborhood"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "zeladoria_nome",
            label: this.$t("keepingSector"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "acoes",
            label: "Ações",
            _style: "min-width:30px;",
            sortable: false,
            sortDirection: null,
          },
        ];
      }
      if (this.currentTab === 102) {
        this.fields = [
          {
            key: "usuario_data_criacao",
            label: this.$t("registerDate"),
            _style: "min-width:80px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_nome",
            label: this.$t("name"),
            _style: "min-width:180px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_telefone",
            label: this.$t("phone"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "acoes",
            label: "Ações",
            _style: "min-width:30px;",
            sortable: false,
            sortDirection: null,
          },
        ];
      }
      if (this.currentTab === 105) {
        this.fields = [
          {
            key: "usuario_data_criacao",
            label: "Reserva",
            _style: "min-width:80px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_data_limite_visita",
            label: "Limite para documentação",
            _style: "min-width:180px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_nome",
            label: this.$t("name"),
            _style: "min-width:180px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_telefone",
            label: this.$t("phone"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_bairro",
            label: this.$t("neighborhood"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "zeladoria_nome",
            label: this.$t("keepingSector"),
            _style: "min-width:100px;",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "acoes",
            label: "Ações",
            _style: "min-width:30px;",
            sortable: false,
            sortDirection: null,
          },
        ];
      }
    },

    async handleChangeTab(value) {
      this.boxInfo = true;
      this.pagination = {
        page: 1,
        limit: 25,
        filter: "",
        status: value,
      };

      this.searchString = null;
      this.pagination.status = value;
      this.currentTab = value;

      this.tabs.map((tab) => {
        if (tab.value === value) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });

      this.setTableColumns();
      await this.requestCandidates();
    },

    async infiniteHandler($state) {
      let currentData = null;
      let currentErrors = null;
      this.pagination.page++;

      if (this.pagination.filter) {
        const { data, errors } = await services.candidates.getCandidatesByName(
          this.pagination
        );
        currentData = data;
        currentErrors = errors;
      } else {
        const { data, errors } = await services.candidates.getCandidates(
          this.pagination
        );
        currentData = data;
        currentErrors = errors;
      }

      if (!currentErrors) {
        if (currentData.length > 0) {
          let temp = currentData;
          temp.forEach((item) => {
            this.candidates.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      } else {
        $state.complete();
      }
    },

    handleDetailsClick(candidateId) {
      this.$router.push(`/candidatos/${candidateId}`);
    },

    checkIfDateIsExpired(date) {
      const item = new Date(date).setUTCHours(0, 0, 0, 0);
      const today = new Date().setUTCHours(0, 0, 0, 0);
      if (date) {
        if (today > item) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.counter {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 132%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6f7fd;
  min-width: 32px;
  height: 24px;
  border-radius: 8px;
  margin-left: 12px;
  padding: 4px 8px;
  background: #ff002e;
  color: #fff;
}
.candidates {
  background-color: #fff;
  border-radius: 8px;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}

.reproved-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  text-transform: uppercase;
  color: #838998;
}

.actions {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.tabs {
  display: flex;
  color: #838998;
}

.tab {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 168%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  color: #838998;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.expired {
  color: tomato;
}

.table {
  .table-wrapper {
    padding: 0;
  }
}

.resume {
  margin-bottom: 32px;
  .candidate-cards {
    display: flex;
    gap: 24px;
    .candidate-card {
      flex: 1;
      border: 1px solid #dddee3;
      border-radius: 16px;
      height: 88px;
      padding: 16px 20px;

      .title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #131e3b;
      }
      .description {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #565e73;
      }
    }
  }
}

.section-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #131e3b;
  margin-bottom: 24px;
}
</style>

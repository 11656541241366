var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 h-100"},[_c('GmapMap',{key:_vm.rerender,ref:"googleMap",staticStyle:{"height":"100%","width":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"roadmap","options":{
      zoomControl: _vm.zoomControl,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
      styles: _vm.stylesMaps,
      gestureHandling: _vm.zoomControl ? 'greedy' : 'none',
      mapTypeId: _vm.mapType,
    }},on:{"zoom_changed":_vm.zoomChanged}},_vm._l((_vm.pins),function(pin,index){return _c('div',{key:pin.id + Math.random()},[_c('GmapCustomMarker',{staticClass:"markerGoogle",attrs:{"draggable":false,"marker":pin.center},nativeOn:{"click":function($event){return _vm.clickPhotoPin(pin, index)}}},[_c('div',{staticClass:"pinMarker colorSector"},[_c('span',{staticClass:"qtdAmas"},[_vm._v(_vm._s(pin.id))])])])],1)}),0),(_vm.changeMapType)?_c('div',{staticClass:"mapTypeControllers"},[_c('CSelect',{attrs:{"placeholder":"Tipo","options":_vm.mapTypes,"value":_vm.mapType},on:{"update:value":function($event){_vm.mapType=$event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
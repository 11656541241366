<template>
  <button
    :style="width ? '{min-width: 300px;}' : '{}'"
    class="secondary-btn"
    @click="$emit('clicked')"
  >
    <span class="btn-name">{{ name }}</span>
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style scoped>
.secondary-btn {
  border: 1px solid #DDDEE3;
  border-radius: 20px;
  padding: 0 4px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: fit-content;
  height: 33px;
  background-color: #fff;
}

.btn-name {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #838998;
  padding-right: 8px;
  padding-left: 8px;
}
</style>

<template>
  <div class="no-results">
    <div class="message">
      {{ message }}
    </div>
    <svg
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3332 22.7527V26.236C15.8247 25.7027 14.2103 25.5391 12.6255 25.759C11.0407 25.9789 9.53183 26.5758 8.22556 27.4997C6.91928 28.4236 5.85374 29.6474 5.11844 31.0684C4.38314 32.4894 3.99953 34.066 3.99984 35.666L0.666504 35.6643C0.665987 33.6292 1.13137 31.6209 2.027 29.7933C2.92263 27.9658 4.22473 26.3676 5.83353 25.1211C7.44233 23.8746 9.31513 23.0129 11.3084 22.6021C13.3017 22.1913 15.3626 22.2422 17.3332 22.751V22.7527ZM13.9998 20.666C8.47484 20.666 3.99984 16.191 3.99984 10.666C3.99984 5.14102 8.47484 0.666016 13.9998 0.666016C19.5248 0.666016 23.9998 5.14102 23.9998 10.666C23.9998 16.191 19.5248 20.666 13.9998 20.666ZM13.9998 17.3327C17.6832 17.3327 20.6665 14.3493 20.6665 10.666C20.6665 6.98268 17.6832 3.99935 13.9998 3.99935C10.3165 3.99935 7.33317 6.98268 7.33317 10.666C7.33317 14.3493 10.3165 17.3327 13.9998 17.3327ZM25.6665 28.3093L29.2015 24.7727L31.5598 27.131L28.0232 30.666L31.5598 34.201L29.2015 36.5593L25.6665 33.0227L22.1315 36.5593L19.7732 34.201L23.3098 30.666L19.7732 27.131L22.1315 24.7727L25.6665 28.3093Z"
        fill="#B0B4BD"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: "Nenhum resultado encontrado",
    },
  },
};
</script>
<style lang="scss" scoped>
.no-results {
  width: 100%;
  display: flex;
  color: #003366;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  justify-content: center;
  margin: 150px auto;
  height: 100%;

  .message {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 24px;
    line-height: 132%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #b0b4bd;
    margin-bottom: 16px;
  }
}
</style>

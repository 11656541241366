<template>
  <div class="supervisor-tags">
    <div class="page-header">
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav">Tags do app do fiscal</span>
      </h3>
      <div class="pr-4">
        <Button
          @clicked="handleModalOpen"
          class="mb-4"
          :name="$t('newTag')"
          size="small"
        />
      </div>
    </div>
    <transition name="bounce">
      <div v-if="!loading">
        <div>
          <div
            class="d-flex justify-content-between align-center pl-3 mb-3 mt-3"
          >
            <div class="w-25">
              <Input
                v-model="searchString"
                :placeholder="$t('searchTag')"
                :search="true"
              />
            </div>
          </div>
          <Table v-if="!loading" :fields="fields" :hasActions="true">
            <TableRow v-for="item in supervisorTags" :key="item.fiscal_tag_id">
              <TableCell
                v-for="field in fields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  <span class="table-row-text">{{ item[field.key] }}</span>
                </template>
              </TableCell>
              <TableCell class="d-flex justify-content-end">
                <CondensedDropdown
                  class="mt-1"
                  @dropdown-click="
                    (ev) => handleCondensedDropdownClick(ev, item)
                  "
                  :options="options"
                />
              </TableCell>
            </TableRow>
            <template v-slot:no-results>
              <NoResults
                v-if="supervisorTags.length <= 0"
                :message="$t('noResults')"
              />
            </template>
          </Table>
        </div>
      </div>
      <Loading v-if="loading" />
    </transition>
    <Modal class="modal-tag" v-show="createModal" :width="400">
      <template v-slot:header>
        <span class="titulo">{{ isEditing ? "Editar Tag" : "Nova Tag" }}</span>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          <div class="mb-3">
            <Input
              name="name"
              placeholder="Inserir tag"
              v-model="tagForm.name"
            />
          </div>
          <CSelect
            placeholder="Tipo de fiscal"
            :options="supervisorTypes"
            :value.sync="tagForm.supervisorType"
          ></CSelect>
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container">
          <Button
            :disabled="!tagForm.supervisorType || !tagForm.name"
            class="save-btn"
            @clicked="handleTagSave"
            name="Confirmar"
          />
          <Button
            class="cancel-btn"
            type="outline"
            :name="$t('cancel')"
            @clicked="handleModalCancel"
          />
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="confirmationModal" :width="400">
      <template v-slot:header>
        <div>
          <svg
            class="mb-3"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0002 73.6666C16.7492 73.6666 0.333496 57.2509 0.333496 36.9999C0.333496 16.7489 16.7492 0.333252 37.0002 0.333252C57.2512 0.333252 73.6668 16.7489 73.6668 36.9999C73.6668 57.2509 57.2512 73.6666 37.0002 73.6666ZM33.3445 51.6666L59.2678 25.7396L54.0832 20.5549L33.3445 41.2973L22.9715 30.9243L17.7868 36.1089L33.3445 51.6666Z"
              fill="#80BE24"
            />
          </svg>
          <div class="titulo">Pronto !</div>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          {{
            isEditing ? "Suas alterações foram salvas" : "Nova tag cadastrada"
          }}
        </div>
      </template>
      <template v-slot:footer>
        <Button
          class="w-100"
          name="Confirmar"
          @clicked="confirmationModal = false"
        />
      </template>
    </Modal>
    <Modal
      class="modal-confirmacao"
      v-show="deleteTagConfirmationModal"
      :width="400"
    >
      <template v-slot:header>
        <div>
          <svg
            class="mb-3"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0002 73.6666C16.7492 73.6666 0.333496 57.2509 0.333496 36.9999C0.333496 16.7489 16.7492 0.333252 37.0002 0.333252C57.2512 0.333252 73.6668 16.7489 73.6668 36.9999C73.6668 57.2509 57.2512 73.6666 37.0002 73.6666ZM33.3445 51.6666L59.2678 25.7396L54.0832 20.5549L33.3445 41.2973L22.9715 30.9243L17.7868 36.1089L33.3445 51.6666Z"
              fill="#80BE24"
            />
          </svg>
          <div class="titulo">Pronto !</div>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">A tag foi excluída</div>
      </template>
      <template v-slot:footer>
        <Button
          class="w-100"
          name="Confirmar"
          @clicked="deleteTagConfirmationModal = false"
        />
      </template>
    </Modal>
    <Modal class="modal-tag" v-show="deleteModal" :width="400">
      <template v-slot:header>
        <span class="titulo">Confirmação de Exclusão de tag</span>
      </template>
      <template v-slot:body>
        <p class="delete-confirmation">
          Tem certeza que deseja excluir a tag
          <b>{{ tagToDelete.name }}</b
          >?
        </p>
      </template>
      <template v-slot:footer>
        <div class="btn-container">
          <Button
            class="mb-3"
            style="background-color: #ff002e; border: none"
            name="Excluir"
            @clicked="handleDeleteTag"
          />
          <Button
            :name="$t('cancel')"
            @clicked="deleteModal = false"
            type="outline"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Table from "../../components/table/Table.vue";
import NoResults from "../../components/table/NoResults.vue";
import Input from "../../components/form/Input.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Modal from "../../components/Modal.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import { debounce } from "../../utils/debounce";
import services from "../../services";

export default {
  components: {
    Button,
    Loading,
    BackArrow,
    CondensedDropdown,
    Input,
    TableCell,
    TableRow,
    NoResults,
    Table,
    Modal,
  },
  watch: {
    searchString: debounce(function (newVal) {
      this.supervisorTags = this.supervisorTagsSourceOfTruth;
      if (newVal) {
        this.supervisorTags = this.supervisorTags.filter((tag) => {
          return tag.fiscal_tag_descricao.includes(newVal);
        });
      }
    }, 500),
  },
  data() {
    return {
      isEditing: false,
      supervisorTags: [],
      supervisorTagsSourceOfTruth: [],
      searchString: null,
      confirmationModal: false,
      loading: false,
      createModal: false,
      deleteTagConfirmationModal: false,
      deleteModal: false,
      tagToDelete: {
        name: null,
        id: null,
      },
      tagForm: {
        id: null,
        name: null,
        supervisorType: null,
      },
      supervisorTypes: [],
      fields: [
        {
          key: "fiscal_tag_descricao",
          label: "Nome",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "fiscal_tipo_descricao",
          label: "Tipo de fiscal",
          _style: "min-width:260px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      options: [
        { label: "Execuções", name: "Execuções", value: "executions" },
        { label: "Editar", name: "Editar", value: "edit" },
        { label: "Excluir", name: "Excluir", value: "delete" },
      ],
    };
  },
  async created() {
    await this.loadSupervisorTags();
    await this.loadSupervisorTypes();
  },
  methods: {
    clearTagForm() {
      this.tagForm = {
        name: null,
        id: null,
      };
    },
    clearTagToDelete() {
      this.tagToDelete = {
        name: null,
        id: null,
      };
    },
    handleModalCancel() {
      this.createModal = false;
      this.clearTagForm();
    },
    async handleModalOpen() {
      this.clearTagForm();
      if (!this.isEditing) {
        this.tagForm = {
          name: null,
          supervisorType: null,
        };
      }
      this.createModal = true;
    },
    async handleTagSave() {
      this.loading = true;
      const payload = {
        fiscal_tag_id: this.isEditing ? this.tagForm.id : null,
        fiscal_tipo_id: this.tagForm.supervisorType,
        fiscal_tag_descricao: this.tagForm.name,
      };

      let err = null;
      if (this.isEditing) {
        const { errors } = await services.tags.editSupervisorTag(payload);
        err = errors;
      } else {
        const { errors } = await services.tags.createSupervisorTag(payload);
        err = errors;
      }

      if (!err) {
        this.createModal = false;
        this.confirmationModal = true;
        this.clearTagForm();
      }
      await this.loadSupervisorTags();
      this.isEditing = false;
      this.loading = false;
    },
    async handleDeleteTag() {
      this.loading = true;
      const { errors } = await services.tags.deleteSupervisorTag(
        this.tagToDelete.id
      );
      if (!errors) {
        this.clearTagToDelete();
        this.deleteModal = false;
        this.deleteTagConfirmationModal = true;
        await this.loadSupervisorTags();
      }
      this.loading = false;
    },
    async loadSupervisorTypes() {
      this.loading = true;
      const { data, errors } =
        await services.UsersSupervisors.getSupervisorTypes();
      if (!errors) {
        this.supervisorTypes = data.map((item) => {
          return {
            label: item.fiscal_tipo_descricao,
            value: item.fiscal_tipo_id,
          };
        });
      }
      this.loading = false;
    },
    async loadSupervisorTagById(tagId) {
      const { data, errors } = await services.tags.getSupervisorTagById(tagId);
      if (!errors) {
        this.tagForm = {
          id: data[0].fiscal_tag_id,
          name: data[0].fiscal_tag_descricao,
          supervisorType: data[0].fiscal_tipo_id,
        };
      }
    },
    async loadSupervisorTags() {
      this.loading = true;
      const { data, errors } = await services.tags.getSupervisorTags();
      if (!errors) {
        this.supervisorTags = data;
        this.supervisorTagsSourceOfTruth = data;
      }
      this.loading = false;
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "executions") {
        this.$router.push(`/execucoes/tag-${item.fiscal_tag_id}`);
      }
      if (value === "edit") {
        this.isEditing = true;
        await this.loadSupervisorTagById(item.fiscal_tag_id);
        this.createModal = true;
      }
      if (value === "delete") {
        this.tagToDelete = {
          id: item.fiscal_tag_id,
          name: item.fiscal_tag_descricao,
        };
        this.deleteModal = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.supervisor-tags {
  background-color: #fff;
  border-radius: 8px;
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;

    div {
      cursor: pointer;
    }
  }
  .modal-tag {
    .titulo {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      justify-content: flex-start;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #131e3b;
    }
    .subtitle {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #565e73;
      span {
        color: #003366;
      }
      ul {
        text-decoration: none;
        margin-top: 16px;
      }
    }
    .btn-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .cancel-btn {
        margin-top: 12px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border-radius: 8px;
        background: white;
        color: #131e3b;
        border: none;
        outline: none;

        &:hover {
          background: #e2f4ff;
        }
      }
      .save-btn {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #003366;
        border-radius: 8px;
        color: white;
        border: none;
        outline: none;

        &:hover {
          background: #003366;
        }
      }
    }
  }
  .modal-confirmacao {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .titulo {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #131e3b;
    }
    .subtitle {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #565e73;
      margin-bottom: 12px;
      span {
        color: #003366;
      }
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .save-btn {
        margin: 12px 0px 12px 12px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #003366;
        padding: 8px;
        border-radius: 8px;
        color: white;
        border: none;
        outline: none;

        &:hover {
          background: #003366;
        }
      }
    }
  }
}
</style>

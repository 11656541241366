<template>
  <div>
    <h3 class="intern-title ml-3 mb-1">
      <back-arrow />
      <span class="page-nav">{{ $t("technicalReports") }}</span>
    </h3>

    <div class="card-body">
      <div class="card">
        <div class="d-flex align-items-center">
          <img class="image mr-4" src="../../assets/img/laudo_semanal.svg" />
          <div class="d-flex flex-column">
            <p class="title">Laudo de Medição</p>
            <Button
              @clicked="goToReport()"
              style="width: 48px"
              :name="$t('see')"
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import BackArrow from "../../components/BackArrow.vue";

export default {
  components: {
    Button,
    BackArrow,
  },
  data() {
    return {
      userType: null,
    };
  },
  mounted: async function () {
    this.$store.dispatch("global/setUser").then(() => {
      const user = this.$store.state.global.user;
      this.userType = user.usuario_gestor_tipo_id;
    });
  },
  methods: {
    goToHome() {
      this.$router.push({
        name: "index",
      });
    },
    goToReport() {
      this.$router.push({
        name: "LaudoMensal",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 132%;
  color: #565e73;
}

.image {
  width: 50px;
  height: 50px;
}

.card-body {
  margin-left: -4px;

  .card {
    border: 1px solid #dddee3 !important;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    display: inline-block;
  }
}
</style>

export function formatCPF(cpf) {
    if(cpf) {
      cpf = cpf.replace(/[^\d]/g, "")
      if (cpf.length === 11) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      }
      return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4.$5")
    }
    return 'CPF Inválido'
}

<template>
  <div>
    <div class="form-section">Adicionar localização</div>
    <div class="d-flex">
      <div class="selector-section">
        <multiselect
          v-model="selectedNeighborhoods"
          noOptions=""
          @input="handleNeighborhoodsChange"
          :options="neighborhoodList"
          track-by="name"
          selectLabel=""
          placeholder="Selecionar Bairros"
          :multiple="true"
          label="name"
          class="mb-4"
        ></multiselect>

        <multiselect
          v-model="selectedKeepingSectors"
          noOptions=""
          @input="handleKeepingSectorsChange"
          :options="keepingSectorsList"
          track-by="name"
          selectLabel=""
          placeholder="Selecionar Áreas de Zeladoria"
          :multiple="true"
          label="name"
          :disabled="keepingSectorsListSourceOfTruth.length === 0"
          class="mb-4"
        ></multiselect>

        <Button size="small" name="Adicionar" @clicked="addLocation" />

        <div class="mt-5" v-if="locations.length">
          <div class="form-section">Localizações selecionadas</div>

          <div class="row selected-header">
            <div class="col-4">BAIRRO</div>
            <div class="col-6">ÁREAS DE ZELADORIA</div>
            <div class="col-2" style="text-align: right">AÇÕES</div>
          </div>

          <hr />

          <div
            v-for="(location, index) of locations"
            :key="'local' + location.neighborhoods[0].id + index"
          >
            <div v-if="location.keepingSectors.length">
              <div
                v-for="(keeping, index) of location.keepingSectors"
                :key="'keeping' + keeping.id + index"
                class="row mb-4"
              >
                <div class="col-4 location-name">
                  {{
                    location.neighborhoods[0].name
                      ? location.neighborhoods[0].name
                      : "--"
                  }}
                </div>
                <div class="col-6">
                  <div class="keeping-container">
                    <span class="location-keeping"> {{ keeping.name }} </span>
                  </div>
                </div>
                <div
                  @click="
                    modalRemoveLocation = true;
                    locationToRemove = location;
                  "
                  class="col-2 d-flex align-items-center pointer justify-content-end"
                >
                  <svg
                    class="remove-icon"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.334 3.00004H13.6673V4.33337H12.334V13C12.334 13.1769 12.2637 13.3464 12.1387 13.4714C12.0137 13.5965 11.8441 13.6667 11.6673 13.6667H2.33398C2.15717 13.6667 1.9876 13.5965 1.86258 13.4714C1.73756 13.3464 1.66732 13.1769 1.66732 13V4.33337H0.333984V3.00004H3.66732V1.00004C3.66732 0.82323 3.73756 0.65366 3.86258 0.528636C3.9876 0.403612 4.15717 0.333374 4.33398 0.333374H9.66732C9.84413 0.333374 10.0137 0.403612 10.1387 0.528636C10.2637 0.65366 10.334 0.82323 10.334 1.00004V3.00004ZM11.0007 4.33337H3.00065V12.3334H11.0007V4.33337ZM5.00065 1.66671V3.00004H9.00065V1.66671H5.00065Z"
                      fill="#131E3B"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mb-4">
                <div class="col-4 location-name">
                  {{
                    location.neighborhoods[0].name
                      ? location.neighborhoods[0].name
                      : "--"
                  }}
                </div>
                <div class="col-6">
                  <div class="keeping-container">
                    <span class="location-keeping">Todas</span>
                  </div>
                </div>
                <div
                  @click="
                    modalRemoveLocation = true;
                    locationToRemove = location;
                  "
                  class="col-2 d-flex align-items-center pointer justify-content-end"
                >
                  <svg
                    class="remove-icon"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.334 3.00004H13.6673V4.33337H12.334V13C12.334 13.1769 12.2637 13.3464 12.1387 13.4714C12.0137 13.5965 11.8441 13.6667 11.6673 13.6667H2.33398C2.15717 13.6667 1.9876 13.5965 1.86258 13.4714C1.73756 13.3464 1.66732 13.1769 1.66732 13V4.33337H0.333984V3.00004H3.66732V1.00004C3.66732 0.82323 3.73756 0.65366 3.86258 0.528636C3.9876 0.403612 4.15717 0.333374 4.33398 0.333374H9.66732C9.84413 0.333374 10.0137 0.403612 10.1387 0.528636C10.2637 0.65366 10.334 0.82323 10.334 1.00004V3.00004ZM11.0007 4.33337H3.00065V12.3334H11.0007V4.33337ZM5.00065 1.66671V3.00004H9.00065V1.66671H5.00065Z"
                      fill="#131E3B"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map-section">
        <div style="height: calc(100vh - 220px)">
          <GmapMap
            ref="googleMap"
            :center="center"
            :zoom="zoom"
            style="height: 100%; width: 100%"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: true,
              mapTypeId: 'satellite',
              gestureHandling: 'greedy',
              fullscreenControl: false,
              disableDefaultUi: true,
            }"
          >
            <div v-for="polygon in polygons" :key="'polygon-' + polygon.id">
              <GmapPolygon
                @click="handleClickPolygon(polygon)"
                @mouseover="
                  polygon.zeladoria_id || polygon.bairro_id
                    ? ''
                    : (polygon.display_name = true)
                "
                @mouseout="
                  polygon.zeladoria_id || polygon.bairro_id
                    ? ''
                    : (polygon.display_name = false)
                "
                :path="polygon.latlngs"
                :options="polygon.options"
              />

              <GmapCustomMarker
                v-if="polygon.display_name"
                :draggable="false"
                :marker="polygon.center"
              >
                <span class="map-label">{{ polygon.nome }}</span>
              </GmapCustomMarker>
            </div>
          </GmapMap>
        </div>
      </div>

      <Modal
        class="modal-confirmacao"
        v-show="modalRemoveLocation"
        :width="413"
        @close="modalRemoveLocation = false"
      >
        <template v-slot:header>
          <div class="modal-title">Excluir localizações</div>
        </template>
        <template v-slot:body>
          <div class="modal-body" v-if="locationToRemove">
            Tem certeza que deseja excluir a localização adicionada?
            <div
              v-if="
                locationToRemove.neighborhoods[0].id &&
                locationToRemove.keepingSectors.length
              "
            >
              Tanto o bairro
              <span>{{ locationToRemove.neighborhoods[0].name }}</span
              >, quanto a área de zeladoria
              <span
                v-for="keeping of locationToRemove.keepingSectors"
                :key="'list ' + keeping"
                >{{ keeping.name }}
              </span>
              será excluída.
            </div>
            <div v-else-if="locationToRemove.neighborhoods[0].id">
              O bairro
              <span>{{ locationToRemove.neighborhoods[0].name }}</span>
              será excluído.
            </div>
            <div v-else-if="locationToRemove.keepingSectors.length">
              A área de zeladoria
              <span
                v-for="keeping of locationToRemove.keepingSectors"
                :key="'list ' + keeping"
                >{{ keeping.name }}
              </span>
              será excluída.
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div
            class="d-flex justify-content-center flex-column align-items-center w-100"
          >
            <div class="d-flex justify-content-between">
              <Button
                :width="110"
                size="small"
                name="Confirmar"
                @clicked="removeLocation"
              />
            </div>

            <div class="d-flex justify-content-between mt-3">
              <Button
                size="small"
                type="white-outline"
                name="Cancelar"
                @clicked="modalRemoveLocation = false"
              />
            </div>
          </div>
        </template>
      </Modal>
    </div>

    <div class="btns-container">
      <Button
        type="outline-danger"
        size="small"
        name="Cancelar pesquisa"
        @clicked="$emit('cancel')"
      />
      <div>
        <Button
          size="small"
          type="white"
          class="mr-3"
          :disabled="!locations.length"
          name="Salvar como rascunho"
          @clicked="saveAsDraft"
        />
        <Button
          size="small"
          name="Enviar para aprovação"
          :disabled="!locations.length"
          @clicked="sendToApproval"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import GmapCustomMarker from "vue2-gmap-custom-marker";

import Button from "../../../components/Button.vue";
import Modal from "../../../components/Modal.vue";

import services from "../../../services";
import { formatForGoogleMaps } from "../../../utils/geoMaps";
import { decrypt } from "../../../utils/decrypt";

export default {
  name: "LocationSection",
  components: {
    Multiselect,
    GmapCustomMarker,
    Button,
    Modal,
  },
  data() {
    return {
      zoom: 4,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      polygons: [],
      neighborhoodList: [],
      selectedNeighborhoods: [],
      keepingSectorsList: [],
      keepingSectorsListSourceOfTruth: [],
      selectedKeepingSectors: [],
      locations: [],
      modalRemoveLocation: false,
      cityId: null,
      locationToRemove: null,
    };
  },
  watch: {
    selectedKeepingSectors(newAray) {
      this.keepingSectorsList = this.keepingSectorsListSourceOfTruth;
      this.keepingSectorsList = this.keepingSectorsList.filter(
        ({ id }) => !newAray.some((item) => item.id == id)
      );
    },
  },
  async mounted() {
    const encrypted = localStorage.getItem("ama");
    const decrypted = decrypt(encrypted);
    this.cityId = JSON.parse(decrypted).usuario_cidade_id;

    this.$nextTick(() => {
      const map = document.querySelector(".vue-map");
      map.style.borderRadius = "8px";
    });
    if (this.$route.params.id) {
      await this.getLocations();
    }
    await this.initNeighborhoods();
  },
  methods: {
    async getLocations() {
      this.locations = [];
      const { data, errors } = await services.researches.getLocations(
        this.$route.params.id
      );
      if (!errors) {
        data.forEach((item) => {
          if (item.cidade_id) {
            this.locations.push({
              neighborhoods: [
                {
                  id: 0,
                  name: "Todos os bairros",
                },
              ],
              keepingSectors: [],
              id: item.atividade_pesquisa_local_id,
            });
          } else if (item.bairro_id) {
            this.locations.push({
              neighborhoods: [
                {
                  id: item.bairro_id,
                  name: item.bairro_nome,
                },
              ],
              keepingSectors: [],
              id: item.atividade_pesquisa_local_id,
            });
          } else if (item.zeladoria_id) {
            this.locations.push({
              neighborhoods: [
                {
                  id: null,
                  name: item.bairro_nome_zeladoria,
                },
              ],
              keepingSectors: [
                {
                  id: item.zeladoria_id,
                  name: item.zeladoria_nome,
                },
              ],
              id: item.atividade_pesquisa_local_id,
            });
          }
        });
      }
    },
    async initNeighborhoods() {
      let { data, errors } =
        await services.neighborhoods.getNeighborhoodMapWithEvaluation();

      if (this.locations.length) {
        //remove bairros que ja tem todas as AZ selecionadas
        const neighborhoodsWithAllKeepingSectors = this.locations.filter(
          (location) => {
            return (
              location.neighborhoods.length > 0 &&
              location.keepingSectors.length === 0
            );
          }
        );
        const neighborhoodsToRemove = neighborhoodsWithAllKeepingSectors
          .map((item) => {
            return item.neighborhoods.map((n) => n.id);
          })
          .flat();

        data = data.filter(
          (neighborhood) =>
            !neighborhoodsToRemove.includes(neighborhood.bairro_id)
        );
      }

      if (!errors) {
        this.formatNeighborhoodList(data);
        this.formatNeighborhoodToRender(data);
        this.fitBoundsGoogle();
      }
    },
    async requestKeepingSectors(neighborhoodId) {
      if (!neighborhoodId) return;

      let { data, errors } =
        await services.keepingSectors.getKeepingSectorMapByNeighborhood(
          neighborhoodId
        );
      if (!errors) {
        data = data.map((item) => {
          return {
            ...item,
            limite: item.zeladoria_limite[0],
            lat: item.zeladoria_lat,
            lng: item.zeladoria_lng,
            id: item.zeladoria_id,
            nome: item.zeladoria_nome,
          };
        });

        if (this.locations.length) {
          // remove as localizações ja selecionadas previamente do retorno
          const dataIds = data.map((item) => item.zeladoria_id);
          const currentIds = this.locations
            .map((location) => location.keepingSectors.map((item) => item.id))
            .flat();
          const intersection = dataIds.filter((dataId) =>
            currentIds.includes(dataId)
          );
          data = data.filter((item) => !intersection.includes(item.id));
        }

        if (!data.length) return;

        this.formatKeepingSectorsList(data);
        this.formatMapToRender(data);
        this.fitBoundsGoogle();
      }
    },
    async createNewLocation(item) {
      if (item.keepingSectors.length) {
        item.keepingSectors.forEach(async (sector) => {
          const request = {
            atividade_pesquisa_id: this.$route.params.id,
            zeladorias: [sector.id],
            //bairros: [item.neighborhoods[0].id],
          };

          const { errors } = await services.researches.addNewLocation(request);
          if (errors) {
            this.$toast.error("Ocorreu um ao salvar a localização!", {
              position: "top",
              duration: 3000,
            });
          }
        });
        return;
      }

      let request = {
        atividade_pesquisa_id: this.$route.params.id,
        bairros: [item.neighborhoods[0].id],
      };

      if (item.neighborhoods[0].id === 0) {
        request.cidade_id = this.cityId;
        delete request.bairros;
      }

      const { errors } = await services.researches.addNewLocation(request);
      if (errors) {
        this.$toast.error("Ocorreu um ao salvar a localização!", {
          position: "top",
          duration: 3000,
        });
      }
    },
    async saveAsDraft() {
      for (let i = 0; i < this.locations.length; i++) {
        if (!this.locations[i].id) {
          await this.createNewLocation(this.locations[i]);
        }
      }

      await this.getLocations();
      this.$emit("save-draft");
    },
    async sendToApproval() {
      for (let i = 0; i < this.locations.length; i++) {
        if (!this.locations[i].id) {
          await this.createNewLocation(this.locations[i]);
        }
      }

      await this.getLocations();
      this.$emit("send-approval");
    },
    async removeLocation() {
      if (this.locationToRemove.id) {
        const { errors } = await services.researches.removeLocation(
          this.locationToRemove.id
        );
        if (!errors) {
          this.$toast.success("Localização removida com sucesso!", {
            position: "top",
            duration: 3000,
          });
        }
      }

      await this.getLocations();
      this.modalRemoveLocation = false;
    },
    async handleNeighborhoodsChange(neighborhoods) {
      if (neighborhoods.length > 1) {
        neighborhoods.shift();
        this.selectedNeighborhoods = neighborhoods;
      }

      this.keepingSectorsList = [];
      this.keepingSectorsListSourceOfTruth = [];
      this.bounds = [];
      this.polygons = [];

      if (neighborhoods.length === 0 || neighborhoods[0].id === 0) {
        this.selectedKeepingSectors = [];
        await this.initNeighborhoods();
        return;
      }

      await Promise.all(
        neighborhoods.map(async (value) => {
          await this.requestKeepingSectors(value.id);
        })
      );
    },
    formatKeepingSectorsList(data) {
      this.keepingSectorsListSourceOfTruth = this.keepingSectorsList.concat(
        data.map((item) => {
          return {
            id: item.zeladoria_id,
            name: item.zeladoria_nome,
          };
        })
      );
      this.keepingSectorsList = this.keepingSectorsList.concat(
        data.map((item) => {
          return {
            id: item.zeladoria_id,
            name: item.zeladoria_nome,
          };
        })
      );
    },
    formatNeighborhoodList(data) {
      this.neighborhoodList =
        data.map((item) => {
          return {
            id: item.bairro_id,
            name: item.bairro_nome,
          };
        }) || [];
      this.neighborhoodList.unshift({
        id: 0,
        name: "Todos os Bairros",
      });
    },
    formatNeighborhoodToRender(data) {
      data.map((item) => {
        if (item.bairro_limite) {
          let polygon = {
            latlngs: formatForGoogleMaps(item.bairro_limite[0]),
            options: {
              strokeColor: "#808080",
              fillColor: "#808080",
            },
            center: {
              lat: item.bairro_lat,
              lng: item.bairro_lng,
            },
            id: item.bairro_id,
            nome: item.bairro_nome,
            display_name: item.bairro_id ? true : false,
            selected: item.bairro_id ? true : false,
            bairro_id: item.bairro_id ? item.bairro_id : null,
          };

          polygon.latlngs.map((item) => {
            this.bounds.push(item);
          });

          this.polygons.push(polygon);
        }
      });
    },
    formatMapToRender(data) {
      data.map((item) => {
        if (item.limite !== "" && item.limite !== null) {
          let polygon = {
            latlngs: formatForGoogleMaps(item.limite),
            options: {
              strokeColor: item.zeladoria_id ? "#00ff00" : "#808080",
              fillColor: item.zeladoria_id ? "#00ff00" : "#808080",
            },
            center: {
              lat: item.lat,
              lng: item.lng,
            },
            id: item.id,
            nome: item.nome,
            display_name: item.zeladoria_id ? true : false,
            selected: item.zeladoria_id ? true : false,
            zeladoria_id: item.zeladoria_id ? item.zeladoria_id : null,
          };

          polygon.latlngs.map((item) => {
            this.bounds.push(item);
          });
          this.polygons.push(polygon);
        }
      });
    },
    handleClickPolygon(polygon) {
      if (!polygon.zeladoria_id) return;

      if (!this.polygons.some((item) => !item.selected)) {
        this.selectedKeepingSectors = this.polygons
          .filter((item) => item.selected)
          .map((item) => {
            return {
              id: item.id,
              name: item.nome,
            };
          });
      }

      if (polygon.selected) {
        polygon.selected = false;
        polygon.options.strokeColor = "#808080";
        polygon.options.fillColor = "#808080";
        this.selectedKeepingSectors = this.selectedKeepingSectors.filter(
          (item) => item.id !== polygon.id
        );
      } else {
        polygon.selected = true;
        polygon.options.strokeColor = "#00ff00";
        polygon.options.fillColor = "#00ff00";
        this.selectedKeepingSectors.push({
          id: polygon.id,
          name: polygon.nome,
        });
      }

      if (this.polygons.every((item) => item.selected)) {
        this.selectedKeepingSectors = [];
      }
    },
    addLocation() {
      if (!this.selectedNeighborhoods.length) return;

      if (this.selectedNeighborhoods[0].id === 0) {
        this.locations = [];
      }

      this.locations.push({
        neighborhoods: this.selectedNeighborhoods,
        keepingSectors: this.selectedKeepingSectors,
      });

      this.selectedNeighborhoods = [];
      this.selectedKeepingSectors = [];

      this.keepingSectorsList = [];
      this.keepingSectorsListSourceOfTruth = [];
      this.bounds = [];
      this.polygons = [];

      this.initNeighborhoods();
    },
    handleKeepingSectorsChange(keepingSectors) {
      // se nenhuma zeladoria estiver selecionada, todas são consideradas selecionadas
      if (!keepingSectors.length) {
        this.polygons.map((item) => {
          item.options.strokeColor = "#00ff00";
          item.options.fillColor = "#00ff00";
          item.selected = true;
        });
        return;
      }

      this.polygons.map((item) => {
        if (keepingSectors.find((sector) => sector.id === item.id)) {
          item.options.strokeColor = "#00ff00";
          item.options.fillColor = "#00ff00";
          item.selected = true;
        } else {
          item.options.strokeColor = "#808080";
          item.options.fillColor = "#808080";
          item.selected = false;
        }
      });
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-section {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #131e3b;
  margin-bottom: 24px;
}

.selector-section {
  padding-right: 32px;
  width: 50%;
}

.map-section {
  width: 50%;
}

.form-section {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #131e3b;
  margin: 24px 0px;
}

.selected-header {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
  line-height: 156%;
  color: #838998;
  padding-top: 32px;
}

.location-name {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 156%;
  color: #131e3b;

  display: flex;
  align-items: center;
}

.keeping-container {
  max-width: 180px;

  .location-keeping {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #00b1eb;
  }
}

.remove-icon {
  &:hover {
    opacity: 0.8;
  }
}

.modal-title {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: #131e3b !important;
}

.modal-body {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #565e73;

  span {
    color: #131e3b;
    font-weight: 500;
  }
}

.btns-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 52px;
}
</style>

<template>
  <div class="mt-4">
    <transition name="left">
      <div v-if="step === 1">
        <DefinitionsSection
          @cancel-form="$emit('cancel-form')"
          @next="$emit('next')"
        />
      </div>
    </transition>
    <transition :name="middle">
      <div v-if="step === 2">
        <QuestionsSection
          @cancel-form="$emit('cancel-form')"
          @next="$emit('next')"
        />
      </div>
    </transition>
    <transition name="right">
      <div v-if="step === 3">
        <LocationSection
          @cancel="$emit('cancel-form')"
          @save-draft="$emit('save-draft')"
          @send-approval="$emit('send-approval')"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import QuestionsSection from "./QuestionsSection.vue";
import LocationSection from "./LocationSection.vue";
import DefinitionsSection from "./DefinitionsSection.vue";

export default {
  name: "FormContainer",
  components: {
    QuestionsSection,
    LocationSection,
    DefinitionsSection,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      middle: "right",
    };
  },
  watch: {
    step: function (newVal, oldVal) {
      if (newVal > oldVal) {
        this.middle = "right";
      } else {
        this.middle = "left";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.right-enter-active,
.left-enter-active {
  transition: 0.5s;
}

.left-leave-active,
.right-leave-active {
  transition: 0s;
}

.right-enter {
  transform: translate(100%, 0);
}

.left-enter {
  transform: translate(-100%, 0);
}
</style>

export default httpClient => ({
    getZelador: async (data) => {
      const response = await httpClient.get(`/user/${data.id}`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getVerifica: async () => {
      const response = await httpClient.get(`/activity`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getMonitor: async (data) => {
      const response = await httpClient.post(`/user-monitor/analytics`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getMonitores: async () => {
      const response = await httpClient.get(`/user-monitor`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getAvaliacaoMonitor: async () => {
      const response = await httpClient.post(`/user-monitor/monitor-general-analytics`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getMonitorExecucoes: async (data) => {
      const response = await httpClient.post(`/user-monitor/keeper-analytics`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    postHorasTrabalhadas: async (data) => {
      const response = await httpClient.post(`/execution/worked-hours`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    postMediaExecucoes: async (data) => {
      const response = await httpClient.post(`/execution/date-average-count`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    postExecucoesCiclo: async (data) => {
      const response = await httpClient.post(`/execution/cycle-average-count`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    postAvaliacao: async (data) => {
      const response = await httpClient.post(`/execution/execution-score-cycle`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    postAvaliacaoFiscalMonitor: async (data) => {
      const response = await httpClient.post(`/execution-avaliacao/evaluation-cycle`, data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getQuestionario: async (data) => {
      const response = await httpClient.get(`/user-onboarding/${data.id}`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
  })

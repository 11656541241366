export function formatDateToLocale(date) {
  var dateInstance = new Date(date)
  return dateInstance.toLocaleDateString()
}

export function formatDate(date) {
  if (date) {
    if (date.toString().includes("GMT")) {
      return date
        .toISOString()
        .slice(0, -5)
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    } else {
      return date.split("T")[0].split("-").reverse().join("/");
    }
  } else {
    return "";
  }
}
export function formatTime(time) {
  if (time) {
    return time.split("T")[1].split(":").slice(0, 2).join(":");
  } else return "-";
}

export function formatMilisecondsToTime(timeInMiliseconds) {
  let s = Math.floor(timeInMiliseconds / 1000);
  let m = Math.floor(s / 60);
  let h = Math.floor(m / 60);

  s = s % 60;
  m = m % 60;


  s < 10 ? s = `0${s}` : s = `${s}`
  m < 10 ? m = `0${m}` : m = `${m}`
  h < 10 ? h = `0${h}` : h = `${h}`


  return `${h}:${m}:${s}`;
}

export function actualMonth() {
  return (
    dateToText(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString()
    ) +
    " - " +
    dateToText(new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      7
    ).toISOString())
  )
}

export function dateToText(date) {
  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const month = monthNames[new Date(date).getMonth()];
  return (
    date.split("T")[0].split("-")[2] +
    " " +
    month +
    " " +
    date.split("T")[0].split("-")[0]
  );
}

export function todayLess15Days() {
  let date = new Date();
  date.setDate(date.getDate() - 15);
  return date;
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neighborhoods-map"},[_c('h3',{staticClass:"intern-title ml-3"},[_c('back-arrow'),_c('span',{staticClass:"page-nav"},[_vm._v(_vm._s(_vm.$t("neighborhoodsMap")))])],1),_c('transition',{attrs:{"name":"bounce"}},[(!_vm.loadingData)?_c('div',[_c('div',{staticClass:"pl-3 pr-1 pt-2"},[_c('div',{staticStyle:{"height":"calc(100vh - 220px)","position":"relative"}},[_c('GmapMap',{ref:"googleMap",staticStyle:{"height":"100%","width":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: true,
              mapTypeId: _vm.mapType,
              gestureHandling: 'greedy',
              fullscreenControl: false,
              disableDefaultUi: true,
            }}},_vm._l((_vm.polygonNeighborhood),function(polygon){return _c('div',{key:'polygonsKeepingSector' + polygon.id},[_c('GmapPolygon',{ref:"polygon",refInFor:true,attrs:{"paths":polygon.latlngs,"options":polygon.options},on:{"click":function($event){return _vm.goToNeighborhood(polygon.bairro_id)},"mouseover":function($event){polygon.display_name = true},"mouseout":function($event){polygon.display_name = false}}}),(polygon.display_name)?_c('GmapCustomMarker',{attrs:{"draggable":false,"marker":polygon.center}},[_c('span',{staticClass:"map-label"},[_vm._v(_vm._s(polygon.bairro_nome))])]):_vm._e()],1)}),0),_c('div',{staticClass:"mapTypeControllers"},[_c('CSelect',{attrs:{"placeholder":"Tipo","options":_vm.mapTypes,"value":_vm.mapType},on:{"update:value":function($event){_vm.mapType=$event}}})],1)],1),_c('hr'),_c('input',{attrs:{"name":"inputFile","type":"file","accept":".geojson"},on:{"change":function($event){return _vm.processFile($event)}}})])]):_vm._e()]),(_vm.loadingData)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-2 mb-4"},[_c('img',{staticClass:"mb-4",attrs:{"src":require(`@/assets/img/loading.gif`),"alt":"AMA Carregando","width":"150"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
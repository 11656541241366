import { render, staticRenderFns } from "./ForgotPasswordRecife.vue?vue&type=template&id=22450f57&scoped=true"
import script from "./ForgotPasswordRecife.vue?vue&type=script&lang=js"
export * from "./ForgotPasswordRecife.vue?vue&type=script&lang=js"
import style0 from "./ForgotPasswordRecife.vue?vue&type=style&index=0&id=22450f57&prod&scoped=true&lang=css"
import style1 from "./ForgotPasswordRecife.vue?vue&type=style&index=1&id=22450f57&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22450f57",
  null
  
)

export default component.exports
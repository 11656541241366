<template>
  <div class="executions-container">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("executions") }}</span>
    </h3>

    <transition name="bounce">
      <div v-if="!loadingData">
        <div class="d-flex justify-content-between search">
          <div class="ml-3">
            <input
              v-model="searchString"
              class="search-input"
              :placeholder="$t('searchExecution')"
            />
          </div>
          <div class="btn-float">
            <Button
              :name="$t('import')"
              size="small"
              v-if="userType === 1"
              @clicked="$router.push({ name: 'Importação AMA PJ' })"
            ></Button>
          </div>
        </div>
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in activities" :key="item.execucao_uuid">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                {{ item[field.key] }}
              </template>
            </TableCell>
            <TableCell>
              <div class="d-flex justify-content-end">
                <router-link
                  :to="{
                    name: 'DetalheExecucao',
                    params: {
                      execucao_uuid: item.execucao_uuid,
                      id: activityId || manholeId || gardenId || trashId,
                    },
                  }"
                >
                  <svg
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0C16.392 0 20.878 3.88 21.819 9C20.879 14.12 16.392 18 11 18C5.60803 18 1.12203 14.12 0.18103 9C1.12103 3.88 5.60803 0 11 0ZM11 16C13.0395 15.9996 15.0184 15.3068 16.6129 14.0352C18.2074 12.7635 19.3229 10.9883 19.777 9C19.3213 7.0133 18.205 5.24 16.6107 3.97003C15.0163 2.70005 13.0383 2.00853 11 2.00853C8.96173 2.00853 6.98372 2.70005 5.38941 3.97003C3.79509 5.24 2.6788 7.0133 2.22303 9C2.67713 10.9883 3.7927 12.7635 5.38717 14.0352C6.98164 15.3068 8.96056 15.9996 11 16ZM11 13.5C9.80656 13.5 8.66196 13.0259 7.81805 12.182C6.97414 11.3381 6.50003 10.1935 6.50003 9C6.50003 7.80653 6.97414 6.66193 7.81805 5.81802C8.66196 4.97411 9.80656 4.5 11 4.5C12.1935 4.5 13.3381 4.97411 14.182 5.81802C15.0259 6.66193 15.5 7.80653 15.5 9C15.5 10.1935 15.0259 11.3381 14.182 12.182C13.3381 13.0259 12.1935 13.5 11 13.5ZM11 11.5C11.6631 11.5 12.299 11.2366 12.7678 10.7678C13.2366 10.2989 13.5 9.66304 13.5 9C13.5 8.33696 13.2366 7.70107 12.7678 7.23223C12.299 6.76339 11.6631 6.5 11 6.5C10.337 6.5 9.7011 6.76339 9.23226 7.23223C8.76342 7.70107 8.50003 8.33696 8.50003 9C8.50003 9.66304 8.76342 10.2989 9.23226 10.7678C9.7011 11.2366 10.337 11.5 11 11.5Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                </router-link>
              </div>
            </TableCell>
          </TableRow>
          <template v-if="activities.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />
  </div>
</template>

<script>
import { formatDateToLocale } from "../../utils/date";
import { debounce } from "../../utils/debounce";
import services from "../../services";

import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import BackArrow from "../../components/BackArrow.vue";

export default {
  name: "Execuções",
  components: {
    Table,
    TableRow,
    BackArrow,
    TableCell,
    NoResults,
    Button,
    Loading,
  },
  data() {
    return {
      activityId: null,
      manholeId: null,
      gardenId: null,
      trashId: null,
      tagId: null,
      institutionId: null,
      fields: [
        {
          key: "atividade_nome",
          label: this.$t("activity"),
          _style: "min-width:60px",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_nome",
          label: this.$t("user"),
          _style: "min-width:120px",
          filter: false,
          sortable: true,
          sortDirection: null,
        },
        {
          key: "data_formated_created",
          label: this.$t("creationDate"),
          _style: "min-width:80px",
          filter: false,
          sortable: true,
          sortDirection: null,
        },
        {
          key: "zeladoria_id",
          label: this.$t("keepingSector"),
          _style: "min-width:120px",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "data_formated_finished",
          label: this.$t("finishDate"),
          _style: "min-width:60px",
          filter: false,
          sortable: true,
          sortDirection: null,
        },
      ],
      activities: [],
      loadingData: true,
      searchString: null,
      userType: null,
      fetchBy: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
        id: null,
      },
    };
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.handleInitData(this.fetchBy);
    }, 500),
  },
  mounted: async function () {
    this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
    this.activityId = this.$route.params.atividade_id;

    if (!isNaN(this.activityId)) {
      this.pagination.id = +this.activityId;
      this.fetchBy = "activity";
      await this.handleInitData(this.fetchBy);
      this.loadingData = false;
      return;
    }
    if (this.activityId.substring(0, 6) === "bueiro") {
      this.manholeId = this.activityId.substring(7);
      this.fetchBy = "manhole";
      await this.handleInitData(this.fetchBy);
    }
    if (this.activityId.substring(0, 5) === "horta") {
      this.gardenId = this.activityId.substring(6);
      this.fetchBy = "garden";
      await this.handleInitData(this.fetchBy);
    }
    if (this.activityId.substring(0, 7) === "lixeira") {
      this.trashId = this.activityId.substring(8);
      this.fetchBy = "trash";
      await this.handleInitData(this.fetchBy);
    }
    if (this.activityId.substring(0, 3) === "tag") {
      this.tagId = parseInt(this.activityId.substring(4));
      this.fetchBy = "tag";
      this.pagination.id = this.tagId;
      await this.handleInitData(this.fetchBy);
    }
    if (this.activityId.substring(0, 11) === "instituicao") {
      this.institutionId = parseInt(this.activityId.substring(12));
      this.fetchBy = "institution";
      this.pagination.id = this.institutionId;
      await this.handleInitData(this.fetchBy);
    }
    if (this.activityId.substring(0, 9) === "zeladoria") {
      let idDaAtividadeIdDaZeladoria = this.activityId.substring(10).split("-");
      this.fetchBy = "sectorAndActivity";
      await this.handleInitData(this.fetchBy, idDaAtividadeIdDaZeladoria);
    }
    if (this.activityId.substring(0, 6) === "bairro") {
      let idDaAtividadeIdDaBairro = this.activityId.substring(7).split("-");
      this.fetchBy = "neighborhoodAndActivity";
      await this.handleInitData(this.fetchBy, idDaAtividadeIdDaBairro);
    }
    if (this.activityId.substring(0, 7) === "zelador") {
      let idDaAtividadeIdDoZelador = this.activityId.substring(8).split("-");
      this.fetchBy = "userAndActivity";
      await this.handleInitData(this.fetchBy, idDaAtividadeIdDoZelador);
    }
    this.loadingData = false;
  },
  methods: {
    async initDataByManhole() {
      const { data, errors } = await services.executions.getExecutionManhole({
        manholeId: this.manholeId,
      });
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataBySectorAndActivity(ids) {
      const { data, errors } =
        await services.executions.getExecutionBySectorAndActivity({
          activityId: parseInt(ids[0]),
          keepingSectorId: parseInt(ids[1]),
        });
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByUserAndActivity(ids) {
      const { data, errors } =
        await services.executions.getExecutionByUserAndActivity({
          activityId: parseInt(ids[0]),
          userId: parseInt(ids[1]),
        });
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByNeighborhoodAndActivity(ids) {
      const { data, errors } =
        await services.executions.getExecutionByNeighborhoodAndActivity({
          activityId: parseInt(ids[0]),
          neighborhoodId: parseInt(ids[1]),
        });
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByTag() {
      const { data, errors } = await services.executions.getExecutionByTagNew(
        this.pagination
      );
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByInstitution() {
      const { data, errors } =
        await services.executions.getExecutionByInstitutionNew(this.pagination);
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByGarden() {
      const { data, errors } = await services.executions.getExecutionGarden({
        gardenId: this.gardenId,
      });
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByTrash() {
      const { data, errors } = await services.executions.getExecutionTrashCan({
        trashId: this.trashId,
      });
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async initDataByActivity() {
      const { data, errors } =
        await services.executions.getExecutionActivityNew(this.pagination);
      if (!errors) {
        this.formatActivities(data);
      }
    },
    async handleInitData(fetchBy, ids) {
      if (fetchBy === "activity") {
        await this.initDataByActivity();
      }
      if (fetchBy === "manhole") {
        await this.initDataByManhole();
      }
      if (fetchBy === "garden") {
        await this.initDataByGarden();
      }
      if (fetchBy === "trash") {
        await this.initDataByTrash();
      }
      if (fetchBy === "tag") {
        await this.initDataByTag();
      }
      if (fetchBy === "institution") {
        await this.initDataByInstitution();
      }
      if (fetchBy === "sectorAndActivity") {
        await this.initDataBySectorAndActivity(ids);
      }
      if (fetchBy === "neighborhoodAndActivity") {
        await this.initDataByNeighborhoodAndActivity(ids);
      }
      if (fetchBy === "userAndActivity") {
        await this.initDataByUserAndActivity(ids);
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      if (this.fetchBy === "activity") {
        const { data, errors } =
          await services.executions.getExecutionActivityNew(this.pagination);
        if (!errors) {
          if (data.length > 0) {
            let temp = data.map((item) => {
              return this.formatActivity(item);
            });
            temp.forEach((item) => {
              this.activities.push(item);
            });
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      } else {
        $state.complete();
      }
    },
    formatActivities(data) {
      this.activities = data.map((item) => {
        return {
          ...item,
          data_formated_created: formatDateToLocale(item.execucao_data_criacao),
          data_formated_finished: formatDateToLocale(item.execucao_data_fim),
          status_id: item.status_id ? this.formatStatus(item.status_id) : "",
        };
      });
    },
    formatActivity(activity) {
      return {
        ...activity,
        data_formated_created: formatDateToLocale(
          activity.execucao_data_criacao
        ),
        data_formated_finished: formatDateToLocale(activity.execucao_data_fim),
        status_id: activity.status_id
          ? this.formatStatus(activity.status_id)
          : "",
      };
    },
    formatStatus(status) {
      return status === 1 ? this.$t("running") : this.$t("finished");
    },
  },
};
</script>
<style lang="scss" scoped>
.executions-container {
  position: relative;
}

.btn-float {
  position: absolute;
  right: 12px;
  top: 6px;
}

.search {
  margin-bottom: 32px;
}
</style>

<script>
export default {
  name: "AnalyticsTable",
  props: {
    totals: {
      type: Array,
      default: () => [],
    },
    measurements: {
      type: Array,
      default: () => [],
    },
    activities: {
      type: Array,
      default: () => [],
    },
    searchAnalytics: {
      type: String,
      default: "",
    },
  },
  watch: {
    searchAnalytics(val) {
      this.filterRowsById(val);
    },
  },
  methods: {
    filterRowsById(val) {
      const rows = document.querySelectorAll("tbody tr");

      Array.from(rows).forEach((row) => {
        if (row.id.toLowerCase().includes(val.toLowerCase())) {
          row.style.display = "table-row";
        } else {
          row.style.display = "none";
        }

        if (row.id === "total") row.style.display = "table-row";
      });
    },
    appendData() {
      setTimeout(() => {
        const wrapper = document.querySelector("tbody");

        while (wrapper.firstChild) {
          wrapper.removeChild(wrapper.firstChild);
        }

        const totalsRow = document.createElement("tr");
        totalsRow.id = "total";
        const totalElement = document.createElement("td");

        totalElement.innerText = "Total";
        totalElement.style.border = "1px solid #D2D2D2";
        totalElement.style.backgroundColor = "#E6F7FD";
        totalElement.style.fontFamily = "Open Sans";
        totalElement.style.fontWeight = "600";
        totalElement.style.fontSize = "10px";
        totalElement.style.lineHeight = "145%";
        totalElement.style.color = "#565E73";
        totalElement.style.padding = "8px";

        totalsRow.appendChild(totalElement);

        this.totals.forEach((activity) => {
          const tableDataExecutions = document.createElement("td");
          tableDataExecutions.innerText = activity.total_qtd_execucoes || 0;
          tableDataExecutions.style.backgroundColor = "#E6F7FD";
          tableDataExecutions.style.border = "1px solid #D2D2D2";

          const tableDataPhotos = document.createElement("td");
          tableDataPhotos.innerText = activity.total_qtd_fotos || 0;
          tableDataPhotos.style.backgroundColor = "#E6F7FD";
          tableDataPhotos.style.border = "1px solid #D2D2D2";

          totalsRow.appendChild(tableDataExecutions);
          totalsRow.appendChild(tableDataPhotos);
        });

        wrapper.appendChild(totalsRow);

        this.measurements.forEach((measurement) => {
          const row = document.createElement("tr");

          row.id = measurement.zeladoria_nome;
          const keepingSector = document.createElement("td");
          keepingSector.innerText = measurement.zeladoria_nome;
          keepingSector.style.border = "1px solid #D2D2D2";
          keepingSector.style.fontFamily = "Open Sans";
          keepingSector.style.fontWeight = "400";
          keepingSector.style.fontSize = "10px";
          keepingSector.style.lineHeight = "145%";
          keepingSector.style.color = "#00B1EB";
          keepingSector.style.padding = "8px";
          keepingSector.style.cursor = "pointer";

          const icon = document.createElement("img");
          icon.src = require("@/assets/img/tab.svg");
          icon.style.marginLeft = "4px";
          icon.style.marginBottom = "4px";
          icon.style.verticalAlign = "middle";
          keepingSector.appendChild(icon);

          keepingSector.addEventListener("click", () => {
            this.$emit("clicked", measurement);
          });

          row.appendChild(keepingSector);

          this.activities.forEach((item, index) => {
            const executionsTotal = document.createElement("td");
            const photosTotal = document.createElement("td");

            executionsTotal.innerText =
              measurement.atividades[index].qtd_execucoes;
            photosTotal.innerText = measurement.atividades[index].qtd_fotos;

            executionsTotal.style.border = "1px solid #D2D2D2";
            photosTotal.style.border = "1px solid #D2D2D2";

            row.appendChild(executionsTotal);
            row.appendChild(photosTotal);
          });

          wrapper.appendChild(row);
        });

        const rowDataElements = document.querySelector("tbody").children;
        Array.from(rowDataElements).forEach((item, index) => {
          if (index % 2 === 0) {
            item.style.backgroundColor = "#F5F5F5";
          }
        });
      }, 10);
    },
    appendSubheaders() {
      setTimeout(() => {
        const wrapper = document.querySelector(".subheaders");

        while (wrapper.firstChild) {
          wrapper.removeChild(wrapper.firstChild);
        }

        for (let i = 0; i < this.totals.length; i++) {
          const subheaderExecutions = document.createElement("th");
          const subheaderPhotos = document.createElement("th");

          subheaderExecutions.style.border = "1px solid #D2D2D2";
          subheaderPhotos.style.border = "1px solid #D2D2D2";
          subheaderExecutions.style.width = "62px";
          subheaderPhotos.style.width = "62px";
          subheaderExecutions.style.padding = "12px 6px";
          subheaderPhotos.style.padding = "12px 6px";

          subheaderExecutions.innerText = "Execuções";
          subheaderPhotos.innerText = "Fotos";

          wrapper.appendChild(subheaderExecutions);
          wrapper.appendChild(subheaderPhotos);
        }
      }, 10);
    },
  },
};
</script>

<template>
  <div id="content" class="mt-1 w-100">
    <table class="w-100">
      <thead>
        <tr>
          <th rowspan="2" class="azs">
            <div>AZS</div>
          </th>
          <th
            class="header"
            v-for="activity in totals"
            :key="activity.id"
            colspan="2"
          >
            {{ activity.atividade_nome }}
          </th>
        </tr>
        <tr class="subheaders"></tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>

<style scoped>
table,
td,
th {
  text-align: center;
  color: #565e73;
}

th {
  background-color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th.header {
  border: 1px solid #d2d2d2;
  padding: 12px;
  background: #565e73;

  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #ffffff;
  text-transform: uppercase;
}

.azs {
  border: 0.2px solid #d2d2d2;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 145%;
  color: #565e73;
  width: 93px;
}

.subheaders th {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  text-align: center;
  color: #565e73;
}

tbody {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 10px;
  line-height: 145%;
  text-align: center;
  color: #565e73;
}
</style>

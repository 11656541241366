import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueToast from 'vue-toast-notification'
import ViaCep from 'vue-viacep'
import VueSweetalert2 from 'vue-sweetalert2'
import datePicker from 'vue-bootstrap-datetimepicker'
import * as VueGoogleMaps from 'vue2-google-maps'
import InfiniteLoading from 'vue-infinite-loading'
import VCalendar from 'v-calendar'
import VueMask from 'v-mask'
import CoolLightBox from 'vue-cool-lightbox'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'

import moment from 'moment'
import 'moment/locale/es'
moment.locale('pt-BR')

import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import "vue-multiselect/dist/vue-multiselect.min.css";

const infiniteLoadingOptions = {
  props: {
    spinner: 'default',
  },
  system: {
    throttleLimit: 50,
  },
  slots: {
    noMore: '',
  },
}

Vue.use(VCalendar, {
  componentPrefix: 'v'
})

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPS_KEY,
    libraries: 'places,drawing,visualization',
    v: '3'
  },
  componentName: 'googleNovo',
  autobindAllEvents: true,
  installComponents: true
})

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(ViaCep)
Vue.use(VueToast)
Vue.use(CoolLightBox)
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(datePicker)
Vue.config.productionTip = false
Vue.use(InfiniteLoading, infiniteLoadingOptions)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

new Vue({
  i18n,
  router,
  store,
  icons,
  render: (h) => h(App)
}).$mount('#app')

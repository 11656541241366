export default (httpClient) => ({
  getResearches: async (data) => {
    const response = await httpClient.post("/activity-survey/find", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getAllStatus: async () => {
    const response = await httpClient.post("/atividade-pesquisa-status/total");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  removeResearch: async (id) => {
    const response = await httpClient.delete(`/activity-survey/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  createResearch: async (data) => {
    const response = await httpClient.post("/activity-survey", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  updateResearch: async (id, data) => {
    const response = await httpClient.put(`/activity-survey/${id}`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getResearchById: async (id) => {
    const response = await httpClient.get(`/activity-survey/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  addNewQuestion: async (data) => {
    const response = await httpClient.post(
      "/atividade-pesquisa-pergunta",
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  addNewOption: async (data) => {
    const response = await httpClient.post(
      "/atividade-pesquisa-resposta",
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  addNewLocation: async (data) => {
    const response = await httpClient.post("/atividade-pesquisa-local", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getLocationByResearchId: async (id) => {
    const response = await httpClient.get(
      `/atividade-pesquisa-pergunta-id/${id}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  editQuestion: async (data) => {
    const response = await httpClient.put("/atividade-pesquisa-pergunta", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  editOption: async (data) => {
    const response = await httpClient.put("/atividade-pesquisa-resposta", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteQuestion: async (id) => {
    const response = await httpClient.delete(
      `/atividade-pesquisa-pergunta/${id}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteOption: async (id) => {
    const response = await httpClient.delete(
      `/atividade-pesquisa-resposta/${id}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getLocations: async (id) => {
    const response = await httpClient.get(`/atividade-pesquisa-local/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  removeLocation: async (id) => {
    const response = await httpClient.delete(`/atividade-pesquisa-local/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  changeStatus: async (id, data) => {
    const response = await httpClient.put(
      `/activity-survey/status/${id}`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  reportByLocation: async (data) => {
    const response = await httpClient.post(
      "/execucao-timeline-pesquisa/relatorio",
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  reportQuestions: async (data) => {
    const response = await httpClient.post(
      "/execucao-pesquisa-pergunta-resposta/relatorio",
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  requestNeighborhoodsComparative: async (data) => {
    const response = await httpClient.post(
      "execucao-pesquisa-pergunta-resposta/relatorio/comparativo-bairro",
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  requestKeepingSectorsComparative: async (data) => {
    const response = await httpClient.post(
      "execucao-pesquisa-pergunta-resposta/relatorio/comparativo-zeladoria",
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  cancelResearch: async (id, data) => {
    const response = await httpClient.put(
      `/activity-survey/cancela/${id}`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
});

<template>
  <div class="extract">
    <div class="header">
      <div class="header-title">ENTREVISTADO</div>
      <div
        v-for="(header, index) of questions"
        :key="header.id"
        class="header-question"
      >
        {{ index + 1 }}. {{ header.question }}
      </div>
    </div>
    <div class="body"></div>
  </div>
</template>

<script>
export default {
  name: "ExtractPanel",
  data() {
    return {
      questions: [
        {
          question: "Qual o seu nome?",
          id: 2,
        },
        {
          question: "2. O que você considera mais urgente arrumar na sua casa?",
          id: 3,
        },
        {
          question: "2. O que você considera mais urgente arrumar na sua casa?",
          id: 4,
        },
        {
          question: "2. O que você considera mais urgente arrumar na sua casa?",
          id: 5,
        },
        {
          question: "2. O que você considera mais urgente arrumar na sua casa?",
          id: 6,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.extract {
  margin-right: 16px;
  margin-top: 48px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid #dddee3;

  .header-title {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #131e3b;
    width: 300px;
  }

  .header-question {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #131e3b;
    max-width: 150px;
  }
}
</style>

<template>
  <div class="users-page">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">Contratos Zeladores</span>
    </h3>

    <div class="d-flex justify-content-between ml-3 mb-3">
      <div class="w-25">
        <Input
          v-model="searchString"
          :placeholder="$t('searchUser')"
          :search="true"
        />
      </div>
    </div>

    <transition name="bounce">
      <div v-if="!loadingData" class="pl-2">
        <Table :fields="fields" :hasActions="true" @sort="handleSort">
          <TableRow v-for="item in users" :key="item.usuario_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
              <template v-slot:usuario_cpf>
                <span class="table-row-text">{{
                  formatCPF(item.usuario_cpf)
                }}</span>
              </template>
              <template v-slot:contrato>
                <div class="download">
                  <div @click="handleDownloadContractModal(item.usuario_id)">
                    <span class="material-icons-outlined">download</span>
                  </div>
                </div>
              </template>
            </TableCell>
          </TableRow>
          <template v-if="users.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />
    <Modal v-show="downloadContractsModal" :width="600">
      <template v-slot:header>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <p class="remove-modal-title">
            Escolha um Contrato / Distrato para baixar
          </p>
          <span
            style="cursor: pointer"
            @click="handleCloseDownloadModal"
            class="material-icons-outlined"
            >close</span
          >
        </div>
      </template>
      <template v-slot:body>
        <div class="download-options">
          <div
            class="option"
            v-for="contract in userContracts"
            :key="contract.usuario_contrato_id"
          >
            <div class="description">
              <span style="margin-right: 40px"
                >TIPO:
                <b>{{ contract.contrato_zelador_tipo_descricao }}</b></span
              >
              <span
                >DATA:
                <b>{{
                  formatDateToLocale(contract.usuario_contrato_data)
                }}</b></span
              >
            </div>
            <div class="button">
              <a :href="contract.usuario_contrato_url" target="_blank">
                <span class="material-icons-outlined">download</span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import services from "../../services";
import { formatPhone } from "../../utils/phone";
import { formatDateToLocale } from "../../utils/date";
import { formatCPF } from "../../utils/docs";
import { debounce } from "../../utils/debounce";
import Modal from "../../components/Modal.vue";
import BackArrow from "../../components/BackArrow.vue";
import NoResults from "../../components/table/NoResults.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Input from "../../components/form/Input.vue";

export default {
  name: "Zeladores",
  components: {
    Modal,
    Table,
    TableRow,
    TableCell,
    NoResults,
    BackArrow,
    Loading,
    Input,
  },
  data() {
    return {
      date: new Date(),
      fields: [
        {
          key: "usuario_nome",
          label: this.$t("name"),
          _style: "width:33%;",
          sortable: true,
          sortDirection: null,
        },
        {
          key: "usuario_cpf",
          label: this.$t("cpf"),
          _style: "width:33%;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "contrato",
          label: "Contrato / Distrato",
          _style: "width:40%;",
          sortable: false,
          sortDirection: null,
        },
      ],
      users: [],
      loadingData: true,
      userType: null,
      searchString: null,
      downloadContractsModal: null,
      userContracts: [],
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      formatDateToLocale,
      formatCPF,
    };
  },
  mounted: async function () {
    await this.getUsersWithContracts(this.pagination);
    this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
    if (this.userType !== 1) {
      this.$router.push("/dashboard");
    }
    this.loadingData = false;
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      if (newVal) {
        this.pagination.filter = newVal;
      } else {
        this.pagination.filter = null;
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset();
        }
      }
      await this.getUsersWithContracts(this.pagination);
    }, 500),
  },
  methods: {
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getUsersWithContracts(this.pagination);
    },
    async handleDownloadContractModal(userId) {
      const { data } = await services.users.getUserContracts(userId);
      this.userContracts = data;
      this.downloadContractsModal = true;
    },
    handleCloseDownloadModal() {
      this.userContracts = [];
      this.downloadContractsModal = false;
    },
    async getUsersWithContracts() {
      const { data, errors } = await services.users.getUsersWithContracts(
        this.pagination
      );
      if (!errors) {
        this.users = data.map((item, id) => {
          return this.formatUser(item, id);
        });
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.users.getUsersWithContracts(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item, id) => {
            return this.formatUser(item, id);
          });
          temp.forEach((item) => {
            this.users.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },

    formatUser(user, id) {
      return {
        ...user,
        id,
        usuario_telefone_formated: user.usuario_telefone
          ? formatPhone(user.usuario_telefone)
          : "--",
        ativo: user.usuario_ativo == 1 ? "Ativo" : "Inativo",
        zeladoria: user.zeladoria_id == null ? "-" : user.zeladoria_nome,
        usuario_email: user.usuario_email == null ? "-" : user.usuario_email,
        treinamento: user.usuario_recebimento_material_id,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.link-icon {
  cursor: pointer;
  width: 22px;
  margin-top: 50%;
}

.buttons-users {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;

  span {
    cursor: pointer;
  }
}

.users {
  background-color: #fff;
  border-radius: 8px;
}

.download-options {
  .option {
    font-size: 1rem;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #9d9d9d;
    padding: 8px;
    &:hover {
      background: #f2f2f3;
    }
    .description {
      display: flex;
      justify-content: space-between;
      color: #565e73;
    }
    .button {
      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}

.download {
  cursor: pointer;
  background-color: #003366;
  width: 80px;
  margin: 0;

  div {
    display: flex;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    span {
      color: #fff;
    }
  }

  border: 1px solid #003366;
  border-radius: 12px;
  padding: 8px;
}
</style>

<template>
  <div class="w-100 h-100">
    <GmapMap
      @zoom_changed="zoomChanged"
      :key="rerender"
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="height: 100%; width: 100%"
      :options="{
        zoomControl: zoomControl,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        styles: stylesMaps,
        gestureHandling: zoomControl ? 'greedy' : 'none',
        mapTypeId: mapType,
      }"
    >
      <div v-for="(pin, index) of pins" :key="pin.id + Math.random()">
        <GmapCustomMarker
          @click.native="clickPhotoPin(pin, index)"
          class="markerGoogle"
          :draggable="false"
          :marker="pin.center"
        >
          <div class="pinMarker colorSector">
            <span class="qtdAmas">{{ pin.id }}</span>
          </div>
        </GmapCustomMarker>
      </div>
    </GmapMap>
    <div v-if="changeMapType" class="mapTypeControllers">
      <CSelect placeholder="Tipo" :options="mapTypes" :value.sync="mapType">
      </CSelect>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import styleClean from "../../../assets/json/theme-light.json";
import styleMalheiros from "../../../assets/json/theme-malheiros.json";
import styleActive from "../../../assets/json/theme-active.json";
import styleDefault from "../../../assets/json/theme-default.json";
import GmapCluster from "vue2-google-maps/src/components/cluster";
import { formatForGoogleMaps } from "../../../utils/geoMaps";
import services from "../../../services";

Vue.component("GmapCluster", GmapCluster);
export default {
  props: {
    polygons: {
      type: Array,
      required: true,
    },
    pins: {
      type: Array,
      required: true,
    },
    tracking: {
      type: Array,
      default: function () {
        return [];
      },
    },
    bounds: {
      type: Array,
      required: true,
    },
    stepMap: {
      type: Number,
      required: false,
    },
    keepingSectorId: {
      default: null,
      required: false,
    },
    mapView: {
      type: String,
      default: null,
    },
    dates: {
      required: true,
    },
    zoomControl: {
      type: Boolean,
      default: true,
    },
    changeMapType: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 4,
    },
    center: {
      type: Object,
      default: function () {
        return {
          lat: -15.288423,
          lng: -49.851561,
        };
      },
    },
  },
  components: {
    GmapCustomMarker,
  },
  data() {
    return {
      stylesMaps: styleDefault,
      dataheatmap: {},
      dataBlocks: [],
      keepingSectorBlocks: [],
      polygonBlocks: {},
      blocksBounds: [],
      cityCircle: null,
      rerender: Math.random(),
      actualBounds: [],
      circlesInfo: [],
      actualZoom: 4,
      showMarkers: true,
      showHeatMap: true,
      showCircles: false,
      showBlocks: false,
      heatPoints: [],
      clusterStyles: [
        {
          textColor: "white",
          url: require("../../../assets/img/map-cluster.svg"),
          height: 60,
          width: 60,
          textSize: 20,
        },
      ],
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: this.mapView || "satellite",
    };
  },
  computed: {
    verifyZoom() {
      return this.actualZoom >= 14;
    },
  },
  watch: {
    dates: {
      handler: function () {
        if (this.stepMap === 2) {
          this.cleanHeatmap();
          this.fitBoundsGoogle();
        }
      },
    },
    pins: {
      handler: function (newVal) {
        /*  if (this.stepMap === 2) {
          this.showMarkers = false;
        } */
        if (newVal.length > 0 && this.stepMap !== 2) {
          this.verifyPinsPosition();
        }
      },
      deep: true,
    },
  },
  methods: {
    verifyPinsPosition() {
      for (let i = 0; i < this.pins.length; i++) {
        for (let j = i + 1; j < this.pins.length; j++) {
          if (
            this.pins[i].center.lat === this.pins[j].center.lat &&
            this.pins[i].center.lng === this.pins[j].center.lng
          ) {
            this.pins[i].center.lng = this.pins[i].center.lng + 0.00002;
          }
        }
      }
    },
    zoomChanged(zoom) {
      this.actualZoom = zoom;
    },
    clickPhotoPin(pin) {
      this.$router.push(`/fiscal/${pin.uuid}`);
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 750);
    },
    async requestKeepingSectorBlocks() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorBlocks(
          this.keepingSectorId
        );
      if (!errors) {
        this.mountBlocks(data);
      }
    },
    syncHeatmap() {
      this.showHeatMap = !this.showHeatMap;
      this.showHeatMap ? this.generateHeatmap(null) : this.cleanHeatmap();
    },
    syncCircles() {
      this.showCircles = !this.showCircles;
      this.showCircles ? this.generateCircles(null) : this.cleanCircles();
    },
    async syncBlocks() {
      this.showBlocks = !this.showBlocks;
      this.showBlocks
        ? await this.requestKeepingSectorBlocks()
        : this.cleanBlocks();
    },
    cleanCircles() {
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.actualBounds = map.getBounds();
      });
      this.cityCircle.setMap(null);
      this.rerender = Math.random();
      setTimeout(() => {
        this.$refs.googleMap.fitBounds(this.actualBounds, 0);
        if (this.showHeatMap) this.generateHeatmap(null);
      }, 0);
    },
    cleanHeatmap() {
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.actualBounds = map.getBounds();
      });
      this.dataheatmap.setMap(null);
      this.rerender = Math.random();
      setTimeout(() => {
        this.$refs.googleMap.fitBounds(this.actualBounds, 0);
        if (this.showCircles) this.generateCircles(null);
      }, 0);
    },
    cleanBlocks() {
      this.dataBlocks = [];
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.actualBounds = map.getBounds();
      });
      this.rerender = Math.random();
      setTimeout(() => {
        this.$refs.googleMap.fitBounds(this.actualBounds, 0);
      });
      this.keepingSectorBlocks.setMap(null);
    },
    mountBlocks(polygons) {
      if (!polygons) {
        polygons = this.dataBlocks;
      }
      this.dataBlocks = polygons;
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.dataBlocks.forEach((polygon) => {
          this.keepingSectorBlocks = new window.google.maps.Polygon({
            path: formatForGoogleMaps(polygon.quadra_limite[0]),
            strokeColor: "#548aba",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#d4edf7",
            fillOpacity: 0.6,
            map,
            zIndex: 9999999,
          });
        });
        this.keepingSectorBlocks.setMap(map);
      });
    },
    generateCircles(points) {
      if (points === null) {
        points = this.circlesInfo;
      }
      this.circlesInfo = points;
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.circlesInfo.forEach((circle) => {
          this.cityCircle = new window.google.maps.Circle({
            strokeColor: circle.color,
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: circle.color,
            fillOpacity: 0.35,
            map,
            center: circle.center,
            radius: circle.radius,
            zIndex: 9999999,
          });
        });
      });
    },
    generateHeatmap(points) {
      if (points === null) {
        points = this.heatPoints;
      }
      this.heatPoints = points;
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.dataheatmap = new window.google.maps.visualization.HeatmapLayer({
          data: points,
          map: map,
          maxIntensity: 350,
          radius: 10,
          zIndex: 9999999,
        });
      });
    },
    moreZoom() {
      this.$refs.googleMap.$mapObject.setZoom(
        this.$refs.googleMap.$mapObject.getZoom() + 1
      );
    },
    lessZoom() {
      this.$refs.googleMap.$mapObject.setZoom(
        this.$refs.googleMap.$mapObject.getZoom() - 1
      );
    },
    changeTheme(type) {
      if (type === "light") {
        this.stylesMaps = styleClean;
      } else if (type === "dark") {
        this.stylesMaps = styleMalheiros;
      } else if (type === "active") {
        this.stylesMaps = styleActive;
      } else if (type === "default") {
        this.stylesMaps = styleDefault;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.importance {
  z-index: 0 !important;
}

.markerGoogle {
  &:hover {
    cursor: pointer;
  }

  .pinMarker {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 0 50%;
    background: white;
    border: 1px solid white;
    transform: rotate(45deg) !important;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2);
  }

  .pinMarker2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 0 50%;
    background: #131e3b;
    border: 1px solid #131e3b;
    transform: rotate(45deg) !important;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2);
  }

  .colorSector {
    background: white;
    border: 1px solid white;
  }

  .colorSector2 {
    background: #131e3b;
    border: 1px solid #131e3b;
  }

  span.qtdAmas {
    position: absolute;
    color: #131e3b;
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 9px;
    font-size: 1rem;
    font-weight: bold;
    transform: rotate(-45deg) !important;
  }
}

.zoomControllers {
  position: absolute;
  top: 22px;
  right: 27px;
  z-index: 500;

  button {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: #003063;
    border-radius: 50%;
    padding: 7px;
    display: block;
    clear: both;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.mapTypeControllers {
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 500;
  width: 120px;
}

.cluster {
  z-index: 99;
  transform: translateY(-15px);
}
.pin-icon {
  transform: rotate(-45deg) !important;
  position: absolute;
  text-align: center;
  left: 10;
  right: 50;
  top: 10px;
  bottom: 5px;
}
</style>

export default httpClient => ({
  getOrdersNew: async (payload) => {
    const response = await httpClient.post('/service-order-data-table', payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getAtividade: async () => {
    const response = await httpClient.get('/activity')
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getBairro: async (payload) => {
    const response = await httpClient.post('/neighborhood/names-data-table', payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getPoligono: async (data) => {
    const response = await httpClient.get(`/keeping-sector/with-limits/neighborhood/${data.id}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getInfoPoligono: async (data) => {
    const response = await httpClient.get(`/keeping-sector/service-order/${data.id}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  verificarEndereco: async (payload) => {
    const response = await httpClient.post(`service-order/endereco`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  salvar: async (payload) => {
    const response = await httpClient.post(`service-order`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getOsInfo: async (data) => {
    const response = await httpClient.get(`/service-order/${data.id}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  salvarRascunho: async (payload) => {
    const response = await httpClient.put(`/service-order`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  enviarOs: async (payload) => {
    const response = await httpClient.post(`/service-order/analise`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  cancelarOs: async (data) => {
    const response = await httpClient.delete(`/service-order/${data.id}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  historico: async (data) => {
    const response = await httpClient.get(`/service-order-log/${data.id}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  aprovarOs: async (payload) => {
    const response = await httpClient.post(`/service-order/em-andamento`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  revisarOs: async (payload) => {
    const response = await httpClient.post(`/service-order/revisar`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getExecucoes: async (data) => {
    const response = await httpClient.get(`/execution/history/service-order/${data.id}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  putAvaliacao: async (payload) => {
    const response = await httpClient.put(`/execution/score`, payload)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

})


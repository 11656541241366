export default httpClient => ({
  getCity: async () => {
    const response = await httpClient.get('/cidade')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getNeighborhoods: async () => {
    const response = await httpClient.get('/neighborhood/names')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getNeighborhoodsNew: async (payload) => {
    const response = await httpClient.post('/neighborhood/names-data-table', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getDetailsNeighborhood: async (data) => {
    const response = await httpClient.get(`/neighborhood/${data.neighborhoodId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getManholesByNeighborhood: async (data) => {
    const response = await httpClient.get(`/manhole/neighborhood/${data.neighborhoodId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getGardensByNeighborhood: async (data) => {
    const response = await httpClient.get(`/garden/neighborhood/${data.neighborhoodId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getTrashByNeighborhood: async (data) => {
    const response = await httpClient.get(`/trash-can/neighborhood/${data.neighborhoodId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getNeighborhoodDetails: async (data) => {
    const response = await httpClient.get(`/neighborhood/activities/${data.neighborhoodId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getNeighborhoodMap: async () => {
    const response = await httpClient.get(`/neighborhood`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getNeighborhoodMapWithEvaluation: async () => {
    const response = await httpClient.get(`/block-evaluation/neighborhood`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
})

export default {
  namespaced: true,
  state: {
    stateTags: [],
  },
  getters: {
    getStateTags: state => state.stateTags,
  },
  mutations: {
    addTags: (state, payload) => {
      if (!state.stateTags.includes(payload)) {
        state.stateTags.push(payload);
      }
    },
    removeTags: (state, payload) => {
      state.stateTags = state.stateTags.filter((item) => item !== payload);
    },
    removeAllTags: (state) => {
      state.stateTags = [];
    }
  },
  actions: {}
}

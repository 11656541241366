export default httpClient => ({
  getInstitutions: async () => {
    const response = await httpClient.get('/institution')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getInstitutionsNew: async (payload) => {
    const response = await httpClient.post('/institution-data-table', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return { data: response.data, errors }
  },
  postInstitution: async (data) => {
    const response = await httpClient.post('/institution', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  putInstitution: async (data) => {
    const response = await httpClient.put('/institution', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deleteInstitution: async (data) => {
    const response = await httpClient.delete(`/institution/${data.institutionId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deleteInstitutionTag: async (data) => {
    const response = await httpClient.delete(`/tag-institution/${data.tagId}/${data.institutionId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getInstitutionByExecution: async (data) => {
    const response = await httpClient.get(`/institution/execution/${data.executionId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
})

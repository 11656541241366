export default httpClient => ({
  getCandidates: async (pagination) => {
    const response = await httpClient.post('/user/new/ready-to-recruit', {
      pagina: pagination.page,
      linhas: pagination.limit,
      usuario_status_id: pagination.status
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getTabs: async () => {
    const response = await httpClient.get('/zelador/total-candidatos-zelador-id')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getBigNumbers: async () => {
    const response = await httpClient.get('/zelador/total-candidatos-zelador')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getCandidateById: async (userId) => {
    const response = await httpClient.get(`/user/${userId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getCandidatesByName: async (pagination) => {
    const response = await httpClient.post('/user/new/ready-to-recruit-by-name', {
      pagina: pagination.page,
      linhas: pagination.limit,
      usuario_nome: pagination.filter,
      usuario_status_id: pagination.status
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  reproveUser: async (payload) => {
    const response = await httpClient.put('/user/user-to-disqualified', {
      usuario_id: payload.userId,
      usuario_motivo_desclassificacao: payload.justification
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  approveInterviewUser: async (userId) => {
    const response = await httpClient.put('/user/interview-qualified', {
      usuario_id: userId
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  reserveUser: async (data) => {
    const response = await httpClient.put('/user/user-to-reserve', {
      usuario_id: data.userId,
      cnpj: data.cnpj,
      subcontratada_id: data.association,
      email: data.email.toLowerCase(),
      cep: data.usuario_cep,
      endereco: data.usuario_endereco,
      numero: data.usuario_numero,
      complemento: data.usuario_complemento,
      bairro: data.usuario_bairro,
      razao_social: data.usuario_razao_social
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  approveUser: async (data) => {
    const response = await httpClient.put('/user/user-to-keeper', {
      usuario_id: data.userId,
      cnpj: data.cnpj,
      subcontratada_id: data.association,
      email: data.email.toLowerCase(),
      cep: data.usuario_cep,
      endereco: data.usuario_endereco,
      numero: data.usuario_numero,
      complemento: data.usuario_complemento,
      bairro: data.usuario_bairro,
      razao_social: data.usuario_razao_social
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  requestFreeKS: async () => {
    const response = await httpClient.get('/keeping-sector/names/without-keeper')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  vinculateCandidate: async (data) => {
    const response = await httpClient.put('/user/reproved-to-requalified', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  }

})

<template>
  <div class="list-container h-100">
    <ul>
      <li v-for="activity in activitiesData" :key="activity.atividade_id">
        <div
          class="d-flex justify-content-between position-relative p-3 align-items-center"
        >
          <div class="d-flex align-items-center">
            <img
              :src="activity.atividade_icon"
              class="mr-4 ml-2"
              width="32"
              height="32"
            />
            <div>
              <div class="activity-name">{{ activity.atividade_nome }}</div>
              <div class="activity-counter">
                {{ activity.quantidade }}
                {{
                  activity.quantidade === 1
                    ? $t("oneExecution")
                    : $t("moreExecutions")
                }}
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-end switch-elements"
          >
            <div class="pt-2 mr-3">
              <CustomSwitch
                :selected="activity.active"
                @checkedSync="changeSwitch(activity)"
                :disabled="activity.quantidade === 0"
              />
            </div>
            <span
              title="Filtros"
              :class="`
                new-icons pointer filters
                ${activity.quantidade > 0 ? '' : 'disabled'}
                ${activity.active ? 'active' : ''}
              `"
              @click="
                showFilterTag(
                  activity.tags,
                  activity.atividade_id,
                  activity.active
                )
              "
            >
              <svg
                width="14"
                height="14"
                viewBox="1 2 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 0.5V1.5H9L6 6V9.5H4V6L1 1.5H0.5V0.5H9.5Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <Transition name="filter">
            <div
              class="absolute-box w-50"
              v-if="filterModal && activityId === activity.atividade_id"
            >
              <CheckboxFilter
                title="Filtros"
                @clicked="filterModal = !filterModal"
                :tagsData="actualTags"
                @filter-by-tag="filterTag"
                name="Filtre por Tag"
              />
            </div>
          </Transition>
        </div>
        <div class="row p-0 m-0">
          <span class="list-item"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CheckboxFilter from "../components/CheckboxFilter.vue";
import CustomSwitch from "./CustomSwitch.vue";

export default {
  name: "ActivitiesList",
  data() {
    return {
      filterModal: false,
      actualTags: [],
      activityId: 0,
    };
  },
  components: {
    CheckboxFilter,
    CustomSwitch,
  },
  watch: {
    async checkAll() {
      if (this.checkAll) {
        this.activitiesData.forEach(async (item) => {
          if (!item.active && item.quantidade > 0) {
            item.active = true;
            await this.requestActivityData(item.atividade_id);
          }
        });
      } else {
        this.activitiesData.forEach(async (item) => {
          if (item.active && item.quantidade > 0) {
            item.active = false;
            await this.desactivatePins(item.atividade_id);
          }
        });
      }
    },
  },
  props: {
    activitiesData: {
      type: Array,
      required: true,
    },
    checkAll: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    changeSwitch(value) {
      this.activitiesData.forEach((item) => {
        if (value.atividade_id === item.atividade_id) {
          item.active = !item.active;
        }
      });

      if (value.active) {
        this.requestActivityData(value.atividade_id);
      } else {
        this.desactivatePins(value.atividade_id);
      }
    },
    showFilterTag(tags, activityId, active) {
      if (!active) {
        return;
      }
      this.activityId = activityId;
      this.actualTags = tags;
      this.$store.state.map.stateTags.forEach((item) => {
        this.actualTags.forEach((tag) => {
          if (item === tag.tag_id) {
            tag.active = true;
          }
        });
      });
      this.filterModal = !this.filterModal;
    },
    async requestActivityData(id) {
      this.$emit("request-activity-data", id);
    },
    filterTag(tags) {
      this.filterModal = !this.filterModal;
      this.$emit("filter-by-tag", tags, this.activityId);
    },
    desactivatePins(id) {
      this.$emit("desactivate-pins", id);
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-item {
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
  height: 1px;
}

.list-container {
  box-shadow: -1px 20px 18px 3px rgba(160, 168, 189, 0.24);
  border-radius: 16px;
}

.activity-name {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.011em;
  color: #838998;
}

.activity-counter {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  color: #838998;
  margin-top: 3px;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: -40px;
  left: 50%;
}

.filters {
  background-color: #d7e8f3;
  width: 28px;
  height: 28px;
}

.filters.disabled {
  background-color: #ddd;
  pointer-events: none;
}

.filters.active {
  background-color: #003366;
}

.filter-enter-active {
  animation: filter-in 0.3s;
}
.filter-leave-active {
  animation: filter-in 0.3s reverse;
}
@keyframes filter-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
</style>

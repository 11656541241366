<template>
  <div class="card">
    <div class="card-body p-5 d-flex justify-content-center">
      <vue-funnel-graph
        v-if="!loading"
        :width="width"
        :height="height"
        :labels="labels"
        :values="values"
        :colors="colors"
        :sub-labels="subLabels"
        :direction="direction"
        :gradient-direction="gradientDirection"
        :animated="true"
        :display-percentage="true"
      ></vue-funnel-graph>
      <Loading v-else />
    </div>
  </div>
</template>

<script>
import { VueFunnelGraph } from "vue-funnel-graph-js";
import Loading from "../../components/IndexComponents/Loading.vue";

export default {
  components: {
    VueFunnelGraph,
    Loading,
  },
  props: {
    graphData: {
      type: Array,
      default: () => [0, 0, 0, 0, 0],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created: async function () {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      if (window.innerWidth < 1024) {
        this.direction = "vertical";
      } else {
        this.direction = "horizontal";
      }
    },
  },

  watch: {
    graphData: {
      handler(newVal) {
        this.values = newVal;
      },
    },
    loading: {
      handler(newVal) {
        this.loading = newVal;
      },
    },
    direction: {
      handler(newVal) {
        if (newVal === "vertical") {
          this.width = 180;
          this.height = 400;
        } else {
          this.width = 900;
          this.height = 300;
        }
      },
    },
  },

  data() {
    return {
      labels: [
        this.$t("registerLp"),
        this.$t("registerAmas"),
        this.$t("amasFromLp"),
        this.$t("amasWithAccount"),
        this.$t("usersVinculateWithKS"),
      ],
      values: [],
      subLabels: [],
      direction: window.innerWidth < 1024 ? "vertical" : "horizontal",
      subLabelValue: "raw",
      colors: ["#61b2e5", "#003366"],
      gradientDirection: "horizontal",
      displayPercentage: true,
      width: window.innerWidth < 1024 ? 180 : 1000,
      height: window.innerWidth < 1024 ? 400 : 300,
    };
  },
};
</script>
<style lang="scss">
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  color: #003366;
}

.vertical-funnel {
  display: block;
}

.label__value {
  font-family: "Open Sans" !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 86% !important;
  color: #565e73 !important;
}

.label__title,
.label__percentage {
  font-family: "Open Sans" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 156% !important;
  color: #565e73 !important;
}

.label__percentage {
  color: #003063 !important;
}

.svg-funnel-js__label {
  border-color: #9f94ce !important;
}
</style>

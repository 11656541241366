<template>
  <div class="users-page">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("users") }}</span>
    </h3>

    <Button
      @clicked="$router.push({ name: 'LaudoContratos' })"
      class="btn-report"
      :name="$t('report')"
      size="small"
      v-if="userType === 1"
    ></Button>

    <div class="d-flex justify-content-between ml-3 mb-3">
      <div class="w-25">
        <Input
          v-model="searchString"
          :placeholder="$t('searchUser')"
          :search="true"
          :class="tabs[1].active && 'disabled-input'"
          :disabled="tabs[1].active"
        />
      </div>
    </div>

    <div class="tabs ml-3">
      <div
        v-for="(tab, index) in showedTabs"
        :key="'tab' + index"
        :class="tab.active && 'active'"
        @click="handleTabClick(tab)"
        class="tab"
        :style="
          index === 1
            ? 'width: 120px;'
            : index === 0
            ? 'width: 80px;'
            : 'width: 90px;'
        "
      >
        <span
          >{{ tab.name }}
          <div v-if="tab.id === 3" class="distracts-counter">
            {{ distracts.length }}
          </div></span
        >
      </div>
    </div>

    <transition name="bounce">
      <div style="overflow: auto" v-if="!loadingData" class="pl-1">
        <Table
          style="overflow: initial"
          :fields="fieldsView"
          :hasActions="true"
          @sort="handleSort"
        >
          <TableRow v-for="item in usersView" :key="'user' + item.usuario_id">
            <TableCell
              v-for="field in fieldsView"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
              <template v-slot:treinamento>
                <span class="table-row-text">
                  <div v-if="item.treinamento">
                    <Button
                      class="yes-btn"
                      @clicked="redirectToTrainingDetails(item)"
                      :name="$t('yes')"
                      size="small"
                    />
                  </div>
                  <div v-else class="ml-3">{{ $t("no") }}</div>
                </span>
              </template>
              <template v-slot:zeladoria>
                <div class="d-flex align-items-center">
                  <div v-if="item.zeladoria !== '-'" class="d-flex">
                    <span
                      style="color: #d3341d; font-weight: bold"
                      class="table-row-text"
                      >{{ item[field.key] }}</span
                    >
                    <div
                      v-if="userType === 1"
                      @click="deleteKeepingSectorFromUser(item)"
                      class="pl-2 pointer"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1667 3.99999H17.3333V5.66666H15.6667V16.5C15.6667 16.721 15.5789 16.933 15.4226 17.0892C15.2663 17.2455 15.0543 17.3333 14.8333 17.3333H3.16666C2.94564 17.3333 2.73368 17.2455 2.5774 17.0892C2.42112 16.933 2.33332 16.721 2.33332 16.5V5.66666H0.666656V3.99999H4.83332V1.49999C4.83332 1.27898 4.92112 1.06701 5.0774 0.910734C5.23368 0.754454 5.44564 0.666656 5.66666 0.666656H12.3333C12.5543 0.666656 12.7663 0.754454 12.9226 0.910734C13.0789 1.06701 13.1667 1.27898 13.1667 1.49999V3.99999ZM14 5.66666H3.99999V15.6667H14V5.66666ZM10.1783 10.6667L11.6517 12.14L10.4733 13.3183L8.99999 11.845L7.52666 13.3183L6.34832 12.14L7.82166 10.6667L6.34832 9.19332L7.52666 8.01499L8.99999 9.48832L10.4733 8.01499L11.6517 9.19332L10.1783 10.6667ZM6.49999 2.33332V3.99999H11.5V2.33332H6.49999Z"
                          fill="#D3341D"
                        />
                      </svg>
                    </div>
                  </div>
                  <div v-else>--</div>
                </div>
              </template>
              <template v-slot:bairro_nome>
                <span class="neighborhood-name">{{
                  item.bairro_nome || "--"
                }}</span>
              </template>
            </TableCell>
            <TableCell
              class="d-flex justify-content-end align-items-center mb-2"
            >
              <CondensedDropdown
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="options"
              />
            </TableCell>
          </TableRow>
          <template v-if="usersView.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
      </div>
    </transition>
    <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
      <div slot="spinner">
        <div></div>
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>

    <Loading v-if="loadingData" />

    <Modal v-show="editModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label"> {{ $t("editUser") }}</span>
      </template>
      <template v-slot:body>
        <Input name="name" :label="$t('name')" v-model="userNameForEdit" />
        <Input
          class="edit-email-input"
          name="name"
          :label="$t('email')"
          v-model="userEmailForEdit"
        />
        <div class="row mb-3 mt-4 pl-1">
          <div class="col-2">
            <CustomSwitch
              :selected="userIsActive"
              @checkedSync="userIsActive = !userIsActive"
            />
          </div>
          <div class="col-6 d-flex align-items-center">
            <h4 class="remove-modal-subtitle font-weight-bold">
              {{ $t("activeUser") }}
            </h4>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            class="w-50 mr-3"
            :name="$t('cancel')"
            type="outline"
            @clicked="editModal = false"
          />
          <Button class="w-50" :name="$t('save')" @clicked="areYouSureModal = true" />
        </div>
      </template>
    </Modal>



    <Modal v-show="areYouSureModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label"> Tem certeza que deseja alterar os dados do usuário?</span>
      </template>
      <template v-slot:body>
       
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            class="w-50 mr-3"
            :name="$t('cancel')"
            type="outline"
            @clicked="() => {
              areYouSureModal = false
            }"
          />
          <Button class="w-50" :name="$t('save')" @clicked="editUser()" />
        </div>
      </template>
    </Modal>



    <Modal v-show="removeModal" :width="450">
      <template v-slot:header>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <p class="contract-modal-title w-100" style="text-align: center">
            Avalie o Zelador
          </p>
          <span
            @click="
              removeModal = false;
              removeModalStep = 1;
            "
            class="material-icons-outlined pointer"
            >close</span
          >
        </div>
      </template>
      <template v-slot:body>
        <div v-show="removeModalStep === 1">
          <p class="remove-modal-title">
            Antes de desvivular o zelador, conte-nos por que você tomou esta
            decisão
          </p>
          <p class="remove-modal-subtitle">
            Informações adicionais sobre sua decisão
          </p>
          <div class="options">
            <div
              v-for="option in cancellationOptions"
              :key="option.id"
              class="option"
            >
              <input
                :checked="option.selected"
                @change="handleOptionChange(option.id)"
                type="checkbox"
                name=""
                id=""
              />
              <label for="">{{ option.text }}</label>
            </div>
          </div>
          <div v-if="cancellationOptions[6].selected" class="description">
            <Textarea :rows="4" v-model="desvinculationDescription" />
          </div>
        </div>
        <div v-show="removeModalStep === 2">
          <div
            class="d-flex align-items-center justify-content-center flex-column w-100 mt-3"
          >
            <div class="evaluation-text mb-3">
              Para proceder com o distrato deste zelador, faça a avaliação final
              do período compreendido entre o primeiro dia do mês até a data do
              distrato.
            </div>
            <div class="evaluation-date mb-3">
              <svg
                class="mr-2"
                style="margin-bottom: 3px"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.833252 7.00004H14.1666V13C14.1666 13.1769 14.0963 13.3464 13.9713 13.4714C13.8463 13.5965 13.6767 13.6667 13.4999 13.6667H1.49992C1.32311 13.6667 1.15354 13.5965 1.02851 13.4714C0.90349 13.3464 0.833252 13.1769 0.833252 13V7.00004ZM10.8333 1.66671H13.4999C13.6767 1.66671 13.8463 1.73695 13.9713 1.86197C14.0963 1.98699 14.1666 2.15656 14.1666 2.33337V5.66671H0.833252V2.33337C0.833252 2.15656 0.90349 1.98699 1.02851 1.86197C1.15354 1.73695 1.32311 1.66671 1.49992 1.66671H4.16659V0.333374H5.49992V1.66671H9.49992V0.333374H10.8333V1.66671Z"
                  fill="#29334E"
                />
              </svg>
              {{ daysUntilToday() }}
            </div>
            <div class="mb-4">
              <multiselect
                v-model="evaluation"
                :options="evaluationOptions"
                track-by="name"
                :showLabels="false"
                placeholder=""
                :multiple="false"
                label="name"
                class="evaluation-selector"
              ></multiselect>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div
          class="d-flex align-items-center justify-content-center flex-column w-100"
        >
          <Button
            :disabled="canDesvinculate || disabledBtn"
            class="mb-2 deactivate-user-btn"
            size="small"
            type="outline"
            :name="removeModalStep === 2 ? 'Avaliar e distratar' : 'Continuar'"
            @clicked="
              removeModalStep === 2
                ? removeKeepingSector()
                : (removeModalStep = 2)
            "
          />
          <Button
            :name="$t('cancel')"
            @clicked="handleCloseDesvinculationModal"
            class="cancel-deactivate-user-btn"
            type="outline"
            size="small"
          />
        </div>
      </template>
    </Modal>

    <ConfirmDeactivationModal
      v-show="confirmDeactivationModal"
      :userToDistract="userToDistract"
      @close="confirmDeactivationModal = false"
      @refresh="
        confirmDeactivationModal = false;
        users = [];
        (pagination = {
          page: 0,
          sortBy: null,
          filter: null,
          order: null,
        }),
          getUsers();
      "
      @continue="
        confirmDeactivationModal = false;
        removeModal = true;
      "
    />

    <ContractsModal
      v-show="downloadContractsModal"
      :userContracts="userContracts"
      @close="handleCloseDownloadModal"
    />

    <MonitorCancellationModal
      :titulo="'Medição Final'"
      :pins="pins"
      :timeline="timeline"
      :polygons="polygons"
      :tracking="tracking"
      :dates="executionDates"
      :loadingData="loadingData"
      @close="monitorCancellationModal = false"
      @distract="handleDistractClick"
      v-show="monitorCancellationModal"
    />

    <FinalizationModal
      v-show="distractFinalizationModal"
      :user="userToDistract"
      @close="distractFinalizationModal = false"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import Modal from "../../components/Modal.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";
import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import NoResults from "../../components/table/NoResults.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Input from "../../components/form/Input.vue";
import Textarea from "../../components/form/Textarea.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";

import MonitorCancellationModal from "../../components/MonitorCancellationModal.vue";
import FinalizationModal from "./modal/FinalizationModal.vue";
import ContractsModal from "./modal/ContractsModal.vue";
import ConfirmDeactivationModal from "./modal/ConfirmDeactivationModal.vue";

import services from "../../services";

import { formatForGoogleMaps } from "../../utils/geoMaps";
import { formatPhone } from "../../utils/phone";

import { successToast } from "../../utils/toast";
import { debounce } from "../../utils/debounce";

import { decrypt } from "../../utils/decrypt";

export default {
  name: "Zeladores",
  components: {
    Modal,
    Table,
    TableRow,
    TableCell,
    NoResults,
    Button,
    BackArrow,
    CustomSwitch,
    Loading,
    Input,
    Textarea,
    CondensedDropdown,
    Multiselect,
    MonitorCancellationModal,
    FinalizationModal,
    ConfirmDeactivationModal,
    ContractsModal,
  },
  data() {
    return {
      fields: [
        {
          key: "usuario_nome",
          label: this.$t("name"),
          _style: "min-width:120px;",
          sortable: true,
          sortDirection: null,
        },
        {
          key: "usuario_telefone_formated",
          label: this.$t("phone"),
          _style: "max-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "treinamento",
          label: this.$t("training"),
          _style: "max-width:140px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "bairro_nome",
          label: this.$t("neighborhood"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladoria",
          label: this.$t("keepingSector"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      tabs: [
        {
          id: 1,
          name: "Zeladores Ativos",
          active: true,
          show: true,
        },
        {
          id: 3,
          name: "Distratos Pendentes",
          active: false,
          show: this.amaCandidate ? true : false,
        },
        {
          id: 2,
          name: "Zeladores Distratados",
          active: false,
          show: true,
        },
      ],
      cancellationOptions: [
        { id: 1, text: "O zelador encontrou outra ocupação", selected: false },
        {
          id: 2,
          text: "O zelador não tem tempo de realizar as atividades",
          selected: false,
        },
        {
          id: 3,
          text: "O zelador tem dificuldade na relação com o monitor",
          selected: false,
        },
        {
          id: 4,
          text: "O zelador acha o aplicativo difícil de usar",
          selected: false,
        },
        {
          id: 5,
          text: "O aplicativo consome o pacote de dados do zelador",
          selected: false,
        },
        {
          id: 6,
          text: "O zelador não tem perfil para essa função",
          selected: false,
        },
        { id: 7, text: "Outro motivo. Especifique:", selected: false },
      ],
      evaluationOptions: [
        { name: "0%", value: 0 },
        { name: "25%", value: 25 },
        { name: "50%", value: 50 },
        { name: "75%", value: 75 },
        { name: "100%", value: 100 },
      ],
      distractFinalizationModal: false,
      evaluation: null,
      users: [],
      removeModalStep: 1,
      removeModal: false,
      executionDates: {
        start: null,
        end: null,
      },
      desvinculationDescription: null,
      editModal: false,
      areYouSureModal: false,
      userNameForEdit: "",
      userEmailForEdit: "",
      userToDistract: null,
      userIdForEdit: 0,
      isUserActiveForEdit: 0,
      userIsActive: false,
      loadingData: true,
      userType: null,
      searchString: null,
      monitorCancellationModal: false,
      disabledBtn: false,
      downloadContractsModal: null,
      timeline: [],
      polygons: [],
      tracking: [],
      userContracts: [],
      bounds: [],
      amaCandidate: false,
      pins: [],
      confirmDeactivationModal: false,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      distracts: [],
    };
  },
  mounted: async function () {
    await this.getUsers(this.pagination);
    await this.getDistracts();
    this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
    if (this.userType !== 1) {
      this.$router.push("/dashboard");
    }

    if (localStorage.getItem("config-500")) {
      const encrypted = localStorage.getItem("config-500");
      const decrypted = decrypt(encrypted);
      const config = JSON.parse(decrypted);
      this.amaCandidate = config.candidate.config_valor === "1" ? true : false;
    }

  },
  computed: {
    showedTabs: function () {
      return this.tabs.filter((tab) => tab.show);
    },
    canDesvinculate: function () {
      let disabled = true;
      if (
        this.cancellationOptions.some((option) => option.selected) &&
        !this.cancellationOptions[6].selected
      ) {
        disabled = false;
      } else if (
        this.cancellationOptions[6].selected &&
        this.desvinculationDescription
      ) {
        disabled = false;
      } else {
        disabled = true;
      }
      return disabled;
    },
    options: function () {
      return this.tabs[1].active
        ? [
            { label: "Relatório", value: "details" },
            { label: "Calendário", value: "calendar" },
            { label: "Editar", value: "edit" },
            { label: "Galeria", value: "gallery" },
            { label: "Contrato & Distrato", value: "contracts" },
            { label: "Medição Final", value: "user" },
          ]
        : [
            { label: "Relatório", value: "details" },
            { label: "Calendário", value: "calendar" },
            { label: "Editar", value: "edit" },
            { label: "Galeria", value: "gallery" },
            { label: "Contrato & Distrato", value: "contracts" },
          ];
    },
    usersView: function () {
      return this.tabs[1].active
        ? this.distracts
        : this.users.filter((user) => {
            if (this.tabs[0].active) {
              return user.zeladoria_id && user.usuario_status_id === 103;
            } else if (this.tabs[2].active) {
              return !user.zeladoria_id;
            }
          });
    },
    fieldsView: function () {
      if (this.tabs[1].active || this.tabs[2].active) {
        return this.fields.filter((field) => {
          return field.key !== "bairro_nome" && field.key !== "zeladoria";
        });
      } else {
        return this.fields;
      }
    },
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      if (newVal) {
        this.pagination.filter = newVal;
      } else {
        this.pagination.filter = null;
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset();
        }
      }
      await this.getUsers(this.pagination);
    }, 500),
  },
  methods: {
    async handleCondensedDropdownClick(value, item) {
      if (value === "details") {
        this.$router.push({
          name: "DesempenhoZelador",
          params: { id: item.usuario_id },
        });
      }
      if (value === "calendar") {
        this.$router.push({
          name: "CalendarioZelador",
          params: { id: item.usuario_id },
        });
      }
      if (value === "gallery") {
        this.$router.push({
          name: "GaleriaZelador",
          params: { id: item.usuario_id },
        });
      }
      if (value === "edit") {
        this.handleEditClick(item);
      }
      if (value == "contracts") {
        this.handleDownloadContractModal(item.usuario_id);
      }
      if (value == "user") {
        this.loadingData = true;
        this.pins = [];
        this.polygons = [];
        this.bounds = [];
        this.tracking = [];
        this.timeline = [];
        await this.keepingSectorLimits(item.zeladoria_id);
        await this.requestExecutionFilter(item);
        this.loadingData = false;
        this.userToDistract = item;
        this.monitorCancellationModal = true;
        this.$nextTick(() => {
          const map = document.querySelector(".vue-map");
          map.style.borderRadius = "8px";
        });
      }
    },
    calculateDates(item) {
      this.executionDates = {
        start: new Date(
          new Date(item.usuario_contrato_data).getFullYear(),
          new Date(item.usuario_contrato_data).getMonth(),
          1
        ).toISOString(),
        end: item.usuario_contrato_data,
      };
    },
    convertTrackingToLatLng(arrayOfXAndY) {
      let arrayOfLatLng = [];
      if (
        arrayOfXAndY === null ||
        arrayOfXAndY.length === 0 ||
        arrayOfXAndY === undefined
      ) {
        return [];
      }
      arrayOfXAndY.forEach((item) => {
        arrayOfLatLng.push({
          lat: item.x,
          lng: item.y,
        });
      });
      return arrayOfLatLng;
    },
    async requestExecutionFilter(item) {
      this.calculateDates(item);
      const { data, errors } = await services.executions.executionFilter({
        zeladoria_id: item.zeladoria_id,
        data_fim: this.executionDates.end,
        data_inicio: this.executionDates.start,
      });
      if (!errors && data.length) {
        let executions = [];
        data.forEach((execution) => {
          executions.push(execution.execucao_uuid);

          let executionTracking = {
            tracking: this.convertTrackingToLatLng(
              execution.execucao_tracking ? execution.execucao_tracking : []
            ),
            id: execution.execucao_uuid,
            active: true,
          };

          this.tracking.push(executionTracking);
        });
        const response =
          await services.executions.postExecutionTimelineAggregated({
            execucoes: executions,
            tag_id: [],
          });

        this.timeline = response.data.sort(function (a, b) {
          var c = new Date(a.execucao_data_criacao);
          var d = new Date(b.execucao_data_criacao);
          return c - d;
        });
        console.log("timeline", this.timeline);

        let counter = 1;

        this.timeline.forEach((item) => {
          if (item.execucao_timeline) {
            item.execucao_timeline.forEach((timeline) => {
              this.pins.push({
                id: timeline.execucao_timeline_uuid,
                center: {
                  lat: timeline.execucao_timeline_lat,
                  lng: timeline.execucao_timeline_lng,
                },
                number: counter++,
              });
            });
          }
        });
      }
    },
    async keepingSectorLimits(zeladoria_id) {
      const { data, errors } =
        await services.keepingSectors.getDetailsKeepingSector({
          keepingSectorId: zeladoria_id,
        });
      if (!errors) {
        data.forEach((neighborhood) => {
          this.mountLimits(neighborhood);
        });
      }
    },
    mountLimits(local) {
      if (local.zeladoria_limite !== "" && local.zeladoria_limite !== null) {
        const keepingSector = {
          latlngs: formatForGoogleMaps(local.zeladoria_limite[0]),
          options: {
            strokeColor: local.avaliacao_quadra_cor,
            fillColor: local.avaliacao_quadra_cor,
            zIndex: 0,
          },
          center: {
            lat: local.zeladoria_lat,
            lng: local.zeladoria_lng,
          },
          id: local.zeladoria_id,
          bairro_id: local.zeladoria_id,
          bairro_nome: local.zeladoria_nome,
        };
        this.polygons.push(keepingSector);
      }
    },
    async handleDistractClick(data) {
      this.evaluation = data;
      this.loadingData = true;
      const { errors } = await services.users.distractUser({
        usuario_contrato_id: this.userToDistract.usuario_contrato_id,
        usuario_contrato_percentual_distrato: this.evaluation.value,
      });

      if (!errors) {
        this.evaluation = null;
        this.timeline = [];
        this.pins = [];
        this.polygons = [];
        this.monitorCancellationModal = false;
        await this.getDistracts();
        this.distractFinalizationModal = true;
        this.loadingData = false;
      } else {
        this.loadingData = false;
      }
    },
    async handleTabClick(tab) {
      this.tabs.forEach((t) => (t.active = false));
      tab.active = true;
      this.pagination.page = 0;
      this.pagination.filter = null;
      this.searchString = null;
      if (tab.id === 1 || tab.id === 2) this.getUsers(this.pagination);
      else {
        this.loadingData = true;
        await this.getDistracts();
        this.loadingData = false;
      }
    },
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getUsers(this.pagination);
    },
    handleOptionChange(id) {
      this.cancellationOptions.forEach((option) => {
        if (option.id != id) option.selected = false;
        else {
          option.selected = !option.selected;
        }
      });
    },
    handleCloseDesvinculationModal() {
      this.cancellationOptions.forEach((item) => (item.selected = false));
      this.desvinculationDescription = null;
      this.removeModalStep = 1;
      this.removeModal = false;
    },
    async handleDownloadContractModal(userId) {
      const { data } = await services.users.getUserContracts(userId);
      this.userContracts = data;
      this.downloadContractsModal = true;
    },
    handleCloseDownloadModal() {
      this.downloadContractsModal = false;
      setTimeout(() => {
        this.userContracts = [];
      }, 250);
    },
    handleEditClick(item) {
      console.log('item',item )
      this.userIdForEdit = item.usuario_id;
      this.userNameForEdit = item.usuario_nome;
      this.userEmailForEdit = item.usuario_email;
      this.userIsActive = item.usuario_ativo == 1 ? true : false
      this.isUserActiveForEdit = item.usuario_ativo;
      this.isUserActiveForEdit == 1
        ? (this.userIsActive = true)
        : (this.userIsActive = false);
      this.editModal = true;
    },
    deleteKeepingSectorFromUser(item) {
      this.userKeepingSectorId = item.zeladoria_id;
      this.userIdForRemoveKeepingSector = item.usuario_id;
      this.userToDistract = item;
      this.confirmDeactivationModal = true;
    },
    async removeKeepingSector() {
      this.disabledBtn = true;
      const option = this.cancellationOptions.find((option) => option.selected);

      const { errors } = await services.users.desvinculateUser({
        usuario_id: this.userIdForRemoveKeepingSector,
        usuario_contrato_motivo_distrato: option.text,
        usuario_contrato_observacao: this.desvinculationDescription,
        zeladoria_id: this.userKeepingSectorId,
        usuario_contrato_percentual_distrato: this.evaluation.value,
      });
      if (!errors) {
        this.pagination.page = 0;
        await this.getUsers(this.pagination);
        this.removeModal = false;
        this.removeModalStep = 1;
        this.evaluation = null;
        this.disabledBtn = false;
        this.distractFinalizationModal = true;
      }
    },
    async editUser() {
      this.userIsActive
        ? (this.isUserActiveForEdit = 1)
        : (this.isUserActiveForEdit = 0);

      const { errors } = await services.users.updateUser({
        usuario_id: this.userIdForEdit,
        usuario_nome: this.userNameForEdit,
        usuario_email: this.userEmailForEdit.toLowerCase(),
        usuario_ativo: this.isUserActiveForEdit,
      });
      console.log(errors)
      if (!errors) {
        await this.getUsers();
        this.userNameForEdit = "";
        this.userEmailForEdit = "";
        this.userIdForEdit = 0;
        this.isUserActiveForEdit = 0;
        successToast(this.$t("successEditUser"));
        this.editModal = false;
        this.areYouSureModal = false
      }
    },
    redirectToTrainingDetails(item) {
      this.$router.push({
        name: "DetalheTreinamento",
        params: {
          user_id: item.usuario_id,
        },
      });
    },
    async getDistracts() {
      const { data, errors } = await services.users.getDistracts();
      if (!errors) {
        this.distracts = data.data.map((item, id) => {
          return this.formatUser(item, id);
        });
      }
    },
    async getUsers() {
      this.loadingData = true;
      const { data, errors } = await services.users.getUsersPaginated(
        this.pagination
      );
      if (!errors) {
        this.users = data.map((item, id) => {
          return this.formatUser(item, id);
        });
        this.loadingData = false;
      }
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return date.split("T")[0].split("-").reverse().join("/");
    },
    daysUntilToday() {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      return `01/${
        month < 10 ? "0" + month : month
      }/${year} - ${today.getDate()}/${
        month < 10 ? "0" + month : month
      }/${year}`;
    },
    async infiniteHandler($state) {
      if (this.tabs[1].active) {
        return;
      }

      this.pagination.page++;
      const { data, errors } = await services.users.getUsersPaginated(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item, id) => {
            return this.formatUser(item, id);
          });
          temp.forEach((item) => {
            this.users.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    formatUser(user, id) {
      return {
        ...user,
        id,
        usuario_telefone_formated: user.usuario_telefone
          ? formatPhone(user.usuario_telefone)
          : "--",
        ativo: user.usuario_ativo == 1 ? "Ativo" : "Inativo",
        zeladoria: user.zeladoria_id == null ? "-" : user.zeladoria_nome,
        usuario_email: user.usuario_email == null ? "-" : user.usuario_email,
        treinamento: user.usuario_recebimento_material_id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.users-page {
  position: relative;
}

.btn-report {
  position: absolute;
  right: 16px;
  top: 6px;
}

.link-icon {
  cursor: pointer;
  width: 22px;
  margin-top: 50%;
}

.buttons-users {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;

  span {
    cursor: pointer;
  }
}

.users {
  background-color: #fff;
  border-radius: 8px;
}

.remove-modal-title {
  text-align: left;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #131e3b;
}

.contract-modal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #131e3b;
}

.deactivate-user-btn {
  background: #d3341d;
  color: white;
  &:disabled {
    background: #f3c6c0;
    border: none;
  }
}

.cancel-deactivate-user-btn {
  background: white;
  color: #131e3b;
  border: 2px solid #dddee3;
}

.options {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 24px;

  .option {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    text-align: left;
    color: #565e73;
    accent-color: #003366;
    input {
      margin-right: 10px;
    }
  }
}

.remove-modal-subtitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  text-align: left;
  color: #565e73;
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}

.neighborhood-name {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  color: #565e73;
}

.status {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  line-height: 156%;
}

.disabled-input {
  opacity: 0.4;
  cursor: not-allowed;
}

.status-active {
  color: #7b9e1e;
}

.status-inactive {
  color: #838998;
}

.yes-btn {
  background: white;
  border: 1px solid #dddee3;
  color: #131e3b;
}

.download {
  cursor: pointer;
  background-color: #003366;
  width: 80px;
  margin: 0;
  div {
    display: flex;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    span {
      color: #fff;
    }
  }
  border: 1px solid #003366;
  border-radius: 12px;
  padding: 8px;
}

.edit-email-input.input-wrapper > .input {
  text-transform: lowercase !important;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -8px;

  .tab {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    line-height: 148%;
    color: #838998;
    margin-right: 24px;
    cursor: pointer;
    padding: 8px 0px;
    padding-left: 4px;
    padding-bottom: 0px;
    height: 64px;
    display: flex;
    align-items: center;

    &.active {
      border-bottom: 4px solid #00b1eb;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: #131e3b;
      font-weight: 500;
    }
  }

  .distracts-counter {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 132%;
    color: #ffffff;
    background: #ff002e;
    border-radius: 6px;
    padding: 2px 4px;
    margin-left: 4px;
    width: 24px;
    text-align: center;
    display: inline-block;
  }
}

.evaluation-date {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 13px;
  line-height: 156%;
  text-align: center;
  color: #565e73;
}

.evaluation-selector {
  width: 106px;
  z-index: 10;
  margin-right: 16px;
}

.evaluation-text {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  text-align: center;
  color: #565e73;
}

::-webkit-input-placeholder {
  text-transform: none;
}
:-moz-placeholder {
  text-transform: none;
}
::-moz-placeholder {
  text-transform: none;
}
:-ms-input-placeholder {
  text-transform: none;
}
::placeholder {
  text-transform: none;
}
</style>

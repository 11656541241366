export default httpClient => ({
  getUsersManagers: async () => {
    const response = await httpClient.get('/user-manager')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getUsersManagersNew: async (payload) => {
    const response = await httpClient.post('/user-manager-data-table', {
      page: payload.page,
      sortBy: payload.sortBy,
      order: payload.order,
      filter: payload.filter
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  addUserManager: async (data) => {
    const response = await httpClient.post('/user-manager', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deleteUserManager: async (data) => {
    const response = await httpClient.delete(`/user-manager/${data.userId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  editUserManager: async (data) => {
    const response = await httpClient.put(`/user-manager`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  changePassword: async (data) => {
    const response = await httpClient.post(`/user-manager/change-password`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  userInfo: async (data) => {
    const response = await httpClient.get(`/usuario/${data.userId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  userManagersTypes: async () => {
    const response = await httpClient.get(`/user-manager-type`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getUsersFromMonitor: async (data) => {
    const response = await httpClient.get(`/user-monitor/${data.monitorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  postUserForMonitor: async (data) => {
    const response = await httpClient.post(`/user-monitor`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deleteUserFromMonitor: async (data) => {
    const response = await httpClient.delete(`/user-monitor/${data.monitorId}/${data.userId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
})

<template>
  <div class="performance-wrapper">
    <div class="performance-header">
      <h3 class="intern-title ml-3">
        <div class="title-box">
          <div>
            <back-arrow />
            <span class="page-nav">Desempenho do zelador </span>
          </div>
         
          <div v-if="user.usuario_nome" class="subtitulo-nome">
            {{ user.usuario_nome || "" }}
          </div>
        </div>
      </h3>
      <!--aqui-->
      <Button
        class="button-local"
        size="small"
        color="warning"
        :name="$t('SeeLocation')"
        type="primary"
        @clicked="visaoMapa"
      ></Button>
    </div>
    <div class="user-informations">
      <div class="user-photo">
        <img v-if="user.usuario_foto" :src="user.usuario_foto" alt="" />
        <svg
          v-else
          width="48"
          height="63"
          viewBox="0 0 48 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 63C0 56.6348 2.52856 50.5303 7.02944 46.0294C11.5303 41.5286 17.6348 39 24 39C30.3652 39 36.4697 41.5286 40.9706 46.0294C45.4714 50.5303 48 56.6348 48 63H42C42 58.2261 40.1036 53.6477 36.7279 50.2721C33.3523 46.8964 28.7739 45 24 45C19.2261 45 14.6477 46.8964 11.2721 50.2721C7.89642 53.6477 6 58.2261 6 63H0ZM24 36C14.055 36 6 27.945 6 18C6 8.055 14.055 0 24 0C33.945 0 42 8.055 42 18C42 27.945 33.945 36 24 36ZM24 30C30.63 30 36 24.63 36 18C36 11.37 30.63 6 24 6C17.37 6 12 11.37 12 18C12 24.63 17.37 30 24 30Z"
            fill="#565E73"
          />
        </svg>
      </div>
      <div class="info-options">
        <div @click="toggleDados()" class="titulo-info" id="dados-cadastrais">
          <div>Dados Cadastrais</div>
          <img v-if="!hideDados" src="../../assets/img/simpleVector.svg" />
          <img v-else src="../../assets/img/simpleVector-reverse.svg" />
        </div>
        <div v-if="!hideDados" class="user-info-box">
          <div class="user-info-column">
            <div>
              <span class="user-info-bold-font">Rua:</span>
              <span class="user-info-normal-font">{{
                user.usuario_endereco
              }}</span>
            </div>
            <div>
              <span class="user-info-bold-font">Número:</span>
              <span class="user-info-normal-font">{{
                user.usuario_numero
              }}</span>
            </div>
            <div>
              <span class="user-info-bold-font">Complemento:</span>
              <span class="user-info-normal-font">
                {{ user.usuario_complemento }}</span
              >
            </div>
            <div>
              <span class="user-info-bold-font">Bairro: </span>
              <span class="user-info-normal-font">{{
                user.usuario_bairro
              }}</span>
            </div>
          </div>
          <div class="separador-vertical"></div>
          <div class="user-info-column">
            <div>
              <span class="user-info-bold-font">cep:</span>
              <span class="user-info-normal-font"> {{ user.usuario_cep }}</span>
            </div>
            <div>
              <span class="user-info-bold-font">Área de Zeladoria:</span>
              <span class="user-info-normal-font">
                {{ user.zeladoria_nome }}</span
              >
            </div>
            <div>
              <span class="user-info-bold-font">Razão Social:</span>
              <span class="user-info-normal-font">
                {{ user.usuario_razao_social }}</span
              >
            </div>
            <div>
              <span class="user-info-bold-font">CNPJ: </span>
              <span class="user-info-normal-font">{{ user.usuario_cnpj }}</span>
            </div>
          </div>
          <div class="separador-vertical"></div>
          <div class="user-info-column">
            <div>
              <span class="user-info-bold-font">Data de Candidatura:</span>
              <span class="user-info-normal-font">
                {{ formatDateToBR(user.usuario_data_criacao) }}</span
              >
            </div>
            <div>
              <span class="user-info-bold-font">Início do Contrato: </span>
              <span class="user-info-normal-font">{{
                formatDateToBR(user.usuario_contrato_data)
              }}</span>
            </div>
            <div>
              <span class="user-info-bold-font">Associação: </span>
              <span class="user-info-normal-font">{{
                user.subcontratada_descricao || ""
              }}</span>
            </div>
          </div>
        </div>
  
        <!--<div
          v-if="questionario.data.length"
          @click="toggleQuestionario()"
          class="titulo-info titulo-info-hidden"
          id="questionario"
        >
          <div>Questionário</div>
          <img
            v-if="!hideQuestionario"
            src="../../assets/img/simpleVector.svg"
          />
          <img v-else src="../../assets/img/simpleVector-reverse.svg" />
        </div>-->
        <div v-if="!hideQuestionario" class="user-info-box-custom">
          <div class="user-answer-column">
            <div>
              <span class="user-info-bold-font">{{
                questionario.data[0].usuario_onboarding_pergunta
              }}</span>
              <span class="user-info-answer">
                {{ questionario.data[0].usuario_onboarding_resposta }}</span
              >
            </div>
            <div class="separador-horizontal"></div>
            <div>
              <span class="user-info-bold-font">{{
                questionario.data[1].usuario_onboarding_pergunta
              }}</span>
              <span class="user-info-answer">
                {{ questionario.data[1].usuario_onboarding_resposta }}</span
              >
            </div>
            <div class="separador-horizontal"></div>
            <div>
              <span class="user-info-bold-font">{{
                questionario.data[2].usuario_onboarding_pergunta
              }}</span>
              <span class="user-info-answer">
                {{ questionario.data[2].usuario_onboarding_resposta }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separador-titulo">
      <span class="page-nav ml-3">Dados Gerais</span>
    </div>

    <!--<UserCard class="user-card" :user="user" />-->
    <BigNumbers
      :key="bigNumbersKey"
      :dates="range"
      :user="user"
      class="big-numbers ml-3"
    />
    <div class="separador-titulo">
      <span class="page-nav ml-3">Desempenho Mensal</span>
    </div>
    <div class="date ml-3">
      <!-- <CSelect
        style="max-width: 220px"
        :value.sync="selectedMonth"
        @change="handleChangeMonth"
        :options="monthsSelectOptions"
      /> -->
      <month-picker-input
        date-format="%n"
        :default-month="defaultMonth + 1"
        @input="handleMonthSelect"
        lang="pt"
      ></month-picker-input>
    </div>
    <UserCharts class="charts" :key="chartsKey" :user="user" :dates="range" />
  </div>
</template>

<script>
import moment from "moment";
import BackArrow from "../../components/BackArrow.vue";
//import UserCard from "../../components/userPerformance/UserCard.vue";
import BigNumbers from "../../components/userPerformance/BigNumbers.vue";
import UserCharts from "../../components/userPerformance/UserCharts.vue";
import services from "../../services";
import { MonthPickerInput } from "vue-month-picker";
import Button from "../../components/Button.vue";

export default {
  components: {
    BackArrow,
    //UserCard,
    BigNumbers,
    MonthPickerInput,
    UserCharts,
    Button,
  },

  created: async function () {
    this.loadingData = true;
    const currentDate = new Date();
    this.defaultMonth = currentDate.getMonth();
    const { data, errors } = await services.analytics.getZelador({
      id: this.$route.params.id,
    });
    if (!errors) {
      this.user = data;
    }
    const response = await services.analytics.getQuestionario({
      id: this.$route.params.id,
    });
    if (!errors) {
      this.questionario = response;
    }
    const date = new Date();
    this.range.start = new Date(date.getFullYear(), date.getMonth(), 1);
    this.range.end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.loadingData = false;
  },

  mounted: async function () {
    await this.mountMonthsByOneYear();
  },

  data() {
    return {
      loadingData: false,
      bigNumbers: [],
      defaultMonth: null,
      user: null,
      initialDate: null,
      calendarModal: false,
      finalDate: null,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      bigNumbersKey: null,
      chartsKey: null,
      hideDados: false,
      hideQuestionario: true,
      questionario: [],
      selectedMonth: moment().month(),
      monthsSelectOptions: [],
    };
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async handleChangeMonth(ev) {
      let current = null;
      ev
        ? (current = +ev.target.value)
        : (current = +moment().format("YYYY-MM-DD").split("-")[1] - 1);

      const year = this.monthsSelectOptions
        .filter((month) => month.value === this.selectedMonth)[0]
        .label.split("/")[1];

      const lastDay = new Date(year, current + 1, 0).getDate();

      this.range.start = new Date(year, current, 1);
      this.range.end = new Date(year, current, lastDay);

      this.range.start = this.range.start.toISOString().slice(0, -5);
      this.range.end = this.range.end.toISOString().slice(0, -5);

      this.bigNumbersKey = Math.random();
      this.chartsKey = Math.random();

      this.loadingData = false;
    },

    async mountMonthsByOneYear() {
      const actualMonth = moment().month();
      const actualYear = moment().year();
      const months = [];
      for (let i = 0; i < 12; i++) {
        const month = actualMonth - i;
        const year = actualYear;
        if (month < 0) {
          months.push({
            value: month + 12,
            label: `${this.capitalizeFirstLetter(
              moment()
                .locale("pt")
                .year(year - 1)
                .month(month + 12)
                .format("MMMM")
            )}/${year - 1}`,
          });
        } else {
          months.push({
            value: month,
            label: `${this.capitalizeFirstLetter(
              moment().locale("pt").year(year).month(month).format("MMMM")
            )}/${year}`,
          });
        }
      }
      this.monthsSelectOptions = months;
      this.selectedMonth = actualMonth;
    },

    handleMonthSelect(ev) {
      this.range.start = ev.from.toISOString();
      this.range.end = ev.to.toISOString();
      this.bigNumbersKey = Math.random();
      this.chartsKey = Math.random();
    },

    formatDateToBR(date) {
      if (date) {
        let dateFormat = date.split("T");
        let dateSplit = dateFormat[0].split("-");
        let dateBR = dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
        return dateBR;
      }
      return "-";
    },

    visaoMapa() {
      this.$router.push(`/desempenho-zelador/mapa/${this.$route.params.id}`);
    },

    toggleDados() {
      this.hideDados = !this.hideDados;
      const dadosCadastrais = document.getElementById("dados-cadastrais");
      if (this.hideDados) {
        dadosCadastrais.classList.add("titulo-info-hidden");
      } else {
        dadosCadastrais.classList.remove("titulo-info-hidden");
      }
    },

    toggleQuestionario() {
      this.hideQuestionario = !this.hideQuestionario;
      const questionario = document.getElementById("questionario");
      if (this.hideQuestionario) {
        questionario.classList.add("titulo-info-hidden");
      } else {
        questionario.classList.remove("titulo-info-hidden");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.performance-wrapper {
  .performance-header {
    display: flex;
    justify-content: space-between;
  }
  .big-numbers {
    margin: 32px 0;
  }
  .charts {
    margin: 32px 0;
  }
}
.absolute-box {
  position: absolute;
  z-index: 10;
  top: 30px;
}

.send-button {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px 24px;
  background: #003063;
  border-radius: 8px;
  margin: 0px 8px;
  outline: none;
  border: none;

  &:hover {
    background: #002a5a;
  }

  &:active {
    background: #002a5a;
  }
}
.cancel-button {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #565e73;
  margin: 0px 8px;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 8px;

  &:hover {
    background: #f5f5f5;
  }

  &:active {
    background: #e5e5e5;
  }
}
</style>
<style lang="scss" scoped>
.title-box {
  display: flex;
  flex-direction: column;
}
.subtitulo-nome {
  margin: 0px 0px 0px 36px;
  font-size: 14px;
  color: #565e73;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
}
.user-photo {
  margin-right: 40px;
  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }
  svg {
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }
}
.user-informations {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
  width: 100%;
}
.user-info-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px;
  align-items: center;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  justify-content: space-between;
}
.user-info-box-custom {
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 0px 0px 8px 8px;
  width: 100%;
  margin-bottom: 16px;
}
.separador-vertical {
  border: 1px solid #f3f4f5;
  height: 48px;
}
.separador-horizontal {
  border: 1px solid #f3f4f5;
  width: 100%;
  margin: 24px 0px;
}
.info-box-icon {
  margin-right: 10px;
}
.separador-titulo {
  margin: 24px 0px;
}
.button-local {
  margin-top: 16px;
}
.titulo-info {
  background-color: rgba(243, 244, 245, 0.4);
  padding: 18px 24px;
  color: #131e3b;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  cursor: pointer;
}
.titulo-info-hidden {
  border-radius: 8px 8px 8px 8px;
}
.info-options {
  width: 100%;
}
.user-info-normal-font {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #565e73;
  margin-left: 4px;
}
.user-info-bold-font {
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #131e3b;
  text-transform: uppercase;
}
.user-info-answer {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #565e73;
  margin-left: 24px;
}
.user-info-column {
  display: flex;
  flex-direction: column;
}
.user-answer-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 1px) and (max-width: 1083px) {
  .separador-vertical {
    display: none;
  }
}
@media screen and (min-width: 1px) and (max-width: 1012px) {
  .user-informations {
    display: flex;
    flex-direction: column;
  }
}
</style>

export default (httpClient) => ({
  getExecutionCount: async () => {
    const response = await httpClient.get("/execution/count");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  executionFilter: async (data) => {
    const response = await httpClient.post("/execution/filter", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  postExecutionScore: async (data) => {
    const response = await httpClient.put("/execution/score", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionManhole: async (data) => {
    const response = await httpClient.get(
      `/execution/manhole/${data.manholeId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionBySectorAndActivity: async (data) => {
    const response = await httpClient.get(
      `/execution/activity/keeping-sector/${data.activityId}/${data.keepingSectorId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionByUserAndActivity: async (data) => {
    const response = await httpClient.get(
      `/execution/activity/user/${data.activityId}/${data.userId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionByNeighborhoodAndActivity: async (data) => {
    const response = await httpClient.get(
      `/execution/activity/neighborhood/${data.activityId}/${data.neighborhoodId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionByTag: async (data) => {
    const response = await httpClient.get(`/execution/tag/${data.tagId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionByTagNew: async (payload) => {
    const response = await httpClient.post(`/execution/tag-data-table`, {
      tag_id: payload.id,
      page: payload.page,
      sortBy: payload.sortBy,
      order: payload.order,
      filter: payload.filter,
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionByInstitution: async (data) => {
    const response = await httpClient.get(
      `/execution/institution/${data.institutionId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionByInstitutionNew: async (payload) => {
    const response = await httpClient.post(
      `/execution/institution-data-table`,
      {
        instituicao_id: payload.id,
        page: payload.page,
        sortBy: payload.sortBy,
        order: payload.order,
        filter: payload.filter,
      }
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return { data: response.data, errors };
  },
  getExecutionGarden: async (data) => {
    const response = await httpClient.get(`/execution/garden/${data.gardenId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionTrashCan: async (data) => {
    const response = await httpClient.get(
      `/execution/trash-can/${data.trashId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionActivity: async (data) => {
    const response = await httpClient.get(`/execution/${data.activityId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionActivityNew: async (payload) => {
    const response = await httpClient.post(
      `/execution-by-activity-data-table`,
      {
        atividade_id: payload.id,
        page: payload.page,
        sortBy: payload.sortBy,
        order: payload.order,
        filter: payload.filter,
      }
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionTimeline: async (data) => {
    const response = await httpClient.get(
      `/execution/info/${data.executionId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  postExecutionTimeline: async (data) => {
    const response = await httpClient.post(`/execution/info/marker`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  postExecutionTimelineAggregated: async (data) => {
    const response = await httpClient.post(
      `/execution/info/marker-aggregated`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getPhotosByActivity: async (data) => {
    const response = await httpClient.get(
      `/execution/history/activity/${data.activityId}/${data.page}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionTimelineDetail: async (data) => {
    const response = await httpClient.post(`/execution-timeline-list`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getPhotosByKeepingSector: async (data) => {
    const response = await httpClient.get(
      `/execution/history/keeping-sector/${data.keepingSectorId}/${data.page}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getPhotosByNeighborhood: async (data) => {
    const response = await httpClient.get(
      `/execution/history/neighborhood/${data.neighborhoodId}/${data.page}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionsWeekly: async (data) => {
    const response = await httpClient.post(
      `/execution/weekly-productivity-time`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionsCountByDate: async (data) => {
    const response = await httpClient.post(
      `/execution/activity/count-by-date`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionsTotalEvidences: async (data) => {
    const response = await httpClient.post(`/execution/event-count`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionsEngineers: async (data) => {
    const response = await httpClient.post(`/engineer-report`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getExecutionsReport: async (data) => {
    const response = await httpClient.post(`/execution/keeper-period`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getPhotosByUser: async (data) => {
    const response = await httpClient.get(
      `/execution/history/user/${data.userId}/${data.page}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getIntervals: async (data) => {
    const response = await httpClient.post(`/execution/info/interval`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  registerMonitorExecutionEvaluation: async (data) => {
    const response = await httpClient.post(
      `/execution-evaluation/monitor/register`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  reportByInstitutionAndActivity: async (data) => {
    const response = await httpClient.post(`/execution/report`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors: errors,
    };
  },
  recieveToolReport: async (data) => {
    const response = await httpClient.post(`/receive-tool-report`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors: errors,
    };
  },
  finishMonitorExecutionEvaluation: async (data) => {
    const response = await httpClient.post(
      `/execution-evaluation/monitor/finish`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  registerSupervisorExecutionEvaluation: async (data) => {
    const response = await httpClient.post(
      `/execution-evaluation/supervisor/register`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  finishSupervisorExecutionEvaluation: async (data) => {
    const response = await httpClient.post(
      `/execution-evaluation/supervisor/finish`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  countAggregated: async (data) => {
    const response = await httpClient.post(
      `/execution/activity/count-by-date/aggregated`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  getMonthCycles: async (data) => {
    const response = await httpClient.post(
      `/execution-avaliacao/weekCicle`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  getCurrentCycle: async (data) => {
    const response = await httpClient.post(
      `/execution-avaliacao/this-week-cycle-dates`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },

  getMonthReportCycles: async (data) => {
    const response = await httpClient.post(
      `/execution-avaliacao/cycle-dates`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  getMonthReport: async (data) => {
    const response = await httpClient.post(`/execution-avaliacao/report`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
  getTotalKeepingSectorsWithUsers: async (date, keepingSectors) => {
    const response = await httpClient.post(`/keeping-sector/with-keepers`, {
      data_inicio: date,
      zeladoria: keepingSectors
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      dataInterval: response.data,
      errorsInterval: errors,
    };
  },
});

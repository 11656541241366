<template>
  <div class="users">
    <div class="div-titulo">
      <div>
        <h3 class="intern-title ml-3">
          <back-arrow />
          <span class="page-nav"
            >Ordem De Serviço {{ OsInfo.ordem_servico_id }}</span
          >
        </h3>

        <div>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Em Criação'"
            class="em-criacao status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Revisar'"
            class="revisar status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Finalizada'"
            class="finalizada status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Cancelada'"
            class="revisar status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Em Andamento'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Revisado'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Em Análise'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Avaliar'"
            class="finalizada status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Re-Execução'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="historicos != []" class="historico-Os ml-3">
      <h3 class="nova-ordem-titulo">Histórico da Ordem de Serviço</h3>
      <div
        v-for="log of historicos"
        :key="log.ordem_servico_log_id"
        class="historico-loop"
      >
        <div class="historico-data">
          {{ formatDate(log.ordem_servico_log_data) }}
        </div>
        <div class="historico-card">
          <div class="historico-usuario">
            <span class="historico-nome">{{ log.usuario_gestor_nome }}</span>
            <span class="historico-descricao">Alterou o status</span>
            <div>
              <span
                v-if="log.ordem_servico_status_descricao === 'Em Criação'"
                class="em-criacao status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Revisar'"
                class="revisar status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Finalizada'"
                class="finalizada status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Cancelada'"
                class="revisar status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Em Andamento'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Revisado'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Em Análise'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Avaliar'"
                class="finalizada status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Re-Execução'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
            </div>
          </div>
          <div class="historico-log-info">
            <h3 v-if="!log.ordem_servico_log_info" class="log-info">
              {{ log.ordem_servico_log_info }}
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="instrucoes ml-3">
      <h3 class="nova-ordem-titulo">Instruções</h3>
      <textarea
        @change="change()"
        placeholder="Digite aqui as instruções da ordem de serviço ..."
        class="instrucoes-input"
        v-model="instrucoes"
        type="text"
        disabled
      ></textarea>
    </div>

    <div v-if="exibirNeutro === true" class="analise-box">
      <div class="box-zeladoria-analise">
        <div class="coluna-dados">
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Atividade</span>
              <span class="card-info">{{ OsInfo.atividade_nome }}</span>
            </div>
            <img src="../../assets/img/atividade.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Criação</span>
              <span class="card-info">{{
                formatDate(OsInfo.ordem_servico_data_criacao)
              }}</span>
            </div>
            <img src="../../assets/img/cricao.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Bairro</span>
              <span class="card-info">{{ OsInfo.bairro_nome }}</span>
            </div>
            <img src="../../assets/img/cricao.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Zelador</span>
              <span class="card-info">{{ OsInfo.usuario_nome }}</span>
            </div>
            <img src="../../assets/img/zelador.png" alt="icon" />
          </div>
        </div>

        <div class="coluna-dados">
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Endereço</span>
              <span class="card-info">{{ OsInfo.ordem_servico_endereco }}</span>
            </div>
            <img src="../../assets/img/endereco.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Finalizada</span>
              <span
                v-if="OsInfo.ordem_servico_status_descricao === 'Finalizada'"
                class="card-info"
                >{{ OsInfo.ordem_servico_status_descricao }}</span
              >
              <span v-else class="card-info">--</span>
            </div>
            <img src="../../assets/img/endereco.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Áreas de Zeladoria</span>
              <span class="card-info">{{ OsInfo.zeladoria_nome }}</span>
            </div>
            <img src="../../assets/img/area.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Monitor</span>
              <span class="card-info">{{ OsInfo.usuario_gestor_nome }}</span>
            </div>
            <img src="../../assets/img/monitor.png" alt="icon" />
          </div>
        </div>

        <div class="os-map">
          <h3 class="mapa-titulo">Instruções</h3>
          <GmapMap
            class="map"
            ref="googleMap"
            :center="center"
            :zoom="zoom"
            style="height: 278px; width: 500px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              mapTypeId: mapType,
              gestureHandling: 'greedy',
              fullscreenControl: true,
              disableDefaultUi: true,
              mapTypeId: mapType,
            }"
          >
            <div
              v-for="polygon of polygonSector"
              :key="'polygonsKeepingSector' + polygon.id"
            >
              <GmapPolygon
                ref="polygon"
                :paths="polygon.latlngs"
                :options="polygon.options2"
              />
            </div>
            <GmapPolyline
              ref="polyline"
              v-if="polyline !== null"
              :path="polyline.latlngs"
              :options="polyline.options"
            />
          </GmapMap>
        </div>
      </div>
      <div
        v-if="
          OsInfo.ordem_servico_status_descricao === 'Em Análise' ||
          OsInfo.ordem_servico_status_descricao === 'Revisado'
        "
      >
        <div v-if="userType === 1" class="botoes-solicitar-os">
          <button @click="ShowModalRevisar()" class="revisar-botao">
            Solicitar Revisão
          </button>
          <button @click="ShowModalAprovar()" class="exibir">Aprovar</button>
          <div v-if="alertaPoligono === true" class="alertErro">
            *Selecione uma Área de Zeladoria no mapa*
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL --------------------------------------------------------------------------------------------------------------------------------------------------------------------------->

    <Modal class="modal-confirmacao" v-show="modalRevisar" :width="500">
      <template v-slot:header
        >Deseja encaminhar Ordem de Serviço para revisão?</template
      >
      <template v-slot:footer>
        <div class="botoes-confirmacao">
          <button class="botao-cancelar" @click="modalRevisar = false">
            {{ $t("cancel") }}
          </button>
          <button class="botao-salvar" @click="revisarOs()">Encaminhar</button>
        </div>
      </template>
    </Modal>

    <Modal class="modal-confirmacao" v-show="modalAprovar" :width="500">
      <template v-slot:header>Deseja aprovar a Ordem de Serviço?</template>
      <template v-slot:footer>
        <div class="botoes-confirmacao">
          <button class="botao-cancelar" @click="modalAprovar = false">
            {{ $t("cancel") }}
          </button>
          <button class="botao-salvar" @click="aprovarOs()">Aprovar</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

  <script>
import { formatDate } from "../../utils/date";
import services from "../../services";
import Modal from "../../components/Modal.vue";
// import CustomSwitch from "../../components/CustomSwitch.vue";
import BackArrow from "../../components/BackArrow.vue";
//import Button from "../../components/Button.vue";
//import Input from "../../components/form/Input.vue";
import { formatForGoogleMaps } from "../../utils/geoMaps";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Zeladores",
  components: {
    Modal,
    // Button,
    BackArrow,
    // Input,
    // CustomSwitch,
  },
  data() {
    return {
      check1: false,
      check2: false,
      check3: false,
      date: new Date(),
      removeModal: false,
      editModal: false,
      userIsActive: false,
      userType: null,
      atividade: 0,
      atividades: [],
      center: {
        lat: -25.43432,
        lng: -49.2638,
      },
      zoom: 2,
      polyline: null,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "roadmap",
      polygonSector: [],
      instrucoes: "",
      endereco: "",
      endereco_selecionado: null,
      atividadeEscolhida: false,
      exibirDiv: false,
      exibirErro: false,
      bairro: 0,
      bairros: [],
      bairro_poligono: {},
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      bounds: [],
      clickedPoligon: null,
      infoZeladoria: {
        zeladoria_nome: null,
        usuario_nome: null,
      },
      exibirSucesso: false,
      alertaPoligono: false,
      exibirNeutro: false,
      OsInfo: null,
      changeEndereco: false,
      modalRevisar: false,
      modalAprovar: false,
      historicos: [],
      formatDate,
    };
  },
  mounted: async function () {
    const user = this.$store.state.global.user;
    this.userType = user.usuario_gestor_tipo_id;
    await this.getAtividades();
    await this.getBairros();
    await this.getOsInfo();
    await this.historico();
    this.loadingData = false;
  },
  methods: {
    async salvar() {
      this.alertaPoligono = false;
      if (this.exibirSucesso === true) {
        const { errors } = await services.serviceOrder.salvar({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.infoZeladoria.zeladoria_id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
        });
        if (errors) {
          console.log(errors);
        }
      } else if (this.clickedPoligon != null) {
        const { errors } = await services.serviceOrder.salvar({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.clickedPoligon.id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
        });
        if (errors) {
          console.log(errors);
        }
      } else {
        this.alertaPoligono = true;
      }
    },
    change() {
      this.conteudo_alterado = true;
      const botao1 = document.getElementById("exibir1");
      if (this.instrucoes.length > 0 && this.endereco.length > 0) {
        botao1.disabled = false;
      } else {
        botao1.disabled = true;
      }
    },
    change2() {
      const botao2 = document.getElementById("exibir2");
      if (this.bairro != 0) {
        botao2.disabled = false;
      } else {
        botao2.disabled = true;
      }
    },
    async exibir() {
      this.exibirNeutro = false;
      this.changeEndereco = true;
      this.exibirErro = false;
      this.exibirDiv = false;
      this.exibirSucesso = false;
      this.clickedPoligon = null;
      (this.infoZeladoria = {
        zeladoria_nome: null,
        usuario_nome: null,
      }),
        (this.conteudo_alterado = true);
      this.polygonSector = [];
      this.endereco_selecionado = this.endereco;
      const { data, errors } = await services.serviceOrder.verificarEndereco({
        endereco: this.endereco,
      });
      if (!errors && !data.zeladoria_id) {
        // Caminho infeliz =[
        this.exibirSucesso = false;

        this.exibirErro = true;
      } else {
        // Caminho feliz =]
        this.exibirErro = false;
        this.clickedPoligon = null;
        this.bairro_poligono = data.zeladoria_limite;
        this.mountPolygon(this.bairro_poligono[0]);
        this.infoZeladoria = data;
        this.exibirSucesso = true;
      }
    },
    async exibir2() {
      this.exibirNeutro = false;
      this.exibirSucesso = false;
      this.polygonSector = [];
      this.exibirDiv = false;
      const { data, errors } = await services.serviceOrder.getPoligono({
        id: this.bairro.bairro_id,
      });
      if (!errors) {
        this.bairro_poligono = data.map((item) => {
          return item;
        });
        this.bairro_poligono.forEach((zeladoria) => {
          this.mountPolygon(
            zeladoria.zeladoria_limite[0],
            zeladoria.zeladoria_id
          );
        });
      }
      this.exibirDiv = true;
    },
    async poligonClick(polygon) {
      this.clickedPoligon = polygon;
      this.polygonSector.map((item) => {
        if (item.id === polygon.id) {
          item.options = {
            strokeColor: "#348534",
            fillColor: "#20d820",
          };
        } else {
          item.options = {
            strokeColor: "#A30BB2",
            fillColor: "#BE21CD",
          };
        }
      });
      const { data, errors } = await services.serviceOrder.getInfoPoligono({
        id: this.clickedPoligon.id,
      });
      if (!errors) {
        this.infoZeladoria = data;
      }
    },
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getOrders(this.pagination);
    },
    async getAtividades() {
      const { data, errors } = await services.serviceOrder.getAtividade();
      if (!errors) {
        this.atividades = data.map((item) => {
          return item;
        });
      }
    },
    async getBairros() {
      const { data, errors } = await services.serviceOrder.getBairro(
        this.pagination
      );
      if (!errors) {
        this.bairros = data.map((item) => {
          return item;
        });
      }
    },
    correçãoErro() {
      formatForGoogleMaps();
    },
    mountPolygon(poligono, id) {
      console.log("poligono", poligono);
      if (poligono !== "") {
        const coordinatesJSONParse = poligono;
        let bairro_poligono = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse),
          options: {
            strokeColor: "#A30BB2",
            fillColor: "#BE21CD",
          },
          options2: {
            strokeColor: "#348534",
            fillColor: "#20d820",
          },
          id,
        };
        bairro_poligono.latlngs.map((item) => {
          this.bounds.push(item);
        });

        this.polygonSector.push(bairro_poligono);
      }
      this.fitBoundsGoogle();
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 100);
    },
    async getOsInfo() {
      const { data, errors } = await services.serviceOrder.getOsInfo({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.OsInfo = data;
        this.atividade = {
          atividade_nome: this.OsInfo.atividade_nome,
          atividade_id: this.OsInfo.atividade_id,
        };
        this.instrucoes = this.OsInfo.ordem_servico_instrucao;
        this.endereco = this.OsInfo.ordem_servico_endereco;
        this.endereco_selecionado = this.OsInfo.ordem_servico_endereco;
        this.infoZeladoria = data;
        this.bairro_poligono = data.zeladoria_limite;
        this.mountPolygon(this.OsInfo.zeladoria_limite[0]);
        this.exibirNeutro = true;
      }
    },
    async historico() {
      const { data, errors } = await services.serviceOrder.historico({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.historicos = data;
      }
    },
    async salvarRascunho() {
      this.alertaPoligono = false;
      if (this.changeEndereco === true) {
        if (this.exibirSucesso === true) {
          const { errors } = await services.serviceOrder.salvarRascunho({
            atividade_id: this.atividade.atividade_id,
            zeladoria_id: this.infoZeladoria.zeladoria_id,
            ordem_servico_instrucao: this.instrucoes,
            ordem_servico_endereco: this.endereco_selecionado,
            ordem_servico_id: this.OsInfo.ordem_servico_id,
          });
          this.modalRascunho = false;
          if (errors) {
            console.log(errors);
          }
        } else if (this.clickedPoligon != null) {
          const { errors } = await services.serviceOrder.salvarRascunho({
            atividade_id: this.atividade.atividade_id,
            zeladoria_id: this.clickedPoligon.id,
            ordem_servico_instrucao: this.instrucoes,
            ordem_servico_endereco: this.endereco_selecionado,
            ordem_servico_id: this.OsInfo.ordem_servico_id,
          });
          this.modalRascunho = false;
          if (errors) {
            console.log(errors);
          }
        } else {
          this.alertaPoligono = true;
        }
      } else {
        const { errors } = await services.serviceOrder.salvarRascunho({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.infoZeladoria.zeladoria_id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
          ordem_servico_id: this.OsInfo.ordem_servico_id,
        });
        this.modalRascunho = false;
        if (errors) {
          console.log(errors);
        }
      }
    },
    async aprovarOs() {
      await this.salvarRascunho();
      const { errors } = await services.serviceOrder.aprovarOs({
        ordem_servico_id: this.OsInfo.ordem_servico_id,
      });
      if (errors) {
        console.log(errors);
      }
      this.$router.push("/ordens-de-servico");
    },
    async revisarOs() {
      const { errors } = await services.serviceOrder.revisarOs({
        ordem_servico_id: this.OsInfo.ordem_servico_id,
      });
      if (errors) {
        console.log(errors);
      }
      this.$router.push("/ordens-de-servico");
    },
    ShowModalRevisar() {
      this.modalRevisar = true;
    },
    ShowModalAprovar() {
      this.modalAprovar = true;
    },
  },
};
</script>
  <style lang="scss">
.nova-ordem-titulo {
  font-family: "Montserrat";
  font-size: 20px;
  color: #565e73;
  margin: 24px 0px;
}
.nova-ordem {
  margin-bottom: 32px;
  margin-top: 64px;
}
.atividade-input {
  padding: 12px 16px;
  width: 600px !important;
  background: #ffffff;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  outline: none;
}
.instrucoes-input {
  height: 160px !important;
  width: 100%;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 12px 16px;
  outline: none;
}
.exibir {
  color: white;
  background: #003366;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
}
.zeladoria-atribuida {
  text-align: center;
  align-items: center;
  padding: 2px 6px;
  background: #dddee3;
  border-radius: 30px;
  width: 80px;
  color: #131e3b;
  margin-bottom: 26px;
}
.nome-zelador {
  display: flex;
  color: #131e3b;
  font-size: 14px;
  font-family: "Open Sans";
  margin: 0px;
  justify-content: center;
  align-items: center;
}
.box-zeladoria {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.revisar-botao {
  padding: 8px 16px;
  color: white;
  text-align: center;
  font-family: "Open Sans";
  margin-right: 32px;
  border-radius: 8px;
  background: #d3341d;
  border: none;
}
.botoes-solicitar-os {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
}
.erro-endereco {
  display: flex;
  flex-direction: row;
  border: solid 3px #d3341d;
  border-radius: 8px;
  height: 124px;
  align-items: center;
  margin: 24px 0px;
}
textarea::placeholder {
  color: lightgray;
}
input::placeholder {
  color: lightgray;
}
.input-wrapper {
  margin: 0px !important;
  padding: 0px;
}
button:disabled {
  background: #b8c5d3;
}
.alertErro {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: red;
  margin: 0px 18px;
  height: 41px;
}
.status_editar {
  font-size: 14px;
}
.modal-confirmacao {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.botoes-confirmacao {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
}
.botao-salvar {
  padding: 12px;
  width: 100px;
  background-color: #7b9e1e;
  color: white;
  border: none;
  border-radius: 8px;
}
.botao-cancelar {
  padding: 12px;
  width: 100px;
  background-color: #a92a17;
  color: white;
  border: none;
  border-radius: 8px;
}
.div-titulo {
  display: flex;
  justify-content: space-between;
}
.botao-cancelar-solicitacao {
  background-color: #d3341d;
  border: none;
  color: white;
  padding: 3px 14px;
  border-radius: 7px;
  border: none;
}
.historico-card {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  padding: 12px;
  border-radius: 12px;
}
.historico-usuario {
  display: flex;
  padding: 6px;
}
.historico-nome {
  color: #00b1eb;
  font-size: 14px;
  margin-right: 12px;
}
.historico-descricao {
  display: flex;
  color: #565e73;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.status_historico {
  font-size: 12px;
  margin-left: 16px;
}
.log-info {
  border: 1px solid #dddee3;
  border-radius: 8px;
  padding: 12px;
  color: #565e73;
  font-size: 14px;
  font-family: "Open Sans";
  background-color: #f3f4f5;
  margin-top: 16px;
}
.historico-data {
  color: #131e3b;
  margin-top: 28px !important;
  margin-bottom: 18px !important;
  margin-left: 8px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: bold;
}
.historico-Os {
  margin-top: 64px;
}
.alertErro {
  display: flex;
  flex-direction: row;
}
.instrucoes {
  margin: 64px 0px;
}
.mini-info-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 18px;
  width: 100%;
  margin: 16px 0px;
}
.coluna-dados {
  width: 100%;
  margin: 0px 16px;
  max-width: 500px;
}
.info-card-text {
  display: flex;
  flex-direction: column;
}
.info-card-nome {
  color: #565e73;
  font-size: 14px;
  font-family: "Montserrat";
  line-height: 132%;
}
.card-info {
  color: #838998;
  font-size: 12px;
  font-family: "Montserrat";
  line-height: 132%;
}
.mini-card-icon {
  width: 18px;
  height: 18px;
}
.os-map {
  padding: 24px;
  border: 1px solid lightgray;
  border-radius: 18px;
  margin: 16px 0px;
  margin-left: 24px;
}
.mapa-titulo {
  font-family: "Montserrat";
  font-size: 20px;
  color: #565e73;
  margin: 12px 0px;
}
.box-zeladoria-analise {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>

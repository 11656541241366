<template>
  <Modal class="modal-cancel" v-show="open" :width="433">
    <template v-slot:header>
      <div class="title-container">
        <div class="modal-title-cancel mb-3">Cancelar Pesquisa</div>
        <svg
          @click="handleClose()"
          class="close-icon mt-3"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00072 5.58599L11.9507 0.635986L13.3647 2.04999L8.41472 6.99999L13.3647 11.95L11.9507 13.364L7.00072 8.41399L2.05072 13.364L0.636719 11.95L5.58672 6.99999L0.636719 2.04999L2.05072 0.635986L7.00072 5.58599Z"
            fill="black"
          />
        </svg>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-subtitle mb-3">
        Antes de cancelar sua pesquisa, informe o motivo pelo qual você deseja
        cancelá-la.
      </div>
      <Textarea
        :rows="3"
        @input="reason = $event"
        placeholder="Informe o motivo..."
      />
      <div class="modal-subtitle mb-3">
        Confirme digitando abaixo, <span style="color: #db5946">cancelar</span>
      </div>
      <Input placeholder="Digite a confirmação..." @input="confirm = $event" />
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-center w-100 mt-3">
        <Button
          :width="110"
          size="small"
          name="Continuar"
          type="delete"
          :disabled="confirm !== 'cancelar' || reason === ''"
          @clicked="handleCancel()"
        />
      </div>
      <div class="d-flex justify-content-center w-100 mt-3">
        <Button
          :width="110"
          size="small"
          type="white-outline"
          name="Cancelar"
          @clicked="handleClose()"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Input from "../../../components/form/Input.vue";
import Textarea from "../../../components/form/Textarea.vue";
import Button from "../../../components/Button.vue";
import Modal from "../../../components/Modal.vue";

import services from "../../../services";

export default {
  name: "FinalModal",
  components: {
    Button,
    Modal,
    Input,
    Textarea,
  },
  data() {
    return {
      reason: "",
      confirm: "",
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClose() {
      this.reason = "";
      this.confirm = "";
      this.$emit("close");
    },
    async handleCancel() {
      const { errors } = await services.researches.cancelResearch(this.id, {
        atividade_pesquisa_motivo_cancelamento: this.reason,
      });

      if (!errors) {
        this.$toast.success("Pesquisa cancelada com sucesso!", {
          position: "top",
          duration: 3000,
        });
        this.reason = "";
        this.confirm = "";
        this.$emit("cancel");
      } else {
        this.$toast.error("Erro ao cancelar pesquisa!", {
          position: "top",
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.modal-title-cancel {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.017em;
  color: #131e3b;

  svg {
    margin-bottom: 16px;
  }
}

.close-icon {
  position: absolute;
  right: 0;
  top: -8px;
  cursor: pointer;
}

.modal-subtitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  text-align: center;
  color: #565e73;
}

.animate__animated {
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.animate__bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
    transform: scale3d(1.09, 1.09, 1.09);
  }

  70% {
    transform: scale3d(0.91, 0.91, 0.91);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>

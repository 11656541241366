<template>
  <div class="w-100">
    <div
      v-for="(answer, index) of data"
      :key="'statistics-' + answer.atividade_pesquisa_resposta_id + index"
    >
      <div class="option-container">
        <div class="question">{{ answer.atividade_pesquisa_resposta }}</div>

        <div class="d-flex flex-column">
          <div
            class="info"
            v-for="(local, index) of answer.respostas"
            :key="
              'answer-' + local.atividade_pesquisa_resposta_id + local.local_id
            "
          >
            <span class="custom-tooltip" :data-text="local.name">
              <div class="progress-bar">
                <div
                  class="full"
                  :style="`--to-width: ${local.percentage}%; background: ${colors[index]}; width: ${local.percentage}%`"
                ></div>
              </div>
              <div class="percent">{{ local.percentage }}%</div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionStatistics",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colors: ["#5CCDF2", "#003063", "#008EBC", "#9AC625", "#E06914"],
    };
  },
};
</script>

<style scoped lang="scss">
.option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #f3f4f5;
}

.question {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #131e3b;
}

.custom-tooltip {
  display: flex;
  align-items: center;
  position: relative;
}

.custom-tooltip:before {
  content: attr(data-text);
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  right: 100%;

  margin-right: 8px;
  width: fit-content;

  padding: 8px;
  white-space: nowrap;
  border-radius: 8px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  background: #29334e;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: 0.2s opacity;
}

.custom-tooltip:after {
  content: "";
  position: absolute;
  right: 100%;
  margin-right: 0px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  border: 4px solid #29334e;
  border-color: transparent #29334e transparent transparent;
  opacity: 0;
  transition: 0.2s;
}

.custom-tooltip:hover:before {
  opacity: 1;
}

.custom-tooltip:hover:after {
  opacity: 1;
}

.info {
  display: flex;
  align-items: center;

  .progress-bar {
    width: 200px;
    height: 12px;
    background: #e9eefa;
    border-radius: 8px;
    margin-right: 8px;
  }

  .percent {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #112c66;
    width: 42px;
    text-align: left;
  }

  .full {
    height: 100%;
    border-radius: 8px;
    animation: progress 1.2s ease-in-out;
  }

  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: var(--to-width);
    }
  }
}
</style>

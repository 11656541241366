<template>
  <div class="filter-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="card-title">Selecione até cinco zeladorias para comparar</div>
      <div class="pointer close-icon" @click="$emit('close')">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99999 5.58672L11.95 0.636719L13.364 2.05072L8.41399 7.00072L13.364 11.9507L11.95 13.3647L6.99999 8.41472L2.04999 13.3647L0.635986 11.9507L5.58599 7.00072L0.635986 2.05072L2.04999 0.636719L6.99999 5.58672Z"
            fill="#565E73"
          />
        </svg>
      </div>
    </div>

    <div class="mb-3 mt-1">
      <Input
        placeholder="Busque uma zeladoria"
        :search="true"
        @input="handleSearch"
      />
    </div>

    <div class="list">
      <div
        v-for="item of filteredKeepingSectors"
        :key="'keepingSector' + item.id"
      >
        <div class="d-flex align-items-center">
          <div>
            <input
              type="checkbox"
              class="input-checkbox"
              :disabled="(disable && !item.check) || selectedId === item.id"
              :id="item.id"
              v-model="item.check"
              @click="handleInput(item)"
            />
          </div>
          <div class="mt-1 ml-2">
            <label class="card-label" :for="item.id">{{ item.name }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mt-3">
      <Button
        @click.native="filter"
        class="mb-2"
        name="Filtrar resultados"
        size="small"
      />

      <Button
        name="Limpar"
        size="small"
        type="white"
        @click.native="cleanFilter"
      />
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import Input from "../../../components/form/Input.vue";

export default {
  name: "FilterStatus",
  components: {
    Button,
    Input,
  },
  data() {
    return {
      disable: false,
      filteredKeepingSectors: this.keepingSectors,
      selectedKeepingSectors: [],
    };
  },
  methods: {
    handleSearch(value) {
      this.filteredKeepingSectors = this.keepingSectors.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
    },
    handleInput(item) {
      item.check = !item.check;

      this.selectedKeepingSectors = [];

      this.keepingSectors.forEach((item) => {
        if (item.check) {
          this.selectedKeepingSectors.push(item.id);
        }
      });

      if (this.selectedKeepingSectors.length > 4) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    filter() {
      this.$emit("filter", this.selectedKeepingSectors);
      this.$emit("close");
    },
    cleanFilter() {
      this.keepingSectors.forEach((item) => {
        item.check = false;
      });

      this.keepingSectors.forEach((item) => {
        if (item.id === this.selectedId) {
          item.check = true;
        }
      });

      this.selectedKeepingSectors = [];
      this.$emit("filter", this.selectedKeepingSectors);
      this.$emit("close");
    },
  },
  props: {
    keepingSectors: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
  padding-left: 0;
  margin-right: 16px;
}

.input-checkbox {
  accent-color: #003366;
}

.close-icon {
  margin-bottom: 28px;
}

.card-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}

.filter-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  min-width: 314px;
  box-shadow: 0px 4px 4px rgba(160, 168, 189, 0.24);
}

.list {
  max-height: 170px;
  overflow-y: auto;
}
</style>

<template>
  <div class="users">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">Nova Ordem de Serviço</span>
    </h3>

    <div class="nova-ordem ml-3">
      <h3 class="nova-ordem-titulo">Selecione uma Atividade</h3>
      <select
        @change="change()"
        class="atividade-input"
        v-model="atividade"
        type="text"
      >
        <option value="0">Selecione uma Atividade</option>
        <option :value="item" v-for="(item, id) of atividades" :key="id">
          {{ item.atividade_nome }}
        </option>
      </select>
    </div>

    <div class="nova-ordem ml-3">
      <h3 class="nova-ordem-titulo">Instruções</h3>
      <textarea
        @change="change()"
        placeholder="Digite aqui as instruções da ordem de serviço ..."
        class="instrucoes-input"
        v-model="instrucoes"
        type="text"
      ></textarea>
    </div>

    <div class="nova-ordem ml-3">
      <h3 class="nova-ordem-titulo">Buscar Endereço</h3>
      <input
        @change="change()"
        placeholder="Digite um endereço válido"
        class="atividade-input"
        v-model="endereco"
        type="text"
      />
    </div>

    <div class="nova-ordem ml-3">
      <button id="exibir1" @click="exibir()" class="exibir" disabled>
        {{ $t("ShowUp") }}
      </button>
    </div>

    <div v-if="this.exibirErro === true" class="erro-exibir ml-3">
      <div class="erro-endereco">
        <div class="barra-vermelha"></div>
        <img class="x-vermelho" src="../../assets/img/erro.png" alt="erro" />
        <div>
          <h3 class="nova-ordem-erro">
            O endereço indicado não foi encontrado.
          </h3>
          <h3 class="nova-ordem-erro2">
            Selecione a área de zeladoria manualmente a partir da busca por
            bairro.
          </h3>
        </div>
      </div>

      <div class="nova-ordem ml-3">
        <h3 class="nova-ordem-titulo">Selecione um bairro</h3>
        <select
          @change="change2()"
          class="atividade-input"
          v-model="bairro"
          type="text"
        >
          <option value="0" disabled>Selecione um Bairro</option>
          <option :value="item" v-for="(item, id) of bairros" :key="id">
            {{ item.bairro_nome }}
          </option>
        </select>
      </div>

      <div class="nova-ordem ml-3">
        <button id="exibir2" @click="exibir2()" class="exibir" disabled>
          {{ $t("ShowUp") }}
        </button>
      </div>
    </div>

    <div v-if="this.exibirDiv === true" class="informacoes-exibir">
      <div class="box-zeladdoria">
        <div class="os-info">
          <h3 class="nova-ordem-titulo">Zeladoria Atribuída</h3>
          <div
            v-if="this.infoZeladoria.zeladoria_nome != null"
            class="zeladoria-atribuida"
          >
            {{ this.infoZeladoria.zeladoria_nome }}
          </div>
          <div v-else class="zeladoria-atribuida">AAA000</div>

          <h3 class="nova-ordem-titulo">Zelador Atribuído</h3>
          <div class="foto-nome">
            <!--<img v-if="this.infoZeladoria.usuario_foto != null" class="os-avatar" :src="infoZeladoria.usuario_foto" alt="zelador">-->
            <!--<img v-else class="os-avatar" src="../../assets/img/face.png" alt="zelador">-->
            <h3
              v-if="this.infoZeladoria.usuario_nome != null"
              class="nome-zelador"
            >
              {{ this.infoZeladoria.usuario_nome }}
            </h3>
            <h3 v-else class="nome-zelador">Zelador</h3>
          </div>

          <h3 class="nova-ordem-titulo">Monitor Atribuído</h3>
          <div class="foto-nome">
            <h3
              v-if="this.infoZeladoria.usuario_gestor_nome != null"
              class="nome-zelador"
            >
              {{ this.infoZeladoria.usuario_gestor_nome }}
            </h3>
            <h3 v-else class="nome-zelador">Monitor</h3>
          </div>
        </div>
        <div class="os-map">
          <GmapMap
            class="map"
            ref="googleMap"
            :center="center"
            :zoom="zoom"
            style="height: 278px; width: 800px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              mapTypeId: mapType,
              gestureHandling: 'greedy',
              fullscreenControl: true,
              disableDefaultUi: true,
              mapTypeId: mapType,
            }"
          >
            <!-- click aqui -> função -->
            <div
              v-for="polygon of polygonSector"
              :key="'polygonsKeepingSector' + polygon.id"
            >
              <GmapPolygon
                @click="poligonClick(polygon)"
                ref="polygon"
                :paths="polygon.latlngs"
                :options="polygon.options"
              />
            </div>
            <GmapPolyline
              ref="polyline"
              v-if="polyline !== null"
              :path="polyline.latlngs"
              :options="polyline.options"
            />
          </GmapMap>
        </div>
      </div>
      <div
        v-if="this.userType === 2 || this.userType === 3 || this.userType === 1"
        class="botoes-solicitar-os"
      >
        <button @click="salvar()" class="exibir">Salvar</button>
        <div v-if="this.alertaPoligono === true" class="alertErro">
          *Selecione uma Área de Zeladoria no mapa*
        </div>
      </div>
    </div>

    <!-- Caminho feliz ---------------------------------------------------------------------------------------------------------------------------------------->

    <div v-if="this.exibirSucesso === true" class="informacoes-exibir">
      <div class="box-zeladdoria">
        <div class="os-info">
          <h3 class="nova-ordem-titulo">Zeladoria Atribuída</h3>
          <div
            v-if="this.infoZeladoria.zeladoria_nome != null"
            class="zeladoria-atribuida"
          >
            {{ this.infoZeladoria.zeladoria_nome }}
          </div>
          <div v-else class="zeladoria-atribuida">AAA000</div>

          <h3 class="nova-ordem-titulo">Zelador Atribuído</h3>
          <div class="foto-nome">
            <!--<img v-if="this.infoZeladoria.usuario_foto != null" class="os-avatar" :src="infoZeladoria.usuario_foto" alt="zelador">-->
            <!--<img v-else class="os-avatar" src="../../assets/img/face.png" alt="zelador">-->
            <h3
              v-if="this.infoZeladoria.usuario_nome != null"
              class="nome-zelador"
            >
              {{ this.infoZeladoria.usuario_nome }}
            </h3>
            <h3 v-else class="nome-zelador">Zelador</h3>
          </div>

          <h3 class="nova-ordem-titulo">Monitor Atribuído</h3>
          <div class="foto-nome">
            <h3
              v-if="this.infoZeladoria.usuario_gestor_nome != null"
              class="nome-zelador"
            >
              {{ this.infoZeladoria.usuario_gestor_nome }}
            </h3>
            <h3 v-else class="nome-zelador">Monitor</h3>
          </div>
        </div>
        <div class="os-map">
          <GmapMap
            class="map"
            ref="googleMap"
            :center="center"
            :zoom="zoom"
            style="height: 278px; width: 800px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              mapTypeId: mapType,
              gestureHandling: 'greedy',
              fullscreenControl: true,
              disableDefaultUi: true,
              mapTypeId: mapType,
            }"
          >
            <div
              v-for="polygon of polygonSector"
              :key="'polygonsKeepingSector' + polygon.id"
            >
              <GmapPolygon
                ref="polygon"
                :paths="polygon.latlngs"
                :options="polygon.options2"
              />
            </div>
            <GmapPolyline
              ref="polyline"
              v-if="polyline !== null"
              :path="polyline.latlngs"
              :options="polyline.options"
            />
          </GmapMap>
        </div>
      </div>
      <div
        v-if="this.userType === 2 || this.userType === 3 || this.userType === 1"
        class="botoes-solicitar-os"
      >
        <button @click="salvar()" class="exibir">Salvar</button>
        <div v-if="this.alertaPoligono === true" class="alertErro">
          *Selecione uma Área de Zeladoria no mapa*
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import services from "../../services";
import BackArrow from "../../components/BackArrow.vue";
import { formatForGoogleMaps } from "../../utils/geoMaps";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Zeladores",
  components: {
    BackArrow,
  },
  data() {
    return {
      check1: false,
      check2: false,
      check3: false,
      date: new Date(),
      removeModal: false,
      editModal: false,
      userIsActive: false,
      userType: null,
      atividade: 0,
      atividades: [],
      center: {
        lat: -25.43432,
        lng: -49.2638,
      },
      zoom: 2,
      polyline: null,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "roadmap",
      polygonSector: [],
      instrucoes: "",
      endereco: "",
      endereco_selecionado: null,
      atividadeEscolhida: false,
      exibirDiv: false,
      exibirErro: false,
      bairro: 0,
      bairros: [],
      bairro_poligono: {},
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      bounds: [],
      clickedPoligon: null,
      infoZeladoria: {
        zeladoria_nome: null,
        usuario_nome: null,
      },
      exibirSucesso: false,
      alertaPoligono: false,
      rerender: 1,
      os_id: null,
    };
  },
  mounted: async function () {
    const user = this.$store.state.global.user;
    this.userType = user.usuario_gestor_tipo_id;
    await this.getAtividades();
    await this.getBairros();
    this.loadingData = false;
  },
  methods: {
    async salvar() {
      this.alertaPoligono = false;
      if (this.exibirSucesso === true) {
        const { data, errors } = await services.serviceOrder.salvar({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.infoZeladoria.zeladoria_id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
        });
        if (!errors) {
          this.os_id = data.ordem_servico_id;
        }
        this.$router.push(`editar-ordem-de-servico/${this.os_id}`);
      } else if (this.clickedPoligon != null) {
        const { data, errors } = await services.serviceOrder.salvar({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.clickedPoligon.id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
        });
        if (!errors) {
          this.os_id = data.ordem_servico_id;
        }
        this.$router.push(`editar-ordem-de-servico/${this.os_id}`);
      } else {
        this.alertaPoligono = true;
      }
    },
    change() {
      const botao1 = document.getElementById("exibir1");
      if (
        this.instrucoes.length > 0 &&
        this.endereco.length > 0 &&
        this.atividade != 0
      ) {
        botao1.disabled = false;
      } else {
        botao1.disabled = true;
      }
    },
    change2() {
      const botao2 = document.getElementById("exibir2");
      if (this.bairro != 0) {
        botao2.disabled = false;
      } else {
        botao2.disabled = true;
      }
    },
    async exibir() {
      this.exibirErro = false;
      this.exibirDiv = false;
      this.exibirSucesso = false;
      this.clickedPoligon = null;
      (this.infoZeladoria = {
        zeladoria_nome: null,
        usuario_nome: null,
      }),
        (this.polygonSector = []);
      this.endereco_selecionado = this.endereco;
      const { data, errors } = await services.serviceOrder.verificarEndereco({
        endereco: this.endereco,
      });
      if (!errors && !data.zeladoria_id) {
        // Caminho infeliz =[
        this.exibirSucesso = false;

        this.exibirErro = true;
      } else {
        // Caminho feliz =]
        this.exibirErro = false;
        this.clickedPoligon = null;
        this.bairro_poligono = data.zeladoria_limite;
        this.mountPolygon(this.bairro_poligono[0]);
        this.infoZeladoria = data;
        this.exibirSucesso = true;
      }
    },
    async exibir2() {
      this.exibirSucesso = false;
      this.polygonSector = [];
      const { data, errors } = await services.serviceOrder.getPoligono({
        id: this.bairro.bairro_id,
      });
      if (!errors) {
        this.bairro_poligono = data.map((item) => {
          return item;
        });
        this.bairro_poligono.forEach((zeladoria) => {
          this.mountPolygon(
            zeladoria.zeladoria_limite[0],
            zeladoria.zeladoria_id
          );
        });
      }
      this.exibirDiv = true;
    },
    async poligonClick(polygon) {
      this.clickedPoligon = polygon;
      this.polygonSector.map((item) => {
        if (item.id === polygon.id) {
          item.options = {
            strokeColor: "#348534",
            fillColor: "#20d820",
          };
        } else {
          item.options = {
            strokeColor: "#A30BB2",
            fillColor: "#BE21CD",
          };
        }
      });
      const { data, errors } = await services.serviceOrder.getInfoPoligono({
        id: this.clickedPoligon.id,
      });
      if (!errors) {
        this.infoZeladoria = data;
      }
    },
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getOrders(this.pagination);
    },
    async getAtividades() {
      const { data, errors } = await services.serviceOrder.getAtividade();
      if (!errors) {
        this.atividades = data.map((item) => {
          return item;
        });
      }
    },
    async getBairros() {
      const { data, errors } = await services.serviceOrder.getBairro(
        this.pagination
      );
      if (!errors) {
        this.bairros = data.map((item) => {
          return item;
        });
      }
    },
    correçãoErro() {
      formatForGoogleMaps();
    },
    mountPolygon(poligono, id) {
      if (poligono !== "") {
        const coordinatesJSONParse = poligono;
        let bairro_poligono = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse),
          options: {
            strokeColor: "#A30BB2",
            fillColor: "#BE21CD",
          },
          options2: {
            strokeColor: "#348534",
            fillColor: "#20d820",
          },
          id,
        };
        bairro_poligono.latlngs.map((item) => {
          this.bounds.push(item);
        });

        this.polygonSector.push(bairro_poligono);
      }
      this.fitBoundsGoogle();
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 100);
    },
  },
};
</script>
  <style lang="scss">
.nova-ordem-titulo {
  font-size: 20px;
  color: #565e73;
  margin-bottom: 24px;
}
.nova-ordem {
  margin-bottom: 32px;
}
.atividade-input {
  padding: 12px 16px;
  width: 600px !important;
  background: #ffffff;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  outline: none;
}
.instrucoes-input {
  height: 160px !important;
  width: 100%;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 12px 16px;
  outline: none;
}
.exibir {
  color: white;
  background: #003366;
  border-radius: 8px;
  padding: 8px 16px;
}
.zeladoria-atribuida {
  text-align: center;
  align-items: center;
  padding: 2px 6px;
  background: #dddee3;
  border-radius: 30px;
  width: 80px;
  color: #131e3b;
  margin-bottom: 26px;
}
.foto-nome {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.os-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 16px;
}
.nome-zelador {
  display: flex;
  color: #131e3b;
  font-size: 14px;
  font-family: "Open Sans";
  margin: 0px;
  justify-content: center;
  align-items: center;
}
.box-zeladdoria {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.salvar-rascunho {
  padding: 8px 16px;
  color: #131e3b;
  text-align: center;
  font-family: "Open Sans";
  margin-right: 32px;
  border-radius: 8px;
  background: #ffffff;
}
.botoes-solicitar-os {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
}
.erro-endereco {
  display: flex;
  flex-direction: row;
  border: solid 3px #d3341d;
  border-radius: 8px;
  height: 124px;
  align-items: center;
  margin: 24px 0px;
}
.barra-vermelha {
  width: 12px;
  background-color: #d3341d;
  height: 100%;
}
.x-vermelho {
  width: 24px;
  height: 24px;
  margin: 0px 20px;
}
.nova-ordem-erro {
  font-family: "Montserrat";
  color: #112c66;
  font-size: 16px;
}
.nova-ordem-erro2 {
  font-family: "Open Sans";
  color: #565e73;
  font-size: 14px;
}
textarea::placeholder {
  color: lightgray;
}
input::placeholder {
  color: lightgray;
}
.input-wrapper {
  margin: 0px !important;
  padding: 0px;
}
button:disabled {
  background: #b8c5d3;
}
.alertErro {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: red;
  margin: 0px 18px;
  height: 41px;
}
</style>

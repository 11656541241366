<script>
import { decrypt } from "../../../utils/decrypt";

import Modal from "../../../components/Modal.vue";
import Button from "../../../components/Button.vue";

import { successToast } from "../../../utils/toast";

import services from "../../../services";

export default {
  name: "ContractsModal",
  components: {
    Modal,
    Button,
  },
  data: () => ({
    amaCandidate: false,
  }),
  props: {
    userToDistract: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (localStorage.getItem("config-500")) {
      const encrypted = localStorage.getItem("config-500");
      const decrypted = decrypt(encrypted);
      const config = JSON.parse(decrypted);
      this.amaCandidate = config.candidate.config_valor === "1" ? true : false;
    }

    console.log(services);
  },
  methods: {
    async removeKeepingSector() {
      await this.getUsers();
    },
    async getUsers() {
      this.loadingData = true;
      const { errors } = await services.keepingSectors.removeKeepingSector({
        usuario_id: this.userToDistract.usuario_id,
      });
      if (!errors) {
        successToast("Zeladoria removida com sucesso");
        this.$emit("refresh");
        this.loadingData = false;
      }
    },
  },
};
</script>

<template>
  <Modal :width="433">
    <template v-slot:header>
      <div class="w-100 d-flex justify-content-center">
        <p class="contract-modal-title w-100" style="text-align: center">
          Desativar Zelador
        </p>
        <span @click="$emit('close')" class="material-icons-outlined pointer"
          >close</span
        >
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body">
        Tem certeza que deseja desativar
        <span style="font-weight: 700" v-if="userToDistract">{{
          userToDistract.usuario_nome
        }}</span>
        da Zeladoria Digital? <br />
        Este procedimento implica em:
      </div>
      <div class="item">• Bloqueio de acesso ao aplicativo zeladoria</div>
      <div class="item mb-3">• Desvinculação da Área de Zeladoria</div>
    </template>
    <template v-slot:footer>
      <div
        class="d-flex align-items-center justify-content-center flex-column w-100"
      >
        <Button
          class="deactivate-user-btn mb-2"
          type="outline"
          size="small"
          name="Continuar"
          @clicked="amaCandidate ? $emit('continue') : removeKeepingSector()"
        />
        <Button
          size="small"
          :name="$t('cancel')"
          @clicked="$emit('close')"
          class="cancel-deactivate-user-btn"
        />
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.contract-modal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #131e3b;
}

.modal-body {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
  text-align: center;

  .item {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    letter-spacing: -0.006em;
    color: #565e73;
  }
}

.deactivate-user-btn {
  background: #d3341d;
  color: white;
  &:disabled {
    background: #f3c6c0;
    border: none;
  }
}

.cancel-deactivate-user-btn {
  background: white;
  color: #131e3b;
  border: 2px solid #dddee3;
}
</style>

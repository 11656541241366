<template>
  <div class="link-keeping-sector">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("linkKeepingSector") }}</span>
      <span v-if="user.usuario_nome" class="subtitle pl-4 ml-2 mt-1">
        Zelador: {{ user.usuario_nome }}
      </span>
    </h3>

    <transition name="bounce">
      <div class="ml-3">
        <div class="d-flex align-items-center pb-4">
          <div class="select-container mr-4">
            <multiselect
              v-model="lastSelectedNeighborhood"
              :noOptions="$t('emptyList')"
              selectLabel=""
              @input="selectedKeeping = null"
              :deselectLabel="$t('remove')"
              :selectedLabel="$t('selected')"
              :options="neighborhoodNames"
              track-by="name"
              :placeholder="$t('searchByNeighborhood')"
              :preselect-first="false"
              :multiple="true"
              label="name"
            ></multiselect>
          </div>
          <span class="mr-4">{{ $t("or") }}</span>
          <div class="mr-4">
            <multiselect
              v-model="selectedKeeping"
              :noOptions="$t('emptyList')"
              selectLabel=""
              @input="selectKeepingSectorByMultiselect"
              :deselectLabel="$t('remove')"
              :selectedLabel="$t('selected')"
              :options="keepingSectors"
              track-by="name"
              :placeholder="$t('selectAKeepingSector')"
              :preselect-first="false"
              :multiple="false"
              label="name"
            ></multiselect>
          </div>
        </div>

        <div class="mb-4 mt-2">
          <Button
            :name="$t('clean')"
            type="outline"
            @clicked="cleanButton"
            size="small"
          />

          <Button
            class="ml-4"
            :name="$t('link')"
            :disabled="!selectedKeeping"
            @clicked="linkKeepingSector"
            size="small"
          />
        </div>

        <div class="map-container mr-3">
          <div class="ml-3 map-title pt-2 pb-2">
            Selecione a Área de Zeladoria
          </div>
          <CCardBody>
            <div style="height: calc(100vh - 220px)">
              <GmapMap
                ref="googleMap"
                :center="center"
                :zoom="zoom"
                style="height: 100%; width: 100%"
                :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: true,
                  mapTypeId: mapType,
                  gestureHandling: 'greedy',
                  fullscreenControl: false,
                  disableDefaultUi: true,
                }"
              >
                <div
                  v-for="polygon of polygonsKeepingSectors"
                  :key="'polygonsKeepingSector' + polygon.id"
                >
                  <GmapPolygon
                    @mouseover="polygon.display_name = true"
                    @mouseout="polygon.display_name = false"
                    @click="
                      polygon.zeladoria_incluida === 1 &&
                      polygon.qtd_usuarios_zeladoria === 0
                        ? selectKeepingSector(polygon.zeladoria_id)
                        : ''
                    "
                    ref="polygon"
                    :paths="polygon.latlngs"
                    :options="polygon.options"
                  />
                  <GmapCustomMarker
                    v-if="polygon.display_name"
                    :draggable="false"
                    :marker="polygon.center"
                  >
                    <span class="map-label">{{ polygon.zeladoria_nome }}</span>
                  </GmapCustomMarker>
                  <GmapCustomMarker
                    v-if="
                      polygon.qtd_usuarios_zeladoria &&
                      polygon.zeladoria_incluida === 1
                    "
                    :draggable="false"
                    :marker="polygon.center"
                    style="font-size: 1.4rem; color: white"
                    class="mt-4"
                  >
                    <span class="material-icons">person</span>
                  </GmapCustomMarker>
                  <GmapCustomMarker
                    :draggable="false"
                    class="mt-4 importance"
                    :marker="polygon.center"
                  >
                    <div class="d-flex flex-column align-items-center">
                      <span
                        v-if="polygon.zeladoria_incluida === 0"
                        class="material-icons"
                        style="
                          color: black;
                          background: #fdc640;
                          font-size: 1.4rem;
                        "
                      >
                        lock
                      </span>
                    </div>
                  </GmapCustomMarker>
                </div>
              </GmapMap>
            </div>
            <div class="mapTypeControllers">
              <CSelect
                placeholder="Tipo"
                :options="mapTypes"
                :value.sync="mapType"
              ></CSelect>
            </div>
          </CCardBody>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import GmapCustomMarker from "vue2-gmap-custom-marker";

import { formatForGoogleMaps } from "../../utils/geoMaps";

import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";

import services from "../../services";

export default {
  name: "MapaZelador",
  components: {
    Multiselect,
    GmapCustomMarker,
    BackArrow,
    Button,
  },
  computed: {},
  data() {
    return {
      zoom: 4,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      user: {},
      polygonsKeepingSectors: [],
      lastSelectedNeighborhood: [],
      neighborhoodNames: [],
      selectedKeeping: null,
      keepingSectors: [],
      selectedKeepingSectorId: 0,
      loadingData: true,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "satellite",
    };
  },
  watch: {
    async lastSelectedNeighborhood(value) {
      if (this.lastSelectedNeighborhood.length === 0) {
        this.emptyPolygons();
        return;
      }
      if (this.lastSelectedNeighborhood.length > 1) {
        this.lastSelectedNeighborhood.shift();
        return;
      }
      await this.requestPolygons(value[0].id);
    },
  },
  mounted: async function () {
    await this.initUser();
    await this.initNeighborhoods();
    await this.initKeepingSectorsList();
    this.loadingData = false;
  },
  methods: {
    async initNeighborhoods() {
      const { data, errors } = await services.neighborhoods.getNeighborhoods();
      if (!errors) {
        this.neighborhoodNames = data.map((item) => {
          return {
            name: item.bairro_nome,
            id: item.bairro_id,
          };
        });
      }
    },
    async initKeepingSectorsList() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorsWithoutKeeper();
      if (!errors) {
        this.keepingSectors = data.map((item) => {
          return {
            name: item.zeladoria_nome,
            id: item.zeladoria_id,
          };
        });
      }
    },
    async selectKeepingSectorByMultiselect() {
      this.lastSelectedNeighborhood = [];
      this.polygonsKeepingSectors = [];
      await this.requestKeepingSectorById();
    },
    async requestKeepingSectorById() {
      const { data, errors } =
        await services.keepingSectors.getDetailsKeepingSector({
          keepingSectorId: this.selectedKeeping.id,
        });
      if (!errors) {
        this.formatMapToRender(data);
        this.fitBoundsGoogle();
        this.polygonsKeepingSectors[0].options.strokeColor = "#89CC49";
        this.polygonsKeepingSectors[0].options.fillColor = "#89CC49";
      } else {
        this.validationToast(
          {
            data: {
              message: this.$t("noDataForKeepingSector"),
            },
          },
          "error"
        );
      }
    },
    async requestPolygons(neighborhoodId) {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorMapByNeighborhood(
          neighborhoodId
        );
      this.emptyPolygons();
      if (!errors) {
        if (data.length) {
          this.formatMapToRender(data);
          this.fitBoundsGoogle();
          return;
        }
        this.validationToast(
          {
            data: {
              message: this.$t("noDataForNeighborhood"),
            },
          },
          "error"
        );
      }
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    async initUser() {
      const { data, errors } = await services.users.getUserById({
        userId: this.$route.params.id,
      });
      if (!errors) {
        this.user = data;
      }
    },
    cleanButton() {
      this.emptyPolygons();
      this.lastSelectedNeighborhood = [];
      this.selectedKeeping = null;
    },
    emptyPolygons() {
      this.bounds = [];
      this.polygonsKeepingSectors = [];
    },
    formatMapToRender(data) {
      data.map((item, index) => {
        if (item.zeladoria_limite !== "" && item.zeladoria_limite !== null) {
          const coordinatesJSONParse = item.zeladoria_limite;
          const centerJSONParse = {
            lat: item.zeladoria_lat,
            lng: item.zeladoria_lng,
          };
          let zeladoria = {
            latlngs: formatForGoogleMaps(coordinatesJSONParse[0]),
            options: {
              strokeColor: "#548aba",
              fillColor: "#548aba",
            },
            center: centerJSONParse,
            id: index,
            zeladoria_id: item.zeladoria_id,
            zeladoria_nome: item.zeladoria_nome,
            zeladoria_incluida: item.zeladoria_incluida,
            qtd_usuarios_zeladoria: item.qtd_usuarios_zeladoria,
            display_name: false,
          };

          zeladoria.latlngs.map((item) => {
            this.bounds.push(item);
          });

          this.polygonsKeepingSectors.push(zeladoria);
        }
      });
    },
    selectKeepingSector(idZeladoria) {
      if (this.selectedKeepingSectorId !== 0) {
        let foundIndex = this.polygonsKeepingSectors.findIndex(
          (polygon) => polygon.zeladoria_id === this.selectedKeepingSectorId
        );
        if (foundIndex !== -1) {
          this.polygonsKeepingSectors[foundIndex].options.strokeColor =
            "#548aba";
          this.polygonsKeepingSectors[foundIndex].options.fillColor = "#548aba";
        }
      }
      this.selectedKeepingSectorId = idZeladoria;
      this.selectedKeeping = this.keepingSectors.find(
        (item) => item.id === idZeladoria
      );
      let foundIndex = this.polygonsKeepingSectors.findIndex(
        (polygon) => polygon.zeladoria_id === idZeladoria
      );
      this.polygonsKeepingSectors[foundIndex].options.strokeColor = "#89CC49";
      this.polygonsKeepingSectors[foundIndex].options.fillColor = "#89CC49";
    },
    async linkKeepingSector() {
      const { errors } = await services.users.updateUser({
        usuario_id: parseInt(this.$route.params.id),
        zeladoria_id: this.selectedKeeping.id,
      });
      if (!errors) {
        this.validationToast(
          {
            data: {
              message: this.$t("successLinkKeepingSector"),
            },
          },
          "success"
        );
        this.$router.go(-1);
      }
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link-keeping-sector {
  background-color: #fff;
  border-radius: 8px;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }

  .select-container {
    z-index: 9000;
    width: 300px;
    height: 44px;
    padding-top: 1px;
  }

  .subtitle {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #565e73;
  }

  .input-az {
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 8px;
    height: 42px;
    width: 120px;
  }

  .map-container {
    background-color: #fff;
    border: 0.75px solid #e4e4e4;
    border-radius: 16px;
    padding: 16px;
    position: relative;
  }

  .map-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 132%;
    color: #838998;
  }
}

.mapTypeControllers {
  position: absolute;
  top: 90px;
  left: 50px;
  z-index: 500;
  width: 120px;
}
</style>

export default (httpClient) => ({
  getActiveUserCount: async () => {
    const response = await httpClient.get("/user/count");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUsers: async () => {
    const response = await httpClient.get("/user");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUsersPaginated: async (payload) => {
    const response = await httpClient.post("/user-data-table", payload);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUser: async (id) => {
    const response = await httpClient.get(`/user/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getKeepingFromUser: async (id) => {
    const response = await httpClient.get(`/user/keeping-sector/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUsersWithContracts: async (payload) => {
    const response = await httpClient.post("/user-contract-list", payload);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  updateUser: async (data) => {
    const response = await httpClient.put("/user", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  removeKeepingSectorFromUser: async (data) => {
    const response = await httpClient.post("/user/remove-keeping-sector", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserContracts: async (userId) => {
    const response = await httpClient.get(`/user-contract/${userId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  desvinculateUser: async (data) => {
    const response = await httpClient.post("/user-distract-dashboard/no-pdf", {
      usuario_id: data.usuario_id,
      usuario_contrato_motivo_distrato: data.usuario_contrato_motivo_distrato,
      usuario_contrato_observacao: data.usuario_contrato_observacao,
      zeladoria_id: data.zeladoria_id,
      usuario_contrato_percentual_distrato:
        data.usuario_contrato_percentual_distrato,
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  monitorDesvinculateUser: async (data) => {
    const response = await httpClient.put("/user/user-to-disqualified", {
      usuario_id: data.usuario_id,
      usuario_motivo_desclassificacao: data.usuario_motivo_desclassificacao
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserById: async (data) => {
    const response = await httpClient.get(`/user/${data.userId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getActivitiesByUser: async (data) => {
    const response = await httpClient.get(`/user/activities/${data.userId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserBigNumbers: async (data) => {
    const response = await httpClient.post(`/execution-atividade-total`, {
      data_inicio: data.initialDate,
      data_fim: data.finalDate,
      usuario_id: data.userId,
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserActivityStatistics: async (data) => {
    const response = await httpClient.post(
      `/execution-atividade-total-periodo/`,
      {
        data_inicio: data.initialDate,
        data_fim: data.finalDate,
        usuario_id: data.userId,
        atividade_id: data.activityId,
      }
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  usersListWithoutBond: async () => {
    const response = await httpClient.get(`/import/user-list`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    
    return {
      data: response.data,
      errors,
    };
  },
  vinculateUsers: async (data) => {
    const response = await httpClient.post("/import/user", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserTrainingDetails: async (data) => {
    const response = await httpClient.post("user-receive-tool/user-id", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserTurnovers: async (id) => {
    const response = await httpClient.post(`/turnover/usuario`, {
      usuario_id: id,
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getTurnoverRelatory: async (data) => {
    const response = await httpClient.post(`/turnover/date`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  createUserTurnover: async (data) => {
    const response = await httpClient.post(`/turnover`, data);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  finishUserTurnover: async (data) => {
    const response = await httpClient.put(`/turnover/finaliza`, data);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },

  getUserOnboarding: async (userId) => {
    const response = await httpClient.get(`/user-onboarding/${userId}`);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response,
      errors,
    };
  },
  getDistracts: async () => {
    const response = await httpClient.get(`/user-contract/avaliacao-pendente`);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response,
      errors,
    };
  },
  getHighPerformanceUsers: async (payload) => {
    const response = await httpClient.post(
      `/execution/monitor/desempenho/alto`,
      {
        data_mes: payload.date,
        usuario_gestor_id: payload.monitorId,
      }
    );
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response,
      errors,
    };
  },
  getLowPerformanceUsers: async (payload) => {
    const response = await httpClient.post(
      `/execution/monitor/desempenho/baixo`,
      {
        data_mes: payload.date,
        usuario_gestor_id: payload.monitorId,
      }
    );
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getDistractUsers: async (payload) => {
    const response = await httpClient.post(
      `/execution/monitor/desempenho/distrato`,
      {
        data_mes: payload.date,
        usuario_gestor_id: payload.monitorId,
      }
    );
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getToCancelUsers: async (payload) => {
    const response = await httpClient.post(
      `/execution/monitor/desempenho/a-desligar`,
      {
        data_mes: payload.date,
        usuario_gestor_id: payload.monitorId,
      }
    );
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUserPerformanceTotals: async (payload) => {
    const response = await httpClient.post(
      `/execution/monitor/desempenho/totais`,
      {
        data_mes: payload.date,
        usuario_gestor_id: payload.monitorId,
      }
    );
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },

  distractUser: async (data) => {
    const response = await httpClient.put(`/user-distract/avaliacao`, data);
    let errors = null;
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },

  searchUserList: async (data) => {
    const response = await httpClient.post(`/import/user-list/filter`, data)
    let errors = null
    if(!response.data){
      errors = {
        status: response.request.status,
        statusText: response.request.statustext
      }
    }
   
    return {
      data: response.data,
      errors,
    }
  },
  getAccessRelatory: async (data) => {
    const response = await httpClient.post(`/getUsersAccess`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  }
});

<template>
  <div class="subcontractor-form">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span>{{
        this.$route.params.tipo ? $t("editAssociation") : $t("newAssociation")
      }}</span>
    </h3>

    <div class="mr-3 ml-3">
      <div class="select-type">
        <CSelect
          :placeholder="$t('type')"
          :options="subcontractorsTypes"
          :value.sync="subcontractor.subcontratada_tipo_id"
        ></CSelect>
      </div>
      <div class="form" v-if="!loadingData">
        <Input
          placeholder="Descrição"
          v-model="subcontractor.subcontratada_descricao"
        />
        <Input
          placeholder="CNPJ"
          v-mask="'##.###.###/####-##'"
          v-model="subcontractor.subcontratada_cnpj"
        />
        <div style="position: relative" class="d-flex align-center">
          <Input
            class="mr-2"
            :placeholder="$t('zipCode')"
            v-mask="'#####-###'"
            v-model="subcontractor.subcontratada_cep"
          />
          <Button
            style="
              position: absolute;
              right: 0;
              height: 51px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            icon="search"
            :disabled="loadingCep"
            @clicked="searchCep()"
          />
        </div>
        <Input
          :placeholder="$t('address')"
          v-model="subcontractor.subcontratada_logradouro"
        />
        <Input
          :placeholder="$t('number')"
          v-model="subcontractor.subcontratada_numero"
        />

        <Input
          :placeholder="$t('complement')"
          v-model="subcontractor.subcontratada_complemento"
        />
        <Input
          :placeholder="$t('city')"
          v-model="subcontractor.subcontratada_cidade"
        />
      </div>
      <div class="d-flex align-items-center mt-4">
        <CustomSwitch
          class="switch"
          :selected="subcontractorStatus"
          @checkedSync="subcontractorStatus = !subcontractorStatus"
        />
        <h4 class="ml-3 switch-label">
          {{ $t("activeAssociation") }}
        </h4>
      </div>
      <div class="mt-4">
        <Button
          class="mb-4"
          :name="$t('save')"
          size="small"
          @clicked="saveSubcontractor"
        />
      </div>
      <div
        v-if="loadingData"
        class="d-flex justify-content-center align-items-center mt-4 mb-4"
      >
        <img
          :src="require(`@/assets/img/loading.gif`)"
          class="mb-4"
          alt="AMA Carregando"
          width="150"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../services";
import { decrypt } from "../../utils/decrypt";
import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import Input from "../../components/form/Input.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";
export default {
  name: "SubcontractorForm",
  components: {
    BackArrow,
    Button,
    Input,
    CustomSwitch,
  },
  data() {
    return {
      loadingData: true,
      loadingCep: false,
      subcontractorsTypes: [],
      usersManagers: [],
      subcontractorStatus: null,
      subcontractor: {
        subcontratada_tipo_id: null,
        subcontratada_descricao: "",
        subcontratada_cnpj: "",
        usuario_gestor_id: null,
        subcontratada_logradouro: "",
        subcontratada_numero: "",
        subcontratada_complemento: "",
        subcontratada_cep: "",
        subcontratada_cidade: "",
        subcontratada_id: null,
        subcontratada_ativa: null,
      },
    };
  },
  mounted: async function () {
    await this.verifyUserRole();
    if (this.$route.params.tipo) {
      await this.initSubcontractorById();
    }
    await this.requestSubcontractorsTypes();
    await this.getAllUsers();
    this.loadingData = false;
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async requestSubcontractorsTypes() {
      const { data, errors } =
        await services.subcontractors.getSubcontractorsTypes();
      if (!errors) {
        this.subcontractorsTypes = data.map((item) => {
          return {
            ...item,
            value: item.subcontratada_tipo_id,
            label: item.subcontratada_tipo_descricao,
          };
        });
      }
    },
    async initSubcontractorById() {
      const { data, errors } =
        await services.subcontractors.getSubcontractorById(
          this.$route.params.tipo
        );
      if (!errors) {
        this.subcontractor.subcontratada_descricao =
          data[0].subcontratada_descricao;
        this.subcontractor.usuario_gestor_id = data[0].usuario_gestor_id;
        this.subcontractor.subcontratada_tipo_id =
          data[0].subcontratada_tipo_id;
        this.subcontractor.subcontratada_cnpj = data[0].subcontratada_cnpj;
        this.subcontractor.subcontratada_logradouro =
          data[0].subcontratada_logradouro;
        this.subcontractor.subcontratada_numero = data[0].subcontratada_numero;
        this.subcontractor.subcontratada_complemento =
          data[0].subcontratada_complemento;
        this.subcontractor.subcontratada_cep = data[0].subcontratada_cep;
        this.subcontractor.subcontratada_cidade = data[0].subcontratada_cidade;
        this.subcontractor.subcontratada_id = data[0].subcontratada_id;
        this.subcontractor.subcontratada_ativa = data[0].subcontratada_ativa;
        this.subcontractorStatus = data[0].subcontratada_ativa ? true : false;
      }
    },
    async getAllUsers() {
      const { data, errors } = await services.usersManagers.getUsersManagers();
      if (!errors) {
        this.usersManagers = data.map((item) => {
          return {
            ...item,
            value: item.usuario_gestor_id,
            label: item.usuario_gestor_nome,
          };
        });
      }
    },
    async saveSubcontractor() {
      this.validateFieldsWithMask();
      if (this.$route.params.tipo) {
        this.subcontractor.subcontratada_ativa = this.subcontractorStatus
          ? 1
          : 0;
        this.editSubcontractors();
      } else {
        const encrypted = localStorage.getItem("ama");
        const decrypted = await decrypt(encrypted);
        const userData = JSON.parse(decrypted);
        this.subcontractor.usuario_gestor_id = userData.usuario_gestor_id;
        this.addSubcontractors();
      }
    },
    validateFieldsWithMask() {
      this.subcontractor.subcontratada_cnpj =
        this.subcontractor.subcontratada_cnpj.replace(/[^\d]+/g, "");
      this.subcontractor.subcontratada_cep =
        this.subcontractor.subcontratada_cep.replace(/[^\d]+/g, "");
    },
    async editSubcontractors() {
      const { errors } = await services.subcontractors.putSubcontractor(
        this.subcontractor
      );
      if (!errors) {
        this.validationToast(
          {
            data: {
              message: this.$t("successEditAssociation"),
            },
          },
          "success"
        );
        this.$router.push("/associacoes");
      }
    },
    async addSubcontractors() {
      const { errors } = await services.subcontractors.postSubcontractor(
        this.subcontractor
      );
      if (!errors) {
        this.validationToast(
          {
            data: {
              message: this.$t("successAddAssociation"),
            },
          },
          "success"
        );
        this.$router.push("/associacoes");
      }
    },
    async searchCep() {
      this.loadingCep = true;
      await this.$viaCep
        .buscarCep(this.subcontractor.subcontratada_cep.replace(/[^\d]+/g, ""))
        .then((resp) => {
          this.loadingCep = false;
          this.subcontractor.subcontratada_logradouro = resp.logradouro;
          this.subcontractor.subcontratada_cidade = resp.localidade;
        })
        .catch(() => {
          this.loadingCep = false;
          this.validationToast(
            {
              data: {
                message: this.$t("zipCodeError"),
              },
            },
            "error"
          );
        });
    },
    backPage() {
      this.$router.push("/associacoes");
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subcontractor-form {
  .select-type {
    padding: 24px 0;
    width: 220px;
  }
  .form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 24px;
    margin-bottom: 32px;
  }
  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}
.progress {
  display: none;
}
</style>
<style lang="scss">
.subcontractor-form {
  .switch {
    .slider {
      background-color: #a47aa9;
    }
    input:checked + .slider {
      background-color: #712f79;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #712f79;
    }
  }
}
.switch-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}
</style>

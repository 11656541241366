import crypto from 'crypto'

export function decrypt (message) {
  let cipher = crypto.createDecipher('aes-256-ctr', process.env.VUE_APP_SEED)
  let crypted = cipher.update(message, 'base64', 'utf8')
  crypted += cipher.final('utf8')
  return crypted
}

export function encrypt (message) {
  let cipher = crypto.createCipher('aes-256-ctr', process.env.VUE_APP_SEED)
  let crypted = cipher.update(message, 'utf8', 'base64')
  crypted += cipher.final('base64')
  return crypted
}

<template>
  <div>
    <h3 class="intern-title ml-3 position-relative">
      <back-arrow />
      <span class="page-nav">Bueiros</span>
      <svg
        @click="print"
        class="print-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#003063" />
        <path
          d="M12.6663 19.333H19.333V22.6663H12.6663V19.333ZM20.6663 21.333V17.9997H11.333V21.333H9.99967C9.82286 21.333 9.65329 21.2628 9.52827 21.1377C9.40325 21.0127 9.33301 20.8432 9.33301 20.6663V13.9997C9.33301 13.8229 9.40325 13.6533 9.52827 13.5283C9.65329 13.4032 9.82286 13.333 9.99967 13.333H21.9997C22.1765 13.333 22.3461 13.4032 22.4711 13.5283C22.5961 13.6533 22.6663 13.8229 22.6663 13.9997V20.6663C22.6663 20.8432 22.5961 21.0127 22.4711 21.1377C22.3461 21.2628 22.1765 21.333 21.9997 21.333H20.6663ZM11.333 14.6663V15.9997H13.333V14.6663H11.333ZM12.6663 9.33301H19.333C19.5098 9.33301 19.6794 9.40325 19.8044 9.52827C19.9294 9.65329 19.9997 9.82286 19.9997 9.99967V11.9997H11.9997V9.99967C11.9997 9.82286 12.0699 9.65329 12.1949 9.52827C12.32 9.40325 12.4895 9.33301 12.6663 9.33301Z"
          fill="white"
        />
      </svg>
    </h3>

    <!-- <div class="selector-section ml-3">
      <multiselect
        v-model="selectedTag"
        noOptions=""
        :options="tags"
        track-by="label"
        selectLabel=""
        placeholder="Selecionar Tag"
        :multiple="false"
        label="label"
        class="mr-3"
      ></multiselect>

      <Button
        size="small"
        name="Buscar"
        @clicked="requestData"
        :disabled="!selectedTag"
      />
    </div> -->

    <div class="map mb-5 ml-3 pr-3">
      <GmapMap
        ref="googleMap"
        :center="center"
        :zoom="zoom"
        style="height: 650px; width: 100%; position: relative"
        :options="{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: true,
          mapTypeId: mapType,
          gestureHandling: 'greedy',
          fullscreenControl: false,
          disableDefaultUi: true,
          mapTypeId: mapType,
        }"
      >
      </GmapMap>
      <div class="mapTypeControllers">
        <CSelect
          placeholder="Tipo"
          :options="mapTypes"
          :value.sync="mapType"
        ></CSelect>
      </div>
    </div>

    <div class="ml-3">
      <section id="content" class="mt-4">
        <table id="table-measurement">
          <thead>
            <tr id="table-header">
              <th>BAIRRO</th>
              <th>AZ</th>
              <th>LOGRADOURO</th>
              <th>NÚMERO</th>
              <th>CEP</th>
              <th>DATA</th>
              <th>TAG</th>
            </tr>
          </thead>
          <tr
            v-for="measurement of measurements"
            :key="measurement.bueiro_uuid"
          >
            <td class="keeping-label">
              {{ measurement.bairro_nome || "-" }}
            </td>
            <td>{{ measurement.zeladoria_nome || "-" }}</td>
            <td>
              {{ measurement.execucao_timeline_logradouro || "-" }}
            </td>
            <td>
              {{ measurement.execucao_timeline_logradouro_numero || "-" }}
            </td>
            <td>{{ measurement.execucao_timeline_logradouro_cep || "-" }}</td>
            <td>{{ formatDate(measurement.execucao_timeline_data) }}</td>
            <td>
              {{ measurement.tag_descricao }}
            </td>
          </tr>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
// import Multiselect from "vue-multiselect";
import BackArrow from "../../components/BackArrow.vue";
// import Button from "../../components/Button.vue";

import services from "../../services";

export default {
  name: "ManholeByTag",
  components: {
    // Multiselect,
    BackArrow,
    // Button,
  },
  data() {
    return {
      zoom: 3,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      selectedTag: null,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "satellite",
      measurements: [],
      heatData: {},
      heatPoints: [],
      tags: [],
    };
  },
  async mounted() {
    await this.getTags();
    await this.requestData();
    setTimeout(() => {
      if (this.measurements.length) {
        this.fitBoundsGoogle();
        this.generateHeatmap();
      }
    }, 600);
  },
  methods: {
    async getTags() {
      const { data, errors } = await services.tags.getTags();
      if (!errors) {
        this.tags = data.map((item) => {
          return {
            label: item.tag_descricao,
            value: item.tag_id,
          };
        });
      }
    },
    formatDate(date) {
      if (date) {
        const dateSplited = date.split("T");
        const dateSplited2 = dateSplited[0].split("-");
        const dateSplited3 = dateSplited[1].split(":");
        return `${dateSplited2[2]}/${dateSplited2[1]}/${dateSplited2[0]} ${dateSplited3[0]}:${dateSplited3[1]}`;
      } else {
        return "-";
      }
    },
    async requestData() {
      const { data, errors } = await services.tags
        .getManholesByTag
        //this.selectedTag.value
        // 185
        ();
      if (!errors) {
        this.heatPoints = data.map((item) => {
          return {
            lat: item.lat,
            lng: item.lng,
          };
        });

        this.measurements = data;
      }
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.heatPoints) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    generateHeatmap() {
      const heatPoints = [];
      for (let i of this.heatPoints) {
        heatPoints.push(new window.google.maps.LatLng(i.lat, i.lng));
      }
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.heatData = new window.google.maps.visualization.HeatmapLayer({
          data: heatPoints,
          map: map,
          maxIntensity: 1,
          radius: 15,
          zIndex: 9999999,
        });
      });
    },
    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  position: relative;
  width: 100%;

  .mapTypeControllers {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 120px;
    z-index: 0;
  }
}

.selector-section {
  padding-right: 32px;
  margin-bottom: 32px;
  width: 420px;
  display: flex;
  align-items: center;
}

.print-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
td,
th {
  border: 1px solid #e4e4e4;
  text-align: center;
}

th,
td {
  height: 48px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-size: 12px;
  padding: 12px;
  line-height: 156%;
}

tr:nth-child(odd) {
  background-color: #f3f4f5;
}

td {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: center;
  color: #838998;
}

thead > tr > th {
  background: #565e73;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
</style>

<template>
  <div class="c-app flex-row align-items-center background">
    <CContainer>
      <CRow class="justify-content-end">
        <CCol xl="4" lg="7" md="7">
          <CCardGroup>
            <CCard class="p-4 card-custom-border">
              <CCardBody>
                <section>
                  <div class="title">
                    <img src="../../../assets/img/login-icon.svg" alt="" />
                    <p class="mt-3">Zeladoria Ambiental</p>
                  </div>
                  <div class="subtitle">
                    Bem vindo ao <br />
                    Zeladoria Ambiental Digital
                  </div>
                  <div class="hint">
                    Selecione uma cidade para continuar com o login
                  </div>
                  <div class="link-container">
                    <CSelect
                      @change="setCity"
                      class="p-0 login"
                      :options="cityOptions"
                    />
                  </div>
                </section>
                <button class="login-button" @click="redirectToCity">
                  LOGIN
                </button>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user_email: "",
      user_password: "",
      city_choosed: "",
      urlLogo: "",
      client_id: 1,
      validation_string: "",
      selectedCity: "/curitiba",
      cityOptions: [
        { label: "Curitiba", value: "/curitiba" },
        { label: "Apucarana", value: "/apucarana" },
        { label: "Florianópolis", value: "/florianopolis" },
        { label: "Manaus", value: "/manaus" },
        { label: "Osasco", value: "/osasco" },
        { label: "Paranaguá", value: "/paranagua" },
        { label: "Ribeirão Preto", value: "/ribeirao-preto" },
        { label: "Recife", value: "/recife" },
        { label: "Rio de Janeiro", value: "/rio-de-janeiro" },
        { label: "São José dos Pinhais", value: "/sao-jose-pinhais" },
        { label: "Guarulhos", value: "/guarulhos" },
        { label: "Belo Horizonte", value: "/belo-horizonte" },
        { label: "Muriaé", value: "/muriae" },
        { label: "Ponta Grossa", value: "/ponta-grossa" },
      ],
    };
  },
  methods: {
    async logoUrl() {},
    setCity(ev) {
      this.selectedCity = ev.target.value;
    },
    redirectToCity() {
      if (this.selectedCity) {
        this.$router.push(this.selectedCity);
      }
    },
  },
};
</script>

<style scoped>
.background {
  background-image: url("../../../assets/img/login-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #394752;
}

.title {
  display: flex;
  margin: 0 auto;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 104%;
  color: #394752;
  text-align: center;
  margin-bottom: 20px;
  width: 200px;
}

.subtitle {
  color: #6d7486;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 16px;
  line-height: 132%;
  text-align: center;
  margin-bottom: 20px;
}

.hint {
  color: #6d7486;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 132%;
  /* or 18px */
  text-align: center;
  margin-bottom: 30px;
}

.link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.city-name {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
}

.login-button {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  border: none;
  color: white;
  background: #80be24;
  border-radius: 8px;
}
</style>

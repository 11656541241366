<template>
  <div class="input-wrapper w-100">
    <div v-if="label" class="label-container">
      <label class="input-label" :for="name">{{ label }}</label>
      <span
        class="counter"
        :class="value.length >= maxlength && 'counter-limit'"
        v-if="count"
        >{{ value.length }}/{{ maxlength }}</span
      >
    </div>

    <input
      :name="name"
      :type="type"
      :min="min"
      :disabled="disabled"
      :class="`input w-100 ${search ? 'search' : ''}`"
      :value="content"
      @focusin="$emit('focusin')"
      @focusout="$emit('focusout')"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      v-model="content"
    />
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  props: {
    value: {
      type: String || null,
      required: false,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    min: {
      type: Number,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "",
    },
    search: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    count: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      content: this.value,
    };
  },

  watch: {
    value: function (newVal) {
      this.content = newVal;
    },
  },
};
</script>

<style scoped>
.input-wrapper {
  margin: 10px 0;
}

.input {
  width: 304px;
  height: 51px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #001832;
  padding-left: 15px;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #838998;
}

.search {
  background: url(../../assets/img/search.svg) no-repeat scroll 96% 15px;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #747474;
  margin-top: 6px;
}

.counter {
  font-size: 0.8rem;
  color: #747474;
  margin-bottom: 4px;
}

.counter-limit {
  color: #ff0000 !important;
}
</style>

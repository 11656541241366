<template>
  <div class="keeping-sector-map">
    <div class="d-flex justify-content-between">
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav">{{ $t("keepingSectorsMap") }}</span>
      </h3>

      <div style="z-index: 900; width: 25%">
        <multiselect
          @select="selectNeighborhood"
          @remove="removeNeighborhood"
          :value="neighborhoodDetails"
          :noOptions="$t('emptyList')"
          selectLabel=""
          :deselectLabel="$t('remove')"
          :selectedLabel="$t('selected')"
          :options="neighborhoodNames"
          track-by="name"
          :placeholder="$t('selecteANeighborhood')"
          :preselect-first="false"
          :multiple="true"
          id="multiselect"
          label="name"
        ></multiselect>
        <label for="multiselect" class="multiselect-label pt-2"
          >{{ polygonsKeepingSector.length }}
          {{ $t("selectedKeepingSectors") }}</label
        >
      </div>
    </div>
    <transition name="bounce">
      <div v-show="!loadingData">
        <div class="pl-3 pt-3">
          <div style="height: calc(100vh - 220px); position: relative">
            <GmapMap
              ref="googleMap"
              :center="center"
              :zoom="zoom"
              style="height: 100%; width: 100%"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
                mapTypeId: mapType,
                gestureHandling: 'greedy',
                disableDefaultUi: true,
              }"
            >
              <div
                v-for="polygon of polygonsNeighborhood"
                :key="Math.random() + polygon.id"
              >
                <GmapPolygon
                  ref="polygon-neighborhood"
                  :paths="polygon.latlngs"
                  :options="polygon.options"
                />
              </div>
              <div
                v-for="polygon of polygonsKeepingSector"
                :key="Math.random() + polygon.id"
              >
                <GmapPolygon
                  @mouseover="polygon.display_name = true"
                  @mouseout="polygon.display_name = false"
                  @click="goToZelador(polygon.zeladoria_id)"
                  ref="polygon"
                  :paths="polygon.latlngs"
                  :options="polygon.options"
                />
                <GmapCustomMarker
                  class="mt-3 importance"
                  :draggable="false"
                  :marker="polygon.center"
                >
                  <span class="map-label">{{ polygon.zeladoria_nome }}</span>
                </GmapCustomMarker>
                <GmapCustomMarker
                  v-if="
                    polygon.qtd_usuarios_zeladoria > 0 &&
                    polygon.zeladoria_incluida === 1
                  "
                  :draggable="false"
                  :marker="polygon.center"
                  style="font-size: 1.4rem; color: white"
                >
                  <span class="material-icons">person</span>
                </GmapCustomMarker>
                <GmapCustomMarker :draggable="false" :marker="polygon.center">
                  <div class="d-flex flex-column align-items-center">
                    <span
                      v-if="polygon.zeladoria_incluida === 0"
                      class="material-icons"
                      style="
                        color: black;
                        background: #fdc640;
                        font-size: 1.4rem;
                      "
                    >
                      lock
                    </span>
                  </div>
                </GmapCustomMarker>
              </div>
            </GmapMap>
            <div class="mapTypeControllers">
              <CSelect
                placeholder="Tipo"
                :options="mapTypes"
                :value.sync="mapType"
              ></CSelect>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </transition>
    <div
      v-if="loadingData"
      class="d-flex justify-content-center align-items-center mt-2 mb-4"
    >
      <img
        :src="require(`@/assets/img/loading.gif`)"
        class="mb-4"
        alt="AMA Carregando"
        width="150"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import Multiselect from "vue-multiselect";

import GmapCustomMarker from "vue2-gmap-custom-marker";
import BackArrow from "../../components/BackArrow.vue";

import services from "../../services";
import {
  invertLatLngOriginal,
  formatForGoogleMaps,
  convertGeoStringToGoogleMaps,
} from "../../utils/geoMaps";

export default {
  name: "MapaZelador",
  components: {
    Multiselect,
    GmapCustomMarker,
    BackArrow,
  },
  data() {
    return {
      zoom: 4,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      polygonsKeepingSector: [],
      polygonsNeighborhood: [],
      polygonsInput: [],
      neighborhoodDetails: [],
      neighborhoodNames: [],
      linesInput: [],
      readContentFile: null,
      loadingData: true,
      allNeighborhoodSelected: false,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "satellite",
    };
  },
  watch: {
    readContentFile(newFile) {
      if (newFile.features.length > 0) {
        for (let item of newFile.features) {
          if (item.geometry.type == "Polygon") {
            this.polygonsInput.push({
              latlngs: invertLatLngOriginal(item.geometry.coordinates[0]),
              color: "#ff0000",
              bairro_id: item.properties.id,
              bairro_nome: item.properties.name,
            });
          } else if (item.geometry.type == "LineString") {
            this.linesInput.push({
              latlngs: invertLatLngOriginal(item.geometry.coordinates),
              color: "#ff0000",
            });
          }
        }
      }
    },
  },
  mounted: async function () {
    await this.initNeighborhoods();
    this.loadingData = false;
    const filtros = JSON.parse(window.localStorage.getItem("filtros"));
    if (filtros) {
      filtros.forEach((detail) => {
        this.selectNeighborhood(detail);
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "DetalhesZeladoria") {
      this.removeLocalStorageFilters();
    }
    next();
  },
  methods: {
    removeLocalStorageFilters() {
      if (window.localStorage.getItem("filtros")) {
        window.localStorage.removeItem("filtros");
      }
    },
    async initNeighborhoods() {
      const { data, errors } = await services.neighborhoods.getNeighborhoods();
      if (!errors) {
        this.neighborhoodNames = data.map((item) => {
          return {
            name: item.bairro_nome,
            id: item.bairro_id,
          };
        });

        this.neighborhoodNames.unshift({
          name: "Todos",
          id: 5000,
        });
      }
    },
    async requestNeighborhoodById(neighborhood) {
      const { data, errors } =
        await services.neighborhoods.getDetailsNeighborhood({
          neighborhoodId: neighborhood.id,
        });
      if (!errors) {
        data.forEach((neighborhood) => {
          this.mountNeighborhood(neighborhood);
        });
      } else {
        console.log(errors);
      }
    },
    async requestAllNeighborhoods() {
      const { data, errors } =
        await services.neighborhoods.getNeighborhoodMap();
      if (!errors) {
        data.forEach((neighborhood) => {
          this.mountNeighborhood(neighborhood);
        });
      } else {
        console.log(errors);
      }
    },
    mountNeighborhood(neighborhood) {
      if (
        neighborhood.bairro_limite !== "" &&
        neighborhood.bairro_limite !== null
      ) {
        const bairro = {
          latlngs: convertGeoStringToGoogleMaps(neighborhood.bairro_limite),
          options: {
            strokeColor: "#f27e7e",
            fillColor: "#f27e7e",
            zIndex: 0,
          },
          center: {
            lat: neighborhood.bairro_lat,
            lng: neighborhood.bairro_lng,
          },
          id: neighborhood.bairro_id,
          bairro_id: neighborhood.bairro_id,
          bairro_nome: neighborhood.bairro_nome,
        };
        this.polygonsNeighborhood.push(bairro);
      }
    },
    async getNeighborhoodInfo(neighborhood) {
      if (neighborhood.id === 5000) {
        this.requestAllNeighborhoods();
        return;
      } else {
        this.requestNeighborhoodById(neighborhood);
      }
    },
    async selectNeighborhood(value) {
      this.neighborhoodDetails.push(value);
      if (value.id === 5000) {
        this.allNeighborhoodSelected = true;
        this.emptyPolygons();
        this.requestAllKeepingSector();
        this.neighborhoodDetails = [value];
        return;
      }

      if (this.allNeighborhoodSelected) {
        this.allNeighborhoodSelected = false;
        this.emptyPolygons();
        this.neighborhoodDetails = [value];
      }

      await this.getNeighborhoodInfo(value);

      await this.requestPolygons(value.id);
    },
    removeNeighborhood(value) {
      this.allNeighborhoodSelected = false;

      this.neighborhoodDetails = this.neighborhoodDetails.filter(
        (item) => item.id !== value.id
      );

      if (value.id === 5000) {
        this.emptyPolygons();
        return;
      }
      this.polygonsKeepingSector = this.polygonsKeepingSector.filter((item) => {
        return item.bairro_id !== value.id;
      });

      this.polygonsNeighborhood = this.polygonsNeighborhood.filter((item) => {
        return item.bairro_id !== value.id;
      });

      this.bounds = [];
      this.polygonsKeepingSector.forEach((polygon) => {
        polygon.latlngs.forEach((latlng) => {
          this.bounds.push(latlng);
        });
      });
      if (this.bounds.length) {
        this.fitBoundsGoogle();
      }
    },
    async requestAllKeepingSector() {
      this.loadingData = true;
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorMap();
      if (!errors) {
        this.formatMapToRender(data, 5000);
        this.loadingData = false;
      } else {
        this.validationToast(
          {
            data: {
              message: "Não há dados cadastrados para esse bairro",
            },
          },
          "error"
        );
      }
    },
    async requestPolygons(neighborhoodId) {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorMapByNeighborhood(
          neighborhoodId
        );
      if (!errors) {
        if (!data.length) {
          this.validationToast(
            {
              data: {
                message: "Não há dados cadastrados para esse bairro",
              },
            },
            "error"
          );
          return;
        }
        this.formatMapToRender(data, neighborhoodId);
        this.fitBoundsGoogle();
      } else {
        this.validationToast(
          {
            data: {
              message: "Não há dados cadastrados para esse bairro",
            },
          },
          "error"
        );
      }
    },
    formatMapToRender(data, neighborhoodId) {
      data.map((item, index) => {
        if (item.zeladoria_limite !== "" && item.zeladoria_limite !== null) {
          let zeladoria = {
            latlngs: formatForGoogleMaps(item.zeladoria_limite[0]),
            options: {
              strokeColor: "#548aba",
              fillColor: "#548aba",
              zIndex: 1,
            },
            center: {
              lat: item.zeladoria_lat,
              lng: item.zeladoria_lng,
            },
            id: index,
            zeladoria_id: item.zeladoria_id,
            zeladoria_nome: item.zeladoria_nome,
            zeladoria_incluida: item.zeladoria_incluida,
            display_name: false,
            bairro_id: neighborhoodId,
            qtd_usuarios_zeladoria: item.qtd_usuarios_zeladoria,
          };
          zeladoria.latlngs.map((item) => {
            this.bounds.push(item);
          });

          this.polygonsKeepingSector.push(zeladoria);
          this.fitBoundsGoogle();
        }
      });
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    emptyPolygons() {
      this.bounds = [];
      this.polygonsKeepingSector = [];
      this.polygonsNeighborhood = [];
    },
    goToZelador(id) {
      this.removeLocalStorageFilters();
      window.localStorage.setItem(
        "filtros",
        JSON.stringify(this.neighborhoodDetails)
      );
      this.$router.push({
        name: "DetalhesZeladoria",
        params: {
          id: id,
        },
      });
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.keeping-sector-map {
  background-color: #fff;
  border-radius: 8px;
}

.multiselect-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-left: 1px;
}

.titulo_pin {
  background: #ffffff85;
  width: max-content;
  text-align: center;
  border-radius: 10px;
  font-size: 9px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
}
.mapTypeControllers {
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: 500;
  width: 120px;
}
</style>

<template>
  <div class="filter-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="card-title">Selecione os bairros</div>
      <div class="pointer close-icon" @click="$emit('close')">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99999 5.58672L11.95 0.636719L13.364 2.05072L8.41399 7.00072L13.364 11.9507L11.95 13.3647L6.99999 8.41472L2.04999 13.3647L0.635986 11.9507L5.58599 7.00072L0.635986 2.05072L2.04999 0.636719L6.99999 5.58672Z"
            fill="#565E73"
          />
        </svg>
      </div>
    </div>

    <input
      placeholder="Busque um bairro"
      class="input-neighborhood mb-3"
      v-model="searchNeighborhood"
    />

    <div class="options">
      <div v-for="neighborhood of neighborhoods" :key="neighborhood.bairro_id">
        <div class="d-flex align-items-center">
          <div>
            <input
              type="checkbox"
              :id="neighborhood.bairro_id"
              v-model="neighborhood.check"
            />
          </div>
          <div class="mt-1 ml-2">
            <label class="card-label" :for="neighborhood.bairro_id">{{
              neighborhood.bairro_nome
            }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mt-2">
      <Button
        name="Limpar Filtro"
        size="small"
        class="mb-2"
        type="white"
        @click.native="cleanFilter"
      />
      <Button @click.native="filter" name="Filtrar Resultados" size="small" />
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";
import services from "../../../services";

export default {
  name: "FilterEvaluations",
  components: {
    Button,
  },
  mounted: async function () {
    const { data } = await services.neighborhoods.getNeighborhoodMap();
    this.allNeighborhoods = data.map((item) => {
      return { ...item, check: false };
    });
    this.neighborhoods = this.allNeighborhoods;
  },
  data() {
    return {
      allNeighborhoods: [],
      neighborhoods: [],
      selectedNeighborhoods: [],
      searchNeighborhood: "",
    };
  },
  watch: {
    searchNeighborhood: function (val) {
      if (val === "") {
        this.neighborhoods = this.allNeighborhoods;
        return;
      }

      this.neighborhoods = this.allNeighborhoods.filter((neighborhood) => {
        return neighborhood.bairro_nome
          .toLowerCase()
          .includes(val.toLowerCase());
      });
    },
  },
  methods: {
    cleanFilter() {
      this.neighborhoods.forEach((neighborhood) => {
        neighborhood.check = false;
      });
      this.$emit("clean");
    },
    filter() {
      this.selectedNeighborhoods = this.neighborhoods.filter(
        (neighborhood) => neighborhood.check
      );
      this.$emit("filter", this.selectedNeighborhoods);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
  padding-left: 0;
  margin-right: 16px;
}

.card-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}

.filter-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  min-width: 314px;
  max-height: 500px;
  z-index: 100;
}

.close-icon {
  margin-bottom: 12px;
}

.options {
  height: 200px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.input-neighborhood {
  width: 100%;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 16px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 32px;
  background: url("../../../assets/img/search.svg") no-repeat right 16px center;

  &::placeholder {
    color: #b0b4bd;
  }

  &:focus {
    outline: none;
    border: 1px solid #61b2e5;
  }
}
</style>

<template>
  <div class="results">
    <div v-if="filteredQuestions.length">
      <details
        class="collapse-panel"
        v-for="question of filteredQuestions"
        :key="'question-details-' + question.atividade_pesquisa_pergunta_id"
      >
        <summary
          class="title"
          @click="
            question.open = !question.open;
            handleSummary(question.open);
          "
        >
          <div>{{ question.atividade_pesquisa_pergunta }}</div>
          <svg
            :class="{ rotate: question.open }"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99962 4.97656L10.1246 0.851562L11.303 2.0299L5.99962 7.33323L0.696289 2.0299L1.87462 0.851562L5.99962 4.97656Z"
              fill="#131E3B"
            />
          </svg>
        </summary>
        <div
          class="content"
          v-if="question.open"
          :class="{ 'handle-animate': question.open }"
        >
          <CompareQuestions :data="question.atividade_pesquisa_resposta" />
        </div>
      </details>
    </div>

    <div v-if="!filteredQuestions.length">
      <details
        class="collapse-panel"
        v-for="question of questions"
        :key="'question-details-' + question.atividade_pesquisa_pergunta_id"
      >
        <summary
          class="title"
          @click="
            question.open = !question.open;
            handleSummary(question.open);
          "
        >
          <div>{{ question.atividade_pesquisa_pergunta }}</div>
          <svg
            :class="{ rotate: question.open }"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99962 4.97656L10.1246 0.851562L11.303 2.0299L5.99962 7.33323L0.696289 2.0299L1.87462 0.851562L5.99962 4.97656Z"
              fill="#131E3B"
            />
          </svg>
        </summary>
        <div
          class="content"
          v-if="question.open"
          :class="{ 'handle-animate': question.open }"
        >
          <div class="chart-container" v-if="!isFiltered && anyData">
            <Doughnut :data="question.atividade_pesquisa_resposta" />
          </div>
          <QuestionStatistics :data="question.atividade_pesquisa_resposta" />
        </div>
      </details>
    </div>
  </div>
</template>

<script>
import Doughnut from "./Doughnut";
import QuestionStatistics from "./QuestionStatistics";
import CompareQuestions from "./CompareQuestions.vue";

export default {
  name: "ResultsPanel",
  components: {
    Doughnut,
    QuestionStatistics,
    CompareQuestions,
  },
  props: {
    isFiltered: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    filteredQuestions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSummary(open) {
      if (open) {
        setTimeout(() => {
          window.scrollBy(0, 350);
        }, 100);
      }
    },
  },
  computed: {
    anyData() {
      return this.questions.some(
        (question) =>
          question.atividade_pesquisa_resposta.length &&
          question.atividade_pesquisa_resposta.some(
            (option) => option.percentage
          )
      );
    },
  },
};
</script>

<style scoped lang="scss">
.results {
  margin-top: 32px;
}

.collapse-panel {
  border: 1px solid rgba(228, 228, 228, 0.56);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 32px;

  .title {
    list-style: none;

    background: rgba(230, 234, 239, 0.24);
    cursor: pointer;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 18px 28px 18px;

    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.014em;
    color: #131e3b;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-right: 28px;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 368px;
  margin-right: 16px;
}

.rotate {
  transform: rotate(180deg);
}

.handle-animate {
  animation: handle 0.3s ease-in-out;
}

@keyframes handle {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>

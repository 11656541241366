<template>
  <div class="chat-sidebar">
    <div class="chat-sidebar-header">
      <div class="alert-box">
        <svg
          class="alert-icon"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"
            fill="#565E73"
          />
        </svg>
        <span class="alert-text">{{ $t("alert") }}</span>
      </div>
      <div class="input-container">
        <Input
          class="input-buscar"
          placeholder="Buscar Zeladoria"
          v-model="palavraChave"
          @keyup.enter.native="buscarPalavraPorZeladoria"
        />
        <!--<svg class="option-button" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="32" height="32" rx="16" fill="#003063"/>
                  <path d="M13.3333 10.6667H22V12H13.3333V10.6667ZM11.3333 10V12H12V12.6667H10V12H10.6667V10.6667H10V10H11.3333ZM10 17.3333V15.6667H11.3333V15.3333H10V14.6667H12V16.3333H10.6667V16.6667H12V17.3333H10ZM11.3333 21H10V20.3333H11.3333V20H10V19.3333H12V22H10V21.3333H11.3333V21ZM13.3333 15.3333H22V16.6667H13.3333V15.3333ZM13.3333 20H22V21.3333H13.3333V20Z" fill="white"/>
              </svg>
              implementar ordenador quando der
              -->
        <svg
          @click="filterModal = !filterModal"
          class="option-button"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="16" fill="#003063" />
          <path
            d="M22 10.6667V12.0001H21.3333L17.3333 18.0001V22.6667H14.6667V18.0001L10.6667 12.0001H10V10.6667H22Z"
            fill="white"
          />
        </svg>
        <div class="absolute-box" v-if="filterModal">
          <CheckboxFilter
            title="Filtros"
            name="Filtre por Bairro"
            @clicked="filterModal = !filterModal"
            :bairros="bairros"
            @filter-by-neighborhood="filterByNeighborhood"
            @clear-filter-by-neighborhood="clearFilterByNeighborhood"
          />
        </div>
      </div>
    </div>
    <div class="chatListScroll">
      <div
        v-for="chat of chats"
        v-show="chat.qtd_mensagens > 0"
        :key="chat.zeladoria_id"
        :class="
          selectedChat.zeladoria_id === chat.zeladoria_id
            ? 'selected-chat'
            : 'chats'
        "
        @click="selectChat(chat)"
      >
        <div class="chat-info">
          <div class="box-1">
            <svg
              v-if="!chat.usuario_foto"
              class="chat-avatar"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="20" fill="#003063" />
              <path
                d="M18.5522 15.3333L17.2188 16.6667H14.6668V24.6667H25.3335V16.6667H22.7815L21.4482 15.3333H18.5522ZM18.0002 14H22.0002L23.3335 15.3333H26.0002C26.177 15.3333 26.3465 15.4036 26.4716 15.5286C26.5966 15.6536 26.6668 15.8232 26.6668 16V25.3333C26.6668 25.5101 26.5966 25.6797 26.4716 25.8047C26.3465 25.9298 26.177 26 26.0002 26H14.0002C13.8234 26 13.6538 25.9298 13.5288 25.8047C13.4037 25.6797 13.3335 25.5101 13.3335 25.3333V16C13.3335 15.8232 13.4037 15.6536 13.5288 15.5286C13.6538 15.4036 13.8234 15.3333 14.0002 15.3333H16.6668L18.0002 14ZM20.0002 24C19.0277 24 18.0951 23.6137 17.4074 22.9261C16.7198 22.2384 16.3335 21.3058 16.3335 20.3333C16.3335 19.3609 16.7198 18.4282 17.4074 17.7406C18.0951 17.053 19.0277 16.6667 20.0002 16.6667C20.9726 16.6667 21.9053 17.053 22.5929 17.7406C23.2805 18.4282 23.6668 19.3609 23.6668 20.3333C23.6668 21.3058 23.2805 22.2384 22.5929 22.9261C21.9053 23.6137 20.9726 24 20.0002 24ZM20.0002 22.6667C20.619 22.6667 21.2125 22.4208 21.6501 21.9832C22.0877 21.5457 22.3335 20.9522 22.3335 20.3333C22.3335 19.7145 22.0877 19.121 21.6501 18.6834C21.2125 18.2458 20.619 18 20.0002 18C19.3813 18 18.7878 18.2458 18.3502 18.6834C17.9127 19.121 17.6668 19.7145 17.6668 20.3333C17.6668 20.9522 17.9127 21.5457 18.3502 21.9832C18.7878 22.4208 19.3813 22.6667 20.0002 22.6667Z"
                fill="white"
              />
            </svg>
            <img v-else class="chat-avatar" :src="chat.usuario_foto" />
            <div class="chat-name">
              <span class="title"
                >{{ chat.zeladoria_nome }} | {{ chat.usuario_nome }}</span
              >
              <!--<span class="message">Bla Bla Bla</span>
                            implementar a última mensagem quando der
                            -->
            </div>
          </div>
          <div class="box-2">
            {{ chat.qtd_mensagens }}   
          </div>
        </div>
      </div>
      <Loading v-if="loading" />
    </div>
  </div>
</template>

  <script>
import Input from "../../components/form/Input.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CheckboxFilter from "../../components/CheckboxFilter.vue";
import services from "../../services";
import { debounce } from "../../utils/debounce";

export default {
  components: {
    Input,
    Loading,
    CheckboxFilter,
  },
  props: {
    propChats: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chats: [],
      filterModal: false,
      bairros: [],
      bairrosSelecionados: [],
      bairroPayload: [],
      selectedChat: [],
      palavraChave: null,
      zeladoriaLocalizada: null,
    };
  },
  mounted: async function () {
    await this.getBairros();
  },
  watch: {
    async propChats() {
      this.chats = this.propChats;
    },

    palavraChave: debounce(async function (newVal) {
      console.log(newVal, "sla");
      this.buscarPalavraPorZeladoria();
    }, 500),
  },
  methods: {
    async getBairros() {
      const { data, errors } =
        await services.neighborhoods.getNeighborhoodMap();
      if (!errors) {
        this.bairros = data.map((bairro) => {
          return {
            bairro_id: bairro.bairro_id,
            bairro_nome: bairro.bairro_nome,
            active: false,
          };
        });
      }
    },

    async filterByNeighborhood(payload) {
      this.filterModal = !this.filterModal;
      this.bairrosSelecionados = payload.map((bairro) => {
        if (bairro.active) {
          return {
            bairro_id: bairro.bairro_id,
          };
        } else {
          return null;
        }
      });
      this.bairroPayload = [];
      this.bairrosSelecionados.forEach((item) => {
        if (item) {
          this.bairroPayload.push(item.bairro_id);
        }
      });
      const { data, errors } = await services.chat.getChatList({
        bairros: this.bairroPayload,
      });
      if (!errors) {
        this.chats = data;
      }
    },

    async clearFilterByNeighborhood(payload) {
      this.bairrosSelecionados = payload.map((bairro) => {
        return {
          bairro_id: bairro.bairro_id,
          bairro_nome: bairro.bairro_nome,
          active: false,
        };
      });
      this.bairros = this.bairrosSelecionados;
    },
    async selectChat(chat) {
      this.selectedChat = chat;
      this.$emit("open-messages", chat);
    },

    async buscarPalavraPorZeladoria() {
      const { data } = await services.chat.filterChatByWord({
        pesquisa: this.palavraChave,
        bairros: this.bairroPayload,
      });
      this.chats = data;
    },
  },
};
</script>

  <style lang="scss" scoped>
.chat-sidebar-header {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 0px;
}
.chat-sidebar {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 374px;
  border-right: 1px solid #dddee3;
  height: calc(100vh - 85px);
  z-index: 99;
  cursor: pointer;
}
.alert-box {
  background: rgba(230, 234, 239, 0.32);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
}
.alert-icon {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}
.alert-text {
  color: #565e73;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  position: relative;
}
.option-button {
  width: 44px;
  margin: 8px;
  cursor: pointer;
}
.input-buscar {
  margin-right: 16px;
  margin-right: 12px !important;
}
.chats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #f3f4f5;
  cursor: pointer;
}
.chats:hover {
  background: rgba(230, 234, 239, 0.72);
}
.selected-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #f3f4f5;
  cursor: pointer;
  background: rgba(230, 234, 239, 0.72);
}
.chat-selected {
  background: rgba(230, 234, 239, 0.72);
}
.chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.chat-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  .box-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  .box-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: white;
    background: #61B2E5;
    border-radius: 8px;
    padding:8px;
    width: 28px;
    height: 28px;
  }
}
.chat-name {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  .title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #29334e;
  }
  .message {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #565e73;
  }
}

.chatListScroll {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.absolute-box {
  position: absolute;
  z-index: 10;
  top: 80px;
  left: 280px;
  width: 280px;
}

@media screen and (min-width: 1px) and (max-width: 800px) {
  .chat-sidebar {
    width: 100%;
    height: calc(100vh - 125px);
    margin-bottom: 15px;
    border-bottom: 1px solid lightgray;
  }
}
</style>

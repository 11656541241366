export default httpClient => ({
  getKeepingSectorCount: async () => {
    const response = await httpClient.get('/keeping-sector/count')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectors: async () => {
    const response = await httpClient.get('/keeping-sector')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsWithoutKeeper: async () => {
    const response = await httpClient.get('/keeping-sector/included/without-keeper')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsList: async () => {
    const response = await httpClient.get('/keeping-sector/names')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getNoUserKeepingSectorsList: async () => {
    const response = await httpClient.get('/keeping-sector/names/without-keeper')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getPrintZelador: async (data) => {
    const response = await httpClient.get(`/keeping-sector-print/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsNew: async (payload) => {
    const response = await httpClient.post('/keeping-sector-data-table', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsWithouthKeepers: async () => {
    const response = await httpClient.get('/keeping-sector/without-keepers')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getDetailsKeepingSector: async (data) => {
    const response = await httpClient.get(`/keeping-sector/with-limits/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getDetailsKeepingSectorWithStatus: async (data) => {
    const response = await httpClient.post(`/keeping-sector/with-limits-and-cicle-status`, {
      zeladoria_id: data.keepingSectorId,
      data_ciclo_inicio: data.initialDate,
      data_ciclo_fim: data.finalDate,
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getManholesByKeepingSector: async (data) => {
    const response = await httpClient.get(`/manhole/keeping-sector/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getGardensByKeepingSector: async (data) => {
    const response = await httpClient.get(`/garden/keeping-sector/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getTrashByKeepingSector: async (data) => {
    const response = await httpClient.get(`/trash-can/keeping-sector/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorDetails: async (data) => {
    const response = await httpClient.get(`/keeping-sector/activities/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorMap: async () => {
    const response = await httpClient.get(`/keeping-sector/with-limits`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorLimitsById: async (data) => {
    const response = await httpClient.get(`/keeping-sector/with-limits/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorMapByNeighborhood: async (data) => {
    const response = await httpClient.get(`/keeping-sector/with-limits/neighborhood/${data}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsWithEvaluationByNeighborhood: async (data) => {
    const response = await httpClient.get(`/block-evaluation/keeping-sector/${data}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsWithEvaluationByNeighborhoodWithStatus: async (data) => {
    const response = await httpClient.post(`/neighborhood/keeping-sector-with-limits-and-cicle-status`, {
      bairro_id: data.neighborhoodId,
      data_ciclo_inicio: data.initialDate,
      data_ciclo_fim: data.finalDate,
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorBlocks: async (keepingSectorId) => {
    const response = await httpClient.get(`/keeping-sector/blocks/${keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getKeepingSectorsOfMonitor: async (data) => {
    const response = await httpClient.get(`/keeping-sector-monitor/${data}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  linkKeepingSectorAndMonitor: async (data) => {
    const response = await httpClient.post(`/keeping-sector-monitor`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  relatoryKeepingSectors: async (data) => {
    const response = await httpClient.post(`/activity/report`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  relatoryKeepingSectorsTotal: async (data) => {
    const response = await httpClient.post(`/activity/report/total-activity`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  totalKeepingSectors: async (data) => {
    const response = await httpClient.post(`/activity/report/total-zeladoria`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  deleteKeepingSectorFromMonitor: async (data) => {
    const response = await httpClient.delete(`/keeping-sector-monitor/${data.userManagerId}/${data.keepingSectorId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  includeKeepingSector: async (data) => {
    const response = await httpClient.put(`/keeping-sector-included`, data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  putImageAz: async (data) => {
    const response = await httpClient.put(`/keeping-sector-print`, data)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  filterAZ: async (payload) => {
    const response = await httpClient.post('/bairro/informacao-avaliacao-by-city-and-name', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  removeKeepingSector: async (data) => {
    const response = await httpClient.post(`/user/remove-keeping-sector`, data)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      errors
    }
  }
})

<template>
  <div :class="`box-info ${type === 'info' && 'info'}`">
    <div class="close-btn">
      <span
        @click="close"
        :style="`color: ${loadBoxColor(type)}`"
        class="material-icons-outlined"
        >close</span
      >
    </div>
    <div class="text">
      <div class="icon">
        <svg
          v-if="type === 'warning'"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 27.3333C6.63602 27.3333 0.666687 21.364 0.666687 14C0.666687 6.63596 6.63602 0.666626 14 0.666626C21.364 0.666626 27.3334 6.63596 27.3334 14C27.3334 21.364 21.364 27.3333 14 27.3333ZM12.6667 18V20.6666H15.3334V18H12.6667ZM12.6667 7.33329V15.3333H15.3334V7.33329H12.6667Z"
            fill="#EDA209"
          />
        </svg>
        <svg
          v-if="type === 'info'"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0001 27.3334C6.63608 27.3334 0.666748 21.3641 0.666748 14.0001C0.666748 6.63608 6.63608 0.666748 14.0001 0.666748C21.3641 0.666748 27.3334 6.63608 27.3334 14.0001C27.3334 21.3641 21.3641 27.3334 14.0001 27.3334ZM12.6667 12.6667V20.6667H15.3334V12.6667H12.6667ZM12.6667 7.33342V10.0001H15.3334V7.33342H12.6667Z"
            fill="#003063"
          />
        </svg>
      </div>
      <div class="info">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "warning",
    },
    open: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    loadBoxColor(type) {
      if (type === "warning") {
        return "#EDA209";
      }
      if (type === "info") {
        return "#003063";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-info {
  position: relative;
  border: 2px solid #eda209;
  border-left: 12px solid #eda209;
  border-radius: 8px;
  height: 124px;
  display: flex;
  align-items: center;
  padding: 38px 64px;
  padding-left: 24px;
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .text {
    display: flex;
    align-items: center;
    gap: 18px;
    .info {
      .title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 152%;
        color: #112c66;
      }
      .description {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #565e73;
      }
    }
  }
  &.info {
    border: 2px solid #003063;
    border-left: 12px solid #003063;
  }
}
</style>

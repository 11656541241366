import moment from 'moment'
import services from '../../services'

export default {
  namespaced: true,
  state: {
    cycles: [],
    currentCycle: {},

  },
  getters: {
    getCycles: state => state.cycles,
    getCurrentCycle: state => state.currentCycle,
  },
  mutations: {
    setCycles(state, payload) {
      state.cycles = payload
    },
    setCurrentCycle(state, payload) {
      state.currentCycle = payload
    }
  },
  actions: {
    async requestCycles({ commit }, {
      keepingSectorId,
      range
    }) {
      const { dataInterval, errosInterval } =
        await services.executions.getMonthCycles({
          zeladoria_id: keepingSectorId,
          data: range.start
            ? moment(range.start).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        });
      if (!errosInterval) {
        commit('setCycles', dataInterval)
      }
    },
    async saveMeasurement({ state }, userType) {
      if (userType === 3) {
        await services.executions.registerMonitorExecutionEvaluation({
          execucao_avaliacao_id: state.currentCycle.execucao_avaliacao_id,
          execucao_avaliacao_etapa_percentual:
            state.currentCycle.execucao_avaliacao_percentual_final,
        });
      }
      if (userType === 2) {
        await services.executions.registerSupervisorExecutionEvaluation({
          execucao_avaliacao_id: state.currentCycle.execucao_avaliacao_id,
          execucao_avaliacao_etapa_percentual:
            state.currentCycle.execucao_avaliacao_prefeitura_percentual_final,
        });
      }
    },
    async finishMeasurement({ state }, userType) {
      if (userType === 3) {
        await services.executions.finishMonitorExecutionEvaluation({
          execucao_avaliacao_id: state.currentCycle.execucao_avaliacao_id,
          execucao_avaliacao_etapa_percentual:
            state.currentCycle.execucao_avaliacao_percentual_final,
        });
      }
      if (userType === 2) {
        await services.executions.finishSupervisorExecutionEvaluation({
          execucao_avaliacao_id: state.currentCycle.execucao_avaliacao_id,
          execucao_avaliacao_etapa_percentual:
            state.currentCycle.execucao_avaliacao_prefeitura_percentual_final,
        });
      }
    },
    selectCycle({ commit }, cycle) {
      commit('setCurrentCycle', cycle)
    }
  },
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"keeping-sector-map"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h3',{staticClass:"intern-title ml-3"},[_c('back-arrow'),_c('span',{staticClass:"page-nav"},[_vm._v(_vm._s(_vm.$t("keepingSectorsMap")))])],1),_c('div',{staticStyle:{"z-index":"900","width":"25%"}},[_c('multiselect',{attrs:{"value":_vm.neighborhoodDetails,"noOptions":_vm.$t('emptyList'),"selectLabel":"","deselectLabel":_vm.$t('remove'),"selectedLabel":_vm.$t('selected'),"options":_vm.neighborhoodNames,"track-by":"name","placeholder":_vm.$t('selecteANeighborhood'),"preselect-first":false,"multiple":true,"id":"multiselect","label":"name"},on:{"select":_vm.selectNeighborhood,"remove":_vm.removeNeighborhood}}),_c('label',{staticClass:"multiselect-label pt-2",attrs:{"for":"multiselect"}},[_vm._v(_vm._s(_vm.polygonsKeepingSector.length)+" "+_vm._s(_vm.$t("selectedKeepingSectors")))])],1)]),_c('transition',{attrs:{"name":"bounce"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingData),expression:"!loadingData"}]},[_c('div',{staticClass:"pl-3 pt-3"},[_c('div',{staticStyle:{"height":"calc(100vh - 220px)","position":"relative"}},[_c('GmapMap',{ref:"googleMap",staticStyle:{"height":"100%","width":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: true,
              fullscreenControl: false,
              mapTypeId: _vm.mapType,
              gestureHandling: 'greedy',
              disableDefaultUi: true,
            }}},[_vm._l((_vm.polygonsNeighborhood),function(polygon){return _c('div',{key:Math.random() + polygon.id},[_c('GmapPolygon',{ref:"polygon-neighborhood",refInFor:true,attrs:{"paths":polygon.latlngs,"options":polygon.options}})],1)}),_vm._l((_vm.polygonsKeepingSector),function(polygon){return _c('div',{key:Math.random() + polygon.id},[_c('GmapPolygon',{ref:"polygon",refInFor:true,attrs:{"paths":polygon.latlngs,"options":polygon.options},on:{"mouseover":function($event){polygon.display_name = true},"mouseout":function($event){polygon.display_name = false},"click":function($event){return _vm.goToZelador(polygon.zeladoria_id)}}}),_c('GmapCustomMarker',{staticClass:"mt-3 importance",attrs:{"draggable":false,"marker":polygon.center}},[_c('span',{staticClass:"map-label"},[_vm._v(_vm._s(polygon.zeladoria_nome))])]),(
                  polygon.qtd_usuarios_zeladoria > 0 &&
                  polygon.zeladoria_incluida === 1
                )?_c('GmapCustomMarker',{staticStyle:{"font-size":"1.4rem","color":"white"},attrs:{"draggable":false,"marker":polygon.center}},[_c('span',{staticClass:"material-icons"},[_vm._v("person")])]):_vm._e(),_c('GmapCustomMarker',{attrs:{"draggable":false,"marker":polygon.center}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[(polygon.zeladoria_incluida === 0)?_c('span',{staticClass:"material-icons",staticStyle:{"color":"black","background":"#fdc640","font-size":"1.4rem"}},[_vm._v(" lock ")]):_vm._e()])])],1)})],2),_c('div',{staticClass:"mapTypeControllers"},[_c('CSelect',{attrs:{"placeholder":"Tipo","options":_vm.mapTypes,"value":_vm.mapType},on:{"update:value":function($event){_vm.mapType=$event}}})],1)],1),_c('hr')])])]),(_vm.loadingData)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-2 mb-4"},[_c('img',{staticClass:"mb-4",attrs:{"src":require(`@/assets/img/loading.gif`),"alt":"AMA Carregando","width":"150"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
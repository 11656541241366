<template>
  <div class="w-100">
    <div
      v-for="(option, index) of data"
      :key="'statistics-' + option.atividade_pesquisa_resposta_id + index"
    >
      <div class="option-container">
        <div class="question">{{ option.atividade_pesquisa_resposta }}</div>

        <div class="info">
          <div class="progress-bar">
            <div
              class="full"
              :style="`--to-width: ${option.percentage}%; background: ${colors[index]}; width: ${option.percentage}%`"
            ></div>
          </div>
          <div class="percent">{{ option.percentage }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionStatistics",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colors: ["#D3341D", "#E06914", "#EDA209", "#9AC625", "#5CCDF2"],
    };
  },
};
</script>

<style scoped lang="scss">
.option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #f3f4f5;
}

.question {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #131e3b;
}

.info {
  display: flex;
  align-items: center;

  .progress-bar {
    width: 200px;
    height: 12px;
    background: #e9eefa;
    border-radius: 8px;
    margin-right: 8px;
  }

  .percent {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #112c66;
    width: 42px;
    text-align: left;
  }

  .full {
    height: 100%;
    border-radius: 8px;
    animation: progress 1.2s ease-in-out;
  }

  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: var(--to-width);
    }
  }
}
</style>

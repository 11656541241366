<template>
  <div class="neighborhoods-map">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("neighborhoodsMap") }}</span>
    </h3>
    <transition name="bounce">
      <div v-if="!loadingData">
        <div class="pl-3 pr-1 pt-2">
          <div style="height: calc(100vh - 220px); position: relative">
            <GmapMap
              ref="googleMap"
              :center="center"
              :zoom="zoom"
              style="height: 100%; width: 100%"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                mapTypeId: mapType,
                gestureHandling: 'greedy',
                fullscreenControl: false,
                disableDefaultUi: true,
              }"
            >
              <div
                v-for="polygon of polygonNeighborhood"
                :key="'polygonsKeepingSector' + polygon.id"
              >
                <GmapPolygon
                  @click="goToNeighborhood(polygon.bairro_id)"
                  @mouseover="polygon.display_name = true"
                  @mouseout="polygon.display_name = false"
                  ref="polygon"
                  :paths="polygon.latlngs"
                  :options="polygon.options"
                />
                <GmapCustomMarker
                  v-if="polygon.display_name"
                  :draggable="false"
                  :marker="polygon.center"
                >
                  <span class="map-label">{{ polygon.bairro_nome }}</span>
                </GmapCustomMarker>
              </div>
            </GmapMap>
            <div class="mapTypeControllers">
              <CSelect
                placeholder="Tipo"
                :options="mapTypes"
                :value.sync="mapType"
              ></CSelect>
            </div>
          </div>
          <hr />
          <input
            name="inputFile"
            type="file"
            @change="processFile($event)"
            accept=".geojson"
          />
        </div>
      </div>
    </transition>
    <div
      v-if="loadingData"
      class="d-flex justify-content-center align-items-center mt-2 mb-4"
    >
      <img
        :src="require(`@/assets/img/loading.gif`)"
        class="mb-4"
        alt="AMA Carregando"
        width="150"
      />
    </div>
  </div>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import BackArrow from "../../components/BackArrow.vue";

import services from "../../services";
import {
  invertLatLngOriginal,
  convertGeoStringToGoogleMaps,
} from "../../utils/geoMaps";

export default {
  name: "MapaZelador",
  components: {
    GmapCustomMarker,
    BackArrow,
  },
  computed: {},
  data() {
    return {
      zoom: 4,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      polygonNeighborhood: [],
      polygonsInput: [],
      linesInput: [],
      readContentFile: null,
      loadingData: true,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "satellite",
    };
  },
  watch: {
    readContentFile(newFile) {
      if (newFile.features.length > 0) {
        for (let item of newFile.features) {
          if (item.geometry.type == "Polygon") {
            this.polygonsInput.push({
              latlngs: invertLatLngOriginal(item.geometry.coordinates[0]),
              color: "#ff0000",
              bairro_id: item.properties.id,
              bairro_nome: item.properties.name,
            });
          } else if (item.geometry.type == "LineString") {
            this.linesInput.push({
              latlngs: invertLatLngOriginal(item.geometry.coordinates),
              color: "#ff0000",
            });
          }
        }
      }
    },
  },
  mounted: async function () {
    const { data, errors } = await services.neighborhoods.getNeighborhoodMap();
    if (!errors) {
      this.formatMapToRender(data);
      setTimeout(() => {
        this.fitBoundsGoogle();
      }, 0);
      this.loadingData = false;
    }
  },
  methods: {
    formatMapToRender(data) {
      data.map((item, index) => {
        if (item.bairro_limite !== "" && item.bairro_limite !== null) {
          let bairro = {
            latlngs: convertGeoStringToGoogleMaps(item.bairro_limite),
            options: {
              strokeColor: "#548aba",
              fillColor: "#548aba",
            },
            center: {
              lat: item.bairro_lat,
              lng: item.bairro_lng,
            },
            bairro_id: item.bairro_id,
            bairro_nome: item.bairro_nome,
            id: index,
            display_name: false,
          };

          bairro.latlngs.map((item) => {
            this.bounds.push(item);
          });
          this.polygonNeighborhood.push(bairro);
        }
      });
    },
    processFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]);
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    readFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.readContentFile = JSON.parse(e.target.result);
      };
      reader.readAsText(file);
    },
    goToNeighborhood(id) {
      this.$router.push({
        name: "DetailsBairro",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.neighborhoods-map {
  background-color: #fff;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.titulo_pin {
  background: #ffffff85;
  width: max-content;
  text-align: center;
  border-radius: 10px;
  font-size: 9px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
}
.mapTypeControllers {
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: 500;
  width: 120px;
}
</style>

<template>
  <div class="wrapper">
    <div class="options">
      <ul>
        <li
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="handleBold"
        >
          <span class="material-icons-outlined">format_bold</span>
        </li>
        <li
          @click="handleItalic"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <span class="material-icons-outlined">format_italic</span>
        </li>
        <li
          @click="handleHeading(1)"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          <b class="header-item">H1</b>
        </li>
        <li
          @click="handleHeading(2)"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          <b class="header-item">H2</b>
        </li>
        <li
          @click="handleHeading(3)"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        >
          <b class="header-item">H3</b>
        </li>
        <li
          @click="handleHeading(4)"
          :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        >
          <b class="header-item">H4</b>
        </li>
        <li
          @click="handleHeading(5)"
          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        >
          <b class="header-item">H5</b>
        </li>
        <li
          @click="handleHeading(6)"
          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        >
          <b class="header-item">H6</b>
        </li>
        <li
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          @click="handleAlign('left')"
        >
          <span class="material-icons-outlined">format_align_left</span>
        </li>
        <li
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          @click="handleAlign('center')"
        >
          <span class="material-icons-outlined">format_align_center</span>
        </li>
        <li
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          @click="handleAlign('right')"
        >
          <span class="material-icons-outlined">format_align_right</span>
        </li>
        <li @click="handleTable">
          <span class="material-icons-outlined">calendar_view_month</span>
        </li>
        <li class="btn" @click="handleRemoveTable">
          <span class="header-item">Remover tabela</span>
        </li>
        <li class="btn" @click="handleRemoveColumn">
          <span class="header-item">Remover coluna</span>
        </li>
        <li class="btn" @click="handleRemoveRow">
          <span class="header-item">Remover linha</span>
        </li>
      </ul>
    </div>
    <editor-content :editable="false" class="editor" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import FontFamily from "@tiptap/extension-font-family";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import TextStyle from "@tiptap/extension-text-style";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Image,
        FontFamily,
        TextStyle,
        Table,
        TableHeader,
        TableCell,
        TableRow,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        FontFamily.configure({
          types: ["textStyle", "paragraph"],
        }),
      ],
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
    this.editor
      .chain()
      .focus()
      .setFontFamily("Comic Sans MS, Comic Sans")
      .run();
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    handleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    handleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    handleHeading(level) {
      this.editor.commands.toggleHeading({ level: level });
    },
    handleAlign(direction) {
      this.editor.chain().focus().setTextAlign(direction).run();
    },
    handleRemoveColumn() {
      this.editor.chain().focus().deleteColumn().run();
    },
    handleRemoveRow() {
      this.editor.chain().focus().deleteRow().run();
    },
    handleRemoveTable() {
      this.editor.chain().focus().deleteTable().run();
    },
    handleTable() {
      this.editor
        .chain()
        .focus()
        .insertTable({ rows: 1, cols: 2, withHeaderRow: false })
        .run();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  border: 1px solid #838998;
  border-radius: 8px;

  .material-icons-outlined,
  .header-item {
    color: #29334e;
  }

  .options {
    padding: 10px 8px;
    border-bottom: 1px solid #838998;
    ul {
      list-style: none;
      display: flex;
      gap: 10px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        padding: 4px;
        font-weight: bold;
        border: 1px solid #838998;
      }
      li:not(.btn) {
        color: #000;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.2rem;
        cursor: pointer;
        span,
        b {
          display: inline-block;
          vertical-align: middle;
          margin: 7px;
        }
        b {
          margin: 5px;
          margin-left: 7px;
        }
      }

      li:hover,
      li.is-active {
        background: #ccc;
      }
    }
    ul,
    li {
      margin: 0;
      padding: 0;
    }
  }
}
</style>

<style lang="scss">
.ProseMirror p {
  margin-bottom: 0 !important;
  padding: 0;
}

.ProseMirror {
  padding: 10px;
}

.editor {
  max-height: 600px;
  overflow: auto;
  color: #000;
  background: #fafbfb;
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100% !important;
    margin: 0 !important;
    overflow: hidden;
    tr {
      width: 100%;
    }

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    p {
      margin: 0;
    }
  }
}
</style>

<template>
  <div class="monitor-report-wrapper">
    <div class="month-selector">
      <div class="intern-title mb-3">
        <back-arrow />
        <span class="page-nav">Overview do mês</span>
      </div>
      <month-picker-input
        date-format="%n"
        :default-month="defaultMonth + 1"
        @input="handleMonthSelect"
        lang="pt"
      ></month-picker-input>
    </div>
    <div class="stats">
      <div>
        <div class="stats-title">Estatísticas</div>
        <div class="cards">
          <div class="stats-card">
            <p>Microáreas e zeladores monitorados</p>
            <span
              >{{ bigNumbers.microAreas }} - {{ bigNumbers.totalUsers }}</span
            >
          </div>
          <div class="stats-card">
            <p>Zeladores a desligar</p>
            <span>{{ bigNumbers.toCancel }}</span>
          </div>
          <div class="stats-card">
            <p>Zeladores com baixo desempenho</p>
            <span>{{ bigNumbers.lowPerformance }}</span>
          </div>
          <div class="stats-card">
            <p>Quantidade de execuções não avaliadas</p>
            <span>{{ bigNumbers.notEvaluated }}</span>
          </div>
        </div>
      </div>
      <div class="divisor">
        <div class="line"></div>
      </div>
      <div>
        <div class="stats-title">Desempenho de avaliações por execuções</div>
        <div class="chart">
          <Bars
            :height="250"
            :hideLabel="true"
            :key="chart.xAxis[0]"
            :newGraphY="chart.yAxis"
            :newGraphX="chart.xAxis"
          />
        </div>
      </div>
    </div>
    <div class="users">
      <div class="stats-title">Zeladorias monitoradas</div>
      <div class="tabs">
        <div
          :class="`tab ${tab.active ? 'active' : ''}`"
          v-for="(tab, index) in tabs"
          :key="index"
          @click="handleChangeTab(tab.value)"
        >
          {{ tab.label }}
          <div class="counter">{{ tab.qtd }}</div>
        </div>
      </div>
      <transition name="bounce">
        <div v-if="!loadingData" class="table">
          <Table @filter="handleFilter" :fields="fields" :hasActions="true">
            <TableRow v-for="item in users" :key="item.zeladoria_id">
              <TableCell
                v-for="field in fields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  <span class="table-row-text">{{
                    item[field.key] || "--"
                  }}</span>
                </template>
                <template v-slot:execucao_qtd>
                  {{ item.execucao_qtd || 0 }} /
                  {{ item.execucao_avaliada_qtd || 0 }}
                </template>
                <template v-slot:zeladoria_nome>
                  <div class="keeping-sector-link">
                    <router-link
                      class="link"
                      :to="`/detalhes-zeladoria/${item.zeladoria_id}`"
                    >
                      <span>
                        {{ item.zeladoria_nome }}
                      </span>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.66667 2V3.33333H1.33333V10.6667H8.66667V7.33333H10V11.3333C10 11.5101 9.92976 11.6797 9.80474 11.8047C9.67971 11.9298 9.51014 12 9.33333 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V2.66667C0 2.48986 0.0702379 2.32029 0.195262 2.19526C0.320286 2.07024 0.489856 2 0.666667 2H4.66667ZM12 0V6L9.47067 3.47133L5.47133 7.47133L4.52867 6.52867L8.528 2.52867L6 0H12Z"
                          fill="#00B1EB"
                        />
                      </svg>
                    </router-link>
                  </div>
                </template>
                <template v-slot:percentual>
                  {{ item.percentual.toFixed(1) || 0 }} %
                </template>
                <template v-slot:acoes>
                  <CondensedDropdown
                    width="270"
                    @dropdown-click="
                      (ev) => handleCondensedDropdownClick(ev, item)
                    "
                    :options="[
                      { label: 'Desempenho do Zelador', value: 'performance' },
                      { label: 'Calendário', value: 'calendar' },
                      { label: 'Desativar Zelador', value: 'deactivate' },
                    ]"
                  />
                </template>
              </TableCell>
            </TableRow>
            <template v-if="users.length === 0" v-slot:no-results>
              <NoResults :message="$t('noResults')" />
            </template>
          </Table>
          <!-- <infinite-loading @infinite="infiniteHandler">
            <div slot="spinner">
              <Loading />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading> -->
        </div>
      </transition>
      <Loading v-if="loadingData" />
    </div>
    <Modal class="modal-confirmacao" v-show="modalDesativar" :width="500">
      <template v-slot:header>
        <span class="titulo">Desativar Zelador</span>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          Tem certeza que deseja desativar {{ userToDeactivate.name }}
          Martins da Zeladoria Digital? Este procedimento implica em:
          <ul>
            <li>Bloqueio de acesso ao aplicativo zeladoria</li>
            <li>Desvinculação da Área de Zeladoria</li>
          </ul>
        </div>
        <div class="subtitle">
          Confirme digitando desativar
          <span style="color: #db5946">desativar</span> abaixo
        </div>
        <div style="margin: 24px 0">
          <Input
            v-model="deactivateConfirmation"
            placeholder="Digite a confirmação"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <Button
            name="Desativar"
            :disabled="deactivateConfirmation !== 'desativar'"
            :class="`reprove-btn ${
              deactivateConfirmation !== 'desativar' ? 'disabled' : ''
            }`"
            @clicked="handleDeactivate"
          />
          <Button
            :name="$t('cancel')"
            class="cancel-btn"
            @clicked="
              modalDesativar = false;
              deactivateConfirmation = null;
            "
          />
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalOk" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0002 73.6666C16.7492 73.6666 0.333496 57.2509 0.333496 36.9999C0.333496 16.7489 16.7492 0.333252 37.0002 0.333252C57.2512 0.333252 73.6668 16.7489 73.6668 36.9999C73.6668 57.2509 57.2512 73.6666 37.0002 73.6666ZM33.3445 51.6666L59.2678 25.7396L54.0832 20.5549L33.3445 41.2973L22.9715 30.9243L17.7868 36.1089L33.3445 51.6666Z"
              fill="#80BE24"
            />
          </svg>
          <span class="titulo">Pronto !</span>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          O usuário <b>{{ userToDeactivate.name }} </b> foi desvinculado
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="handleConfirmation">
            Continuar
          </button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalErro" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40ZM20 17.172L14.344 11.514L11.514 14.344L17.172 20L11.514 25.656L14.344 28.486L20 22.828L25.656 28.486L28.486 25.656L22.828 20L28.486 14.344L25.656 11.514L20 17.172Z"
              fill="#FF002E"
            />
          </svg>
          <span class="titulo">Erro</span>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">Erro ao desativar zelador</div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="handleConfirmation">
            Continuar
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { MonthPickerInput } from "vue-month-picker";
import Bars from "../../components/Bars.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/form/Input.vue";
import Button from "../../components/Button.vue";
import services from "../../services";
import { decrypt } from "../../utils/decrypt";
import NoResults from "../../components/table/NoResults.vue";
import BackArrow from "../../components/BackArrow.vue";

export default {
  components: {
    MonthPickerInput,
    CondensedDropdown,
    Button,
    Input,
    Bars,
    Table,
    TableRow,
    TableCell,
    Loading,
    Modal,
    NoResults,
    BackArrow,
  },
  data() {
    return {
      users: [],
      usersSourceOfTruth: [],
      activeFilters: [],
      neighborhoods: [],
      loadingData: false,
      modalDesativar: false,
      modalOk: false,
      modalErro: false,
      deactivateConfirmation: null,
      monitorId: null,
      selectedMonth: null,
      currentTab: 0,
      defaultMonth: null,
      userToDeactivate: {
        name: null,
        id: null,
      },
      bigNumbers: {
        higPerformance: 0,
        lowPerformance: 0,
        toCancel: 0,
        totalHighLowPerformance: 0,
        notEvaluated: 0,
        microAreas: 0,
        totalUsers: 0,
        cancelled: 0,
        graph: [],
      },
      chart: {
        xAxis: [],
        yAxis: null,
      },
      range: {
        start: null,
        end: null,
      },
      pagination: {
        page: 1,
        limit: 25,
        filter: "",
      },
      tabs: [
        {
          active: true,
          label: "Zeladores a desligar",
          value: 0,
          show: true,
          qtd: 0,
        },
        {
          active: false,
          label: "Baixo desempenho ",
          value: 1,
          show: true,
          qtd: 0,
        },
        {
          active: false,
          label: "Alto desempenho",
          value: 2,
          show: true,
          qtd: 0,
        },
        {
          active: false,
          label: "Solicitações de distrato",
          value: 3,
          show: true,
          qtd: 0,
        },
      ],
      fields: [],
    };
  },
  async created() {
    const user = JSON.parse(decrypt(localStorage.getItem("ama")));
    this.monitorId = user.usuario_gestor_id;
    const currentDate = new Date();
    this.defaultMonth = currentDate.getMonth();
    this.selectedMonth = this.defaultMonth;
    this.range.start = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    this.range.end = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    this.setTableColumns();
  },
  watch: {
    selectedMonth: {
      async handler() {
        await this.initData();
      },
    },
  },
  methods: {
    async initData() {
      if (this.currentTab === 0) {
        await this.getToCancelUsers();
      }
      if (this.currentTab === 1) {
        await this.getLowPerformanceUsers();
      }
      if (this.currentTab === 2) {
        await this.getHighPerformanceUsers();
      }
      if (this.currentTab === 3) {
        await this.getDistractUsers();
      }
      this.users = this.usersSourceOfTruth;

      await this.getUserPerformanceTotals();
      await this.getNeighborhoods();
    },
    async getNeighborhoods() {
      const response = await services.neighborhoods.getNeighborhoods();
      this.fields.map((item) => {
        if (item.key === "bairro_nome") {
          item.filters = [];
          response.data.forEach((neighborhood) => {
            item.filters.push({
              column: "bairro_nome",
              label: neighborhood.bairro_nome,
              value: neighborhood.bairro_id,
              active: false,
            });
          });
        }
      });
    },
    async setTableColumns() {
      if (this.currentTab === 0 || this.currentTab === 3) {
        this.fields = [
          {
            key: "zeladoria_nome",
            label: "Área de zeladoria",
            _style: "min-width:100px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_nome",
            label: "Zelador",
            _style: "min-width:180px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "bairro_nome",
            label: "Bairro",
            _style: "min-width:100px",
            sortable: false,
            filterable: true,
            filterTitle: "Filtrar por bairro",
            filters: [],
            searchableFilter: true,
            sortDirection: null,
          },
          {
            key: "acoes",
            label: "Ações",
            _style: "min-width:30px;",
            sortable: false,
            sortDirection: null,
          },
        ];
      } else {
        this.fields = [
          {
            key: "zeladoria_nome",
            label: "Área de zeladoria",
            _style: "min-width:100px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "usuario_nome",
            label: "Zelador",
            _style: "min-width:180px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "bairro_nome",
            label: "Bairro",
            _style: "min-width:100px",
            sortable: false,
            filterable: true,
            filterTitle: "Filtrar por bairro",
            filters: [],
            searchableFilter: true,
            sortDirection: null,
          },
          {
            key: "execucao_qtd",
            label: "Execucoes / Avaliações",
            _style: "min-width:100px",
            sortable: false,
            sortDirection: null,
          },
          {
            key: "percentual",
            label: "Avaliação do Monitor",
            _style: "min-width:100px",
            sortable: false,
            // filterable: true,
            // filters: [
            //   {
            //     column: "percentual",
            //     label: "100%",
            //     value: 100,
            //     active: false,
            //   },
            //   { column: "percentual", label: "75%", value: 75, active: false },
            //   { column: "percentual", label: "50%", value: 50, active: false },
            //   { column: "percentual", label: "25%", value: 25, active: false },
            //   { column: "percentual", label: "0%", value: 0, active: false },
            // ],
            // filterTitle: "Filtrar por média",
            sortDirection: null,
          },
          {
            key: "acoes",
            label: "Ações",
            _style: "min-width:30px;",
            sortable: false,
            sortDirection: null,
          },
        ];
      }
      await this.getNeighborhoods();
    },
    handleFilter(ev) {
      this.activeFilters = [];
      const toFilter = ev.filters.filter((item) => item.active);
      let filteredUsers = [];

      if (toFilter.length <= 0) {
        this.activeFilters = this.activeFilters.filter((item) => {
          return !ev.filters.includes(item);
        });
      } else {
        toFilter.forEach((item) => {
          const alreadyExists = this.activeFilters.find(
            (el) => el.value === item.value
          );
          if (!alreadyExists) {
            this.activeFilters.push(item);
          }
        });
      }

      // console.log(this.activeFilters);

      // if (this.currentTab === 0) {
      //   await this.getToCancelUsers();
      // }
      // if (this.currentTab === 1) {
      //   await this.getLowPerformanceUsers();
      // }
      // if (this.currentTab === 2) {
      //   await this.getHighPerformanceUsers();
      // }
      // if (this.currentTab === 3) {
      //   await this.getDistractUsers();
      // }

      if (this.activeFilters.length > 0) {
        this.users = this.usersSourceOfTruth;
        this.activeFilters.forEach((activeFilter) => {
          const results = this.users.filter((user) => {
            // if (activeFilter.column === "percentual") {
            //   return user[activeFilter.column] >= activeFilter.value;
            // } else {
            //   }
            return user[activeFilter.column] === activeFilter.label;
          });
          filteredUsers.push(...results);
        });

        this.users = filteredUsers;
      } else {
        this.users = this.usersSourceOfTruth;
      }
    },
    async getHighPerformanceUsers() {
      this.loadingData = true;
      const { data, errors } = await services.users.getHighPerformanceUsers({
        date: this.range.start,
        monitorId: this.monitorId,
      });
      if (!errors) {
        this.usersSourceOfTruth = data;
        this.users = data;
      }
      this.loadingData = false;
    },
    async getLowPerformanceUsers() {
      this.loadingData = true;
      const { data, errors } = await services.users.getLowPerformanceUsers({
        date: this.range.start,
        monitorId: this.monitorId,
      });
      if (!errors) {
        this.usersSourceOfTruth = data;
        this.users = data;
      }
      this.loadingData = false;
    },
    async getDistractUsers() {
      this.loadingData = true;
      const { data, errors } = await services.users.getDistractUsers({
        date: this.range.start,
        monitorId: this.monitorId,
      });
      if (!errors) {
        this.users = data;
        this.usersSourceOfTruth = data;
      }
      this.loadingData = false;
    },
    async handleConfirmation() {
      this.modalOk = false;
      this.modalErro = false;
      this.userToDeactivate.id = null;
      this.userToDeactivate.name = null;

      await this.initData();
    },
    async getToCancelUsers() {
      this.loadingData = true;
      const { data, errors } = await services.users.getToCancelUsers({
        date: this.range.start,
        monitorId: this.monitorId,
      });
      if (!errors) {
        this.usersSourceOfTruth = data;
        this.users = data;
      }
      this.loadingData = false;
    },
    async getUserPerformanceTotals() {
      this.loadingData = true;
      const { data } = await services.users.getUserPerformanceTotals({
        date: this.range.start,
        monitorId: this.monitorId,
      });

      this.bigNumbers.lowPerformance = data.baixo_desempenho || 0;
      this.tabs[1].qtd = this.bigNumbers.lowPerformance;

      this.bigNumbers.higPerformance = data.alto_desempenho || 0;
      this.tabs[2].qtd = this.bigNumbers.higPerformance;

      this.bigNumbers.cancelled = data.distratos_zeladores || 0;
      this.tabs[3].qtd = this.bigNumbers.cancelled;

      this.bigNumbers.microAreas = data.total_microareas || 0;

      this.bigNumbers.totalUsers = data.total_zeladores || 0;

      this.bigNumbers.notEvaluated = data.total_execucoes_nao_avaliadas || 0;

      this.bigNumbers.toCancel = data.zeladores_desligar || 0;
      this.tabs[0].qtd = this.bigNumbers.toCancel;

      this.bigNumbers.graph = data.graph;

      this.mountChart(data.graph);

      this.loadingData = false;
    },
    handleMonthSelect(ev) {
      this.range.start = ev.from.toISOString();
      this.range.end = ev.to.toISOString();
      this.selectedMonth = ev.to.getMonth();
    },
    async handleChangeTab(tab) {
      this.activeFilters = [];
      this.tabs.map((item) => {
        if (item.value === tab) {
          item.active = true;
          this.currentTab = item.value;
        } else {
          item.active = false;
        }
      });
      if (tab === 0) {
        await this.getToCancelUsers();
      }
      if (tab === 1) {
        await this.getLowPerformanceUsers();
      }
      if (tab === 2) {
        await this.getHighPerformanceUsers();
      }
      if (tab === 3) {
        await this.getDistractUsers();
      }

      this.setTableColumns();
    },
    handleCondensedDropdownClick(value, item) {
      if (value === "performance") {
        this.$router.push({
          name: "DesempenhoZelador",
          params: { id: item.usuario_id },
        });
      }
      if (value === "calendar") {
        this.$router.push({
          name: "CalendarioZelador",
          params: { id: item.usuario_id },
        });
      }
      if (value === "deactivate") {
        this.modalDesativar = true;
        this.userToDeactivate.id = item.usuario_id;
        this.userToDeactivate.name = item.usuario_nome;
      }
    },
    async infiniteHandler($state) {
      let currentData = [];
      let currentErrors = null;

      this.pagination.page++;

      if (this.currentTab === 0) {
        const { data, errors } = await services.users.getToCancelUsers({
          date: this.range.start,
          monitorId: this.monitorId,
        });
        currentData = data;
        currentErrors = errors;
      }
      if (this.currentTab === 1) {
        const { data, errors } = await services.users.getLowPerformanceUsers({
          date: this.range.start,
          monitorId: this.monitorId,
        });
        currentData = data;
        currentErrors = errors;
      }
      if (this.currentTab === 2) {
        const { data, errors } = await services.users.getHighPerformanceUsers({
          date: this.range.start,
          monitorId: this.monitorId,
        });
        currentData = data;
        currentErrors = errors;
      }
      if (this.currentTab === 3) {
        const { data, errors } = await services.users.getDistractUsers({
          date: this.range.start,
          monitorId: this.monitorId,
        });
        currentData = data;
        currentErrors = errors;
      }

      if (!currentErrors) {
        if (currentData.length > 0) {
          let temp = currentData;
          temp.forEach((item) => {
            this.users.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      } else {
        $state.complete();
      }
    },
    mountChart(data) {
      this.chart.xAxis = data.map((item) => {
        const labelInitial = item.data_inicio_fim.slice(0, 10);
        const labelFinal = item.data_inicio_fim.slice(11, 21);
        return `${labelInitial.replace(/-/g, "/")} a ${labelFinal.replace(
          /-/g,
          "/"
        )}`;
      });
      this.chart.yAxis = [
        {
          label: "Quantidade de avaliações por execução",
          backgroundColor: "#5C7B9B",
          data: data.map((item) => {
            const values = item.qty_execucoes_qty_avaliadas
              ? item.qty_execucoes_qty_avaliadas.split(/[/]/)
              : 0;
            return +values[0];
          }),
        },
        {
          label: "Quantidade de execuções",
          backgroundColor: "#B8E9F9",
          data: data.map((item) => {
            const values = item.qty_execucoes_qty_avaliadas
              ? item.qty_execucoes_qty_avaliadas.split(/[/]/)
              : 0;
            return +values[1];
          }),
        },
      ];
    },
    async handleDeactivate() {
      const { errors } = await services.users.monitorDesvinculateUser({
        usuario_id: this.userToDeactivate.id,
        usuario_motivo_desclassificacao: "",
      });
      if (!errors) {
        this.modalOk = true;
      } else {
        this.modalErro = true;
      }
      this.modalDesativar = false;
      this.deactivateConfirmation = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.monitor-report-wrapper {
  .stats-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #131e3b;
    margin-bottom: 32px;
  }
  .stats {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    gap: 32px;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
    .divisor {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        width: 1px;
        background: #dddee3;
        height: 335px;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
      }
      .stats-card {
        flex: 1;
        padding: 16px 20px;
        border: 1px solid #dddee3;
        border-radius: 16px;
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #565e73;
        }
        span {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: #131e3b;
        }
      }
    }
    .chart {
    }
  }
  .tabs {
    display: flex;
    color: #838998;
  }

  .tab {
    display: flex;
    flex-direction: row;
    padding: 5px 15px;
    color: #838998;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
  .counter {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 132%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6f7fd;
    min-width: 32px;
    height: 24px;
    border-radius: 6px;
    margin-left: 12px;
    padding: 4px 8px;
    background: #ff002e;
    color: #fff;
  }

  .tab.active {
    color: #131e3b;
    border-bottom: 4px solid #00b1eb;
  }
  .users {
    margin-top: 40px;
  }
  .modal-confirmacao {
    .titulo {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #131e3b;
    }
    .subtitle {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #565e73;
      span {
        color: #003366;
      }
      ul {
        text-decoration: none;
        margin-top: 16px;
      }
    }
    .btn-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .cancel-btn {
        margin-top: 12px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border-radius: 8px;
        background: white;
        color: #131e3b;
        border: none;
        outline: none;

        &:hover {
          background: #e2f4ff;
        }
      }
      .reprove-btn {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #d3341d;
        padding: 8px;
        border-radius: 8px;
        color: white;
        border: none;
        outline: none;
        &.disabled {
          opacity: 0.6;
        }
      }
      .save-btn {
        margin: 12px 0px 12px 12px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #003366;
        padding: 8px;
        border-radius: 8px;
        color: white;
        border: none;
        outline: none;

        &:hover {
          background: #003366;
        }
      }
    }
  }
  .keeping-sector-link {
    .link {
      display: flex;
      align-items: baseline;
      gap: 10px;
      text-decoration: none;
    }
    span {
      color: #00b1eb !important;
      font-weight: 700px;
    }
  }
}
</style>

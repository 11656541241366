<template>
  <div class="list-container">
    <CoolLightBox
      :items="item"
      :index="index"
      @close="(index = null), (item = [])"
    >
    </CoolLightBox>

    <ul>
      <li
        class="d-flex p-2 align-items-center justify-content-between list-item position-relative"
      >
        <slot name="header"></slot>
        <span
          title="Filtros"
          class="material-icons pointer filters mr-3"
          :class="activitiesActiveCount !== 1 && 'filter-disabled'"
          @click="showFilterTag()"
          >filter_alt</span
        >
        <Transition name="filter">
          <div class="absolute-box" v-if="filterModal">
            <CheckboxFilter
              title="Filtros"
              @clicked="filterModal = !filterModal"
              :tagsData="actualTags"
              @filter-by-tag="filterTag"
              name="Filtre por Tag"
            />
          </div>
        </Transition>
      </li>

      <div v-if="activitiesData.length === 0">
        <li class="d-flex p-3 align-items-center justify-content-center">
          <p class="subtitle p-2 pb-0 mb-1 ml-2">
            {{ $t("emptyActivities") }}
          </p>
        </li>
      </div>

      <div v-if="activitiesData.length > 0" :key="executions">
        <div
          v-for="(activity, index) in activitiesData"
          :key="activity.execucao_uuid"
        >
          <!-- CARD SE NÃO FOR EDUCAÇÃO AMBIENTAL PESQUISA -->

          <div v-if="activity.atividade_id != 109">
            <li
              class="d-flex pl-3 pr-3 pt-3 align-items-center justify-content-between"
              :class="activity.active ? '' : 'list-item'"
            >
              <div class="d-flex align-items-center p-2">
                <img
                  :src="activity.atividade_icon"
                  class="mr-3"
                  width="27"
                  height="27"
                />
                <div>
                  <p class="activity-name mb-0">
                    {{ activity.atividade_nome }}
                  </p>
                  <span class="activity-date">{{ activity.data_inicio }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center p-1 position-relative">
                <span class="activity-counter mr-3"
                  >{{ activity.qtd_fotos }}
                  {{ activity.qtd_fotos === 1 ? "FOTO" : "FOTOS" }}</span
                >
                <span
                  v-if="!timelineView"
                  class="material-icons pointer mr-3 new-icons"
                  @click="timelineView = !timelineView"
                  >show_chart</span
                >
                <span
                  v-else
                  class="material-icons pointer mr-3 new-icons mr-3"
                  style="background-color: #00b1eb"
                  @click="timelineView = !timelineView"
                  >collections</span
                >
                <span
                  class="d-none material-icons pointer mr-3"
                  style="background-color: #00b1eb"
                  @click="showAllPhotos(activity.execucao_uuid)"
                  >collections</span
                >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <img src="../assets/img/star.svg" width="14" height="14" />
                  <p class="star-legend mb-0">{{ activity.execucao_nota }}/5</p>
                </div>
              </div>
            </li>

            <li v-if="activity.active" class="list-item mt-2">
              <div v-if="activity.photos" class="w-100">
                <div v-if="!timelineView" class="photo-container">
                  <div
                    v-for="(photo, index) in activity.photos"
                    :key="photo.execucao_timeline_uuid"
                    class="photo-container-loop"
                  >
                    <div class="photo-container-item">
                      <img
                        class="item pointer"
                        :src="photo.execucao_timeline_foto_thumb"
                        alt="Foto da atividade"
                        @click="openModal(activity.photos, index)"
                      />
                      <div
                        class="d-flex align-items-center justify-content-start mt-3 mb-3 ml-1"
                      >
                        <div class="pr-1">
                          <div class="pinMarker mb-3">
                            <span class="pinCounter">{{ photo.counter }}</span>
                          </div>
                        </div>
                        <div class="ml-2">
                          <div class="photo-subtitle">
                            {{
                              photo.tags[0]
                                ? photo.tags[0].tag_descricao || ""
                                : ""
                            }}
                          </div>
                          <div class="photo-subtitle-time">
                            {{
                              photo.execucao_timeline_data
                                .split("T")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")
                            }}
                          </div>
                          <div class="photo-subtitle-time">
                            {{ photo.execucao_timeline_lat }},
                            {{ photo.execucao_timeline_lng }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="timelineView"
                  class="card-educacao-ambiental d-flex justify-content-center flex-column"
                >
                  <div
                    v-for="(photo, index) in activity.photos"
                    :key="photo.execucao_timeline_uuid"
                    class="pl-3 pt-2"
                  >
                    <div>
                      <div class="d-flex ml-1">
                        <div class="pinMarker">
                          <span class="pinCounter">{{ photo.counter }}</span>
                        </div>

                        <div class="ml-3">
                          <div class="photo-subtitle">
                            {{
                              photo.tags[0]
                                ? photo.tags[0].tag_descricao || ""
                                : ""
                            }}
                          </div>
                          <div class="photo-subtitle-time">
                            {{
                              photo.execucao_timeline_data
                                .split("T")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")
                            }}
                          </div>
                          <div
                            class="photo-subtitle-time"
                            style="margin-top: 2px"
                          >
                            {{ photo.execucao_timeline_lat }},
                            {{ photo.execucao_timeline_lng }}
                          </div>
                        </div>
                      </div>
                      <timeline
                        timeline-theme="#B8C5D3"
                        line-color="#B8C5D3"
                        class="ml-3"
                      >
                        <div
                          class="ml-4 pt-3 pb-3 pl-2 d-flex align-items-center"
                        >
                          <img
                            class="item pointer"
                            :src="photo.execucao_timeline_foto_thumb"
                            width="94.5%"
                            height="94.5%"
                            alt="Foto da atividade"
                            @click="openModal(activity.photos, index)"
                          />
                        </div>
                      </timeline>
                    </div>
                  </div>
                  <div class="mb-4 ml-3">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 32C7.1632 32 0 24.8368 0 16C0 7.1632 7.1632 0 16 0C24.8368 0 32 7.1632 32 16C32 24.8368 24.8368 32 16 32ZM14.4048 22.4L25.7168 11.0864L23.4544 8.824L14.4048 17.8752L9.8784 13.3488L7.616 15.6112L14.4048 22.4Z"
                        fill="#003063"
                      />
                    </svg>
                  </div>
                </div>
                <div v-if="userType === 3">
                  <div v-if="!activity.execucao_nota" class="mt-4 mb-3">
                    <div
                      class="d-flex justify-content-center align-items-center star-title mb-2 flex-column"
                    >
                      <div class="evaluation-label mb-2">
                        Avalie esta execução
                      </div>
                      <star-rating
                        active-color="#F2B836"
                        inactive-color="#C4C4C4"
                        v-model="rating[index]"
                        :star-size="33"
                      />
                      <button
                        class="star-button"
                        @click="showModal(activity, index)"
                        :disabled="rating[index] === 0"
                      >
                        <span class="star-button-title"> Salvar </span>
                      </button>
                    </div>
                  </div>

                  <div v-else class="mt-4 mb-3">
                    <div
                      class="d-flex justify-content-center align-items-center star-title mb-2 flex-column"
                    >
                      <p>{{ $t("alreadyClassified") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>{{ $t("noPhotoFound") }}</div>
            </li>

            <Modal class="modal-confirmacao" v-show="modalAvaliar" :width="500">
              <template v-slot:header>
                <span v-if="indexValue < 4" class="titulo-justificativa"
                  >Justificativa da Avaliação:</span
                >
                <span v-else class="titulo-justificativa"
                  >Encaminhar avaliação?</span
                >
              </template>
              <template v-if="indexValue < 4" v-slot:body>
                <span class="subtitulo-justificativa"
                  >Digite abaixo uma justificativa para sua avaliação</span
                >
                <Textarea v-model="justificativa" class="justify" />
              </template>
              <template v-slot:footer>
                <span v-if="showAlert === true" class="alertModal"
                  >Digite corretamente a justificativa solicitada</span
                >
                <div class="btn-container">
                  <button
                    class="cancel-btn"
                    @click="(modalAvaliar = false), (showAlert = false)"
                  >
                    {{ $t("cancel") }}
                  </button>

                  <button class="save-btn" @click="avaliate">Confirmar</button>
                </div>
              </template>
            </Modal>
          </div>

          <!-- CARD SE FOR EDUCAÇÃO AMBIENTAL PESQUISA -->

          <div v-if="activity.atividade_id === 109">
            <li
              class="d-flex pl-3 pr-3 pt-3 align-items-center justify-content-between"
              :class="activity.active ? '' : 'list-item'"
            >
              <div class="d-flex align-items-center p-2">
                <img
                  :src="activity.atividade_icon"
                  class="mr-3"
                  width="27"
                  height="27"
                />
                <div>
                  <p class="activity-name mb-0">
                    {{ activity.atividade_nome }}
                  </p>
                  <span class="activity-date">{{ activity.data_inicio }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center p-1 position-relative">
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <img src="../assets/img/star.svg" width="14" height="14" />
                  <p class="star-legend mb-0">{{ activity.execucao_nota }}/5</p>
                </div>
              </div>
            </li>

            <li v-if="activity.active" class="list-item mt-2">
              <div v-if="activity.photos" class="w-100">
                <div
                  v-if="timelineView"
                  class="d-flex justify-content-center flex-column"
                >
                  <div
                    v-for="photo in activity.photos"
                    :key="photo.execucao_timeline_uuid"
                    class="pl-3 pt-2"
                  >
                    <div
                      v-for="(
                        entrevista, index
                      ) of photo.execucao_timeline_pesquisa"
                      :key="entrevista.execucao_timeline_pesquisa_uuid"
                    >
                      <div class="d-flex justify-content-start mt-2 mb-2 ml-1">
                        <div class="pr-1">
                          <div class="pinMarker mb-2">
                            <span class="pinCounter">{{ photo.counter }} </span>
                          </div>
                        </div>
                        <div>
                          <div class="photo-subtitle">
                            {{
                              entrevista.execucao_timeline_pesquisa_data
                                .split("T")[1]
                                .split(":")
                                .slice(0, 2)
                                .join(":")
                            }}
                          </div>
                          <div class="photo-subtitle-time">
                            {{
                              entrevista.execucao_timeline_pesquisa_latlng.x
                                ? entrevista.execucao_timeline_pesquisa_latlng
                                    .x || ""
                                : ""
                            }},
                            {{
                              entrevista.execucao_timeline_pesquisa_latlng.y
                                ? entrevista.execucao_timeline_pesquisa_latlng
                                    .y || ""
                                : ""
                            }}
                          </div>
                        </div>
                      </div>
                      <timeline
                        timeline-theme="#BDE6FF"
                        line-color="#BDE6FF"
                        class="ml-3"
                      >
                        <timeline-item bg-color="#BDE6FF" line-color="#BDE6FF">
                          <div
                            class="mt-2 pl-4 ml-2 d-flex pesquisa-info-container"
                          >
                            <span class="nome-pesquisa">
                              {{ entrevista.atividade_pesquisa_titulo }}</span
                            >
                            <div class="d-flex">
                              <img
                                v-if="
                                  entrevista.execucao_timeline_pesquisa_foto
                                "
                                class="item pointer img-avatar"
                                :src="
                                  entrevista.execucao_timeline_pesquisa_foto
                                "
                                width="64px"
                                height="64px"
                                alt="Foto da atividade"
                                @click="openModal(activity.photos, index)"
                              />
                              <div
                                class="nome-entrevistado-box justify-content-center align-items-center"
                              >
                                <span class="entrevistada">Entrevistado:</span>
                                <span class="nome-entrevistado ml-3">{{
                                  entrevista.execucao_timeline_pesquisa_nome
                                }}</span>
                              </div>
                            </div>
                            <div class="separador-perguntas">Perguntas</div>
                            <div class="pesquisa-info-container-child">
                              <div
                                v-for="pergunta of entrevista.execucao_timeline_pesquisa_resposta"
                                :key="
                                  pergunta.execucao_timeline_pesquisa_resposta_uuid
                                "
                                class="perguntas-container-card"
                              >
                                <spam class="pergunta">
                                  {{ pergunta.atividade_pesquisa_pergunta }}
                                </spam>
                                <spam class="resposta">
                                  {{
                                    pergunta.atividade_pesquisa_resposta
                                  }}</spam
                                >
                              </div>
                            </div>
                          </div>
                        </timeline-item>
                      </timeline>
                    </div>
                  </div>
                </div>
                <div v-if="userType === 3">
                  <div v-if="!activity.execucao_nota" class="mt-4 mb-3">
                    <div
                      class="d-flex justify-content-center align-items-center star-title mb-2 flex-column"
                    >
                      <p>{{ $t("activityClassification") }}</p>
                      <star-rating
                        active-color="#F2B836"
                        inactive-color="#C4C4C4"
                        v-model="rating[index]"
                        :star-size="33"
                      />
                      <p class="star-counter mt-3">{{ rating[index] }}/5</p>
                      <button
                        class="star-button"
                        @click="showModal(activity, index)"
                        :disabled="rating[index] === 0"
                      >
                        <span class="star-button-title">{{
                          $t("classify")
                        }}</span>
                      </button>
                    </div>
                  </div>

                  <div v-else class="mt-4 mb-3">
                    <div
                      class="d-flex justify-content-center align-items-center star-title mb-2 flex-column"
                    >
                      <p>{{ $t("alreadyClassified") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>{{ $t("noPhotoFound") }}</div>
            </li>

            <Modal class="modal-confirmacao" v-show="modalAvaliar" :width="500">
              <template v-slot:header>
                <span v-if="indexValue < 4" class="titulo-justificativa"
                  >Justificativa da Avaliação:</span
                >
                <span v-else class="titulo-justificativa"
                  >Encaminhar avaliação?</span
                >
              </template>
              <template v-if="indexValue < 4" v-slot:body>
                <span class="subtitulo-justificativa"
                  >Digite abaixo uma justificativa para sua avaliação</span
                >
                <Textarea v-model="justificativa" class="justify" />
              </template>
              <template v-slot:footer>
                <span v-if="showAlert === true" class="alertModal"
                  >Digite corretamente a justificativa solicitada</span
                >
                <div class="btn-container">
                  <button
                    class="cancel-btn"
                    @click="(modalAvaliar = false), (showAlert = false)"
                  >
                    {{ $t("cancel") }}
                  </button>

                  <button class="save-btn" @click="avaliate">Confirmar</button>
                </div>
              </template>
            </Modal>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import { Timeline, TimelineItem } from "vue-cute-timeline";
import "vue-cute-timeline/dist/index.css";
import { formatMilisecondsToTime } from "../utils/date";
import Modal from "../components/Modal.vue";

import services from "../services";

import CheckboxFilter from "../components/CheckboxFilter.vue";
import Textarea from "./form/Textarea.vue";

export default {
  components: {
    StarRating,
    Timeline,
    TimelineItem,
    CheckboxFilter,
    Modal,
    Textarea,
  },
  name: "ActivitiesPhotoList",
  data() {
    return {
      justificativa: null,
      modalAvaliar: false,
      item: [],
      index: null,
      executions: this.activitiesData,
      rating: new Array(this.activitiesData.length).fill(0),
      timelineView: true,
      userType: null,
      filterModal: false,
      activityId: 0,
      actualTags: [],
      executionId: null,
      activityValue: null,
      uuid: null,
      indexValue: null,
      showAlert: false,
      formatMilisecondsToTime,
    };
  },
  props: {
    activitiesData: {
      type: Array,
      required: true,
    },
    activitiesActiveCount: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.rating = this.activitiesData.map(() => 0);
    this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
  },
  methods: {
    showAllPhotos(id) {
      this.activitiesData.forEach((item) => {
        if (item.execucao_uuid === id) {
          item.active ? (item.active = false) : (item.active = true);
          if (item.active) {
            this.$emit("show-photos", id);
          } else {
            this.$emit("hide-photos", id);
          }
        }
      });
    },
    showFilterTag() {
      if (this.activitiesActiveCount !== 1) {
        this.filterModal = false;
        return;
      }
      this.actualTags = [];
      this.activitiesData.forEach((activity) => {
        activity.photos.forEach((photo) => {
          photo.tags.forEach((tag) => {
            if (
              this.actualTags.findIndex(
                (item) => item.tag_id === tag.tag_id
              ) === -1
            )
              this.actualTags.push({
                ...tag,
                active: false,
              });
          });
        });
      });

      this.$store.state.map.stateTags.forEach((item) => {
        this.actualTags.forEach((tag) => {
          if (item === tag.tag_id) {
            tag.active = true;
          }
        });
      });
      this.filterModal = !this.filterModal;
    },
    filterTag(tags) {
      tags.forEach((tag) => {
        if (tag.active) {
          this.$store.commit("map/addTags", tag.tag_id);
        } else {
          this.$store.commit("map/removeTags", tag.tag_id);
        }
      });
      this.filterModal = !this.filterModal;
      this.$emit("filter-by-tag", tags);
    },
    openModal(photos, index) {
      this.item = [];
      photos.forEach((photo) => {
        if (photo.atividade_nome === "Educação Ambiental - Pesquisa") {
          photo.execucao_timeline_pesquisa.forEach((thing) => {
            if (thing.execucao_timeline_pesquisa_foto) {
              this.item.push({
                src: thing.execucao_timeline_pesquisa_foto,
                title: thing.atividade_pesquisa_titulo,
                description: thing.execucao_timeline_pesquisa_nome,
              });
            }
          });
        } else {
          this.item.push({
            src: photo.execucao_timeline_foto,
            title: photo.atividade_nome,
            description:
              photo.tags[0].tag_descricao +
              " | " +
              photo.execucao_timeline_data
                .split("T")[1]
                .split(":")
                .slice(0, 2)
                .join(":"),
          });
        }
      });
      this.index = index;
    },

    openModalByPinClick(pin) {
      this.executions.forEach((execution) => {
        if (execution.photos) {
          execution.photos.forEach((photo) => {
            if (photo.execucao_timeline_uuid == pin.atividade_id) {
              this.item = [];
              if (photo.atividade_nome === "Educação Ambiental - Pesquisa") {
                photo.execucao_timeline_pesquisa.forEach((thing) => {
                  if (thing.execucao_timeline_pesquisa_foto) {
                    this.item.push({
                      src: thing.execucao_timeline_pesquisa_foto,
                      title: thing.atividade_pesquisa_titulo,
                      description: thing.execucao_timeline_pesquisa_nome,
                    });
                  }
                });
              } else {
                this.item.push({
                  src: photo.execucao_timeline_foto,
                  title: photo.atividade_nome,
                  description:
                    photo.tags[0].tag_descricao +
                    " | " +
                    photo.execucao_timeline_data
                      .split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":"),
                });
              }
              this.index = 0;
            }
          });
        }
      });
    },
    async avaliate() {
      if (this.indexValue < 4 && this.justificativa == null) {
        this.showAlert = true;
        this.justificativa = null;
        return;
      }

      const { errors } = await services.serviceOrder.putAvaliacao({
        execucao_uuid: this.activityValue,
        execucao_nota: this.indexValue,
        ordem_servico_log_info: this.justificativa ? this.justificativa : null,
      });
      if (!errors) {
        this.executions.forEach((item) => {
          if (item.execucao_uuid === this.activityValue) {
            item.execucao_nota = this.indexValue;
          }
        });

        this.modalAvaliar = false;
        this.justificativa = null;
      }
    },
    async showModal(activity, index) {
      if (this.rating[index] === 0) {
        return;
      }
      this.modalAvaliar = true;
      this.activityValue = activity.execucao_uuid;
      this.indexValue = this.rating[index];
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 3px;
  width: 100%;
  justify-content: start;
}

.item {
  margin: 5px;
  border-radius: 16px;
}

.list-item {
  border-bottom: 1px solid #e4e4e4;
}

.list-container {
  box-shadow: -2px 20px 18px 4px rgba(160, 168, 189, 0.24);
  border-radius: 16px;
}

.subtitle {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #565e73;
}

.subtitle-section {
  font-family: "OpenSans-SemiBold";
  font-size: 13px;
}

.activity-name {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #404960;
  text-transform: uppercase;
}

.activity-date {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.006em;
  color: #565e73;
}

.star-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
}

.photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin: 16px;
  margin-right: 20px;

  .photo-container-loop {
    width: 48%;
  }

  .photo-container-item {
    flex-grow: 1;
    width: 100%;
  }

  img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
}

.activities-container {
  margin-left: 24px;
}

.activity-subtitle {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #565e73;
  margin-bottom: 4px;
}

.activity-counter {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #565e73;
}

.photo-subtitle {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #565e73;
  margin-left: -3px;
  text-transform: uppercase;
}

.photo-subtitle-time {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: -0.006em;
  color: #838998;
  margin-left: -3px;
}

.filters {
  background-color: #003366;
}

.filter-disabled {
  background-color: #e2f4ff;
  cursor: not-allowed;
}

.pinMarker {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50% 50% 0 50%;
  background: #003063;
  transform: rotate(45deg);
  right: 4px;
}

.pinCounter {
  position: absolute;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #ffffff;
  width: 100%;
  left: 0;
  right: 0;
  top: 8px;
  transform: rotate(-45deg);
}

.star-counter {
  font-family: "OpenSansCondensed-Bold";
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #003067;
}

.star-button {
  background: none;
  color: #fff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 71px;
  height: 34px;
  background: #003366;
  border-radius: 6px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 16px;

  &:disabled {
    background: #cddee8;
    cursor: not-allowed;
  }
}

.star-legend {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #838998;
  text-align: center;
}

.star-button-title {
  font-family: "OpenSans-Regular";
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 30px;
  width: 220px;
}

.alertModal {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: red;
  margin: -12px 0px 12px 2px;
}

.btn-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.save-btn {
  margin: 12px 0px 12px 12px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: #003366;
  padding: 8px;
  border-radius: 8px;
  color: white;
  border: none;
  outline: none;

  &:hover {
    background: #003366;
  }
}

.cancel-btn {
  margin: 12px 12px 12px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  background: white;
  padding: 8px;
  color: #838998;
  border: none;
  outline: none;

  &:hover {
    background: #e2f4ff;
  }
}

.titulo-justificativa {
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #29334e;
  text-align: center;
}

.subtitulo-justificativa {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
  margin-bottom: 24px;
}

.nome-pesquisa {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  margin: 24px 0px;
  color: #131e3b;
}

.pesquisa-info-container {
  display: flex;
  flex-direction: column;
}

.pesquisa-info-container-child {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 200px;
}
.nome-entrevistado {
  color: #565e73;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 13px;
}
.entrevistada {
  color: #131e3b;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
}
.nome-entrevistado-box {
  display: flex;
  flex-direction: column;
}
.separador-perguntas {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  color: 131e3B;
  background: rgba(243, 244, 245, 0.4);
  margin-top: 24px;
}
.perguntas-container-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #f5f5f5;
  line-height: 156%;
  .pergunta {
    color: #565e73;
    font-family: "Montserrat";
    font-weight: bold;
    line-height: 156%;
    flex-wrap: wrap;
  }
  .resposta {
    color: #565e73;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 156%;
    flex-wrap: wrap;
  }
}
.img-avatar {
  margin-right: 22px;
}

.evaluation-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #131e3b;
}

.filter-enter-active {
  animation: filter-in 0.3s;
}
.filter-leave-active {
  animation: filter-in 0.3s reverse;
}
@keyframes filter-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
</style>

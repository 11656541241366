export default httpClient => ({
  getActivityCount: async () => {
    const response = await httpClient.get('/activity/count')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getActivities: async () => {
    const response = await httpClient.get('/activity/activities')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getActivitiesTag: async () => {
    const response = await httpClient.get('/activity-tag')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getAllActivities: async () => {
    const response = await httpClient.get('/activity')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getAllActivitiesNew: async (pagination) => {
    const response = await httpClient.post('/activity-data-table', pagination)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  editActivityName: async (data) => {
    const response = await httpClient.put('/activity', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  relateActivityWithTag: async (data) => {
    const response = await httpClient.post('/activity-tag', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  activityCountByNeighborhood: async (data) => {
    const response = await httpClient.post('/activity/count-by-neighborhood', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  activityByInstitution: async (data) => {
    const response = await httpClient.post('/activity/return-all', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getActivitiesReport: async (data) => {
    const response = await httpClient.post('/activity/report/activity/byInstitute', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  activityCountByKeepingSector: async (data) => {
    const response = await httpClient.post('/activity/count-by-keeping-sector', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
})

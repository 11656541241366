import Vue from 'vue'
import Vuex from 'vuex'

import coreUiModule from './modules/coreUiModule'
import globalModule from './modules/globalModule'
import mapModule from './modules/mapModule'
import cyclesModule from './modules/cyclesModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    coreUi: coreUiModule,
    global: globalModule,
    map: mapModule,
    cycles: cyclesModule,
  }
})

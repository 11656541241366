<template>
  <div class="mt-4 mb-4">
    <CRow class="position-relative">
      <CCol sm="12" class="mt-2">
        <div class="d-flex align-items-center justify-content-between">
          <p class="month-selector-label mt-2">Medição</p>
        </div>
      </CCol>
      <div class="mt-2 p-2 w-100">
        <div class="row flex-fill mb-4">
          <div class="d-flex justify-content-center col-3 offset-5 cycle-label">
            AVALIAÇÃO <br />
            MONITOR
          </div>
          <div class="d-flex justify-content-center col-3 cycle-label">
            AVALIAÇÃO <br />
            FISCAL
          </div>
        </div>
        <div
          v-for="cycle in getCycles"
          :key="cycle.execucao_avaliacao_id"
          class="cycle row flex-fill mt-3 d-flex align-items-center"
        >
          <div class="col-5 d-flex align-items-baseline justify-content-start">
            <div class="cycle-input d-flex align-items-center">
              <label
                class="cycle-date-label"
                :style="`${
                  checkIfDateIsFuture(cycle.execucao_avaliacao_inicio)
                    ? 'color: #CCCCCC !important'
                    : ''
                }`"
              >
                {{ cycle.execucao_avaliacao_inicio }} a
                {{ cycle.execucao_avaliacao_fim }}
              </label>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center col-3 mt-1"
          >
            <CSelect
              v-if="userType === 3"
              @change="handleChangePercentage"
              :value.sync="cycle.execucao_avaliacao_percentual_final"
              :disabled="monitorSelectDisabled(cycle)"
              :options="percentageOptions"
            />
            <div class="cycle-evaluation mr-2" v-else>
              {{ cycle.execucao_avaliacao_percentual_final }}%
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center col-3 mt-1"
          >
            <CSelect
              v-if="userType === 2"
              @change="handleChangePercentageSupervisor"
              :disabled="supervisorSelectDisabled(cycle)"
              :value.sync="cycle.execucao_avaliacao_prefeitura_percentual_final"
              :options="percentageOptions"
            />
            <div class="cycle-evaluation" v-else>
              {{ cycle.execucao_avaliacao_prefeitura_percentual_final }}%
            </div>
          </div>
          <div class="d-flex justify-content-center col-1 mb-3">
            <div class="d-flex align-items-center">
              <div
                @click="
                  disableSaveButton(cycle) || disableButtons(cycle)
                    ? ''
                    : (saveMeasurementModal = true)
                "
              >
                <span
                  class="icon"
                  :class="`${
                    disableSaveButton(cycle) || disableButtons(cycle)
                      ? 'disabled'
                      : ''
                  }`"
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 9.5V5.5H2V9.5H1C0.867392 9.5 0.740215 9.44732 0.646447 9.35355C0.552678 9.25979 0.5 9.13261 0.5 9V1C0.5 0.867392 0.552678 0.740215 0.646447 0.646447C0.740215 0.552678 0.867392 0.5 1 0.5H7.5L9.5 2.5V9C9.5 9.13261 9.44732 9.25979 9.35355 9.35355C9.25979 9.44732 9.13261 9.5 9 9.5H8ZM7 9.5H3V6.5H7V9.5Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CRow>

    <Modal v-show="saveMeasurementModal" :width="400">
      <template v-slot:header>
        <div class="w-100 position-relative">
          <p class="contract-modal-title">Salvar medição</p>
        </div>
        <span
          @click="
            () => {
              saveMeasurementModal = false;
            }
          "
          class="material-icons icon-close"
          >close</span
        >
      </template>
      <template v-slot:body>
        <div class="modal-text">
          <p>Tem certeza que deseja avaliar?</p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            @clicked="saveMeasurement"
            size="small"
            class="w-50"
            name="Salvar"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import Modal from "../../components/Modal.vue";
import Button from "../../components/Button.vue";

import { mapGetters } from "vuex";

export default {
  props: ["keepingSectorId", "dates"],
  components: {
    Modal,
    Button,
  },
  computed: {
    ...mapGetters({
      getCurrentCycle: "cycles/getCurrentCycle",
      getCycles: "cycles/getCycles",
    }),
  },
  emits: ["send-dates"],
  data() {
    return {
      userType: null,
      finishMeasurementModal: false,
      saveMeasurementModal: false,
      actualDate: null,
      monthsSelectOptions: [
        { value: 1, label: "Janeiro" },
        { value: 2, label: "Fevereiro" },
        { value: 3, label: "Março" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Maio" },
        { value: 6, label: "Junho" },
        { value: 7, label: "Julho" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Setembro" },
        { value: 10, label: "Outubro" },
        { value: 11, label: "Novembro" },
        { value: 12, label: "Dezembro" },
      ],
      percentageOptions: [
        { value: 0, label: "0%" },
        { value: 25, label: "25%" },
        { value: 50, label: "50%" },
        { value: 75, label: "75%" },
        { value: 100, label: "100%" },
      ],
      monitorPercentage: null,
      supervisorPercentage: null,
      range: {
        start: null,
        end: null,
      },
    };
  },

  async created() {
    // await this.requestCycles();
    await this.filterMonthOnMount();
    this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
    this.selectCurrentCycle(this.getCycles[0]);
  },

  watch: {
    dates: {
      async handler(oldVal, newVal) {
        this.actualDate = newVal[0];
        // if (this.actualDate === newVal[0]) return;
        await this.changeSidebarFiltersCallback(newVal);
      },
    },
  },

  methods: {
    async filterMonthOnMount() {
      this.range.start = this.dates[0];
      this.range.end = this.dates[1];
      await this.requestCycles();
    },

    isChecked(cycle) {
      const [startDateDay, startDateMonth, startDateYear] =
        cycle.execucao_avaliacao_inicio.split("/");

      let cycleDate = moment({
        y: startDateYear,
        M: startDateMonth - 1,
        d: startDateDay,
      }).toISOString();

      return (
        (cycle.execucao_avaliacao_id === this.range.start ||
          this.dates[0] === cycleDate) &&
        cycle.execucao_avaliacao_id ===
          this.getCurrentCycle.execucao_avaliacao_id
      );
    },

    disableMonitorByArea() {
      if (this.userType === 4) {
        return true;
      }
    },

    async changeSidebarFiltersCallback(dates) {
      this.range.start = dates[0];
      this.range.end = dates[1];
      await this.requestCycles();
    },

    checkIfDateIsFuture(date) {
      const [day, month, year] = date.split("/");
      const today = new Date();
      const recievedDate = new Date(+year, +month - 1, +day);

      return recievedDate > today;
    },

    handleChangeWeelCycle(cycle) {
      const [startDateDay, startDateMonth, startDateYear] =
        cycle.execucao_avaliacao_inicio.split("/");
      const [endDateDay, endDateMonth, endDateYear] =
        cycle.execucao_avaliacao_fim.split("/");

      this.range.start = moment({
        y: startDateYear,
        M: startDateMonth - 1,
        d: startDateDay,
      }).toISOString();
      this.range.end = moment({
        y: endDateYear,
        M: endDateMonth - 1,
        d: endDateDay,
      }).toISOString();

      // this.$emit("send-dates", this.range);
    },

    disableButtons(cycle) {
      const today = new Date();
      const [dayStart, monthStart, yearStart] =
        cycle.execucao_avaliacao_inicio.split("/");
      const [dayEnd, monthEnd, yearEnd] =
        cycle.execucao_avaliacao_fim.split("/");

      // apos o vencimento do ciclo, monitor tem 2 dias pra salvar a avaliação, enquanto o fiscal tem 7
      let finalDate = null;
      if (this.userType === 3) {
        finalDate = new Date(+yearEnd, +monthEnd, 4, "23", "59", "59");
      } else if (this.userType === 2) {
        finalDate = new Date(+yearEnd, +monthEnd, 7, "23", "59", "59");
      } else {
        finalDate = new Date(+yearEnd, +monthEnd, +dayEnd, "23", "59", "59");
      }

      const startDate = new Date(+yearStart, +monthStart - 1, +dayStart);

      if (this.userType === 3) {
        return (
          cycle.execucao_avaliacao_id !==
            this.getCurrentCycle.execucao_avaliacao_id ||
          startDate <= today >= finalDate
        );
      }

      if (this.userType === 2) {
        return (
          cycle.execucao_avaliacao_id !==
            this.getCurrentCycle.execucao_avaliacao_id ||
          startDate <= today >= finalDate
        );
      }

      if (this.userType === 1) {
        return true;
      }
    },

    disableSaveButton(cycle) {
      const today = new Date();
      const [day, month, year] = cycle.execucao_avaliacao_fim.split("/");

      // apos o vencimento do ciclo, monitor tem 2 dia pra salvar a avaliação, enquanto o fiscal tem 7
      let finalDate = null;
      if (this.userType === 3) {
        finalDate = new Date(+year, +month, 4, "23", "59", "59");
      } else if (this.userType === 2) {
        finalDate = new Date(+year, +month, 7, "23", "59", "59");
      } else {
        finalDate = new Date(+year, +month, +day, "23", "59", "59");
      }

      return (
        cycle.execucao_avaliacao_id !==
          this.getCurrentCycle.execucao_avaliacao_id || today > finalDate
      );
    },

    handleChangePercentage(ev) {
      this.$store.dispatch("cycles/selectCycle", {
        ...this.getCurrentCycle,
        execucao_avaliacao_percentual_final: ev.target.value,
      });
    },
    handleChangePercentageSupervisor(ev) {
      this.$store.dispatch("cycles/selectCycle", {
        ...this.getCurrentCycle,
        execucao_avaliacao_prefeitura_percentual_final: ev.target.value,
      });
    },

    async requestCycles() {
      await this.$store.dispatch("cycles/requestCycles", {
        keepingSectorId: this.keepingSectorId,
        range: this.range,
      });
    },

    selectCurrentCycle(selectedCycle) {
      const currentCycle = this.getCycles.find((cycle) => {
        return (
          cycle.execucao_avaliacao_id === selectedCycle.execucao_avaliacao_id
        );
      });
      this.$store.dispatch("cycles/selectCycle", currentCycle);
      this.handleChangeWeelCycle(selectedCycle);
    },

    async saveMeasurement() {
      await this.$store.dispatch("cycles/saveMeasurement", this.userType);
      this.saveMeasurementModal = false;
      await this.requestCycles();
    },

    async finishMeasurement() {
      await this.$store.dispatch("cycles/finishMeasurement", this.userType);
      this.finishMeasurementModal = false;
      await this.requestCycles();
    },

    monitorSelectDisabled(cycle) {
      return (
        cycle.execucao_avaliacao_id !==
          this.getCurrentCycle.execucao_avaliacao_id || this.userType === 2
      );
    },

    supervisorSelectDisabled(cycle) {
      return (
        cycle.execucao_avaliacao_id !==
          this.getCurrentCycle.execucao_avaliacao_id || this.userType === 3
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.month-selector-label {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

.cycle-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  color: #565e73;
  text-align: center;
}

.cycle-date-label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
  margin-bottom: 6px;
}

.cycle-evaluation {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #8eb257;
  margin-bottom: 16px;
}

.icon-close {
  position: absolute;
  right: 12px;
  top: 24px;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: initial;
}

.execution-evaluation {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #7b9e1e;
  margin-bottom: 13px;
}

.cycle {
  padding-left: 8px;

  .cycle-input {
    label {
      font-size: 0.8rem;
      margin-left: 7px;
    }
  }
}

.contract-modal-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
  text-align: center;
}

.icon {
  margin: 0 3px;
  background: #003366;
  color: white;
  border-radius: 50%;
  padding: 5px;
  width: 32px !important;
  height: 32px !important;
  display: inline-block;
  cursor: pointer;

  svg {
    width: 0.8rem !important;
    height: 0.8rem !important;
    margin-left: 4px;
    vertical-align: middle;
  }
}

.modal-text {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
  text-align: center;
}

.icon.disabled {
  background: #e0e0e0;
  color: #9e9e9e;
  cursor: initial;
}
</style>

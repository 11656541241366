<template>
  <div class="users">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">Ordens de serviço</span>
    </h3>

    <div class="d-flex justify-content-between pl-3 mb-3">
      <div class="w-25">
        <Input
          v-model="searchString"
          :placeholder="$t('searchSo')"
          :search="true"
        />
      </div>
      <div v-if="this.userType === 2 || this.userType === 3" class="pr-3">
        <Button
          @clicked="$router.push({ name: 'novaOrdemDeServico' })"
          class=""
          size="small"
          :name="$t('Request')"
        ></Button>
      </div>
    </div>

    <transition name="bounce">
      <div v-if="!loadingData" class="pl-1">
        <Table
          style="text-align: center; align-items: center"
          :fields="fields"
          :hasActions="true"
          @sort="handleSort"
        >
          <TableRow v-for="item in orders" :key="item.ordem_servico_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>

              <template v-slot:ordem_servico_status_descricao>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Em Criação'"
                  class="em-criacao"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Revisar'"
                  class="revisar"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Finalizada'"
                  class="finalizada"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Cancelada'"
                  class="revisar"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Em Andamento'"
                  class="andamento"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Revisado'"
                  class="andamento"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Em Análise'"
                  class="andamento"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Avaliar'"
                  class="finalizada"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Re-Execução'"
                  class="andamento"
                >
                  {{ item.ordem_servico_status_descricao }}
                </span>
              </template>
            </TableCell>

            <TableCell>
              <!-- Se o admin acessar a página -->
              <div v-if="userType === 1" class="buttons">
                <span
                  v-if="item.ordem_servico_status_descricao === 'Em Criação'"
                  title="Ações"
                  ><a
                    @click="
                      $router.push(
                        `/editar-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/edit.png"
                      alt="img" /></a
                ></span>
                <span
                  v-if="
                    item.ordem_servico_status_descricao === 'Em Análise' ||
                    item.ordem_servico_status_descricao === 'Revisado' ||
                    item.ordem_servico_status_descricao === 'Revisar'
                  "
                  title="Ações"
                  ><a
                    @click="
                      $router.push(
                        `/analise-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/edit.png"
                      alt="img" /></a
                ></span>
                <span v-else title="Ações"
                  ><a
                    @click="
                      $router.push(
                        `/analise-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/visualizar.png"
                      alt="img" /></a
                ></span>
              </div>
              <!-- Se o monitor acessar a página -->
              <div v-if="userType === 3" class="buttons">
                <span
                  v-if="item.ordem_servico_status_descricao === 'Em Criação'"
                  title="Detalhes"
                  ><a
                    @click="
                      $router.push(
                        `/editar-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/edit.png"
                      alt="img" /></a
                ></span>
                <span
                  v-if="item.ordem_servico_status_descricao === 'Avaliar'"
                  title="Detalhes"
                  ><a
                    @click="
                      $router.push(
                        `/avaliar-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/edit.png"
                      alt="img" /></a
                ></span>
                <span
                  v-if="
                    item.ordem_servico_status_descricao !== 'Avaliar' &&
                    item.ordem_servico_status_descricao !== 'Em Criação'
                  "
                  title="Ações"
                  ><a
                    @click="
                      $router.push(
                        `/avaliar-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/visualizar.png"
                      alt="img" /></a
                ></span>
              </div>
              <!-- Se o fiscal acessar a página -->
              <div v-if="userType === 2" class="buttons">
                <span
                  v-if="item.ordem_servico_status_descricao === 'Revisar'"
                  title="Ações"
                  ><a
                    @click="
                      $router.push(
                        `/editar-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/edit.png"
                      alt="img" /></a
                ></span>
                <span v-else title="Ações"
                  ><a
                    @click="
                      $router.push(
                        `/editar-ordem-de-servico/${item.ordem_servico_id}`
                      )
                    "
                    ><img
                      class="link-icon"
                      src="../../assets/img/visualizar.png"
                      alt="img" /></a
                ></span>
              </div>
            </TableCell>
          </TableRow>

          <template v-if="orders.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />
  </div>
</template>

  <script>
import services from "../../services";
import { successToast } from "../../utils/toast";
import { debounce } from "../../utils/debounce";
import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import NoResults from "../../components/table/NoResults.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Input from "../../components/form/Input.vue";
import { formatDate } from "@fullcalendar/common";

export default {
  name: "Zeladores",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Button,
    BackArrow,
    Loading,
    Input,
  },
  data() {
    return {
      date: new Date(),
      fields: [
        {
          key: "ordem_servico_data_criacao",
          label: this.$t("date"),
          _style: "min-width:80px;",
          sortable: true,
          sortDirection: null,
        },
        {
          key: "atividade_nome",
          label: this.$t("activities"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ordem_servico_id",
          label: this.$t("OsId"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_gestor_nome",
          label: this.$t("Requester"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "bairro_nome",
          label: this.$t("neighborhood"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladoria_nome",
          label: this.$t("keepingSector2"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ordem_servico_status_descricao",
          label: this.$t("status"),
          _style: "min-width:80px;",
          sortable: true,
          sortDirection: null,
        },
      ],
      orders: [],
      removeModal: false,
      editModal: false,
      userNameForEdit: "",
      userEmailForEdit: "",
      userIdForEdit: 0,
      isUserActiveForEdit: 0,
      userIsActive: false,
      loadingData: true,
      userType: null,
      searchString: null,
      pagination: {
        page: 0,
        sortBy: "ordem_servico_data_criacao",
        filter: null,
        order: "DESC",
      },
    };
  },
  mounted: async function () {
    await this.getUserType();
    await this.getOrders(this.pagination);
    this.loadingData = false;
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.getOrders(this.pagination);
    }, 500),
  },
  methods: {
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getOrders(this.pagination);
    },
    async getUserType() {
      const user = this.$store.state.global.user;
      this.userType = user.usuario_gestor_tipo_id;
    },
    handleEditClick(item) {
      this.userIdForEdit = item.usuario_id;
      this.userNameForEdit = item.usuario_nome;
      this.userEmailForEdit = item.usuario_email;
      this.isUserActiveForEdit = item.usuario_ativo;
      this.isUserActiveForEdit == 1
        ? (this.userIsActive = true)
        : (this.userIsActive = false);
      this.editModal = true;
    },
    deleteKeepingSectorFromUser(item) {
      this.userIdForRemoveKeepingSector = item.usuario_id;
      this.removeModal = true;
    },
    async removeKeepingSector() {
      const { errors } = await services.users.removeKeepingSectorFromUser({
        usuario_id: this.userIdForRemoveKeepingSector,
      });
      if (!errors) {
        await this.getUsers(this.pagination),
          (this.removeModal = false),
          successToast(this.$t("successDeleteKeepingSector"));
      }
    },
    async getOrders() {
      const { data, errors } = await services.serviceOrder.getOrdersNew(
        this.pagination
      );
      if (!errors) {
        this.orders = data.map((item) => {
          return {
            atividade_id: item.atividade_id,
            atividade_nome: item.atividade_nome,
            bairro_id: item.bairro_id,
            bairro_nome: item.bairro_nome,
            ordem_servico_data_criacao: formatDate(
              item.ordem_servico_data_criacao
            ),
            ordem_servico_endereco: item.ordem_servico_endereco,
            ordem_servico_id: item.ordem_servico_id,
            ordem_servico_instrucao: item.ordem_servico_instrucao,
            ordem_servico_status_descricao: item.ordem_servico_status_descricao,
            ordem_servico_status_id: item.ordem_servico_status_id,
            usuario_gestor_id: item.usuario_gestor_id,
            usuario_gestor_nome: item.usuario_gestor_nome,
            zeladoria_id: item.zeladoria_id,
            zeladoria_nome: item.zeladoria_nome,
          };
        });
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.serviceOrder.getOrdersNew(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item) => {
            return {
              ...item,
              ordem_servico_data_criacao: formatDate(
                item.ordem_servico_data_criacao
              ),
            };
          });
          temp.forEach((item) => {
            this.orders.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.em-criacao {
  padding: 4px 16px;
  background: #dddee3;
  border-radius: 5px;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.cancelada {
  padding: 4px 16px;
  background: #db5946;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.revisar {
  padding: 4px 16px;
  background: #db5946;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.finalizada {
  padding: 4px 16px;
  background: #9ac625;

  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.andamento {
  padding: 4px 16px;
  background: #eda209;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
.header-wrapper {
  width: 100% !important;
  justify-content: center !important;
}
.link-icon {
  cursor: pointer;
  width: 22px;
  margin-top: 50%;
}
.buttons {
  display: flex;
  justify-content: center;
  max-width: 80px;
}
.users {
  background-color: #fff;
  border-radius: 8px;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.remove-modal-title {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #003366;
}

.remove-modal-subtitle {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #9d9d9d;
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}
</style>

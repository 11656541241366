<template>
  <div>
    <div v-if="!loading" class="details-wrapper">
      <h3 class="intern-title">
        <div>
          <back-arrow />
          <span class="page-nav">{{ candidate.usuario_nome }}</span>
        </div>
        <span class="user-status-badge">{{
          candidate.usuario_status_descricao
        }}</span>
      </h3>
      <div style="display: flex; align-items: center">
        <div :key="candidate.usuario_foto" class="photo">
          <img
            @error="candidate.usuario_foto = null"
            v-if="candidate.usuario_foto"
            :src="candidate.usuario_foto"
            alt=""
          />
          <div class="no-photo" v-else>
            <svg
              width="48"
              height="63"
              viewBox="0 0 48 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 63C0 56.6348 2.52856 50.5303 7.02944 46.0294C11.5303 41.5286 17.6348 39 24 39C30.3652 39 36.4697 41.5286 40.9706 46.0294C45.4714 50.5303 48 56.6348 48 63H42C42 58.2261 40.1036 53.6477 36.7279 50.2721C33.3523 46.8964 28.7739 45 24 45C19.2261 45 14.6477 46.8964 11.2721 50.2721C7.89642 53.6477 6 58.2261 6 63H0ZM24 36C14.055 36 6 27.945 6 18C6 8.055 14.055 0 24 0C33.945 0 42 8.055 42 18C42 27.945 33.945 36 24 36ZM24 30C30.63 30 36 24.63 36 18C36 11.37 30.63 6 24 6C17.37 6 12 11.37 12 18C12 24.63 17.37 30 24 30Z"
                fill="#565E73"
              />
            </svg>
          </div>
        </div>
        <div class="user-card">
          <div class="title">Dados cadastrais</div>
          <div class="user-info">
            <div class="info">
              <div class="info-group">
                <div
                  v-if="candidate.usuario_status_id !== 102"
                  class="info city"
                >
                  <p>
                    <b> {{ $t("city") }}</b> :
                    {{ candidate.cidade_nome || "--" }}
                  </p>
                </div>
                <div
                  v-if="candidate.usuario_status_id !== 102"
                  class="info neighborhood"
                >
                  <p>
                    <b>{{ $t("neighborhood") }}</b> :
                    {{ candidate.bairro_nome || "--" }}
                  </p>
                </div>
              </div>
              <div class="separador-vertical">
                <div class="line"></div>
              </div>
              <div class="info-group">
                <div
                  v-if="candidate.usuario_status_id !== 102"
                  class="info keeping-sector"
                >
                  <p>
                    <b>{{ $t("keepingSector") }} :</b>
                    {{ candidate.zeladoria_nome || "--" }}
                  </p>
                </div>
                <div class="info email">
                  <p>
                    <b>{{ $t("email") }}</b> :
                    {{ candidate.usuario_email || "--" }}
                  </p>
                </div>
              </div>
              <div class="separador-vertical">
                <div class="line"></div>
              </div>
              <div class="info-group">
                <div class="info phone">
                  <p>
                    <b>{{ $t("phone") }}</b> :
                    {{ formatPhone(candidate.usuario_telefone) || "--" }}
                  </p>
                </div>
                <div class="info date">
                  <p>
                    <b>{{ $t("candidateDate") }}</b> :
                    {{ formatDate(candidate.usuario_data_criacao) || "--" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="candidate.usuario_status_id === 102"
        class="reproved-justification"
      >
        <h2 class="pagetitle">Justificativa de desclassificação</h2>
        <div class="justification-card">
          <div class="header">
            <!-- <div class="user">Monitor que desclassificaou</div> -->
            <div
              v-if="candidate.usuario_historico_desclassificacao_data"
              class="date"
            >
              {{
                formatDate(candidate.usuario_historico_desclassificacao_data)
              }}
              •
              {{
                formatTime(candidate.usuario_historico_desclassificacao_data)
              }}
            </div>
          </div>
          <div class="content">
            <div
              v-if="candidate.usuario_historico_desclassificacao_motivo"
              class="field"
            >
              Justificativa:
              {{ candidate.usuario_historico_desclassificacao_motivo }}
            </div>
            <div v-else class="field">Sem justificativa</div>
          </div>
        </div>
      </div>
      <div class="questionary">
        <h2 class="pagetitle">Questionário</h2>
        <table v-if="onboarding.length > 0">
          <thead>
            <th>PERGUNTA</th>
            <th>RESPOSTA</th>
          </thead>
          <tbody>
            <tr v-for="item in onboarding" :key="item.usuario_onboarding_id">
              <td>{{ item.usuario_onboarding_pergunta }}</td>
              <td>{{ item.usuario_onboarding_resposta }}</td>
            </tr>
          </tbody>
        </table>
        <NoResults v-else message="Nenhum questionário encontrado" />
      </div>
      <div v-if="candidate.usuario_status_id !== 102" class="user-status">
        <h2 class="pagetitle">Status do candidato</h2>
        <form class="user-status-form">
          <div v-if="candidate.usuario_status_id !== 101" class="status-field">
            <input v-model="status" :value="101" id="interviwed" type="radio" />
            <label for="interviwed">Triado</label>
          </div>
          <div v-if="candidate.usuario_status_id === 101" class="status-field">
            <input v-model="status" :value="105" id="interviwed" type="radio" />
            <label for="interviwed">Reserva de zeladoria</label>
          </div>
          <div v-if="candidate.usuario_status_id !== 100" class="status-field">
            <input v-model="status" :value="103" id="approved" type="radio" />
            <label for="approved">Zelador</label>
          </div>
          <div class="status-field">
            <input
              v-model="status"
              :value="102"
              id="desclassified"
              type="radio"
            />
            <label for="desclassified">Desclassificado</label>
          </div>
        </form>
        <div v-if="status === 103 || status === 105" class="mt-5 mb-5">
          <BoxInfo
            @close="closeBoxInfo"
            v-if="status == 103 && limiteData && boxInfo"
            title="Notifique o zelador"
            description="Já atingimos o período limite para início de atividades. Não se esqueça de notificar o zelador para iniciar suas atividades no dia primeiro."
          />
          <BoxInfo
            @close="closeBoxInfo"
            v-if="
              limiteEstourado && (status == 103 || status === 105) && boxInfo
            "
            title="Já atingimos o limite de 600 zeladores"
            description="Já atingimos o período limite para início de atividades. Não se esqueça de notificar o zelador para iniciar suas atividades no dia primeiro."
          />
          <BoxInfo
            @close="closeBoxInfo"
            v-if="
              candidate.usuario_status_id !== 105 && status === 105 && boxInfo
            "
            title="Notifique o candidato via Whatsapp com a mensagem abaixo:"
            :description="`Atenção: devido à intensa procura dos candidatos e urgência de preenchimento das vagas, o seu prazo para entrega de toda documentação e informações é até o dia ${formatDate(
              reservaLimite
            )}, às 16h30min. O não cumprimento do prazo implica em desclassificação.`"
          />
          <BoxInfo
            @close="closeBoxInfo"
            v-if="
              candidate.usuario_status_id == 101 &&
              status === 105 &&
              limiteEstourado
            "
            title="Já atingimos o limite de 600 zeladores"
            :description="`Ainda assim você pode adiantar o processo, preenchendo os campos abaixo. O candidato triado, permanecerá com esse status até que seja liberada uma vaga.`"
          />
          <h2 class="pagetitle">Preencha os campos abaixo</h2>
          <form class="association-form">
            <div class="field">
              <label
                for="social"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >Razão social
                <span v-if="status !== 105" style="color: red">*</span></label
              >
              <Input
                v-model="candidateSocial"
                name="social"
                placeholder="Razão social"
              />
            </div>
            <div class="field">
              <label
                for="cnpj"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >CNPJ
                <span v-if="status !== 105" style="color: red">*</span></label
              >
              <Input
                v-mask="'##.###.###/####-##'"
                v-model="candidateCNPJ"
                name="cnpj"
                placeholder="CNPJ"
              />
            </div>
            <div class="field">
              <label
                for="email"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >E-Mail <span style="color: red">*</span></label
              >
              <Input
                class="candidate-emal-input"
                v-model="candidateEmail"
                name="email"
                placeholder="E-Mail"
              />
            </div>
            <div class="field">
              <label
                style="
                  color: #747474;
                  font-size: 1rem;
                  display: inline-block;
                  margin-bottom: 0 !important;
                "
                >Associação <span style="color: red">*</span></label
              >
              <CSelect
                class="candidate-association"
                :value.sync="candidateAssociation"
                :options="associations"
              />
            </div>
            <div class="field">
              <label
                for="CEP"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >CEP <span style="color: red">*</span></label
              >
              <Input
                v-mask="'#####-###'"
                v-model="candidateCEP"
                name="CEP"
                placeholder="CEP"
              />
            </div>
            <div class="field">
              <label
                for="Endereco"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >Endereço <span style="color: red">*</span></label
              >
              <Input
                v-model="candidateEndereco"
                name="Endereço"
                placeholder="Endereço"
              />
            </div>
            <div class="field">
              <label
                for="Numero"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >Número <span style="color: red">*</span></label
              >
              <Input
                v-model="candidateNumero"
                name="Numero"
                placeholder="Numero"
              />
            </div>
            <div class="field">
              <label
                for="Complemento"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >Complemento</label
              >
              <Input
                v-model="candidateComplemento"
                name="Complemento"
                placeholder="Complemento"
              />
            </div>
            <div class="field">
              <label
                for="Bairro"
                style="color: #747474; font-size: 1rem; margin-bottom: 0"
                >Bairro <span style="color: red">*</span></label
              >
              <Input
                v-model="candidateBairro"
                name="Bairro"
                placeholder="Bairro"
              />
            </div>
          </form>
        </div>
        <Button
          class="save-button"
          :disabled="
            saving ||
            !canRecruit ||
            (status === 103 && candidateCNPJ.length < 18)
          "
          @clicked="handleModal(status)"
          :name="$t('save')"
        />
      </div>
    </div>
    <Loading v-else />
    <Modal class="modal-confirmacao" v-show="modalAlterarStatus" :width="500">
      <template v-slot:header>
        <span class="titulo">Confirmação de alteração de status</span>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          Tem certeza que deseja alterar o status de
          {{ candidate.usuario_nome }} de
          <span>{{ statusDictionary(candidate.usuario_status_id) }}</span> para
          <span>{{ statusDictionary(status) }}</span
          >?
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container">
          <button class="cancel-btn" @click="modalAlterarStatus = false">
            {{ $t("cancel") }}
          </button>

          <button class="save-btn" @click="setUserStatus">Confirmar</button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalOk" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0002 73.6666C16.7492 73.6666 0.333496 57.2509 0.333496 36.9999C0.333496 16.7489 16.7492 0.333252 37.0002 0.333252C57.2512 0.333252 73.6668 16.7489 73.6668 36.9999C73.6668 57.2509 57.2512 73.6666 37.0002 73.6666ZM33.3445 51.6666L59.2678 25.7396L54.0832 20.5549L33.3445 41.2973L22.9715 30.9243L17.7868 36.1089L33.3445 51.6666Z"
              fill="#80BE24"
            />
          </svg>
          <span class="titulo">Pronto !</span>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          O status de {{ candidate.usuario_nome }} foi alterado
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="handleConfirmation">
            Continuar
          </button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalErro" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40ZM20 17.172L14.344 11.514L11.514 14.344L17.172 20L11.514 25.656L14.344 28.486L20 22.828L25.656 28.486L28.486 25.656L22.828 20L28.486 14.344L25.656 11.514L20 17.172Z"
              fill="#FF002E"
            />
          </svg>
          <span class="titulo">Erro</span>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          {{ errorMsg }}
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="modalErro = false">Continuar</button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalWarning" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="75"
            height="74"
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.4997 73.6666C17.2487 73.6666 0.833008 57.2509 0.833008 36.9999C0.833008 16.7489 17.2487 0.333252 37.4997 0.333252C57.7507 0.333252 74.1663 16.7489 74.1663 36.9999C74.1663 57.2509 57.7507 73.6666 37.4997 73.6666ZM33.833 47.9999V55.3333H41.1663V47.9999H33.833ZM33.833 18.6666V40.6666H41.1663V18.6666H33.833Z"
              fill="#FFB800"
            />
          </svg>
          <span class="titulo"
            >Antes de prosseguir, notifique o candidato via Whatsapp com a
            mensagem abaixo:</span
          >
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          Atenção: devido à intensa procura dos candidatos e urgência de
          preenchimento das vagas, o seu prazo para entrega de toda documentação
          e informações é até o dia {{ formatDate(reservaLimite) }}, às
          16h30min. O não cumprimento do prazo implica em desclassificação.
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="handleConfirmation">
            Continuar
          </button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalWarningData" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="75"
            height="74"
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.4997 73.6666C17.2487 73.6666 0.833008 57.2509 0.833008 36.9999C0.833008 16.7489 17.2487 0.333252 37.4997 0.333252C57.7507 0.333252 74.1663 16.7489 74.1663 36.9999C74.1663 57.2509 57.7507 73.6666 37.4997 73.6666ZM33.833 47.9999V55.3333H41.1663V47.9999H33.833ZM33.833 18.6666V40.6666H41.1663V18.6666H33.833Z"
              fill="#FFB800"
            />
          </svg>
          <span class="titulo"
            >Estamos no período limite para início de atividades</span
          >
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          Não se esqueça de notificar o zelador para iniciar suas atividades no
          dia primeiro.
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="handleConfirmation">
            Continuar
          </button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalEspera" :width="500">
      <template v-slot:header>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
          "
        >
          <svg
            width="75"
            height="74"
            viewBox="0 0 75 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.4997 73.6666C17.2487 73.6666 0.833008 57.2509 0.833008 36.9999C0.833008 16.7489 17.2487 0.333252 37.4997 0.333252C57.7507 0.333252 74.1663 16.7489 74.1663 36.9999C74.1663 57.2509 57.7507 73.6666 37.4997 73.6666ZM33.833 47.9999V55.3333H41.1663V47.9999H33.833ZM33.833 18.6666V40.6666H41.1663V18.6666H33.833Z"
              fill="#FFB800"
            />
          </svg>
          <span class="titulo">Pronto! Os campos foram preenchidos.</span>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          Já atingimos o limite de 600 zeladores, porém o processo foi adiantado
          com o preenchimento dos campos. O candidato triado, permanecerá com
          esse status até que seja liberada uma vaga.
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <button class="save-btn" @click="handleConfirmation">
            Continuar
          </button>
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="modalDesclassificar" :width="500">
      <template v-slot:header>
        <span class="titulo">Desclassificar candidato</span>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          Antes de continuar o candidato, informe o motivo pelo qual você deseja
          desclassifica-lo.
        </div>
        <div style="margin: 24px 0">
          <Input
            v-model="reproveMessage"
            required
            placeholder="Informe o motivo"
          />
        </div>
        <div class="subtitle">
          Confirme digitando abaixo,
          <span style="color: #db5946">cancelar</span>
        </div>
        <div style="margin: 24px 0">
          <Input
            v-model="reproveConfirmation"
            placeholder="Digite a confirmação"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container" style="justify-content: center">
          <Button
            :name="$t('cancel')"
            class="cancel-btn"
            @clicked="
              modalDesclassificar = false;
              reproveConfirmation = null;
              reproveMessage = null;
            "
          />

          <Button
            name="Continuar"
            :disabled="reproveConfirmation !== 'cancelar' || !reproveMessage"
            :class="`reprove-btn ${
              reproveConfirmation !== 'cancelar' || !reproveMessage
                ? 'disabled'
                : ''
            }`"
            @clicked="setUserStatus"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import "moment-business-days";
import Button from "../../components/Button.vue";
import BackArrow from "../../components/BackArrow.vue";
import BoxInfo from "../../components/BoxInformation.vue";
import services from "../../services";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Input from "../../components/form/Input.vue";
import { formatDate } from "../../utils/date";
import { formatPhone } from "../../utils/phone";
import Modal from "../../components/Modal.vue";

export default {
  components: {
    BackArrow,
    Button,
    NoResults,
    Loading,
    BoxInfo,
    Input,
    Modal,
  },

  watch: {
    status() {
      this.boxInfo = true;
    },
  },
  async created() {
    this.loading = true;
    const onboarding = await services.users.getUserOnboarding(
      this.$route.params.id
    );
    const user = await services.candidates.getCandidateById(
      this.$route.params.id
    );
    const associations = await services.subcontractors.getSubcontractors();

    const compareDate = moment();
    const startDate = moment().date(22);
    const endDate = moment().endOf("month");

    this.limiteData = compareDate.isBetween(startDate, endDate);

    this.candidate = user.data;
    if (this.candidate) {
      this.candidateBairro = user.data.usuario_bairro;
      this.candidateCEP = user.data.usuario_cep;
      this.candidateComplemento = user.data.usuario_complemento;
      this.candidateEmail = user.data.usuario_email;
      this.candidateNumero = user.data.usuario_numero;
      this.candidateEndereco = user.data.usuario_endereco;
      this.candidateSocial = user.data.usuario_nome;
    }
    this.onboarding = onboarding.data.data;
    this.status = user.data.usuario_status_id;
    this.associations = associations.data.map((item) => {
      return {
        label: item.subcontratada_descricao,
        value: item.subcontratada_id,
      };
    });

    this.associations.unshift({ label: "", value: null });

    this.numeroDiasLimite = await services.config.getConfigById(302);
    this.reservaLimite = moment().businessAdd(
      +this.numeroDiasLimite.data.config_valor
    );

    await this.loadBigNumbers();
    this.limiteEstourado =
      +this.bigNumbers.total_zelador >= +this.bigNumbers.limite_zelador;
    this.loading = false;
  },
  data() {
    return {
      associations: [],
      candidate: {},
      candidateCNPJ: "",
      candidateSocial: "",
      candidateEmail: "",
      candidateAssociation: null,
      candidateCEP: null,
      candidateEndereco: null,
      candidateNumero: null,
      candidateBairro: null,
      candidateComplemento: null,
      modalAlterarStatus: false,
      modalOk: false,
      modalWarningData: false,
      modalEspera: false,
      limiteEstourado: false,
      modalDesclassificar: false,
      reproveMessage: null,
      reproveConfirmation: null,
      errorMsg: null,
      onboarding: [],
      boxInfo: true,
      limiteData: false,
      numeroDiasLimite: null,
      reservaLimite: null,
      bigNumbers: null,
      modalErro: false,
      modalWarning: false,
      status: null,
      loading: true,
      saving: false,
      formatDate,
      formatPhone,
    };
  },
  computed: {
    canRecruit: function () {
      if (this.status === 102 || this.status === 101) {
        return true;
      }
      if (this.status == 105) {
        return (
          this.candidateAssociation &&
          this.candidateEmail &&
          this.candidateCEP &&
          this.candidateBairro &&
          this.candidateEndereco &&
          this.candidateNumero
        );
      } else {
        return (
          this.candidateAssociation &&
          this.candidateCNPJ &&
          this.candidateEmail &&
          this.candidateSocial &&
          this.candidateCEP &&
          this.candidateBairro &&
          this.candidateEndereco &&
          this.candidateNumero
        );
      }
    },
  },
  methods: {
    async loadBigNumbers() {
      const response = await services.candidates.getBigNumbers();
      this.bigNumbers = response.data[0];
    },
    handleModal(status) {
      if (status === 102) {
        this.modalDesclassificar = true;
      } else {
        this.modalAlterarStatus = true;
      }
    },
    closeBoxInfo() {
      this.boxInfo = false;
    },
    statusDictionary(id) {
      if (id === 101) {
        return "triado";
      }
      if (id === 102) {
        return "desclassificado";
      }
      if (id === 103) {
        return "zelador";
      }
      if (id === 105) {
        return "reserva de zeladoria";
      }
    },
    async setUserStatus() {
      this.saving = true;
      if (this.status) {
        // Triado
        if (this.status === 101) {
          const { errors } = await services.candidates.approveInterviewUser(
            this.candidate.usuario_id
          );
          if (errors) {
            this.modalErro = true;
            this.errorMsg = errors.statusText;
          } else {
            this.modalOk = true;
          }
        }
      }
      // desclassificado
      if (this.status === 102) {
        const { errors } = await services.candidates.reproveUser({
          userId: this.candidate.usuario_id,
          justification: this.reproveMessage,
        });
        if (errors) {
          this.modalErro = true;
        } else {
          this.modalDesclassificar = false;
          this.modalOk = true;
        }
      }

      // aprovado
      if (this.status === 103) {
        const { errors } = await services.candidates.approveUser({
          userId: this.candidate.usuario_id,
          association: this.candidateAssociation,
          email: this.candidateEmail.toLowerCase(),
          cnpj: this.candidateCNPJ.replace(/[^a-zA-Z0-9 ]/g, ""),
          usuario_cep: this.candidateCEP.replace(/[^a-zA-Z0-9 ]/g, ""),
          usuario_endereco: this.candidateEndereco,
          usuario_numero: this.candidateNumero,
          usuario_complemento: this.candidateComplemento,
          usuario_bairro: this.candidateBairro,
          usuario_razao_social: this.candidateSocial,
        });
        if (errors) {
          this.modalErro = true;
          this.errorMsg = errors.statusText;
        } else {
          if (this.limiteEstourado) {
            this.modalEspera = true;
          } else {
            if (this.limiteData) {
              this.modalWarningData = true;
            } else {
              this.modalOk = true;
            }
          }
        }
      }
      // reserva
      if (this.status === 105) {
        const { errors } = await services.candidates.reserveUser({
          userId: this.candidate.usuario_id,
          association: this.candidateAssociation,
          email: this.candidateEmail.toLowerCase(),
          cnpj: this.candidateCNPJ.replace(/[^a-zA-Z0-9 ]/g, ""),
          usuario_cep: this.candidateCEP.replace(/[^a-zA-Z0-9 ]/g, ""),
          usuario_endereco: this.candidateEndereco,
          usuario_numero: this.candidateNumero,
          usuario_complemento: this.candidateComplemento,
          usuario_bairro: this.candidateBairro,
          usuario_razao_social: this.candidateSocial,
        });
        if (errors) {
          this.modalErro = true;
          this.errorMsg = errors.statusText;
        } else {
          this.modalWarning = true;
        }
      }
      this.saving = false;
      this.modalAlterarStatus = false;
    },

    handleConfirmation() {
      this.modalOk = false;
      this.modalWarning = false;
      this.modalWarningData = false;
      this.$router.push("/candidatos");
    },

    formatTime(time) {
      if (time) {
        return time.split("T")[1].split(":").slice(0, 2).join(":");
      } else return "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.photo {
  .no-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: #d9d9d9;
    border-radius: 50%;
  }
  img {
    width: 80px;
    height: 80px;
    background: #d9d9d9;
    border-radius: 50%;
  }
  padding-right: 32px;
}
.user-card {
  color: #29334e;
  width: 100%;
  border: 1px solid #b0b4bd;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;

  .separador-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    .line {
      width: 1px;
      background: #dddee3;
      height: 52px;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .title {
    width: 100%;
    padding: 12px 24px;
    background: #e4e4e48f;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    font-weight: bold;
    color: #131e3b;
  }
  .user-info {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 156%;
    width: 100%;
    padding: 24px;
    .info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 100px 1fr 100px 1fr;
      justify-items: center;
      font-size: 1rem;
      justify-items: center;
      .info-group {
        &:first-child {
          justify-self: start;
        }
        &:last-child {
          justify-self: end;
        }
      }
      span {
        margin-right: 5px;
      }
      p {
        margin: 0;
        b {
          text-transform: uppercase;
        }
      }
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }
    .city,
    .neighborhood,
    .keeping-sector,
    .email,
    .phone,
    .date {
      min-width: 300px;
      display: flex;
      text-align: left;
      flex: 1;
      padding: 8px 0;
    }
  }
}

.questionary {
  margin: 30px 0;
  h2 {
    margin: 32px 0;
  }
}

.divider {
  width: 1px;
  height: 80px;
  margin-left: 30px;
  margin-right: 20px;
  background: #b0b4bd;
}

.intern-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  .user-status-badge {
    display: block;
    padding: 2px 8px;
    font-weight: 600;
    margin-left: 32px;
    color: #131e3b;
    background: #f3f4f5;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
  }
}

.user-status {
  h2 {
    margin: 32px 0;
  }
  .user-status-form {
    color: #565e73;
    display: flex;
    gap: 34px;
    flex-wrap: wrap;
    .status-field {
      display: flex;
      align-items: baseline;
      gap: 10px;
      max-width: fit-content;
    }
  }
}
.reproved-justification {
  margin: 32px 0;
  .justification-card {
    margin-top: 32px;
    padding: 12px 24px;
    border: 1px solid #dddee3;
    border-radius: 16px;
    .header {
      display: flex;
      justify-content: space-between;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 24px;
      .user {
        color: #00b1eb;
        font-weight: bold;
      }
      .date {
        color: #131e3b;
      }
    }
    .content {
      .field {
        width: 100%;
        min-height: 46px;
        background: #fafbfb;
        color: #565e73;
        border: 1px solid #dddee3;
        border-radius: 8px;
        padding: 12px;
      }
    }
  }
}
table,
td,
th {
  border-top: 1px solid #f3f4f5;
}

th,
td {
  height: 33px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 12px;
  color: #565e73;
  padding: 24px;
  text-align: left;
  padding-left: 0;
}

th {
  background-color: #fff;
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  line-height: 156%;
  color: #565e73;
  user-select: none;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.association-form {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 24px;
  .field {
    min-width: 400px;
    flex: 1;
  }
}

.save-button {
  margin: 32px 0;
}
.modal-confirmacao {
  .titulo {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #131e3b;
  }
  .subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #565e73;
    span {
      color: #003366;
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .cancel-btn {
      margin: 12px 12px 12px 0px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      background: white;
      padding: 8px;
      color: #131e3b;
      border: none;
      outline: none;

      &:hover {
        background: #e2f4ff;
      }
    }
    .save-btn {
      margin: 12px 0px 12px 12px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background: #003366;
      padding: 8px;
      border-radius: 8px;
      color: white;
      border: none;
      outline: none;

      &:hover {
        background: #003366;
      }
    }
    .reprove-btn {
      margin: 12px 0px 12px 12px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background: #d3341d;
      padding: 8px;
      border-radius: 8px;
      color: white;
      border: none;
      outline: none;
      &.disabled {
        opacity: 0.6;
      }
    }
  }
}
</style>
<style>
.candidate-emal-input.input-wrapper > .input {
  text-transform: lowercase !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"d-flex align-items-center mb-4 mt-1"},[_c('svg',{staticClass:"arrow-icon pointer",attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){_vm.backModal = true}}},[_c('path',{attrs:{"d":"M3.828 7.00005H16V9.00005H3.828L9.192 14.364L7.778 15.778L0 8.00005L7.778 0.222046L9.192 1.63605L3.828 7.00005Z","fill":"#29334E"}})]),_c('span',{staticClass:"page-nav position-relative"},[(!_vm.research)?_c('span',[_vm._v("Nova pesquisa")]):_c('span',[_vm._v(" "+_vm._s(_vm.research.atividade_pesquisa_titulo))]),_c('div',{staticClass:"status-tag",class:_vm.tagStyle},[_vm._v(" "+_vm._s(_vm.status)+" ")])])]),_c('div',{staticClass:"steps"},[_c('div',{staticClass:"pointer",on:{"click":function($event){_vm.step = 1}}},[_c('span',{staticClass:"counter",class:_vm.step === 1
            ? 'counter-true'
            : _vm.step > 1
            ? 'counter-false'
            : 'counter-disabled'},[_vm._v("1")]),_c('span',{staticClass:"label",class:_vm.step === 1 || _vm.step > 1 ? 'label-true' : 'label-false'},[_vm._v("Definições")])]),_c('svg',{attrs:{"width":"8","height":"14","viewBox":"0 0 8 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.17217 6.99999L0.222168 2.04999L1.63617 0.635986L8.00017 6.99999L1.63617 13.364L0.222168 11.95L5.17217 6.99999Z","fill":"#B0B4BD"}})]),_c('div',{staticClass:"pointer",on:{"click":function($event){_vm.$route.params.id ? (_vm.step = 2) : ''}}},[_c('span',{staticClass:"counter",class:_vm.step === 2
            ? 'counter-true'
            : _vm.step > 2
            ? 'counter-false'
            : 'counter-disabled'},[_vm._v("2")]),_c('span',{staticClass:"label",class:_vm.step === 2 || _vm.step > 2 ? 'label-true' : 'label-false'},[_vm._v("Perguntas")])]),_c('svg',{attrs:{"width":"8","height":"14","viewBox":"0 0 8 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.17217 6.99999L0.222168 2.04999L1.63617 0.635986L8.00017 6.99999L1.63617 13.364L0.222168 11.95L5.17217 6.99999Z","fill":"#B0B4BD"}})]),_c('div',{staticClass:"pointer",on:{"click":function($event){_vm.$route.params.id ? (_vm.step = 3) : ''}}},[_c('span',{staticClass:"counter",class:_vm.step === 3
            ? 'counter-true'
            : _vm.step > 3
            ? 'counter-false'
            : 'counter-disabled'},[_vm._v("3")]),_c('span',{staticClass:"label",class:_vm.step === 3 || _vm.step > 3 ? 'label-true' : 'label-false'},[_vm._v("Local")])])]),_c('FormContainer',{attrs:{"step":_vm.step},on:{"cancel-form":_vm.cancelResearch,"next":function($event){_vm.step < 3 && _vm.step++},"save-draft":function($event){_vm.modalType = 0;
      _vm.openModal('draft');},"send-approval":function($event){_vm.modalType = 1;
      _vm.approve();}}}),_c('FinalModal',{attrs:{"open":_vm.confirmModal,"body":_vm.modalTypes[_vm.modalType]},on:{"close":_vm.closeFinalModal}}),_c('BackModal',{attrs:{"open":_vm.backModal},on:{"cancel":function($event){return _vm.cancelResearch()},"close":function($event){_vm.backModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
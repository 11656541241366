<template>
  <div class="researches">
    <div class="d-flex justify-content-between">
      <h3 class="ml-3 intern-title">
        <back-arrow />
        <span class="page-nav">Pesquisa com vizinhos</span>
      </h3>
      <Button
        class="mr-3 mt-1"
        size="small"
        tyoe="primary"
        name="Nova pesquisa"
        @clicked="redirectToNewResearch"
      />
    </div>

    <div class="ml-3 d-flex align-items-center mb-3">
      <div class="search-field mr-3">
        <Input placeholder="Busque uma pesquisa..." v-model="searchString" />
      </div>

      <div class="filter-btn" id="filter">
        <Transition name="filter">
          <FilterStatus
            class="filter-modal"
            v-if="filterStatus"
            :status="statusList"
            @close="filterStatus = false"
            @filter="filterByStatus"
          />
        </Transition>
        <svg
          @click="filterStatus ? (filterStatus = false) : (filterStatus = true)"
          class="mr-3 pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="16" fill="#003063" />
          <path
            d="M22 10.6665V11.9998H21.3333L17.3333 17.9998V22.6665H14.6667V17.9998L10.6667 11.9998H10V10.6665H22Z"
            fill="white"
          />
        </svg>
      </div>

      <div class="filter-btn" id="order">
        <Transition name="filter">
          <FilterOrder
            class="filter-modal"
            v-if="filterOrder"
            @close="filterOrder = false"
            @filter="filterByOrder"
          />
        </Transition>
        <svg
          @click="filterOrder ? (filterOrder = false) : (filterOrder = true)"
          class="mr-3 pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="16" fill="#003063" />
          <path
            d="M13.3333 10.6667H22V12H13.3333V10.6667ZM11.3333 10V12H12V12.6667H10V12H10.6667V10.6667H10V10H11.3333ZM10 17.3333V15.6667H11.3333V15.3333H10V14.6667H12V16.3333H10.6667V16.6667H12V17.3333H10ZM11.3333 21H10V20.3333H11.3333V20H10V19.3333H12V22H10V21.3333H11.3333V21ZM13.3333 15.3333H22V16.6667H13.3333V15.3333ZM13.3333 20H22V21.3333H13.3333V20Z"
            fill="white"
          />
        </svg>
      </div>

      <div class="filter-btn" id="calendar">
        <Transition name="filter">
          <v-date-picker
            class="filter-modal"
            v-if="calendarModal"
            v-model="range"
            is-range
          >
            <template v-slot:footer>
              <div class="d-flex flex-column pb-4 pr-3 pl-3">
                <Button
                  size="small"
                  class="mb-2"
                  name="Filtrar"
                  @clicked="filterByDate"
                />

                <Button
                  type="outline"
                  size="small"
                  name="Cancelar"
                  @clicked="calendarModal = false"
                />
              </div>
            </template>
          </v-date-picker>
        </Transition>

        <svg
          @click="
            calendarModal ? (calendarModal = false) : (calendarModal = true)
          "
          class="pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="16" fill="#003063" />
          <path
            d="M9.3335 15.3332H22.6668V21.3332C22.6668 21.51 22.5966 21.6796 22.4716 21.8046C22.3465 21.9296 22.177 21.9998 22.0002 21.9998H10.0002C9.82335 21.9998 9.65378 21.9296 9.52876 21.8046C9.40373 21.6796 9.3335 21.51 9.3335 21.3332V15.3332ZM19.3335 9.99984H22.0002C22.177 9.99984 22.3465 10.0701 22.4716 10.1951C22.5966 10.3201 22.6668 10.4897 22.6668 10.6665V13.9998H9.3335V10.6665C9.3335 10.4897 9.40373 10.3201 9.52876 10.1951C9.65378 10.0701 9.82335 9.99984 10.0002 9.99984H12.6668V8.6665H14.0002V9.99984H18.0002V8.6665H19.3335V9.99984Z"
            fill="white"
          />
        </svg>
      </div>
    </div>

    <span
      v-for="item of status"
      :key="'status' + item"
      class="ml-3 status-tag sketch"
    >
      {{
        statusList.find(
          (status) => status.atividade_pesquisa_status_id === item
        ).atividade_pesquisa_status_descricao
      }}
      <svg
        @click="removeStatus(item)"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.99999 4.05732L8.29999 0.757324L9.24266 1.69999L5.94266 4.99999L9.24266 8.29999L8.29999 9.24266L4.99999 5.94266L1.69999 9.24266L0.757324 8.29999L4.05732 4.99999L0.757324 1.69999L1.69999 0.757324L4.99999 4.05732Z"
          fill="#131E3B"
        />
      </svg>
    </span>

    <transition name="bounce">
      <div v-if="!loadingData">
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in researches" :key="item.instituicao_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
              <template v-slot:status>
                <div
                  class="status-tag"
                  :class="tagStyle(item.atividade_pesquisa_status_id)"
                >
                  {{ item[field.key] }}
                </div>
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch(item)"
              />
            </TableCell>
          </TableRow>
          <template v-if="researches.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
      </div>
    </transition>
    <CancelModal
      :open="cancelModal"
      @close="cancelModal = false"
      @cancel="
        getResearches();
        cancelModal = false;
      "
      :id="researchToCancel"
    />
    <Loading v-if="loadingData" />
  </div>
</template>

<script>
import services from "../../services";

import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import BackArrow from "../../components/BackArrow.vue";
import Input from "../../components/form/Input.vue";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import FilterOrder from "./components/FilterOrder.vue";
import FilterStatus from "./components/FilterStatus.vue";
import CancelModal from "./modal/CancelModal.vue";

import { debounce } from "../../utils/debounce";

export default {
  name: "Institutions",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Button,
    Loading,
    Input,
    BackArrow,
    CondensedDropdown,
    FilterOrder,
    FilterStatus,
    CancelModal,
  },
  data() {
    return {
      fields: [
        {
          key: "atividade_pesquisa_titulo",
          label: "Título",
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "validade",
          label: "Validade",
          _style: "min-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "status",
          label: "Status",
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      researches: [],
      statusList: [],
      loadingData: true,
      searchString: null,
      filterOrder: false,
      filterStatus: false,
      calendarModal: false,
      cancelModal: false,
      researchToCancel: null,
      order: "desc",
      status: [],
      range: {
        start: null,
        end: null,
      },
    };
  },
  mounted: async function () {
    await this.getResearches();
    await this.getAllStatus();
    this.loadingData = false;

    document.addEventListener("click", (e) => {
      if (!e.target.closest("#filter")) {
        this.filterStatus = false;
      }
      if (!e.target.closest("#order")) {
        this.filterOrder = false;
      }
      if (!e.target.closest("#calendar")) {
        this.calendarModal = false;
      }
    });
  },
  watch: {
    searchString: debounce(async function () {
      await this.getResearches();
    }, 500),
  },
  methods: {
    async filterByOrder(order) {
      this.order = order;
      await this.getResearches();
      this.filterOrder = false;
    },
    optionsByResearch(item) {
      if (item.atividade_pesquisa_status_id === 1) {
        return [
          { label: "Editar", value: "edit" },
          { label: "Excluir pesquisa", value: "delete" },
        ];
      } else if (item.atividade_pesquisa_status_id === 2) {
        return [
          { label: "Editar", value: "edit" },
          { label: "Excluir pesquisa", value: "delete" },
          { label: "Publicar", value: "publish" },
        ];
      } else if (item.atividade_pesquisa_status_id === 3) {
        return [
          { label: "Ver Resultados", value: "results" },
          { label: "Cancelar", value: "cancel" },
        ];
      } else if (
        item.atividade_pesquisa_status_id === 4 ||
        item.atividade_pesquisa_status_id === 5
      ) {
        return [{ label: "Ver Resultados", value: "results" }];
      }
    },
    async filterByDate() {
      await this.getResearches();
      this.calendarModal = false;
    },
    async filterByStatus(status) {
      this.status = status;
      await this.getResearches();
      this.filterStatus = false;
    },
    formatDate(date) {
      const dateArray = date.split("T")[0].split("-");
      return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    },
    redirectToNewResearch() {
      this.$router.push("/pesquisa");
    },
    tagStyle(tag) {
      if (tag === 1) return "sketch";
      if (tag === 2) return "waiting";
      if (tag === 3) return "published";
      if (tag === 4) return "finished";
      if (tag === 5) return "canceled";
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "results") {
        this.$router.push("/pesquisa/relatorio/" + item.atividade_pesquisa_id);
      }
      if (value === "edit") {
        this.$router.push({
          name: "PesquisaForm",
          params: { id: item.atividade_pesquisa_id },
        });
      }
      if (value === "delete") {
        const { errors } = await services.researches.removeResearch(
          item.atividade_pesquisa_id
        );
        if (!errors) {
          this.$toast.success("Pesquisa excluída com sucesso!", {
            position: "top",
            duration: 3000,
          });
          await this.getResearches();
        } else {
          this.$toast.error("Erro ao excluir pesquisa!", {
            position: "top",
            duration: 3000,
          });
        }
      }
      if (value === "cancel") {
        this.researchToCancel = item.atividade_pesquisa_id.toString();
        this.cancelModal = true;
      }
      if (value === "publish") {
        const { errors } = await services.researches.changeStatus(
          item.atividade_pesquisa_id,
          {
            atividade_pesquisa_status_id: 3,
          }
        );

        if (!errors) {
          this.$toast.success("Pesquisa publicada com sucesso!", {
            position: "top",
            duration: 3000,
          });
          await this.getResearches();
        } else {
          this.$toast.error("Erro ao publicar pesquisa!", {
            position: "top",
            duration: 3000,
          });
        }
      }
    },
    async getAllStatus() {
      const { data, errors } = await services.researches.getAllStatus();
      if (!errors) {
        this.statusList = data.map((item) => {
          return { ...item, checked: false };
        });
      }
    },
    removeStatus(status) {
      this.status = this.status.filter((item) => item !== status);
      this.getResearches();
    },
    async getResearches() {
      this.loadingData = true;
      const { data, errors } = await services.researches.getResearches({
        ordem: this.order,
        atividade_pesquisa_status_id: this.status,
        data_inicio: this.range.start
          ? this.range.start.toISOString().split("T")[0]
          : null,
        data_fim: this.range.end
          ? this.range.end.toISOString().split("T")[0]
          : null,
        atividade_pesquisa_titulo: this.searchString,
      });
      if (!errors) {
        this.researches = data.map((item) => {
          return {
            ...item,
            validade: `${this.formatDate(
              item.atividade_pesquisa_data_inicio
            )} - ${this.formatDate(item.atividade_pesquisa_data_fim)}`,
            status: item.atividade_pesquisa_status_descricao,
          };
        });
        this.loadingData = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icons-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.search-field {
  width: 400px;
}

.pointer {
  &:hover {
    opacity: 0.8;
  }
}

.filter-btn {
  position: relative;
}

.filter-modal {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 24px;
}

.status-tag {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  text-transform: uppercase;
  border-radius: 5px;
  width: fit-content;

  svg {
    margin-left: 4px;
    margin-bottom: 2px;
    cursor: pointer;
  }
}

.filter-enter-active {
  animation: filter-in 0.3s;
}
.filter-leave-active {
  animation: filter-in 0.3s reverse;
}
@keyframes filter-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
</style>

export function invertLatLng(coordinates) {
  let invertedCoordinates = [];
  coordinates.map((item) => {
    let arrayLatLng = [item["y"], item["x"]];
    invertedCoordinates.push(arrayLatLng);
  });
  return invertedCoordinates;
}

export function formatForGoogleMaps(coordinates) {
  let invertedCoordinates = [];
  coordinates.map((item) => {
    let arrayLatLng = { lat: item["y"], lng: item["x"] };
    invertedCoordinates.push(arrayLatLng);
  });
  return invertedCoordinates;
}

export function formatForGoogleMapsInverted(coordinates) {
  let invertedCoordinates = [];
  coordinates.map((item) => {
    let arrayLatLng = { lat: item["x"], lng: item["y"] };
    invertedCoordinates.push(arrayLatLng);
  });
  return invertedCoordinates;
}

export function invertLatLngOriginal(coordinates) {
  let invertedCoordinates = [];
  coordinates.map((item) => {
    let lng = parseFloat(item[1].toFixed(10));
    let lat = parseFloat(item[0].toFixed(10));
    let arrayLatLng = [lng, lat];
    invertedCoordinates.push(arrayLatLng);
  });
  return invertedCoordinates;
}

export function geoJsonToMaps(coordinates) {
  let invertedCoordinates = [];
  coordinates.map((item) => {
    let arrayLatLng = { lat: item[1], lng: item[0] };
    invertedCoordinates.push(arrayLatLng);
  });
  return invertedCoordinates;
}

// -----------------------------------------------FUNÇÕES DO AMANET-----------------------------------------------

// export function convertGeoArrayToGoogleMaps(array) {
//   let coordinates = []

//   array.map(item => {
//     coordinates.push({ lat: item['y'], lng: item['x'] })
//   })

//   return coordinates
// }


export function convertGeoStringToGoogleMaps(string) {
  const data = JSON.parse(string)

  if (data.type === "MultiPolygon") {
    const coordinates = data.coordinates;
    const mappedCoordinates = coordinates.map(polygon => {
      return polygon[0].map(coord => {
        return {
          lat: coord[1],
          lng: coord[0]
        }
      })

    });
    return mappedCoordinates
  } else {
    const coordinates = data.coordinates[0];
    return coordinates.map(coord => {
      return {
        lat: coord[1],
        lng: coord[0]
      }
    });
  }
}

<template>
  <td class="table-cell">
    <slot v-if="name" :name="name"></slot>
    <slot v-else></slot>
  </td>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.table-cell {
  text-align: center;
  border-top-color: #e4e4e4 !important;
  border-bottom-color: #e4e4e4 !important;
  padding: 12px !important;
}
</style>

<template>
  <div class="policies">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("terms") }}</span>
    </h3>
    <transition name="bounce">
      <div v-if="!loadingData">
        <div>
          <Button
            v-if="policies.length === 0"
            class="ml-2"
            :name="$t('newVersion')"
            @click="onClickAddPolicy()"
          />
          <Table :fields="fields" :hasActions="true">
            <TableRow v-for="item in policies" :key="item.contrato_id">
              <TableCell
                v-for="field in fields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  <span class="table-row-text">{{ item[field.key] }}</span>
                </template>
              </TableCell>
              <TableCell class="d-flex justify-content-end">
                <div class="table-options">
                  <div title="Visualizar">
                    <Button
                      :name="$t('edit')"
                      class="ml-1"
                      v-if="
                        item.status_contrato_id === 1 ||
                        item.status_contrato_id === 4
                      "
                      @clicked="handleEditClick(item)"
                    />
                    <div @click="handleEditClick(item)" v-else>
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 0C16.392 0 20.878 3.88 21.819 9C20.879 14.12 16.392 18 11 18C5.60803 18 1.12203 14.12 0.18103 9C1.12103 3.88 5.60803 0 11 0ZM11 16C13.0395 15.9996 15.0184 15.3068 16.6129 14.0352C18.2074 12.7635 19.3229 10.9883 19.777 9C19.3213 7.0133 18.205 5.24 16.6107 3.97003C15.0163 2.70005 13.0383 2.00853 11 2.00853C8.96173 2.00853 6.98372 2.70005 5.38941 3.97003C3.79509 5.24 2.6788 7.0133 2.22303 9C2.67713 10.9883 3.7927 12.7635 5.38717 14.0352C6.98164 15.3068 8.96056 15.9996 11 16ZM11 13.5C9.80656 13.5 8.66196 13.0259 7.81805 12.182C6.97414 11.3381 6.50003 10.1935 6.50003 9C6.50003 7.80653 6.97414 6.66193 7.81805 5.81802C8.66196 4.97411 9.80656 4.5 11 4.5C12.1935 4.5 13.3381 4.97411 14.182 5.81802C15.0259 6.66193 15.5 7.80653 15.5 9C15.5 10.1935 15.0259 11.3381 14.182 12.182C13.3381 13.0259 12.1935 13.5 11 13.5ZM11 11.5C11.6631 11.5 12.299 11.2366 12.7678 10.7678C13.2366 10.2989 13.5 9.66304 13.5 9C13.5 8.33696 13.2366 7.70107 12.7678 7.23223C12.299 6.76339 11.6631 6.5 11 6.5C10.337 6.5 9.7011 6.76339 9.23226 7.23223C8.76342 7.70107 8.50003 8.33696 8.50003 9C8.50003 9.66304 8.76342 10.2989 9.23226 10.7678C9.7011 11.2366 10.337 11.5 11 11.5Z"
                          fill="#B0B4BD"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    title="Revisar"
                    v-if="
                      item.status_contrato_id === 2 &&
                      item.contrato_em_revisao_id === null
                    "
                    @click="handleActivePolicy(item, 'review')"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.463 4.43301C7.27756 2.86067 9.59899 1.99666 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 14.136 21.33 16.116 20.19 17.74L17 12H20C20.0001 10.4316 19.5392 8.89781 18.6747 7.58927C17.8101 6.28072 16.5799 5.25517 15.1372 4.64013C13.6944 4.0251 12.1027 3.84771 10.56 4.13003C9.0172 4.41234 7.59145 5.14191 6.46 6.22801L5.463 4.43301ZM18.537 19.567C16.7224 21.1393 14.401 22.0034 12 22C6.477 22 2 17.523 2 12C2 9.86401 2.67 7.88401 3.81 6.26001L7 12H4C3.99987 13.5684 4.46075 15.1022 5.32534 16.4108C6.18992 17.7193 7.42007 18.7449 8.86282 19.3599C10.3056 19.9749 11.8973 20.1523 13.44 19.87C14.9828 19.5877 16.4085 18.8581 17.54 17.772L18.537 19.567Z"
                        fill="#B0B4BD "
                      />
                    </svg>
                  </div>

                  <Button
                    v-if="
                      item.status_contrato_id === 4 ||
                      item.status_contrato_id === 1
                    "
                    class="ml-1"
                    :name="$t('makeEffective')"
                    @clicked="handleActivePolicy(item, 'release')"
                  />
                </div>
              </TableCell>
            </TableRow>
            <template v-if="policies.length === 0" v-slot:no-results>
              <NoResults :message="$t('noResults')" />
            </template>
          </Table>
        </div>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <CModal
      :title="$t('changeStatus')"
      color="primary"
      :show.sync="policyModal"
      class="mt-4"
    >
      <p v-if="modalType === 'review'">
        {{ $t("confirmChangeStatus") }}
      </p>
      <p v-if="modalType === 'cancel'">
        {{ $t("cancelChangeStatus") }}
      </p>
      <p v-if="modalType === 'release'">
        {{ $t("confirmEffective") }}
      </p>
      <template v-slot:footer>
        <CButton color="primary" @click="handleSubmitModal">
          {{ $t("save") }}</CButton
        >
        <CButton color="primary" @click="policyModal = false">{{
          $t("cancel")
        }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import services from "../../services";

import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import BackArrow from "../../components/BackArrow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

import { formatDateToLocale } from "../../utils/date";
import { successToast } from "../../utils/toast";

export default {
  name: "Policies",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Loading,
    Button,
    BackArrow,
  },
  data() {
    return {
      date: new Date(),
      fields: [
        {
          key: "contrato_titulo",
          label: this.$t("title"),
          _style: "min-width:200px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "contrato_versao",
          label: this.$t("version"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "date_formatted",
          label: this.$t("validity"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "status",
          label: this.$t("status"),
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      policies: [],
      policy: {},
      modalType: "",
      policyModal: false,
      loadingData: true,
    };
  },
  mounted: async function () {
    await this.initPolicies();
    this.loadingData = false;
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async initPolicies() {
      const { data, errors } = await services.policies.getAllPolicies();
      if (!errors) {
        this.policies = data.map((item) => {
          return {
            ...item,
            date_formatted: formatDateToLocale(
              item.contrato_data_inicio_vigencia
            ),
            status:
              item.status_contrato_id === 1
                ? this.$t("creation")
                : item.status_contrato_id === 2
                ? this.$t("actual")
                : item.status_contrato_id === 3
                ? this.$t("obsolete")
                : item.status_contrato_id === 4
                ? this.$t("reviewing")
                : "",
          };
        });
      }
    },
    handleActivePolicy(item, type) {
      this.policy = item;
      this.modalType = type;
      this.policyModal = true;
    },
    initialConditions() {
      this.modalType = "";
      this.policyModal = false;
      this.policy = {};
    },
    async newVersionOfPolicy() {
      const { errors } = await services.policies.newVersionPolicy({
        contrato_id: this.policy.contrato_id,
        usuario_id: this.$store.state.global.user.usuario_gestor_id,
      });
      if (!errors) {
        successToast(this.$t("versionChangedForReview"));
        await this.initPolicies();
        this.initialConditions();
      }
    },
    async cancelReviewPolicy() {},
    async releasePolicy() {
      const { errors } = await services.policies.releasePolicy({
        contrato_id: this.policy.contrato_id,
      });
      if (!errors) {
        await this.initPolicies();
        this.initialConditions();
        successToast(this.$t("successChangeVersionStatus"));
      }
    },
    onClickAddPolicy() {
      this.$router.push(`/politicas/cadastro`);
    },
    handleEditClick(item) {
      this.$router.push(`/politicas/editar/${item.contrato_id}`);
    },
    async handleSubmitModal() {
      if (this.modalType === "review") {
        await this.newVersionOfPolicy();
      } else if (this.modalType === "cancel") {
        this.cancelReviewPolicy();
      } else if (this.modalType === "release") {
        this.releasePolicy();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policies {
  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
}
</style>

<template>
  <div class="users">
    <div class="div-titulo">
      <div>
        <h3 class="intern-title mb-4 pb-2">
          <back-arrow />
          <span class="pagetitle pl-2"
            >Editar Ordem De serviço {{ OsInfo.ordem_servico_id }}</span
          >
        </h3>

        <div>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Em Criação'"
            class="em-criacao status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Revisar'"
            class="revisar status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Finalizada'"
            class="finalizada status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Cancelada'"
            class="revisar status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Em Andamento'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Revisado'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Em Análise'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Avaliar'"
            class="finalizada status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
          <span
            v-if="OsInfo.ordem_servico_status_descricao === 'Re-Execução'"
            class="andamento status_editar"
          >
            {{ OsInfo.ordem_servico_status_descricao }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="historicos != []" class="historico-Os">
      <h3 class="nova-ordem-titulo">Histórico da Ordem de Serviço</h3>
      <div
        v-for="log of historicos"
        :key="log.ordem_servico_log_id"
        class="historico-loop"
      >
        <div class="historico-data">
          {{ formatDate(log.ordem_servico_log_data) }}
        </div>
        <div class="historico-card">
          <div class="historico-usuario">
            <span class="historico-nome">{{ log.usuario_gestor_nome }}</span>
            <span class="historico-descricao">Alterou o status</span>
            <div>
              <span
                v-if="log.ordem_servico_status_descricao === 'Em Criação'"
                class="em-criacao status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Revisar'"
                class="revisar status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Finalizada'"
                class="finalizada status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Cancelada'"
                class="revisar status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Em Andamento'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Revisado'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Em Análise'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Avaliar'"
                class="finalizada status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
              <span
                v-if="log.ordem_servico_status_descricao === 'Re-Execução'"
                class="andamento status_historico"
              >
                {{ log.ordem_servico_status_descricao }}
              </span>
            </div>
          </div>
          <div class="historico-log-info">
            <h3 v-if="!log.ordem_servico_log_info" class="log-info">
              {{ log.ordem_servico_log_info }}
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="instrucoes">
      <h3 class="nova-ordem-titulo">Instruções</h3>
      <textarea
        @change="change()"
        placeholder="Digite aqui as instruções da ordem de serviço ..."
        class="instrucoes-input"
        v-model="instrucoes"
        type="text"
        disabled
      ></textarea>
    </div>

    <div v-if="this.exibirNeutro === true" class="analise-box">
      <div class="box-zeladoria-analise">
        <div class="coluna-dados">
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Atividade</span>
              <span class="card-info">{{ OsInfo.atividade_nome }}</span>
            </div>
            <img src="../../assets/img/atividade.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Criação</span>
              <span class="card-info">{{
                formatDate(OsInfo.ordem_servico_data_criacao)
              }}</span>
            </div>
            <img src="../../assets/img/cricao.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Bairro</span>
              <span class="card-info">{{ OsInfo.bairro_nome }}</span>
            </div>
            <img src="../../assets/img/cricao.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Zelador</span>
              <span class="card-info">{{ OsInfo.usuario_nome }}</span>
            </div>
            <img src="../../assets/img/zelador.png" alt="icon" />
          </div>
        </div>

        <div class="coluna-dados">
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Endereço</span>
              <span class="card-info">{{ OsInfo.ordem_servico_endereco }}</span>
            </div>
            <img src="../../assets/img/endereco.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Finalizada</span>
              <span
                v-if="OsInfo.ordem_servico_status_descricao === 'Finalizada'"
                class="card-info"
                >{{ OsInfo.ordem_servico_status_descricao }}</span
              >
              <span v-else class="card-info">--</span>
            </div>
            <img src="../../assets/img/endereco.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Áreas de Zeladoria</span>
              <span class="card-info">{{ OsInfo.zeladoria_nome }}</span>
            </div>
            <img src="../../assets/img/area.png" alt="icon" />
          </div>
          <div class="mini-info-card">
            <div class="info-card-text">
              <span class="info-card-nome">Monitor</span>
              <span class="card-info">{{ OsInfo.usuario_gestor_nome }}</span>
            </div>
            <img src="../../assets/img/monitor.png" alt="icon" />
          </div>
        </div>

        <div class="os-map">
          <h3 class="mapa-titulo">Instruções</h3>
          <GmapMap
            class="map"
            ref="googleMap"
            :center="center"
            :zoom="zoom"
            style="height: 278px; width: 500px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              mapTypeId: mapType,
              gestureHandling: 'greedy',
              fullscreenControl: true,
              disableDefaultUi: true,
              mapTypeId: mapType,
            }"
          >
            <div
              v-for="polygon of polygonSector"
              :key="'polygonsKeepingSector' + polygon.id"
            >
              <GmapPolygon
                ref="polygon"
                :paths="polygon.latlngs"
                :options="polygon.options2"
              />
            </div>
            <GmapPolyline
              ref="polyline"
              v-if="polyline !== null"
              :path="polyline.latlngs"
              :options="polyline.options"
            />
          </GmapMap>
        </div>
      </div>

      <div class="linha-do-tempo">
        <h3 class="nova-ordem-titulo">Linha do Tempo</h3>
        <div v-if="activities.length > 0" class="box-cards">
          <div
            v-for="(item, $index) of activities"
            :key="$index"
            class="card-lista-execucoes"
          >
            <div class="card-execucoes">
              <div class="box-foto-execucao">
                <div
                  v-for="foto of item.execucao_timeline"
                  :key="foto.execucao_uuid"
                >
                  <img
                    @click="redirectToDetails(item)"
                    class="card-img-top"
                    :src="foto.execucao_timeline_foto"
                  />
                </div>
              </div>

              <div class="card-block">
                <div class="card-subtitle-execucoes">
                  {{ item.atividade_nome }}
                </div>
                <div class="card-data">
                  {{ formatDate(item.execucao_data_criacao) }}
                </div>
              </div>

              <h3 v-if="item.execucao_nota > 0" class="card-avaliacao">
                Avaliação
              </h3>
              <h3 v-else class="card-avaliacao">Avalie a atividade:</h3>
              <div v-if="item.execucao_nota > 0" class="star-rating">
                <star-rating active-color="#F2B836" inactive-color="#C4C4C4"
                v-model="item.execucao_nota" :star-size="33" Readme: read-only
                />
              </div>
              <div v-else class="star-rating">
                <star-rating
                  active-color="#F2B836"
                  inactive-color="#C4C4C4"
                  v-model="nota"
                  :star-size="33"
                />
                <div v-if="nota < 4 && nota > 0" class="instrucoes">
                  <h3 class="nova-ordem-titulo">Justificativa</h3>
                  <textarea
                    placeholder="Digite aqui a justificativa ..."
                    class="justificativa-input"
                    v-model="justificativa"
                    type="text"
                  ></textarea>
                </div>

                <div v-if="OsInfo.ordem_servico_status_descricao === 'Avaliar'">
                  <div v-if="userType === 3" class="botoes-avaliar">
                    <button
                      id="botao-avaliar"
                      @click="avaliar()"
                      class="exibir"
                    >
                      Avaliar
                    </button>
                    <div v-if="alertaJustificativa === true" class="alertErro">
                      *Preencha o campo de justificativa*
                    </div>
                    <div v-if="alertaAvaliacao === true" class="alertErro">
                      *Defina a nota de avaliação*
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL --------------------------------------------------------------------------------------------------------------------------------------------------------------------------->

    <Modal class="modal-confirmacao" v-show="modalAvaliar" :width="500">
      <template v-slot:header>Deseja encaminhar avaliação?</template>
      <template v-slot:footer>
        <div class="botoes-confirmacao">
          <button class="botao-cancelar" @click="modalAvaliar = false">
            {{ $t("cancel") }}
          </button>
          <button class="botao-salvar" @click="enviarAvaliacao()">
            Encaminhar
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

  <script>
import StarRating from "vue-star-rating";
import { formatDate } from "../../utils/date";
import services from "../../services";
import Modal from "../../components/Modal.vue";
import BackArrow from "../../components/BackArrow.vue";
import { formatForGoogleMaps } from "../../utils/geoMaps";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Zeladores",
  components: {
    Modal,
    BackArrow,
    StarRating,
  },
  data() {
    return {
      check1: false,
      check2: false,
      check3: false,
      date: new Date(),
      removeModal: false,
      editModal: false,
      userIsActive: false,
      userType: null,
      atividade: 0,
      atividades: [],
      center: {
        lat: -25.43432,
        lng: -49.2638,
      },
      zoom: 2,
      polyline: null,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "roadmap",
      polygonSector: [],
      instrucoes: "",
      endereco: "",
      endereco_selecionado: null,
      atividadeEscolhida: false,
      exibirDiv: false,
      exibirErro: false,
      bairro: 0,
      bairros: [],
      bairro_poligono: {},
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      bounds: [],
      clickedPoligon: null,
      infoZeladoria: {
        zeladoria_nome: null,
        usuario_nome: null,
      },
      exibirSucesso: false,
      alertaJustificativa: false,
      alertaAvaliacao: false,
      exibirNeutro: false,
      OsInfo: null,
      execucoes: null,
      changeEndereco: false,
      modalAvaliar: false,
      historicos: [],
      formatDate,
      activities: [],
      nota: 0,
      justificativaBox: false,
      justificativa: "",
    };
  },
  mounted: async function () {
    const user = this.$store.state.global.user;
    this.userType = user.usuario_gestor_tipo_id;
    await this.getOsInfo();
    await this.historico();
    await this.getExecucoes();
    await this.OsFinalizada();
    this.loadingData = false;
  },
  methods: {
    redirectToDetails(item) {
      this.$router.push({
        name: "DetalheExecucao",
        params: { execucao_uuid: item.execucao_uuid },
      });
    },
    async salvar() {
      this.alertaJustificativa = false;
      if (this.exibirSucesso === true) {
        const { errors } = await services.serviceOrder.salvar({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.infoZeladoria.zeladoria_id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
        });
        if (errors) {
          console.log(errors);
        }
      } else if (this.clickedPoligon != null) {
        const { errors } = await services.serviceOrder.salvar({
          atividade_id: this.atividade.atividade_id,
          zeladoria_id: this.clickedPoligon.id,
          ordem_servico_instrucao: this.instrucoes,
          ordem_servico_endereco: this.endereco_selecionado,
        });
        if (errors) {
          console.log(errors);
        }
      } else {
        this.alertaJustificativa = true;
      }
    },
    async OsFinalizada() {
      if (this.OsInfo.ordem_servico_status_descricao === "Finalizada") {
        this.nota = this.activities[0].execucao_nota;
      }
    },
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getOrders(this.pagination);
    },
    correçãoErro() {
      formatForGoogleMaps();
    },
    mountPolygon(poligono, id) {
      if (poligono !== "") {
        const coordinatesJSONParse = poligono;
        let bairro_poligono = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse),
          options: {
            strokeColor: "#A30BB2",
            fillColor: "#BE21CD",
          },
          options2: {
            strokeColor: "#348534",
            fillColor: "#20d820",
          },
          id,
        };
        bairro_poligono.latlngs.map((item) => {
          this.bounds.push(item);
        });

        this.polygonSector.push(bairro_poligono);
      }
      this.fitBoundsGoogle();
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 100);
    },
    async getOsInfo() {
      const { data, errors } = await services.serviceOrder.getOsInfo({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.OsInfo = data;
        this.atividade = {
          atividade_nome: this.OsInfo.atividade_nome,
          atividade_id: this.OsInfo.atividade_id,
        };
        this.instrucoes = this.OsInfo.ordem_servico_instrucao;
        this.endereco = this.OsInfo.ordem_servico_endereco;
        this.endereco_selecionado = this.OsInfo.ordem_servico_endereco;
        this.infoZeladoria = data;
        this.bairro_poligono = data.zeladoria_limite;
        this.mountPolygon(this.OsInfo.zeladoria_limite[0]);
        this.exibirNeutro = true;
      }
    },
    async historico() {
      const { data, errors } = await services.serviceOrder.historico({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.historicos = data;
      }
    },
    async getExecucoes() {
      const { data, errors } = await services.serviceOrder.getExecucoes({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.activities = data;
      }
    },
    avaliar() {
      this.alertaJustificativa = false;
      this.alertaAvaliacao = false;
      if (this.nota < 4 && this.nota > 0) {
        if (this.justificativa.length > 0) {
          this.modalAvaliar = true;
        } else {
          this.alertaJustificativa = true;
        }
      } else if (this.nota > 3) {
        this.modalAvaliar = true;
      } else {
        this.alertaAvaliacao = true;
      }
    },
    async enviarAvaliacao() {
      let lastOne = this.activities.length - 1;
      const { errors } = await services.serviceOrder.putAvaliacao({
        execucao_uuid: this.activities[lastOne].execucao_uuid,
        execucao_nota: this.nota,
        ordem_servico_log_info: this.justificativa,
      });
      if (!errors) {
        console.log(errors);
      }
      this.modalAvaliar = false;
      this.$router.push("/ordens-de-servico");
    },
  },
};
</script>
  <style lang="scss">
.nova-ordem-titulo {
  font-family: "Montserrat";
  font-size: 20px;
  color: #565e73;
  margin: 24px 0px;
  font-weight: 600;
}
.nova-ordem {
  margin-bottom: 32px;
  margin-top: 64px;
}
.atividade-input {
  padding: 12px 16px;
  width: 600px !important;
  background: #ffffff;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  outline: none;
}
.instrucoes-input {
  height: 160px !important;
  width: 100%;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 12px 16px;
  outline: none;
}
.justificativa-input {
  height: 160px !important;
  width: 98%;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 12px 16px;
  outline: none;
}
.exibir {
  font-size: 14px;
  width: min-content;
  color: white;
  background: #003366;
  border-radius: 8px;
  padding: 4px 16px;
  border: none;
}
.zeladoria-atribuida {
  text-align: center;
  align-items: center;
  padding: 2px 6px;
  background: #dddee3;
  border-radius: 30px;
  width: 80px;
  color: #131e3b;
  margin-bottom: 26px;
}
.nome-zelador {
  display: flex;
  color: #131e3b;
  font-size: 14px;
  font-family: "Open Sans";
  margin: 0px;
  justify-content: center;
  align-items: center;
}
.box-zeladoria {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.revisar-botao {
  padding: 8px 16px;
  color: white;
  text-align: center;
  font-family: "Open Sans";
  margin-right: 32px;
  border-radius: 8px;
  background: #d3341d;
  border: none;
}
.botoes-avaliar {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 0px 0px;
  justify-content: center;
  font-size: 18px;
}
.erro-endereco {
  display: flex;
  flex-direction: row;
  border: solid 3px #d3341d;
  border-radius: 8px;
  height: 124px;
  align-items: center;
  margin: 24px 0px;
}
textarea::placeholder {
  color: lightgray;
}
input::placeholder {
  color: lightgray;
}
.input-wrapper {
  margin: 0px !important;
  padding: 0px;
}
button:disabled {
  background: #b8c5d3;
}
.alertErro {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: red;
  margin: 0px 18px;
  height: 41px;
}
.status_editar {
  font-size: 14px;
  margin-left: 44px;
}
.modal-confirmacao {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.botoes-confirmacao {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
}
.botao-salvar {
  padding: 12px;
  width: 100px;
  background-color: #7b9e1e;
  color: white;
  border: none;
  border-radius: 8px;
}
.botao-cancelar {
  padding: 12px;
  width: 100px;
  background-color: #a92a17;
  color: white;
  border: none;
  border-radius: 8px;
}
.div-titulo {
  display: flex;
  justify-content: space-between;
}
.botao-cancelar-solicitacao {
  background-color: #d3341d;
  border: none;
  color: white;
  padding: 3px 14px;
  border-radius: 7px;
  border: none;
}
.historico-card {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  padding: 12px;
  border-radius: 12px;
}
.historico-usuario {
  display: flex;
  padding: 6px;
}
.historico-nome {
  color: #00b1eb;
  font-size: 14px;
  margin-right: 12px;
}
.historico-descricao {
  display: flex;
  color: #565e73;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.status_historico {
  font-size: 12px;
  margin-left: 16px;
}
.log-info {
  border: 1px solid #dddee3;
  border-radius: 8px;
  padding: 12px;
  color: #565e73;
  font-size: 14px;
  font-family: "Open Sans";
  background-color: #f3f4f5;
  margin-top: 16px;
}
.historico-data {
  color: #131e3b;
  margin-top: 28px !important;
  margin-bottom: 18px !important;
  margin-left: 8px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: bold;
}
.historico-Os {
  margin-top: 64px;
}
.alertErro {
  display: flex;
  flex-direction: row;
}
.instrucoes {
  margin: 64px 0px;
}
.mini-info-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 18px;
  width: 100%;
  margin: 16px 0px;
}
.coluna-dados {
  width: 100%;
  margin: 0px 16px;
  max-width: 500px;
}
.info-card-text {
  display: flex;
  flex-direction: column;
}
.info-card-nome {
  color: #565e73;
  font-size: 14px;
  font-family: "Montserrat";
  line-height: 132%;
}
.card-info {
  color: #838998;
  font-size: 12px;
  font-family: "Montserrat";
  line-height: 132%;
}
.mini-card-icon {
  width: 18px;
  height: 18px;
}
.os-map {
  padding: 24px;
  border: 1px solid lightgray;
  border-radius: 18px;
  margin: 16px 0px;
  margin-left: 24px;
}
.mapa-titulo {
  font-family: "Montserrat";
  font-size: 20px;
  color: #565e73;
  margin: 12px 0px;
}
.box-zeladoria-analise {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
}
.card-execucoes {
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
  width: 100%;
}
.card-img-top {
  display: block;
  width: 274px;
  height: auto;
  border-radius: 12px;
  margin: 12px;
}
.card-title {
  padding: 0px 12px;
  font-family: "OpenSans-Regular";
  font-size: 20px;
  line-height: 140.5%;
  color: #838998;
}
.card-subtitle-execucoes {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #131e3b;
  border-radius: 8px;
  background: #dddee3;
  padding: 8px;
  width: max-content !important;
  margin: 12px;
  margin-top: 0px;
}
.card-lista-execucoes {
  width: 100%;
  margin: 12px;
  display: flex;
  flex-wrap: wrap;
}
.estrela-acesa {
  cursor: pointer;
  margin: 4px;
}
.estrela-apagada {
  cursor: pointer;
  margin: 4px;
}
.estrelas {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 0px;
}
#avaliacao {
  margin-top: 64px;
}
.nota {
  margin: 5px 0px 12px 70px;
  color: #b0b4bd;
}
.box-cards {
  width: 100%;
  display: flex;
}
.box-foto-execucao {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.card-data {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #838998;
  margin-left: 12px;
}
.star-rating {
  padding: 0px 0px 24px 12px;
}
.card-avaliacao {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #565e73;
  margin: 12px 12px 18px 12px;
}
</style>

<template>
  <div>
    <h3 class="intern-title">
      <back-arrow />
      <span class="page-nav">{{ $t("reports") }}</span>
    </h3>

    <div class="cards-container">
      <div class="card-body">
        <div class="card-type" v-if="userType !== 2">
          <div class="icon">
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.675781"
                width="44"
                height="44"
                rx="22"
                fill="#F3F4F5"
              />
              <path
                d="M26 12.6758L31 17.6758V31.6688C31 32.2248 30.555 32.6758 30.007 32.6758H13.993C13.445 32.6758 13 32.2208 13 31.6838V13.6678C13 13.1198 13.447 12.6758 13.999 12.6758H26ZM25 14.6758H15V30.6758H29V18.6758H25V14.6758ZM23 19.6758V23.6758H26V25.6758H21V19.6758H23Z"
                fill="#838998"
              />
            </svg>
          </div>
          <div class="content">
            <p class="title">{{ $t("executorsByPeriodReport") }}</p>
            <p class="description">
              O relatório de executores apresenta quando foi a primeira e a
              última execução de cada zelador em sua área de zeladoria.
            </p>
            <Button
              class="button"
              @clicked="goToReport('executions')"
              style="width: 44px"
              :name="$t('see')"
              size="small"
            />
          </div>
        </div>

        <div class="card-type" v-if="userType !== 2">
          <div class="icon">
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.675781"
                width="44"
                height="44"
                rx="22"
                fill="#F3F4F5"
              />
              <path
                d="M26.05 22.7266L31 27.6766L26.05 32.6266L24.636 31.2126L27.172 28.6756L14 28.6766V26.6766H27.172L24.636 24.1406L26.05 22.7266ZM17.95 12.7266L19.364 14.1406L16.828 16.6766H30V18.6766H16.828L19.364 21.2126L17.95 22.6266L13 17.6766L17.95 12.7266Z"
                fill="#838998"
              />
            </svg>
          </div>
          <div class="content">
            <p class="title">{{ $t("turnoverReport") }}</p>
            <p class="description">
              Este relatório permite saber quais zeladores estão ativos e
              inativos dentro de um período estabelecido.
            </p>
            <Button
              class="button"
              @clicked="goToReport('turnover')"
              style="width: 44px"
              :name="$t('see')"
              size="small"
            />
          </div>
        </div>

        <div class="card-type">
          <div class="icon">
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.675781"
                width="44"
                height="44"
                rx="22"
                fill="#F3F4F5"
              />
              <path
                d="M18 30.6758V25.6758H20V30.6758H29V23.6758H15V30.6758H18ZM14 21.6758H30V18.6758H24V14.6758H20V18.6758H14V21.6758ZM13 31.6758V23.6758H12V17.6758C12 17.4106 12.1054 17.1562 12.2929 16.9687C12.4804 16.7811 12.7348 16.6758 13 16.6758H18V13.6758C18 13.4106 18.1054 13.1562 18.2929 12.9687C18.4804 12.7811 18.7348 12.6758 19 12.6758H25C25.2652 12.6758 25.5196 12.7811 25.7071 12.9687C25.8946 13.1562 26 13.4106 26 13.6758V16.6758H31C31.2652 16.6758 31.5196 16.7811 31.7071 16.9687C31.8946 17.1562 32 17.4106 32 17.6758V23.6758H31V31.6758C31 31.941 30.8946 32.1954 30.7071 32.3829C30.5196 32.5704 30.2652 32.6758 30 32.6758H14C13.7348 32.6758 13.4804 32.5704 13.2929 32.3829C13.1054 32.1954 13 31.941 13 31.6758Z"
                fill="#838998"
              />
            </svg>
          </div>
          <div class="content">
            <p class="title">{{ $t("activitiesByInstitutionReport") }}</p>
            <p class="description">
              O relatório de recebimento de materiais lista datas de recebimento
              dos materiais pelos zeladores e inclui registros fotográficos das
              entregas.
            </p>
            <Button
              class="button"
              @clicked="goToReport('institution')"
              style="width: 44px"
              :name="$t('see')"
              size="small"
            />
          </div>
        </div>

        <div class="card-type">
          <div class="icon">
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.675781"
                width="44"
                height="44"
                rx="22"
                fill="#F3F4F5"
              />
              <path
                d="M17 15.6758V12.6758C17 12.4106 17.1054 12.1562 17.2929 11.9687C17.4804 11.7811 17.7348 11.6758 18 11.6758H26C26.2652 11.6758 26.5196 11.7811 26.7071 11.9687C26.8946 12.1562 27 12.4106 27 12.6758V15.6758H31C31.2652 15.6758 31.5196 15.7811 31.7071 15.9687C31.8946 16.1562 32 16.4106 32 16.6758V30.6758C32 30.941 31.8946 31.1954 31.7071 31.3829C31.5196 31.5704 31.2652 31.6758 31 31.6758H13C12.7348 31.6758 12.4804 31.5704 12.2929 31.3829C12.1054 31.1954 12 30.941 12 30.6758V16.6758C12 16.4106 12.1054 16.1562 12.2929 15.9687C12.4804 15.7811 12.7348 15.6758 13 15.6758H17ZM14 26.6758V29.6758H30V26.6758H14ZM14 24.6758H30V17.6758H14V24.6758ZM19 13.6758V15.6758H25V13.6758H19ZM21 21.6758H23V23.6758H21V21.6758Z"
                fill="#838998"
              />
            </svg>
          </div>
          <div class="content">
            <p class="title">{{ $t("materialsReceiptReport") }}</p>
            <p class="description">
              Este relatório permite encontrar registros de atividades e tags
              específicas vinculadas a uma instituição de Florianópolis.
            </p>
            <Button
              class="button"
              @clicked="goToReport('materials')"
              style="width: 44px"
              :name="$t('see')"
              size="small"
            />
          </div>
        </div>

        <div class="card-type" v-if="userType !== 2">
          <div class="icon">
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.675781"
                width="44"
                height="44"
                rx="22"
                fill="#F3F4F5"
              />
              <path
                d="M26.05 22.7266L31 27.6766L26.05 32.6266L24.636 31.2126L27.172 28.6756L14 28.6766V26.6766H27.172L24.636 24.1406L26.05 22.7266ZM17.95 12.7266L19.364 14.1406L16.828 16.6766H30V18.6766H16.828L19.364 21.2126L17.95 22.6266L13 17.6766L17.95 12.7266Z"
                fill="#838998"
              />
            </svg>
          </div>
          <div class="content">
            <p class="title">{{ $t("accessReport") }}</p>
            <p class="description">
              Este relatório permite saber quais zeladores estão ativos e
              inativos dentro de um período estabelecido.
            </p>
            <Button
              class="button"
              @clicked="goToReport('access')"
              style="width: 44px"
              :name="$t('see')"
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import BackArrow from "../../components/BackArrow.vue";

export default {
  components: {
    Button,
    BackArrow,
  },
  data() {
    return {
      userType: null,
    };
  },
  mounted: async function () {
    this.$store.dispatch("global/setUser").then(() => {
      const user = this.$store.state.global.user;
      this.userType = user.usuario_gestor_tipo_id;
    });
  },
  methods: {
    goToHome() {
      this.$router.push({
        name: "index",
      });
    },
    goToReport(report) {
      if (report === "executions") {
        this.$router.push({
          name: "LaudoTecnico",
        });
      } else if (report === "turnover") {
        this.$router.push({
          name: "LaudoContratos",
        });
      } else if (report === "institution") {
        this.$router.push({
          name: "LaudoTecnicoPorInstituicao",
        });
      } else if (report === "materials") {
        this.$router.push({
          name: "RelatorioMateriais",
        });
      } else if (report === "access") {
        this.$router.push({
          name: "RelatorioAcessos",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.card-body {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  .card-type {
    border: 1px solid #dddee3;
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1;
    padding: 32px 24px;
    border-radius: 8px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.006em;
      text-align: left;
      color: #565e73;
      .title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #131e3b;
      }
      .button {
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .card-type {
    width: 100%;
  }
  .card-body {
    grid-template-columns: 1fr !important;
  }
}
</style>



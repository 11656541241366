export default httpClient => ({
  getAllPolicies: async () => {
      const response = await httpClient.get('/privacy-policy/all')
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  getPolicyById: async (data) => {
      const response = await httpClient.get(`/privacy-policy/id/${data.id}`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  createPolicy: async (data) => {
      const response = await httpClient.post('/privacy-policy/new', data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  updatePolicy: async (data) => {
      const response = await httpClient.put('/privacy-policy/update', data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  newVersionPolicy: async (data) => {
      const response = await httpClient.put('/privacy-policy/new-version', data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  releasePolicy: async (data) => {
      const response = await httpClient.put('/privacy-policy/release', data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
})

import { decrypt } from '../../utils/decrypt'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    user: {},
    printBackup: {},
    printNeighborhood: {},
    printCity: {},
    city: null,
    language: 'pt-br',
    nomeMonitor: null,
    emailMonitor: null,
  },
  getters: {
    loading: state => state.isLoading,
    user: state => state.user,
    printBackup: state => state.printBackup,
    printNeighborhood: state => state.printNeighborhood,
    printCity: state => state.printCity,
    city: state => state.city,
    language: state => state.language,
    nomeMonitor: state => state.nomeMonitor,
    emailMonitor: state => state.emailMonitor,
  },
  mutations: {
    setLoading(state, status) {
      state.isLoading = status
    },
    setUser(state, user) {
      state.user = user
    },
    setPrintBackup(state, printBackup) {
      state.printBackup = printBackup
    },
    setPrintNeighborhood(state, printNeighborhood) {
      state.printNeighborhood = printNeighborhood
    },
    setPrintCity(state, printCity) {
      state.printCity = printCity
    },
    setCities(state, city) {
      state.city = city
    },
    setLanguage(state, language) {
      state.language = language
    },
    setNomeMonitor(state, nomeMonitor) {
      state.nomeMonitor = nomeMonitor
    },
    setEmailMonitor(state, emailMonitor) {
      state.emailMonitor = emailMonitor
    }
  },
  actions: {
    setLoading({ commit }, status) {
      commit('setLoading', status)
    },
    setUser({ commit }) {
      const encrypted = localStorage.getItem('ama')
      const decrypted = decrypt(encrypted)
      const user = JSON.parse(decrypted)
      commit('setUser', user)
    },
    setPrintBackup({ commit }, printBackup) {
      commit('setPrintBackup', printBackup)
    },
    setPrintNeighborhood({ commit }, printNeighborhood) {
      commit('setPrintNeighborhood', printNeighborhood)
    },
    setPrintCity({ commit }, printCity) {
      commit('setPrintCity', printCity)
    },
    setCities({ commit }, city) {
      commit('setCities', city)
    },
    setNomeMonitor({ commit }, nomeMonitor) {
      commit('setNomeMonitor', nomeMonitor)
    },
    setEmailMonitor({ commit }, EmailMonitor) {
      commit('setEmailMonitor', EmailMonitor)
    },
  }
}

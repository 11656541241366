<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="$emit('close')">
      <div
        @click.stop=""
        class="modal"
        role="dialog"
        :style="`${dimentions()}`"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-header" id="modalTitle">
          <slot name="header"></slot>
        </div>

        <section class="modal-body" id="modalDescription">
          <slot name="body"> </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer"> </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  props: {
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dimentions: () => {
        if (this.height) {
          return `height: ${this.height}px; width:${this.width}px`;
        } else {
          return `width: ${this.width}px`;
        }
      },
    };
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  padding-top: 20px;

  &:hover{
    transform: scale(1.05);
    transition: 0.4s;
  }
}

.modal {
  position: relative;
  margin: 0 auto;
  padding: 32px 24px;
  height: fit-content;
  width: fit-content;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
}

.modal-header,
.modal-footer {
  display: flex;
  width: 100%;
}

.modal-header {
  width: 100%;
  border-bottom: none;
  font-size: 1.3rem;
  color: #003366;
  justify-content: center;
  padding: 0;
  margin-bottom: 12px;
}

.modal-footer {
  border-top: 1px solid #fff;
  justify-content: flex-start;
  padding: 0;
}

.modal-body {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 12px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>

<template>
  <div>
    <div class="row">
      <div class="col-6 form-section">Título da pesquisa</div>
      <div class="col-3 form-section">Período de Vigência</div>
      <div class="col-3 form-section">Meta de entrevistados por zelador</div>
    </div>

    <section class="row">
      <div class="col-6 pr-4">
        <div class="position-relative">
          <Input
            placeholder="Adicione um título *"
            @focusin="focusInput = true"
            @focusout="focusInput = false"
            :maxlength="45"
            v-model="research.title"
          />
          <span
            v-if="focusInput || research.title.length > 44"
            class="alert-normal"
            :class="research.title.length > 44 && 'alert-length'"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7.00004C0.333496 3.31804 3.31816 0.333374 7.00016 0.333374C10.6822 0.333374 13.6668 3.31804 13.6668 7.00004C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3334C8.41465 12.3334 9.77121 11.7715 10.7714 10.7713C11.7716 9.77108 12.3335 8.41453 12.3335 7.00004C12.3335 5.58555 11.7716 4.229 10.7714 3.2288C9.77121 2.22861 8.41465 1.66671 7.00016 1.66671C5.58567 1.66671 4.22912 2.22861 3.22893 3.2288C2.22873 4.229 1.66683 5.58555 1.66683 7.00004C1.66683 8.41453 2.22873 9.77108 3.22893 10.7713C4.22912 11.7715 5.58567 12.3334 7.00016 12.3334ZM6.3335 9.00004H7.66683V10.3334H6.3335V9.00004ZM6.3335 3.66671H7.66683V7.66671H6.3335V3.66671Z"
                :fill="research.title.length > 45 ? '#ff002e' : '#131e3b'"
              />
            </svg>
            {{ research.title.length }}/45 caracteres
          </span>
        </div>
      </div>
      <div class="col-3 d-flex align-items-center">
        <div class="position-relative" id="calendar">
          <svg
            class="pointer calender-icon"
            @click="calendarModal = !calendarModal"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="14" fill="#003063" />
            <path
              d="M9 13.5H19V18C19 18.1326 18.9473 18.2598 18.8536 18.3536C18.7598 18.4473 18.6326 18.5 18.5 18.5H9.5C9.36739 18.5 9.24021 18.4473 9.14645 18.3536C9.05268 18.2598 9 18.1326 9 18V13.5ZM16.5 9.5H18.5C18.6326 9.5 18.7598 9.55268 18.8536 9.64645C18.9473 9.74021 19 9.86739 19 10V12.5H9V10C9 9.86739 9.05268 9.74021 9.14645 9.64645C9.24021 9.55268 9.36739 9.5 9.5 9.5H11.5V8.5H12.5V9.5H15.5V8.5H16.5V9.5Z"
              fill="white"
            />
          </svg>

          <Transition name="filter">
            <div class="absolute-box w-50" id="calendar" v-if="calendarModal">
              <v-date-picker
                v-model="range"
                is-range
                @input="
                  calendarModal = false;
                  forwardSound.play();
                "
              />
            </div>
          </Transition>
        </div>
        <div class="dates-container" v-if="range.start && range.end">
          <span class="date">{{ formatDate(range.start) }}</span>
          <span> - </span>
          <span class="date">{{ formatDate(range.end) }}</span>
          <svg
            @click="range = { start: null, end: null }"
            class="remove-dates"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.00048 4.05732L8.30048 0.757324L9.24315 1.69999L5.94315 4.99999L9.24315 8.29999L8.30048 9.24266L5.00048 5.94266L1.70048 9.24266L0.757812 8.29999L4.05781 4.99999L0.757812 1.69999L1.70048 0.757324L5.00048 4.05732Z"
              fill="#131E3B"
            />
          </svg>
        </div>
      </div>
      <div class="col-3">
        <div class="input-container">
          <Input
            placeholder="Adicione a meta *"
            type="number"
            :min="0"
            v-model="research.goal"
          />
        </div>
      </div>
    </section>

    <section class="section mt-5">
      <div class="form-section">Identificar entrevistado</div>
      <div>
        <CustomSwitch @checkedSync="handleCheck" :selected="checkId" />
      </div>
    </section>

    <div class="btns-container">
      <Button
        type="outline-danger"
        size="small"
        name="Cancelar pesquisa"
        @clicked="$emit('cancel-form')"
      />

      <Button
        size="small"
        :disabled="
          research.title.length === 0 ||
          !research.goal ||
          range.start === null ||
          range.end === null
        "
        name="Salvar e continuar"
        @clicked="saveAndContinue"
      />
    </div>
  </div>
</template>

<script>
import Input from "../../../components/form/Input.vue";
import CustomSwitch from "../../../components/CustomSwitch.vue";
import Button from "../../../components/Button.vue";

import forwardSound from "../../../assets/sounds/navigation_forward-selection-minimal.mp3";
import backwardSound from "../../../assets/sounds/navigation_backward-selection-minimal.mp3";

import services from "../../../services";

export default {
  components: {
    Button,
    Input,
    CustomSwitch,
  },
  data() {
    return {
      calendarModal: false,
      focusInput: false,
      checkId: true,
      research: {
        title: "",
        goal: "",
      },
      range: {
        start: null,
        end: null,
      },
      forwardSound: new Audio(forwardSound),
      backwardSound: new Audio(backwardSound),
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", (e) => {
      if (
        !e.target.closest("#calendar") &&
        !e.target.closest(".calendar-icon")
      ) {
        this.calendarModal = false;
      }
    });
  },
  mounted() {
    if (this.$route.params.id) {
      this.getResearchById();
    }

    document.addEventListener("click", (e) => {
      if (
        !e.target.closest("#calendar") &&
        !e.target.closest(".calendar-icon")
      ) {
        this.calendarModal = false;
      }
    });
  },
  methods: {
    handleCheck() {
      this.checkId = !this.checkId;
      if (this.checkId) {
        this.backwardSound.play();
      } else {
        this.forwardSound.play();
      }
    },
    async getResearchById() {
      const { data, errors } = await services.researches.getResearchById(
        this.$route.params.id
      );
      if (!errors) {
        this.research.title = data.atividade_pesquisa_titulo;
        this.range.start = new Date(data.atividade_pesquisa_data_inicio);
        this.range.end = new Date(data.atividade_pesquisa_data_fim);
        this.research.goal = data.atividade_pesquisa_meta.toString();
        this.checkId = data.atividade_pesquisa_identificacao === 1;
      }
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    async saveAndContinue() {
      let body = {
        atividade_pesquisa_titulo: this.research.title,
        atividade_pesquisa_data_inicio: this.range.start
          .toISOString()
          .split("T")[0],
        atividade_pesquisa_data_fim: this.range.end.toISOString().split("T")[0],
        atividade_pesquisa_meta: this.research.goal,
        atividade_pesquisa_identificacao: this.checkId ? 1 : 0,
      };

      if (!this.$route.params.id) {
        const { data, errors } = await services.researches.createResearch(body);
        if (!errors) {
          this.$router.push({
            name: "PesquisaForm",
            params: { id: data.atividade_pesquisa_id },
          });
        } else {
          this.$toast.error("Ocorreu um erro ao salvar a pesquisa!", {
            position: "top",
            duration: 3000,
          });
        }
      } else {
        const { errors } = await services.researches.updateResearch(
          this.$route.params.id,
          body
        );
        if (!errors) {
          this.$emit("next");
        } else {
          this.$toast.error("Ocorreu um erro ao salvar a pesquisa!", {
            position: "top",
            duration: 3000,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-section {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #131e3b;
  margin-bottom: 24px;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 0px;
}

.alert-length {
  color: #ff002e !important;
}

.date {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #131e3b;
}

.dates-container {
  background: #dddee3;
  border-radius: 5px;
  padding: 0px 6px;
  margin-bottom: 4px;
  margin-left: 16px;
}

.input-container {
  width: 152px;
}

.section {
  margin-top: 24px;
}

.remove-dates {
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.calender-icon {
  margin-bottom: 4px;
}

.alert-normal {
  position: absolute;

  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #131e3b;
  margin-left: 12px;

  svg {
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 2px;
  }
}

.btns-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 52px;
}

.filter-enter-active {
  animation: filter-in 0.4s;
}
.filter-leave-active {
  animation: filter-in 0.3s reverse;
}
@keyframes filter-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<template>
  <button :class="classe" @click="$emit('clicked')">
    <span class="btn-name">{{ name }}</span>
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    classe: {
      type: String,
      required: false,
      default: "primary-btn"
    },
  },
};
</script>

<style scoped>
.primary-btn {
  background: #003063;
  border-radius: 8px;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  height: 32px;
  color: #ffffff;
}
.primary-btn-negative {
  color: #003366 !important;
  background: white;
  border-radius: 8px;
  border: 1px solid #DDDEE3;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  height: 32px;
}

.btn-name {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
</style>

export default httpClient => ({

    getChatList: async (payload) => {
        const response = await httpClient.post('/zeladoria-chat/zeladorias', payload)
        let errors = null
  
        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },

      getChatMessages: async (zeladoria_id) => {
        const response = await httpClient.get(`/zeladoria-chat/zeladoria/${zeladoria_id}`)
        let errors = null
  
        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },

      filterChatByWord: async (payload) => {
        const response = await httpClient.post(`/zeladoria-chat/zeladorias`, payload)
        let errors = null
  
        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },

      moderarMensagem: async (payload) => {
        const response = await httpClient.put(`zeladoria-chat`, payload)
        let errors = null
  
        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },

      removerModeracao: async (payload) => {
        const response = await httpClient.put(`/zeladoria-chat-retirar`, payload)
        let errors = null
  
        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
      
      filterMessagesByWord: async (payload) => {
        const response = await httpClient.post(`zeladoria-chat/palavra-chave`, payload)
        let errors = null
  
        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
  })
<template>
  <td class="table-cell-header">
    <div class="header-wrapper" @click="toggleSort(field)">
      <div class="d-flex align-items-center">
        <span class="header-wrapper__label" :class="!sortable && 'label-delay'">
          {{ field.label }}
        </span>
        <div class="header-wrapper__filter" v-if="filterable">
          <svg
            @click="filterOpen = true"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" rx="4" fill="#003063" />
            <path
              d="M14.5 6.00005V7.00005H14L11 11.5V15H9V11.5L6 7.00005H5.5V6.00005H14.5Z"
              fill="white"
            />
          </svg>
          <div v-if="filterOpen" class="header-wrapper__filter-modal">
            <div class="header">
              <div class="title">{{ filterTitle }}</div>
              <svg
                @click="filterOpen = false"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99999 5.58599L11.95 0.635986L13.364 2.04999L8.41399 6.99999L13.364 11.95L11.95 13.364L6.99999 8.41399L2.04999 13.364L0.635986 11.95L5.58599 6.99999L0.635986 2.04999L2.04999 0.635986L6.99999 5.58599Z"
                  fill="#131E3B"
                />
              </svg>
            </div>
            <div class="filters">
              <Input
                style="padding: 24px 0 8px 0"
                placeholder="Buscar..."
                v-if="searchableFilter"
                v-model="search"
              />
              <ul>
                <li v-for="(filter, index) in filters" :key="index">
                  <input
                    :checked="filter.active"
                    @change="filter.active = !filter.active"
                    type="checkbox"
                    name=""
                    id=""
                  />
                  <label>{{ filter.label }}</label>
                </li>
              </ul>
            </div>
            <div class="filter-buttons">
              <Button
                @clicked="handleFilter"
                size="small"
                name="Filtrar resultados"
              />
              <Button
                @clicked="handleFilterCancel"
                size="small"
                type="white-outline"
                name="Cancelar"
              />
            </div>
          </div>
        </div>
        <span class="header-wrapper__sort" v-if="sortable">
          <span
            v-if="!sortDirection || sortDirection === 'asc'"
            class="material-icons pointer"
            :style="`color: ${sortDirection === 'asc' ? '#003366' : '#bbb'};`"
          >
            arrow_drop_up
          </span>
          <span
            :style="`color: ${sortDirection === 'desc' ? '#003366' : '#bbb'};`"
            v-if="sortDirection === 'desc'"
            class="material-icons pointer"
            >arrow_drop_down
          </span>
        </span>
      </div>
    </div>
  </td>
</template>
<script>
import Button from "../../components/Button.vue";
import Input from "../../components/form/Input.vue";
export default {
  name: "table-cell-header",
  components: {
    Button,
    Input,
  },
  props: {
    field: {
      default: null,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    filterTitle: {
      type: String,
      default: "Filtrar",
    },
    searchableFilter: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: null,
    },
  },
  emits: ["sort", "filter"],
  data() {
    return {
      direction: this.sortDirection,
      filterOpen: false,
      search: null,
    };
  },
  watch: {
    search: {
      handler: function (val) {
        this.handleSearch(val);
      },
      immediate: true,
    },
  },
  methods: {
    setDirection() {
      if (this.sortDirection === null) {
        this.direction = "asc";
        return this.direction;
      }
      if (this.sortDirection === "asc") {
        this.direction = "desc";
        return this.direction;
      }
      if (this.sortDirection === "desc") {
        this.direction = null;
        return this.direction;
      }
    },
    toggleSort(field) {
      if (this.sortable) {
        this.$emit("sort", {
          key: field.key,
          sortDirection: this.setDirection(),
        });
      }
    },
    handleFilter() {
      this.$emit("filter", { filters: this.filters, column: this.field.key });
    },
    handleFilterCancel() {
      this.filters.map((item) => {
        item.active = false;
      });
      this.filterOpen = false;
    },
    handleSearch(value) {
      this.filters.filter((item) => {
        item.label.includes(value);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  padding: 8px 0px;
}

.header-wrapper__filter {
  margin-left: 12px;
  position: relative;
  svg {
    cursor: pointer;
  }
}

.header-wrapper__filter-modal {
  position: absolute;
  right: 0px;
  top: 28px;
  z-index: 999;
  box-shadow: 0px 8px 12px rgba(160, 168, 189, 0.24);
  border-radius: 8px;
  background: #fff;
  width: 246px;
  padding: 24px;
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .filters {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 16px;
      li {
        width: 100%;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        color: #565e73;
        label {
          margin-left: 8px;
        }
      }
    }
  }
  .filter-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    button {
      margin: 4px 0;
    }
  }
}

.header-wrapper__label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  line-height: 156%;
  text-transform: uppercase;
  color: #565e73;
}

.label-delay {
  padding-top: 4px;
}

.header-wrapper__sort {
  margin-left: 8px;
  margin-top: 6px;

  span {
    margin: 0px;
    background: none;
    font-weight: bold;
    font-size: 1rem;
    line-height: 6%;
  }
}
</style>

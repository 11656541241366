<template>
  <button
    :disabled="disabled"
    class="button-base"
    :class="[type, size && `size-${size}`]"
    @click="$emit('clicked')"
    :style="[width ? `width: ${width}px` : '']"
  >
    <div class="d-flex align-center justify-content-center text">
      <div v-if="name" class="btn-name m-0 p-2" style="vertical-align: center">
        {{ name }}
      </div>
      <span class="material-icons pl-2 pr-2 m-0" v-if="icon">
        {{ icon }}
      </span>
    </div>
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.button-base {
  border-radius: 6px;
  border: none;
  outline: 0;
  padding: 4px 12px;
  text-align: center;
  line-height: 16px;
  font-weight: 600;
  font-size: 12px;
  font-family: "OpenSans-Regular";
  color: #fff;

  &:hover {
    opacity: 0.9;
  }
}

.btn-name {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
}

.text {
  white-space: nowrap;
}

.size-small {
  padding: 0px 8px;
  border-radius: 8px;
  line-height: 100%;
  height: fit-content;
}

.button-base:hover {
  filter: brightness(90%);
}

button:disabled {
  pointer-events: none;
  background-color: #c7c7c7;
}

.primary {
  border: 1px solid #003063;
  background: #003063;
  color: #ffffff;

  &:disabled {
    opacity: 0.8;
    border: 1px solid #c7c7c7;
  }
}

.bg-danger{
  background-color: red;
  
  border: 1px solid red;
  color: #fff;
}

.secondary,
.submit {
  background: #61b2e5;
  color: #ffffff;
}

.outline {
  background-color: transparent;
  border: 1px solid #dddee3;
  color: #565e73;
}

.outline-danger {
  background-color: transparent;
  border: 2px solid #d3341d;
  color: #d3341d;

  &:hover {
    opacity: 0.8;
  }
}

.delete {
  background-color: tomato;
  color: #ffffff;
}

.white {
  background-color: #ffffff;
  color: #131e3b;
  border: 2px solid #dddee3;
}

.white-outline {
  background-color: #ffffff;
  color: #131e3b;
  padding: 0px 12px;
}

.white:disabled {
  background-color: #ffffff !important;
  opacity: 0.6 !important;
}
</style>

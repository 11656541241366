<template>
  <div class="users-managers">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("usersSupervisors") }}</span>
    </h3>

    <transition name="bounce">
      <div v-if="!loadingData">
        <div class="d-flex justify-content-between ml-3">
          <div></div>
          <div class="pr-4">
            <Button
              class="mb-3"
              size="small"
              :name="$t('newUserSupervisor')"
              @clicked="onclickAdd()"
            />
          </div>
        </div>

        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in usuarios" :key="item.usuario_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="usuarios.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
      </div>

      <Loading v-else />
    </transition>

    <Modal v-show="addingModal" :width="400">
      <template v-slot:header>{{ $t("newUserSupervisor") }}</template>
      <template v-slot:body>
        <Input name="name" :label="$t('name')" v-model="newUserName" />
        <Input
          class="user-manager-email-input"
          name="name"
          :label="$t('email')"
          v-model="newUserMail"
        />
        <Input
          class="user-manager-email-input"
          name="name"
          :label="$t('phone')"
          v-model="newUserPhone"
          type="tel"
          @input="handlePhoneAdd"
          :maxlength="15"
        />
        <div class="label-container-tipo-fiscal">
          <span class="tipo-fiscal-label">Tipo de Fiscal</span>
        </div>
        <CSelect
          class="select-input-fiscal"
          @change="change()"
          :options="fiscalTipos"
          :value.sync="fiscalTipo"
        >
        </CSelect>
        <Input
          name="name"
          :label="$t('password')"
          type="password"
          v-model="newUserPassword"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            type="outline"
            @clicked="addingModal = false"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('save')" @clicked="addUser" />
        </div>
      </template>
    </Modal>

    <Modal v-show="editModal">
      <template v-slot:header>Editar Usuário</template>
      <template v-slot:body>
        <Input :label="$t('name')" v-model="userNameForEdit" />
        <Input
          class="user-manager-email-input"
          :label="$t('email')"
          v-model="userMailForEdit"
        />
        <Input
          class="user-manager-email-input"
          :label="$t('phone')"
          v-model="userPhoneForEdit"
          type="tel"
          @input="handlePhoneEdit"
          maxlength="15"
        />
        <div class="row mb-3 mt-4 pl-1">
          <div class="col-2">
            <CustomSwitch
              :selected="isUserActive"
              @checkedSync="isUserActive = !isUserActive"
            />
          </div>
          <div class="col-6 d-flex align-items-center">
            <h4 style="color: #747474; font-size: 16px">
              {{ $t("activeUserManager") }}
            </h4>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            type="outline"
            @clicked="editModal = false"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('save')" @clicked="editUser" />
        </div>
      </template>
    </Modal>

    <Modal
      style="color: #747474"
      :title="$t('deleteUser')"
      v-show="deleteModal"
    >
      <template v-slot:header>{{ $t("deleteUser") }}</template>
      <template v-slot:body>
        {{ $t("confirmDeleteUser") }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <Button
            type="outline"
            class="w-50 mr-2"
            :name="$t('cancel')"
            @clicked="deleteModal = false"
          />
          <Button
            class="w-50"
            :name="$t('save')"
            type="delete"
            @clicked="deleteUser"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

  <script>
import services from "../../services";
import { successToast } from "../../utils/toast";

import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Input from "../../components/form/Input.vue";
import Modal from "../../components/Modal.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import TableCell from "../../components/table/TableCell.vue";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";

export default {
  name: "CadastroUsuario",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Loading,
    BackArrow,
    Button,
    CondensedDropdown,
    Modal,
    Input,
    CustomSwitch,
  },
  data() {
    return {
      fields: [
        {
          key: "usuario_nome",
          label: this.$t("name"),
          _style: "min-width:200px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_email",
          label: this.$t("email"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_telefone",
          label: this.$t("phone"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladores",
          label: "",
          _style: "width:1%",
          sortable: false,
          sortDirection: null,
        },
      ],
      phone: null,
      usuarios: [],
      addingModal: false,
      newUserName: null,
      userIdForDelete: null,
      newUserMail: null,
      newUserPhone: null,
      newUserPhonePayload: null,
      newUserPassword: null,
      editModal: false,
      userNameForEdit: null,
      userMailForEdit: null,
      userPhoneForEdit: null,
      userPhoneForEditPayload: null,
      userIdForEdit: 0,
      deleteModal: false,
      isUserActive: true,
      institutionNameForDelete: null,
      institutionIdForDelete: 0,
      loadingData: true,
      usersTypes: [],
      userTypeForCreation: null,
      userTypeForEdit: null,
      searchString: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      fiscalTipos: [],
      fiscalTipo: 1,
    };
  },
  mounted: async function () {
    //await this.verifyUserRole();
    await this.getAllSupervisors();
    await this.getSupervisorTypes();
    this.loadingData = false;
  },

  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "delete") {
        this.handleDeleteClick(item);
      }
      if (value === "edit") {
        this.handleEditClick(item);
      }
    },
    optionsByResearch() {
      return [
        { label: "Editar", value: "edit" },
        //{ label: "Excluir", value: "delete" },
      ];
    },

    async handlePhoneEdit(value) {
      if (!value) return "";
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d)(\d{4})$/, "$1-$2");
      this.userPhoneForEdit = value;
      this.userPhoneForEditPayload = this.userPhoneForEdit.replace(/\D/g, "");
    },

    async handlePhoneAdd(value) {
      if (!value) return "";
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d)(\d{4})$/, "$1-$2");
      this.newUserPhone = value;
      this.newUserPhonePayload = this.newUserPhone.replace(/\D/g, "");
    },

    handleEditClick(item) {
      this.userIdForEdit = item.usuario_id;
      this.userNameForEdit = item.usuario_nome;
      this.userMailForEdit = item.usuario_email;
      this.userPhoneForEdit = item.usuario_telefone;
      item.usuario_ativo === 1
        ? (this.isUserActive = true)
        : (this.isUserActive = false);
      this.editModal = true;
    },
    handleDeleteClick(item) {
      this.userIdForDelete = item.usuario_id;
      this.deleteModal = true;
    },
    onclickAdd() {
      this.newUserName = null;
      this.newUserMail = null;
      this.newUserPassword = null;
      this.newUserPhone = null;
      this.newUserPhonePayload = null;
      this.addingModal = true;
    },
    redirectToKeepingSectors(item) {
      this.$router.push(`/monitor/zeladorias/${item.usuario_id}`);
    },
    async addUser() {
      const { errors } = await services.UsersSupervisors.addUserSupervisor({
        usuario_nome: this.newUserName,
        usuario_email: this.newUserMail.toLowerCase(),
        usuario_fiscal_senha: this.newUserPassword,
        usuario_telefone: this.newUserPhonePayload,
        fiscal_tipo_id: this.fiscalTipo,
      });
      if (!errors) {
        await this.getAllSupervisors();
        this.newUserName = null;
        this.newUserMail = null;
        this.newUserPassword = null;
        this.newUserPhone = null;
        successToast(this.$t("successAddUser"));
        this.addingModal = false;
      }
    },
    async deleteUser() {
      const { errors } = await services.UsersSupervisors.deleteUserSupervisor({
        userId: this.userIdForDelete,
      });
      if (!errors) {
        await this.getAllSupervisors();
        this.deleteModal = false;
        successToast(this.$t("successDeleteUser"));
      }
    },
    async editUser() {
      const { errors } = await services.UsersSupervisors.editUserSupervisor({
        usuario_id: this.userIdForEdit,
        usuario_nome: this.userNameForEdit,
        usuario_email: this.userMailForEdit.toLowerCase(),
        usuario_ativo: this.isUserActive ? 1 : 0,
        usuario_telefone: this.userPhoneForEditPayload,
      });
      if (!errors) {
        await this.getAllSupervisors();
        this.userNameForEdit = null;
        this.userMailForEdit = null;
        this.userPhoneForEdit = null;
        this.userPhoneForEditPayload = null;
        this.userIdForEdit = 0;
        successToast(this.$t("successEditUserManager"));
        this.editModal = false;
      }
    },
    async getAllSupervisors() {
      const { data, errors } =
        await services.UsersSupervisors.getUsersSupervisorsNew();
      if (!errors) {
        this.usuarios = data;
      }
    },
    async getSupervisorTypes() {
      const { data, errors } =
        await services.UsersSupervisors.getSupervisorTypes();
      if (!errors) {
        let tipo = data;
        tipo.map((item) => {
          this.fiscalTipos.push({
            label: item.fiscal_tipo_descricao,
            value: item.fiscal_tipo_id,
          });
        });
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
.users-managers {
  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
}
</style>
  <style>
.user-manager-email-input.input-wrapper > .input {
  text-transform: lowercase !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.select-input-fiscal {
  margin-top: 16px;
}
.tipo-fiscal-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  color: #838998;
  margin-top: 8px;
}
.label-container-tipo-fiscal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #747474;
  margin-top: 6px;
}
</style>

<template>
  <label class="switch">
    <input
      :disabled="disabled"
      type="checkbox"
      v-model="selectedData"
      @change="changeSwitch($event)"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selected() {
      this.$nextTick(() => {
        this.selectedData = this.selected;
      });
    },
  },
  data() {
    return {
      selectedData: this.selected,
    };
  },
  mounted() {
    setTimeout(() => {
      this.selectedData = this.selected;
    }, 100);
  },
  methods: {
    changeSwitch() {
      this.$nextTick(() => {
        this.selectedData = !this.selected;
        this.$emit("checkedSync", this.selectedData);
      });
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #838998;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input[type="checkbox"]:disabled + .slider.round {
  background-color: #838998;
  pointer-events: none;
}

input:checked + .slider {
  background-color: #00b1eb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00b1eb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

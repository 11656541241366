export default httpClient => ({

  getContractList: async () => {
    const response = await httpClient.get('/keeper-contract')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  putContracts: async (data) => {
    const response = await httpClient.put('/keeper-contract', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getContractTemplates: async () => {
    const response = await httpClient.get('/keeper-contract/1 ')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getCancellationTemplates: async () => {
    const response = await httpClient.get('/keeper-contract/2')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getContractLogs: async () => {
    const response = await httpClient.get(`/keeper-contract-log`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getContractLogDetails: async (contractId) => {
    const response = await httpClient.get(`/keeper-contract-log/${contractId}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getContractTags: async () => {
    const response = await httpClient.get(`/keeper-contract-tag`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  }
})
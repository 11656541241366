<template>
  <div class="candidates p-5">
    <h3 class="intern-title mb-4 pb-2">
      <back-arrow />
      <span class="pagetitle pl-2">Analytics de Monitores</span>
    </h3>

    <div class="graphBox">
      <span class="graphTitle">Eficiência dos monitores</span>
      <div class="eficiencia-card-box">
        <div
          v-for="monitor in monitores"
          :key="monitor.usuario_gestor_id"
          class="card-analytics-monitor"
        >
          <div class="card-monitor-title-box">
            <span class="card-monitor-title">{{
              monitor.usuario_gestor_nome
            }}</span>
            <img
              @click="
                setMonitor(
                  monitor.usuario_gestor_id,
                  monitor.usuario_gestor_nome,
                  monitor.usuario_gestor_email
                )
              "
              src="../../assets/img/grafico-icone.png"
              alt="icone-graficos"
              class="icone-grafico-monitor"
            />
          </div>
          <span class="monitor-eficiencia"
            >{{ monitor.avaliacao_prefeitura_media }}%</span
          >
        </div>
      </div>
    </div>

    <span class="graphTitle">Indicadores</span>
    <div>
      <Table :fields="fields" :hasActions="true">
        <TableRow v-for="monitor in monitores" :key="monitor.usuario_gestor_id">
          <TableCell
            v-for="field in fields"
            :key="field.key"
            :name="field.key"
            :style="field._style"
            :sort-direction="field.sortDirection"
          >
            <template v-slot:[field.key]>
              <span class="table-row-text">{{
                monitores[field.key] || "--"
              }}</span>
            </template>
            <template v-slot:monitor>
              <div>
                {{ monitor.usuario_gestor_nome }}
              </div>
            </template>
            <template v-slot:areas>
              <div>
                {{ monitor.qtd_zeladoria }}
              </div>
            </template>
            <template v-slot:livre>
              <div>
                {{ monitor.qtd_zeladoria_livre }}
              </div>
            </template>
            <template v-slot:eficiencia_area>
              {{
                (
                  (monitor.qtd_zeladoria_ativa / monitor.qtd_zeladoria) *
                  100
                ).toFixed(2)
              }}%
            </template>
            <template v-slot:ativas>
              {{ monitor.qtd_zeladoria_ativa }}
            </template>
            <template v-slot:eficiencia_medicao>
              {{ monitor.avaliacao_prefeitura_media }}%
            </template>
            <template v-slot:eficiencia_monitor>
              {{ monitor.avaliacao_monitor_media }}%
            </template>
          </TableCell>
        </TableRow>
        <template v-if="monitores.length === 0" v-slot:no-results>
          <NoResults :message="$t('noResults')" />
        </template>
      </Table>
    </div>
    <Loading v-if="loadingData" />
  </div>
</template>

  <script>
import services from "../../services";
import { formatDate } from "../../utils/date";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Table from "../../components/table/Table.vue";
import NoResults from "../../components/table/NoResults.vue";

export default {
  components: {
    BackArrow,
    Loading,
    Table,
    TableRow,
    TableCell,
    NoResults,
  },

  data() {
    return {
      monitores: [],
      fields: [
        {
          key: "monitor",
          label: "Monitor",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "areas",
          label: "Areas",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "livre",
          label: "Livre",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "eficiencia_area",
          label: "Eficiência Área",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ativas",
          label: "Ativas",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "eficiencia_medicao",
          label: "Eficiência Medição",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "eficiencia_monitor",
          label: "Eficiência Monitor",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {},
      ],
      loading: false,
      loadingData: false,
      formatDate,
    };
  },
  mounted() {
    this.getAvaliacaoMonitores();
  },

  methods: {
    async getAvaliacaoMonitores() {
      const { data, errors } = await services.analytics.getAvaliacaoMonitor();
      if (!errors) {
        this.monitores = data;
      }
    },

    async getMonitores() {
      const { data, errors } = await services.analytics.getMonitores();
      if (!errors) {
        console.log(data);
      }
    },
    async setMonitor(id, nome, email) {
      this.$store.dispatch("global/setNomeMonitor", {
        nomeMonitor: nome,
      });
      this.$store.dispatch("global/setEmailMonitor", {
        emailMonitor: email.toLowerCase(),
      });
      this.$router.push({ name: "AnalyticsMonitor", params: { id: id } });
    },
  },
};
</script>

  <style lang="scss" scoped>
.form-class {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lista {
  width: 80%;
}
.button {
  color: white;
  padding: 8px 24px;
  background: #003063;
  border-radius: 8px;
  margin: 0px 8px;
  cursor: pointer;
}
.grafico {
  height: 500px;
}
.position-relative {
  position: relative;
}
.selecionar-ano {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  width: 260px;
  height: 447px;
  border: 1px solid lightgray;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 10;
}
.flex-row {
  display: flex;
  flex-direction: column;
}
.coluna {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.duas-colunas {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.display-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filtrar {
  font-weight: bolder;
  color: white;
  padding: 5px 12px;
  background: #61b2e5;
  border: none;
  border-radius: 8px;
}
.limpar-filtro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 8px;
  width: 100%;
}
.nome-mes {
  color: #565e73;
  font-size: 14px;
}
.checkbox-mes {
  background: #c6c9d0;
  width: 15px;
  height: 15px;
  margin: 0px 8px;
}
fieldset {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.titulo-select {
  margin-bottom: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #565e73;
}
.selecionar-mes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  width: 260px;
  height: 447px;
  border: 1px solid lightgray;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 10;
}
label {
  margin: 0px;
  cursor: pointer;
}
.selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 8px 16px;
  width: 152px;
  height: 32px;
  background: #003063;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  margin: 0px 8px;
}
.display {
  display: none;
}
.titulo-tab {
  margin: 32px 0px;
}

.candidates {
  background-color: #fff;
  border-radius: 8px;
  padding-top: 40px !important;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}

.actions {
  cursor: pointer;
}

.tabs {
  display: flex;
  color: #838998;
}

.tab {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
  color: #838998;
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.expired {
  color: tomato;
}
.form {
  input {
    width: 170px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    color: #747474;
  }
  label {
    margin-right: 10px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.atividades {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 40px;
  border: 1px solid #dddee3;
  border-radius: 16px;
  margin: 16px 0px;
}
.atividade {
  display: flex;
  flex-direction: row;
  margin: 0px 5px;
  align-items: center;
}
.atividade-switches {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px;
  gap: 40px;
}
.atividade-nome {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #565e73;
  margin-left: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.atividade-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #565e73;
}
.selecionar-todos {
  margin-right: 64px;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 24px;
}
#alert1 {
  color: red;
  font-size: 14px;
}
#alert2 {
  color: red;
  font-size: 14px;
}
#alert3 {
  color: red;
  font-size: 14px;
}
#alert4 {
  color: red;
  font-size: 14px;
}
#alert5 {
  color: red;
  font-size: 14px;
}
.hidden {
  display: none;
}
.name-box {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  border-bottom: 1px solid #dddee3;
}
.subtitle-icon {
  width: 12px;
  margin-right: 10px;
}
.nome-monitor {
  color: #565e73;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 14px;
}
.graphTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #565e73;
  margin: 32px 0px;
}
.graphBox {
  display: flex;
  flex-direction: column;
}
.card-analytics-monitor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border: 2px solid lightgray;
  border-radius: 12px;
  width: 254px;
  height: 100px;
  margin: 14px;
}
.card-monitor-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #838998;
}
.card-monitor-title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.monitor-eficiencia {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #131e3b;
}
.eficiencia-card-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 56px;
}
.icone-grafico-monitor {
  width: 20px;
  cursor: pointer;
}
</style>

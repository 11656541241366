<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    handleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<template>
  <div class="card-wrapper">
    <details>
      <summary @click="handleOpen">
        {{ title }}
        <span class="arrow">
          <svg
            v-if="open"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99999 3.02366L1.87499 7.14866L0.696655 5.97033L5.99999 0.666992L11.3033 5.97033L10.125 7.14866L5.99999 3.02366Z"
              fill="black"
            />
          </svg>
          <svg
            v-else
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.99999 3.78145L8.29999 0.481445L9.24266 1.42411L4.99999 5.66678L0.757324 1.42411L1.69999 0.481445L4.99999 3.78145Z"
              fill="black"
            />
          </svg>
        </span>
      </summary>
      <div class="content mt-3">
        <slot name="content"></slot>
      </div>
    </details>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  details > summary {
    list-style: none;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  details {
    width: 100%;
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    border: 2px solid #dddee3;
    background: #fff;
    summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #29334e;
      font-size: 16px;
      font-family: "Open Sans";
      font-weight: 600;
      .arrow {
        cursor: pointer;
        pointer-events: unset;
        display: flex;
        justify-content: center;

        padding: 8px;
        border-radius: 3px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: "Open Sans";
      color: #565e73;
      border: 1px solid #dddee3;
      border-radius: 6px;
      padding: 8px 12px;
    }
  }
}
</style>

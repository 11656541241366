<template>
  <div class="tags">
    <div class="page-header">
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav">{{ $t("tags") }}</span>
      </h3>
      <div class="pr-4">
        <Button
          class="mb-4"
          :name="$t('newTag')"
          size="small"
          @clicked="
            (addingModal = true),
              (valueInstituitions = []),
              (institutionRequest = [])
          "
        />
      </div>
    </div>
    <transition name="bounce">
      <div v-if="!loadingData">
        <div>
          <div
            class="d-flex justify-content-between align-center pl-3 mb-3 mt-3"
          >
            <div class="w-25">
              <Input
                v-model="searchString"
                :placeholder="$t('searchTag')"
                :search="true"
              />
            </div>
          </div>
          <Table :fields="fields" :hasActions="true" @sort="handleSort">
            <TableRow v-for="item in tags" :key="item.tag_id">
              <TableCell
                v-for="field in fields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  <span class="table-row-text">{{ item[field.key] }}</span>
                </template>
              </TableCell>
              <TableCell class="d-flex justify-content-end">
                <CondensedDropdown
                  class="mt-1"
                  @dropdown-click="
                    (ev) => handleCondensedDropdownClick(ev, item)
                  "
                  :options="optionsByResearch()"
                />
              </TableCell>
            </TableRow>
            <template v-if="tags.length === 0" v-slot:no-results>
              <NoResults :message="$t('noResults')" />
            </template>
          </Table>
          <infinite-loading @infinite="infiniteHandler">
            <div slot="spinner">
              <Loading />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </div>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <Modal v-show="addingModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label">{{ $t("newTag") }}</span></template
      >
      <template v-slot:body>
        <p class="modal-label-tags">{{ $t("relatedInstitution") }}</p>
        <multiselect
          v-model="valueInstituitions"
          :noOptions="$t('emptyList')"
          selectLabel=""
          :deselectLabel="$t('remove')"
          :selectedLabel="$t('selected')"
          :options="institutions"
          track-by="name"
          :placeholder="$t('selectAInstitution')"
          :preselect-first="true"
          :multiple="true"
          label="name"
        ></multiselect>
        <Input
          name="name"
          :label="$t('description')"
          v-model="newTagName"
          :maxlength="30"
          :count="true"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            @clicked="addingModal = false"
            type="outline"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('confirm')" @clicked="addTag" />
        </div>
      </template>
    </Modal>

    <Modal v-show="editModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label">{{ $t("editTag") }}</span></template
      >
      <template v-slot:body>
        <p class="modal-label-tags">{{ $t("relatedInstitution") }}</p>
        <multiselect
          v-model="valueInstituitionsEdit"
          @remove="removeInstitutionTag"
          :noOptions="$t('emptyList')"
          selectLabel=""
          :deselectLabel="$t('remove')"
          :selectedLabel="$t('selected')"
          :options="institutions"
          track-by="name"
          :placeholder="$t('selectAInstitution')"
          :preselect-first="true"
          :multiple="true"
          label="name"
        ></multiselect>
        <Input
          name="name"
          :label="$t('description')"
          v-model="tagNameForEdit"
          :maxlength="30"
          :count="true"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            @clicked="editModal = false"
            type="outline"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('confirm')" @clicked="editTag" />
        </div>
      </template>
    </Modal>

    <Modal v-show="deleteModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label">{{ $t("deleteTag") }}</span></template
      >
      <template v-slot:body>
        <p class="delete-confirmation">
          {{ $t("confirmDeleteTag") + " " + tagNameForDelete }}?
        </p>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            @clicked="deleteModal = false"
            type="outline"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('confirm')" @clicked="deleteTag" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import services from "../../services";
import { successToast, validationToast } from "../../utils/toast";
import { debounce } from "../../utils/debounce";

import Multiselect from "vue-multiselect";
import Button from "../../components/Button.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Table from "../../components/table/Table.vue";
import BackArrow from "../../components/BackArrow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Modal from "../../components/Modal.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import Input from "../../components/form/Input.vue";

export default {
  name: "Tags",
  components: {
    Multiselect,
    Button,
    Loading,
    CondensedDropdown,
    Table,
    TableRow,
    TableCell,
    NoResults,
    BackArrow,
    Input,
    Modal,
  },
  watch: {
    valueInstituitions: function (value) {
      value.map((item) => {
        this.institutionRequest.push(item);
      });
    },
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.requestTags();
    }, 500),
  },
  data() {
    return {
      fields: [
        {
          key: "tag_descricao",
          label: this.$t("description"),
          sortable: false,
          sortDirection: null,
        },
      ],
      tags: [],
      newTagName: "",
      addingModal: false,
      tagNameForEdit: "",
      tagIdForEdit: 0,
      editModal: false,
      tagNameForDelete: "",
      tagIdForDelete: 0,
      deleteModal: false,
      institutions: [],
      valueInstituitions: [],
      valueInstituitionsEdit: [],
      tagsInstitutions: [],
      institutionRequest: [],
      searchString: null,
      loadingData: true,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
    };
  },
  mounted: async function () {
    await this.verifyUserRole();
    await this.initAllData();
    this.loadingData = false;
  },
  methods: {
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.requestTags();
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "executions") {
        this.redirectToList(item);
      }
      if (value === "delete") {
        this.handleDeleteClick(item);
      }
      if (value === "edit") {
        this.handleEditClick(item);
      }
    },
    optionsByResearch() {
      return [
        { label: "Execuções", value: "executions" },
        { label: "Editar", value: "edit" },
        { label: "Excluir", value: "delete" },
      ];
    },
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.tags.getTagsDataTable(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item, id) => {
            return { ...item, id };
          });
          temp.forEach((item) => {
            this.tags.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    async initAllData() {
      await this.requestTags();
      await this.requestInstitutions();
      await this.requestTagsIntitution();
    },
    async requestTags() {
      const { data, errors } = await services.tags.getTagsDataTable(
        this.pagination
      );
      if (!errors) {
        this.tags = data.map((item, id) => {
          return { ...item, id };
        });
      }
    },
    async requestInstitutions() {
      const { data, errors } = await services.institutions.getInstitutions();
      if (!errors) {
        this.institutions = data.map((item) => {
          return {
            name: item.instituicao_descricao,
            id: item.instituicao_id,
          };
        });
      }
    },
    async requestTagsIntitution() {
      const { data, errors } = await services.tags.getTagsInstitutions();
      if (!errors) this.tagsInstitutions = data;
    },
    async removeInstitutionTag(item) {
      const { errors } = await services.institutions.deleteInstitutionTag({
        tagId: this.tagIdForEdit,
        institutionId: item.id,
      });
      if (!errors) {
        successToast(this.$t("successRemoveLink"));
        this.initAllData();
      }
    },
    handleEditClick(item) {
      this.valueInstituitionsEdit = [];
      this.tagNameForEdit = item.tag_descricao;
      this.tagIdForEdit = item.tag_id;
      this.tagsInstitutions.map((tag) => {
        if (tag.tag_id === item.tag_id) {
          this.valueInstituitionsEdit.push({
            name: tag.instituicao_descricao,
            id: tag.instituicao_id,
          });
        }
      });
      this.editModal = true;
    },
    handleDeleteClick(item) {
      this.tagNameForDelete = item.tag_descricao;
      this.tagIdForDelete = item.tag_id;
      this.deleteModal = true;
    },
    async addTag() {
      const { data, errors } = await services.tags.postTag({
        tag_descricao: this.newTagName,
      });
      if (!errors) {
        successToast(this.$t("successAddTag"));
        await this.linkTagInstitution(this.institutionRequest, data.tag_id);
        this.newTagName = "";
        this.valueInstituitions = [];
        this.initAllData();
        this.addingModal = false;
      } else {
        const errorMessage = errors.statusText.erro || errors.statusText.error;
        validationToast(errorMessage);
      }
    },
    async linkTagInstitution(institutionsSelected, tagId) {
      if (institutionsSelected.length > 0) {
        institutionsSelected.map(async (item) => {
          const { errors } = await services.tags.linkTagInstitution({
            tag_id: tagId,
            instituicao_id: item.id,
          });
          if (!errors) successToast(this.$t("successAddLink"));
        });
      }
    },
    redirectToList(item) {
      this.$router.push(`/execucoes/tag-${item.tag_id}`);
    },
    async editTag() {
      const { errors } = await services.tags.putTag({
        tag_id: this.tagIdForEdit,
        tag_descricao: this.tagNameForEdit,
      });
      if (!errors) {
        successToast(this.$t("successEditTag"));
        await this.linkTagInstitution(
          this.valueInstituitionsEdit,
          this.tagIdForEdit
        );
        this.tagNameForEdit = "";
        this.tagIdForEdit = 0;
        this.initAllData();
        this.editModal = false;
      }
    },
    async deleteTag() {
      const { errors } = await services.tags.deleteTag({
        tagId: this.tagIdForDelete,
      });
      if (!errors) {
        this.tagNameForDelete = "";
        this.tagIdForDelete = 0;
        successToast(this.$t("successDeleteTag"));
        this.initAllData();
        this.deleteModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  background-color: #fff;
  border-radius: 8px;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;

    div {
      cursor: pointer;
    }
  }
}

.modal-label-tags {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 86%;
  color: #838998;
  margin-top: 4px;
}
</style>

<template>
  <div class="user-card">
    <GmapMap
      class="map"
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      style="height: 100%; width: 100%"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        mapTypeId: mapType,
        gestureHandling: 'greedy',
        fullscreenControl: true,
        disableDefaultUi: true,
        mapTypeId: mapType,
      }"
    >
      <div
        v-for="polygon of polygonSector"
        :key="'polygonsKeepingSector' + polygon.id"
      >
        <GmapPolygon
          ref="polygon"
          :paths="polygon.latlngs"
          :options="polygon.options"
        />
      </div>
      <GmapPolyline
        ref="polyline"
        v-if="polyline !== null"
        :path="polyline.latlngs"
        :options="polyline.options"
      />
    </GmapMap>
  </div>
</template>

<script>
import { formatForGoogleMaps } from "../../utils/geoMaps";
import { formatDateToLocale } from "../../utils/date";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },

  mounted: async function () {
    if (this.user.zeladoria_limite && this.user.zeladoria_limite.length > 0) {
      this.mountPolygon(this.user.zeladoria_limite[0]);
    }
  },

  data() {
    return {
      polygonSector: [],
      zoom: 4,
      bounds: [],
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "roadmap",
      center: {
        lat: -25.43432,
        lng: -49.2638,
      },
      polyline: null,
      formatDateToLocale,
    };
  },
  methods: {
    formatDateToBR(date) {
      if (date) {
        let dateFormat = date.split("T");
        let dateSplit = dateFormat[0].split("-");
        let dateBR = dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
        return dateBR;
      }
      return "-";
    },
    mountPolygon(poligono) {
      if (poligono !== "") {
        this.polygonSector = [];
        const coordinatesJSONParse = poligono;
        let bairro = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse),
          options: {
            strokeColor: "#348534",
            fillColor: "#20d820",
          },
          id: 1,
        };
        bairro.latlngs.map((item) => {
          this.bounds.push(item);
        });

        this.polygonSector.push(bairro);
      }
      this.fitBoundsGoogle();
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  display: flex;
  width: 100%;
  height: 75vh;
}
</style>

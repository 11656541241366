export default httpClient => ({
  importAmaUserData: async () => {
    const response = await httpClient.post('/import', {})
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  importAmaKeepingSectors: async () => {
    const response = await httpClient.post('/import/keeping-sector', {})
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  cityData: async (data) => {
    const response = await httpClient.get('/config/100', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getLegalText: async (code) => {
    const response = await httpClient.get(`/config/${code}`)
    let errors = null
    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  }
})

<template>
  <td class="table-cell-text">
    <slot v-if="name" :name="name"></slot>
    <slot v-else></slot>
  </td>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.table-cell-text {
  text-align: left;
  border-top-color: #f0f0f0 !important;
  border-bottom-color: #dddee3 !important;
  vertical-align: middle !important;

  font-family: "OpenSans-Regular" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 156% !important;
  color: #565e73 !important;

  height: 64px !important;
}
</style>

<template>
  <div class="subcontractors">
    <div class="page-header">
      <h3 class="ml-3 intern-title">
        <back-arrow />
        <span class="page-nav">{{ $t("associations") }}</span>
      </h3>
      <Button
        :name="$t('newAssociation')"
        @clicked="redirectToNewSubcontractor()"
        size="small"
        class="mb-4"
      />
    </div>

    <transition name="bounce">
      <div v-if="!loadingData">
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in subcontractors" :key="item.subcontratada_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="subcontractors.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
      </div>
    </transition>
    <Loading v-if="loadingData" />
  </div>
</template>

<script>
import services from "../../services";

import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import BackArrow from "../../components/BackArrow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

export default {
  name: "Subcontractors",
  components: {
    Table,
    TableRow,
    TableCell,
    CondensedDropdown,
    NoResults,
    Button,
    BackArrow,
    Loading,
  },
  data() {
    return {
      fields: [
        {
          key: "subcontratada_descricao",
          label: this.$t("description"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      subcontractors: [],
      deleteModal: false,
      subcontractorId: 0,
      loadingData: true,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
    };
  },
  mounted: async function () {
    await this.verifyUserRole();
    await this.requestSubcontractors();
    this.loadingData = false;
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    handleEditClick(item) {
      this.$router.push(`/associacao/${item.subcontratada_id}`);
    },
    redirectToNewSubcontractor() {
      this.$router.push("/associacao");
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "users") {
        this.handleAddUsers(item);
      }
      if (value === "edit") {
        this.handleEditClick(item);
      }
    },
    optionsByResearch() {
      return [
        { label: "Zeladores", value: "users" },
        { label: "Editar", value: "edit" },
      ];
    },
    handleAddUsers(item) {
      this.$router.push(`/associacao/zeladores/${item.subcontratada_id}`);
    },
    async requestSubcontractors() {
      const { data, errors } =
        await services.subcontractors.getSubcontractorsNew(this.pagination);
      if (!errors) {
        this.subcontractors = data.map((item, id) => {
          return { ...item, id };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subcontractors {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;

    div {
      cursor: pointer;
    }
  }
}
</style>

import Vue from "vue";

export function validationToast(err) {
  Vue.$toast.open({
    message: `${err}`,
    position: "top",
    type: "error",
  });
}

export function infoToast(msg) {
  Vue.$toast.open({
    message: `${msg}`,
    position: "top",
    type: "info",
  });
}

export function successToast(msg) {
  Vue.$toast.open({
    message: `${msg}`,
    position: "top",
    type: "success",
  });
}

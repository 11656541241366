<template>
  <div>
    <div class="print-container bg-white">
      <div v-if="!loadingData">
        <header class="d-flex justify-content-between mb-5">
          <div class="d-flex align-items-center">
            <div class="mr-4 pointer" @click="goToHome">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.828 7.00066H16V9.00066H3.828L9.192 14.3647L7.778 15.7787L0 8.00066L7.778 0.222656L9.192 1.63666L3.828 7.00066Z"
                  fill="#565E73"
                />
              </svg>
            </div>
            <div>
              <div class="page-title">
                {{ $t("activitiesByInstitutionReport") }}
              </div>
            </div>
          </div>
          <div>
            <span @click="print" class="material-icons print-button ml-4">
              print
            </span>
          </div>
        </header>

        <div class="form-wrapper mt-3 mb-5">
          <div class="mb-3 engineers-title">Filtros</div>
          <form class="d-flex align-center form row" @submit="requestReport">
            <div class="col-3">
              <multiselect
                v-model="selectedInstitutions"
                noOptions="Lista vazia"
                selectLabel=""
                deselectLabel="remover"
                selectedLabel="Selecionado"
                :options="institutions"
                @input="selectInstitution"
                track-by="name"
                placeholder="Instituições"
                :multiple="false"
                label="name"
              ></multiselect>
            </div>
            <div class="col-3">
              <multiselect
                v-model="selectedActivities"
                :loading="loadingActivities"
                noOptions="Lista vazia"
                selectLabel=""
                deselectLabel="remover"
                selectedLabel="Selecionado"
                @input="selectActivities"
                :options="activities"
                track-by="name"
                placeholder="Atividades"
                :multiple="true"
                label="name"
                :disabled="activities.length === 0 || loadingActivities"
              ></multiselect>
            </div>
            <div class="col-3">
              <multiselect
                v-model="selectedTags"
                :loading="loadingTags"
                noOptions="Lista vazia"
                selectLabel=""
                deselectLabel="remover"
                selectedLabel="Selecionado"
                :options="tags"
                track-by="name"
                placeholder="Tags"
                :multiple="true"
                label="name"
                :disabled="!selectedActivities.length || loadingTags"
              ></multiselect>
            </div>
            <div class="col d-flex align-items-center">
              <div
                class="d-flex align-items-center position-relative"
                id="calendar"
              >
                <span
                  @click="calendarModal = !calendarModal"
                  class="new-icons pointer btn-relatory mr-3 mb-1"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="-1 1 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <div class="absolute-box w-50" v-if="calendarModal">
                  <v-date-picker
                    v-model="range"
                    is-range
                    @input="calendarModal = false"
                  />
                </div>
              </div>
              <Button
                size="small"
                class="btn-position"
                name="Gerar Relatório"
                :disabled="!this.range.start && !this.range.end"
              />
            </div>
          </form>

          <div class="d-flex align-items-center mt-4">
            <div v-if="range.start && range.end" class="range-label mr-3">
              <div class="d-flex align-items-center">
                {{ formatDate(range.start) }} - {{ formatDate(range.end) }}
                <span
                  @click="(range.start = null), (range.end = null)"
                  class="pointer ml-2 close-icon"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.99992 13.6673C3.31792 13.6673 0.333252 10.6827 0.333252 7.00065C0.333252 3.31865 3.31792 0.333984 6.99992 0.333984C10.6819 0.333984 13.6666 3.31865 13.6666 7.00065C13.6666 10.6827 10.6819 13.6673 6.99992 13.6673ZM6.99992 6.05798L5.11459 4.17198L4.17125 5.11532L6.05725 7.00065L4.17125 8.88598L5.11459 9.82932L6.99992 7.94332L8.88525 9.82932L9.82859 8.88598L7.94258 7.00065L9.82859 5.11532L8.88525 4.17198L6.99992 6.05798Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              class="clean-filters"
              @click="
                (selectedInstitutions = []),
                  (selectedActivities = []),
                  (selectedTags = [])
              "
            >
              LIMPAR FILTROS
            </div>
          </div>
        </div>

        <section id="content">
          <div class="current-dates">
            Período selecionado: {{ formatDate(range.start) }} -
            {{ formatDate(range.end) }}
          </div>
          <div class="mt-4">
            <table v-if="measurements.length">
              <thead>
                <tr>
                  <th>INSTITUIÇÃO</th>
                  <th>
                    ATIVIDADE
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('atividade_nome', sortByActivity),
                          (sortByActivity =
                            sortByActivity === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByActivity === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th>TAG</th>
                  <th>
                    CRIAÇÃO
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('execucao_data_criacao', sortByDate),
                          (sortByDate = sortByDate === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByDate === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th>
                    BAIRRO NOME
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('bairro_nome', sortByNeighborhood),
                          (sortByNeighborhood =
                            sortByNeighborhood === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByNeighborhood === 'asc'
                              ? 'rotate(180deg)'
                              : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th>
                    ZELADORIA NOME
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('zeladoria_nome', sortByKeepingSector),
                          (sortByKeepingSector =
                            sortByKeepingSector === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByKeepingSector === 'asc'
                              ? 'rotate(180deg)'
                              : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th class="actions-column">AÇÕES</th>
                </tr>
              </thead>
              <tr
                v-for="measurement of measurements"
                :key="Math.random() + measurement"
              >
                <td>{{ selectedInstitutions.name }}</td>
                <td>{{ measurement.atividade_nome }}</td>
                <td>{{ measurement.tags[0].tag_descricao }}</td>
                <td>{{ formatDate(measurement.execucao_data_criacao) }}</td>
                <td>{{ measurement.bairro_nome }}</td>
                <td>{{ measurement.zeladoria_nome }}</td>
                <td class="redirect" @click="redirectToDetails(measurement)">
                  <svg
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9997 0C16.3917 0 20.8777 3.88 21.8187 9C20.8787 14.12 16.3917 18 10.9997 18C5.60766 18 1.12166 14.12 0.180664 9C1.12066 3.88 5.60766 0 10.9997 0ZM10.9997 16C13.0391 15.9996 15.0181 15.3068 16.6125 14.0352C18.207 12.7635 19.3226 10.9883 19.7767 9C19.3209 7.0133 18.2046 5.24 16.6103 3.97003C15.016 2.70005 13.038 2.00853 10.9997 2.00853C8.96136 2.00853 6.98335 2.70005 5.38904 3.97003C3.79473 5.24 2.67843 7.0133 2.22266 9C2.67676 10.9883 3.79234 12.7635 5.38681 14.0352C6.98128 15.3068 8.9602 15.9996 10.9997 16ZM10.9997 13.5C9.80619 13.5 8.6616 13.0259 7.81768 12.182C6.97377 11.3381 6.49966 10.1935 6.49966 9C6.49966 7.80653 6.97377 6.66193 7.81768 5.81802C8.6616 4.97411 9.80619 4.5 10.9997 4.5C12.1931 4.5 13.3377 4.97411 14.1816 5.81802C15.0256 6.66193 15.4997 7.80653 15.4997 9C15.4997 10.1935 15.0256 11.3381 14.1816 12.182C13.3377 13.0259 12.1931 13.5 10.9997 13.5ZM10.9997 11.5C11.6627 11.5 12.2986 11.2366 12.7674 10.7678C13.2363 10.2989 13.4997 9.66304 13.4997 9C13.4997 8.33696 13.2363 7.70107 12.7674 7.23223C12.2986 6.76339 11.6627 6.5 10.9997 6.5C10.3366 6.5 9.70074 6.76339 9.2319 7.23223C8.76306 7.70107 8.49966 8.33696 8.49966 9C8.49966 9.66304 8.76306 10.2989 9.2319 10.7678C9.70074 11.2366 10.3366 11.5 10.9997 11.5Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                </td>
              </tr>
            </table>
            <NoResults v-else />
          </div>
        </section>
      </div>
      <Loading v-else />
    </div>
  </div>
</template>

<script>
import Loading from "../../components/IndexComponents/Loading.vue";
import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import services from "../../services";
import { infoToast } from "../../utils/toast";
import Multiselect from "vue-multiselect";

export default {
  name: "Measurement",
  components: {
    Loading,
    Button,
    NoResults,
    Multiselect,
  },
  mounted: async function () {
    if (!this.range) {
      infoToast("Nenhum período foi selecionado");
    }
    await this.initAllData();
  },
  data() {
    return {
      loadingData: true,
      measurements: [],
      calendarModal: false,
      monthName: "",
      legalText: "Lorem ipsum",
      sortByActivity: "asc",
      sortByDate: "asc",
      sortByNeighborhood: "asc",
      sortByKeepingSector: "asc",
      loadingActivities: false,
      loadingTags: false,
      engineers: [],
      range: {
        start: null,
        end: null,
      },
      institutions: [],
      selectedInstitutions: [],
      activities: [],
      selectedActivities: [],
      tags: [],
      selectedTags: [],
    };
  },
  methods: {
    async initAllData() {
      await this.getLegalText();
      await this.getInstitutions();
      this.loadingData = false;
    },
    async getLegalText() {
      const { data, errors } = await services.importData.getLegalText(202);
      if (!errors) {
        this.legalText = data.config_valor;
      }
    },
    async selectInstitution() {
      this.selectedActivities = [];
      this.activities = [];
      this.selectedTags = [];
      this.tags = [];
      this.loadingActivities = true;
      await this.getActivities();
      this.loadingActivities = false;
    },
    async selectActivities() {
      this.selectedTags = [];
      this.tags = [];
      this.loadingTags = true;
      await this.getTags();
      this.loadingTags = false;
    },
    redirectToDetails(measurement) {
      let route = this.$router.resolve({
        path: `/detalhe-execucao/${measurement.execucao_uuid}`,
      });
      window.open(route.href, "_blank");
    },
    async getInstitutions() {
      const { data, errors } = await services.institutions.getInstitutions();
      if (!errors) {
        this.institutions = data.map((item) => {
          return {
            name: item.instituicao_descricao,
            id: item.instituicao_id,
          };
        });
      }
    },
    async getActivities() {
      const { data, errors } = await services.activities.activityByInstitution({
        instituicao_id: this.selectedInstitutions.id,
      });
      if (!errors) {
        this.activities = data.map((item) => {
          return {
            ...item,
            name: item.atividade_nome,
            id: item.atividade_id,
          };
        });
      }
    },
    async getTags() {
      const { data, errors } =
        await services.tags.getTagsByActivityAndInstitution({
          atividade_id: this.selectedActivities.map(
            (item) => item.atividade_id
          ),
          instituicao_id: this.selectedInstitutions.id,
        });
      if (!errors) {
        this.tags = data.map((item) => {
          return { ...item, name: item.tag_descricao, id: item.tag_id };
        });
      }
    },
    async requestReport(ev) {
      ev.preventDefault();
      this.calendarModal = false;
      if (this.range) {
        await this.requestExecutionEngineers();
        const { data, errors } =
          await services.executions.reportByInstitutionAndActivity({
            data_inicio: this.range.start,
            data_fim: this.range.end,
            atividade_id: this.selectedActivities.map((item) => item.id),
            instituicao_id: [this.selectedInstitutions.id],
            tag_id: this.selectedTags.map((item) => item.id),
          });
        if (!errors) {
          this.measurements = data;
        }
      }
    },
    async requestExecutionEngineers() {
      if (this.range) {
        const { data, errors } =
          await services.executions.getExecutionsEngineers({
            data_inicio: this.range.start,
            data_fim: this.range.end,
          });
        if (!errors) this.engineers = data;
      }
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    blockChooseDaysFromDifferentMonths() {
      this.dates = {
        start: null,
        end: null,
      };
    },
    print() {
      setTimeout(function () {
        window.print();
      }, 0);
    },
    goToHome() {
      this.$router.push({
        name: "Relatorios",
      });
    },
    sortBy(key, order) {
      this.measurements.sort((a, b) => {
        if (a[key] < b[key]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: A4 landscape !important;
    page-break-inside: avoid;
  }
  .print-button {
    display: none;
  }
  .form-wrapper {
    display: none;
  }
  .pointer {
    display: none !important;
  }

  .back-arrow {
    display: none !important;
  }
  .actions-column {
    display: none !important;
  }
  .redirect {
    display: none !important;
  }
  .current-dates {
    display: block !important;
  }
}

.page-title {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 132% !important;
  color: #565e73 !important;
  margin-left: -8px !important;
  margin-top: 2px !important;
}

.print-container {
  padding: 32px;
  min-height: 100vh;
}

.print-title {
  font-family: "OpenSans-Bold";
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #003366;
}

.print-subtitle {
  font-family: "OpenSans-Bold";
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  color: #61b2e5;
}

.range-label {
  background: #838998;
  border-radius: 16px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: center;
  color: #ffffff;
  padding: 4.5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-icon {
    vertical-align: middle;
    padding-bottom: 3px;
  }
}

.clean-filters {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  color: #003366;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 22px;
  right: 258px;
}

.separator-calendar {
  border: 1px solid #ececec;
  width: 85%;
  margin-top: 0;
}

.print-button {
  cursor: pointer;
}

table,
td,
th {
  border-top: 1px solid #dddee3;
}

th,
td {
  height: 33px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 12px;
  color: #565e73;
  padding: 24px;
  text-align: left;
}

th {
  background-color: #fff;
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  line-height: 156%;
  color: #565e73;
  user-select: none;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr > .date {
  background-color: #003366;
  color: #fff;
  font-family: "OpenSans-Bold";
  font-size: 14px;
}

.legal-text {
  font-family: "Roboto-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #838998;
  margin-bottom: 24px;
}

.btn-relatory {
  width: 32px;
  height: 32px;
}

.engineers-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  color: #565e73;
}

.btn-position {
  margin-bottom: 3px;
}

.engineer-card {
  border: 1px solid #dddee3;
  border-radius: 12px;
  padding: 16px;

  .engineer-info {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 12px;
    color: #747474;
  }
}

.form {
  input {
    width: 140px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    color: #747474;
  }
}

.redirect {
  cursor: pointer;
}

.current-dates {
  display: none;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  line-height: 132%;
  color: #003366;
  margin: 10px 0;
}
</style>

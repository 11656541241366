<template>
  <div class="c-app flex-row align-items-center login-manaus-bk">
    <CContainer>
      <CRow class="justify-content-end">
        <CCol xl="4" lg="7" md="7">
          <CCardGroup>
            <CCard class="p-3 card-custom-border">
              <CCardBody v-if="step === 0">
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <CButton class="mb-1" to="/manaus">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.828 7.15984H16V9.15984H3.828L9.192 14.5238L7.778 15.9378L0 8.15984L7.778 0.381836L9.192 1.79584L3.828 7.15984Z"
                        fill="#565E73"
                      />
                    </svg>
                  </CButton>
                  <div class="forgot-title">{{ $t("recoverPwd") }}</div>
                  <div></div>
                </div>
                <div
                  class="
                    w-100
                    d-flex
                    justify-content-center
                    align-items-center
                    flex-column
                    mt-2
                    mb-4
                  "
                >
                  <div class="forgot-description">
                    {{ $t("recoverPwdText") }}
                  </div>
                </div>
                <section>
                  <div class="d-flex justify-content-center">
                    <Input
                      class="login-input"
                      placeholder="E-mail"
                      type="text"
                      v-model="user_mail"
                    />
                  </div>
                  <div class="d-flex justify-content-center mt-4">
                    <LoginButton :name="$t('recoverBtn')" @clicked="send" />
                  </div>
                </section>
              </CCardBody>
              <CCardBody v-if="step === 1">
                <div
                  class="
                    w-100
                    d-flex
                    justify-content-center
                    align-items-center
                    flex-column
                    mt-2
                    mb-4
                  "
                >
                  <div class="forgot-title mb-3">
                    {{ $t("msgSuccess") }}
                  </div>
                  <div class="forgot-description w-100">
                    {{ $t("clickContinue") }}
                  </div>
                  <div class="circle mt-4 mb-3">
                    <span class="counter">{{ counter }}</span>
                  </div>
                  <div class="d-flex justify-content-center mt-4 mb-1 w-75">
                    <LoginButton name="CONTINUAR" @clicked="backToLogin" />
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../../services";

import Input from "../../../components/form/Input";
import LoginButton from "../../../components/LoginButton";

export default {
  name: "ForgotPasswordManaus",
  data() {
    return {
      user_mail: "",
      step: 0,
      counter: 600,
    };
  },
  components: {
    Input,
    LoginButton,
  },
  mounted() {},
  methods: {
    backToLogin() {
      this.$router.push("/manaus");
    },
    send: async function () {
      try {
        const { data, errors } = await services.auth.forgotPassword({
          usuario_gestor_email: this.user_mail.toLowerCase(),
          cidade_id: 1302603,
        });
        if (!errors) {
          Vue.$toast.open({
            message: data,
            position: "top",
            type: "success",
          });
          this.step = 1;
          setInterval(() => {
            this.counter--;
            if (this.counter === 0) {
              this.$router.push("/manaus");
            }
          }, 1000);
        } else {
          Vue.$toast.open({
            message: errors.statusText.erro || errors.statusText.error,
            position: "top",
            type: "error",
          });
        }
      } catch (error) {
        Vue.$toast.open({
          message: "Ocorreu um erro ao recuperar a senha",
          position: "top",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.forgot-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  text-align: center;
  color: #565e73;
  margin-right: 32px;
}

.forgot-description {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  text-align: center;
  color: #747474;
  width: 60%;
}

.circle {
  border-radius: 50%;
  border: 16px solid #61b2e5;
  width: 127px;
  height: 127px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  font-family: "OpenSans-Regular";
  font-size: 28px;
  line-height: 145%;
  text-align: center;
  color: #003366;
}
</style>
<style>
.login-input.input-wrapper > .input {
  text-transform: lowercase !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
</style>

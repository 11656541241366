<template>
  <div class="user-gallery">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav"
        >{{ $t("gallery") }}: {{ userName }}
        <span v-if="keepingSector">{{ keepingSector }}</span></span
      >
    </h3>
    <div class="cards-list ml-3" v-if="activities.length > 0">
      <div v-for="(item, index) of activities" :key="item.id">
        <div class="card">
          <a @click="photoIndex = index">
            <img class="card-img-top" :src="item.photo" />
          </a>

          <div @click="redirectToDetails(item)" class="card-content">
            <div class="content-info">
              <div class="card-title">{{ item.tag }}</div>
              <div class="card-subtitle">
                {{ item.date }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoolLightBox
        v-if="photos.length > 0"
        :items="photos"
        :index="photoIndex"
        @close="photoIndex = null"
      />
    </div>
    <div class="d-flex align-items-center justify-content-center mt-3" v-else>
      <p class="page-nav p-2">{{ $t("noPhotoFound") }}</p>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner">
        <Loading />
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import moment from "moment";
import services from "../../services";
import BackArrow from "../../components/BackArrow.vue";
import CoolLightBox from "vue-cool-lightbox";
import Loading from "../../components/IndexComponents/Loading.vue";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { formatDate, formatTime } from "../../utils/date";

export default {
  name: "GallerySectorKeeper",
  components: {
    BackArrow,
    CoolLightBox,
    Loading,
  },
  data() {
    return {
      userId: null,
      userName: null,
      keepingSector: null,
      pagina: 1,
      activities: [],
      photoIndex: null,
      photos: [],
    };
  },
  mounted: async function () {
    this.userId = this.$route.params.id;
    const userList = await services.users.getUsers();
    const user = userList.data.find((user) => {
      return user.usuario_id === +this.$route.params.id;
    });
    this.userName = user.usuario_nome;
    this.keepingSector = user.zeladoria_nome;
    await this.loadPhotos();
  },
  methods: {
    redirectToDetails(item) {
      this.$router.push({
        name: `DetalheExecucao`,
        params: { execucao_uuid: item.executionId, userId: this.userId },
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async loadPhotos() {
      const { data, errors } = await services.executions.getPhotosByUser({
        userId: this.userId,
        page: this.pagina,
      });
      if (!errors) {
        this.pagina++;
        let temp = data.map((item) => {
          return item.execucao_timeline.map((i) => {
            return {
              photo: i.execucao_timeline_foto,
              name: i.atividade_nome,
              id: i.execucao_timeline_uuid,
              tag: i.tags[0].tag_descricao,
              executionId: item.execucao_uuid,
              date: `${formatDate(i.execucao_timeline_data)} • ${formatTime(
                i.execucao_timeline_data
              )}`,
            };
          });
        });
        temp.forEach((item) => {
          item.forEach((i, index) => {
            this.activities.push(i);
            this.photos.push({
              src: i.photo,
              title: i.name,
              description: i.date,
              imageIndex: index,
            });
          });
        });
      }
    },
    async infiniteHandler($state) {
      setTimeout(async () => {
        const { data, errors } = await services.executions.getPhotosByUser({
          userId: this.userId,
          page: this.pagina,
        });
        if (!errors) {
          if (data.length > 0) {
            this.pagina++;
            let temp = data.map((item) => {
              return item.execucao_timeline.map((i) => {
                return {
                  photo: i.execucao_timeline_foto,
                  name: i.atividade_nome,
                  id: i.execucao_timeline_uuid,
                  executionId: item.execucao_uuid,
                  date: moment(i.execucao_timeline_data).format(
                    "DD/MM/YYYY hh:mm"
                  ),
                };
              });
            });
            temp.forEach((item) => {
              item.forEach((i, index) => {
                this.activities.push(i);
                this.photos.push({
                  src: i.photo,
                  title: i.name,
                  description: i.date,
                  imageIndex: index,
                });
              });
            });
            $state.loaded();
          } else {
            $state.loaded();
          }
        } else {
          $state.complete();
        }
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.user-gallery {
  .cards-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }

  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: fit-content;
    box-shadow: (0px 4px 4px rgba(160, 168, 189, 0.24));
    border-radius: 8px;

    img {
      width: 286px;
      max-width: 286px;
      border-radius: 8px 8px 0 0;
    }

    .card-content {
      padding: 24px 16px;
      width: 100%;
      .card-title {
        padding: 2px 8px;
        background: #dddee3;
        border-radius: 4px;
        color: #565e73;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 156%;
        width: fit-content;
      }

      .card-subtitle {
        font-family: "OpenSans-Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.006em;
        color: #565e73;
        margin-top: 4px;
      }
    }
  }

  .card-img-top {
    width: 100%;
    margin: 0;
    border-radius: 8px 8px 0px 0px;
  }

  .div-button-details {
    width: 100%;
    font-size: 18px;
    button {
      width: 100%;
      height: 32px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-container h-100"},[_c('ul',_vm._l((_vm.activitiesData),function(activity){return _c('li',{key:activity.atividade_id},[_c('div',{staticClass:"d-flex justify-content-between position-relative p-3 align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-4 ml-2",attrs:{"src":activity.atividade_icon,"width":"32","height":"32"}}),_c('div',[_c('div',{staticClass:"activity-name"},[_vm._v(_vm._s(activity.atividade_nome))]),_c('div',{staticClass:"activity-counter"},[_vm._v(" "+_vm._s(activity.quantidade)+" "+_vm._s(activity.quantidade === 1 ? _vm.$t("oneExecution") : _vm.$t("moreExecutions"))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end switch-elements"},[_c('div',{staticClass:"pt-2 mr-3"},[_c('CustomSwitch',{attrs:{"selected":activity.active,"disabled":activity.quantidade === 0},on:{"checkedSync":function($event){return _vm.changeSwitch(activity)}}})],1),_c('span',{class:`
              new-icons pointer filters
              ${activity.quantidade > 0 ? '' : 'disabled'}
              ${activity.active ? 'active' : ''}
            `,attrs:{"title":"Filtros"},on:{"click":function($event){return _vm.showFilterTag(
                activity.tags,
                activity.atividade_id,
                activity.active
              )}}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"1 2 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.5 0.5V1.5H9L6 6V9.5H4V6L1 1.5H0.5V0.5H9.5Z","fill":"white"}})])])]),_c('Transition',{attrs:{"name":"filter"}},[(_vm.filterModal && _vm.activityId === activity.atividade_id)?_c('div',{staticClass:"absolute-box w-50"},[_c('CheckboxFilter',{attrs:{"title":"Filtros","tagsData":_vm.actualTags,"name":"Filtre por Tag"},on:{"clicked":function($event){_vm.filterModal = !_vm.filterModal},"filter-by-tag":_vm.filterTag}})],1):_vm._e()])],1),_vm._m(0,true)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-0 m-0"},[_c('span',{staticClass:"list-item"})])
}]

export { render, staticRenderFns }
<template>
  <div class="details-keeping-sector">
    <div class="d-flex justify-content-between">
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav"
          >{{ $t("reportKeepingSector") }}:
          <span class="page-nav" v-if="!loadingData">
            {{ details.info.zeladoria_nome }}
          </span>
        </span>
      </h3>
      <div class="d-flex align-items-center pl-3 mb-4 pb-2">
        <CustomSwitch
          :selected="keepingSectorIncluded === true"
          @checkedSync="handleKeepingSectorBlock"
        />
        <label class="switch-label ml-2">{{ $t("includeKeepSector") }}</label>
      </div>
    </div>

    <div class="activities-cards ml-3">
      <div v-for="(item, id) of activities" :key="id">
        <div class="activity-card pointer" @click="redirectToList(item)">
          <div class="activiy-icon">
            <img
              :src="item.atividade_icon"
              height="64"
              alt=""
              class="img-border"
            />
          </div>
          <div class="activity-counter">
            <div class="card-title">{{ item.atividade_nome }}</div>
            <div class="card-counter">
              {{
                item.atividade_quantidade !== null
                  ? item.atividade_quantidade.toString() + ""
                  : "0"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ml-3">
      <div class="map-container">
        <div class="map-title">Localização das atividades</div>
        <GmapMap
          ref="googleMap"
          :center="center"
          :zoom="zoom"
          style="height: 850px; width: 100%"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: true,
            mapTypeId: mapType,
            gestureHandling: 'greedy',
            fullscreenControl: false,
            disableDefaultUi: true,
          }"
        >
          <div
            v-for="polygon of polygonKeepingSector"
            :key="'polygonsKeepingSector' + polygon.id"
          >
            <GmapPolygon
              ref="polygon"
              :paths="polygon.latlngs"
              :options="polygon.options"
            />
          </div>
          <div
            v-for="polygon of polygonBlocks"
            :key="'polygonsBlock' + polygon.id"
          >
            <GmapPolygon
              ref="polygon"
              :paths="polygon.latlngs"
              :options="polygon.options"
            />
          </div>
        </GmapMap>
        <div class="mapTypeControllers">
          <CSelect
            :placeholder="$t('type')"
            :options="mapTypes"
            :value.sync="mapType"
          ></CSelect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from "../../services";
import { formatForGoogleMaps } from "../../utils/geoMaps";

import BackArrow from "../../components/BackArrow.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";

export default {
  name: "DetailsKeepingSector",
  components: {
    BackArrow,
    CustomSwitch,
  },
  data() {
    return {
      details: {
        info: {},
        zeladoria: {
          qtdAcao: 0,
          qtdPesquisa: 0,
          qtdChamado: 0,
          qtdColeta: 0,
        },
      },
      zoom: 8,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      polygonKeepingSector: [],
      polygonBlocks: [],
      type: "",
      activities: [],
      loadingData: true,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "roadmap",
      keepingSectorIncluded: true,
    };
  },
  mounted: async function () {
    await this.requestKeepingSectorDetails();
    await this.requestActivitiesByKeepingSector();
    // await this.requestKeepingSectorBlocks();
    this.setIncluded();
    this.loadingData = false;
  },
  methods: {
    setIncluded() {
      this.keepingSectorIncluded =
        this.details.info.zeladoria_incluida === 1 ? true : false;
    },
    async requestActivitiesByKeepingSector() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorDetails({
          keepingSectorId: this.$route.params.id,
        });
      if (!errors) {
        this.activities = data.map((item) => {
          return {
            ...item,
          };
        });
      }
    },
    async requestKeepingSectorDetails() {
      const { data, errors } =
        await services.keepingSectors.getDetailsKeepingSector({
          keepingSectorId: this.$route.params.id,
        });

      if (!errors) {
        this.details.info = data[0];
        this.mountKeepingSector(data[0].zeladoria_limite);
      }
    },
    async requestKeepingSectorBlocks() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorBlocks(
          this.$route.params.id
        );
      if (!errors) {
        this.mountBlocks(data);
      }
    },
    redirectToList(item) {
      this.$router.push(
        `/execucoes/zeladoria-${item.atividade_id}-${this.$route.params.id}`
      );
    },
    mountKeepingSector(poligono) {
      if (poligono !== "") {
        this.polygonKeepingSector = [];
        const coordinatesJSONParse = poligono;
        let bairro = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse[0]),
          options: {
            strokeColor: "#548aba",
            fillColor: "#d4edf7",
          },
          id: 1,
        };

        bairro.latlngs.map((item) => {
          this.bounds.push(item);
        });
        this.polygonKeepingSector.push(bairro);
      }
      this.fitBoundsGoogle();
    },
    mountBlocks(polygons) {
      if (polygons.length) {
        this.polygonBlocks = [];

        polygons.forEach((polygon) => {
          let block = {
            latlngs: formatForGoogleMaps(polygon.quadra_limite[0]),
            options: {
              strokeColor: "#FF0000",
              fillColor: "#FF0000",
            },
            name: polygon.quadra_nome,
            id: polygon.quadra_id,
          };

          block.latlngs.map((item) => {
            this.bounds.push(item);
          });
          this.polygonBlocks.push(block);
        });
      }
      this.fitBoundsGoogle();
    },

    async handleKeepingSectorBlock(value) {
      this.keepingSectorIncluded = value;
      const keepingSector = {
        zeladoria_id: this.details.info.zeladoria_id,
        zeladoria_incluida: this.keepingSectorIncluded ? 1 : 0,
      };
      await services.keepingSectors.includeKeepingSector(keepingSector);
    },

    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.details-keeping-sector {
  .activities-cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .activity-card {
      min-width: 292px;
      min-height: 88px;
      border: 1px solid #dddee3;
      border-radius: 16px;
      padding: 16px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      .card-counter {
        font-family: "OpenSans-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #29334e;
        margin: 0;
        padding: 0;
      }

      .card-title {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.014em;
        color: #565e73;
        margin: 0;
        padding: 0;
      }
    }
  }

  .map-container {
    position: relative;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 32px;

    .map-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.017em;
      color: #29334e;
      margin-bottom: 32px;
    }
  }

  .img-border {
    border-radius: 50%;
    border: 1px solid #dddee3;
    padding: 8px;
  }
}

.mapTypeControllers {
  position: absolute;
  top: 82px;
  left: 18px;
  z-index: 500;
  width: 120px;
}

.switch-label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
}
</style>

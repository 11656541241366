<template>
  <div class="performance-wrapper">
    <div class="performance-header">
      <h3 class="intern-title ml-3">
        <div class="title-box">
          <div>
            <back-arrow />
            <span class="page-nav">Desempenho do zelador - Mapa </span>
          </div>
          <div v-if="user.usuario_nome" class="subtitulo-nome">
            {{ user.zeladoria_nome || "" }}
          </div>
        </div>
      </h3>
    </div>

    <UserCard class="user-card ml-3 mr-2" :user="user" />
  </div>
</template>

  <script>
import BackArrow from "../../components/BackArrow.vue";
import UserCard from "../../components/userPerformance/UserCard.vue";
import services from "../../services";

export default {
  components: {
    BackArrow,
    UserCard,
  },

  created: async function () {
    this.loadingData = true;
    const currentDate = new Date();
    this.defaultMonth = currentDate.getMonth();
    const { data, errors } = await services.analytics.getZelador({
      id: this.$route.params.id,
    });
    if (!errors) {
      this.user = data;
    }
    const date = new Date();
    this.range.start = new Date(date.getFullYear(), date.getMonth(), 1);
    this.range.end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.loadingData = false;
  },

  data() {
    return {
      loadingData: false,
      bigNumbers: [],
      defaultMonth: null,
      user: null,
      initialDate: null,
      calendarModal: false,
      finalDate: null,
      range: {
        start: null,
        end: null,
      },
      bigNumbersKey: null,
      chartsKey: null,
    };
  },

  methods: {
    handleMonthSelect(ev) {
      this.range.start = ev.from.toISOString();
      this.range.end = ev.to.toISOString();

      this.bigNumbersKey = Math.random();
      this.chartsKey = Math.random();
    },
    formatDateToBR(date) {
      if (date) {
        let dateFormat = date.split("T");
        let dateSplit = dateFormat[0].split("-");
        let dateBR = dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
        return dateBR;
      }
      return "-";
    },
  },
};
</script>

  <style lang="scss" scoped>
.performance-wrapper {
  .performance-header {
    display: flex;
    justify-content: space-between;
  }
  .big-numbers {
    margin: 32px 0;
  }
  .charts {
    margin: 32px 0;
  }
}
.absolute-box {
  position: absolute;
  z-index: 10;
  top: 30px;
}

.send-button {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 8px 24px;
  background: #003063;
  border-radius: 8px;
  margin: 0px 8px;
  outline: none;
  border: none;

  &:hover {
    background: #002a5a;
  }

  &:active {
    background: #002a5a;
  }
}
.cancel-button {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #565e73;
  margin: 0px 8px;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 8px;

  &:hover {
    background: #f5f5f5;
  }

  &:active {
    background: #e5e5e5;
  }
}
</style>
  <style lang="scss">
</style>

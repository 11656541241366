<template>
  <div class="contracts">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span v-if="!contractLogItem" class="page-nav">
        Contratos e Distratos</span
      >
      <span v-if="contractLogItem">
        Log do
        {{
          contractLogItem.contrato_zelador_tipo_id === 1
            ? "Contrato"
            : "Distrato"
        }}:
        {{ formatDate(contractLogItem.contrato_zelador_log_data) }}
      </span>
    </h3>

    <div v-if="!contractLogItem" class="tabs ml-3">
      <div
        :disabled="tab.disabled"
        :class="`tab ${tab.active ? 'active' : ''}`"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="handleChangeTab(tab.value)"
      >
        {{ tab.label }}
      </div>
    </div>

    <div v-if="!contractLogItem" class="content ml-3">
      <div v-if="currentTab === 1" class="contract">
        <div v-if="contract">
          <div class="info">
            <p class="info-item">
              <span class="material-icons-outlined">person</span>Gestor:
              {{ contract.usuario_gestor_nome }}
            </p>
            <p class="info-item">
              <span class="material-icons-outlined">calendar_today</span
              >{{ formatDate(contract.contrato_zelador_data) }}
            </p>
          </div>
          <div v-if="!contractLogItem" class="tags">
            <p class="title">Tags disponíveis</p>
            <div class="items">
              <div
                class="item"
                v-for="item in tags"
                :key="item.contrato_zelador_tag_id"
              >
                <strong>{{ item.contrato_zelador_tag }}</strong> -
                {{ item.contrato_zelador_tag_descricao }}
              </div>
            </div>
          </div>
          <TextEditor v-model="contract.contrato_zelador_texto"></TextEditor>
          <div class="actions">
            <Button
              name="Atualizar"
              size="small"
              :disabled="!contract.contrato_zelador_texto"
              @clicked="handleContractEdit(contract)"
            />
            <Button
              size="small"
              @clicked="handleContractLogs(1)"
              name="Log de alterações"
            />
          </div>
        </div>
        <div v-else class="no-data">Nenhum dado referente ao contrato</div>
      </div>

      <div v-if="currentTab === 2" class="cancellation">
        <div v-if="cancellation">
          <div class="info">
            <p class="info-item">
              <span class="material-icons-outlined">person</span>Gestor:
              {{ cancellation.usuario_gestor_nome }}
            </p>
            <p class="info-item">
              <span class="material-icons-outlined">calendar_today</span
              >{{ formatDate(cancellation.contrato_zelador_data) }}
            </p>
          </div>
          <div v-if="!contractLogItem" class="tags">
            <p class="title">Tags disponíveis</p>
            <div class="items">
              <div
                class="item"
                v-for="item in tags"
                :key="item.contrato_zelador_tag_id"
              >
                <strong>{{ item.contrato_zelador_tag }}</strong> -
                {{ item.contrato_zelador_tag_descricao }}
              </div>
            </div>
          </div>
          <TextEditor v-model="cancellation.contrato_zelador_texto" />
          <div class="actions">
            <Button
              name="Atualizar"
              size="small"
              :disabled="!contract.contrato_zelador_texto"
              @clicked="handleContractEdit(cancellation)"
            />
            <Button
              size="small"
              @clicked="handleContractLogs(2)"
              name="Log de alterações"
            />
          </div>
        </div>
        <div v-else class="no-data">Nenhum dado referente ao distrato</div>
      </div>
    </div>

    <div v-if="contractLogItem" class="contract-item">
      <div
        class="log-item"
        v-html="contractLogItem.contrato_zelador_texto"
      ></div>
    </div>
  </div>
</template>
<script>
import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import { formatDate } from "../../utils/date";
import { successToast, validationToast } from "../../utils/toast";
import TextEditor from "../../components/TextEditor.vue";

import services from "../../services";

export default {
  components: {
    BackArrow,
    Button,
    TextEditor,
  },
  async created() {
    await this.getContractTags();
    if (this.$route.params.contractId) {
      const response = await services.contracts.getContractLogDetails(
        this.$route.params.contractId
      );
      this.contractLogItem = response.data[0];
    } else {
      await this.getContract();
    }
  },
  data() {
    return {
      formatDate,
      successToast,
      validationToast,
      contract: null,
      tags: [],
      contractLogItem: null,
      cancellation: null,
      tabs: [
        {
          disabled: false,
          active: true,
          label: "Contrato",
          value: 1,
          show: true,
        },
        {
          disabled: false,
          active: false,
          label: "Distrato",
          value: 2,
          show: false,
        },
      ],
      currentTab: 1,
    };
  },
  methods: {
    async getContractTags() {
      const response = await services.contracts.getContractTags();
      this.tags = response.data;
    },
    async getContract() {
      const contractResponse = await services.contracts.getContractTemplates();
      const cancellationResponse =
        await services.contracts.getCancellationTemplates();

      this.contract = contractResponse.data;
      this.cancellation = cancellationResponse.data;
    },
    async handleChangeTab(value) {
      this.tabs.map((tab) => {
        if (tab.value === value) {
          this.currentTab = value;
          tab.active = true;
          tab.show = false;
        } else {
          tab.active = false;
          tab.show = true;
        }
      });
    },
    handleContractLogs(typeId) {
      this.$router.push({ name: "LogContratos", params: { type: typeId } });
    },

    //teste para sync dev
    async handleContractEdit(contract) {
      console.log(contract.contrato_zelador_texto);
      const { errors } = await services.contracts.putContracts({
        contrato_zelador_id: contract.contrato_zelador_id,
        contrato_zelador_texto: contract.contrato_zelador_texto,
      });

      if (!errors) {
        successToast(
          `${
            contract.contrato_zelador_tipo_id === 1 ? "Contrato" : "Distrato"
          } alterado com sucesso !`
        );
      } else {
        validationToast(
          `Erro ao alterar ${
            contract.contrato_zelador_tipo_id === 1 ? "Contrato" : "Distrato"
          }`
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contracts {
  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.tabs {
  display: flex;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  color: #838998;
}

.material-icons-outlined {
  font-size: 20px;
  margin-right: 2px;
}

.tab {
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin-right: 12px;
  cursor: pointer;
  color: #838998;
  &:hover {
    background: #eee;
  }
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.editor {
  height: 40rem;
  overflow: hidden;
  border-color: #565e73;
  color: #565e73;
}

.content {
  .cancellation,
  .contract {
    .info {
      font-size: 1rem;
      color: #565e73;
      margin: 30px 0;

      .info-item {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        line-height: 156%;
        color: #565e73;

        display: flex;
        align-items: center;
        gap: 10px;
        margin: 15px 0;
      }
    }
    .actions {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      button {
        width: 300px;
        font-size: 1rem;
      }
    }
  }
}

.tags {
  margin: 32px 0;

  .title {
    font-family: "Montserrat";
    font-size: 1.2rem;
    color: #565e73;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .item {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 12px;
      line-height: 156%;
      color: #565e73;
      text-overflow: ellipsis;
      border: 1px solid #565e73;
      border-radius: 8px;
      padding: 8px;
    }
  }
}

.log-item {
  border: 1px solid #565e73;
  color: #000;
  border-radius: 8px;
  padding: 10px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.017em;
  color: #29334e;
}

.editor.disabled {
  .ql-toolbar.ql-snow {
    display: none;
  }
}
</style>

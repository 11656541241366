import jwt from "jsonwebtoken";
import services from "../services";

import { decrypt, encrypt } from "../utils/decrypt";

export default async (to, from, next) => {
  let userData = null;
  let userConfig500 = null

  if (localStorage.getItem("config-500")) {
    const encrypted = localStorage.getItem("config-500");
    const decrypted = decrypt(encrypted);
    userConfig500 = JSON.parse(decrypted)
  }

  if (localStorage.getItem("ama")) {
    const encrypted = localStorage.getItem("ama");
    const decrypted = decrypt(encrypted);
    userData = JSON.parse(decrypted);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.path === '/ordens-de-servico' || to.path.includes('ordem-de-servico')) {
      if (+userConfig500.config_valor === 0) {
        next('/dashboard')
      }
    }
    if (userData) {
      var decoded = jwt.decode(userData.usuario_token, { complete: true });
      const expiryDate = new Date(decoded.payload.expiresAt * 1000);
      const actualDate = new Date();

      if (actualDate < expiryDate) {
        next();
        return;
      }

      const { data, errors } = await services.auth.refresh({
        refresh_token: userData.usuario_refresh_token,
      });

      if (!errors) {
        userData.usuario_token = data.usuario_token;
        userData.usuario_refresh_token = data.usuario_refresh_token;
        const encrypted = encrypt(JSON.stringify(userData));
        localStorage.setItem("ama", encrypted);
        next();
        return;
      } else {
        localStorage.removeItem("ama");
        if (userData.usuario_cidade === "Curitiba/PR") {
          next("/curitiba");
        } else if (userData.usuario_cidade === "Florianópolis/SC") {
          next("/florianopolis");
        } else if (userData.usuario_cidade === "Apucarana/PR") {
          next("/apucarana");
        } else if (userData.usuario_cidade === "Osasco/SP") {
          next("/osasco");
        } else if (userData.usuario_cidade === "Paranaguá/PR") {
          next("/paranagua");
        } else if (userData.usuario_cidade === "Ribeirão Preto/SP") {
          next("/ribeirao-preto");
        } else if (userData.usuario_cidade === "Manaus/AM") {
          next("/manaus");
        } else if (userData.usuario_cidade === "São José dos Pinhais/PR") {
          next("/sao-jose-pinhais");
        }
        return;
      }
    }
    return;
  } else {
    if (
      (to.name === "login-curitiba" ||
        to.name === "login-florianopolis" ||
        to.name === "login-osasco" ||
        to.name === "login-apucarana" ||
        to.name === "login-paranagua" ||
        to.name === "login-manaus" ||
        to.name === "login-ribeirao-preto" ||
        to.name === "login-sao-jose-dos-pinhais") &&
      localStorage.getItem("ama")
    ) {
      next("/dashboard");
      return;
    }
    next();
  }
};

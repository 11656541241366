<template>
  <div class="import">
    <h3 class="ml-3 intern-title">
      <back-arrow />
      <span class="page-nav">{{ $t("importUsers") }}</span>
    </h3>
    <div class="d-flex justify-content-between ml-2 mb-0">
      <div class="w-25">
        <Input
          v-model="searchUser"
          :placeholder="$t('searchUser')"
          :search="true"
          :disabled="false"
        />
      
      </div>
      <Modal v-if="modalIsOpen">
        <template v-slot:header>
          <h1>Cuidado!</h1>
        </template>
        <template v-slot:body>
          <p>{{ $t('modalConfirmImportText') }}</p>
        </template>
        <template v-slot:footer >
          <div class="modal-btns w-100">
            <Button
              type="primary"
              :name="$t('yes')"
              size="big"
              @clicked="importUsers()"
            />
            <Button
              type="bg-danger"
              :name="$t('no')"
              size="big"
              @clicked="openModal()"
            />
          </div>
        </template>
      </Modal>
    </div>
    <div class="actions d-flex justify-content-between align-items-center mt-0">
      
      <div class="user-card d-flex flex-column alig ">
        <h3 class="mt-5 mb-3">{{ $t("selectedUsers") }}:</h3>
        <p v-if="selectedUsers.length > 0">
          {{ selectedUsers.length }} {{ $t("selectedUsers") }}
        </p>
        <p v-else>{{ $t("noUsersSelected") }}</p>
      </div>
      <Button
        type="primary"
        :name="$t('import')"
        size="small"
        @clicked="openModal()"
      />
      
    </div>
    <div v-if="selectedUsers.length > 0">

      <Table :fields="fieldsSelected" class="selectedTable">
      
        <TableRow v-for="item in selectedUsers" :key="item.usuario_id" >
          
          <TableCell
            v-for="field in fieldsSelected"
            :key="field.key"
            :name="field.key"
            :style="field._style"
            :sort-direction="field.sortDirection"
          >
            <template v-slot:[field.key]>
              <span class="table-row-text">{{ item[field.key] }}</span>
            </template>
            <template v-slot:usuario_check>
              <input
                @change="selectUser(item)"
                v-model="item.selecionado"
                type="checkbox"
              />
            </template>
            <template v-slot:usuario_zeladoria>
              <span class="table-row-text" v-if="item.zeladoria !== null">{{ item.zeladoria.name }}</span>
              <span class="table-row-text" v-else>{{ $t('nothing') }}</span>
            </template>
            <template v-slot:usuario_subcontratada>
              <span class="table-row-text" v-if="item.subcontratada !== null">{{ item.subcontratada.name }}</span>
              <span class="table-row-text" v-else>{{ $t('nothing') }}</span>
            </template>
            
          </TableCell>
        </TableRow>
        <template v-if="users.length === 0" v-slot:no-results>
          <NoResults :message="$t('noResults')" />
        </template>
      </Table>
    </div>
    <h3 class="mt-5 ml-3">{{ $t("allUsersList") }}</h3>
    <Table :fields="fields">
      
      <TableRow v-for="item in users" :key="item.usuario_id">
        
        <TableCell
          v-for="field in fields"
          :key="field.key"
          :name="field.key"
          :style="field._style"
          :sort-direction="field.sortDirection"
        >
          <template v-slot:[field.key]>
            <span class="table-row-text">{{ item[field.key] }}</span>
          </template>
          <template v-slot:usuario_check>
            <input
              v-if="item.zeladoria && item.subcontratada"
              @change="selectUser(item)"
              v-model="item.selecionado"
              type="checkbox"
              :disabled="false"
            />
            <input
              v-else
              @change="selectUser(item)"
              v-model="item.selecionado"
              type="checkbox"
              :disabled="true"
            />
          </template>
          <template v-slot:area_zeladoria>
            <div v-if="!item.selecionado">
              <multiselect
                v-model="item.zeladoria"
                :noOptions="$t('emptyList')"
                :deselectLabel="$t('remove')"
                :selectedLabel="$t('selected')"
                selectLabel=""
                :options="keepingSectors"
                track-by="id"
                label="name"
                :placeholder="$t('selectKeepingSector')"
                :preselect-first="false"
                :multiple="false"
                :select-options="{
                  selectAllByGroup: false,
                }"
              ></multiselect>
            </div>
          </template>
          <template v-slot:area_subcontratadas>
            <div v-if="!item.selecionado">
              <multiselect
                v-model="item.subcontratada"
                :noOptions="$t('emptyList')"
                :deselectLabel="$t('remove')"
                :selectedLabel="$t('selected')"
                selectLabel=""
                :options="subcontractors"
                track-by="id"
                label="name"
                :placeholder="$t('selectSubcontractor')"
                :preselect-first="false"
                :multiple="false"
                :select-options="{
                  selectAllByGroup: false,
                }"
              ></multiselect>
            </div>
          </template>
        </TableCell>
      </TableRow>
      <template v-if="users.length === 0" v-slot:no-results>
        <NoResults :message="$t('noResults')" />
      </template>
    </Table>
  </div>
</template>

<script>
import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import NoResults from "../../components/table/NoResults.vue";
import TableCell from "../../components/table/TableCell.vue";
import Input from "../../components/form/Input.vue";
import Modal from "../../components/Modal.vue";
import services from "../../services";
import Multiselect from "vue-multiselect";

import { formatPhone } from "../../utils/phone.js";
import { formatCPF } from "../../utils/docs.js";
import { infoToast, validationToast, successToast } from "../../utils/toast";
import { debounce } from "../../utils/debounce";



export default {
  components: {
    BackArrow,
    Button,
    Multiselect,
    Table,
    TableRow,
    TableCell,
    NoResults,
    Input,
    Modal,
  },
  data() {
    return {
      columns: [
        {
          label: this.$t("name"),
          field: "usuario_nome",
        },
        {
          label: this.$t("phone"),
          field: "usuario_telefone",
        },
        {
          label: "CPF",
          field: "usuario_cpf",
        },
        
        {
          label: this.$t("keepingSector"),
          field: "usuario_zeladoria",
          sortable: false,
        },

        {
          label: this.$t("subcontractors"),
          field: "subcontratadas",
          sortable:false,
        },
      ],
      fields: [
        {
          key: "usuario_check",
          label: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_nome",
          label: "Nome",
          _style: "min-width:260px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_telefone",
          label: "Telefone",
          _style: "min-width:180px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_cpf",
          label: "CPF",
          _style: "min-width:90px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "area_zeladoria",
          label: "Zeladoria",
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "area_subcontratadas",
          label: "Subcontratadas",
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: null,
        },
        
      ],
      columnsSelected: [
        {
          label: this.$t("name"),
          field: "usuario_nome",
        },
        {
          label: this.$t("phone"),
          field: "usuario_telefone",
        },
        {
          label: "CPF",
          field: "usuario_cpf",
        },
        
        {
          label: this.$t("keepingSector"),
          field: "usuario_zeladoria",
          sortable: false,
        },

        {
          label: this.$t("subcontractors"),
          field: "usuario_subcontratadas",
          sortable:false,
        },
      ],
      fieldsSelected: [
        {
          key: "usuario_check",
          label: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_nome",
          label: "Nome",
          _style: "min-width:260px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_telefone",
          label: "Telefone",
          _style: "min-width:180px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_cpf",
          label: "CPF",
          _style: "min-width:90px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_zeladoria",
          label: "Zeladoria",
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_subcontratada",
          label: "Subcontratadas",
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: null,
        },
        
      ],
      users: [],
      selectedUsers: [],
      subcontractors: [],
      keepingSectors: [],
      searchUser: null,
      filter: null,
      filteredUsers: [],
      modalIsOpen: false,
      enableRadio: false,
    };
  },
  watch: {
    searchUser: debounce(async function(newVal){
      if(newVal){
        this.filter = newVal
        const {data, errors} = await services.users.searchUserList({filter: this.filter})
        if (!errors && data.length > 0){
          this.users = data.map((user) => {
          return {
            usuario_id: user.usuario_id,
            usuario_nome: user.usuario_nome,
            zeladoria: null,
            usuario_telefone: formatPhone(user.usuario_telefone),
            usuario_cpf: formatCPF(user.usuario_cpf),
            selecionado: false,
            subcontratada: null,
          };
        });
        }
      } 
      
    })
  },
  mounted: async function () {
    await this.verifyUserRole();
    await this.getUsers();
    await this.initSubcontractorsList()
    await this.initKeepingSectorsList();
  },
  methods: {
    openModal(){
      if(this.selectedUsers.length > 0){
        this.modalIsOpen = !this.modalIsOpen
      }
    },
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async initSubcontractorsList(){
      const {data, erros} = await services.subcontractors.getSubcontractors()
      if (!erros && data.length > 0) {
        this.subcontractors = data.map((item) => {
          return {
            name: item.subcontratada_descricao,
            id: item.subcontratada_id,
          }
        })
      }
    },
    async getUsers() {
      const { data, errors } = await services.users.usersListWithoutBond();
      if (!errors && data.length > 0) {
        this.users = data.map((user) => {
          return {
            usuario_id: user.usuario_id,
            usuario_nome: user.usuario_nome,
            zeladoria: null,
            usuario_telefone: formatPhone(user.usuario_telefone),
            usuario_cpf: formatCPF(user.usuario_cpf),
            selecionado: false,
            subcontratada: null,
          };
        });
      }
    },

    selectUser(user) {
      const selectedUser = this.users.find((u) => {
        return u.usuario_id === user.usuario_id;
      });

      if(selectedUser.zeladoria && selectedUser.subcontratada){
        
        if (user.selecionado) {
          this.selectedUsers.push(selectedUser);
        }
  
        if (!user.selecionado) {
          this.selectedUsers = this.selectedUsers.filter((u) => {
            return u.usuario_id !== user.usuario_id;
          });
        }
      } else {
        infoToast(this.$t("allUsersLinkInfo"), "info");

      }
    },

    formatUser(user) {
      return {
        usuario_id: user.usuario_id,
        zeladoria_id: user.zeladoria?.id,
        subcontratada_id: user.subcontratada?.id,
      };
    },

    formatUsers(users) {
      const formattedUsers = users.map((u) => {
        return {
          usuario_id: u.usuario_id,
          zeladoria_id: u.zeladoria?.id,
          subcontratada_id: u.subcontratada?.id,
        };
      });
      return formattedUsers;
    },

    validateUsers() {
      return this.selectedUsers.every((user) => {
        return user.zeladoria !== null && user.subcontratada !== null
        
      });
    },

    async importUsers() {
      const users = this.formatUsers(this.selectedUsers);
      if (this.validateUsers()) {
        const { errors } = await services.users.vinculateUsers({
          usuarios: users,
        });
        if (!errors) {
          successToast(this.$t("successImportUsers"), "success");
          await this.getUsers();
          this.selectedUsers = [];

        } else {
          validationToast(this.$t("errorImportUsers"), "error");

        }
      } else {
        infoToast(this.$t("allUsersLinkInfo"), "info");
      }

      this.modalIsOpen = false
    },

    async initKeepingSectorsList() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorsWithoutKeeper();
      if (!errors) {
        this.keepingSectors = data.map((item) => {
          return {
            name: item.zeladoria_nome,
            id: item.zeladoria_id,
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-btns{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.no-users{
  font-size: 1.1rem;
}
.selectedTable{
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 2.5rem;
}
.import {
  background-color: #fff;
  border-radius: 8px;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.user-card {
  font-size: 1rem;
  display: flex;
  align-items: start;
  padding: 1rem;

  p {
    margin: 0;
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: #838998;
  }
}
</style>

<template>
  <div class="neighborhoods">
    <h3 class="ml-3 intern-title">
      <back-arrow />
      <span class="page-nav">{{ $t("neighborhoods") }}</span>
    </h3>
    <div
      class="d-flex justify-content-between align-items-center ml-3 mb-4 mr-3"
    >
      <div>
        <input
          v-model="searchString"
          class="search-input"
          :search="true"
          :placeholder="$t('searchNeighborhood')"
        />
      </div>
      <router-link
        :to="{
          name: 'MapaBairro',
        }"
      >
        <Button
          size="small"
          color="warning"
          :name="$t('neighborhoodsMap')"
          type="primary"
        />
      </router-link>
    </div>
    <transition name="bounce">
      <div v-if="!loadingData">
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in neighborhoods" :key="item.bairro_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="neighborhoods.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />
  </div>
</template>

<script>
import services from "../../services";

import Table from "../../components/table/Table.vue";
import Button from "../../components/Button.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import BackArrow from "../../components/BackArrow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

import { debounce } from "../../utils/debounce";

export default {
  name: "Bairros",
  components: {
    Table,
    TableRow,
    TableCell,
    CondensedDropdown,
    NoResults,
    Button,
    Loading,
    BackArrow,
  },
  data() {
    return {
      date: new Date(),
      fields: [
        {
          key: "bairro_nome",
          label: this.$t("name"),
          _style: "min-width:200px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "status",
          label: "",
          _style: "width:1%",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "show_gallery",
          label: "",
          _style: "width:1%",
          sortable: false,
          sortDirection: null,
        },
      ],
      details: [],
      neighborhoods: [],
      loadingData: true,
      searchString: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
    };
  },
  mounted: async function () {
    await this.getNeighborhoods();
    this.loadingData = false;
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.getNeighborhoods();
    }, 500),
  },
  methods: {
    async getNeighborhoods() {
      const { data, errors } = await services.neighborhoods.getNeighborhoodsNew(
        this.pagination
      );
      if (!errors) {
        this.neighborhoods = data.map((item, id) => {
          return { ...item, id };
        });
      }
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "details") {
        this.$router.push({
          name: "DetailsBairro",
          params: { id: item.bairro_id },
        });
      }
      if (value === "gallery") {
        this.$router.push({
          name: "GaleriaBairro",
          params: { id: item.bairro_id },
        });
      }
    },
    optionsByResearch() {
      return [
        { label: "Detalhes", value: "details" },
        { label: "Galeria", value: "gallery" },
      ];
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.neighborhoods.getNeighborhoodsNew(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item, id) => {
            return { ...item, id };
          });
          temp.forEach((item) => {
            this.neighborhoods.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.neighborhoods {
  background-color: #fff;
}

.links {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.search-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c6c9d0;
  box-sizing: border-box;
  border-radius: 12px;
  width: 500px;
  height: 48px;

  &:focus {
    outline: none;
    border: 1px solid #00264f;
  }

  &:placeholder {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #b0b4bd;
  }
}
</style>

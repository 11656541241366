<template>
  <div>
    <Loading v-if="loadingData" />
    <div v-else class="charts-card">
      <div v-if="yAxis.length > 0" class="chart">
        <Bars
          :hideLabel="true"
          :beginAtZero="true"
          :newGraphY="yAxis"
          :newGraphX="xAxis"
          class="bar"
        />
      </div>

      <div class="chart-labels">
        <div
          class="label-item"
          v-for="(label, index) in labelItems"
          :key="index"
        >
          <div :style="`background-color: ${label.color}`" class="color"></div>
          <span class="name">{{ label.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatDate } from "../../utils/date";
import services from "../../services";
import Bars from "../../components/Bars.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

export default {
  components: {
    Bars,
    Loading,
  },
  props: {
    dates: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  mounted: async function () {
    this.loadingData = true;
    const activities = await services.activities.getActivities();
    this.activities = activities.data.map((activity) => {
      return {
        id: activity.atividade_id,
        name: activity.atividade_nome,
      };
    });
    this.calculatePeriods();
    await this.getPeriodsInfo();
    this.loadingData = false;
  },

  data() {
    return {
      loadingData: false,
      activities: [],
      periods: [],
      labelItems: [],
      xAxis: [],
      yAxis: [],
      formatDate,
      boca: [],
      capina: [],
      descarte: [],
      educacao: [],
      hortas: [],
      infraestrutura: [],
      lixeiras: [],
      solicitacoes: [],
      varricao: [],
      limpezas : [],
    };
  },
  methods: {
    setYAxisData(atv) {
      if (atv.length > 0) {
        return atv.map((item) => {
          return item.total;
        });
      }
      return [];
    },
    calculatePeriods() {
      const firstPeriod = {
        id: 0,
        dates: {
          start: moment(this.dates.start).toDate().toISOString(),
          end: moment(this.dates.start).add(6, "d").toDate().toISOString(),
        },
        info: {
          activities: [],
          xAxis: [],
        },
      };
      const secondPeriod = {
        id: 1,
        dates: {
          start: moment(this.dates.start).add(7, "d").toDate().toISOString(),
          end: moment(this.dates.start).add(13, "d").toDate().toISOString(),
        },
        info: {
          activities: [],
          XAxis: [],
        },
      };
      const thirdPeriod = {
        id: 2,
        dates: {
          start: moment(this.dates.start).add(14, "d").toDate().toISOString(),
          end: moment(this.dates.start).add(20, "d").toDate().toISOString(),
        },
        info: {
          activities: [],
          xAxis: [],
        },
      };
      const fourthPeriod = {
        id: 3,
        dates: {
          start: moment(this.dates.start).add(21, "d").toDate().toISOString(),
          end: moment(this.dates.end).toDate().toISOString(),
        },
        info: {
          activities: [],
          xAxis: [],
        },
      };
      /*const fifthPeriod = {
        id: 3,
        dates: {
          start: moment(this.dates.start).add(28, "d").toDate().toISOString(),
          end: moment(this.dates.end).toDate().toISOString(),
        },
        info: {
          activities: [],
          xAxis: [],
        },
      };*/

      this.periods.push(firstPeriod, secondPeriod, thirdPeriod, fourthPeriod);
      this.xAxis = [];
      this.xAxis.push(
        formatDate(firstPeriod.dates.start) +
          " a " +
          formatDate(firstPeriod.dates.end)
      );
      this.xAxis.push(
        formatDate(secondPeriod.dates.start) +
          " a " +
          formatDate(secondPeriod.dates.end)
      );
      this.xAxis.push(
        formatDate(thirdPeriod.dates.start) +
          " a " +
          formatDate(thirdPeriod.dates.end)
      );
      this.xAxis.push(
        formatDate(fourthPeriod.dates.start) +
          " a " +
          formatDate(fourthPeriod.dates.end)
      );
      /*
        if(!moment(this.dates.start).month() === 1) {
          this.periods.push(fifthPeriod);
          this.xAxis.push(formatDate(fifthPeriod.dates.start) + " a " + formatDate(fifthPeriod.dates.end))
        }
        */
    },

    verifyWeek(week) {
      console.log('week',week);
      
      week.forEach((item) => {
        if (item.nome === "Boca de Lobo") this.boca.push(item);
        if (item.nome === "Capina") this.capina.push(item);
        if (item.nome === "Descarte Irregular") this.descarte.push(item);
        if (item.nome === "Educação Ambiental") this.educacao.push(item);
        if (item.nome === "Hortas Comunitárias") this.hortas.push(item);
        if (item.nome === "Limpeza de Vias") this.limpezas.push(item);
        if (item.nome === "Infraestrutura Urbana")
          this.infraestrutura.push(item);
        if (item.nome === "Lixeiras Comunitárias") this.lixeiras.push(item);
        if (item.nome === "Solicitações de Moradores")
          this.solicitacoes.push(item);
        if (item.nome === "Varrição") this.varricao.push(item);
      });
    },

    async request() {
      let totalPorPeriodo = [];
      for (let i = 0; i < this.periods.length; i++) {
        const { data } = await services.users.getUserActivityStatistics({
          initialDate: this.periods[i].dates.start,
          finalDate: this.periods[i].dates.end,
          userId: this.user.usuario_id,
        });
        let periodoObj = [];
        console.log('data', data);
        periodoObj = data.map((atv) => {
          console.log('atividade', atv);
          return {
            total: atv.execucoes,
            id: atv.atividade_id,
            nome: atv.atividade_nome,
          };
        });
        totalPorPeriodo = [];
        totalPorPeriodo.push(periodoObj);
        totalPorPeriodo.forEach((week) => {
          this.verifyWeek(week);
        });
      }
    },

    async getPeriodsInfo() {
      this.yAxis = [];
      await this.request();
      const atividades = [
        this.boca,
        this.capina,
        this.descarte,
        this.educacao,
        this.hortas,
        this.infraestrutura,
        this.lixeiras,
        this.solicitacoes,
        this.varricao,
        this.limpezas
      ];
      console.log('atividades2',atividades);

      console.log('atividades2',atividades[atividades.length - 1]);
      for (let i = 0; i < atividades.length; i++) {
        if(atividades[i].length) {
          this.yAxis.push({
          label: atividades[i][0].nome,
          backgroundColor: this.generateLineChartColorByActivity(
            atividades[i][0].id
          ),
          borderColor: this.generateLineChartColorByActivity(
            atividades[i][0].id
          ),
          data: this.setYAxisData(atividades[i]),
          id: atividades[i][0].id,
        });
        }
      }
      this.yAxis.forEach((item) => {
        this.labelItems.push({
          name: item.label,
          color: item.backgroundColor,
        });
      });
    },

    generateLineChartColorByActivity(activityId) {
      if (activityId === 100) return "#9AC625";
      if (activityId === 101) return "#5CCDF2";
      if (activityId === 102) return "#E06914";
      if (activityId === 103) return "#003063";
      if (activityId === 104) return "#008EBC ";
      if (activityId === 105) return "#6C8B1A";
      if (activityId === 106) return "#EDA209";
      if (activityId === 107) return "#D3341D";
      if (activityId === 108) return "#B30020";
      if (activityId === 109) return "#775105 ";
      else return "#212121";
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-card {
  padding: 24px;
  .chart-labels {
    border: 1px solid #dddee3;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 64px;
    border-radius: 16px;
    padding: 24px;
    .label-item {
      display: flex;
      gap: 5px;
      align-items: center;
      .name {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }
      .color {
        width: 16px;
        height: 16px;
        border-radius: 4px;
      }
    }
  }

  .period {
    .title {
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #565e73;
      border-bottom: 1px solid #dddee3;
      padding-bottom: 12px;
    }
  }
  .chart {
    overflow: auto;
  }
  .bar {
    min-width: 800px;
  }
}
</style>

<template>
  <div class="activities p-3">
    <h3 class="ml-2 intern-title">
      <back-arrow />
      <span class="pagetitle pl-2">{{ $t("keeperContract") }}</span>
    </h3>
    <transition name="bounce">
      <div v-if="!loadingData">
        <CCardBody>
          <Table :fields="fields" :hasActions="true">
            <TableRow v-for="item in fix" :key="item.contrato_zelador_id">
              <TableCell
                v-for="field in fields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  {{ item[field.key] }}
                </template>
              </TableCell>
              <TableCell>
                <div style="display: flex; justify-content: center">
                  <Button
                    v-if="userType === 1"
                    :name="$t('edit')"
                    size="small"
                    class="ml-1"
                    @clicked="handleEditContract(item)"
                  />
                </div>
              </TableCell>
            </TableRow>
            <template v-if="fix.length === 0" v-slot:no-results>
              <NoResults :message="$t('emptyContracts')" />
            </template>
          </Table>

          <!--        <infinite-loading @infinite="infiniteHandler">
              <div slot="spinner">
                <Loading />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
-->
        </CCardBody>
      </div>
    </transition>

    <Loading v-if="loadingData" />

    <Modal v-show="editModal" :height="600" :width="800">
      <template v-slot:header> {{ $t("editContract") }} </template>
      <template v-slot:body>
        <Input
          name="type"
          :label="$t('type')"
          v-model="contractType"
          disabled="disabled"
        />
        <VueEditor
          id="box"
          name="content"
          :label="$t('content')"
          useCustomImageHandler
          v-model="editContent"
        >
        </VueEditor>
      </template>
      <template v-slot:footer class="footer">
        <Button
          type="outline"
          :name="$t('cancel')"
          class="w-25"
          @clicked="editModal = false"
        />
        <Button @clicked="saveEdit" :name="$t('save')" class="mr-2 w-25" />
      </template>
    </Modal>
  </div>
</template>

  <script>
/* eslint-disable*/
import BackArrow from "../../components/BackArrow.vue";
import services from "../../services";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/form/Input.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

import { VueEditor } from "vue2-editor";

export default {
  name: "Contract",
  components: {
    VueEditor,
    BackArrow,
    Table,
    TableRow,
    TableCell,
    NoResults,
    Button,
    Loading,
    Modal,
    Input,
  },
  data() {
    return {
      date: new Date(),
      details: [],
      contracts: [],
      editModal: false,
      fix: [],
      contractId: 0,
      contractType: "",
      editContent: "",
      loadingData: true,
      userType: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      fields: [
        {
          key: "contrato_zelador_tipo_descricao",
          label: this.$t("type"),
          _style: "min-width:80px;",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_gestor_nome",
          label: this.$t("responsible"),
          _style: "min-width:200px",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "contrato_zelador_data",
          label: this.$t("date"),
          _style: "min-width:80px;",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
      ],
    };
  },
  mounted: async function () {
    await this.initAllData();
    this.loadingData = false;
  },
  methods: {
    async initAllData() {
      this.fixData();
      this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
    },
    async fixData() {
      const { data } = await services.contracts.getContractList();
      this.fix = data.map((item) => {
        return {
          ...item,
          contrato_zelador_data: item.contrato_zelador_data
            .slice(0, 10)
            .split("-")
            .reverse()
            .join("/"),
        };
      });
    },
    async handleEditContract(item) {
      this.contractId = item.contrato_zelador_id;
      this.contractType = item.contrato_zelador_tipo_descricao;
      this.editContent = item.contrato_zelador_texto;
      this.editModal = true;
    },
    async saveEdit() {
      await services.contracts.putContracts({
        contrato_zelador_id: this.contractId,
        contrato_zelador_texto: this.editContent,
      });
      await this.initAllData();
      this.editModal = false;
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.activities.getAllActivitiesNew(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          data.forEach((item) => {
            this.activities.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>
  <style lang="scss">
#box {
  height: fit-content;
}
.pagetitle {
  font-family: "OpenSans-Bold";
  font-weight: 700;
  font-size: 20px;
  line-height: 166.5%;
  color: #043464;
}
.label {
  margin-top: 10px;
  color: #747474;
}
</style>

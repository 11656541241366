<template>
  <div class="ml-3">
    <div class="d-flex align-items-center mb-4 mt-1">
      <svg
        @click="backModal = true"
        class="arrow-icon pointer"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.828 7.00005H16V9.00005H3.828L9.192 14.364L7.778 15.778L0 8.00005L7.778 0.222046L9.192 1.63605L3.828 7.00005Z"
          fill="#29334E"
        />
      </svg>
      <span class="page-nav position-relative"
        ><span v-if="!research">Nova pesquisa</span
        ><span v-else> {{ research.atividade_pesquisa_titulo }}</span>
        <div :class="tagStyle" class="status-tag">
          {{ status }}
        </div>
      </span>
    </div>

    <div class="steps">
      <div class="pointer" @click="step = 1">
        <span
          class="counter"
          :class="
            step === 1
              ? 'counter-true'
              : step > 1
              ? 'counter-false'
              : 'counter-disabled'
          "
          >1</span
        >
        <span
          class="label"
          :class="step === 1 || step > 1 ? 'label-true' : 'label-false'"
          >Definições</span
        >
      </div>

      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.17217 6.99999L0.222168 2.04999L1.63617 0.635986L8.00017 6.99999L1.63617 13.364L0.222168 11.95L5.17217 6.99999Z"
          fill="#B0B4BD"
        />
      </svg>

      <div class="pointer" @click="$route.params.id ? (step = 2) : ''">
        <span
          class="counter"
          :class="
            step === 2
              ? 'counter-true'
              : step > 2
              ? 'counter-false'
              : 'counter-disabled'
          "
          >2</span
        >
        <span
          class="label"
          :class="step === 2 || step > 2 ? 'label-true' : 'label-false'"
          >Perguntas</span
        >
      </div>

      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.17217 6.99999L0.222168 2.04999L1.63617 0.635986L8.00017 6.99999L1.63617 13.364L0.222168 11.95L5.17217 6.99999Z"
          fill="#B0B4BD"
        />
      </svg>

      <div class="pointer" @click="$route.params.id ? (step = 3) : ''">
        <span
          class="counter"
          :class="
            step === 3
              ? 'counter-true'
              : step > 3
              ? 'counter-false'
              : 'counter-disabled'
          "
          >3</span
        >
        <span
          class="label"
          :class="step === 3 || step > 3 ? 'label-true' : 'label-false'"
          >Local</span
        >
      </div>
    </div>

    <FormContainer
      @cancel-form="cancelResearch"
      @next="step < 3 && step++"
      @save-draft="
        modalType = 0;
        openModal('draft');
      "
      @send-approval="
        modalType = 1;
        approve();
      "
      :step="step"
    />

    <FinalModal
      :open="confirmModal"
      @close="closeFinalModal"
      :body="modalTypes[modalType]"
    />

    <BackModal
      :open="backModal"
      @cancel="cancelResearch()"
      @close="backModal = false"
    />
  </div>
</template>

<script>
import FormContainer from "./components/FormContainer.vue";

import FinalModal from "./modal/FinalModal.vue";
import BackModal from "./modal/BackModal.vue";

import services from "../../services";

import soundFinish from "../../assets/sounds/hero_simple-celebration-03.mp3";

export default {
  name: "ResearchForm",
  components: {
    FormContainer,
    FinalModal,
    BackModal,
  },
  data() {
    return {
      step: 1,
      soundFinish: new Audio(soundFinish),
      confirmModal: false,
      backModal: false,
      research: null,
      modalTypes: [
        "Sua pesquisa foi salva como rascunho.",
        "Sua pesquisa foi enviada para aprovação.",
      ],
      modalType: 0,
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    if (!this.prevRoute) this.step = 2;

    if (this.$route.params.id) {
      this.getResearchById();
    }
  },
  computed: {
    tagStyle() {
      if (!this.research) return "sketch";
      if (this.research.atividade_pesquisa_status_id === 1) return "sketch";
      if (this.research.atividade_pesquisa_status_id === 2) return "waiting";
      if (this.research.atividade_pesquisa_status_id === 3) return "published";
      if (this.research.atividade_pesquisa_status_id === 4) return "finished";
      if (this.research.atividade_pesquisa_status_id === 5) return "canceled";
      return "sketch";
    },
    status() {
      if (!this.research) return "rascunho";
      else return this.research.atividade_pesquisa_status_descricao;
    },
  },
  methods: {
    async openModal(type) {
      if (this.step < 3) return;

      if (type === "draft") {
        this.confirmModal = true;
        setTimeout(async () => {
          await this.soundFinish.play();
        }, 10);
      }
    },
    cancelResearch() {
      this.backModal = false;
      this.$router.push("/pesquisas");
    },
    async approve() {
      const { errors } = await services.researches.changeStatus(
        this.$route.params.id,
        {
          atividade_pesquisa_status_id: 2,
        }
      );

      if (!errors) {
        this.modalType = 1;
        setTimeout(async () => {
          this.openModal("draft");
        }, 200);
      }
    },
    async getResearchById() {
      const { data, errors } = await services.researches.getResearchById(
        this.$route.params.id
      );
      if (!errors) {
        this.research = data;
      }
    },
    closeFinalModal() {
      this.confirmModal = false;

      if (this.modalType === 1) {
        this.$router.push("/pesquisas");
        return;
      }

      this.modalType = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.steps {
  display: flex;
  padding-bottom: 32px;
  border-bottom: 1px solid #f3f4f5;
}

svg {
  margin: 0 16px;
  margin-top: 9px;
}

.arrow-icon {
  margin: 0;
  margin-right: 20px;
}

.counter {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 6px;
  margin-right: 8px;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.status-tag {
  position: absolute;
  top: 8px;
  right: -100px;

  font-family: "Poppins";
  font-weight: 500;
  font-size: 10px;
  line-height: 156%;

  border-radius: 4px;
  padding: 2px 8px;
  text-transform: uppercase;
}

.waiting {
  right: -170px !important;
}

.counter-true {
  background-color: #00b1eb;
  color: #ffffff;
}

.counter-disabled {
  background-color: #fff;
  border: 1px solid #b0b4bd;
  color: #b0b4bd;
}

.counter-false {
  background-color: #003063;
  color: #ffffff;
}

.label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.label-true {
  color: #131e3b;
}

.label-false {
  color: #b0b4bd;
}
</style>

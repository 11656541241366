export default httpClient => ({
    getRelatorio: async (payload) => {
      const response = await httpClient.post(`/execution-avaliacao/monthly-cycle`, payload)
      let errors = null
  
      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    getGrafico: async (payload) => {
      const response = await httpClient.post(`/execution-avaliacao/chart/monthly-cycle`, payload)
      let errors = null
  
      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
  })
  
<template>
  <div class="policies ml-3">
    <h3 class="intern-title">
      <back-arrow />
      <span>{{ $t("settings") }}</span>
    </h3>
    <transition name="bounce">
      <div v-if="!loadingData">
        <Button
          @clicked="handlePolicyCreate"
          :name="$t('createSetting')"
          size="small"
        />
        <Table :fields="fields" :hasActions="true" class="table-delay">
          <TableRow v-for="item in configs" :key="item.config_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
            </TableCell>
            <TableCell>
              <div class="table-options">
                <div title="Editar" @click="handlePolicyEdit(item)">
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.414 14.0001L13.556 3.85808L12.142 2.44408L2 12.5861V14.0001H3.414ZM4.243 16.0001H0V11.7571L11.435 0.322083C11.6225 0.134612 11.8768 0.0292969 12.142 0.0292969C12.4072 0.0292969 12.6615 0.134612 12.849 0.322083L15.678 3.15108C15.8655 3.33861 15.9708 3.59292 15.9708 3.85808C15.9708 4.12325 15.8655 4.37756 15.678 4.56508L4.243 16.0001ZM0 18.0001H18V20.0001H0V18.0001Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                </div>
              </div>
            </TableCell>
          </TableRow>
          <template v-if="configs.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <Modal v-show="policyCreateEditModal" :width="600">
      <template v-slot:header>
        {{ modalType === "edit" ? $t("edit") : $t("create") }}
        {{ $t("setting") }}
      </template>
      <template v-slot:body>
        <Input
          name="id"
          :disabled="modalType === 'edit'"
          :label="$t('code')"
          type="number"
          v-model="policy.config_id"
        />
        <Input
          name="description"
          :label="$t('description')"
          v-model="policy.config_descricao"
        />
        <Input
          name="value"
          :label="$t('value')"
          v-model="policy.config_valor"
        />
      </template>
      <template v-slot:footer>
        <Button
          type="outline"
          :name="$t('cancel')"
          class="w-25"
          @clicked="policyCreateEditModal = false"
        />
        <Button
          @clicked="handleConfigSubmit"
          :name="$t('save')"
          class="mr-2 w-25"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import services from "../../services";

import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import BackArrow from "../../components/BackArrow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/form/Input.vue";

import { successToast } from "../../utils/toast";

export default {
  name: "Policies",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Loading,
    Button,
    BackArrow,
    Modal,
    Input,
  },
  data() {
    return {
      date: new Date(),
      fields: [
        {
          key: "config_descricao",
          label: this.$t("description"),
          _style: "min-width:200px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "config_id",
          label: this.$t("code"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      policies: [],
      configs: [],
      policy: {},
      modalType: "",
      policyCreateEditModal: false,
      policyModal: false,
      loadingData: true,
    };
  },
  mounted: async function () {
    await this.initConfig();
    this.loadingData = false;
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async initConfig() {
      const { data, errors } = await services.config.getAllConfig();
      if (!errors) {
        this.configs = data;
      }
    },
    handlePolicyEdit(item) {
      this.policyCreateEditModal = true;
      this.modalType = "edit";
      this.policy = item;
    },
    handlePolicyCreate() {
      this.policyCreateEditModal = true;
      this.modalType = "create";
      this.policy = {};
    },
    async saveEdit() {
      const { errors } = await services.config.editConfig(this.policy);
      if (!errors) {
        await this.initConfig();
        this.policyCreateEditModal = false;
      }
    },
    async saveCreate() {
      const { errors } = await services.config.createConfig(this.policy);
      if (!errors) {
        await this.initConfig();
        this.policyCreateEditModal = false;
      }
    },
    async handleConfigSubmit() {
      if (this.modalType === "edit") {
        await this.saveEdit();
      } else {
        await this.saveCreate();
      }
    },
    initialConditions() {
      this.modalType = "";
      this.policyModal = false;
      this.policy = {};
    },
    async newVersionOfPolicy() {
      const { errors } = await services.policies.newVersionPolicy({
        contrato_id: this.policy.contrato_id,
        usuario_id: this.$store.state.global.user.usuario_gestor_id,
      });
      if (!errors) {
        successToast("Versão alterada para revisão!");
        await this.initConfig();
        this.initialConditions();
      }
    },
    async cancelReviewPolicy() {},
    async releasePolicy() {
      const { errors } = await services.policies.releasePolicy({
        contrato_id: this.policy.contrato_id,
      });
      if (!errors) {
        await this.initConfig();
        this.initialConditions();
        successToast("Versão vigente alterada com sucesso!");
      }
    },
    onClickAddPolicy() {
      this.$router.push(`/politicas/cadastro`);
    },
    handleEditClick(item) {
      this.$router.push(`/politicas/editar/${item.contrato_id}`);
    },
    async handleSubmitModal() {
      if (this.modalType === "review") {
        await this.newVersionOfPolicy();
      } else if (this.modalType === "cancel") {
        this.cancelReviewPolicy();
      } else if (this.modalType === "release") {
        this.releasePolicy();
      }
      this.policyModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.policies {
  background-color: #fff;
  border-radius: 8px;

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;

    div {
      cursor: pointer;
    }
  }
}

.table-delay {
  margin-left: -16px;
}
</style>

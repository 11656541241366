<template>
  <div class="textarea-wrapper">
    <label class="input-label" :for="name">{{ label }}</label>
    <textarea
      :name="name"
      type="text"
      class="textarea w-100"
      :value="value"
      :rows="rows"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "CustomTextarea",
  props: {
    value: {
      type: String || null,
      required: false,
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 10,
    },
  },
};
</script>

<style scoped>
.textarea-wrapper {
  margin: 10px 0;
}

.textarea-label {
  color: #747474;
}

.textarea {
  resize: none;
  width: 304px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #001832;
  padding: 6px 0px 2px 12px;
}

.textarea:focus {
  outline: none;
}

.textarea::placeholder {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #838998;
}
</style>

<template>
  <div class="researches-report ml-3">
    <div class="d-flex justify-content-between">
      <h3 class="intern-title">
        <back-arrow class="print-hide" />
        <span class="page-nav">{{
          researchData.atividade_pesquisa_titulo
        }}</span>
      </h3>
      <svg
        @click="print"
        class="pointer print-hide"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#003063" />
        <path
          d="M12.6668 19.333H19.3335V22.6663H12.6668V19.333ZM20.6668 21.333V17.9997H11.3335V21.333H10.0002C9.82335 21.333 9.65378 21.2628 9.52876 21.1377C9.40373 21.0127 9.3335 20.8432 9.3335 20.6663V13.9997C9.3335 13.8229 9.40373 13.6533 9.52876 13.5283C9.65378 13.4032 9.82335 13.333 10.0002 13.333H22.0002C22.177 13.333 22.3465 13.4032 22.4716 13.5283C22.5966 13.6533 22.6668 13.8229 22.6668 13.9997V20.6663C22.6668 20.8432 22.5966 21.0127 22.4716 21.1377C22.3465 21.2628 22.177 21.333 22.0002 21.333H20.6668ZM11.3335 14.6663V15.9997H13.3335V14.6663H11.3335ZM12.6668 9.33301H19.3335C19.5103 9.33301 19.6799 9.40325 19.8049 9.52827C19.9299 9.65329 20.0002 9.82286 20.0002 9.99967V11.9997H12.0002V9.99967C12.0002 9.82286 12.0704 9.65329 12.1954 9.52827C12.3204 9.40325 12.49 9.33301 12.6668 9.33301Z"
          fill="white"
        />
      </svg>
    </div>

    <div class="mb-4 d-flex align-items-center print-hide">
      <multiselect
        v-model="selectedNeighborhoods"
        @input="handleNeighborhoodsChange"
        :options="neighborhoodList"
        track-by="name"
        :showLabels="false"
        placeholder="Filtrar por bairro"
        :multiple="false"
        label="name"
        class="neighborhood-selector"
      ></multiselect>

      <multiselect
        v-model="selectedKeepingSectors"
        :options="keepingSectorsList"
        @input="handleKeepingSectorsChange"
        track-by="name"
        placeholder="Filtrar por AZ"
        :showLabels="false"
        :multiple="false"
        label="name"
        :disabled="keepingSectorsList.length === 0"
        class="neighborhood-selector"
      ></multiselect>

      <Button
        size="small"
        name="Filtrar resultados"
        @clicked="
          handleRequest();
          alreadyFiltered = true;
        "
      />
    </div>

    <div
      v-if="selectedNeighborhoods && alreadyFiltered"
      class="mb-4 print-hide"
    >
      <div class="showing-label">Mostrando resultados em:</div>
      <div class="showing-value">
        {{
          selectedKeepingSectors
            ? selectedKeepingSectors.name
            : selectedNeighborhoods.name
        }}
        <svg
          @click="handleClearFilters"
          class="icon-close"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.00048 4.05781L8.30048 0.757812L9.24315 1.70048L5.94315 5.00048L9.24315 8.30048L8.30048 9.24315L5.00048 5.94315L1.70048 9.24315L0.757812 8.30048L4.05781 5.00048L0.757812 1.70048L1.70048 0.757812L5.00048 4.05781Z"
            fill="#131E3B"
          />
        </svg>
      </div>
    </div>

    <div class="summary">
      <div class="summary-item">
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
            fill="black"
          />
        </svg>
        <div class="summary-data">
          <div class="data__label">Participantes</div>
          <div class="data__quantity">{{ participants }}</div>
        </div>
      </div>
      <svg
        width="2"
        height="32"
        viewBox="0 0 2 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.16699"
          y1="2.18557e-08"
          x2="1.16699"
          y2="32"
          stroke="#C6C9D0"
        />
      </svg>

      <div class="summary-item">
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.6665 2H19.6665C19.9317 2 20.1861 2.10536 20.3736 2.29289C20.5611 2.48043 20.6665 2.73478 20.6665 3V19C20.6665 19.2652 20.5611 19.5196 20.3736 19.7071C20.1861 19.8946 19.9317 20 19.6665 20H1.6665C1.40129 20 1.14693 19.8946 0.959397 19.7071C0.771861 19.5196 0.666504 19.2652 0.666504 19V3C0.666504 2.73478 0.771861 2.48043 0.959397 2.29289C1.14693 2.10536 1.40129 2 1.6665 2H5.6665V0H7.6665V2H13.6665V0H15.6665V2ZM13.6665 4H7.6665V6H5.6665V4H2.6665V8H18.6665V4H15.6665V6H13.6665V4ZM18.6665 10H2.6665V18H18.6665V10Z"
            fill="black"
          />
        </svg>

        <div class="summary-data">
          <div class="data__label">Vigência</div>
          <div class="data__quantity">{{ researchDates() }}</div>
        </div>
      </div>
      <svg
        width="2"
        height="32"
        viewBox="0 0 2 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.16699"
          y1="2.18557e-08"
          x2="1.16699"
          y2="32"
          stroke="#C6C9D0"
        />
      </svg>

      <div class="summary-item">
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
            fill="black"
          />
        </svg>
        <div class="summary-data">
          <div class="data__label">Status</div>
          <div class="data__quantity status-tag" :class="tagStyle(3)">
            {{ researchData.atividade_pesquisa_status_descricao }}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between print-hide">
      <div class="tabs">
        <div
          class="tab pointer"
          :class="tabActive === 1 && 'active'"
          @click="tabActive = 1"
        >
          Resultados
        </div>
        <!--  <div
          class="tab disabled"
          :class="tabActive === 2 && 'active'"
          @click="tabActive = 1"
        >
          Extrato
        </div> -->
      </div>
      <div>
        <div
          class="neighborhood-switch"
          v-if="
            selectedNeighborhoods && alreadyFiltered && !selectedKeepingSectors
          "
        >
          <Transition name="filter">
            <FilterNeighborhood
              class="filter-modal"
              :neighborhoods="neighborhoodList"
              v-if="neighborhoodModal"
              :selectedId="selectedNeighborhoods.id"
              @close="neighborhoodModal = false"
              @filter="handleFilterNeighborhood"
            />
          </Transition>

          <div
            v-if="tabActive === 1"
            @click="neighborhoodModal = !neighborhoodModal"
          >
            Comparar bairros
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.1665 8.16602V4.83268H9.83317V8.16602H13.1665V9.83268H9.83317V13.166H8.1665V9.83268H4.83317V8.16602H8.1665ZM8.99984 17.3327C4.39734 17.3327 0.666504 13.6018 0.666504 8.99935C0.666504 4.39685 4.39734 0.666016 8.99984 0.666016C13.6023 0.666016 17.3332 4.39685 17.3332 8.99935C17.3332 13.6018 13.6023 17.3327 8.99984 17.3327ZM8.99984 15.666C10.7679 15.666 12.4636 14.9636 13.7139 13.7134C14.9641 12.4632 15.6665 10.7675 15.6665 8.99935C15.6665 7.23124 14.9641 5.53555 13.7139 4.2853C12.4636 3.03506 10.7679 2.33268 8.99984 2.33268C7.23173 2.33268 5.53603 3.03506 4.28579 4.2853C3.03555 5.53555 2.33317 7.23124 2.33317 8.99935C2.33317 10.7675 3.03555 12.4632 4.28579 13.7134C5.53603 14.9636 7.23173 15.666 8.99984 15.666Z"
                fill="#003063"
              />
            </svg>
          </div>
        </div>

        <div
          class="neighborhood-switch"
          v-if="selectedKeepingSectors && alreadyFiltered"
        >
          <Transition name="filter">
            <FilterKeepingSectors
              class="filter-modal"
              v-if="keepingSectorModal"
              @close="keepingSectorModal = false"
              :keepingSectors="keepingSectorsList"
              :selectedId="selectedKeepingSectors.id"
              @filter="handleFilterKeepingSector"
            />
          </Transition>

          <div
            v-if="tabActive === 1"
            @click="keepingSectorModal = !keepingSectorModal"
          >
            Comparar AZs
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.1665 8.16602V4.83268H9.83317V8.16602H13.1665V9.83268H9.83317V13.166H8.1665V9.83268H4.83317V8.16602H8.1665ZM8.99984 17.3327C4.39734 17.3327 0.666504 13.6018 0.666504 8.99935C0.666504 4.39685 4.39734 0.666016 8.99984 0.666016C13.6023 0.666016 17.3332 4.39685 17.3332 8.99935C17.3332 13.6018 13.6023 17.3327 8.99984 17.3327ZM8.99984 15.666C10.7679 15.666 12.4636 14.9636 13.7139 13.7134C14.9641 12.4632 15.6665 10.7675 15.6665 8.99935C15.6665 7.23124 14.9641 5.53555 13.7139 4.2853C12.4636 3.03506 10.7679 2.33268 8.99984 2.33268C7.23173 2.33268 5.53603 3.03506 4.28579 4.2853C3.03555 5.53555 2.33317 7.23124 2.33317 8.99935C2.33317 10.7675 3.03555 12.4632 4.28579 13.7134C5.53603 14.9636 7.23173 15.666 8.99984 15.666Z"
                fill="#003063"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1">
      <div v-if="tabActive === 1">
        <div
          class="d-flex justify-content-around w-100 mt-3"
          v-if="filteredNeighborhoods.length"
        >
          <div class="total-filtered">
            Participantes totais: {{ comparativeNumber }}
          </div>
          <div
            v-for="(neighborhood, index) of filteredNeighborhoods"
            :key="'filtered-neighborhood' + neighborhood.id"
          >
            <span class="filtered" :style="`--to-color: ${colors[index]};`">{{
              neighborhood.name
            }}</span>
          </div>
        </div>

        <div
          class="d-flex justify-content-around w-100 mt-3"
          v-if="filteredKeepingSectors.length"
        >
          <div class="total-filtered">
            Participantes totais: {{ comparativeNumber }}
          </div>
          <div
            v-for="(keepingSector, index) of filteredKeepingSectors"
            :key="'filtered-neighborhood' + keepingSector.id"
          >
            <span class="filtered" :style="`--to-color: ${colors[index]};`">{{
              keepingSector.name
            }}</span>
          </div>
        </div>

        <ResultsPanel
          v-if="!isLoading"
          :isFiltered="
            filteredNeighborhoods.length || filteredKeepingSectors.length
              ? true
              : false
          "
          :questions="questions"
          :filteredQuestions="filteredQuestions"
        />

        <div
          class="d-flex justify-content-center align-items-center mt-2 mb-4"
          v-if="isLoading"
        >
          <img
            :src="require(`@/assets/img/loading.gif`)"
            class="mb-4 pday-resume-box-galleryb-4"
            alt="AMA carregando"
            width="150"
          />
        </div>
      </div>
      <div v-if="tabActive === 2">
        <ExtractPanel />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import BackArrow from "../../components/BackArrow.vue";
import ResultsPanel from "./components/ResultsPanel.vue";
import ExtractPanel from "./components/ExtractPanel.vue";
import FilterNeighborhood from "./components/FilterNeighborhood.vue";
import FilterKeepingSectors from "./components/FilterKeepingSectors.vue";

import Button from "../../components/Button.vue";

import services from "../../services";

export default {
  name: "ResearchesReport",
  components: {
    BackArrow,
    ResultsPanel,
    Multiselect,
    Button,
    ExtractPanel,
    FilterNeighborhood,
    FilterKeepingSectors,
  },
  data() {
    return {
      researchData: {
        atividade_pesquisa_titulo: "-",
        atividade_pesquisa_status_descricao: "-",
      },
      tabActive: 1,
      neighborhoodList: [],
      keepingSectorsList: [],
      selectedNeighborhoods: null,
      selectedKeepingSectors: null,
      neighborhoodModal: false,
      keepingSectorModal: false,
      participants: 0,
      alreadyFiltered: false,
      filteredNeighborhoods: [],
      filteredKeepingSectors: [],
      researchNumber: 0,
      isLoading: true,
      questions: [],
      filteredQuestions: [],
      colors: ["#5CCDF2", "#003063", "#008EBC", "#9AC625", "#E06914"],
      comparativeNumber: 0,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initNeighborhoods();
    await this.requestByLocation();
    await this.requestResearchQuantity();
    await this.requestQuestions();
    this.animateNumbers();
    this.isLoading = false;
  },
  methods: {
    async initNeighborhoods() {
      let { data, errors } = await services.neighborhoods.getNeighborhoodMap();
      if (!errors) {
        this.neighborhoodList =
          data.map((item) => {
            return {
              id: item.bairro_id,
              name: item.bairro_nome,
              check: false,
            };
          }) || [];
      }
    },
    async handleRequest() {
      this.isLoading = true;
      this.participants = 0;
      await this.requestResearchQuantity();
      await this.requestQuestions();
      this.animateNumbers();
      this.isLoading = false;
    },
    handleClearFilters() {
      this.selectedNeighborhoods = null;
      this.selectedKeepingSectors = null;
      this.keepingSectorsList = [];
      this.filteredKeepingSectors = [];
      this.filteredNeighborhoods = [];
      this.filteredQuestions = [];
      this.alreadyFiltered = false;
      this.handleRequest();
    },
    animateNumbers() {
      let interval = setInterval(() => {
        if (this.participants < this.researchNumber) {
          this.participants++;
        } else {
          clearInterval(interval);
        }
      }, 15);
    },
    async requestByLocation() {
      let { data, errors } = await services.researches.getResearchById(
        this.$route.params.id
      );
      if (!errors) {
        this.researchData = data;
      }
    },
    async requestKeepingSectorsFromNeighborhood() {
      let { data, errors } =
        await services.keepingSectors.getKeepingSectorMapByNeighborhood(
          this.selectedNeighborhoods.id
        );
      if (!errors) {
        this.keepingSectorsList =
          data.map((item) => {
            return {
              id: item.zeladoria_id,
              name: item.zeladoria_nome,
              check: false,
            };
          }) || [];
      }
    },
    async requestResearchQuantity() {
      const body = this.mountBody();

      let { data, errors } = await services.researches.reportByLocation(body);
      if (!errors) {
        if (data.length) {
          this.researchNumber = data[0].qtd_pesquisas;
        } else {
          this.researchNumber = 0;
        }
      }
    },
    async requestComparativeNumberOfParticipants() {
      const body = this.mountFilteredBody();

      let { data, errors } = await services.researches.reportByLocation(body);
      if (!errors) {
        if (data.length) {
          this.comparativeNumber = data[0].qtd_pesquisas;
        } else {
          this.comparativeNumber = 0;
        }
      }
    },
    async requestQuestions() {
      const body = this.mountBody();

      let { data, errors } = await services.researches.reportQuestions(body);
      if (!errors) {
        this.questions = data.map((item) => {
          return {
            ...item,
            open: false,
          };
        });

        this.calculatePercentage();
      }
    },
    mountBody() {
      let body = {
        atividade_pesquisa_id: this.$route.params.id,
      };

      if (this.selectedNeighborhoods) {
        body.bairro_id = [this.selectedNeighborhoods.id];
      }

      if (this.selectedKeepingSectors) {
        body.zeladoria_id = [this.selectedKeepingSectors.id];
        delete body.bairro_id;
      }

      return body;
    },
    mountFilteredBody() {
      let body = {
        atividade_pesquisa_id: this.$route.params.id,
      };

      if (this.filteredNeighborhoods.length) {
        body.bairro_id = this.filteredNeighborhoods.map((item) => item.id);
      }

      if (this.filteredKeepingSectors.length) {
        body.zeladoria_id = this.filteredKeepingSectors.map((item) => item.id);
        delete body.bairro_id;
      }

      return body;
    },
    activeTheSelectedKeepingSector() {
      this.keepingSectorsList.forEach((item) => {
        if (item.id === this.selectedKeepingSectors.id) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
    },
    activeTheSelectedNeighborhood() {
      this.neighborhoodList.forEach((item) => {
        if (item.id === this.selectedNeighborhoods.id) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
    },
    async handleKeepingSectorsChange() {
      this.activeTheSelectedKeepingSector();
    },
    async handleNeighborhoodsChange() {
      this.keepingSectorsList = [];
      this.selectedKeepingSectors = null;

      this.activeTheSelectedNeighborhood();
      await this.requestKeepingSectorsFromNeighborhood();
    },
    calculatePercentage() {
      this.questions.forEach((question) => {
        const total = question.atividade_pesquisa_resposta.reduce(
          (acc, curr) => (acc += curr.qtd_respostas),
          0
        );

        question.atividade_pesquisa_resposta.forEach((answer) => {
          if (answer.qtd_respostas) {
            answer.percentage = ((answer.qtd_respostas / total) * 100).toFixed(
              2
            );
          } else {
            answer.percentage = 0;
          }
        });
      });
    },
    async requestNeighborhoodsComparative() {
      let body = {
        atividade_pesquisa_id: this.$route.params.id,
        bairro_id: this.filteredNeighborhoods.map((item) => item.id),
      };

      let { data, errors } =
        await services.researches.requestNeighborhoodsComparative(body);
      if (!errors) {
        this.filteredQuestions = data.map((item) => {
          return {
            ...item,
            open: false,
          };
        });

        this.filteredQuestions.forEach((question) => {
          let total = this.comparativeNumber;

          question.atividade_pesquisa_resposta.forEach((answer) => {
            answer.respostas.forEach((item) => {
              if (item.qtd_respostas) {
                item.percentage = ((item.qtd_respostas / total) * 100).toFixed(
                  1
                );
              } else {
                item.percentage = 0;
              }
              item.local_id = item.bairro_id;
              item.name = item.bairro_nome;
            });
          });
        });
      }
    },
    async requestKeepingSectorsComparative() {
      let body = {
        atividade_pesquisa_id: this.$route.params.id,
        zeladoria_id: this.filteredKeepingSectors.map((item) => item.id),
      };

      let { data, errors } =
        await services.researches.requestKeepingSectorsComparative(body);
      if (!errors) {
        this.filteredQuestions = data.map((item) => {
          return {
            ...item,
            open: false,
          };
        });

        this.filteredQuestions.forEach((question) => {
          let total = this.comparativeNumber;

          question.atividade_pesquisa_resposta.forEach((answer) => {
            answer.respostas.forEach((item) => {
              if (item.qtd_respostas) {
                item.percentage = ((item.qtd_respostas / total) * 100).toFixed(
                  1
                );
              } else {
                item.percentage = 0;
              }
              item.local_id = item.zeladoria_id;
              item.name = item.zeladoria_nome;
            });
          });
        });
      }
    },
    async handleFilterNeighborhood(data) {
      this.isLoading = true;
      this.filteredKeepingSectors = [];
      this.filteredNeighborhoods = [];
      this.filteredQuestions = [];
      this.comparativeNumber = 0;
      this.filteredNeighborhoods = this.neighborhoodList.filter((item) =>
        data.includes(item.id)
      );

      this.filteredNeighborhoods.sort((a, b) => a.id - b.id);

      if (this.filteredNeighborhoods.length > 1) {
        await this.requestComparativeNumberOfParticipants();
        await this.requestNeighborhoodsComparative();
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 150);
    },
    async handleFilterKeepingSector(data) {
      this.isLoading = true;
      this.filteredNeighborhoods = [];
      this.filteredKeepingSectors = [];
      this.filteredQuestions = [];
      this.comparativeNumber = 0;
      this.filteredKeepingSectors = this.keepingSectorsList.filter((item) =>
        data.includes(item.id)
      );

      this.filteredKeepingSectors.sort((a, b) => a.id - b.id);

      if (this.filteredKeepingSectors.length > 1) {
        await this.requestComparativeNumberOfParticipants();
        await this.requestKeepingSectorsComparative();
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 150);
    },
    researchDates() {
      if (this.researchData.atividade_pesquisa_data_inicio) {
        let date = this.researchData.atividade_pesquisa_data_inicio.split("T");
        let day = date[0].split("-")[2];
        let month = date[0].split("-")[1];
        let year = date[0].split("-")[0];

        let date1 = `${day}/${month}/${year}`;

        date = this.researchData.atividade_pesquisa_data_fim.split("T");
        day = date[0].split("-")[2];
        month = date[0].split("-")[1];
        year = date[0].split("-")[0];

        let date2 = `${day}/${month}/${year}`;

        return `${date1} - ${date2}`;
      }
      return "-";
    },
    tagStyle(tag) {
      if (tag === 1) return "sketch";
      if (tag === 2) return "waiting";
      if (tag === 3) return "published";
      if (tag === 4) return "finished";
      if (tag === 5) return "canceled";
    },
    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  padding: 24px;
  margin-right: 16px;
  margin-top: 40px;

  .summary-item {
    display: flex;
    align-items: center;
    padding-right: 32px;

    svg {
      margin-right: 32px;
    }

    .summary-data {
      display: flex;
      flex-direction: column;

      .data__label {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        text-transform: uppercase;
      }

      .data__quantity {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.006em;
        color: #565e73;
      }
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 32px;

  .tab {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid #fff;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 168%;
    color: #838998;
    margin-right: 16px;
    padding-bottom: 8px;

    &.active {
      color: #131e3b;
      font-weight: 600;
      border-bottom: 4px solid #00b1eb;
    }
  }
}

.neighborhood-selector {
  width: 260px;
  z-index: 10;
  margin-right: 16px;
}

.neighborhood-switch {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #003063;
  margin-top: 16px;
  cursor: pointer;
  position: relative;

  svg {
    margin-left: 8px;
  }
}

.showing-label {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #000000;
}

.showing-value {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #131e3b;
  background: #dddee3;
  border-radius: 5px;
  padding: 4px 8px;
  width: fit-content;
  margin-top: 8px;
}

.filter-modal {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 24px;
}

.status-tag {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 5px;
  color: #fff !important;
  width: fit-content;
}

.icon-close {
  margin-left: 4px;
  margin-bottom: 1px;
  cursor: pointer;
}

.filter-enter-active {
  animation: filter-in 0.4s;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.filtered {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #000000;

  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: var(--to-color);
    border-radius: 4px;
    margin-right: 24px;
    vertical-align: middle;
    margin-bottom: 2px;
  }
}

.total-filtered {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 156%;
  color: #131e3b;
}

.filter-leave-active {
  animation: filter-in 0.4s reverse;
}

@keyframes filter-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

@media print {
  .print-hide {
    display: none !important;
  }
}
</style>

<template>
  <div class="c-app flex-row align-items-center login-paranagua-bk">
    <CContainer>
      <CRow class="justify-content-end">
        <CCol xl="4" lg="7" md="7">
          <CCardGroup>
            <CCard class="p-3 card-custom-border">
              <CCardBody>
                <div
                  class="w-100 d-flex justify-content-center align-items-center mt-1 mb-4"
                >
                  <img
                    src="../../../assets/img/logo-paranagua.png"
                    class="mb-4"
                    alt="logo"
                    width="220"
                  />
                </div>
                <section>
                  <div class="d-flex w-100 justify-content-center">
                    <Input
                      class="login-input"
                      placeholder="E-mail"
                      v-model="user_email"
                    />
                  </div>

                  <div class="d-flex justify-content-center mt-3">
                    <Input
                      @keyup.enter.native="login"
                      placeholder="Senha"
                      type="password"
                      v-model="user_password"
                    />
                  </div>

                  <div class="d-flex justify-content-center mt-4 mb-4">
                    <LoginButton name="LOGIN" @clicked="login" />
                  </div>

                  <CButton
                    id="forgot-pwd-btn"
                    color="link"
                    to="/esqueci-minha-senha/paranagua"
                    block
                    class="px-0 mt-2 link-custom-color"
                    >Esqueci minha senha</CButton
                  >
                </section>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../../services";

import LoginButton from "../../../components/LoginButton";
import Input from "../../../components/form/Input";

import { encrypt } from "../../../utils/decrypt";

export default {
  name: "Login",
  components: {
    LoginButton,
    Input,
  },
  data() {
    return {
      user_email: "",
      user_password: "",
      urlLogo: "",
      client_id: 1,
      validation_string: "",
    };
  },
  methods: {
    async logoUrl() {
      const { data, errors } = await services.importData.cityData({});
      if (!errors) {
        this.urlLogo = data.config_valor;
      }
    },
    async login() {
      try {
        const { data, errors } = await services.auth.login({
          usuario_email: this.user_email.toLowerCase(),
          usuario_senha: this.user_password,
          cidade_id: 4118204,
        });
        if (!errors) {
          let encrypted = encrypt(
            JSON.stringify({
              ...data,
              usuario_cidade: "Paranaguá/PR",
              usuario_cidade_id: 4118204,
            })
          );
          localStorage.setItem("ama", encrypted);
          await this.logoUrl();

          const configData = await services.config.getConfigById(500);
          const candidadeData = await services.config.getConfigById(502);

          let encryptedConfig = encrypt(
            JSON.stringify({
              ...configData.data,
              candidate: candidadeData.data,
            })
          );

          localStorage.setItem("config-500", encryptedConfig);

          encrypted = encrypt(
            JSON.stringify({
              ...data,
              usuario_cidade: "Paranaguá/PR",
              usuario_cidade_id: 4118204,
              cidade_logo: this.urlLogo,
            })
          );
          localStorage.removeItem("ama");
          localStorage.setItem("ama", encrypted);
          this.$router.push("/dashboard");
          return;
        } else {
          Vue.$toast.open({
            message: errors.statusText.erro || errors.statusText.error,
            position: "top",
            type: "error",
          });
        }
      } catch (error) {
        localStorage.removeItem("ama");
        Vue.$toast.open({
          message: "Ocorreu um erro ao fazer o login",
          position: "top",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#forgot-pwd-btn {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #00a8ff;
}

.translate-box {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 80px;
  height: 45px;
  display: flex;
  flex-direction: column;

  label {
    font-family: "Roboto-Regular";
    color: #00a8ff;
    font-size: 82%;
    padding: 0;
    margin: 0;
  }
}
</style>

<style>
.login-input.input-wrapper > .input {
  text-transform: lowercase !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
</style>

<template>
  <div>
    <div class="print-container bg-white">
      <div v-if="!loadingData">
        <header class="d-flex justify-content-between mb-4">
          <div>
            <div>
              <div class="d-flex align-items-center">
                <div class="pr-2 pb-5">
                  <span @click="goToHome" class="pointer">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.828 6.99992H16V8.99992H3.828L9.192 14.3639L7.778 15.7779L0 7.99992L7.778 0.221924L9.192 1.63592L3.828 6.99992Z"
                        fill="#565E73"
                      />
                    </svg>
                  </span>
                </div>
                <div class="ml-2">
                  <h3 class="print-title mb-2">
                    {{ $t("materialsReceiptReport") }}
                  </h3>
                  <p class="print-subtitle">{{ cityName.slice(0, -3) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="logo-city mr-4">
            <img :src="urlLogo" alt="logo" width="220" />
          </div>
        </header>

        <div class="form-wrapper mb-5">
          <h5 class="filter-label mb-4">Filtrar por data específica</h5>

          <div class="d-flex align-items-center">
            <form class="d-flex align-center form" @submit="requestReport">
              <span
                @click="calendarModal = !calendarModal"
                class="new-icons pointer btn-relatory mr-3 mb-1"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="-1 1 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div class="absolute-box w-50" v-if="calendarModal">
                <v-date-picker
                  v-model="dates"
                  is-range
                  @input="calendarModal = false"
                />
              </div>
              <DateLabel
                :name="formatDates()"
                class="no-pointer mr-3"
                v-if="dates.start && dates.end"
              />

              <Button
                type="primary"
                size="small"
                :width="400"
                class="btn-position"
                name="Gerar Relatório"
                :disabled="requestApi"
              />
            </form>

            <span class="new-icons pointer btn-relatory ml-3" @click="print">
              <svg
                width="17"
                height="17"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.66659 10.334H10.3333V13.6673H3.66659V10.334ZM11.6666 12.334V9.00065H2.33325V12.334H0.999919C0.823108 12.334 0.653538 12.2637 0.528514 12.1387C0.40349 12.0137 0.333252 11.8441 0.333252 11.6673V5.00065C0.333252 4.82384 0.40349 4.65427 0.528514 4.52925C0.653538 4.40422 0.823108 4.33398 0.999919 4.33398H12.9999C13.1767 4.33398 13.3463 4.40422 13.4713 4.52925C13.5963 4.65427 13.6666 4.82384 13.6666 5.00065V11.6673C13.6666 11.8441 13.5963 12.0137 13.4713 12.1387C13.3463 12.2637 13.1767 12.334 12.9999 12.334H11.6666ZM2.33325 5.66732V7.00065H4.33325V5.66732H2.33325ZM3.66659 0.333984H10.3333C10.5101 0.333984 10.6796 0.404222 10.8047 0.529246C10.9297 0.654271 10.9999 0.82384 10.9999 1.00065V3.00065H2.99992V1.00065C2.99992 0.82384 3.07016 0.654271 3.19518 0.529246C3.32021 0.404222 3.48977 0.333984 3.66659 0.333984Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </div>

        <section id="content">
          <div class="mt-4">
            <div class="current-dates">
              Período selecionado: {{ formatDate(dates.start) }} -
              {{ formatDate(dates.end) }}
            </div>
            <table v-if="measurements.length">
              <thead>
                <tr>
                  <th>
                    ZELADOR
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('usuario_nome', sortByUser),
                          (sortByUser = sortByUser === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByUser === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th>
                    BAIRRO
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('bairro_nome', sortByNeighborhood),
                          (sortByNeighborhood =
                            sortByNeighborhood === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByNeighborhood === 'asc'
                              ? 'rotate(180deg)'
                              : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th>
                    ZELADORIA
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('zeladoria_nome', sortByKeepingSector),
                          (sortByKeepingSector =
                            sortByKeepingSector === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByKeepingSector === 'asc'
                              ? 'rotate(180deg)'
                              : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th>
                    DATA DE RECEBIMENTO
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('usuario_recebimento_material_data', sortByDate),
                          (sortByDate = sortByDate === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByDate === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#565e73" /></svg
                    ></span>
                  </th>
                  <th class="actions-column">AÇÕES</th>
                </tr>
              </thead>
              <tr
                v-for="measurement of measurements"
                :key="measurement.usuario_id"
              >
                <td>{{ measurement.usuario_nome }}</td>
                <td>{{ measurement.bairro_nome }}</td>
                <td>{{ measurement.zeladoria_nome }}</td>
                <td>
                  {{
                    formatDate(measurement.usuario_recebimento_material_data)
                  }}
                </td>
                <td class="redirect" @click="redirectToDetails(measurement)">
                  <svg
                    width="22"
                    height="18"
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9997 0C16.3917 0 20.8777 3.88 21.8187 9C20.8787 14.12 16.3917 18 10.9997 18C5.60766 18 1.12166 14.12 0.180664 9C1.12066 3.88 5.60766 0 10.9997 0ZM10.9997 16C13.0391 15.9996 15.0181 15.3068 16.6125 14.0352C18.207 12.7635 19.3226 10.9883 19.7767 9C19.3209 7.0133 18.2046 5.24 16.6103 3.97003C15.016 2.70005 13.038 2.00853 10.9997 2.00853C8.96136 2.00853 6.98335 2.70005 5.38904 3.97003C3.79473 5.24 2.67843 7.0133 2.22266 9C2.67676 10.9883 3.79234 12.7635 5.38681 14.0352C6.98128 15.3068 8.9602 15.9996 10.9997 16ZM10.9997 13.5C9.80619 13.5 8.6616 13.0259 7.81768 12.182C6.97377 11.3381 6.49966 10.1935 6.49966 9C6.49966 7.80653 6.97377 6.66193 7.81768 5.81802C8.6616 4.97411 9.80619 4.5 10.9997 4.5C12.1931 4.5 13.3377 4.97411 14.1816 5.81802C15.0256 6.66193 15.4997 7.80653 15.4997 9C15.4997 10.1935 15.0256 11.3381 14.1816 12.182C13.3377 13.0259 12.1931 13.5 10.9997 13.5ZM10.9997 11.5C11.6627 11.5 12.2986 11.2366 12.7674 10.7678C13.2363 10.2989 13.4997 9.66304 13.4997 9C13.4997 8.33696 13.2363 7.70107 12.7674 7.23223C12.2986 6.76339 11.6627 6.5 10.9997 6.5C10.3366 6.5 9.70074 6.76339 9.2319 7.23223C8.76306 7.70107 8.49966 8.33696 8.49966 9C8.49966 9.66304 8.76306 10.2989 9.2319 10.7678C9.70074 11.2366 10.3366 11.5 10.9997 11.5Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                </td>
              </tr>
            </table>
            <NoResults v-else />
          </div>
        </section>
      </div>
      <Loading v-else />
    </div>
  </div>
</template>

<script>
import Loading from "../../components/IndexComponents/Loading.vue";
import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import DateLabel from "../../components/DateLabel.vue";
import services from "../../services";
import { decrypt } from "../../utils/decrypt";
import { infoToast } from "../../utils/toast";
import { dateToText } from "../../utils/date";

export default {
  name: "Measurement",
  components: {
    Loading,
    Button,
    DateLabel,
    NoResults,
  },
  mounted: async function () {
    if (!this.range) {
      infoToast("Nenhum período foi selecionado");
    }
    await this.initAllData();
    this.logoUrl();
  },
  data() {
    return {
      loadingData: true,
      urlLogo: "",
      calendarModal: false,
      cityName: "",
      measurements: [],
      monthName: "",
      sortByKeepingSector: "asc",
      sortByNeighborhood: "asc",
      sortByDate: "asc",
      sortByUser: "asc",
      dates: {
        start: null,
        end: null,
      },
      requestApi: false,
    };
  },
  created() {
    window.addEventListener("click", (e) => {
      if (
        e.target.id !== "calendar" &&
        e.target.className.toString() !== "[object SVGAnimatedString]"
      ) {
        this.calendarInitial = false;
        this.calendarFinal = false;
      }
    });
  },
  methods: {
    async initAllData() {
      this.loadingData = false;
    },
    formatDates() {
      if (this.dates.start && this.dates.end) {
        return `${dateToText(this.dates.start.toISOString())} - ${dateToText(
          this.dates.end.toISOString()
        )}`;
      }
    },
    async logoUrl() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      this.cityName = user.usuario_cidade;
      this.urlLogo = user.cidade_logo;
    },
    async requestReport(ev) {
      ev.preventDefault();
      this.calendarFinal = false;
      this.calendarInitial = false;
      this.requestApi = true;
      if (this.dates) {
        const { data, errors } = await services.executions.recieveToolReport({
          data_inicio: this.dates.start,
          data_fim: this.dates.end,
        });
        if (!errors) {
          this.measurements = data;
        }
        this.requestApi = false;
      }
    },
    redirectToDetails(measurement) {
      let route = this.$router.resolve({
        path: `/detalhe-treinamento/${measurement.usuario_id}`,
      });
      window.open(route.href, "_blank");
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    convertDate(date) {
      if (date) {
        return date
          .toISOString()
          .slice(0, -5)
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      return "";
    },
    blockChooseDaysFromDifferentMonths() {
      this.dates = {
        start: null,
        end: null,
      };
    },
    print() {
      setTimeout(function () {
        window.print();
      }, 0);
    },
    goToHome() {
      this.$router.push({
        name: "Relatorios",
      });
    },
    sortBy(key, order) {
      this.measurements.sort((a, b) => {
        if (a[key] < b[key]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: A4 landscape !important;
    page-break-inside: avoid;
  }
  table {
    -webkit-print-color-adjust: exact;
  }
  .print-button {
    display: none;
  }
  .form-wrapper {
    display: none;
  }
  .pointer {
    display: none !important;
  }
  .actions-column {
    display: none !important;
  }
  .redirect {
    display: none !important;
  }
  .current-dates {
    display: block !important;
  }
}

.print-container {
  padding: 32px;
  min-height: 100vh;
}

.print-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
}

.filter-label {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

.print-subtitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #565e73;
}

.input-date-custom {
  width: 100%;
  min-width: 160px;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 10px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

table,
td,
th {
  border-top: 1px solid #dddee3;
}

th,
td {
  height: 33px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 12px;
  color: #565e73;
  padding: 24px;
  text-align: left;
}

.btn-position {
  margin-top: 3px;
}

th {
  background-color: #fff;
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  line-height: 156%;
  color: #565e73;
  user-select: none;
}

.btn-relatory {
  width: 32px;
  height: 32px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 30px;
}

tr > .date {
  background-color: #003366;
  color: #fff;
  font-family: "OpenSans-Bold";
  font-size: 14px;
}

.legal-text {
  color: #747474;
  margin: 10px 0;
}
.engineers {
  color: #747474;
}

.logo-city {
  max-width: 192px;
  height: 10px;
}

.form {
  input {
    width: 140px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    color: #747474;
  }
}

.redirect {
  cursor: pointer;
}

.current-dates {
  display: none;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  line-height: 132%;
  color: #003366;
  margin: 10px 0;
}

.form {
  position: relative;
}

svg {
  margin-bottom: 3px;
}
</style>

<template>
  <div class="details-neighborhood">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("reportNeighborhood") }}:</span>
      <span v-if="!loadingData" class="page-nav">
        {{ details.info.bairro_nome }}</span
      >
    </h3>

    <div class="activities-cards ml-3">
      <div v-for="(item, id) of activities" :key="id">
        <div class="activity-card pointer" @click="redirectToList(item)">
          <div class="activiy-icon">
            <img
              :src="item.atividade_icon"
              height="64"
              alt=""
              class="img-border"
            />
          </div>
          <div class="activity-counter">
            <div class="card-title">{{ item.atividade_nome }}</div>
            <div class="card-counter">
              {{
                item.atividade_quantidade !== null
                  ? item.atividade_quantidade.toString() + ""
                  : "0"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ml-3">
      <div class="map-container">
        <div class="map-title">Localização das atividades</div>
        <GmapMap
          ref="googleMap"
          :center="center"
          :zoom="zoom"
          style="height: 460px; width: 100%"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            mapTypeId: mapType,
            gestureHandling: 'greedy',
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: false,
            disableDefaultUi: true,
          }"
        >
          <div
            v-for="polygon of polygonSector"
            :key="'polygonsKeepingSector' + polygon.id"
          >
            <GmapPolygon
              ref="polygon"
              :paths="polygon.latlngs"
              :options="polygon.options"
            />
          </div>
        </GmapMap>
        <div class="mapTypeControllers">
          <CSelect
            :placeholder="$t('type')"
            :options="mapTypes"
            :value.sync="mapType"
          ></CSelect>
        </div>
      </div>
    </div>

    <CRow class="mb-4" ref="myDetails">
      <CCol col="12" sm="12" lg="12" class="mt-4 mb-4" v-if="loadingEvent">
        <div
          class="d-flex justify-content-center align-items-center mt-4 mb-4 fixed-size"
        >
          <CSpinner color="primary" style="width: 3rem; height: 3rem" />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { convertGeoStringToGoogleMaps } from "../../utils/geoMaps";
import services from "../../services";

import BackArrow from "../../components/BackArrow.vue";

export default {
  name: "DetailsZelador",
  components: {
    BackArrow,
  },
  data() {
    return {
      details: {
        info: {},
        bairro: {
          qtdAcao: 0,
          qtdPesquisa: 0,
          qtdChamado: 0,
          qtdColeta: 0,
        },
      },
      zoom: 4,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      polygonSector: [],
      loadingEvent: false,
      loadingData: false,
      type: "",
      activities: [],
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "satellite",
    };
  },
  mounted: async function () {
    await this.requestActivitiesByNeighborhood();
    await this.requestNeighborhoodDetails();
  },
  methods: {
    async requestActivitiesByNeighborhood() {
      const { data, errors } =
        await services.neighborhoods.getNeighborhoodDetails({
          neighborhoodId: this.$route.params.id,
        });
      if (!errors) {
        this.activities = data.map((item) => {
          return {
            ...item,
          };
        });
      }
    },
    async requestNeighborhoodDetails() {
      const { data, errors } =
        await services.neighborhoods.getDetailsNeighborhood({
          neighborhoodId: this.$route.params.id,
        });
      if (!errors) {
        this.details.info = data[0];
        this.mountPolygon(data[0].bairro_limite);
      }
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    mountPolygon(poligono) {
      if (poligono !== "") {
        this.polygonSector = [];
        const coordinatesJSONParse = poligono;
        let bairro = {
          latlngs: convertGeoStringToGoogleMaps(coordinatesJSONParse),
          options: {
            strokeColor: "#548aba",
            fillColor: "#548aba",
          },
          id: 1,
        };

        bairro.latlngs.map((item) => {
          this.bounds.push(item);
        });
        this.polygonSector.push(bairro);
      }
      this.fitBoundsGoogle();
    },
    redirectToList(item) {
      this.$router.push(
        `/execucoes/bairro-${item.atividade_id}-${this.$route.params.id}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.details-neighborhood {
  background-color: #fff;
  border-radius: 8px;

  .activities-cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .activity-card {
      min-width: 292px;
      min-height: 88px;
      border: 1px solid #dddee3;
      border-radius: 16px;
      padding: 16px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      .card-counter {
        font-family: "OpenSans-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #29334e;
        margin: 0;
        padding: 0;
      }

      .card-title {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.014em;
        color: #565e73;
        margin: 0;
        padding: 0;
      }
    }
  }

  .map-container {
    position: relative;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 32px;

    .map-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.017em;
      color: #29334e;
      margin-bottom: 32px;
    }
  }

  .img-border {
    border-radius: 50%;
    border: 1px solid #dddee3;
    padding: 8px;
  }
}

.mapTypeControllers {
  position: absolute;
  top: 82px;
  left: 18px;
  z-index: 500;
  width: 120px;
}
</style>

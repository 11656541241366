<template>
<button :style="width ? '{min-width: 300px;}' : '{}'" class="secondary-btn" @click="$emit('clicked')">
    <span class="btn-name">{{name}}</span>
</button>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            default: null
        },
    }
}
</script>

<style scoped>
.secondary-btn {
    border: 1px solid #BDE6FF;
    border-radius: 20px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: fit-content;
    height: 33px;
    background-color: #fff;
}

.btn-name {
    font-family: 'Roboto-Regular';
    padding-right: 8px;
    padding-left: 8px;
    font-size: 12px;
    line-height: 145%;
    text-align: center;
    color: #61B2E5;
}
</style>

<template>
  <div>
    <div class="print-container bg-white">
      <div v-if="!loadingData">
        <header class="d-flex justify-content-between mb-4">
          <div class="d-flex align-items-center">
            <div class="pr-2 pb-5">
              <span @click="goToHome" class="pointer">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.828 6.99992H16V8.99992H3.828L9.192 14.3639L7.778 15.7779L0 7.99992L7.778 0.221924L9.192 1.63592L3.828 6.99992Z"
                    fill="#565E73"
                  />
                </svg>
              </span>
            </div>
            <div class="ml-2">
              <h3 class="print-title mb-2">
                {{ $t("executorsByPeriodReport") }}
              </h3>
              <p class="print-subtitle">{{ cityName.slice(0, -3) }}</p>
            </div>
          </div>

          <div class="logo-city mr-4">
            <img :src="urlLogo" alt="logo" width="220" />
          </div>
        </header>

        <!-- <div v-if="engineers.length" class="mb-5">
          <h5 class="filter-label mb-4">Responsável Técnico:</h5>
          <div class="engineers-box">
            <div class="engineers-label">
              {{ engineers[0].engenheiro_nome }}
            </div>
            <div class="engineers-label">
              CREA: {{ engineers[0].engenheiro_crea }}
            </div>
          </div>
        </div> -->

        <div class="form-wrapper">
          <h5 class="filter-label mb-4">Filtrar por data específica</h5>
          <div class="d-flex align-items-center">
            <form class="d-flex align-center form" @submit="requestReport">
              <span
                @click="calendarModal = !calendarModal"
                class="new-icons pointer btn-relatory mr-3 mb-1"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="-1 1 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div class="absolute-box w-50" v-if="calendarModal">
                <v-date-picker
                  v-model="dates"
                  is-range
                  @input="calendarModal = false"
                />
              </div>
              <DateLabel
                :name="formatDates()"
                class="no-pointer mr-3"
                v-if="dates.start && dates.end"
              />

              <Button
                type="primary"
                size="small"
                :width="400"
                class="btn-position"
                name="Gerar Relatório"
                :disabled="requestApi"
              />
            </form>

            <span class="new-icons pointer btn-relatory ml-3" @click="print">
              <svg
                width="17"
                height="17"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.66659 10.334H10.3333V13.6673H3.66659V10.334ZM11.6666 12.334V9.00065H2.33325V12.334H0.999919C0.823108 12.334 0.653538 12.2637 0.528514 12.1387C0.40349 12.0137 0.333252 11.8441 0.333252 11.6673V5.00065C0.333252 4.82384 0.40349 4.65427 0.528514 4.52925C0.653538 4.40422 0.823108 4.33398 0.999919 4.33398H12.9999C13.1767 4.33398 13.3463 4.40422 13.4713 4.52925C13.5963 4.65427 13.6666 4.82384 13.6666 5.00065V11.6673C13.6666 11.8441 13.5963 12.0137 13.4713 12.1387C13.3463 12.2637 13.1767 12.334 12.9999 12.334H11.6666ZM2.33325 5.66732V7.00065H4.33325V5.66732H2.33325ZM3.66659 0.333984H10.3333C10.5101 0.333984 10.6796 0.404222 10.8047 0.529246C10.9297 0.654271 10.9999 0.82384 10.9999 1.00065V3.00065H2.99992V1.00065C2.99992 0.82384 3.07016 0.654271 3.19518 0.529246C3.32021 0.404222 3.48977 0.333984 3.66659 0.333984Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </div>

        <section id="content">
          <div class="mt-4">
            <div class="current-dates">
              Período selecionado: {{ convertDate(dates.start) }} -
              {{ convertDate(dates.end) }}
            </div>
            <table v-if="measurements.length > 0" class="mb-5">
              <thead>
                <tr>
                  <th>
                    Áreas de zeladoria
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('zeladoria_nome', sortByKeepingSector),
                          (sortByKeepingSector =
                            sortByKeepingSector === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByKeepingSector === 'asc'
                              ? 'rotate(180deg)'
                              : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                    ></span>
                  </th>
                  <th>
                    Primeira execução
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('primeira_execucao', sortByInitialDate),
                          (sortByInitialDate =
                            sortByInitialDate === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByInitialDate === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                    ></span>
                  </th>
                  <th>
                    Última execução
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('ultima_execucao', sortByFinalDate),
                          (sortByFinalDate =
                            sortByFinalDate === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByFinalDate === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                    ></span>
                  </th>
                  <th>
                    Zelador
                    <span
                      class="pointer pl-2 pb-2"
                      @click="
                        sortBy('usuario_nome', sortByUser),
                          (sortByUser = sortByUser === 'asc' ? 'desc' : 'asc')
                      "
                      ><svg
                        :style="{
                          transform:
                            sortByUser === 'asc' ? 'rotate(180deg)' : '',
                        }"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                    ></span>
                  </th>
                </tr>
              </thead>
              <tr
                v-for="measurement of measurements"
                :key="Math.random() + measurement"
              >
                <td>{{ measurement.zeladoria_nome }}</td>
                <td>{{ formatDate(measurement.primeira_execucao) }}</td>
                <td>{{ formatDate(measurement.ultima_execucao) }}</td>
                <td>{{ measurement.usuario_nome }}</td>
              </tr>
            </table>
            <NoResults v-else />
          </div>
        </section>
      </div>
      <Loading v-else />
    </div>
  </div>
</template>

<script>
import Loading from "../../components/IndexComponents/Loading.vue";
import NoResults from "../../components/table/NoResults.vue";
import DateLabel from "../../components/DateLabel.vue";
import Button from "../../components/Button.vue";
import services from "../../services";
import { decrypt } from "../../utils/decrypt";
import { infoToast } from "../../utils/toast";
import { dateToText } from "../../utils/date";

export default {
  name: "Measurement",
  components: {
    Loading,
    Button,
    DateLabel,
    NoResults,
  },
  mounted: async function () {
    if (!this.range) {
      infoToast("Nenhum período foi selecionado");
    }
    await this.initAllData();
    this.logoUrl();
  },
  data() {
    return {
      loadingData: true,
      urlLogo: "",
      cityName: "",
      measurements: [],
      monthName: "",
      engineers: [],
      sortByKeepingSector: "asc",
      sortByInitialDate: "asc",
      sortByFinalDate: "asc",
      sortByUser: "asc",
      calendarModal: false,
      dates: {
        start: null,
        end: null,
      },
      requestApi: false,
    };
  },
  methods: {
    async initAllData() {
      this.loadingData = false;
    },
    async logoUrl() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      this.cityName = user.usuario_cidade;
      this.urlLogo = user.cidade_logo;
    },
    async requestReport(ev) {
      ev.preventDefault();
      this.calendarModal = false;
      this.requestApi = true;
      if (this.dates) {
        await this.requestExecutionEngineers();
        const { data, errors } = await services.executions.getExecutionsReport({
          data_inicio_pesquisa: this.dates.start,
          data_fim_pesquisa: this.dates.end,
        });
        if (!errors) {
          this.measurements = data;
        }
        this.requestApi = false;
      }
    },
    formatDates() {
      if (this.dates.start && this.dates.end) {
        return `${dateToText(this.dates.start.toISOString())} - ${dateToText(
          this.dates.end.toISOString()
        )}`;
      }
    },
    async requestExecutionEngineers() {
      if (this.dates) {
        const { data, errors } =
          await services.executions.getExecutionsEngineers({
            data_inicio: this.dates.start,
            data_fim: this.dates.end,
          });
        if (!errors) this.engineers = data;
      }
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    blockChooseDaysFromDifferentMonths() {
      this.dates = {
        start: null,
        end: null,
      };
    },
    convertDate(date) {
      if (date) {
        return date
          .toISOString()
          .slice(0, -5)
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      return "";
    },
    print() {
      setTimeout(function () {
        window.print();
      }, 0);
    },
    goToHome() {
      this.$router.push({
        name: "Relatorios",
      });
    },
    sortBy(key, order) {
      this.measurements.sort((a, b) => {
        if (a[key] < b[key]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: A4 landscape !important;
    page-break-inside: avoid;
  }

  table {
    -webkit-print-color-adjust: exact;
  }

  .print-button {
    display: none;
  }
  .form-wrapper {
    display: none;
  }

  .pointer {
    display: none !important;
  }
  .current-dates {
    display: block !important;
  }
  .print-container {
    padding: 32px;
  }
}

.print-container {
  padding: 32px;
  min-height: 100vh;
}

.print-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
}

.print-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #565e73;
}

.input-date-custom {
  width: 100%;
  min-width: 160px;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 10px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

table,
td,
th {
  border: 1px solid #e4e4e4;
  text-align: center;
  color: #838998;
}

th,
td {
  height: 33px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-size: 16px;
  padding: 12px;
}

th {
  background-color: #838998;
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 16px;
}

.absolute-box-initial {
  position: absolute;
  top: 40px;
  left: 5px;
}

.absolute-box-final {
  position: absolute;
  top: 40px;
  left: 180px;
  z-index: 10;
}

.btn-position {
  margin-top: 3px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr > .date {
  background-color: #003366;
  color: #fff;
  font-family: "OpenSans-Bold";
  font-size: 14px;
}

.btn-relatory {
  width: 32px;
  height: 32px;
}

.filter-label {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

tr:nth-child(odd) {
  background-color: #f3f4f5;
}

td {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: center;
  color: #838998;
}

.legal-text {
  color: #747474;
  margin: 10px 0;
}

.engineers {
  color: #747474;
}

.logo-city {
  max-width: 192px;
  height: 10px;
}

.form {
  position: relative;
}

svg {
  margin-bottom: 3px;
}

.current-dates {
  display: none;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  line-height: 132%;
  color: #003366;
  margin: 10px 0;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 30px;
}

.engineers-box {
  border: 1px solid #dddee3;
  border-radius: 16px;
  width: 300px;
  padding: 8px 16px;
}

.engineers-label {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 11px;
  line-height: 165%;
  color: #747474;
}
</style>

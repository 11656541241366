<template>
  <div class="candidates-page">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("keeperAnalytics") }}: </span>
      <span class="page-nav">{{ zelador.usuario_nome }}</span>
    </h3>

    <div class="big-box ml-3 mr-2">
      <div>
        <img
          v-if="this.avatar === null"
          class="avatar"
          src="../../assets/img/icone_mapa_comfotos copy.png"
          alt="avatar"
        />
        <img v-else class="avatar" :src="avatar" alt="avatar" />
        <span class="box-span"
          ><img class="box-icon" src="../../assets/img/cidades.png" alt="" />
          <p class="box-text">Cidade: {{ zelador.cidade_nome }}</p></span
        >
        <span class="box-span"
          ><img class="box-icon" src="../../assets/img/Bairro.png" alt="" />
          <p class="box-text">Bairro: {{ zelador.bairro_nome }}</p></span
        >
        <span class="box-span"
          ><img class="box-icon" src="../../assets/img/img.png" alt="" />
          <p class="box-text">Zeladoria: {{ zelador.zeladoria_nome }}</p></span
        >
        <span class="box-span"
          ><img class="box-icon" src="../../assets/img/Email.png" alt="" />
          <p class="box-text">Email: {{ zelador.usuario_email }}</p></span
        >
        <span class="box-span"
          ><img class="box-icon" src="../../assets/img/telefone.png" alt="" />
          <p class="box-text">Telefone: {{ zelador.usuario_telefone }}</p></span
        >
        <span class="box-span"
          ><img class="box-icon" src="../../assets/img/calendario.png" alt="" />
          <p class="box-text">
            Data de Candidatura:
            {{ formatDateToBR(zelador.usuario_contrato_data) }}
          </p></span
        >
      </div>

      <div class="map-box">
        <GmapMap
          class="map"
          ref="googleMap"
          :center="center"
          :zoom="zoom"
          style="height: 278px; width: 100%"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            mapTypeId: mapType,
            gestureHandling: 'greedy',
            fullscreenControl: true,
            disableDefaultUi: true,
            mapTypeId: mapType,
          }"
        >
          <div
            v-for="polygon of polygonSector"
            :key="'polygonsKeepingSector' + polygon.id"
          >
            <GmapPolygon
              ref="polygon"
              :paths="polygon.latlngs"
              :options="polygon.options"
            />
          </div>
          <GmapPolyline
            ref="polyline"
            v-if="polyline !== null"
            :path="polyline.latlngs"
            :options="polyline.options"
          />
        </GmapMap>
      </div>
    </div>

    <div class="tabs ml-3">
      <div
        :class="`tab ${tab.active ? 'active' : ''}`"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="handleChangeTab(tab.value)"
      >
        {{ tab.label }}
      </div>
    </div>

    <transition name="bounce">
      <div v-if="this.currentTab === 0">
        <div class="tag-box ml-3">
          <h3 class="titulo-tab">{{ $t("HoursWorkedPerWeeklyCycle") }}</h3>

          <div class="flex-row">
            <div class="form-wrapper mt-3 mb-5 form-class">
              <form class="d-flex align-center form">
                <div>
                  <label class="date-label" for="initialDate">{{
                    $t("of")
                  }}</label>
                  <input
                    name="initialDate"
                    id="initialDate"
                    class="mr-3"
                    type="month"
                    v-model="date_start"
                  />
                </div>

                <div>
                  <label class="date-label" for="finalDate">{{
                    $t("to")
                  }}</label>
                  <input
                    name="finalDate"
                    id="finalDate"
                    class="mr-3"
                    type="month"
                    v-model="date_end"
                  />
                </div>
              </form>
              <button @click="postHorasTrabalhadas()" class="filtrar">
                Filtrar
              </button>
            </div>
          </div>

          <div class="lista">
            <Table :fields="fields" :hasActions="true">
              <TableRow
                v-for="horas in horasTrabalhadas"
                :key="horas + Math.random()"
              >
                <TableCell
                  v-for="field in fields"
                  :key="field.key"
                  :name="field.key"
                  :style="field._style"
                  :sort-direction="field.sortDirection"
                >
                  <template v-slot:[field.key]>
                    <span class="table-row-text">{{
                      horas[field.key] || "--"
                    }}</span>
                  </template>
                  <template v-slot:ciclo_inicio>
                    <div>
                      {{ formatDate(horas.ciclo_inicio) || "--" }}
                    </div>
                  </template>
                  <template v-slot:ciclo_fim>
                    <div>
                      {{ formatDate(horas.ciclo_fim) || "--" }}
                    </div>
                  </template>
                  <template v-slot:tempo_execucao>
                    {{ horas.tempo_execucao }}
                  </template>
                </TableCell>
              </TableRow>
              <template v-if="horasTrabalhadas.length === 0" v-slot:no-results>
                <NoResults :message="$t('noResults')" />
              </template>
            </Table>
          </div>
        </div>
      </div>

      <div v-if="this.currentTab === 1">
        <div class="tag-box">
          <h3 class="titulo-tab">{{ $t("DailyAverageOfExecutions") }}</h3>
          <div class="atividades">
            <div class="atividade-titulo">
              <h3>{{ $t("SelectActivities") }}</h3>
              <div class="atividade selecionar-todos">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="selecionarTodos"
                    @click="todos()"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("SelectAll") }}</h4>
              </div>
            </div>

            <div class="atividade-switches">
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="horta1"
                    @change="atividades(103)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("CommunityGardens") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="lixeira1"
                    @change="atividades(102)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("CommunityRecycleBins") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="solicitacao1"
                    @change="atividades(104)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("RequestFromResidents") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="boca1"
                    @change="atividades(106)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("Manhole") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="varricao1"
                    @change="atividades(100)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("Sweeping") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="infraestrutura1"
                    @change="atividades(107)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("UrbanInfrastructure") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="descarte1"
                    @change="atividades(105)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("IrregularDiscard") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="capina1"
                    @change="atividades(101)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("Weeding") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="educacao1"
                    @change="atividades(108)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">
                  {{ $t("EnvironmentalEducation") }}
                </h4>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="form-wrapper mt-3 mb-5 form-class">
              <form class="d-flex align-center form">
                <div>
                  <label class="date-label" for="initialDate">{{
                    $t("of")
                  }}</label>
                  <input
                    name="initialDate"
                    id="initialDate"
                    class="mr-3"
                    type="date"
                    v-model="date_start_1"
                  />
                </div>

                <div>
                  <label class="date-label" for="finalDate">{{
                    $t("to")
                  }}</label>
                  <input
                    name="finalDate"
                    id="finalDate"
                    class="mr-3"
                    type="date"
                    v-model="date_end_1"
                  />
                </div>
              </form>
              <button @click="postMediaExecucoes()" class="filtrar">
                {{ $t("filter") }}
              </button>
            </div>
          </div>
          <h1 id="alert1" class="hidden">{{ $t("NoActivitySelected") }}</h1>
          <h1 id="alert2" class="hidden">{{ $t("15less") }}</h1>
          <div v-if="show === true">
            <Bar :newGraphY="barY" :newGraphX="barX" />
          </div>
        </div>
      </div>

      <div v-if="this.currentTab === 2">
        <div class="tag-box">
          <h3 class="titulo-tab">{{ $t("RunsPerWeeklyCycle") }}</h3>
          <div class="atividades">
            <div class="atividade-titulo">
              <h3>{{ $t("SelectActivities") }}</h3>
              <div class="atividade selecionar-todos">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="selecionarTodos2"
                    @click="todos2()"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("SelectAll") }}</h4>
              </div>
            </div>
            <div class="atividade-switches">
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="horta2"
                    @change="atividades2(103)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("CommunityGardens") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="lixeira2"
                    @change="atividades2(102)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("CommunityRecycleBins") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="solicitacao2"
                    @change="atividades2(104)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("RequestFromResidents") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="boca2"
                    @change="atividades2(106)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("Manhole") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="varricao2"
                    @change="atividades2(100)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("Sweeping") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="infraestrutura2"
                    @change="atividades2(107)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("UrbanInfrastructure") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="descarte2"
                    @change="atividades2(105)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("IrregularDiscard") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="capina2"
                    @change="atividades2(101)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">{{ $t("Weeding") }}</h4>
              </div>
              <div class="atividade">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="educacao2"
                    @change="atividades2(108)"
                  />
                  <span class="slider round"></span>
                </label>
                <h4 class="atividade-nome">
                  {{ $t("EnvironmentalEducation") }}
                </h4>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="form-wrapper mt-3 mb-5 form-class">
              <form class="d-flex align-center form">
                <div>
                  <label class="date-label" for="initialDate">{{
                    $t("of")
                  }}</label>
                  <input
                    name="initialDate"
                    id="initialDate"
                    class="mr-3"
                    type="month"
                    v-model="date_start_2"
                  />
                </div>

                <div>
                  <label class="date-label" for="finalDate">{{
                    $t("to")
                  }}</label>
                  <input
                    name="finalDate"
                    id="finalDate"
                    class="mr-3"
                    type="month"
                    v-model="date_end_2"
                  />
                </div>
              </form>
              <button @click="postExecucoesCiclo()" class="filtrar">
                {{ $t("filter") }}
              </button>
            </div>
          </div>
          <div v-if="show2 === true">
            <Bar :newGraphY="barY2" :newGraphX="barX2" />
          </div>
        </div>
        <h1 id="alert3" class="hidden">{{ $t("NoActivitySelected") }}</h1>
        <h1 id="alert4" class="hidden">{{ $t("3less") }}</h1>
      </div>

      <div v-if="this.currentTab === 3">
        <div class="tag-box">
          <h3 class="titulo-tab">{{ $t("InspectorAndMonitorAssessment") }}</h3>
          <div class="flex-row">
            <div class="form-wrapper mt-3 mb-5 form-class">
              <form class="d-flex align-center form">
                <div>
                  <label class="date-label" for="initialDate">{{
                    $t("of")
                  }}</label>
                  <input
                    name="initialDate"
                    id="initialDate"
                    class="mr-3"
                    type="month"
                    v-model="date_start_4"
                  />
                </div>

                <div>
                  <label class="date-label" for="finalDate">{{
                    $t("to")
                  }}</label>
                  <input
                    name="finalDate"
                    id="finalDate"
                    class="mr-3"
                    type="month"
                    v-model="date_end_4"
                  />
                </div>
              </form>
              <button @click="postAvaliacaoFiscalMonitor()" class="filtrar">
                {{ $t("filter") }}
              </button>
            </div>
          </div>
          <div v-if="show4 === true">
            <LineChart :newGraphY="barY4" :newGraphX="barX4" />
          </div>
          <h1 id="alert6" class="hidden">{{ $t("3less") }}</h1>
        </div>
      </div>

      <div v-if="this.currentTab === 4">
        <div class="tag-box">
          <h3 class="titulo-tab">{{ $t("KeeperNoteHistory") }}</h3>
          <div class="flex-row">
            <div class="form-wrapper mt-3 mb-5 form-class">
              <form class="d-flex align-center form">
                <div>
                  <label class="date-label" for="initialDate">{{
                    $t("of")
                  }}</label>
                  <input
                    name="initialDate"
                    id="initialDate"
                    class="mr-3"
                    type="month"
                    v-model="date_start_3"
                  />
                </div>

                <div>
                  <label class="date-label" for="finalDate">{{
                    $t("to")
                  }}</label>
                  <input
                    name="finalDate"
                    id="finalDate"
                    class="mr-3"
                    type="month"
                    v-model="date_end_3"
                  />
                </div>
              </form>
              <button @click="postAvaliacao()" class="filtrar">Filtrar</button>
            </div>
          </div>
          <div v-if="show3 === true">
            <LineChart :newGraphY="barY3" :newGraphX="barX3" />
          </div>
          <h1 id="alert5" class="hidden">{{ $t("3less") }}</h1>
        </div>
      </div>
    </transition>

    <Loading v-if="loadingData" />
  </div>
</template>
  <script>
import services from "../../services";
import { formatPhone } from "../../utils/phone";
import { formatDate } from "../../utils/date";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Table from "../../components/table/Table.vue";
import NoResults from "../../components/table/NoResults.vue";

import { formatForGoogleMaps } from "../../utils/geoMaps";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Bar from "../../components/Bar.vue";
import LineChart from "../../components/LineChart.vue";

export default {
  components: {
    BackArrow,
    Loading,
    Table,
    TableRow,
    TableCell,
    Bar,
    LineChart,
    NoResults,
  },
  data() {
    return {
      selecionarTodos: false,
      selecionarTodos2: false,
      horta1: false,
      lixeira1: false,
      solicitacao1: false,
      boca1: false,
      varricao1: false,
      infraestrutura1: false,
      descarte1: false,
      capina1: false,
      educacao1: false,
      horta2: false,
      lixeira2: false,
      solicitacao2: false,
      boca2: false,
      varricao2: false,
      infraestrutura2: false,
      descarte2: false,
      capina2: false,
      educacao2: false,
      show: false,
      show2: false,
      show3: false,
      show4: false,
      date_start: null,
      date_end: null,
      date_start_1: null,
      date_end_1: null,
      date_start_2: null,
      date_end_2: null,
      date_start_3: null,
      date_end_3: null,
      date_start_4: null,
      date_end_4: null,
      atividades_tag_1: [],
      atividades_tag_2: [],
      mediaExecucoes: [],
      mediaExecucoesCiclos: [],
      notaMedia: null,
      notaMediaInicio: null,
      notaMediaFim: null,
      notaMediaCiclos: [],
      notaValor: [],
      notaMedia2: null,
      notaMediaInicio2: null,
      notaMediaFim2: null,
      notaMediaCiclos2: [],
      notaValor2: [],
      notaMedia3: null,
      notaMediaInicio3: null,
      notaMediaFim3: null,
      notaMediaCiclos3: [],
      notaValor3: [],
      barY: {
        label: "Média de execuções por dia",
        backgroundColor: "#B8E9F9",
        data: [],
      },
      barY2: {
        label: "Média de execuções por ciclo",
        backgroundColor: "#B8E9F9",
        data: [],
      },
      barY3: [
        {
          label: "Média de execuções por ciclo",
          backgroundColor: "#00B1EB",
          borderColor: "#00B1EB",
          data: [],
        },
      ],
      barY4: [
        {
          label: "Avaliação do Fiscal",
          backgroundColor: "#003063",
          borderColor: "#003063",
          data: [],
        },
        {
          label: "Avaliação do Monitor",
          backgroundColor: "#00B1EB",
          borderColor: "#00B1EB",
          data: [],
        },
      ],
      barX: [],
      barX2: [],
      barX3: [],
      barX4: [],
      mediaExecucoesData: [],
      mediaExecucoesQuantidade: [],
      mediaExecucoesDataCiclos: [],
      mediaExecucoesDataCiclosInicio: [],
      mediaExecucoesDataCiclosFim: [],
      mediaExecucoesQuantidadeCiclos: [],
      ciclos: [],
      horasTrabalhadas: [],
      fields: [
        {
          key: "ciclo_inicio",
          label: "Ciclo Inicio",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "ciclo_fim",
          label: "Ciclo Fim",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "tempo_execucao",
          label: "Horas Trabalhadas",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
      ],
      avatar: null,
      bounds: [],
      currentTabData: null,
      loading: false,
      zelador: {},
      bairro: {},
      zoom: 4,
      center: {
        lat: -25.43432,
        lng: -49.2638,
      },
      polyline: null,
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "roadmap",
      polygonSector: [],
      candidates: [],
      searchString: null,
      loadingData: false,
      tabs: [
        { active: true, label: "Horas trabalhadas", value: 0, show: false },
        {
          active: false,
          label: "Média diária de execuções",
          value: 1,
          show: true,
        },
        {
          active: false,
          label: "Execuções por ciclo semanal",
          value: 2,
          show: true,
        },
        {
          active: false,
          label: "Avaliação Fiscal e monitor",
          value: 3,
          show: true,
        },
        {
          active: false,
          label: "Histórico de notas do zelador",
          value: 4,
          show: true,
        },
      ],
      currentTab: 0,
      formatPhone,
      formatDate,
    };
  },
  mounted() {
    this.getDadosZelador();
    this.verificarCodigo();
  },

  methods: {
    atividades(cod) {
      let stop = false;
      for (let i = 0; i < this.atividades_tag_1.length; i++) {
        if (cod === this.atividades_tag_1[i]) {
          this.atividades_tag_1.splice(i, 1);
          stop = true;
          break;
        }
      }
      if (stop === false) {
        this.atividades_tag_1.push(cod);
      }
    },
    todos() {
      if (this.selecionarTodos === false) {
        this.selecionarTodos = true;
        this.horta1 = true;
        this.lixeira1 = true;
        this.solicitacao1 = true;
        this.boca1 = true;
        this.varricao1 = true;
        this.infraestrutura1 = true;
        this.descarte1 = true;
        this.capina1 = true;
        this.educacao1 = true;
        this.atividades_tag_1 = [100, 108, 101, 102, 103, 104, 105, 106, 107];
      } else {
        this.selecionarTodos = false;
        this.horta1 = false;
        this.lixeira1 = false;
        this.solicitacao1 = false;
        this.boca1 = false;
        this.varricao1 = false;
        this.infraestrutura1 = false;
        this.descarte1 = false;
        this.capina1 = false;
        this.educacao1 = false;
        this.atividades_tag_1 = [];
      }
    },
    todos2() {
      if (this.selecionarTodos2 === false) {
        this.selecionarTodos2 = true;
        this.horta2 = true;
        this.lixeira2 = true;
        this.solicitacao2 = true;
        this.boca2 = true;
        this.varricao2 = true;
        this.infraestrutura2 = true;
        this.descarte2 = true;
        this.capina2 = true;
        this.educacao2 = true;
        this.atividades_tag_2 = [100, 108, 101, 102, 103, 104, 105, 106, 107];
      } else {
        this.selecionarTodos2 = false;
        this.horta2 = false;
        this.lixeira2 = false;
        this.solicitacao2 = false;
        this.boca2 = false;
        this.varricao2 = false;
        this.infraestrutura2 = false;
        this.descarte2 = false;
        this.capina2 = false;
        this.educacao2 = false;
        this.atividades_tag_2 = [];
      }
    },
    atividades2(cod) {
      let stop2 = false;
      for (let i = 0; i < this.atividades_tag_2.length; i++) {
        if (cod === this.atividades_tag_2[i]) {
          this.atividades_tag_2.splice(i, 1);
          stop2 = true;
          break;
        }
      }
      if (stop2 === false) {
        this.atividades_tag_2.push(cod);
      }
    },
    async postHorasTrabalhadas() {
      const { data, errors } = await services.analytics.postHorasTrabalhadas({
        usuario_id: this.$route.params.id,
        data_inicio: this.date_start,
        data_fim: this.date_end,
      });
      if (!errors) {
        this.horasTrabalhadas = data;
      }
    },
    async postMediaExecucoes() {
      this.show = false;
      const alert1 = document.getElementById("alert1");
      const alert2 = document.getElementById("alert2");
      if (this.atividades_tag_1.length === 0) {
        alert1.classList.remove("hidden");
      } else {
        alert1.classList.add("hidden");
        const init = new Date(this.date_start_1);
        const end = new Date(this.date_end_1);
        const diff = Math.abs(end.getTime() - init.getTime());
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (days > 16) {
          alert2.classList.remove("hidden");
        } else {
          alert2.classList.add("hidden");
          const { data, errors } = await services.analytics.postMediaExecucoes({
            usuario_id: this.$route.params.id,
            data_inicio: this.date_start_1,
            data_fim: this.date_end_1,
            atividades: this.atividades_tag_1,
          });
          if (!errors) {
            this.mediaExecucoes = data;
            this.mediaExecucoesData = this.mediaExecucoes.map((item) => {
              return formatDate(item.data_execucao);
            });
            this.mediaExecucoesQuantidade = this.mediaExecucoes.map((item) => {
              return item.quantidade_execucao;
            });
            this.barX = this.mediaExecucoesData;
            this.barY.data = this.mediaExecucoesQuantidade;
            this.show = true;
          }
        }
      }
    },
    async postExecucoesCiclo() {
      this.show2 = false;
      const alert3 = document.getElementById("alert3");
      const alert4 = document.getElementById("alert4");
      if (this.atividades_tag_2.length === 0) {
        alert3.classList.remove("hidden");
      } else {
        alert3.classList.add("hidden");
        const init = new Date(this.date_start_2);
        const end = new Date(this.date_end_2);
        const diff = Math.abs(end.getTime() - init.getTime());
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (days > 93) {
          alert4.classList.remove("hidden");
        } else {
          alert4.classList.add("hidden");
          const { data, errors } = await services.analytics.postExecucoesCiclo({
            usuario_id: this.$route.params.id,
            data_inicio: this.date_start_2,
            data_fim: this.date_end_2,
            atividades: this.atividades_tag_2,
          });
          if (!errors) {
            this.mediaExecucoesCiclos = data;
            this.mediaExecucoesDataCiclosInicio = this.mediaExecucoesCiclos.map(
              (item) => {
                return formatDate(item.ciclo_inicio);
              }
            );
            this.mediaExecucoesDataCiclosFim = this.mediaExecucoesCiclos.map(
              (item) => {
                return formatDate(item.ciclo_fim);
              }
            );
            for (
              let i = 0;
              i < this.mediaExecucoesDataCiclosInicio.length;
              i++
            ) {
              let init = this.mediaExecucoesDataCiclosInicio[i];
              let end = this.mediaExecucoesDataCiclosFim[i];
              let ciclo = init + " a " + end;
              this.mediaExecucoesDataCiclos.push(ciclo);
            }
            this.mediaExecucoesQuantidadeCiclos = this.mediaExecucoesCiclos.map(
              (item) => {
                return item.quantidade_execucao;
              }
            );
            this.barX2 = this.mediaExecucoesDataCiclos;
            this.barY2.data = this.mediaExecucoesQuantidadeCiclos;
            this.show2 = true;
          }
        }
      }
    },
    async postAvaliacao() {
      this.show3 = false;
      const alert5 = document.getElementById("alert5");
      const init = new Date(this.date_start_3);
      const end = new Date(this.date_end_3);
      const diff = Math.abs(end.getTime() - init.getTime());
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (days > 94) {
        alert5.classList.remove("hidden");
      } else {
        alert5.classList.add("hidden");
        const { data, errors } = await services.analytics.postAvaliacao({
          usuario_id: this.$route.params.id,
          data_inicio: this.date_start_3,
          data_fim: this.date_end_3,
        });
        if (!errors) {
          this.notaMedia = data;
          this.notaMediaInicio = this.notaMedia.map((item) => {
            return formatDate(item.ciclo_inicio);
          });
          this.notaMediaFim = this.notaMedia.map((item) => {
            return formatDate(item.ciclo_fim);
          });
          this.notaMediaCiclos = [];
          for (let i = 0; i < this.notaMediaInicio.length; i++) {
            let init = this.notaMediaInicio[i];
            let end = this.notaMediaFim[i];
            let ciclo = init + " a " + end;
            this.notaMediaCiclos.push(ciclo);
          }
          this.notaValor = null;
          this.notaValor = this.notaMedia.map((item) => {
            return item.execucao_nota_media;
          });
          this.barX3 = this.notaMediaCiclos;
          this.barY3[0].data = this.notaValor;
          this.show3 = true;
        }
      }
    },
    async postAvaliacaoFiscalMonitor() {
      this.show4 = false;
      const alert6 = document.getElementById("alert6");
      const init = new Date(this.date_start_4);
      const end = new Date(this.date_end_4);
      const diff = Math.abs(end.getTime() - init.getTime());
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (days > 94) {
        alert6.classList.remove("hidden");
      } else {
        alert6.classList.add("hidden");
        const { data, errors } =
          await services.analytics.postAvaliacaoFiscalMonitor({
            usuario_id: this.$route.params.id,
            data_inicio: this.date_start_4,
            data_fim: this.date_end_4,
          });
        this.notaMediaCiclos2 = [];
        if (!errors) {
          this.notaMedia2 = data;
          this.notaMediaInicio2 = this.notaMedia2.avaliacao_fiscal.map(
            (item) => {
              return formatDate(item.ciclo_inicio);
            }
          );
          this.notaMediaFim2 = this.notaMedia2.avaliacao_fiscal.map((item) => {
            return formatDate(item.ciclo_fim);
          });
          for (let i = 0; i < this.notaMediaInicio2.length; i++) {
            let init = this.notaMediaInicio2[i];
            let end = this.notaMediaFim2[i];
            let ciclo = init + " a " + end;
            this.notaMediaCiclos2.push(ciclo);
          }
          this.notaValor2 = null;
          this.notaValor2 = this.notaMedia2.avaliacao_fiscal.map((item) => {
            return item.execucao_avaliacao_fiscal_media;
          });
          this.barX4 = this.notaMediaCiclos2;
          this.barY4[0].data = this.notaValor2;
        }
        if (!errors) {
          this.notaMedia3 = data;
          this.notaValor3 = null;
          this.notaValor3 = this.notaMedia3.avaliacao_monitor.map((item) => {
            return item.execucao_avaliacao_monitor_media;
          });
          this.barY4[1].data = this.notaValor3;
          this.show4 = true;
        }
      }
    },
    formatDateToBR(date) {
      if (date) {
        let dateFormat = date.split("T");
        let dateSplit = dateFormat[0].split("-");
        let timeSplit = dateFormat[1].split(":");
        let dateBR =
          dateSplit[2] +
          "/" +
          dateSplit[1] +
          "/" +
          dateSplit[0] +
          " " +
          timeSplit[0] +
          ":" +
          timeSplit[1];
        return dateBR;
      }
      return "-";
    },
    async getDadosZelador() {
      const { data, errors } = await services.analytics.getZelador({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.zelador = data;
        this.mountPolygon(data.zeladoria_limite[0]);
        this.avatar = data.usuario_foto;
      }
    },
    async verificarCodigo() {
      const { data, errors } = await services.analytics.getVerifica();
      if (!errors) {
        console.log(data);
      }
    },
    mountPolygon(poligono) {
      if (poligono !== "") {
        this.polygonSector = [];
        const coordinatesJSONParse = poligono;
        let bairro = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse),
          options: {
            strokeColor: "#348534",
            fillColor: "#20d820",
          },
          id: 1,
        };
        bairro.latlngs.map((item) => {
          this.bounds.push(item);
        });

        this.polygonSector.push(bairro);
      }
      this.fitBoundsGoogle();
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 100);
    },
    async handleChangeTab(value) {
      this.currentTab = value;
      this.tabs.map((tab) => {
        if (tab.value === value) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
    },

    async infiniteHandler($state) {
      let currentData = null;
      let currentErrors = null;
      this.pagination.page++;

      if (this.pagination.filter) {
        const { data, errors } = await services.candidates.getCandidatesByName(
          this.pagination
        );
        currentData = data;
        currentErrors = errors;
      } else {
        const { data, errors } = await services.candidates.getCandidates(
          this.pagination
        );
        currentData = data;
        currentErrors = errors;
      }

      if (!currentErrors) {
        if (currentData.length > 0) {
          let temp = currentData;
          temp.forEach((item) => {
            this.candidates.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      } else {
        $state.complete();
      }
    },

    handleDetailsClick(candidateId) {
      this.$router.push(`/candidatos/${candidateId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-class {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lista {
  width: 100%;
}

.button {
  color: white;
  padding: 8px 24px;
  background: #003063;
  border-radius: 8px;
  margin: 0px 8px;
  cursor: pointer;
}

.grafico {
  height: 500px;
}

.position-relative {
  position: relative;
}

.selecionar-ano {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  width: 260px;
  height: 447px;
  border: 1px solid lightgray;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 10;
}

.flex-row {
  display: flex;
  flex-direction: column;
}

.coluna {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.duas-colunas {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.display-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filtrar {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  background: #003063;
  border-radius: 8px;
  border: none;

  padding: 4px 18px;

  &:hover {
    background: #003063;
  }

  &:focus {
    outline: none;
    background: #003050;
  }
}

.limpar-filtro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 8px;
  width: 100%;
}

.nome-mes {
  color: #565e73;
  font-size: 14px;
}

.checkbox-mes {
  background: #c6c9d0;
  width: 15px;
  height: 15px;
  margin: 0px 8px;
}

fieldset {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.titulo-select {
  margin-bottom: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #565e73;
}

.selecionar-mes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  width: 260px;
  height: 447px;
  border: 1px solid lightgray;
  border-radius: 12px;
  position: absolute;
  background-color: white;
  z-index: 10;
}

label {
  margin: 0px;
  cursor: pointer;
}

.selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 8px 16px;
  width: 152px;
  height: 32px;
  background: #003063;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  margin: 0px 8px;
}

.display {
  display: none;
}

.titulo-tab {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
  margin: 32px 0px;
}

.map {
  margin: 0px;
}

.map-box {
  width: 70%;
}

.box-span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box-icon {
  width: 16px;
  height: 16px;
  margin: 0px 8px 0px 24px;
}

.box-text {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #565e73;
  padding: 0px;
  margin: 6px 0px;
}

.avatar {
  width: 60px;
  border-radius: 50%;
  height: 60px;
  margin: 8px 24px 24px 24px;
}

.big-box {
  display: flex;
  justify-content: space-between;
  height: 320px;
  width: 100%;
  border: 1px solid #dddee3;
  border-radius: 16px;
  padding: 4px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.subtitle {
  color: #565e73;
  font-size: 18px;
  font-family: "Open Sans";
  padding: 16px 32px;
}

.counter.show {
  display: none;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6f7fd;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  padding: 4px 8px;
  color: #00b1eb;
}

.candidates {
  background-color: #fff;
  border-radius: 8px;
  padding-top: 40px !important;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #003366;

  &:focus {
    outline: none;
    border: 1px solid #9f9e9e;
    box-shadow: 0 0 4px #bde6ff;
  }
}

.reproved-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  text-transform: uppercase;
  color: #838998;
}

.actions {
  cursor: pointer;
}

.tabs {
  display: flex;
  color: #838998;
  text-align: left;
}

.tab {
  display: flex;
  flex-direction: row;
  padding: 4px 16px 8px 16px;
  cursor: pointer;
  text-align: left;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #838998;

  &:hover {
    background: #eee;
  }
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.expired {
  color: tomato;
}

.form {
  input {
    width: 170px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    color: #747474;
  }
  label {
    margin-right: 10px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

.date-label {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  color: #565e73;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.atividades {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 40px;
  border: 1px solid #dddee3;
  border-radius: 16px;
  margin: 16px 0px;
}
.atividade {
  display: flex;
  flex-direction: row;
  margin: 0px 5px;
  align-items: center;
}
.atividade-switches {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px;
  gap: 40px;
}
.atividade-nome {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #565e73;
  margin-left: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.atividade-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #565e73;
}
.selecionar-todos {
  margin-right: 64px;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 24px;
}
#alert1 {
  color: red;
  font-size: 14px;
}
#alert2 {
  color: red;
  font-size: 14px;
}
#alert3 {
  color: red;
  font-size: 14px;
}
#alert4 {
  color: red;
  font-size: 14px;
}
#alert5 {
  color: red;
  font-size: 14px;
}
.hidden {
  display: none;
}
</style>

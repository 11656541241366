<template>
  <div class="supervisor-types">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav">Cadastros de tipos de Fiscal</span>
      </h3>
      <Button
        @clicked="handleModalOpen"
        class="btn-report"
        name="Novo tipo de fiscal"
        size="small"
      ></Button>
    </div>
    <transition name="bounce">
      <div v-if="!loading">
        <div>
          <Table v-if="!loading" :fields="fields" :hasActions="true">
            <TableRow
              v-for="item in supervisorTypes"
              :key="item.fiscal_tipo_id"
            >
              <TableCell
                v-for="field in fields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  <span class="table-row-text">{{ item[field.key] }}</span>
                </template>
              </TableCell>
              <TableCell class="d-flex justify-content-end">
                <CondensedDropdown
                  class="mt-1"
                  @dropdown-click="
                    (ev) => handleCondensedDropdownClick(ev, item)
                  "
                  :options="options"
                />
              </TableCell>
            </TableRow>
            <template v-slot:no-results>
              <NoResults
                v-if="supervisorTypes.length <= 0"
                :message="$t('noResults')"
              />
            </template>
          </Table>
        </div>
      </div>
      <Loading v-if="loading" />
    </transition>

    <Modal class="modal-type" v-show="createModal" :width="400">
      <template v-slot:header>
        <span class="titulo">{{
          isEditing ? "Editar tipo de Fiscal" : "Novo tipo de Fiscal"
        }}</span>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          <div class="mb-3">
            <Input
              name="name"
              placeholder="Inserir tipo"
              v-model="typeForm.name"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="btn-container">
          <Button
            :disabled="!typeForm.name"
            class="save-btn"
            size="sm"
            @clicked="handleTypeSave"
            name="Salvar"
          />
          <Button
            class="cancel-btn"
            type="outline"
            size="sm"
            :name="$t('cancel')"
            @clicked="handleModalCancel"
          />
        </div>
      </template>
    </Modal>
    <Modal class="modal-type" v-show="deleteModal" :width="400">
      <template v-slot:header>
        <span class="titulo" style="justify-content: center"
          >Excluir tipo de fiscal</span
        >
      </template>
      <template v-slot:body>
        <p class="delete-confirmation">
          Tem certeza que deseja excluir o tipo de fiscal
          <b>{{ typeToDelete.name }}</b> da Zeladoria Digital ?
        </p>
      </template>
      <template v-slot:footer>
        <div class="btn-container">
          <Button
            @clicked="handleDeleteType"
            class="mb-3"
            size="sm"
            style="background-color: #ff002e; border: none"
            name="Excluir"
          />
          <Button
            :name="$t('cancel')"
            @clicked="handleDeleteModalClose"
            type="outline"
          />
        </div>
      </template>
    </Modal>
    <Modal class="modal-confirmacao" v-show="confirmationModal" :width="400">
      <template v-slot:header>
        <div>
          <svg
            class="mb-3"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0002 73.6666C16.7492 73.6666 0.333496 57.2509 0.333496 36.9999C0.333496 16.7489 16.7492 0.333252 37.0002 0.333252C57.2512 0.333252 73.6668 16.7489 73.6668 36.9999C73.6668 57.2509 57.2512 73.6666 37.0002 73.6666ZM33.3445 51.6666L59.2678 25.7396L54.0832 20.5549L33.3445 41.2973L22.9715 30.9243L17.7868 36.1089L33.3445 51.6666Z"
              fill="#80BE24"
            />
          </svg>
          <div class="titulo">Pronto !</div>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">
          {{
            isEditing
              ? "Suas alterações foram salvas"
              : "Novo tipo de fiscal cadastrado"
          }}
        </div>
      </template>
      <template v-slot:footer>
        <Button
          class="w-100"
          name="Confirmar"
          @clicked="confirmationModal = false"
        />
      </template>
    </Modal>
    <Modal
      class="modal-confirmacao"
      v-show="deleteTypeConfirmationModal"
      :width="400"
    >
      <template v-slot:header>
        <div>
          <svg
            class="mb-3"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0002 73.6666C16.7492 73.6666 0.333496 57.2509 0.333496 36.9999C0.333496 16.7489 16.7492 0.333252 37.0002 0.333252C57.2512 0.333252 73.6668 16.7489 73.6668 36.9999C73.6668 57.2509 57.2512 73.6666 37.0002 73.6666ZM33.3445 51.6666L59.2678 25.7396L54.0832 20.5549L33.3445 41.2973L22.9715 30.9243L17.7868 36.1089L33.3445 51.6666Z"
              fill="#80BE24"
            />
          </svg>
          <div class="titulo">Pronto !</div>
        </div>
      </template>
      <template v-slot:body>
        <div class="subtitle">O tipo de fiscal foi excluído</div>
      </template>
      <template v-slot:footer>
        <Button
          class="w-100"
          name="Confirmar"
          @clicked="deleteTypeConfirmationModal = false"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import BackArrow from "../../components/BackArrow.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Table from "../../components/table/Table.vue";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/form/Input.vue";
import services from "../../services";
export default {
  components: {
    Button,
    Input,
    BackArrow,
    CondensedDropdown,
    Table,
    TableCell,
    TableRow,
    Loading,
    NoResults,
    Modal,
  },
  async created() {
    await this.loadSupervisorTypes();
  },
  data() {
    return {
      isEditing: false,
      createModal: false,
      confirmationModal: false,
      supervisorTypes: [],
      deleteModal: false,
      loading: false,
      deleteTypeConfirmationModal: false,
      typeForm: {
        id: null,
        name: null,
      },
      typeToDelete: {
        name: null,
        id: null,
      },
      fields: [
        {
          key: "fiscal_tipo_descricao",
          label: "Nome",
          sortable: false,
          sortDirection: null,
        },
      ],
      options: [
        { label: "Editar", name: "Editar", value: "edit" },
        { label: "Excluir", name: "Excluir", value: "delete" },
      ],
    };
  },
  methods: {
    clearTypeForm() {
      this.typeForm = {
        id: null,
        name: null,
      };
    },
    clearTypeToDelete() {
      this.typeToDelete = {
        id: null,
        name: null,
      };
    },
    handleModalCancel() {
      this.createModal = false;
      this.clearTypeForm();
    },
    handleDeleteModalClose() {
      this.clearTypeToDelete();
      this.deleteModal = false;
    },
    async loadSupervisorTypeById(typeId) {
      const { data, errors } =
        await services.UsersSupervisors.getSupervisorTypeById(typeId);
      if (!errors) {
        this.typeForm = {
          id: data[0].fiscal_tipo_id,
          name: data[0].fiscal_tipo_descricao,
        };
      }
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "edit") {
        this.isEditing = true;
        await this.loadSupervisorTypeById(item.fiscal_tipo_id);
        this.createModal = true;
      }
      if (value === "delete") {
        this.typeToDelete = {
          id: item.fiscal_tipo_id,
          name: item.fiscal_tipo_descricao,
        };
        this.deleteModal = true;
      }
    },
    async loadSupervisorTypes() {
      this.loading = true;
      const { data, errors } =
        await services.UsersSupervisors.getSupervisorTypes();
      if (!errors) {
        this.supervisorTypes = data;
      }
      this.loading = false;
    },
    async handleModalOpen() {
      if (!this.isEditing) {
        this.typeForm = {
          id: null,
          name: null,
        };
      }
      this.createModal = true;
    },
    async handleTypeSave() {
      const payload = {
        fiscal_tipo_id: this.isEditing ? this.typeForm.id : null,
        fiscal_tipo_descricao: this.typeForm.name,
      };

      let err = null;
      if (this.isEditing) {
        const { errors } = await services.UsersSupervisors.putSupervisorType(
          payload
        );
        err = errors;
      } else {
        const { errors } = await services.UsersSupervisors.postSupervisorType(
          payload
        );
        err = errors;
      }

      if (!err) {
        this.createModal = false;
        this.confirmationModal = true;
        this.clearTypeForm();
        await this.loadSupervisorTypes();
      }
    },
    async handleDeleteType() {
      this.loading = true;
      const { errors } = await services.UsersSupervisors.deleteSupervisorType(
        this.typeToDelete.id
      );
      if (!errors) {
        this.typeToDelete = {
          id: null,
          name: null,
        };
      }
      this.deleteModal = false;
      this.deleteTypeConfirmationModal = true;
      this.clearTypeToDelete();
      await this.loadSupervisorTypes();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-type {
  .titulo {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;
    justify-content: flex-start;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #131e3b;
  }
  .subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #565e73;
    span {
      color: #003366;
    }
    ul {
      text-decoration: none;
      margin-top: 16px;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .cancel-btn {
      margin-top: 12px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      border-radius: 8px;
      background: white;
      color: #131e3b;
      border: none;
      outline: none;

      &:hover {
        background: #e2f4ff;
      }
    }
    .save-btn {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background: #003366;
      border-radius: 8px;
      color: white;
      border: none;
      outline: none;

      &:hover {
        background: #003366;
      }
    }
  }
}
.modal-confirmacao {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .titulo {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #131e3b;
  }
  .subtitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #565e73;
    margin-bottom: 12px;
    span {
      color: #003366;
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .save-btn {
      margin: 12px 0px 12px 12px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background: #003366;
      padding: 8px;
      border-radius: 8px;
      color: white;
      border: none;
      outline: none;

      &:hover {
        background: #003366;
      }
    }
  }
}
</style>

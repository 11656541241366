<template>
  <div>
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">Vincular zeladorias ao monitor</span>
    </h3>
    <transition name="bounce">
      <div v-if="!loadingData">
        <CCol>
          <CRow class="d-flex align-items-center mb-4">
            <CCol md="4" xs="6" sm="6" style="z-index: 900">
              <multiselect
                v-model="lastSelectedNeighborhood"
                noOptions="Lista vazia"
                selectLabel=""
                deselectLabel="remover"
                selectedLabel="Selecionado"
                :options="neighborhoodNames"
                track-by="name"
                placeholder="Selecione um bairro"
                :preselect-first="false"
                :multiple="true"
                label="name"
              ></multiselect>
            </CCol>
            <CCol md="1" xs="6" sm="6">
              <CButton
                size="md"
                color="primary"
                :disabled="keepingSectors.length === 0"
                @click="linkKeepingSector"
                >Vincular</CButton
              >
            </CCol>
          </CRow>
          <CRow class="d-flex align-items-center mb-3">
            <div
              v-for="item of keepingSectors"
              :key="item.zeladoria_id"
              class="ml-3 mr-2 mt-2"
            >
              <div class="d-flex align-items-center keeping-card">
                <p class="ml-1 card-name">{{ item.zeladoria_nome }}</p>
                <CButton
                  @click="removeSelectedKeepingSector(item.zeladoria_id)"
                >
                  <CIcon name="cilTrash" />
                </CButton>
              </div>
            </div>
          </CRow>
          <div class="mt-5">
            <div class="tip-text d-none">
              <strong v-if="lastSelectedNeighborhood.length > 0"
                >Agora selecione uma das áreas de zeladoria disponíveis no mapa
                <CIcon name="cilHandPointDown" class="ml-1"
              /></strong>
              <strong v-else
                >Selecione um bairro no seletor acima
                <CIcon name="cilHandPointUp" class="ml-1"
              /></strong>
            </div>
            <div class="mt-2">
              <div style="height: calc(100vh - 220px); position: relative">
                <GmapMap
                  ref="googleMap"
                  :center="center"
                  :zoom="zoom"
                  style="height: 100%; width: 100%"
                  :options="{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: true,
                    mapTypeId: mapType,
                    gestureHandling: 'greedy',
                    fullscreenControl: false,
                    disableDefaultUi: true,
                  }"
                >
                  <div
                    v-for="polygon of polygonsKeepingSectors"
                    :key="'polygonsKeepingSector' + polygon.id"
                  >
                    <GmapPolygon
                      @mouseover="polygon.display_name = true"
                      @mouseout="polygon.display_name = false"
                      @click="
                        selectKeepingSector(
                          polygon.zeladoria_id,
                          polygon.zeladoria_nome
                        )
                      "
                      ref="polygon"
                      :paths="polygon.latlngs"
                      :options="polygon.options"
                    />
                    <GmapCustomMarker
                      v-if="polygon.display_name"
                      :draggable="false"
                      :marker="polygon.center"
                    >
                      <span class="map-label">{{
                        polygon.zeladoria_nome
                      }}</span>
                    </GmapCustomMarker>
                  </div>
                </GmapMap>
                <div class="mapTypeControllers">
                  <CSelect
                    placeholder="Tipo"
                    :options="mapTypes"
                    :value.sync="mapType"
                  ></CSelect>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </div>
    </transition>
    <div
      v-if="loadingData"
      class="d-flex justify-content-center align-items-center"
    >
      <CCard class="center rounded-circle">
        <CCardBody>
          <img
            :src="require(`@/assets/img/loading.gif`)"
            class="mb-4"
            alt="AMA Carregando"
            width="150"
          />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import GmapCustomMarker from "vue2-gmap-custom-marker";

import { formatForGoogleMaps } from "../../utils/geoMaps";

import BackArrow from "../../components/BackArrow.vue";

import services from "../../services";

export default {
  name: "MapaZelador",
  components: {
    Multiselect,
    GmapCustomMarker,
    BackArrow,
  },
  computed: {},
  data() {
    return {
      zoom: 4,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      neighborhoodNames: [],
      lastSelectedNeighborhood: [],
      polygonsKeepingSectors: [],
      keepingSectors: [],
      initialKeepingSectors: [],
      loadingData: true,
      mapTypes: [
        { label: "Padrão", value: "roadmap" },
        { label: "Satélite", value: "satellite" },
        { label: "Híbrido", value: "hybrid" },
        { label: "Relevo", value: "terrain" },
      ],
      mapType: "satellite",
    };
  },
  watch: {
    async lastSelectedNeighborhood(value) {
      if (this.lastSelectedNeighborhood.length === 0) {
        this.emptyPolygons();
        return;
      }
      if (this.lastSelectedNeighborhood.length > 1) {
        this.lastSelectedNeighborhood.shift();
        return;
      }
      await this.requestPolygonsOfSelectedNeighborhood(value[0].id);
    },
  },
  mounted: async function () {
    await this.initExistingKeepingSectorsOfMonitor();
    await this.initNeighborhoods();
    this.loadingData = false;
  },
  methods: {
    async initNeighborhoods() {
      const { data, errors } =
        await services.neighborhoods.getNeighborhoodMapWithEvaluation();
      if (!errors) {
        this.neighborhoodNames = data.map((item) => {
          return {
            name: item.bairro_nome,
            id: item.bairro_id,
          };
        });
      }
    },
    async initExistingKeepingSectorsOfMonitor() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorsOfMonitor(
          this.$route.params.id
        );
      if (!errors) {
        data.map((item) => {
          this.keepingSectors.push({
            zeladoria_nome: item.zeladoria_nome,
            zeladoria_id: item.zeladoria_id,
          });
        });
        this.initialKeepingSectors = data;

        if (data.length) {
          data.map((item) => {
            this.mountPolygons(item, "#89CC49");
          });

          setTimeout(() => {
            this.fitBoundsGoogle();
          }, 500);
        }
      }
    },
    async requestPolygonsOfSelectedNeighborhood(neighborhoodId) {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorMapByNeighborhood(
          neighborhoodId
        );

      if (this.polygonsKeepingSectors.length > 0) {
        this.emptyPolygons();
      }

      if (!errors) {
        this.formatMapToRender(data);
        this.fitBoundsGoogle();
      } else {
        this.validationToast(
          {
            data: {
              message: "Não há dados cadastrados para esse bairro!",
            },
          },
          "error"
        );
      }
    },
    emptyPolygons() {
      this.bounds = [];

      let temporaryKeepings = [];
      this.keepingSectors.map((keepingSector) => {
        temporaryKeepings.push(
          this.polygonsKeepingSectors.filter(
            (polygon) => polygon.zeladoria_id === keepingSector.zeladoria_id
          )[0]
        );
      });

      this.polygonsKeepingSectors = temporaryKeepings;
    },
    formatMapToRender(data) {
      data.map((item) => {
        const foundKeepingSector = this.keepingSectors.findIndex(
          (keepingSector) => item.zeladoria_id === keepingSector.zeladoria_id
        );
        if (
          foundKeepingSector === -1 &&
          item.zeladoria_limite !== "" &&
          item.zeladoria_limite !== null
        ) {
          this.mountPolygons(item, "#4F0DCC");
        }
      });
    },
    mountPolygons(item, color) {
      const coordinatesJSONParse = item.zeladoria_limite;
      const centerJSONParse = {
        lat: item.zeladoria_lat,
        lng: item.zeladoria_lng,
      };
      let zeladoria = {
        latlngs: formatForGoogleMaps(coordinatesJSONParse[0]),
        options: {
          strokeColor: color,
          fillColor: color,
        },
        center: centerJSONParse,
        id: item.zeladoria_id,
        zeladoria_id: item.zeladoria_id,
        zeladoria_nome: item.zeladoria_nome,
        display_name: false,
      };

      zeladoria.latlngs.map((item) => {
        this.bounds.push(item);
      });

      this.polygonsKeepingSectors.push(zeladoria);
    },
    selectKeepingSector(idZeladoria, nomeZeladoria) {
      const foundKeepingSector = this.keepingSectors.findIndex(
        (item) => item.zeladoria_id === idZeladoria
      );

      if (foundKeepingSector === -1) {
        this.keepingSectors.push({
          zeladoria_id: idZeladoria,
          zeladoria_nome: nomeZeladoria,
        });

        let foundIndex = this.polygonsKeepingSectors.findIndex(
          (polygon) => polygon.zeladoria_id === idZeladoria
        );
        this.polygonsKeepingSectors[foundIndex].options.strokeColor = "#89CC49";
        this.polygonsKeepingSectors[foundIndex].options.fillColor = "#89CC49";
      }
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    async removeSelectedKeepingSector(id) {
      let foundIndex = this.initialKeepingSectors.findIndex(
        (item) => item.zeladoria_id === id
      );
      if (foundIndex !== -1) {
        await this.requestDeleteKeepingSector(id);
        this.initialKeepingSectors.splice(foundIndex, 1);
      }

      foundIndex = this.polygonsKeepingSectors.findIndex(
        (polygon) => polygon.zeladoria_id === id
      );
      if (foundIndex !== -1) {
        this.polygonsKeepingSectors[foundIndex].options.strokeColor = "#4F0DCC";
        this.polygonsKeepingSectors[foundIndex].options.fillColor = "#4F0DCC";
      }
      this.keepingSectors = this.keepingSectors.filter(
        (item) => item.zeladoria_id !== id
      );
    },
    async requestDeleteKeepingSector(id) {
      const { errors } =
        await services.keepingSectors.deleteKeepingSectorFromMonitor({
          userManagerId: this.$route.params.id,
          keepingSectorId: id,
        });

      if (!errors) {
        this.validationToast(
          {
            data: {
              message: "Vínculo removido com sucesso!",
            },
          },
          "success"
        );
      } else {
        this.validationToast(
          {
            data: {
              message: "Ocorreu um erro ao tentar remover o vínculo",
            },
          },
          "error"
        );
      }
    },
    async linkKeepingSector() {
      const promiseErros = [];

      await Promise.all(
        this.keepingSectors.map(async (item) => {
          let foundIndex = this.initialKeepingSectors.findIndex(
            (initial) => initial.zeladoria_id === item.zeladoria_id
          );
          if (foundIndex === -1) {
            const { errors } =
              await services.keepingSectors.linkKeepingSectorAndMonitor({
                usuario_gestor_id: parseInt(this.$route.params.id),
                zeladoria_id: item.zeladoria_id,
              });
            if (errors) {
              promiseErros.push(errors);
              this.validationToast(
                {
                  data: {
                    message: "Ocorreu um erro ao cadastrar o vínculo",
                  },
                },
                "error"
              );
            }
          }
        })
      );

      if (promiseErros.length === 0) {
        this.validationToast(
          {
            data: {
              message: "Vínculos cadastrados com sucesso!",
            },
          },
          "success"
        );
        this.$router.go(-1);
      }
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style>
.titulo_pin {
  background: #ffffff85;
  width: max-content;
  text-align: center;
  border-radius: 10px;
  font-size: 9px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
}

.mapTypeControllers {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 500;
  width: 120px;
}

.card-name {
  font-family: "OpenSans-Regular";
  font-weight: 500;
  font-size: 12px;
  color: #003366;
  text-align: left;
}

.keeping-card {
  width: fit-content;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
}
</style>

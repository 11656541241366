<template>
  <div class="filter-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="card-title">Filtrar por status</div>
      <div class="pointer close-icon" @click="$emit('close')">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99999 5.58672L11.95 0.636719L13.364 2.05072L8.41399 7.00072L13.364 11.9507L11.95 13.3647L6.99999 8.41472L2.04999 13.3647L0.635986 11.9507L5.58599 7.00072L0.635986 2.05072L2.04999 0.636719L6.99999 5.58672Z"
            fill="#565E73"
          />
        </svg>
      </div>
    </div>

    <div v-for="item of status" :key="item.atividade_pesquisa_status_id">
      <div class="d-flex align-items-center">
        <div>
          <input
            type="checkbox"
            :id="item.atividade_pesquisa_status_id"
            v-model="item.check"
            class="input-checkbox"
          />
        </div>
        <div class="mt-1 ml-2">
          <label class="card-label" :for="item.atividade_pesquisa_status_id">{{
            item.atividade_pesquisa_status_descricao
          }}</label>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mt-3">
      <Button @click.native="filter" class="mb-2" name="Filtrar" size="small" />

      <Button
        name="Cancelar"
        size="small"
        type="white"
        @click.native="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button.vue";

export default {
  name: "FilterStatus",
  components: {
    Button,
  },
  methods: {
    filter() {
      let statusValues = [];
      this.status.forEach((item) => {
        if (item.check) {
          statusValues.push(item.atividade_pesquisa_status_id);
        }
      });
      this.$emit("filter", statusValues);
    },
  },
  props: {
    status: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
  padding-left: 0;
  margin-right: 16px;
}

.close-icon {
  margin-bottom: 12px;
}

.card-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}

.input-checkbox {
  accent-color: #003366;
}

.filter-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  min-width: 261px;
  box-shadow: 0px 4px 4px rgba(160, 168, 189, 0.24);
}
</style>

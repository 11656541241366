<template>
  <div class="institutions">
    <div class="d-flex justify-content-between">
      <h3 class="ml-3 intern-title">
        <back-arrow />
        <span class="page-nav">{{ $t("institutions") }}</span>
      </h3>
      <Button
        class="mr-3"
        size="small"
        tyoe="primary"
        :name="$t('newInstitution')"
        @clicked="addingModal = true"
      />
    </div>

    <transition name="bounce">
      <div v-if="!loadingData">
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in institutions" :key="item.instituicao_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="institutions.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <Modal v-show="addingModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label">{{
          $t("newInstitution")
        }}</span></template
      >
      <template v-slot:body>
        <Input
          name="name"
          :label="$t('description')"
          v-model="newInstitutionName"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            type="outline"
            @clicked="addingModal = false"
            class="mr-3 w-50"
          />
          <Button
            class="w-50"
            :name="$t('confirm')"
            @clicked="addInstitution"
          />
        </div>
      </template>
    </Modal>

    <Modal v-show="editModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label">{{
          $t("editInstitution")
        }}</span></template
      >
      <template v-slot:body>
        <Input
          name="name"
          :label="$t('description')"
          v-model="institutionNameForEdit"
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            @clicked="editModal = false"
            type="outline"
            class="mr-3 w-50"
          />
          <Button
            class="w-50"
            :name="$t('confirm')"
            @clicked="editInstitution"
          />
        </div>
      </template>
    </Modal>

    <Modal v-show="deleteModal" :width="400">
      <template v-slot:header
        ><span class="modal-title-label">{{
          $t("deleteInstitution")
        }}</span></template
      >
      <template v-slot:body>
        <p class="delete-confirmation">
          {{ $t("confirmDeleteInstitution") + " " + institutionNameForDelete }}?
        </p>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            @clicked="deleteModal = false"
            type="outline"
            class="mr-3 w-50"
          />
          <Button
            class="w-50"
            :name="$t('confirm')"
            @clicked="deleteInstitution"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import BackArrow from "../../components/BackArrow.vue";
import Input from "../../components/form/Input.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Modal from "../../components/Modal.vue";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

import { debounce } from "../../utils/debounce";
import { successToast } from "../../utils/toast";

import services from "../../services";

export default {
  name: "Institutions",
  components: {
    Table,
    TableRow,
    CondensedDropdown,
    TableCell,
    NoResults,
    Button,
    Loading,
    Input,
    BackArrow,
    Modal,
  },
  data() {
    return {
      fields: [
        {
          key: "instituicao_descricao",
          label: this.$t("description"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      institutions: [],
      addingModal: false,
      newInstitutionName: "",
      editModal: false,
      institutionNameForEdit: "",
      institutionIdForEdit: 0,
      deleteModal: false,
      institutionNameForDelete: "",
      institutionIdForDelete: 0,
      loadingData: true,
      searchString: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
    };
  },
  mounted: async function () {
    await this.verifyUserRole();
    await this.getInstitutions();
    this.loadingData = false;
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.getNeighborhoods();
    }, 500),
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    redirectToList(item) {
      this.$router.push(`/execucoes/instituicao-${item.instituicao_id}`);
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "executions") {
        this.redirectToList(item);
      }
      if (value === "delete") {
        this.handleEditClick(item);
      }
      if (value === "edit") {
        this.handleDeleteClick(item);
      }
    },
    optionsByResearch() {
      return [
        { label: "Execuções", value: "executions" },
        { label: "Editar", value: "edit" },
        { label: "Excluir", value: "delete" },
      ];
    },
    handleEditClick(item) {
      this.institutionNameForEdit = item.instituicao_descricao;
      this.institutionIdForEdit = item.instituicao_id;
      this.editModal = true;
    },
    handleDeleteClick(item) {
      this.institutionNameForDelete = item.instituicao_descricao;
      this.institutionIdForDelete = item.instituicao_id;
      this.deleteModal = true;
    },
    async addInstitution() {
      const { errors } = await services.institutions.postInstitution({
        instituicao_descricao: this.newInstitutionName,
      });
      if (!errors) {
        await this.getInstitutions();
        this.newInstitutionName = "";
        successToast(this.$t("successAddInstitution"));
        this.addingModal = false;
      }
    },
    async editInstitution() {
      const { errors } = await services.institutions.putInstitution({
        instituicao_id: this.institutionIdForEdit,
        instituicao_descricao: this.institutionNameForEdit,
      });
      if (!errors) {
        await this.getInstitutions();
        this.institutionNameForEdit = "";
        this.institutionIdForEdit = 0;
        successToast(this.$t("successEditInstitution"));
        this.editModal = false;
      }
    },
    async deleteInstitution() {
      const { errors } = await services.institutions.deleteInstitution({
        institutionId: this.institutionIdForDelete,
      });
      if (!errors) {
        await this.getInstitutions();
        this.institutionNameForDelete = "";
        this.institutionIdForDelete = 0;
        successToast(this.$t("successDeleteInstitution"));
        this.deleteModal = false;
      }
    },
    async getInstitutions() {
      const { data, errors } = await services.institutions.getInstitutionsNew(
        this.pagination
      );
      if (!errors) {
        this.institutions = data.map((item, id) => {
          return { ...item, id };
        });
      }
    },
  },
};
</script>

<style scoped>
.icons-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}
</style>

<template>
  <div class="change-password">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("changePwd") }}</span>
    </h3>

    <div class="ml-5 inputs-container">
      <div class="line mt-4 mb-4" />

      <div class="row pt-3 pb-3 d-flex align-items-center">
        <label for="password" class="col-lg-2 col-sm-4 input-label">{{
          $t("actualPwd")
        }}</label>
        <input
          id="password"
          v-model="password"
          class="input col-lg-5 col-sm-6"
          type="password"
        />
      </div>

      <div class="line mt-4 mb-4" />

      <div class="row pt-3 pb-3 d-flex align-items-center">
        <label for="newPassword" class="col-lg-2 col-sm-4 input-label">{{
          $t("newPwd")
        }}</label>
        <input
          id="newPassword"
          v-model="new_password"
          class="input col-lg-5 col-sm-6"
          type="password"
        />
      </div>

      <div class="line mt-4 mb-4" />

      <div class="row pt-3 pb-3 d-flex align-items-center">
        <label for="confirmPassword" class="col-lg-2 col-sm-4 input-label">{{
          $t("confirmPwd")
        }}</label>
        <input
          id="confirmPassword"
          v-model="confirm_password"
          class="input col-lg-5 col-sm-6"
          type="password"
        />
      </div>

      <div class="line mt-4 mb-5" />
    </div>

    <div class="d-flex align-items-center justify-content-start ml-5">
      <Button
        @clicked="$router.go(-1)"
        size="small"
        class="mr-3"
        type="outline"
        name="Cancelar"
      />
      <Button @clicked="send" name="Atualizar senha" size="small" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import BackArrow from "../../components/BackArrow.vue";
import Button from "../../components/Button.vue";
import services from "../../services";

export default {
  name: "ChangePassword",
  components: {
    BackArrow,
    Button,
  },
  data() {
    return {
      password: "",
      new_password: "",
      confirm_password: "",
    };
  },
  methods: {
    send: async function () {
      let regEx = /(?:[A-Za-z].*?\d|\d.*?[A-Za-z])/;
      if (this.new_password.length < 6) {
        this.toastError(this.$t("newPwdSize"));
        return;
      }
      if (this.new_password !== this.confirm_password) {
        this.toastError(this.$t("pwdNotMatch"));
        return;
      }
      if (!regEx.test(this.new_password)) {
        this.toastError(this.$t("pwdNumbers"));
        return;
      }

      let passwords = {
        usuario_senha: this.password,
        usuario_nova_senha: this.new_password,
      };

      const { errors } = await services.usersManagers.changePassword(passwords);
      if (!errors) {
        this.toastError(this.$t("pwdUpdated"), "success");
        this.password = "";
        this.new_password = "";
        this.confirm_password = "";
      }
    },
    toastError(msg, type = "error") {
      Vue.$toast.open({
        message: msg,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style lang="scss">
.change-password {
  background-color: #fff;
  border-radius: 8px;

  .intern-subtitle {
    font-family: "OpenSans-SemiBold";
    font-size: 16px;
    color: #747474;
  }

  .line {
    border: 1px solid #e4e4e4;
    width: 120%;
  }

  .inputs-container {
    width: 80%;
  }

  .input {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    width: 300px;

    &:focus {
      outline: none;
      border: 1px solid #9f9e9e;
      box-shadow: 0 0 4px #bde6ff;
    }
  }

  .input-label {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 166.5%;
    color: #747474;
  }
}
</style>

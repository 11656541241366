<template>
  <div>
    <span
      class="toggle-wrapper"
      role="checkbox"
      :aria-checked="value.toString()"
      tabindex="0"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
      <span class="toggle-background" :class="backgroundStyles" />
      <span class="toggle-indicator" :style="indicatorStyles" />
    </span>
  </div>
</template>

<script>
import selectSound from "../assets/sounds/navigation_forward-selection-minimal.mp3";

export default {
  data() {
    return {
      selectSound: new Audio(selectSound),
    };
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    backgroundStyles() {
      return {
        "gold-mid": this.value,
        "gray-lighter": !this.value,
      };
    },
    indicatorStyles() {
      return { transform: this.value ? "translateX(14px)" : "translateX(0)" };
    },
  },
  methods: {
    toggle() {
      this.selectSound.play();
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style>
.gold-mid {
  background-color: #61b2e5;
}

.gray-lighter {
  background-color: #bcdbef;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  transition: transform 0.4s ease;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-user"},[_c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"intern-title ml-3"},[_c('back-arrow'),_c('span',{staticClass:"page-nav"},[_vm._v(_vm._s(_vm.$t("report"))+":")]),(_vm.item.usuario_nome)?_c('span',{staticClass:"page-nav"},[_vm._v(" "+_vm._s(_vm.item.usuario_nome))]):_vm._e()],1)]),_c('p',{staticClass:"subtitle ml-3"},[_vm._v(" "+_vm._s(_vm.$t("keepingSectors"))+": "+_vm._s(_vm.item.zeladoria_nome || _vm.$t("noKeepingSector"))+" ")]),_c('div',{staticClass:"activities-cards ml-3"},_vm._l((_vm.activities),function(item,id){return _c('div',{key:id},[_c('div',{staticClass:"activity-card pointer",on:{"click":function($event){return _vm.redirectToList(item)}}},[_c('div',{staticClass:"activiy-icon"},[_c('img',{staticClass:"img-border",attrs:{"src":item.atividade_icon,"height":"64","alt":""}})]),_c('div',{staticClass:"activity-counter"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.atividade_nome))]),_c('div',{staticClass:"card-counter"},[_vm._v(" "+_vm._s(item.atividade_quantidade !== null ? item.atividade_quantidade.toString() + "" : "0")+" ")])])])])}),0),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"map-container"},[_c('p',{staticClass:"map-title"},[_vm._v("Localização das Atividades")]),_c('GmapMap',{ref:"googleMap",staticStyle:{"height":"850px","width":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          mapTypeId: _vm.mapType,
          gestureHandling: 'greedy',
          streetViewControl: false,
          rotateControl: true,
          fullscreenControl: false,
          disableDefaultUi: true,
        }}},_vm._l((_vm.polygonSector),function(polygon){return _c('div',{key:'polygonsKeepingSector' + polygon.id},[_c('GmapPolygon',{ref:"polygon",refInFor:true,attrs:{"paths":polygon.latlngs,"options":polygon.options}}),(_vm.type === 'acao')?_c('div',_vm._l((_vm.eventPhotos),function(photo){return _c('GmapCustomMarker',{key:photo.usuario_acao_registro_id,attrs:{"draggable":false,"marker":photo.center}},[_c('span',{staticClass:"map-label"},[_vm._v(_vm._s(photo.photo_url))])])}),1):_vm._e()],1)}),0),_c('div',{staticClass:"mapTypeControllers"},[_c('CSelect',{attrs:{"placeholder":_vm.$t('type'),"options":_vm.mapTypes,"value":_vm.mapType},on:{"update:value":function($event){_vm.mapType=$event}}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
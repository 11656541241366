<template>
  <div class="w-100 h-100">
    <GmapMap
      @zoom_changed="zoomChanged"
      :key="rerender"
      ref="googleMap"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="height: 100%; width: 100%"
      :options="{
        zoomControl: zoomControl,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        styles: stylesMaps,
        gestureHandling: zoomControl ? 'greedy' : 'none',
        mapTypeId: mapType,
      }"
    >
      <div v-for="polygon of polygons" :key="'polygons' + polygon.id">
        <GmapPolygon
          @click="$emit('change-step', polygon)"
          ref="polygon"
          :paths="polygon.latlngs"
          :options="polygon.options"
        />
        <div v-if="verifyZoom">
          <GmapCustomMarker
            :draggable="false"
            class="mt-4 importance"
            :marker="polygon.center"
          >
            <div class="d-flex flex-column align-items-center">
              <span class="map-label">{{
                polygon.bairro_nome ||
                polygon.zeladoria_nome ||
                polygon.cidade_nome
              }}</span>
              <span
                v-if="
                  !polygon.canPartcipate && (stepMap === 1 || stepMap === 2)
                "
                class="material-icons mt-2"
                style="color: black; background: #fdc640; font-size: 1.4rem"
              >
                lock
              </span>
            </div>
          </GmapCustomMarker>
          <GmapCustomMarker
            :draggable="false"
            :marker="polygon.center"
            v-if="
              polygon.qtd_usuarios_zeladoria > 0 &&
              (stepMap === 1 || stepMap === 2)
            "
          >
            <div class="elevation">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_6848_204422)">
                  <rect
                    x="4"
                    width="32"
                    height="32"
                    rx="16"
                    :fill="
                      polygon.avaliated ? 'background-color: green' : 'white'
                    "
                    shape-rendering="crispEdges"
                  />
                  <path
                    d="M24.2367 19.176C25.0203 18.3479 25.5442 17.3084 25.7435 16.1859C25.9428 15.0634 25.8088 13.9071 25.3581 12.8599C24.9074 11.8128 24.1597 10.9206 23.2074 10.2937C22.2551 9.6669 21.1401 9.33283 20 9.33283C18.8599 9.33283 17.7449 9.6669 16.7926 10.2937C15.8403 10.9206 15.0926 11.8128 14.6419 12.8599C14.1912 13.9071 14.0572 15.0634 14.2565 16.1859C14.4558 17.3084 14.9797 18.3479 15.7633 19.176C16.2131 18.4583 16.8378 17.8668 17.5789 17.4569C18.32 17.047 19.1531 16.8322 20 16.8326C20.8469 16.8322 21.68 17.047 22.4211 17.4569C23.1622 17.8668 23.7869 18.4583 24.2367 19.176ZM20 25.7726L14.6967 20.4693C13.6478 19.4204 12.9335 18.084 12.6441 16.6292C12.3547 15.1743 12.5033 13.6663 13.0709 12.2959C13.6386 10.9254 14.5999 9.75409 15.8332 8.92999C17.0666 8.10588 18.5166 7.66602 20 7.66602C21.4834 7.66602 22.9334 8.10588 24.1668 8.92999C25.4001 9.75409 26.3614 10.9254 26.9291 12.2959C27.4968 13.6663 27.6453 15.1743 27.3559 16.6292C27.0665 18.084 26.3522 19.4204 25.3033 20.4693L20 25.7726ZM20 15.9993C19.337 15.9993 18.7011 15.7359 18.2322 15.2671C17.7634 14.7982 17.5 14.1623 17.5 13.4993C17.5 12.8362 17.7634 12.2004 18.2322 11.7315C18.7011 11.2627 19.337 10.9993 20 10.9993C20.663 10.9993 21.2989 11.2627 21.7678 11.7315C22.2366 12.2004 22.5 12.8362 22.5 13.4993C22.5 14.1623 22.2366 14.7982 21.7678 15.2671C21.2989 15.7359 20.663 15.9993 20 15.9993Z"
                    :fill="
                      polygon.avaliated ? 'background-color: white' : '#003063'
                    "
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_6848_204422"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_6848_204422"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_6848_204422"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </GmapCustomMarker>
        </div>
      </div>

      <div v-if="stepMap === 0 || stepMap === 1">
        <GmapCluster
          :styles="clusterStyles"
          :enableRetinaIcons="false"
          :gridSize="10"
          :minimumClusterSize="2"
          :zoomOnClick="false"
          v-if="showMarkers"
          :clickable="true"
          :animation="4"
        >
          <div v-for="pin of pins" :key="pin.atividade_id + Math.random()">
            <GmapCustomMarker
              v-if="pin.atividade_nome === 'Educação Ambiental - Pesquisa'"
              :title="`${pin.atividade_nome} | ${pin.tag_nome}`"
              @click.native="clickPhotoPin(pin, index)"
              class="markerGoogle"
              :draggable="false"
              :marker="pin.center"
            >
              <div class="pinMarker2 colorSector2">
                <svg
                  class="pin-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.9286 14.7567H1.61069C1.42308 14.7567 1.24316 14.6822 1.1105 14.5496C0.977846 14.4169 0.90332 14.237 0.90332 14.0494V1.31674C0.90332 1.12914 0.977846 0.949216 1.1105 0.816558C1.24316 0.683901 1.42308 0.609375 1.61069 0.609375H12.9286C13.1162 0.609375 13.2961 0.683901 13.4288 0.816558C13.5614 0.949216 13.636 1.12914 13.636 1.31674V14.0494C13.636 14.237 13.5614 14.4169 13.4288 14.5496C13.2961 14.6822 13.1162 14.7567 12.9286 14.7567ZM4.44016 4.14622V5.56095H10.0991V4.14622H4.44016ZM4.44016 6.97569V8.39043H10.0991V6.97569H4.44016ZM4.44016 9.80516V11.2199H7.977V9.80516H4.44016Z"
                    fill="white"
                  />
                </svg>
              </div>
            </GmapCustomMarker>
            <GmapCustomMarker
              v-else
              :title="pin.atividade_nome"
              class="markerGoogle"
              :draggable="false"
              :marker="pin.center"
            >
              <div class="pinMarker">
                <span class="qtdAmas">{{ pin.quantidade }}</span>
              </div>
            </GmapCustomMarker>
          </div>
        </GmapCluster>
      </div>
      <div v-if="stepMap === 2">
        <div v-if="showMarkers">
          <div
            v-for="(pin, index) of pins"
            :key="pin.atividade_id + Math.random()"
          >
            <!--Colocar modal aqui-->
            <GmapCustomMarker
              v-if="pin.atividade_nome === 'Educação Ambiental - Pesquisa'"
              :title="`${pin.atividade_nome} | ${pin.tag_nome}`"
              class="markerGoogle"
              :draggable="false"
              :marker="pin.center"
            >
              <div class="pinMarker2 colorSector2">
                <svg
                  class="pin-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.9286 14.7567H1.61069C1.42308 14.7567 1.24316 14.6822 1.1105 14.5496C0.977846 14.4169 0.90332 14.237 0.90332 14.0494V1.31674C0.90332 1.12914 0.977846 0.949216 1.1105 0.816558C1.24316 0.683901 1.42308 0.609375 1.61069 0.609375H12.9286C13.1162 0.609375 13.2961 0.683901 13.4288 0.816558C13.5614 0.949216 13.636 1.12914 13.636 1.31674V14.0494C13.636 14.237 13.5614 14.4169 13.4288 14.5496C13.2961 14.6822 13.1162 14.7567 12.9286 14.7567ZM4.44016 4.14622V5.56095H10.0991V4.14622H4.44016ZM4.44016 6.97569V8.39043H10.0991V6.97569H4.44016ZM4.44016 9.80516V11.2199H7.977V9.80516H4.44016Z"
                    fill="white"
                  />
                </svg>
              </div>
            </GmapCustomMarker>

            <GmapCustomMarker
              v-else
              :title="`${pin.atividade_nome} | ${pin.tag_nome}`"
              @click.native="clickPhotoPin(pin, index)"
              class="markerGoogle"
              :draggable="false"
              :marker="pin.center"
            >
              <div class="pinMarker colorSector">
                <span class="qtdAmas">{{ pin.quantidade }}</span>
              </div>
            </GmapCustomMarker>
          </div>
        </div>
      </div>
      <div v-if="stepMap === undefined">
        <div v-if="showMarkers">
          <GmapCustomMarker
            :title="`${pin.atividade_nome} | ${pin.tag_nome}`"
            v-for="(pin, index) of pins"
            :key="pin.atividade_id + Math.random()"
            @click.native="clickPhotoPin(pin, index)"
            class="markerGoogle"
            :draggable="false"
            :marker="pin.center"
          >
            <div class="pinMarker colorSector">
              <span class="qtdAmas">{{ pin.quantidade }}</span>
            </div>
          </GmapCustomMarker>
        </div>
      </div>
    </GmapMap>
    <div v-if="changeMapType" class="mapTypeControllers">
      <CSelect placeholder="Tipo" :options="mapTypes" :value.sync="mapType">
      </CSelect>
    </div>
    <div class="zoomControllers">
      <div v-if="stepMap === 2">
        <button v-if="showHeatMap" @click="syncHeatmap" title="Heatmap">
          <img src="../../assets/img/heatmap.svg" alt />
        </button>
        <button v-if="!showHeatMap" @click="syncHeatmap" title="Heatmap">
          <img src="../../assets/img/no-heatmap.svg" alt />
        </button>
      </div>

      <div v-if="stepMap === 2">
        <button v-if="showCircles" @click="syncCircles" title="circles">
          <img src="../../assets/img/circle.svg" alt />
        </button>
        <button v-if="!showCircles" @click="syncCircles" title="circles">
          <img src="../../assets/img/no-circle.svg" alt />
        </button>
      </div>

      <div v-if="stepMap === 2">
        <button v-if="showBlocks" @click="syncBlocks" title="Blocks">
          <img src="../../assets/img/block.svg" alt />
        </button>
        <button v-if="!showBlocks" @click="syncBlocks" title="Blocks">
          <img src="../../assets/img/no-block.svg" alt />
        </button>
      </div>

      <div v-if="stepMap !== undefined">
        <button
          v-if="!showMarkers"
          @click="showMarkers = !showMarkers"
          title="Pins"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.6382 10.6378C17.4192 11.7389 16.9562 12.7713 16.2931 13.6659C13.8275 11.2004 11.6516 9.02642 9.74647 7.12305C8.26402 5.64195 6.94555 4.32468 5.78219 3.16131C5.88854 3.07998 5.9949 3.00491 6.10126 2.92983L6.10127 2.92982C7.34005 2.10397 8.78529 1.66602 10.2743 1.66602C11.7634 1.66602 13.2149 2.10397 14.4474 2.92982C15.6799 3.75568 16.6434 4.93189 17.2128 6.30206C17.7821 7.67223 17.926 9.18004 17.6382 10.6378ZM18.5298 18.2676L18.5329 18.2707V18.2644L18.5298 18.2676ZM15.167 14.9047L18.5298 18.2676L17.3505 19.4469L13.9845 16.0809L10.2744 19.791L4.96265 14.4793C3.91781 13.4344 3.19832 12.0956 2.91052 10.6378C2.61647 9.1863 2.76662 7.67223 3.33596 6.30207C3.4173 6.10186 3.51114 5.90791 3.61125 5.71396L0.833374 2.93608L2.01585 1.75361L4.56849 4.30625L5.75096 5.48872L13.9845 13.7222L15.167 14.9047Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          v-if="showMarkers"
          @click="showMarkers = !showMarkers"
          title="Pins"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.364 15.3639L9 21.7279L2.636 15.3639C1.37734 14.1052 0.520187 12.5016 0.172928 10.7558C-0.17433 9.00995 0.00390685 7.20035 0.685099 5.55582C1.36629 3.91129 2.51984 2.50569 3.99988 1.51677C5.47992 0.527838 7.21998 0 9 0C10.78 0 12.5201 0.527838 14.0001 1.51677C15.4802 2.50569 16.6337 3.91129 17.3149 5.55582C17.9961 7.20035 18.1743 9.00995 17.8271 10.7558C17.4798 12.5016 16.6227 14.1052 15.364 15.3639ZM9 10.9999C9.53044 10.9999 10.0391 10.7892 10.4142 10.4141C10.7893 10.0391 11 9.53035 11 8.99992C11 8.46949 10.7893 7.96078 10.4142 7.58571C10.0391 7.21064 9.53044 6.99992 9 6.99992C8.46957 6.99992 7.96086 7.21064 7.58579 7.58571C7.21072 7.96078 7 8.46949 7 8.99992C7 9.53035 7.21072 10.0391 7.58579 10.4141C7.96086 10.7892 8.46957 10.9999 9 10.9999Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import styleClean from "../../assets/json/theme-light.json";
import styleMalheiros from "../../assets/json/theme-malheiros.json";
import styleActive from "../../assets/json/theme-active.json";
import styleDefault from "../../assets/json/theme-default.json";
import GmapCluster from "vue2-google-maps/src/components/cluster";
import { formatForGoogleMaps } from "../../utils/geoMaps";
import services from "../../services";

Vue.component("GmapCluster", GmapCluster);
export default {
  props: {
    polygons: {
      type: Array,
      required: true,
    },
    pins: {
      type: Array,
      required: true,
    },
    tracking: {
      type: Array,
      default: function () {
        return [];
      },
    },
    bounds: {
      type: Array,
      required: true,
    },
    stepMap: {
      type: Number,
      required: false,
    },
    keepingSectorId: {
      default: null,
      required: false,
    },
    mapView: {
      type: String,
      default: null,
    },
    dates: {
      required: true,
    },
    zoomControl: {
      type: Boolean,
      default: true,
    },
    changeMapType: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 4,
    },
    center: {
      type: Object,
      default: function () {
        return {
          lat: -15.288423,
          lng: -49.851561,
        };
      },
    },
  },
  components: {
    GmapCustomMarker,
  },
  data() {
    return {
      stylesMaps: styleDefault,
      dataheatmap: {},
      dataBlocks: [],
      keepingSectorBlocks: [],
      polygonBlocks: {},
      blocksBounds: [],
      cityCircle: null,
      rerender: Math.random(),
      actualBounds: [],
      circlesInfo: [],
      actualZoom: 4,
      showMarkers: true,
      showHeatMap: true,
      showCircles: false,
      showBlocks: false,
      heatPoints: [],
      clusterStyles: [
        {
          textColor: "white",
          url: require("../../assets/img/map-cluster.svg"),
          height: 60,
          width: 60,
          textSize: 20,
        },
      ],
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: this.mapView || "satellite",
    };
  },
  computed: {
    verifyZoom() {
      return this.actualZoom >= 14;
    },
  },
  watch: {
    dates: {
      handler: function () {
        if (this.stepMap === 2) {
          this.cleanHeatmap();
          this.fitBoundsGoogle();
        }
      },
    },
    pins: {
      handler: function (newVal) {
        /*  if (this.stepMap === 2) {
          this.showMarkers = false;
        } */
        if (newVal.length > 0 && this.stepMap !== 2) {
          this.verifyPinsPosition();
        }
      },
      deep: true,
    },
  },
  methods: {
    verifyPinsPosition() {
      for (let i = 0; i < this.pins.length; i++) {
        for (let j = i + 1; j < this.pins.length; j++) {
          if (
            this.pins[i].center.lat === this.pins[j].center.lat &&
            this.pins[i].center.lng === this.pins[j].center.lng
          ) {
            this.pins[i].center.lng = this.pins[i].center.lng + 0.00002;
          }
        }
      }
    },
    zoomChanged(zoom) {
      this.actualZoom = zoom;
    },
    clickPhotoPin(pin, index) {
      this.$emit("click-pin", {
        ...pin,
        index,
      });
    },
    fitBoundsGoogle() {
      setTimeout(() => {
        this.$refs.googleMap.$mapPromise.then(() => {
          const b = new window.google.maps.LatLngBounds();
          for (let i of this.bounds) {
            b.extend({
              lat: i.lat,
              lng: i.lng,
            });
          }
          this.$refs.googleMap.fitBounds(b);
        });
      }, 750);
    },
    async requestKeepingSectorBlocks() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorBlocks(
          this.keepingSectorId
        );
      if (!errors) {
        this.mountBlocks(data);
      }
    },
    syncHeatmap() {
      this.showHeatMap = !this.showHeatMap;
      this.showHeatMap ? this.generateHeatmap(null) : this.cleanHeatmap();
    },
    syncCircles() {
      this.showCircles = !this.showCircles;
      this.showCircles ? this.generateCircles(null) : this.cleanCircles();
    },
    async syncBlocks() {
      this.showBlocks = !this.showBlocks;
      this.showBlocks
        ? await this.requestKeepingSectorBlocks()
        : this.cleanBlocks();
    },
    cleanCircles() {
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.actualBounds = map.getBounds();
      });
      this.cityCircle.setMap(null);
      this.rerender = Math.random();
      setTimeout(() => {
        this.$refs.googleMap.fitBounds(this.actualBounds, 0);
        if (this.showHeatMap) this.generateHeatmap(null);
      }, 0);
    },
    cleanHeatmap() {
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.actualBounds = map.getBounds();
      });
      this.dataheatmap.setMap(null);
      this.rerender = Math.random();
      setTimeout(() => {
        this.$refs.googleMap.fitBounds(this.actualBounds, 0);
        if (this.showCircles) this.generateCircles(null);
      }, 0);
    },
    cleanBlocks() {
      this.dataBlocks = [];
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.actualBounds = map.getBounds();
      });
      this.rerender = Math.random();
      setTimeout(() => {
        this.$refs.googleMap.fitBounds(this.actualBounds, 0);
      });
      this.keepingSectorBlocks.setMap(null);
    },
    mountBlocks(polygons) {
      if (!polygons) {
        polygons = this.dataBlocks;
      }
      this.dataBlocks = polygons;
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.dataBlocks.forEach((polygon) => {
          this.keepingSectorBlocks = new window.google.maps.Polygon({
            path: formatForGoogleMaps(polygon.quadra_limite[0]),
            strokeColor: "#548aba",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#d4edf7",
            fillOpacity: 0.6,
            map,
            zIndex: 9999999,
          });
        });
        this.keepingSectorBlocks.setMap(map);
      });
    },
    generateCircles(points) {
      if (points === null) {
        points = this.circlesInfo;
      }
      this.circlesInfo = points;
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.circlesInfo.forEach((circle) => {
          this.cityCircle = new window.google.maps.Circle({
            strokeColor: circle.color,
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: circle.color,
            fillOpacity: 0.35,
            map,
            center: circle.center,
            radius: circle.radius,
            zIndex: 9999999,
          });
        });
      });
    },
    generateHeatmap(points) {
      if (points === null) {
        points = this.heatPoints;
      }
      this.heatPoints = points;
      this.$refs.googleMap.$mapPromise.then((map) => {
        this.dataheatmap = new window.google.maps.visualization.HeatmapLayer({
          data: points,
          map: map,
          maxIntensity: 350,
          radius: 10,
          zIndex: 9999999,
        });
      });
    },
    moreZoom() {
      this.$refs.googleMap.$mapObject.setZoom(
        this.$refs.googleMap.$mapObject.getZoom() + 1
      );
    },
    lessZoom() {
      this.$refs.googleMap.$mapObject.setZoom(
        this.$refs.googleMap.$mapObject.getZoom() - 1
      );
    },
    changeTheme(type) {
      if (type === "light") {
        this.stylesMaps = styleClean;
      } else if (type === "dark") {
        this.stylesMaps = styleMalheiros;
      } else if (type === "active") {
        this.stylesMaps = styleActive;
      } else if (type === "default") {
        this.stylesMaps = styleDefault;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.importance {
  z-index: 0 !important;
}

.markerGoogle {
  &:hover {
    cursor: pointer;
  }

  .pinMarker {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 0 50%;
    background: white;
    border: 1px solid white;
    transform: rotate(45deg) !important;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2);
  }

  .pinMarker2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50% 50% 0 50%;
    background: #131e3b;
    border: 1px solid #131e3b;
    transform: rotate(45deg) !important;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2);
  }

  .colorSector {
    background: white;
    border: 1px solid white;
  }

  .colorSector2 {
    background: #131e3b;
    border: 1px solid #131e3b;
  }

  span.qtdAmas {
    position: absolute;
    color: #131e3b;
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    top: 9px;
    font-size: 1rem;
    font-weight: bold;
    transform: rotate(-45deg) !important;
  }
}

.zoomControllers {
  position: absolute;
  top: 22px;
  right: 27px;
  z-index: 500;

  button {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: #003063;
    border-radius: 50%;
    padding: 7px;
    display: block;
    clear: both;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.mapTypeControllers {
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 500;
  width: 120px;
}

.cluster {
  z-index: 99;
  transform: translateY(-15px);
}
.pin-icon {
  transform: rotate(-45deg) !important;
  position: absolute;
  text-align: center;
  left: 10;
  right: 50;
  top: 10px;
  bottom: 5px;
}
</style>

<template>
  <div class="users-managers">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">Fiscalizações em campo</span>
    </h3>

    <transition name="bounce">
      <div v-if="!loadingData">
        <div class="d-flex justify-content-start align-items-center ml-3">
          <div class="w-25">
            <Input
              v-model="searchString"
              placeholder="Busque por fiscal, bairro ou AZ"
              :search="true"
            />
          </div>
          <div class="position-relative">
            <svg
              @click="filterStatus = !filterStatus"
              class="ml-4 pointer filter-icon"
              id="filter-btn"
              width="28"
              height="28"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="20" height="20" rx="4" fill="#003063" />
              <path
                d="M14.5 6.00006V7.00006H14L11 11.5001V15.0001H9V11.5001L6 7.00006H5.5V6.00006H14.5Z"
                fill="white"
              />
            </svg>
            <Transition name="filter">
              <div v-if="filterStatus" class="status-filter" id="status-filter">
                <div class="d-flex justify-content-between">
                  <p class="status-title">Filtrar por status</p>
                  <svg
                    @click="filterStatus = false"
                    class="pointer status-close"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.99681 5.58574L11.9468 0.635742L13.3608 2.04974L8.41081 6.99974L13.3608 11.9497L11.9468 13.3637L6.99681 8.41374L2.04681 13.3637L0.632812 11.9497L5.58281 6.99974L0.632812 2.04974L2.04681 0.635742L6.99681 5.58574Z"
                      fill="#B0B4BD"
                    />
                  </svg>
                </div>
                <div class="status-modal__body">
                  <div
                    v-for="item of status"
                    :key="'status-modal-' + item.fiscal_registro_status_id"
                    class="mb-2"
                  >
                    <input
                      type="radio"
                      :id="'status' + item.fiscal_registro_status_id"
                      :value="item.fiscal_registro_status_id"
                      v-model="filteredStatus"
                      :name="item.fiscal_registro_status_id"
                    />
                    <label :for="item.fiscal_registro_status_id">{{
                      item.fiscal_registro_status_descricao
                    }}</label>
                  </div>
                  <input
                    type="radio"
                    id="status-all"
                    value="all"
                    v-model="filteredStatus"
                    name="Todos"
                  />
                  <label for="status-all">Todos</label>
                </div>
                <div class="mt-3">
                  <Button
                    @clicked="filterStatus = false"
                    class="mt-2 w-100"
                    name="Fechar"
                    type="white"
                    size="small"
                  />
                </div>
              </div>
            </Transition>
          </div>
        </div>

        <Table style="overflow: visible" :fields="fields" :hasActions="true">
          <TableRow v-for="item in supervisors" :key="item.usuario_gestor_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
              <template v-slot:fiscal_registro_status_descricao>
                <span
                  class="table-row-text tag"
                  :class="'tag-id' + '-' + item.fiscal_registro_status_id"
                >
                  {{ item.fiscal_registro_status_descricao }}
                </span>
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end align-items-center">
              <div v-if="userRole === 1 || userRole === 3" id="table-cell">
                <CondensedDropdown
                  @dropdown-click="
                    (ev) => handleCondensedDropdownClick(ev, item)
                  "
                  :options="options"
                  class="dropdown"
                />

                <Transition name="filter">
                  <div class="status-modal" v-if="item.statusModal">
                    <div class="status-modal__header">
                      <div>Mudar Status</div>
                      <svg
                        @click="item.statusModal = false"
                        class="pointer"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.99999 5.58599L11.95 0.635986L13.364 2.04999L8.41399 6.99999L13.364 11.95L11.95 13.364L6.99999 8.41399L2.04999 13.364L0.635986 11.95L5.58599 6.99999L0.635986 2.04999L2.04999 0.635986L6.99999 5.58599Z"
                          fill="#131E3B"
                        />
                      </svg>
                    </div>

                    <div class="status-modal__body">
                      <div
                        v-for="item of status"
                        :key="'status' + item.fiscal_registro_status_id"
                        class="mb-2"
                      >
                        <input
                          type="radio"
                          :id="item.fiscal_registro_status_id"
                          :value="item.fiscal_registro_status_id"
                          v-model="statusSelected"
                          :name="item.fiscal_registro_status_id"
                        />
                        <label :for="item.fiscal_registro_status_id">{{
                          item.fiscal_registro_status_descricao
                        }}</label>
                      </div>
                    </div>

                    <div class="status-modal__footer">
                      <Button
                        @clicked="handleChangeStatus(item)"
                        class="w-100"
                        :disabled="
                          statusSelected === item.fiscal_registro_status_id
                        "
                        name="Confirmar"
                        size="small"
                      />

                      <Button
                        @clicked="item.statusModal = false"
                        class="mt-2 w-100"
                        name="Cancelar"
                        type="white"
                        size="small"
                      />
                    </div>
                  </div>
                </Transition>
              </div>

              <div v-else>
                <svg
                  @click="handleDetailsItem(item)"
                  class="pointer"
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0.721924C16.392 0.721924 20.878 4.60192 21.819 9.72192C20.879 14.8419 16.392 18.7219 11 18.7219C5.60803 18.7219 1.12203 14.8419 0.18103 9.72192C1.12103 4.60192 5.60803 0.721924 11 0.721924ZM11 16.7219C13.0395 16.7215 15.0184 16.0287 16.6129 14.7571C18.2074 13.4854 19.3229 11.7102 19.777 9.72192C19.3213 7.73523 18.205 5.96193 16.6107 4.69195C15.0163 3.42197 13.0383 2.73045 11 2.73045C8.96173 2.73045 6.98372 3.42197 5.38941 4.69195C3.79509 5.96193 2.6788 7.73523 2.22303 9.72192C2.67713 11.7102 3.7927 13.4854 5.38717 14.7571C6.98164 16.0287 8.96056 16.7215 11 16.7219ZM11 14.2219C9.80656 14.2219 8.66196 13.7478 7.81805 12.9039C6.97414 12.06 6.50003 10.9154 6.50003 9.72192C6.50003 8.52845 6.97414 7.38386 7.81805 6.53994C8.66196 5.69603 9.80656 5.22192 11 5.22192C12.1935 5.22192 13.3381 5.69603 14.182 6.53994C15.0259 7.38386 15.5 8.52845 15.5 9.72192C15.5 10.9154 15.0259 12.06 14.182 12.9039C13.3381 13.7478 12.1935 14.2219 11 14.2219ZM11 12.2219C11.6631 12.2219 12.299 11.9585 12.7678 11.4897C13.2366 11.0209 13.5 10.385 13.5 9.72192C13.5 9.05888 13.2366 8.423 12.7678 7.95416C12.299 7.48532 11.6631 7.22192 11 7.22192C10.337 7.22192 9.7011 7.48532 9.23226 7.95416C8.76342 8.423 8.50003 9.05888 8.50003 9.72192C8.50003 10.385 8.76342 11.0209 9.23226 11.4897C9.7011 11.9585 10.337 12.2219 11 12.2219Z"
                    fill="#838998"
                  />
                </svg>
              </div>
            </TableCell>
          </TableRow>
          <template v-if="allSupervisors.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
      <Loading v-else />
    </transition>

    <Modal v-show="confirmModal">
      <template v-slot:header>
        <div class="confirm-modal-title">
          Confirmação de Alteração <br />
          de status
        </div>
      </template>
      <template v-slot:body>
        <p class="confirm-modal-description">
          Tem certeza que deseja alterar o status de
          <span>{{
            supervisorToChange ? supervisorToChange.usuario_nome : ""
          }}</span>
          de
          <span>{{
            supervisorToChange
              ? supervisorToChange.fiscal_registro_status_descricao
              : ""
          }}</span>
          para
          <span>{{ newStatusLabel }}</span
          >?
        </p>
      </template>
      <template v-slot:footer>
        <Button
          @clicked="requestChangeStatus"
          class="w-100"
          name="Confirmar"
          size="small"
        />

        <Button
          @clicked="
            confirmModal = false;
            statusSelected = null;
            supervisorToChange = null;
          "
          class="mt-2 w-100"
          name="Cancelar"
          type="white"
          size="small"
        />
      </template>
    </Modal>
    <FinalModal
      :open="finalModal"
      :body="`O status de ${
        supervisorToChange ? supervisorToChange.usuario_nome : ''
      } foi alterado`"
      @close="
        finalModal = false;
        supervisorToChange = null;
      "
    />
  </div>
</template>

<script>
import services from "../../services";
import { debounce } from "../../utils/debounce";
import { decrypt } from "../../utils/decrypt";

import NoResults from "../../components/table/NoResults.vue";
import Input from "../../components/form/Input.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import Button from "../../components/Button.vue";
import BackArrow from "../../components/BackArrow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Modal from "../../components/Modal.vue";
import FinalModal from "./modal/FinalModal.vue";

export default {
  name: "SupervisorsReport",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Loading,
    Button,
    BackArrow,
    Input,
    Modal,
    CondensedDropdown,
    FinalModal,
  },
  data() {
    return {
      fields: [
        {
          key: "fiscal_registro_id",
          label: "ID",
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_nome",
          label: "FISCAL",
          _style: "min-width:60px;",
          sortable: false,
          sortDirection: "asc",
        },
        {
          key: "date",
          label: "DATA DE EXECUÇÃO",
          _style: "min-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "bairro_nome",
          label: "BAIRRO",
          _style: "min-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladoria_nome",
          label: "ZELADORIA",
          _style: "min-width:120px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "fiscal_registro_status_descricao",
          label: "STATUS",
          _style: "min-width:120px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      options: [
        {
          label: "Visualizar",
          value: "eye",
        },
        {
          label: "Mudar status",
          value: "loop",
        },
      ],
      allSupervisors: [],
      loadingData: true,
      searchString: null,
      userRole: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: "asc",
      },
      status: [],
      statusSelected: null,
      supervisorToChange: null,
      confirmModal: false,
      finalModal: false,
      filterStatus: false,
      filteredStatus: null,
    };
  },
  mounted: async function () {
    const encrypted = localStorage.getItem("ama");
    const decrypted = decrypt(encrypted);
    const user = JSON.parse(decrypted);
    this.userRole = user.usuario_gestor_tipo_id;
    await this.getSupervisors();
    await this.getSupervisorsStatus();
    this.loadingData = false;

    document.addEventListener("click", (e) => {
      if (
        !e.target.closest("#status-filter") &&
        !e.target.closest("#filter-btn")
      ) {
        this.filterStatus = false;
      }
    });
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      if (newVal) {
        this.pagination.filter = newVal;
      } else {
        this.pagination.filter = null;
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset();
        }
      }
      await this.getSupervisors(this.pagination);
    }, 500),
  },
  computed: {
    newStatusLabel() {
      const status = this.status.find(
        (item) => item.fiscal_registro_status_id === this.statusSelected
      );
      return status ? status.fiscal_registro_status_descricao : "";
    },
    supervisors() {
      return this.allSupervisors.filter((item) => {
        if (this.filteredStatus && this.filteredStatus !== "all") {
          return item.fiscal_registro_status_id === this.filteredStatus;
        } else {
          return item;
        }
      });
    },
  },
  methods: {
    handleDetailsItem(item) {
      this.$router.push(`/fiscalizacoes/${item.fiscal_registro_uuid}`);
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "eye") {
        this.handleDetailsItem(item);
      }
      if (value === "loop") {
        this.allSupervisors.forEach((item) => {
          item.statusModal = false;
        });
        this.statusSelected = item.fiscal_registro_status_id;
        item.statusModal = true;
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } =
        await services.UsersSupervisors.requestSupervisors(this.pagination);
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item) => {
            return {
              ...item,
              date: this.formatDate(item.fiscal_registro_data),
              statusModal: false,
            };
          });
          temp.forEach((item) => {
            this.allSupervisors.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    formatDate(date) {
      const dateArray = date.split("T")[0].split("-");
      return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    },
    async getSupervisorsStatus() {
      const { data, errors } =
        await services.UsersSupervisors.getSupervisorsStatus();
      if (!errors) {
        this.status = data;
      }
    },
    async requestChangeStatus() {
      const { errors } = await services.UsersSupervisors.changeSupervisorStatus(
        {
          fiscal_registro_uuid: this.supervisorToChange.fiscal_registro_uuid,
          fiscal_registro_status_id: this.statusSelected,
        }
      );
      if (!errors) {
        this.confirmModal = false;

        this.pagination = {
          page: 0,
          sortBy: null,
          filter: null,
          order: "asc",
        };

        this.loadingData = true;
        await this.getSupervisors();
        this.statusSelected = null;
        this.finalModal = true;
        this.loadingData = false;
      }
    },
    handleChangeStatus(item) {
      this.supervisorToChange = item;
      this.allSupervisors.forEach((item) => {
        item.statusModal = false;
      });
      this.confirmModal = true;
    },
    async getSupervisors() {
      const { data, errors } =
        await services.UsersSupervisors.requestSupervisors(this.pagination);
      if (!errors) {
        this.allSupervisors = data.map((item) => {
          return {
            ...item,
            date: this.formatDate(item.fiscal_registro_data),
            bairro_nome: item.bairro_nome ? item.bairro_nome : "--",
            zeladoria_nome: item.zeladoria_nome ? item.zeladoria_nome : "--",
            statusModal: false,
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-managers {
  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
}

.tag {
  border-radius: 6px;
  padding: 4px 8px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  text-transform: uppercase;
}

.tag-id-1 {
  background: #ffb800;
  color: #043464;
}

.tag-id-2 {
  background: #8acc0d;
  color: #fff;
}

.tag-id-3 {
  background: #ff002e;
  color: #fff;
}

.tag-id-4 {
  background: #dddee3;
  color: #131e3b;
}

.status-modal {
  position: absolute;
  right: 12px;
  width: 216px;
  z-index: 1000;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 24px;

  .status-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #131e3b;
  }

  input {
    margin-right: 8px;
    accent-color: #003366;
    margin-top: 4px;
  }

  .status-modal__body {
    margin-top: 24px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: #565e73;
  }

  .status-modal__footer {
    margin-top: 12px;
  }
}

.filter-icon {
  border-radius: 50%;
}

.confirm-modal-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.017em;
  color: #131e3b;
  text-align: center;
}

.confirm-modal-description {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #565e73;
  text-align: center;

  span {
    color: #003366;
  }
}

.status-filter {
  position: absolute;
  z-index: 10;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(160, 168, 189, 0.24);
  background-color: #fff;
  border-radius: 8px !important;
  width: 240px;
  top: 12px;
  right: 12px;

  .status-title {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #131e3b;
  }

  .status-close {
    margin-top: 5px !important;
    margin-right: 2px !important;
  }

  .status-modal__body {
    margin-top: 8px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: #565e73;
  }

  input {
    margin-right: 8px;
    accent-color: #003366;
    margin-top: 4px;
  }
}
</style>

<template>
  <div>
    <div class="row mt-5">
      <div class="monthly-users col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <h6>{{ $t("usersByPeriod") }}</h6>
        <div class="graph-card">
          <UsersPerPeriodChart
            v-if="!loading"
            :startDate="startDate"
            :endDate="endDate"
            :graphData="usersCount"
          />
          <Loading v-else />
        </div>
      </div>
      <div class="cumulative-users col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <h6>{{ $t("cumulative") }}</h6>
        <div class="graph-card">
          <CumulativeChart v-if="!loading" :graphData="usersCount" />
          <Loading v-else />
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div class="d-flex align-items-baseline justify-content-between">
        <h3 class="page-nav mr-3">{{ setUsersTableTitle(tab) }}</h3>
        <div>
          <Button
            :disabled="users.length === 0"
            @clicked="getDataForCSV"
            size="small"
            style="padding: 5px"
            :name="$t('downloadSpreadsheet')"
          />
        </div>
      </div>
      <div class="graph-card mt-3">
        <div class="w-50">
          <Input
            v-model="searchString"
            :placeholder="$t('searchAnUserManager')"
            :search="true"
          />
        </div>
        <div class="card-body" style="overflow: auto">
          <Table v-if="!loading" :fields="definitiveFields">
            <TableRow v-for="(item, index) in users" :key="index">
              <TableCell
                v-for="field in definitiveFields"
                :key="field.key"
                :name="field.key"
                :style="field._style"
                :sort-direction="field.sortDirection"
              >
                <template v-slot:[field.key]>
                  {{ item[field.key] || "--" }}
                </template>
              </TableCell>
            </TableRow>
            <template v-if="users.length === 0" v-slot:no-results>
              <NoResults :message="$t('noResults')" />
            </template>
          </Table>
          <Loading v-else />
        </div>
      </div>
      <infinite-loading
        ref="infinite"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
      >
        <div slot="spinner">
          <Loading />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import { debounce } from "../../utils/debounce";
import services from "../../services";
import UsersPerPeriodChart from "../../components/graphs/UsersPerPeriodChart.vue";
import CumulativeChart from "../../components/graphs/CumulativeChart.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Button from "../../components/Button.vue";
import Input from "../../components/form/Input.vue";
import { formatDate } from "@fullcalendar/common";

export default {
  components: {
    UsersPerPeriodChart,
    CumulativeChart,
    Table,
    TableRow,
    TableCell,
    NoResults,
    Loading,
    Input,
    Button,
  },
  props: {
    tab: {
      type: Number,
      default: 1,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    usersCount: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tab: {
      async handler(oldVal, newVal) {
        this.pagination.page = 0;
        this.searchString = null;
        oldVal = newVal;
        if (this.$refs.infinite) this.$refs.infinite.stateChanger.reset();
        await this.loadDataAndCheckColumns();
      },
    },
    startDate: {
      async handler() {
        this.users = [];
        this.pagination.page = 0;
        if (this.$refs.infinite) this.$refs.infinite.stateChanger.reset();
        await this.loadDataAndCheckColumns();
      },
    },
    endDate: {
      async handler() {
        this.users = [];
        this.pagination.page = 0;
        if (this.$refs.infinite) this.$refs.infinite.stateChanger.reset();
        await this.loadDataAndCheckColumns();
      },
    },
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.loadUsers();
    }, 1000),
  },
  async mounted() {
    this.infiniteId = 0;
    await this.loadDataAndCheckColumns();
  },
  methods: {
    async loadUsers() {
      //identificador para resetar com component infinite-loader quando houver mudança no filtro ou na tab
      this.infiniteId++;
      this.pagination.page = 0;
      if (this.tab === 1) {
        const { data, errors } = await services.statistics.getLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          this.users = data;
        }
      }
      if (this.tab === 2) {
        const { data, errors } = await services.statistics.getAmaLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          this.users = data;
        }
      }
      if (this.tab === 3) {
        const { data, errors } = await services.statistics.getAmaLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          this.users = data.filter((item) => item.lead_lp === 1);
        }
      }
      if (this.tab === 4) {
        const { data, errors } = await services.statistics.getAmaLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          this.users = data.filter((item) => item.fitbank === 1);
        }
      }
      if (this.tab === 5) {
        const { data, errors } = await services.statistics.getUsers({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          this.users = data;
        }
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      let newUsers = [];

      if (this.tab === 1) {
        const { data, errors } = await services.statistics.getLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          newUsers = data;
        }
      }
      if (this.tab === 2) {
        const { data, errors } = await services.statistics.getAmaLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          newUsers = data;
        }
      }
      if (this.tab === 3) {
        const { data, errors } = await services.statistics.getAmaLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          newUsers = data.filter((item) => item.lead_lp === 1);
        }
      }
      if (this.tab === 4) {
        const { data, errors } = await services.statistics.getAmaLeads({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          newUsers = data.filter((item) => item.fitbank === 1);
        }
      }
      if (this.tab === 5) {
        const { data, errors } = await services.statistics.getUsers({
          data_inicio: this.startDate,
          data_fim: this.endDate,
          filtro: this.pagination.filter,
          pagina: this.pagination.page,
        });
        if (!errors) {
          newUsers = data;
        }
      }
      if (newUsers.length > 0) {
        newUsers.forEach((item) => {
          this.users.push(item);
        });
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    async loadDataAndCheckColumns() {
      await this.loadUsers();
      if (this.tab === 1) {
        this.definitiveFields = this.fields.filter((field) => {
          return (
            field.key !== "usuario_bairro" && field.key !== "zeladoria_nome"
          );
        });
      }
      if (this.tab === 2) {
        this.definitiveFields = this.fields.filter((field) => {
          return field.key !== "usuario_cep" && field.key !== "zeladoria_nome";
        });
      }
      if (this.tab === 3) {
        this.definitiveFields = this.fields.filter((field) => {
          return field.key !== "usuario_cep" && field.key !== "zeladoria_nome";
        });
      }
      if (this.tab === 4) {
        this.definitiveFields = this.fields.filter((field) => {
          return field.key !== "usuario_cep" && field.key !== "zeladoria_nome";
        });
      }
      if (this.tab === 5) {
        this.definitiveFields = this.fields.filter((field) => {
          return field.key !== "usuario_cep";
        });
      }
    },
    setUsersTableTitle() {
      if (this.tab === 1) {
        return this.$t("registerLp");
      }
      if (this.tab === 2) {
        return this.$t("registerAmas");
      }
      if (this.tab === 3) {
        return this.$t("amasFromLp");
      }
      if (this.tab === 4) {
        return this.$t("amasWithAccount");
      }
      if (this.tab === 5) {
        return this.$t("usersVinculateWithKS");
      }
    },
    async getDataForCSV() {
      if (this.tab === 1) {
        const { data, errors } = await services.statistics.getLeadsFull({
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });

        if (!errors) {
          this.exportDataToCSV("leads", data);
        }
      }
      if (this.tab === 2) {
        const { data, errors } = await services.statistics.getAmaLeadsFull({
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });

        if (!errors) {
          this.exportDataToCSV("ama", data);
        }
      }
      if (this.tab === 3) {
        const { data, errors } = await services.statistics.getAmaLeadsFull({
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });

        if (!errors) {
          this.exportDataToCSV(
            "amaLp",
            data.filter((item) => item.lead_lp === 1)
          );
        }
      }
      if (this.tab === 4) {
        const { data, errors } = await services.statistics.getAmaLeadsFull({
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });

        if (!errors) {
          this.exportDataToCSV(
            "amaFitbank",
            data.filter((item) => item.fitbank === 1)
          );
        }
      }
      if (this.tab === 5) {
        const { data, errors } = await services.statistics.getUsersFull({
          data_inicio: this.startDate,
          data_fim: this.endDate,
        });

        if (!errors) {
          this.exportDataToCSV("zeladores", data);
        }
      }
    },
    downloadCSV(fileName, data) {
      const blob = new Blob([data.join("\n")], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.setAttribute(
        "download",
        `${fileName}-${formatDate(this.startDate)}-${formatDate(
          this.endDate
        )}.csv`
      );
      a.click();
      a.remove();
    },
    async exportDataToCSV(fileName, data) {
      let rows = [];
      if (data.length > 0) {
        let headers = Object.keys(data[0]);
        headers = headers.filter((item) => item !== "usuario_mensagem");
        headers = headers.filter((item) => item !== "usuario_cep");
        rows.push(headers.join(";"));

        for (const item of data) {
          if (item) {
            delete item["usuario_mensagem"];
            delete item["usuario_cep"];
            if (item.data_cadastro)
              item.data_cadastro = item.data_cadastro.slice(0, 10);
            if (item.ultimo_acesso)
              item.ultimo_acesso = item.ultimo_acesso.slice(0, 10);
            if (item.data_criacao)
              item.data_criacao = item.data_criacao.slice(0, 10);
            const values = Object.values(item)
              .map((value) => {
                if (!value) return "----";
                return value;
              })
              .join(";");
            values.length > 0 && rows.push(values);
          }
        }
      }
      this.downloadCSV(fileName, rows);
    },
  },

  data() {
    return {
      definitiveFields: [],
      searchString: null,
      users: [],
      infiniteId: 0,
      pagination: {
        page: 0,
        filter: null,
      },
      fields: [
        {
          key: "usuario_nome",
          label: "Nome",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_telefone",
          label: "Telefone",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_bairro",
          label: "Bairro",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        // {
        //   key: "usuario_cep",
        //   label: "CEP",
        //   filter: false,
        //   sortable: false,
        //   sortDirection: null,
        // },
        {
          key: "zeladoria_nome",
          label: "Zeladoria",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.graph-card {
  border: 1px solid #dddee3;
  border-radius: 16px;
  padding: 24px;
}

.table-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
  margin-bottom: 32px;
}

.monthly-users,
.cumulative-users {
  h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156%;
    color: #565e73;
  }
}
</style>

<template>
  <div class="table-wrapper">
    <div class="no-results">
      <slot name="no-results"></slot>
    </div>
    <table class="table">
      <thead class="table-head">
        <TableRow>
          <TableCellHeader
            v-for="field in fields"
            :key="field.key"
            :field="field"
            @sort="sortTable"
            @filter="filterTable"
            :style="field._style"
            :sortable="field.sortable"
            :filterable="field.filterable"
            :searchableFilter="field.searchableFilter"
            :filters="field.filters"
            :filterTitle="field.filterTitle"
            :sort-direction="field.sortDirection"
          />
          <TableCell v-if="hasActions" />
        </TableRow>
      </thead>
      <tbody class="table-body">
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>
<script>
import TableRow from "./TableRow.vue";
import TableCell from "./TableCell.vue";
import TableCellHeader from "./TableCellHeader.vue";
export default {
  name: "Table",
  components: {
    TableRow,
    TableCell,
    TableCellHeader,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    pages: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    hasActions: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["sort", "changePage", "changeItemsPerPage", "filter"],
  data() {
    return {};
  },
  methods: {
    sortTable(field) {
      this.$emit("sort", field);
    },
    filterTable(ev) {
      this.$emit("filter", ev);
    },
    changeItemsPerPage(itemsPerPage) {
      this.$emit("changeItemsPerPage", itemsPerPage);
    },
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  transform: rotateX(180deg);
  padding: 1rem;
  overflow-x: auto;
  color: #565e73;
  padding-top: 220px;
  table {
    border-collapse: collapse;
  }
  th,
  td {
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
  }
}

.table-wrapper > * {
  transform: rotateX(180deg);
}

.table-body {
  min-height: 100px;
  tr {
    cursor: initial;
    color: #565e73;
  }
}
</style>

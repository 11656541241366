<template>
  <div>
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("Administrative Report") }}</span>
    </h3>

    <div class="ml-3">
      <div class="month-label">Selecione o mês</div>
      <div class="flex-row">
        <div class="input-box">
          <form class="d-flex align-center form">
            <span
              @click="calendarModal = !calendarModal"
              class="material-icons pointer mr-3"
              id="calendar"
              >date_range</span
            >
            <div class="absolute-box w-50" v-if="calendarModal">
              <div>
                <v-date-picker
                  @input="calendarModal = false"
                  v-model="date_start"
                  is-range
                ></v-date-picker>
              </div>
            </div>
          </form>
        </div>
        <div v-for="(nome, index) in nomeList" :key="index" class="close-box">
          <span>{{ nomeList[index] }}</span>
          <img
            @click="closeTab(index)"
            class="close-x"
            src="../../assets/img/close-x.png"
          />
        </div>
      </div>

      <button @click="getRelatorio()" class="Botao-gerar-relatorio">
        Gerar Relatório
      </button>
      <button @click="getGrafico()" class="Botao-gerar-relatorio">
        Gerar Gráfico
      </button>
    </div>

    <div class="tabs ml-3">
      <div
        :class="`tab ${tab.active ? 'active' : ''}`"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="handleChangeTab(tab.value)"
      >
        {{ tab.label }}
      </div>
    </div>

    <transition name="bounce">
      <div>
        <div v-for="(tab, index) in tabList" :key="index">
          <div v-if="currentTab === tab.tab_id">
            <div>
              <div v-show="tabType[index] === 1">
                <div>
                  <Bars :newGraphY="graphY[index]" :newGraphX="graphX[index]" />
                </div>
              </div>

              <div class="tag-box">
                <div v-show="tabType[index] === 0" class="lista">
                  <Table :fields="fields" :hasActions="true">
                    <TableRow
                      v-for="linha in tabList[index].tabela"
                      :key="linha + Math.random()"
                    >
                      <TableCell
                        v-for="field in fields"
                        :key="field.key"
                        :name="field.key"
                        :style="field._style"
                        :sort-direction="field.sortDirection"
                      >
                        <template v-slot:[field.key]>
                          <span class="table-row-text">{{
                            linha[field.key] || "--"
                          }}</span>
                        </template>
                        <template v-slot:usuario_nome>
                          <div>
                            {{ linha.usuario_nome || "--" }}
                          </div>
                        </template>
                        <template v-slot:bairro_nome>
                          <div>
                            {{ linha.bairro_nome || "--" }}
                          </div>
                        </template>
                        <template v-slot:zeladoria_nome>
                          {{ linha.zeladoria_nome || "--" }}
                        </template>
                        <template v-slot:usuario_conta>
                          {{ linha.usuario_conta || "--" }}
                        </template>
                        <template v-slot:execucao_avaliacao_media>
                          {{ linha.execucao_avaliacao_media || "--" }}
                        </template>
                        <template v-slot:valor_remuneracao>
                          {{ linha.valor_remuneracao || "--" }}
                        </template>
                      </TableCell>
                    </TableRow>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Loading v-if="loadingData" />
  </div>
</template>
  <script>
import services from "../../services";
import { formatDate } from "../../utils/date";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import Table from "../../components/tableAdm/Table.vue";
import TableRow from "../../components/tableAdm/TableRow.vue";
import TableCell from "../../components/tableAdm/TableCell.vue";
import Bars from "../../components/Bars.vue";

export default {
  components: {
    BackArrow,
    Loading,
    Table,
    TableRow,
    TableCell,
    Bars,
  },
  data() {
    return {
      editArray: null,
      tabCounter: 0,
      searchString: null,
      calendarModal: false,
      tabList: [],
      nomeList: [],
      tabType: [],
      counter: 0,
      linhas: [],
      counterList: [],
      date_start: null,
      graph: [],
      graphX: [],
      graphY: [],
      barX: [],
      fields: [
        {
          key: "usuario_nome",
          label: "Nome",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "bairro_nome",
          label: "Bairro",
          _style: "",
          sortable: true,
          sortDirection: null,
        },
        {
          key: "zeladoria_nome",
          label: "Zeladoria",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_conta",
          label: "Conta Digital",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "execucao_avaliacao_media",
          label: "Média Avaliações",
          _style: "",
          sortable: true,
          sortDirection: null,
        },
        {
          key: "valor_remuneracao",
          label: "Valor",
          _style: "",
          sortable: false,
          sortDirection: null,
        },
      ],
      currentTabData: null,
      loading: false,
      tabs: [],
      loadingData: false,
      currentTab: 0,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
    };
  },
  created() {
    window.addEventListener("click", (e) => {
      if (
        e.target.id !== "calendar" &&
        e.target.className.toString() !== "[object SVGAnimatedString]" &&
        e.target.className.toString() !== "vc-day-content vc-focusable"
      ) {
        this.calendarModal = false;
      }
    });
  },
  methods: {
    async getRelatorio() {
      const { data, errors } = await services.admReport.getRelatorio({
        data_mes: this.date_start,
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      });
      if (!errors) {
        this.tabType.push(0);
        this.graphX.push([]);
        this.graphY.push([]);
        this.linhas = data;
        this.editArray = {
          tab_id: this.tabCounter,
          tabela: this.linhas,
        };
        this.tabList.push(this.editArray);
        const tabInfo = {
          activate: true,
          label:
            "Relatório " +
            formatDate(this.date_start.start) +
            " - " +
            formatDate(this.date_start.end),
          value: this.tabCounter,
          show: false,
        };
        this.tabs.push(tabInfo);
        this.nomeList.push(tabInfo.label);
        this.handleChangeTab(this.tabCounter);
        this.tabCounter++;
      }
    },
    async getGrafico() {
      const { data, errors } = await services.admReport.getGrafico({
        data_mes: this.date_start,
      });
      if (!errors) {
        this.tabType.push(1);
        this.graph = data;
        this.editArray = {
          tab_id: this.tabCounter,
          grafico: this.graph,
        };
        this.tabList.push(this.editArray);
        const tabInfo = {
          activate: true,
          label:
            "Gráfico " +
            formatDate(this.date_start.start) +
            " - " +
            formatDate(this.date_start.end),
          value: this.tabCounter,
          show: false,
        };
        this.tabs.push(tabInfo);
        this.nomeList.push(tabInfo.label);
        this.barX = [];
        this.barX = this.graph.map((item) => {
          return (
            formatDate(item.ciclo_inicio) + " a " + formatDate(item.ciclo_fim)
          );
        });
        this.graphX.push(this.barX);
        const barY = [
          {
            label: "0% - R$ 0",
            backgroundColor: "#E85755",
            data: [],
          },
          {
            label: "25% - R$ 275",
            backgroundColor: "#F09F44",
            data: [],
          },
          {
            label: "50% - R$ 550",
            backgroundColor: "#61B669",
            data: [],
          },
          {
            label: "75% - R$ 825",
            backgroundColor: "#5DA1D1",
            data: [],
          },
          {
            label: "100% - R$ 1100",
            backgroundColor: "#AB74AB",
            data: [],
          },
        ];
        barY[0].data = this.graph.map((item) => {
          return item.dados[0].qtd_zelador;
        });
        barY[1].data = this.graph.map((item) => {
          return item.dados[1].qtd_zelador;
        });
        barY[2].data = this.graph.map((item) => {
          return item.dados[2].qtd_zelador;
        });
        barY[3].data = this.graph.map((item) => {
          return item.dados[3].qtd_zelador;
        });
        barY[4].data = this.graph.map((item) => {
          return item.dados[4].qtd_zelador;
        });
        this.graphY.push(barY);
        this.handleChangeTab(this.tabCounter);
        this.tabCounter++;
      }
    },
    closeTab(index) {
      this.tabList.splice(index, 1);
      this.nomeList.splice(index, 1);
      this.tabs.splice(index, 1);
      this.graphX.splice(index, 1);
      this.graphY.splice(index, 1);
      this.tabType.splice(index, 1);
    },
    async handleChangeTab(value) {
      this.currentTab = value;
      this.tabs.map((tab) => {
        if (tab.value === value) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.admReport.getRelatorio(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item, id) => {
            return item, id;
          });
          temp.forEach((item) => {
            this.orders.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagetitle {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
}

.form-class {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actions {
  cursor: pointer;
}

.tabs {
  display: flex;
  color: #838998;
}

.tab {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
  cursor: pointer;
  color: #838998;
  &:hover {
    background: #eee;
  }
}

.tab.active {
  color: #131e3b;
  border-bottom: 4px solid #00b1eb;
}

.form {
  position: relative;
  input {
    width: 170px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    color: #747474;
  }
  label {
    margin-right: 10px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 12px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.atividades {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 40px;
  border: 1px solid #dddee3;
  border-radius: 16px;
  margin: 16px 0px;
}
.atividade {
  display: flex;
  flex-direction: row;
  margin: 0px 5px;
  align-items: center;
}
.atividade-switches {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px;
  gap: 40px;
}
.atividade-nome {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #565e73;
  margin-left: 14px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.atividade-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #565e73;
}
.selecionar-todos {
  margin-right: 64px;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #dddee3;
  border-radius: 24px;
}
.hidden {
  display: none;
}
.Botao-gerar-relatorio {
  background: #003063;
  margin-right: 16px;
  margin-bottom: 38px;
  border: none;
  color: white;
  padding: 6px;
  width: 130px;
  border-radius: 6px;
  outline: none;
}
.input-box {
  margin: 16px 0px;
}
.close-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #8aa0b7;
  border-radius: 16px;
  color: white;
  padding: 8px 12px;
  margin: 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.month-label {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

.close-x {
  cursor: pointer;
  margin-left: 5px;
}
.input-filtro {
  margin: 32px 16px 8px 16px;
  padding: 12px;
  border-radius: 12px;
  outline: none;
  border: 1px solid gray;
  width: 300px;
  font-size: 14px;
}
.display-none {
  display: none;
}
</style>




import { render, staticRenderFns } from "./LoginParanagua.vue?vue&type=template&id=56b9292b&scoped=true"
import script from "./LoginParanagua.vue?vue&type=script&lang=js"
export * from "./LoginParanagua.vue?vue&type=script&lang=js"
import style0 from "./LoginParanagua.vue?vue&type=style&index=0&id=56b9292b&prod&scoped=true&lang=scss"
import style1 from "./LoginParanagua.vue?vue&type=style&index=1&id=56b9292b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b9292b",
  null
  
)

export default component.exports
import Vue from "vue";
import Router from "vue-router";

import Index from "../views/index/Index.vue";

import ChangePassword from "../views/profileConfig/ChangePassword.vue";

import KeepingSectorMap from "../views/maps/KeepingSectorMap.vue";
import NeighborhoodMap from "../views/maps/NeighborhoodMap.vue";

import DetailsNeighborhood from "../views/details/DetailsNeighborhood.vue";
import DetailsUser from "../views/details/DetailsUser.vue";
import UserPerformance from "../views/details/UserPerformance.vue";
import UserPerformanceMap from "../views/details/UserPerformanceMap.vue";
import DetailsKeepingSector from "../views/details/DetailsKeepingSector.vue";
import DetailsExecution from "../views/details/DetailsExecution.vue";
import DetailsTraining from "../views/details/DetailsTraining.vue";

import ActivityGallery from "../views/galleries/ActivityGallery.vue";
import KeepingSectorGallery from "../views/galleries/KeepingSectorGallery.vue";
import NeighborhoodGallery from "../views/galleries/NeighborhoodGallery.vue";
import UserGallery from "../views/galleries/UserGallery.vue";

import UserCalendar from "../views/calendars/UserCalendar.vue";

import Policies from "../views/policies/Policies.vue";
import PolicyForm from "../views/policies/PolicyForm.vue";

import Configs from "../views/configs/Configs.vue";
import ConfigsForm from "../views/configs/ConfigsForm.vue";

import Contract from "../views/contract/Contract.vue";
import ContractsAndCancellation from "../views/contractTemplates/ContractsAndCancellation.vue";
import ContractLogs from "../views/contractTemplates/ContractLogs.vue";
import ImageContract from "../views/contract/ImageContract";
import Analytics from "../views/analytics/Analytics.vue";
import AnalyticsMonitores from "../views/analytics/AnalyticsMonitores";
import AnalyticsMonitor from "../views/analytics/AnalyticsPaginaMonitor";
import relatorioAdministrativo from "../views/relatorioAdministrativo/relatorioAdministrativo.vue";
import ordensDeServico from "../views/ordensDeServico/ordensDeServico.vue";
import novaOrdem from "../views/ordensDeServico/novaOrdem.vue";
import editarOrdem from "../views/ordensDeServico/editarOrdem.vue";
import analiseOrdem from "../views/ordensDeServico/analiseOrdem.vue";
import avaliarOrdem from "../views/ordensDeServico/avaliarOrdem.vue";
import Chat from "../views/chat/chat.vue";

import SupervisorsReport from "../views/supervisors/SupervisorsReport.vue";
import SupervisorsDetails from "../views/supervisors/SupervisorsDetails.vue";
import SupervisorTypes from "../views/supervisors/SupervisorTypes.vue";

import Activities from "../views/activities/Activities.vue";
import Tags from "../views/tags/Tags.vue";
import TagTypes from "../views/tags/TagTypes.vue";
import SupervisorTags from "../views/tags/SupervisorTags.vue";
import Institutions from "../views/institutions/Institutions.vue";
import KeepingSectors from "../views/locals/KeepingSectors.vue";
import Neighborhoods from "../views/locals/Neighborhoods.vue";
import Users from "../views/users/Users.vue";
import UserTypes from "../views/users/UserTypes.vue";
import AllUsers from "../views/users/AllUsers.vue";
import UserContracts from "../views/users/UserContracts.vue";
import ContractsRelatory from "../views/users/ContractsRelatory.vue";
import AccessRelatory from "../views/users/AccessRelatory.vue";
import UsersManagers from "../views/users/UsersManagers.vue";
import UsersSupervisors from "../views/users/UsersSupervisors.vue";
import MonitorKeepingSectors from "../views/users/MonitorKeepingSectors.vue";
import Executions from "../views/executions/Executions.vue";
import Import from "../views/imports/Import.vue";
import LinkKeepingSector from "../views/linkKeepingSector/LinkKeepingSector.vue";
import Subcontractors from "../views/subcontractors/Subcontractors.vue";
import SubcontractorForm from "../views/subcontractors/SubcontractorForm.vue";
import SubcontractorUsers from "../views/subcontractors/SubcontractorUsers.vue";

import LoginCuritiba from "../views/noAuth/Curitiba/LoginCuritiba.vue";
import LoginGuarulhos from "../views/noAuth/Guarulhos/LoginGuarulhos.vue";
import LoginMuriae from "../views/noAuth/Muriae/LoginMuriae.vue";
import ForgotPasswordMuriae from "../views/noAuth/Muriae/ForgotPasswordMuriae.vue";
import LoginBeloHorizonte from "../views/noAuth/BeloHorizonte/LoginBeloHorizonte.vue";
import ForgotPasswordGuarulhos from "../views/noAuth/Guarulhos/ForgotPasswordGuarulhos.vue";
import ForgotPasswordBeloHorizonte from "../views/noAuth/BeloHorizonte/ForgotPasswordBeloHorizonte.vue";
import Login from "../views/noAuth/SmartCitizen/Login.vue";
import LoginFloripa from "../views/noAuth/Floripa/LoginFloripa.vue";
import LoginRio from "../views/noAuth/Rio/LoginRio.vue";
import LoginRecife from "../views/noAuth/Recife/LoginRecife.vue";
import LoginOsasco from "../views/noAuth/Osasco/LoginOsasco.vue";
import LoginParanagua from "../views/noAuth/Paranagua/LoginParanagua.vue";
import LoginRibeiraoPreto from "../views/noAuth/RibeiraoPreto/LoginRibeiraoPreto.vue";
import LoginApucarana from "../views/noAuth/Apucarana/LoginApucarana.vue";
import LoginSaoJoseDosPinhais from "../views/noAuth/SaoJoseDosPinhais/LoginSaoJose.vue";
import LoginPontaGrossa from "../views/noAuth/PontaGrossa/LoginPontaGrossa.vue";

import LoginManaus from "../views/noAuth/Manaus/LoginManaus.vue";
import ForgotPasswordCuritiba from "../views/noAuth/Curitiba/ForgotPasswordCuritiba.vue";
import ForgotPasswordRecife from "../views/noAuth/Recife/ForgotPasswordRecife.vue";
import ForgotPasswordSaoJoseDosPinhais from "../views/noAuth/SaoJoseDosPinhais/ForgotPasswordSaoJose.vue";
import ForgotPasswordFloripa from "../views/noAuth/Floripa/ForgotPasswordFloripa.vue";
import ForgotPasswordRio from "../views/noAuth/Rio/ForgotPasswordRio.vue";
import ForgotPasswordOsasco from "../views/noAuth/Osasco/ForgotPasswordOsasco.vue";
import ForgotPasswordParanagua from "../views/noAuth/Paranagua/ForgotPasswordParanagua.vue";
import ForgotPasswordRibeiraoPreto from "../views/noAuth/RibeiraoPreto/ForgotPasswordRibeiraoPreto.vue";
import ForgotPasswordManaus from "../views/noAuth/Manaus/ForgotPasswordManaus.vue";
import ForgotPasswordApucarana from "../views/noAuth/Apucarana/ForgotPasswordApucarana.vue";
import ForgotPasswordPontaGrossa from "../views/noAuth/PontaGrossa/ForgotPasswordPontaGrossa.vue";


import Print from "../views/print/Print.vue";

import ExecutionsMeasurement from "../views/measurement/ExecutionsMeasurement.vue";
import MeasurementByInstitution from "../views/measurement/MeasurementByInstitution.vue";
import MaterialsRelatory from "../views/measurement/MaterialsRelatory.vue";
import Reports from "../views/reports/Reports.vue";
import TechnicalReports from "../views/reports/TechnicalReports.vue";
import Statistics from "../views/statistics/Statistics.vue";

import Candidates from "../views/candidates/Candidates.vue";
import CandidateDetails from "../views/candidates/CandidateDetails.vue";

import FiscalList from "../views/fiscal/FiscalList.vue";
import FiscalReport from "../views/fiscal/FiscalReport.vue";
import CityView from "../views/fiscal/CityView.vue";

import MonthReport from "../views/monthReport/monthReport.vue";

import MonitorDashboard from "../views/monitor-dashboard/MonitorDashboard.vue";
import ResearchesPage from "../views/researches/ResearchesPage.vue";
import ResearchForm from "../views/researches/ResearchForm.vue";
import ResearchesReport from "../views/researches/ResearchesReport.vue";

import ManholeByTag from "../views/manholes/ManholeByTag.vue";

import beforeEach from "./beforeEach";

const TheContainer = () => import("@/components/TheContainer");

Vue.use(Router);
export let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/a",
      component: TheContainer,
      children: [
        {
          path: "/dashboard-monitor",
          name: "DashboardMonitor",
          component: MonitorDashboard,
        },
        {
          path: "/zeladores/:id?",
          name: "Zeladores",
          component: Users,
        },
        {
          path: "/contratos-zeladores",
          name: "ContratosZeladores",
          component: AllUsers,
        },
        {
          path: "/zeladores-contratos/:id",
          name: "Contratos",
          component: UserContracts,
        },

        {
          path: "/monitor/zeladorias/:id?",
          name: "MonitorKeepingSectors",
          component: MonitorKeepingSectors,
        },
        {
          path: "/bairros",
          name: "Bairros",
          component: Neighborhoods,
        },
        {
          path: "/detalhes-bairro/:id",
          name: "DetailsBairro",
          component: DetailsNeighborhood,
        },
        {
          path: "/areas-de-zeladoria",
          name: "Áreas de Zeladoria",
          component: KeepingSectors,
        },
        {
          path: "/detalhes-zelador/:id",
          name: "DetalhesZelador",
          component: DetailsUser,
        },
        {
          path: "/desempenho-zelador/:id",
          name: "DesempenhoZelador",
          component: UserPerformance,
        },
        {
          path: "/desempenho-zelador/mapa/:id",
          name: "DesempenhoZeladorMapa",
          component: UserPerformanceMap,
        },
        {
          path: "/fiscal/lista",
          name: "Fiscais",
          component: FiscalList,
        },
        {
          path: "/fiscal/:id?",
          name: "FiscalDetalhamento",
          component: FiscalReport,
        },
        {
          path: "/galeria-atividade/:id",
          name: "GaleriaAtividade",
          component: ActivityGallery,
        },
        {
          path: "/galeria-bairro/:id",
          name: "GaleriaBairro",
          component: NeighborhoodGallery,
        },
        {
          path: "/galeria-zelador/:id",
          name: "GaleriaZelador",
          component: UserGallery,
        },
        {
          path: "/galeria-zeladoria/:id",
          name: "GaleriaZeladoria",
          component: KeepingSectorGallery,
        },
        {
          path: "/detalhes-zeladoria/:id",
          name: "DetalhesZeladoria",
          component: DetailsKeepingSector,
        },
        {
          path: "/imagem-contrato/:id",
          name: "ImagemContrato",
          component: ImageContract,
        },
        {
          path: "/analytics-do-zelador/:id",
          name: "Analytics",
          component: Analytics,
        },
        {
          path: "/relatorioAdministrativo",
          name: "relatorioAdm",
          component: relatorioAdministrativo,
        },
        {
          path: "/AnalyticsMonitores",
          name: "AnalyticsMonitores",
          component: AnalyticsMonitores,
        },
        {
          path: "/AnalyticsMonitor/:id",
          name: "AnalyticsMonitor",
          component: AnalyticsMonitor,
        },
        {
          path: "/calendario-zelador/:id",
          name: "CalendarioZelador",
          component: UserCalendar,
        },
        {
          path: "/vincular-zelador/:id",
          name: "AssociarZeladoria",
          component: LinkKeepingSector,
        },
        {
          path: "/mapa/zelador",
          name: "MapaZelador",
          component: KeepingSectorMap,
        },
        {
          path: "/mapa/bairros",
          name: "MapaBairro",
          component: NeighborhoodMap,
        },
        {
          path: "/senha",
          name: "Senha",
          component: ChangePassword,
        },
        {
          path: "/execucoes/:atividade_id",
          name: "Execucoes",
          component: Executions,
        },
        {
          path: "/detalhe-execucao/:execucao_id?",
          name: "DetalheExecucao",
          props: true,
          component: DetailsExecution,
        },
        {
          path: "/detalhe-treinamento/:user_id",
          name: "DetalheTreinamento",
          component: DetailsTraining,
        },
        {
          path: "/atividades",
          name: "Atividades",
          component: Activities,
        },
        {
          path: "/instituicoes",
          name: "Instituicoes",
          component: Institutions,
        },
        {
          path: "/associacoes",
          name: "Subcontratadas",
          component: Subcontractors,
        },
        {
          path: "/chat",
          name: "Chat",
          component: Chat,
        },
        {
          path: "/associacao/:tipo?",
          name: "Subcontratada",
          component: SubcontractorForm,
        },
        {
          path: "/politicas",
          name: "Políticas",
          component: Policies,
        },
        {
          path: "/politicas/:tipo?/:id?",
          name: "Políticas Formulário",
          component: PolicyForm,
        },
        {
          path: "/configuracoes",
          name: "Configurações",
          component: Configs,
        },
        {
          path: "/contrato-zelador",
          name: "Contrato Zelador",
          component: Contract,
        },
        {
          path: "/contratos-distratos/:contractId?",
          name: "ContratosDistratos",
          component: ContractsAndCancellation,
        },
        {
          path: "/contratos-distratos/logs/:type",
          name: "LogContratos",
          component: ContractLogs,
        },
        {
          path: "/configuracoes/:tipo?/:id?",
          name: "Configurações Formulário",
          component: ConfigsForm,
        },
        {
          path: "/associacao/zeladores/:id",
          name: "SubcontratadaZeladores",
          component: SubcontractorUsers,
        },
        {
          path: "/tipos-tag",
          name: "TiposTag",
          component: TagTypes,
        },
        {
          path: "/tags",
          name: "Tags",
          component: Tags,
        },
        {
          path: "/tags-fiscal",
          name: "TagsFiscal",
          component: SupervisorTags,
        },
        {
          path: "/tipos-fiscal",
          name: "TiposFiscal",
          component: SupervisorTypes,
        },
        {
          path: "/importacao",
          name: "Importação AMA PJ",
          component: Import,
        },
        {
          path: "/cadastro-usuario",
          name: "CadastroUsuario",
          component: UsersManagers,
        },
        {
          path: "/tipos-usuario",
          name: "TiposUsuario",
          component: UserTypes,
        },
        {
          path: "/cadastro-fiscal",
          name: "CadastroFiscal",
          component: UsersSupervisors,
        },
        {
          path: "/fiscalizacoes",
          name: "Fiscalizações",
          component: SupervisorsReport,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/fiscalizacoes/:id?",
          name: "Fiscalizações Detalhes",
          component: SupervisorsDetails,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/pesquisas",
          name: "Pesquisas",
          component: ResearchesPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/pesquisa/:id?",
          name: "PesquisaForm",
          component: ResearchForm,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/pesquisa/relatorio/:id?",
          name: "PesquisaRelatorio",
          component: ResearchesReport,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/funil",
          name: "Statistics",
          component: Statistics,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/ordens-de-servico",
          name: "ordensDeServico",
          component: ordensDeServico,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/analise-ordem-de-servico/:id",
          name: "analiseOrdemDeServico",
          component: analiseOrdem,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/avaliar-ordem-de-servico/:id",
          name: "avaliarOrdemDeServico",
          component: avaliarOrdem,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/nova-ordem-de-servico",
          name: "novaOrdemDeServico",
          component: novaOrdem,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/editar-ordem-de-servico/:id",
          name: "editarOrdemDeServico",
          component: editarOrdem,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/bueiro-por-tag",
          name: "BueirosPorTag",
          component: ManholeByTag,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/relatorios",
          name: "Relatorios",
          component: Reports,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/laudos",
          name: "Laudos",
          component: TechnicalReports,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/candidatos",
          name: "Candidatos",
          component: Candidates,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/candidatos/:id",
          name: "CandidatoDetalhes",
          component: CandidateDetails,
          meta: {
            requiresAuth: true,
          },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/dashboard/:neighborhoodId?/:keepingSectorId?",
      name: "index",
      component: Index,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/mapa/fiscal/cidade",
      name: "Fiscal",
      component: CityView,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/laudo-contratos",
      name: "LaudoContratos",
      component: ContractsRelatory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/relatorio-materiais",
      name: "RelatorioMateriais",
      component: MaterialsRelatory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/relatorio-acesso",
      name: "RelatorioAcessos",
      component: AccessRelatory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/laudo-tecnico",
      name: "LaudoTecnico",
      component: ExecutionsMeasurement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/laudo-tecnico-por-instituicao",
      name: "LaudoTecnicoPorInstituicao",
      component: MeasurementByInstitution,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/laudo-tecnico-mensal",
      name: "LaudoMensal",
      component: MonthReport,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/relatorio/:id",
      name: "Print",
      component: Print,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/curitiba",
      name: "login-curitiba",
      component: LoginCuritiba,
    },
    {
      path: "/esqueci-minha-senha/curitiba",
      name: "esqueci-senha-curitiba",
      component: ForgotPasswordCuritiba,
    },
    {
      path: "/florianopolis",
      name: "login-florianopolis",
      component: LoginFloripa,
    },
    {
      path: "/guarulhos",
      name: "login-guarulhos",
      component: LoginGuarulhos,
    },
    {
      path: "/muriae",
      name: "login-muriae",
      component: LoginMuriae,
    },
    {
      path: "/belo-horizonte",
      name: "login-belo-horizonte",
      component: LoginBeloHorizonte,
    },
    {
      path: "/ponta-grossa",
      name: "login-ponta-grossa",
      component: LoginPontaGrossa,
    },
    {
      path: "/esqueci-minha-senha/guarulhos",
      name: "esqueci-senha-guarulhos",
      component: ForgotPasswordGuarulhos,
    },
    {
      path: "/esqueci-minha-senha/ponta-grossa",
      name: "esqueci-senha-ponta-grossa",
      component: ForgotPasswordPontaGrossa,
    },
    {
      path: "/esqueci-minha-senha/muriae",
      name: "esqueci-senha-muriae",
      component: ForgotPasswordMuriae,
    },
    {
      path: "/esqueci-minha-senha/belo-horizonte",
      name: "esqueci-senha-belo-horizonte",
      component: ForgotPasswordBeloHorizonte,
    },
    {
      path: "/esqueci-minha-senha/florianopolis",
      name: "esqueci-senha-florianopolis",
      component: ForgotPasswordFloripa,
    },
    {
      path: "/rio-de-janeiro",
      name: "login-rio",
      component: LoginRio,
    },
    {
      path: "/esqueci-minha-senha/rio",
      name: "esqueci-senha-rio",
      component: ForgotPasswordRio,
    },
    {
      path: "/osasco",
      name: "login-osasco",
      component: LoginOsasco,
    },
    {
      path: "/esqueci-minha-senha/osasco",
      name: "esqueci-senha-osasco",
      component: ForgotPasswordOsasco,
    },
    {
      path: "/apucarana",
      name: "login-apucarana",
      component: LoginApucarana,
    },
    {
      path: "/esqueci-minha-senha/apucarana",
      name: "esqueci-senha-apucarana",
      component: ForgotPasswordApucarana,
    },
    {
      path: "/sao-jose-pinhais",
      name: "login-sao-jose-dos-pinhais",
      component: LoginSaoJoseDosPinhais,
    },
    {
      path: "/esqueci-minha-senha/sao-jose-pinhais",
      name: "esqueci-senha-sao-jose-dos-pinhais",
      component: ForgotPasswordSaoJoseDosPinhais,
    },
    {
      path: "/paranagua",
      name: "login-paranagua",
      component: LoginParanagua,
    },
    {
      path: "/esqueci-minha-senha/paranagua",
      name: "esqueci-senha-paranagua",
      component: ForgotPasswordParanagua,
    },
    {
      path: "/ribeirao-preto",
      name: "login-ribeirao-preto",
      component: LoginRibeiraoPreto,
    },
    {
      path: "/manaus",
      name: "login-manaus",
      component: LoginManaus,
    },
    {
      path: "/esqueci-minha-senha/manaus",
      name: "esqueci-senha-manaus",
      component: ForgotPasswordManaus,
    },
    {
      path: "/recife",
      name: "login-recife",
      component: LoginRecife,
    },
    {
      path: "/esqueci-minha-senha/recife",
      name: "esqueci-senha-recife",
      component: ForgotPasswordRecife,
    },
    {
      path: "/esqueci-minha-senha/ribeirao-preto",
      name: "esqueci-senha-ribeirao-preto",
      component: ForgotPasswordRibeiraoPreto,
    },
  ],
});

router.beforeEach(beforeEach);

export default router;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 mb-4"},[_c('CRow',{staticClass:"position-relative"},[_c('CCol',{staticClass:"mt-2",attrs:{"sm":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"month-selector-label mt-2"},[_vm._v("Medição")])])]),_c('div',{staticClass:"mt-2 p-2 w-100"},[_c('div',{staticClass:"row flex-fill mb-4"},[_c('div',{staticClass:"d-flex justify-content-center col-3 offset-5 cycle-label"},[_vm._v(" AVALIAÇÃO "),_c('br'),_vm._v(" MONITOR ")]),_c('div',{staticClass:"d-flex justify-content-center col-3 cycle-label"},[_vm._v(" AVALIAÇÃO "),_c('br'),_vm._v(" FISCAL ")])]),_vm._l((_vm.getCycles),function(cycle){return _c('div',{key:cycle.execucao_avaliacao_id,staticClass:"cycle row flex-fill mt-3 d-flex align-items-center"},[_c('div',{staticClass:"col-5 d-flex align-items-baseline justify-content-start"},[_c('div',{staticClass:"cycle-input d-flex align-items-center"},[_c('label',{staticClass:"cycle-date-label",style:(`${
                _vm.checkIfDateIsFuture(cycle.execucao_avaliacao_inicio)
                  ? 'color: #CCCCCC !important'
                  : ''
              }`)},[_vm._v(" "+_vm._s(cycle.execucao_avaliacao_inicio)+" a "+_vm._s(cycle.execucao_avaliacao_fim)+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center col-3 mt-1"},[(_vm.userType === 3)?_c('CSelect',{attrs:{"value":cycle.execucao_avaliacao_percentual_final,"disabled":_vm.monitorSelectDisabled(cycle),"options":_vm.percentageOptions},on:{"change":_vm.handleChangePercentage,"update:value":function($event){return _vm.$set(cycle, "execucao_avaliacao_percentual_final", $event)}}}):_c('div',{staticClass:"cycle-evaluation mr-2"},[_vm._v(" "+_vm._s(cycle.execucao_avaliacao_percentual_final)+"% ")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center col-3 mt-1"},[(_vm.userType === 2)?_c('CSelect',{attrs:{"disabled":_vm.supervisorSelectDisabled(cycle),"value":cycle.execucao_avaliacao_prefeitura_percentual_final,"options":_vm.percentageOptions},on:{"change":_vm.handleChangePercentageSupervisor,"update:value":function($event){return _vm.$set(cycle, "execucao_avaliacao_prefeitura_percentual_final", $event)}}}):_c('div',{staticClass:"cycle-evaluation"},[_vm._v(" "+_vm._s(cycle.execucao_avaliacao_prefeitura_percentual_final)+"% ")])],1),_c('div',{staticClass:"d-flex justify-content-center col-1 mb-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{on:{"click":function($event){_vm.disableSaveButton(cycle) || _vm.disableButtons(cycle)
                  ? ''
                  : (_vm.saveMeasurementModal = true)}}},[_c('span',{staticClass:"icon",class:`${
                  _vm.disableSaveButton(cycle) || _vm.disableButtons(cycle)
                    ? 'disabled'
                    : ''
                }`},[_c('svg',{attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8 9.5V5.5H2V9.5H1C0.867392 9.5 0.740215 9.44732 0.646447 9.35355C0.552678 9.25979 0.5 9.13261 0.5 9V1C0.5 0.867392 0.552678 0.740215 0.646447 0.646447C0.740215 0.552678 0.867392 0.5 1 0.5H7.5L9.5 2.5V9C9.5 9.13261 9.44732 9.25979 9.35355 9.35355C9.25979 9.44732 9.13261 9.5 9 9.5H8ZM7 9.5H3V6.5H7V9.5Z","fill":"white"}})])])])])])])})],2)],1),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.saveMeasurementModal),expression:"saveMeasurementModal"}],attrs:{"width":400},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w-100 position-relative"},[_c('p',{staticClass:"contract-modal-title"},[_vm._v("Salvar medição")])]),_c('span',{staticClass:"material-icons icon-close",on:{"click":() => {
            _vm.saveMeasurementModal = false;
          }}},[_vm._v("close")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"modal-text"},[_c('p',[_vm._v("Tem certeza que deseja avaliar?")])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[_c('Button',{staticClass:"w-50",attrs:{"size":"small","name":"Salvar"},on:{"clicked":_vm.saveMeasurement}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <canvas
      ref="cumulativeChart"
      id="cumulative-chart"
      width="400"
      height="400"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";

export default {
  name: "UsersPerPeriodGraph",
  props: {
    graphData: {
      type: Array,
      default: () => [0],
    },
    startDate: { type: String, default: "" },
    endDate: { type: String, default: "" },
  },
  mounted() {
    this.chartElement = new Chart(
      this.$refs.cumulativeChart,
      this.chartConfigs
    );
    setTimeout(() => {
      if (this.chartElement) {
        this.chartElement.update();
      }
    }, 0);
  },
  watch: {
    graphData: {
      handler(newVal) {
        this.chartConfigs.data.datasets[0].data = [];
        this.formatAndAppendData(newVal);
        if (this.chartElement) {
          this.chartElement.update();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatAndAppendData(data) {
      const arr = data.map((item) => {
        return {
          x: item.data_cadastro,
          y: item.usuarios_dia,
        };
      });
      arr.map((item, index, originalArr) => {
        if (index === 0) {
          return originalArr[index];
        }
        if (index > 0) {
          return (originalArr[index].y =
            originalArr[index - 1].y + originalArr[index].y);
        }
      });

      this.chartConfigs.data.datasets[0].data = arr;

      if (this.chartElement) {
        this.chartElement.update();
      }
    },
  },
  data() {
    return {
      chartElement: null,
      chartConfigs: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: [],
              data: [],
              fill: false,
              borderColor: "#61b2e5",
              cubicInterpolationMode: "default",
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            intersect: false,
          },
          tooltips: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: true,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: "DD-MM-YY",
              },
            },
            y: {
              display: true,
              beginAtZero: true,
              title: {
                display: true,
              },
            },
          },
        },
      },
    };
  },
};
</script>

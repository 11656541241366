export default httpClient => ({
  getSubcontractors: async () => {
    const response = await httpClient.get('/subcontractor')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getSubcontractorsNew: async (payload) => {
    const response = await httpClient.post('/subcontractor-data-table', {
      page: payload.page,
      sortBy: payload.sortBy,
      order: payload.order,
      filter: payload.filter
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getSubcontractorsTypes: async () => {
    const response = await httpClient.get('/subcontractor-type')
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  postSubcontractor: async (data) => {
    const response = await httpClient.post('/subcontractor', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getSubcontractorById: async (data) => {
    const response = await httpClient.get(`/subcontractor/${data}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getUsersOfSubcontractors: async (data) => {
    const response = await httpClient.get(`/subcontractor-user/${data}`)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getUsersOfSubcontractorsPaginated: async (id, pagination) => {
    const response = await httpClient.post(`/subcontractor-user/user-data-table`, {
      subcontratada_id: id,
      page: pagination.page,
      sortBy: pagination.sortBy,
      order: pagination.order,
      filter: pagination.filter
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  updateStatusUserSubcontractor: async (data) => {
    const response = await httpClient.put('/subcontractor-user/change-state', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  putSubcontractor: async (data) => {
    const response = await httpClient.put('/subcontractor', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  linkSubcontractorAndUser: async (data) => {
    const response = await httpClient.post('/subcontractor-user', data)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
})

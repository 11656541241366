export default (httpClient) => ({
  getTags: async () => {
    const response = await httpClient.get("/tag");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getTagsDataTable: async (payload) => {
    const response = await httpClient.post("/tag-data-table", payload);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorTags: async () => {
    const response = await httpClient.get("/fiscal-tag");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorTagById: async (tagId) => {
    const response = await httpClient.get(`/fiscal-tag/id/${tagId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  createSupervisorTag: async (payload) => {
    const response = await httpClient.post(`/fiscal-tag`, payload);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  editSupervisorTag: async (payload) => {
    const response = await httpClient.put(`/fiscal-tag/${payload.fiscal_tag_id}`, payload);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteSupervisorTag: async (tagId) => {
    const response = await httpClient.delete(
      `/fiscal-tag/${tagId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteActivityTag: async (data) => {
    const response = await httpClient.delete(
      `/activity-tag/${data.activityId}/${data.tagId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getTagsByActivity: async (data) => {
    const response = await httpClient.get(`/activity-tag/${data.activityId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getTagsByActivityAndInstitution: async (data) => {
    const response = await httpClient.post(`/tag/return-all`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getTagsInstitutions: async () => {
    const response = await httpClient.get(`/tag-institution`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteTag: async (data) => {
    const response = await httpClient.delete(`/tag/${data.tagId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  postTag: async (data) => {
    const response = await httpClient.post(`/tag`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: JSON.parse(response.request.responseText),
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  linkTagInstitution: async (data) => {
    const response = await httpClient.post(`/tag-institution`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  putTag: async (data) => {
    const response = await httpClient.put(`/tag`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getManholesByTag: async () => {
    const response = await httpClient.post(`manhole/185`, {
      bairro_id: 79,
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
});

<template>
  <div>
    <div class="print-container bg-white">
      <header class="d-flex justify-content-between align-items-center mb-4">
        <div>
          <div class="d-flex align-items-center">
            <div>
              <div class="pr-2 pb-5">
                <span @click="goToHome" class="pointer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.828 6.99992H16V8.99992H3.828L9.192 14.3639L7.778 15.7779L0 7.99992L7.778 0.221924L9.192 1.63592L3.828 6.99992Z"
                      fill="#565E73"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div class="ml-2 d-flex flex-column align-items-start">
              <h3 class="print-title">{{ $t("turnoverReport") }}</h3>
              <p class="print-subtitle">
                {{ cityName.slice(0, -3) }}
              </p>
            </div>
          </div>
        </div>
        <div class="">
          <img :src="urlLogo" alt="logo" width="220" />
        </div>
      </header>

      <h5 class="form-title mb-4">Filtrar por data específica</h5>
      <div class="d-flex align-items-center">
        <form class="d-flex align-center form" @submit="requestReport">
          <span
            @click="calendarModal = !calendarModal"
            class="new-icons pointer btn-relatory mr-3 mb-1"
          >
            <svg
              width="14"
              height="14"
              viewBox="-1 1 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                fill="white"
              />
            </svg>
          </span>
          <div class="absolute-box w-50" v-if="calendarModal">
            <v-date-picker
              v-model="dates"
              is-range
              @input="calendarModal = false"
            />
          </div>
          <DateLabel
            :name="formatDates()"
            class="no-pointer mr-3"
            v-if="dates.start && dates.end"
          />

          <Button
            type="primary"
            size="small"
            :width="400"
            class="btn-position generate-report"
            name="Gerar Relatório"
            :disabled="loadingData"
          />
        </form>

        <span class="new-icons pointer btn-relatory ml-3" @click="print">
          <svg
            width="17"
            height="17"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.66659 10.334H10.3333V13.6673H3.66659V10.334ZM11.6666 12.334V9.00065H2.33325V12.334H0.999919C0.823108 12.334 0.653538 12.2637 0.528514 12.1387C0.40349 12.0137 0.333252 11.8441 0.333252 11.6673V5.00065C0.333252 4.82384 0.40349 4.65427 0.528514 4.52925C0.653538 4.40422 0.823108 4.33398 0.999919 4.33398H12.9999C13.1767 4.33398 13.3463 4.40422 13.4713 4.52925C13.5963 4.65427 13.6666 4.82384 13.6666 5.00065V11.6673C13.6666 11.8441 13.5963 12.0137 13.4713 12.1387C13.3463 12.2637 13.1767 12.334 12.9999 12.334H11.6666ZM2.33325 5.66732V7.00065H4.33325V5.66732H2.33325ZM3.66659 0.333984H10.3333C10.5101 0.333984 10.6796 0.404222 10.8047 0.529246C10.9297 0.654271 10.9999 0.82384 10.9999 1.00065V3.00065H2.99992V1.00065C2.99992 0.82384 3.07016 0.654271 3.19518 0.529246C3.32021 0.404222 3.48977 0.333984 3.66659 0.333984Z"
              fill="white"
            />
          </svg>
        </span>
      </div>

      <section id="content" class="mt-5">
        <div v-if="!loadingData">
          <div class="current-dates">
            Período selecionado: {{ formatDate(dates.start) }} -
            {{ formatDate(dates.end) }}
          </div>
          <table v-if="contracts.length > 0">
            <thead>
              <tr>
                <th>
                  Zelador
                  <span
                    class="pointer pl-2 pb-2"
                    @click="
                      sortBy('usuario_nome', sortByUser),
                        (sortByUser = sortByUser === 'asc' ? 'desc' : 'asc')
                    "
                    ><svg
                      :style="{
                        transform: sortByUser === 'asc' ? 'rotate(180deg)' : '',
                      }"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                  ></span>
                </th>
                <th>CPF</th>
                <th>CNPJ</th>
                <th>
                  inicio
                  <span
                    class="pointer pl-2 pb-2"
                    @click="
                      sortBy(
                        'turnover_data_inicio_contrato',
                        sortByInitialDate
                      ),
                        (sortByInitialDate =
                          sortByInitialDate === 'asc' ? 'desc' : 'asc')
                    "
                    ><svg
                      :style="{
                        transform:
                          sortByInitialDate === 'asc' ? 'rotate(180deg)' : '',
                      }"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                  ></span>
                </th>
                <th>
                  Fim
                  <span
                    class="pointer pl-2 pb-2"
                    @click="
                      sortBy('turnover_data_fim_contrato', sortByFinalDate),
                        (sortByFinalDate =
                          sortByFinalDate === 'asc' ? 'desc' : 'asc')
                    "
                    ><svg
                      :style="{
                        transform:
                          sortByFinalDate === 'asc' ? 'rotate(180deg)' : '',
                      }"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                  ></span>
                </th>
                <th>
                  Status
                  <span
                    class="pointer pl-2 pb-2"
                    @click="
                      sortBy('turnover_contrato_ativo', sortByStatus),
                        (sortByStatus = sortByStatus === 'asc' ? 'desc' : 'asc')
                    "
                    ><svg
                      :style="{
                        transform:
                          sortByStatus === 'asc' ? 'rotate(180deg)' : '',
                      }"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 4L0 0H8L4 4Z" fill="#fff" /></svg
                  ></span>
                </th>
              </tr>
            </thead>
            <tr v-for="contract of contracts" :key="Math.random() + contract">
              <td>{{ contract.usuario_nome || "--" }}</td>
              <td>{{ formataCPF(contract.usuario_cpf) || "--" }}</td>
              <td>{{ formataCPF(contract.usuario_cnpj) || "--" }}</td>
              <td>
                {{ formatDate(contract.turnover_data_inicio_contrato) || "--" }}
              </td>
              <td>
                {{ formatDate(contract.turnover_data_fim_contrato) || "--" }}
              </td>
              <td>
                {{
                  contract.turnover_contrato_ativo === 1 ? "Ativo" : "Inativo"
                }}
              </td>
            </tr>
          </table>
          <NoResults v-else />
        </div>
        <Loading v-else />
      </section>
    </div>
  </div>
</template>

<script>
import services from "../../services";
import NoResults from "../../components/table/NoResults.vue";
import DateLabel from "../../components/DateLabel.vue";
import Button from "../../components/Button.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import { decrypt } from "../../utils/decrypt";
import { dateToText } from "../../utils/date";

export default {
  data() {
    return {
      loadingData: false,
      cityName: "",
      urlLogo: "",
      calendarModal: false,
      sortByUser: "asc",
      sortByInitialDate: "asc",
      sortByFinalDate: "asc",
      sortByStatus: "asc",
      engineers: [],
      contracts: [],
      dates: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      requestApi: false,
    };
  },
  components: {
    NoResults,
    Button,
    DateLabel,
    Loading,
  },
  mounted: async function () {
    await this.loggedUser();
    await this.requestReport();
  },
  created() {
    window.addEventListener("click", (e) => {
      if (
        e.target.id !== "calendar" &&
        e.target.className.toString() !== "[object SVGAnimatedString]"
      ) {
        this.calendarInitial = false;
        this.calendarFinal = false;
      }
    });
  },
  methods: {
    formataCPF(cpf) {
      if(!cpf) return '--';

      if (cpf.length === 11) {
        cpf = cpf.replace(/[^\d]/g, "");
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }

      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    async loggedUser() {
      const encrypted = localStorage.getItem("ama");
      const decrypted = decrypt(encrypted);
      const user = JSON.parse(decrypted);
      if (user) {
        this.cityName = user.usuario_cidade;
        this.urlLogo = user.cidade_logo;
      }
    },
    formatDates() {
      if (this.dates.start && this.dates.end) {
        return `${dateToText(this.dates.start.toISOString())} - ${dateToText(
          this.dates.end.toISOString()
        )}`;
      }
    },
    async requestReport(ev) {
      this.loadingData = true;
      this.calendarFinal = false;
      this.calendarInitial = false;
      if (ev) {
        ev.preventDefault();
      }
      if (this.dates) {
        await this.requestExecutionEngineers();
        const { data, errors } = await services.users.getTurnoverRelatory({
          data_inicio: this.dates.start,
          data_fim: this.dates.end,
        });
        if (!errors) {
          this.contracts = data;
        }
      }
      this.loadingData = false;
    },
    formatDate(date) {
      if (date) {
        if (date.toString().includes("GMT")) {
          return date
            .toISOString()
            .slice(0, -5)
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
        } else {
          return date.split("T")[0].split("-").reverse().join("/");
        }
      } else {
        return "";
      }
    },
    sortBy(key, order) {
      this.contracts.sort((a, b) => {
        if (a[key] < b[key]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    },
    convertDate(date) {
      if (date) {
        return date
          .toISOString()
          .slice(0, -5)
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      return "";
    },
    async requestExecutionEngineers() {
      if (this.range) {
        const { data, errors } =
          await services.executions.getExecutionsEngineers({
            data_inicio: this.dates.start,
            data_fim: this.dates.end,
          });
        if (!errors) this.engineers = data;
      }
    },
    goToHome(ev) {
      ev.preventDefault();
      this.$router.go(-1);
    },
    print() {
      setTimeout(function () {
        window.print();
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .print-button {
    display: none;
  }
  .generate-report {
    display: none;
  }
  .pointer {
    display: none;
  }
  .form-wrapper {
    display: none !important;
  }
  .form-title {
    display: none !important;
  }
  .back-arrow {
    display: none !important;
  }
  .current-dates {
    display: block !important;
  }
}

.print-container {
  padding: 32px;
  min-height: 100vh;
}

.form-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  color: #565e73;
}

.print-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
}

.print-subtitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 136%;
  color: #565e73;
}

.input-date-custom {
  width: 100%;
  min-width: 160px;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 10px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.btn-position {
  margin-top: 3px;
}

.btn-relatory {
  width: 32px;
  height: 32px;
}

.form-wrapper {
  display: flex;
  align-items: center;
}

table,
td,
th {
  border: 1px solid #e4e4e4;
  text-align: center;
  color: #838998;
}

th,
td {
  height: 33px;
  max-width: 50px;
  font-family: "OpenSans-Regular";
  font-size: 16px;
  padding: 12px;
}

th {
  background-color: #838998;
  font-family: "OpenSans-Regular";
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 16px;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr > .date {
  background-color: #003366;
  color: #fff;
  font-family: "OpenSans-Bold";
  font-size: 14px;
}

tr:nth-child(odd) {
  background-color: #f3f4f5;
}

td {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  text-align: center;
  color: #838998;
}

.legal-text {
  color: #747474;
}
.engineers {
  color: #747474;
}

.form {
  position: relative;

  .print-button {
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.current-dates {
  display: none;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 11px;
  line-height: 145%;
  color: #747474;
  margin: 10px 16px;
}
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ptBr from './locales/pt-br.json'
import en from './locales/en.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || 'pt-br',
  fallbackLocale: 'pt-br',
  messages: {
    'pt-br': ptBr,
    'en': en
  }
})

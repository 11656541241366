<template>
  <div>
    <div class="form-section">Perguntas</div>
    <draggable tag="div" :list="questions" handle=".handle" @update="dragEnd">
      <transition-group name="list" tag="div">
        <div
          v-for="(question, index) of questions"
          :key="'question' + index"
          class="questions-list"
        >
          <div class="question-header">
            <svg
              class="handle"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 9V6L20 10L16 14V11H11V16H14L10 20L6 16H9V11H4V14L0 10L4 6V9H9V4H6L10 0L14 4H11V9H16Z"
                fill="#565E73"
              />
            </svg>

            <svg
              class="pointer remove-btn"
              @click="handleDeleteQuestion(index)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM11.414 12L13.182 13.768L11.768 15.182L10 13.414L8.232 15.182L6.818 13.768L8.586 12L6.818 10.232L8.232 8.818L10 10.586L11.768 8.818L13.182 10.232L11.414 12ZM7 2V4H13V2H7Z"
                fill="#565E73"
              />
            </svg>
          </div>

          <div class="question-title">Pergunta {{ index + 1 }}</div>

          <div class="d-flex">
            <div class="question">
              <Input
                @focusin="focusInput = true"
                @focusout="focusInput = false"
                :maxlength="130"
                placeholder="Adicione uma pergunta"
                v-model="question.question"
              />
              <span
                class="alert-normal"
                :class="question.question.length > 129 && 'alert-length'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7.00004C0.333496 3.31804 3.31816 0.333374 7.00016 0.333374C10.6822 0.333374 13.6668 3.31804 13.6668 7.00004C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3334C8.41465 12.3334 9.77121 11.7715 10.7714 10.7713C11.7716 9.77108 12.3335 8.41453 12.3335 7.00004C12.3335 5.58555 11.7716 4.229 10.7714 3.2288C9.77121 2.22861 8.41465 1.66671 7.00016 1.66671C5.58567 1.66671 4.22912 2.22861 3.22893 3.2288C2.22873 4.229 1.66683 5.58555 1.66683 7.00004C1.66683 8.41453 2.22873 9.77108 3.22893 10.7713C4.22912 11.7715 5.58567 12.3334 7.00016 12.3334ZM6.3335 9.00004H7.66683V10.3334H6.3335V9.00004ZM6.3335 3.66671H7.66683V7.66671H6.3335V3.66671Z"
                    :fill="
                      question.question.length > 129 ? '#ff002e' : '#131e3b'
                    "
                  />
                </svg>
                {{ question.question.length }}/130 caracteres
              </span>
            </div>

            <div class="options-list">
              <draggable
                tag="div"
                :list="question.options"
                handle=".drag-option"
                @update="dragEnd"
              >
                <div
                  v-for="(option, optionIndex) of question.options"
                  :key="'option' + index + optionIndex"
                  class="option"
                >
                  <div class="drag-option">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8 7.2V4.8L16 8L12.8 11.2V8.8H8.8V12.8H11.2L8 16L4.8 12.8H7.2V8.8H3.2V11.2L0 8L3.2 4.8V7.2H7.2V3.2H4.8L8 0L11.2 3.2H8.8V7.2H12.8Z"
                        fill="#565E73"
                      />
                    </svg>
                  </div>
                  <div style="width: 100%">
                    <Input
                      :placeholder="`Resposta ${optionIndex + 1}`"
                      v-model="option.option"
                      :maxlength="55"
                    />
                    <span
                      class="alert-normal"
                      :class="option.option.length > 54 && 'alert-length'"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7.00004C0.333496 3.31804 3.31816 0.333374 7.00016 0.333374C10.6822 0.333374 13.6668 3.31804 13.6668 7.00004C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3334C8.41465 12.3334 9.77121 11.7715 10.7714 10.7713C11.7716 9.77108 12.3335 8.41453 12.3335 7.00004C12.3335 5.58555 11.7716 4.229 10.7714 3.2288C9.77121 2.22861 8.41465 1.66671 7.00016 1.66671C5.58567 1.66671 4.22912 2.22861 3.22893 3.2288C2.22873 4.229 1.66683 5.58555 1.66683 7.00004C1.66683 8.41453 2.22873 9.77108 3.22893 10.7713C4.22912 11.7715 5.58567 12.3334 7.00016 12.3334ZM6.3335 9.00004H7.66683V10.3334H6.3335V9.00004ZM6.3335 3.66671H7.66683V7.66671H6.3335V3.66671Z"
                          :fill="
                            option.option.length > 54 ? '#ff002e' : '#131e3b'
                          "
                        />
                      </svg>
                      {{ option.option.length }}/55 caracteres
                    </span>
                  </div>

                  <div
                    :class="optionIndex < 2 && 'btn-disabled'"
                    class="pointer remove-btn remove-option"
                    @click="removeOption(index, optionIndex)"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.167 3.99996H17.3337V5.66663H15.667V16.5C15.667 16.721 15.5792 16.9329 15.4229 17.0892C15.2666 17.2455 15.0547 17.3333 14.8337 17.3333H3.16699C2.94598 17.3333 2.73402 17.2455 2.57774 17.0892C2.42146 16.9329 2.33366 16.721 2.33366 16.5V5.66663H0.666992V3.99996H4.83366V1.49996C4.83366 1.27895 4.92146 1.06698 5.07774 0.910704C5.23402 0.754423 5.44598 0.666626 5.66699 0.666626H12.3337C12.5547 0.666626 12.7666 0.754423 12.9229 0.910704C13.0792 1.06698 13.167 1.27895 13.167 1.49996V3.99996ZM14.0003 5.66663H4.00033V15.6666H14.0003V5.66663ZM6.50033 2.33329V3.99996H11.5003V2.33329H6.50033Z"
                        fill="#29334E"
                      />
                    </svg>
                  </div>
                </div>
              </draggable>
              <div class="d-flex justify-content-start">
                <div
                  class="add-btn position-relative"
                  :class="
                    questions[index].options.length === 5 && 'btn-disabled'
                  "
                  @click="addOption(index)"
                >
                  <span>Adicionar resposta</span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.1665 8.16663V4.83329H9.83317V8.16663H13.1665V9.83329H9.83317V13.1666H8.1665V9.83329H4.83317V8.16663H8.1665ZM8.99984 17.3333C4.39734 17.3333 0.666504 13.6025 0.666504 8.99996C0.666504 4.39746 4.39734 0.666626 8.99984 0.666626C13.6023 0.666626 17.3332 4.39746 17.3332 8.99996C17.3332 13.6025 13.6023 17.3333 8.99984 17.3333ZM8.99984 15.6666C10.7679 15.6666 12.4636 14.9642 13.7139 13.714C14.9641 12.4638 15.6665 10.7681 15.6665 8.99996C15.6665 7.23185 14.9641 5.53616 13.7139 4.28591C12.4636 3.03567 10.7679 2.33329 8.99984 2.33329C7.23173 2.33329 5.53603 3.03567 4.28579 4.28591C3.03555 5.53616 2.33317 7.23185 2.33317 8.99996C2.33317 10.7681 3.03555 12.4638 4.28579 13.714C5.53603 14.9642 7.23173 15.6666 8.99984 15.6666Z"
                      fill="#131E3B"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>

    <div
      class="add-btn position-relative"
      @click="addQuestion()"
      v-if="questions.length < 10"
    >
      <span>Adicionar pergunta</span>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.1665 8.16663V4.83329H9.83317V8.16663H13.1665V9.83329H9.83317V13.1666H8.1665V9.83329H4.83317V8.16663H8.1665ZM8.99984 17.3333C4.39734 17.3333 0.666504 13.6025 0.666504 8.99996C0.666504 4.39746 4.39734 0.666626 8.99984 0.666626C13.6023 0.666626 17.3332 4.39746 17.3332 8.99996C17.3332 13.6025 13.6023 17.3333 8.99984 17.3333ZM8.99984 15.6666C10.7679 15.6666 12.4636 14.9642 13.7139 13.714C14.9641 12.4638 15.6665 10.7681 15.6665 8.99996C15.6665 7.23185 14.9641 5.53616 13.7139 4.28591C12.4636 3.03567 10.7679 2.33329 8.99984 2.33329C7.23173 2.33329 5.53603 3.03567 4.28579 4.28591C3.03555 5.53616 2.33317 7.23185 2.33317 8.99996C2.33317 10.7681 3.03555 12.4638 4.28579 13.714C5.53603 14.9642 7.23173 15.6666 8.99984 15.6666Z"
          fill="#131E3B"
        />
      </svg>
    </div>
    <div v-else class="text-danger">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00033 17.3333C4.39783 17.3333 0.666992 13.6025 0.666992 8.99996C0.666992 4.39746 4.39783 0.666626 9.00033 0.666626C13.6028 0.666626 17.3337 4.39746 17.3337 8.99996C17.3337 13.6025 13.6028 17.3333 9.00033 17.3333ZM9.00033 15.6666C10.7684 15.6666 12.4641 14.9642 13.7144 13.714C14.9646 12.4638 15.667 10.7681 15.667 8.99996C15.667 7.23185 14.9646 5.53616 13.7144 4.28591C12.4641 3.03567 10.7684 2.33329 9.00033 2.33329C7.23222 2.33329 5.53652 3.03567 4.28628 4.28591C3.03604 5.53616 2.33366 7.23185 2.33366 8.99996C2.33366 10.7681 3.03604 12.4638 4.28628 13.714C5.53652 14.9642 7.23222 15.6666 9.00033 15.6666ZM8.16699 11.5H9.83366V13.1666H8.16699V11.5ZM8.16699 4.83329H9.83366V9.83329H8.16699V4.83329Z"
          fill="#D3341D"
        />
      </svg>
      <span>Você atingiu o limite de perguntas permitidas</span>
    </div>

    <div class="btns-container">
      <Button
        type="outline-danger"
        size="small"
        name="Cancelar pesquisa"
        @clicked="$emit('cancel-form')"
      />

      <Button
        size="small"
        name="Salvar e continuar"
        @clicked="saveQuestions"
        :disabled="!questions.length || !optionsValid"
      />
    </div>

    <Modal class="modal-confirmacao" v-show="modalRemoveQuestion" :width="413">
      <template v-slot:header>
        <div class="modal-title">Excluir pergunta com resposta</div>
      </template>
      <template v-slot:body>
        <div class="modal-body">
          A pergunta que você quer excluir tem respostas associadas. Se decidir
          prosseguir com esta ação,
          <span>tanto a pergunta quanto as respostas serão excluídas</span>. Tem
          certeza de que deseja excluir esta pergunta?
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100">
          <Button
            size="small"
            type="white-outline"
            name="Cancelar"
            @clicked="modalRemoveQuestion = false"
          />

          <Button
            :width="110"
            size="small"
            name="Confirmar"
            @clicked="removeQuestion()"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import Input from "../../../components/form/Input.vue";
import Modal from "../../../components/Modal.vue";
import Button from "../../../components/Button.vue";

import dragEndSound from "../../../assets/sounds/navigation_backward-selection-minimal.mp3";

import services from "../../../services";

export default {
  name: "QuestionsSection",
  components: {
    Input,
    draggable,
    Modal,
    Button,
  },
  data() {
    return {
      questions: [
        {
          id: null,
          question: "",
          options: [
            {
              id: null,
              option: "",
            },
            {
              id: null,
              option: "",
            },
          ],
        },
      ],
      dragEndSound: new Audio(dragEndSound),
      modalRemoveQuestion: false,
      questionToRemove: null,
      focusInput: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getQuestionsById();
    }
  },
  computed: {
    optionsValid() {
      return this.questions.every((question) => {
        return question.options.every((option) => option.option);
      });
    },
  },
  methods: {
    addQuestion() {
      this.questions.push({
        id: null,
        question: "",
        options: [],
      });

      this.addOption(this.questions.length - 1);
      this.addOption(this.questions.length - 1);

      setTimeout(() => {
        window.scrollBy(0, 700);
      }, 100);
    },
    async getQuestionsById() {
      await services.researches
        .getLocationByResearchId(this.$route.params.id)
        .then(({ data }) => {
          if (data.length) this.questions = [];

          data.map((item) => {
            this.questions.push({
              id: item.atividade_pesquisa_pergunta_id,
              question: item.atividade_pesquisa_pergunta,
              options: item.atividade_pesquisa_resposta.map((option) => {
                return {
                  id: option.atividade_pesquisa_resposta_id,
                  option: option.atividade_pesquisa_resposta,
                };
              }),
            });
          });
        });
    },
    async handleDeleteQuestion(index) {
      const hasOptionWithText = this.questions[index].options.some(
        (option) => option.option
      );

      this.questionToRemove = index;

      if (hasOptionWithText) {
        this.modalRemoveQuestion = true;
      } else {
        await this.removeQuestion();
      }
    },
    addOption(index) {
      if (this.questions[index].options.length === 5) {
        this.$toast.default(
          "Você já adicionou o máximo de respostas possíveis",
          {
            position: "top",
            duration: 3000,
          }
        );
        return;
      }

      this.questions[index].options.push({
        id: null,
        option: "",
      });
    },
    async removeQuestion() {
      const question = this.questions[this.questionToRemove];

      if (question.id) {
        const { errors } = await services.researches.deleteQuestion(
          question.id
        );
        if (!errors) {
          this.questions.splice(this.questionToRemove, 1);
          this.modalRemoveQuestion = false;
        }
      } else {
        this.questions.splice(this.questionToRemove, 1);
        this.modalRemoveQuestion = false;
      }

      this.questionToRemove = null;
    },
    async removeOption(questionIndex, optionIndex) {
      if (optionIndex < 2) {
        this.$toast.default("Uma pergunta exige ao menos duas respostas", {
          position: "top",
          duration: 3000,
        });
        return;
      }

      const question = this.questions[questionIndex];
      const option = question.options[optionIndex];

      if (option.id) {
        const { errors } = await services.researches.deleteOption(option.id);
        if (!errors) {
          this.questions[questionIndex].options.splice(optionIndex, 1);
        } else {
          this.$toast.error("Erro ao remover resposta!", {
            position: "top",
            duration: 3000,
          });
          return;
        }
      } else {
        this.questions[questionIndex].options.splice(optionIndex, 1);
      }

      this.$toast.success("Resposta removida com sucesso!", {
        position: "top",
        duration: 3000,
      });
    },
    async dragEnd() {
      await this.dragEndSound.play();
    },
    async createNewQuestion(item, index) {
      const { data, errors } = await services.researches.addNewQuestion({
        atividade_pesquisa_pergunta: item.question,
        atividade_pesquisa_id: this.$route.params.id,
        atividade_pesquisa_pergunta_sequencia: index,
      });
      if (!errors) {
        item.options.map(async (option, optionIndex) => {
          this.createOption(data, option, optionIndex);
        });
      }
    },
    async updateQuestion(item, index) {
      const { errors } = await services.researches.editQuestion({
        atividade_pesquisa_pergunta: item.question,
        atividade_pesquisa_id: this.$route.params.id,
        atividade_pesquisa_pergunta_sequencia: index,
        atividade_pesquisa_pergunta_id: item.id,
      });
      if (!errors) {
        item.options.map(async (option, optionIndex) => {
          if (option.id) {
            this.editOption(item, option, optionIndex);
          } else {
            this.createOption(item, option, optionIndex);
          }
        });
      }
    },
    async createOption(data, option, optionIndex) {
      const { errors } = await services.researches.addNewOption({
        atividade_pesquisa_resposta: option.option,
        atividade_pesquisa_pergunta_id: data.id,
        atividade_pesquisa_resposta_sequencia: optionIndex,
      });
      if (errors) {
        this.$toast.error("Ocorreu um erro ao salvar a pesquisa!", {
          position: "top",
          duration: 3000,
        });
      }
    },
    async editOption(item, option, optionIndex) {
      const { errors } = await services.researches.editOption({
        atividade_pesquisa_resposta: option.option,
        atividade_pesquisa_pergunta_id: item.id,
        atividade_pesquisa_resposta_sequencia: optionIndex,
        atividade_pesquisa_resposta_id: option.id,
      });
      if (errors) {
        this.$toast.error("Ocorreu um erro ao salvar a pesquisa!", {
          position: "top",
          duration: 3000,
        });
      }
    },
    async saveQuestions() {
      await Promise.all(
        this.questions.map(async (item, index) => {
          if (item.id) {
            await this.updateQuestion(item, index);
          } else {
            await this.createNewQuestion(item, index);
          }
        })
      );

      this.$emit("next");
    },
  },
};
</script>

<style scoped lang="scss">
.form-section {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #131e3b;
  margin-bottom: 24px;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 188px;
  height: 32px;
  background: #ffffff;
  border: 2px solid #dddee3;
  border-radius: 6px;
  padding: 0px 8px;

  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #131e3b;
  cursor: pointer;

  span {
    margin-right: 22px;
  }

  svg {
    position: absolute;
    right: 6px;
  }

  &:hover {
    background: #f3f4f5;
  }
}

.question-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.question-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #131e3b;
  margin-bottom: 24px;
}

.questions-list {
  width: 100%;
  background: rgba(230, 247, 253, 0.32);
  border: 1px solid #00b1eb;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
}

.question {
  width: 50%;
  margin-right: 24px;
}

.options-list {
  width: 50%;
}

.option {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 12px;
  }
}

.drag-option {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background: #ffffff;
  border: 2px solid #e6eaef;
  border-radius: 4px;
  height: 50px;
  padding: 0px 6px;
  cursor: move;

  svg {
    margin: 0px;
  }
}

.remove-btn {
  &:hover {
    opacity: 0.8;
  }
}

.remove-option {
  background: rgba(230, 234, 239, 0.8);
  border-radius: 12px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 0 12px;

  svg {
    margin: 0px;
  }
}

.btn-disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.6s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-24px);
}

.handle {
  cursor: move;
}

.text-danger {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #d3341d;

  svg {
    margin-right: 6px;
    margin-bottom: 2px;
  }
}

.modal-title {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: #131e3b !important;
}

.modal-body {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #565e73;

  span {
    color: #131e3b;
    font-weight: 500;
  }
}

.btns-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 52px;
}

.alert-normal {
  position: absolute;

  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #131e3b;
  margin-left: 12px;

  svg {
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 2px;
  }
}
.alert-length {
  color: #ff002e !important;
}
</style>

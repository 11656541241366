export default (httpClient) => ({
  getUsersManagers: async () => {
    const response = await httpClient.get("/user-manager");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUsersSupervisorsNew: async () => {
    const response = await httpClient.get("/user/app-supervisor");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorsStatus: async () => {
    const response = await httpClient.get("/supervisor-record-status");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  addUserSupervisor: async (data) => {
    const response = await httpClient.post("/user/supervisor", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  changeSupervisorStatus: async (data) => {
    const response = await httpClient.put(`/supervisor-record/status`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteUserSupervisor: async (data) => {
    const response = await httpClient.delete(`/user-manager/${data.userId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  editUserSupervisor: async (data) => {
    const response = await httpClient.put(`/user`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  changePassword: async (data) => {
    const response = await httpClient.post(
      `/user-manager/change-password`,
      data
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  userInfo: async (data) => {
    const response = await httpClient.get(`/usuario/${data.userId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  userManagersTypes: async () => {
    const response = await httpClient.get(`/user-manager-type`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getUsersFromMonitor: async (data) => {
    const response = await httpClient.get(`/user-monitor/${data.monitorId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  postUserForMonitor: async (data) => {
    const response = await httpClient.post(`/user-monitor`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteUserFromMonitor: async (data) => {
    const response = await httpClient.delete(
      `/user-monitor/${data.monitorId}/${data.userId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  requestSupervisors: async (payload) => {
    const response = await httpClient.post(
      "/supervisor-record/data-table",
      payload
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  requestFiscalData: async (payload) => {
    const response = await httpClient.post("/supervisor-record-data", payload);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorDetails: async (id) => {
    const response = await httpClient.get(`supervisor-record/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorPhotos: async (id) => {
    const response = await httpClient.get(`supervisor-record-image/${id}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorPins: async (payload) => {
    const response = await httpClient.post(
      `execution/supervisor-record/report`,
      payload
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },

  getSupervisorTypes: async () => {
    const response = await httpClient.get("/supervisor-type");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorTypeById: async (typeId) => {
    const response = await httpClient.get(`/supervisor-type/id/${typeId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getSupervisorBigNumbers: async (data) => {
    const response = await httpClient.post(`/supervisor-big-numbers`, data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  postSupervisorType: async (data) => {
    const response = await httpClient.post(`/supervisor-type`, {
      fiscal_tipo_descricao: data.fiscal_tipo_descricao,
    });
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  putSupervisorType: async (data) => {
    const response = await httpClient.put(
      `/supervisor-type-users/${data.fiscal_tipo_id}`,
      {
        fiscal_tipo_descricao: data.fiscal_tipo_descricao,
      }
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deleteSupervisorType: async (supervisorId) => {
    const response = await httpClient.delete(
      `/supervisor-type/${supervisorId}`
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
});

<template>
  <div class="details-user">
    <div class="page-header">
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav">{{ $t("report") }}:</span>
        <span v-if="item.usuario_nome" class="page-nav">
          {{ item.usuario_nome }}</span
        >
      </h3>
    </div>

    <p class="subtitle ml-3">
      {{ $t("keepingSectors") }}:
      {{ item.zeladoria_nome || $t("noKeepingSector") }}
    </p>

    <div class="activities-cards ml-3">
      <div v-for="(item, id) of activities" :key="id">
        <div class="activity-card pointer" @click="redirectToList(item)">
          <div class="activiy-icon">
            <img
              :src="item.atividade_icon"
              height="64"
              alt=""
              class="img-border"
            />
          </div>
          <div class="activity-counter">
            <div class="card-title">{{ item.atividade_nome }}</div>
            <div class="card-counter">
              {{
                item.atividade_quantidade !== null
                  ? item.atividade_quantidade.toString() + ""
                  : "0"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ml-3">
      <div class="map-container">
        <p class="map-title">Localização das Atividades</p>
        <GmapMap
          ref="googleMap"
          :center="center"
          :zoom="zoom"
          style="height: 850px; width: 100%"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            mapTypeId: mapType,
            gestureHandling: 'greedy',
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: false,
            disableDefaultUi: true,
          }"
        >
          <div
            v-for="polygon of polygonSector"
            :key="'polygonsKeepingSector' + polygon.id"
          >
            <GmapPolygon
              ref="polygon"
              :paths="polygon.latlngs"
              :options="polygon.options"
            />
            <div v-if="type === 'acao'">
              <GmapCustomMarker
                v-for="photo of eventPhotos"
                :key="photo.usuario_acao_registro_id"
                :draggable="false"
                :marker="photo.center"
              >
                <span class="map-label">{{ photo.photo_url }}</span>
              </GmapCustomMarker>
            </div>
          </div>
        </GmapMap>
        <div class="mapTypeControllers">
          <CSelect
            :placeholder="$t('type')"
            :options="mapTypes"
            :value.sync="mapType"
          ></CSelect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import services from "../../services";
import BackArrow from "../../components/BackArrow.vue";
import { formatForGoogleMaps } from "../../utils/geoMaps";

export default {
  name: "DetailsZelador",
  components: {
    GmapCustomMarker,
    BackArrow,
  },
  data() {
    return {
      zoom: 5,
      center: {
        lat: -15.288423,
        lng: -49.851561,
      },
      bounds: [],
      polygonSector: [],
      eventPhotos: [],
      type: "",
      activities: [],
      loadingData: false,
      item: {},
      mapTypes: [
        { label: this.$t("roadmap"), value: "roadmap" },
        { label: this.$t("sattellite"), value: "satellite" },
        { label: this.$t("hybrid"), value: "hybrid" },
        { label: this.$t("roadmap"), value: "terrain" },
      ],
      mapType: "satellite",
    };
  },
  mounted: async function () {
    await this.requestActivitiesByUser();
    await this.requestUserById();
  },
  methods: {
    async requestUserById() {
      const { data, errors } = await services.users.getUserById({
        userId: this.$route.params.id,
      });
      if (!errors) {
        this.item = data;
        if (data.zeladoria_limite) {
          this.mountPolygon(data.zeladoria_limite);
        }
      }
    },
    async requestActivitiesByUser() {
      const { data, errors } = await services.users.getActivitiesByUser({
        userId: this.$route.params.id,
      });
      if (!errors) {
        this.activities = data.map((item) => {
          return {
            ...item,
          };
        });
      }
    },
    redirectToList(item) {
      this.$router.push(
        `/execucoes/zelador-${item.atividade_id}-${this.$route.params.id}`
      );
    },
    fitBoundsGoogle() {
      this.$refs.googleMap.$mapPromise.then(() => {
        const b = new window.google.maps.LatLngBounds();
        for (let i of this.bounds) {
          b.extend({
            lat: i.lat,
            lng: i.lng,
          });
        }
        this.$refs.googleMap.fitBounds(b);
      });
    },
    mountPolygon(poligono) {
      if (poligono !== "") {
        this.polygonSector = [];
        const coordinatesJSONParse = poligono;
        let bairro = {
          latlngs: formatForGoogleMaps(coordinatesJSONParse[0]),
          options: {
            strokeColor: "#548aba",
            fillColor: "#548aba",
          },
          id: 1,
        };

        bairro.latlngs.map((item) => {
          this.bounds.push(item);
        });

        this.polygonSector.push(bairro);
      }
      this.fitBoundsGoogle();
    },
  },
};
</script>

<style lang="scss" scoped>
.details-user {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .username {
    color: #747474;
    margin: 0 12px;
  }

  .subtitle {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #565e73;
  }

  .map-container {
    position: relative;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 32px;

    .map-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.017em;
      color: #29334e;
      margin-bottom: 32px;
    }

    .mapTypeControllers {
      position: absolute;
      top: 82px;
      left: 18px;
      z-index: 500;
      width: 120px;
    }
  }
}

.activities-cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .activity-card {
    min-width: 292px;
    min-height: 88px;
    border: 1px solid #dddee3;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    .card-counter {
      font-family: "OpenSans-Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #29334e;
      margin: 0;
      padding: 0;
    }

    .card-title {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.014em;
      color: #565e73;
      margin: 0;
      padding: 0;
    }
  }
}
</style>

<template>
  <div class="users-managers">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("usersManagers") }}</span>
    </h3>

    <transition name="bounce">
      <div v-if="!loadingData">
        <div class="d-flex justify-content-between ml-3">
          <div class="w-25">
            <input
              autocomplete="off"
              v-model="searchString"
              class="search-input"
              :search="true"
              :placeholder="$t('searchAnUserManager')"
            />
          </div>
          <div class="pr-4">
            <Button
              class="mb-3"
              size="small"
              :name="$t('newUserManager')"
              @clicked="onclickAdd()"
            />
          </div>
        </div>

        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in usuarios" :key="item.usuario_gestor_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                <span class="table-row-text">{{ item[field.key] }}</span>
              </template>
              <template v-slot:zeladores>
                <Button
                  v-if="
                    item.usuario_gestor_tipo_id === 3 ||
                    item.usuario_gestor_tipo_id === 4
                  "
                  @clicked="redirectToKeepingSectors(item)"
                  class="ml-1 mr-3"
                  size="small"
                  :name="$t('keepingSectors')"
                />
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="usuarios.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>

        <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>

      <Loading v-else />
    </transition>

    <Modal v-show="addingModal" :width="400">
      <template v-slot:header>{{ $t("newUser") }}</template>
      <template v-slot:body>
        <Input name="name" :label="$t('name')" v-model="newUserName" />
        <Input
          class="user-manager-email-input"
          name="name"
          :label="$t('email')"
          v-model="newUserMail"
        />
        <Input
          name="name"
          :label="$t('password')"
          type="password"
          v-model="newUserPassword"
        />
        <p class="modal-label">{{ $t("type") }}</p>
        <CSelect
          :placeholder="$t('type')"
          :options="usersTypes"
          :value.sync="userTypeForCreation"
        ></CSelect>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            type="outline"
            @clicked="addingModal = false"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('save')" @clicked="addUser" />
        </div>
      </template>
    </Modal>

    <Modal v-show="editModal">
      <template v-slot:header>Editar Usuário</template>
      <template v-slot:body>
        <Input :label="$t('name')" v-model="userNameForEdit" />
        <Input
          class="user-manager-email-input"
          :label="$t('email')"
          v-model="userMailForEdit"
        />
        <p class="modal-label">{{ $t("type") }}</p>
        <CSelect
          :placeholder="$t('type')"
          :options="usersTypes"
          :value.sync="userTypeForEdit"
        ></CSelect>
        <div class="row mb-3 mt-4 pl-1">
          <div class="col-2">
            <CustomSwitch
              :selected="isUserActive"
              @checkedSync="isUserActive = !isUserActive"
            />
          </div>
          <div class="col-6 d-flex align-items-center">
            <h4 style="color: #747474; font-size: 16px">
              {{ $t("activeUserManager") }}
            </h4>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <Button
            :name="$t('cancel')"
            type="outline"
            @clicked="editModal = false"
            class="mr-3 w-50"
          />
          <Button class="w-50" :name="$t('save')" @clicked="editUser" />
        </div>
      </template>
    </Modal>

    <Modal
      style="color: #747474"
      :title="$t('deleteUser')"
      v-show="deleteModal"
    >
      <template v-slot:header>{{ $t("deleteUser") }}</template>
      <template v-slot:body>
        {{ $t("confirmDeleteUser") }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <Button
            type="outline"
            class="w-50 mr-2"
            :name="$t('cancel')"
            @clicked="deleteModal = false"
          />
          <Button
            class="w-50"
            :name="$t('save')"
            type="delete"
            @clicked="deleteUser"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import services from "../../services";
import { successToast } from "../../utils/toast";
import { debounce } from "../../utils/debounce";

import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/form/Input.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import TableCell from "../../components/table/TableCell.vue";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CustomSwitch from "../../components/CustomSwitch.vue";

export default {
  name: "CadastroUsuario",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Loading,
    Input,
    BackArrow,
    Button,
    CondensedDropdown,
    Modal,
    CustomSwitch,
  },
  data() {
    return {
      fields: [
        {
          key: "usuario_gestor_nome",
          label: this.$t("name"),
          _style: "min-width:200px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_gestor_email",
          label: this.$t("email"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "tipo_descricao",
          label: this.$t("type"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladores",
          label: "",
          _style: "width:1%",
          sortable: false,
          sortDirection: null,
        },
      ],
      usuarios: [],
      addingModal: false,
      newUserName: "",
      userIdForDelete: null,
      newUserMail: "",
      newUserPassword: "",
      editModal: false,
      userNameForEdit: "",
      userMailForEdit: "",
      userIdForEdit: 0,
      deleteModal: false,
      isUserActive: true,
      institutionNameForDelete: "",
      institutionIdForDelete: 0,
      loadingData: true,
      usersTypes: [],
      userTypeForCreation: null,
      userTypeForEdit: null,
      searchString: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
    };
  },
  mounted: async function () {
    await this.verifyUserRole();
    await this.getUsersTypes();
    await this.getAllUsers();
    this.loadingData = false;
  },
  watch: {
    searchString: debounce(async function (newVal) {
      this.pagination.page = 0;
      if (newVal) {
        this.pagination.filter = newVal;
      } else {
        this.pagination.filter = null;
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset();
        }
      }
      await this.getAllUsers(this.pagination);
    }, 500),
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "delete") {
        this.handleDeleteClick(item);
      }
      if (value === "edit") {
        this.handleEditClick(item);
      }
    },
    optionsByResearch() {
      return [
        { label: "Editar", value: "edit" },
        { label: "Excluir", value: "delete" },
      ];
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.usersManagers.getUsersManagersNew(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item) => {
            return {
              ...item,
              tipo_descricao: this.formatUserType(item.usuario_gestor_tipo_id),
            };
          });
          temp.forEach((item) => {
            this.usuarios.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    handleEditClick(item) {
      this.userIdForEdit = item.usuario_gestor_id;
      this.userNameForEdit = item.usuario_gestor_nome;
      this.userMailForEdit = item.usuario_gestor_email;
      this.userTypeForEdit = item.usuario_gestor_tipo_id;
      item.usuario_gestor_ativo === 1
        ? (this.isUserActive = true)
        : (this.isUserActive = false);
      this.editModal = true;
    },
    handleDeleteClick(item) {
      this.userIdForDelete = item.usuario_gestor_id;
      this.deleteModal = true;
    },
    onclickAdd() {
      this.newUserName = "";
      this.newUserMail = "";
      this.newUserPassword = "";
      this.addingModal = true;
    },
    redirectToKeepingSectors(item) {
      this.$router.push(`/monitor/zeladorias/${item.usuario_gestor_id}`);
    },
    async addUser() {
      const { errors } = await services.usersManagers.addUserManager({
        usuario_gestor_nome: this.newUserName,
        usuario_gestor_email: this.newUserMail.toLowerCase(),
        usuario_gestor_senha: this.newUserPassword,
        usuario_gestor_tipo_id: this.userTypeForCreation,
      });
      if (!errors) {
        await this.getAllUsers();
        this.newUserName = "";
        this.newUserMail = "";
        this.newUserPassword = "";
        successToast(this.$t("successAddUser"));
        this.addingModal = false;
      }
    },
    async deleteUser() {
      const { errors } = await services.usersManagers.deleteUserManager({
        userId: this.userIdForDelete,
      });
      if (!errors) {
        await this.getAllUsers();
        this.deleteModal = false;
        successToast(this.$t("successDeleteUser"));
      }
    },
    async editUser() {
      const { errors } = await services.usersManagers.editUserManager({
        usuario_gestor_id: this.userIdForEdit,
        usuario_gestor_nome: this.userNameForEdit,
        usuario_gestor_email: this.userMailForEdit.toLowerCase(),
        usuario_gestor_ativo: this.isUserActive ? 1 : 0,
        usuario_gestor_tipo_id: this.userTypeForEdit,
      });
      if (!errors) {
        await this.getAllUsers();
        this.userNameForEdit = "";
        this.userMailForEdit = "";
        this.userIdForEdit = 0;
        successToast(this.$t("successEditUserManager"));
        this.editModal = false;
      }
    },
    async getAllUsers() {
      const { data, errors } = await services.usersManagers.getUsersManagersNew(
        this.pagination
      );
      if (!errors) {
        this.usuarios = data.map((item) => {
          return {
            ...item,
            tipo_descricao: this.formatUserType(item.usuario_gestor_tipo_id),
          };
        });
      }
    },
    formatUserType(userType) {
      const userTypeDescription = this.usersTypes.find(
        (item) => item.value === userType
      );
      return userTypeDescription ? userTypeDescription.label : "";
    },
    async getUsersTypes() {
      const { data, errors } = await services.usersManagers.userManagersTypes();
      if (!errors) {
        this.usersTypes = data.map((item) => {
          return {
            value: item.usuario_gestor_tipo_id,
            label: item.usuario_gestor_tipo_descricao,
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-managers {
  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }

  .table-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
}
.search-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #c6c9d0;
  box-sizing: border-box;
  border-radius: 12px;
  width: 500px;
  height: 48px;

  &:focus {
    outline: none;
    border: 1px solid #00264f;
  }

  &:placeholder {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #b0b4bd;
  }
}
</style>
<style>
.user-manager-email-input.input-wrapper > .input {
  text-transform: lowercase !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
</style>

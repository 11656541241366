var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-neighborhood"},[_c('h3',{staticClass:"intern-title ml-3"},[_c('back-arrow'),_c('span',{staticClass:"page-nav"},[_vm._v(_vm._s(_vm.$t("reportNeighborhood"))+":")]),(!_vm.loadingData)?_c('span',{staticClass:"page-nav"},[_vm._v(" "+_vm._s(_vm.details.info.bairro_nome))]):_vm._e()],1),_c('div',{staticClass:"activities-cards ml-3"},_vm._l((_vm.activities),function(item,id){return _c('div',{key:id},[_c('div',{staticClass:"activity-card pointer",on:{"click":function($event){return _vm.redirectToList(item)}}},[_c('div',{staticClass:"activiy-icon"},[_c('img',{staticClass:"img-border",attrs:{"src":item.atividade_icon,"height":"64","alt":""}})]),_c('div',{staticClass:"activity-counter"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.atividade_nome))]),_c('div',{staticClass:"card-counter"},[_vm._v(" "+_vm._s(item.atividade_quantidade !== null ? item.atividade_quantidade.toString() + "" : "0")+" ")])])])])}),0),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"map-container"},[_c('div',{staticClass:"map-title"},[_vm._v("Localização das atividades")]),_c('GmapMap',{ref:"googleMap",staticStyle:{"height":"460px","width":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          mapTypeId: _vm.mapType,
          gestureHandling: 'greedy',
          streetViewControl: false,
          rotateControl: true,
          fullscreenControl: false,
          disableDefaultUi: true,
        }}},_vm._l((_vm.polygonSector),function(polygon){return _c('div',{key:'polygonsKeepingSector' + polygon.id},[_c('GmapPolygon',{ref:"polygon",refInFor:true,attrs:{"paths":polygon.latlngs,"options":polygon.options}})],1)}),0),_c('div',{staticClass:"mapTypeControllers"},[_c('CSelect',{attrs:{"placeholder":_vm.$t('type'),"options":_vm.mapTypes,"value":_vm.mapType},on:{"update:value":function($event){_vm.mapType=$event}}})],1)],1)]),_c('CRow',{ref:"myDetails",staticClass:"mb-4"},[(_vm.loadingEvent)?_c('CCol',{staticClass:"mt-4 mb-4",attrs:{"col":"12","sm":"12","lg":"12"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-4 mb-4 fixed-size"},[_c('CSpinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"color":"primary"}})],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
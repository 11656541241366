<template>
  <div class="chat-container">
    <ChatList
      :propChats="chatList"
      :loading="loadingData"
      @open-messages="mountMessages"
    />
    <ChatBox
      :messages="messages"
      :vizinhos="vizinhos"
      :bairro="bairro"
      :zelador="zelador"
      :az="az"
    />
  </div>
</template>

    <script>
import services from "../../services";
import ChatList from "../../components/chat/chatList.vue";
import ChatBox from "../../components/chat/chatBox.vue";

export default {
  name: "Zeladores",
  components: {
    ChatList,
    ChatBox,
  },
  data() {
    return {
      loadingData: false,
      chatList: [],
      messages: [],
      vizinhos: 0,
      zelador: "-",
      bairro: "-",
      az: "-",
    };
  },
  mounted: async function () {
    this.loadingData = true;
    await this.mountChatList();
    //await this.getOrders(this.pagination);
    this.loadingData = false;
  },
  methods: {
    async mountChatList() {
      const { data, errors } = await services.chat.getChatList();
      if (!errors) {
        this.chatList = data;
      }
    },
    async mountMessages(selectedChat) {
      const { data, errors } = await services.chat.getChatMessages(
        selectedChat.zeladoria_id
      );
      if (!errors) {
        this.messages = data.data.map((item) => {
          return {
            ...item,
            check: false,
          };
        });
        this.vizinhos = data.qty_vizinhos;
        this.bairro = selectedChat.bairro_nome;
        this.az = selectedChat.zeladoria_nome;
        this.zelador = selectedChat.usuario_nome;
      }
    },

    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.serviceOrder.getOrdersNew(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          let temp = data.map((item, id) => {
            return item, id;
          });
          temp.forEach((item) => {
            this.orders.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>

  <style lang="scss">
.chat-container {
  display: flex;
  flex-direction: row;
  margin: -20px !important;
  margin-top: -30px !important;
}

@media screen and (min-width: 1px) and (max-width: 800px) {
  .chat-container {
    display: flex;
    flex-direction: column;
  }
}
</style>

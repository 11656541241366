<template>
  <tr class="table-row">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "table-row",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

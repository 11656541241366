<template>
  <div class="policies-form">
    <h3
      v-if="this.$route.params.tipo === 'editar'"
      class="ml-3 intern-title mb-1"
    >
      <back-arrow />
      <span class="page-nav"
        >{{ $t("editVersion") }}
        <span
          class="subtitle"
          v-if="policyStatus === 3 || policyStatus === 2"
          >{{ $t("dontAllowEdit") }}</span
        ></span
      >
    </h3>

    <h3
      v-if="this.$route.params.tipo === 'cadastro'"
      class="ml-2 intern-title mb-1"
    >
      <back-arrow />
      <span class="pagetitle pl-2">{{ $t("newVersion") }}</span>
    </h3>

    <transition name="bounce">
      <div v-if="!loadingData">
        <div class="mr-4 ml-3 mb-4 mt-4">
          <CForm>
            <CRow class="d-flex align-items-center justify-content-center">
              <CCol sm="8">
                <p style="color: #747474; font-size: 1rem">{{ $t("title") }}</p>
                <input
                  class="form-control"
                  :placeholder="$t('title')"
                  :label="$t('title')"
                  v-model="policy.contrato_titulo"
                />
              </CCol>

              <CCol sm="4">
                <p style="color: #747474; font-size: 1rem">
                  {{ $t("startOfValidity") }}
                </p>
                <v-date-picker v-model="policy.contrato_data_inicio_vigencia">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :placeholder="$t('startOfValidity')"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </CCol>
            </CRow>

            <CRow class="d-flex align-items-center justify-content-center mt-2">
              <CCol sm="12">
                <p style="color: #747474; font-size: 1rem">{{ $t("text") }}</p>
                <vue-editor
                  class="editor"
                  :disabled="policyStatus === 3 || policyStatus === 2"
                  v-model="policy.contrato_texto"
                ></vue-editor>
              </CCol>
            </CRow>

            <CRow
              class="d-flex align-items-center justify-content-center mt-4"
              v-if="this.$route.params.tipo === 'editar'"
            >
              <CCol sm="12">
                <p style="color: #747474; font-size: 1rem">
                  {{ $t("descriptionOfVersion") }}
                </p>
                <vue-editor
                  :disabled="policyStatus === 3 || policyStatus === 2"
                  class="editor"
                  v-model="policy.contrato_descricao_versao"
                ></vue-editor>
              </CCol>
            </CRow>

            <CRow class="mt-4 mb-2 d-flex justify-content-end">
              <Button type="outline" @clicked="backPage" :name="$t('back')" />
              <Button
                class="ml-2"
                :name="$t('save')"
                :disabled="policyStatus === 3 || policyStatus === 2"
                @clicked="savePolicy"
              />
            </CRow>
          </CForm>
        </div>
      </div>
    </transition>
    <div
      v-if="loadingData"
      class="d-flex justify-content-center align-items-center mt-2 mb-4"
    >
      <img
        :src="require(`@/assets/img/loading.gif`)"
        class="mb-4 pday-resume-box-galleryb-4"
        alt="AMA carregando"
        width="150"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VueEditor } from "vue2-editor";
import BackArrow from "../../components/BackArrow.vue";
import services from "../../services";
import Button from "../../components/Button.vue";

export default {
  name: "PolicyForm",
  components: {
    VueEditor,
    BackArrow,
    Button,
  },
  data() {
    return {
      loadingData: true,
      subcontractorsTypes: [],
      inputValue: null,
      inputEvents: null,
      policyStatus: null,
      policy: {
        contrato_titulo: "",
        contrato_texto: "",
        contrato_data_inicio_vigencia: "",
        usuario_id: null,
        contrato_descricao_versao: null,
        contrato_id: null,
      },
    };
  },
  mounted: async function () {
    if (this.$route.params.tipo === "editar") {
      await this.initPolicyById();
    }
    this.loadingData = false;
  },
  methods: {
    async verifyUserRole() {
      if (this.$store.state.global.user.usuario_gestor_tipo_id === 3) {
        this.$router.push("/dashboard");
      }
    },
    async initPolicyById() {
      const { data, errors } = await services.policies.getPolicyById({
        id: this.$route.params.id,
      });
      if (!errors) {
        this.policyStatus = data.status_contrato_id;
        this.policy.contrato_titulo = data.contrato_titulo;
        this.policy.contrato_texto = data.contrato_texto;
        this.policy.contrato_data_inicio_vigencia =
          data.contrato_data_inicio_vigencia;
        this.policy.contrato_descricao_versao = data.contrato_descricao_versao;
        this.policy.contrato_id = data.contrato_id;
      }
    },
    async savePolicy() {
      if (this.$route.params.tipo === "editar") {
        this.editPolicy();
      } else {
        this.addPolicy();
      }
    },
    async editPolicy() {
      this.formatDateToSave();
      const { errors } = await services.policies.updatePolicy(this.policy);
      if (!errors) {
        this.validationToast(
          {
            data: {
              message: this.$t("successEditVersion"),
            },
          },
          "success"
        );
        this.$router.push("/politicas");
      }
    },
    formatDateToSave() {
      const date = new Date(this.policy.contrato_data_inicio_vigencia);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      this.policy.contrato_data_inicio_vigencia = `${year}-${month}-${day} 00:00:01`;
    },
    async addPolicy() {
      this.formatDateToSave();
      this.policy.usuario_id = this.$store.state.global.user.usuario_gestor_id;
      const { errors } = await services.policies.createPolicy(this.policy);
      if (!errors) {
        this.validationToast(
          {
            data: {
              message: this.$t("successCreateVersion"),
            },
          },
          "success"
        );
        this.$router.push("/politicas");
      }
    },
    backPage() {
      this.$router.push("/politicas");
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.policies-form {
  background-color: #fff;
  border-radius: 8px;

  .pagetitle {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 20px;
    line-height: 166.5%;
    color: #043464;
  }
}

.subtitle {
  color: red;
  font-size: 50%;
}

.editor {
  height: 30rem;
  overflow: hidden;
  border-color: #747474;
  color: #747474;
}

.form-control {
  width: 99%;
  height: 48px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 12px;
  color: #747474;
  padding-left: 15px;
  color: #000;
}

.form-control:focus {
  outline: none;
}
</style>

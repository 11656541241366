export default httpClient => ({
  getUsers: async (payload) => {
    const response = await httpClient.post('/funil/zelador', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  getUsersFull: async (payload) => {
    const response = await httpClient.post('/funil/zelador/full', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getVinculatedUsersCount: async (dates) => {
    const response = await httpClient.post('/funil/lead/zeladores-vinculados/count', {
      data_inicio: dates.start,
      data_fim: dates.end
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getAmaLeads: async (payload) => {
    const response = await httpClient.post('/funil/ama', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getAmaLeadsFull: async (payload) => {
    const response = await httpClient.post('/funil/ama/full', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getAmaLeadsCount: async (dates) => {
    const response = await httpClient.post('/funil/lead/ama-cidade/count', {
      data_inicio: dates.start,
      data_fim: dates.end
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getAmaLeadsLpCount: async (dates) => {
    const response = await httpClient.post('/funil/lead/ama-lp/count', {
      data_inicio: dates.start,
      data_fim: dates.end
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getAmaLeadsFitBankCount: async (dates) => {
    const response = await httpClient.post('/funil/lead/ama-conta-digital/count', {
      data_inicio: dates.start,
      data_fim: dates.end
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getLeads: async (payload) => {
    const response = await httpClient.post('/funil/leads', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getLeadsFull: async (payload) => {
    const response = await httpClient.post('/funil/leads/full', payload)
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  getLeadsCount: async (dates) => {
    const response = await httpClient.post('/funil/leads/count', {
      data_inicio: dates.start,
      data_fim: dates.end
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
})

<template>
  <div>
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ details.info.zeladoria_nome }}</span>
    </h3>

    <div class="image-container">
      <div v-if="show === true">
        <div
          @click="$refs.file.click()"
          v-if="!keepingSectors"
          class="image-not-registered"
        >
          <p class="noImage">{{ $t("ImageNotRegistered") }}</p>
          <img
            class="upload-icon"
            src="../../assets/img/Vector.png"
            alt="icon"
          />
          <p class="upload-link">{{ $t("uploadImage") }}</p>
        </div>

        <div class="" v-else>
          <img
            id="Image"
            class="imagemAZ"
            :src="keepingSectors"
            alt="imagem-aqui"
          />
          <div class="button-div">
            <button @click="$refs.file.click()">
              {{ $t("changeImage") }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="show === false" class="cropper-box">
        <cropper
          v-if="image.src"
          @change="crop"
          class="cropper"
          ref="cropper"
          :src="image.src"
          :stencil-props="stencilProps"
          :stencil-size="stencilSize"
          image-restriction="stencil"
        />
      </div>
      <input
        type="file"
        accept="image/png, image/jpeg"
        @change="loadImage($event)"
        class="d-none"
        ref="file"
      />
    </div>

    <div v-if="show === false" class="button-div">
      <!-- <div>
                <input @change="handleImageUpload" id="btn" type="file" accept="image/png, image/jpeg" hidden>
                <label for="btn">{{ $t("loadFile") }}</label>
                <span id="message">{{ $t("noFileSelected") }}</span>
              </div> -->
      <button class="mr-2" @click="cancelar()">{{ $t("cancel") }}</button>
      <button @click="salvarImagem()">{{ $t("send") }}</button>
    </div>
  </div>
</template>

  <script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import BackArrow from "../../components/BackArrow.vue";
import services from "../../services";
import { blobToBase64 } from "../../utils/blobToBase64.js";

export default {
  name: "DetailsKeepingSector",
  components: {
    BackArrow,
    Cropper,
  },
  data() {
    return {
      show: true,
      stencilProps: {
        handlers: {},
        movable: false,
        scalable: false,
      },
      stencilSize: {
        width: 500,
        height: 500,
      },
      details: {
        info: {},
      },
      image: {
        src: null,
        type: null,
      },
      finalImage: null,
      loadingData: true,
      print: null,
      keepingSectors: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
        keepingSectorId: null,
        newIMG: null,
      },
    };
  },
  mounted: async function () {
    await this.getKeepingSectorImage();
    await this.requestKeepingSectorDetails();
    await this.chooseFile();
    this.loadingData = false;
  },
  methods: {
    async cancelar() {
      this.show = true;
    },
    async requestKeepingSectorDetails() {
      const { data, errors } =
        await services.keepingSectors.getDetailsKeepingSector({
          keepingSectorId: this.$route.params.id,
        });

      if (!errors) {
        this.details.info = data[0];
      }
    },
    async chooseFile() {
      const botao = document.getElementById("btn");
      const message = document.getElementById("message");
      botao.addEventListener("change", function () {
        message.textContent = this.files[0].name;
      });
    },
    async getKeepingSectorImage() {
      const { data, errors } = await services.keepingSectors.getPrintZelador({
        keepingSectorId: this.$route.params.id,
      });
      if (!errors) {
        console.log(data);
        this.keepingSectors = data.zeladoria_print;
      }
    },
    async salvarImagem() {
      this.show = true;
      const imageAZ = {
        zeladoria_id: this.$route.params.id,
        zeladoria_print: this.finalImage,
      };
      await services.keepingSectors.putImageAz(imageAZ);
      await this.getKeepingSectorImage();
    },

    async handleImageUpload({ target }) {
      await blobToBase64(target.files[0]).then((base64) => {
        this.newIMG = base64;
      });
    },
    async crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob(async (blob) => {
        this.blobToBase64(blob).then((base64) => {
          this.finalImage = base64;
        });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          this.finalImage = base64data;
        };
      }, this.image.type);
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    loadImage(event) {
      this.show = false;
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: this.getMimeType(e.target.result, files[0].type),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    getMimeType(file, fallback = null) {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = "";
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#Image {
  margin: 32px 0px;
}
.image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.button-div {
  display: flex;
  justify-content: center;

  button {
    background: #003366;
    padding: 8px 24px;
    width: fit-content;
    height: 32px;
    color: #fff;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    border: none;
    border-radius: 8px;
    text-align: center;
  }
}

.upload-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #00b1eb;
  font-weight: bold;
  cursor: pointer;
}
.upload-icon {
  width: 70px;
  margin: 21px;
}
.image-not-registered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dotted #00b1eb;
  background: #e6f7fd33;
  border-radius: 16px;
  width: 1216px;
  height: 424px;
}
.cropper-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  .cropper {
    width: 1216px;
    height: 424px;
    border-radius: 16px;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  .cropper:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.hidden {
  display: hidden;
}
.show {
  display: flex;
}
.noImage {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #838998;
  margin: 0px;
}
.imagemAZ {
  width: 500px;
  height: 500px;
  border-radius: 16px;
  border: 1px solid rgb(87, 87, 188);
  img {
    margin: 0;
  }
}
</style>

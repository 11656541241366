import axios from "axios";
import router from "../router";
import store from "../store";

import { decrypt } from "../utils/decrypt";

import AuthService from "./auth";
import UsersService from "./users";
import ExecutionsService from "./executions";
import ActivitiesService from "./activities";
import KeepingSectorsService from "./keepingSectors";
import TagsService from "./tags";
import NeighborhoodsService from "./neighborhoods";
import InstitutionsService from "./institutions";
import UsersManagers from "./usersManagers";
import UsersSupervisors from "./usersSupervisors";
import Subcontractors from "./subcontractors";
import ImportData from "./importData";
import Config from "./config";
import Policies from "./policies";
import Statistics from "./statistics";
import Candidates from "./candidates";
import contracts from "./contracts";
import analytics from "./analytics";
import analyticsMonitores from "./analytics";
import serviceOrder from "./serviceOrder";
import admReport from "./admReport";
import Researches from "./researches";
import chat from "./chat";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});

httpClient.interceptors.request.use((config) => {
  store.dispatch("global/setLoading", true);

  const encrypted = localStorage.getItem("ama");

  if (encrypted) {
    const decrypted = decrypt(encrypted);
    const userData = JSON.parse(decrypted);
    config.headers.common.Authorization = `Bearer ${userData.usuario_token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    store.dispatch("global/setLoading", false);
    return response;
  },
  (error) => {
    const canThrowAnError =
      error.request.status === 0 || error.request.status === 500;

    if (canThrowAnError) {
      store.dispatch("global/setLoading", false);
      throw new Error(error.message);
    }

    if (error.response.status === 401) {
      store.dispatch("global/setLoading", false);
      localStorage.removeItem("ama");
      router.push({ name: "login" });
    }

    store.dispatch("global/setLoading", false);
    return error;
  }
);

export default {
  auth: AuthService(httpClient),
  users: UsersService(httpClient),
  executions: ExecutionsService(httpClient),
  activities: ActivitiesService(httpClient),
  keepingSectors: KeepingSectorsService(httpClient),
  tags: TagsService(httpClient),
  neighborhoods: NeighborhoodsService(httpClient),
  institutions: InstitutionsService(httpClient),
  usersManagers: UsersManagers(httpClient),
  importData: ImportData(httpClient),
  subcontractors: Subcontractors(httpClient),
  policies: Policies(httpClient),
  config: Config(httpClient),
  statistics: Statistics(httpClient),
  candidates: Candidates(httpClient),
  contracts: contracts(httpClient),
  analytics: analytics(httpClient),
  analyticsMonitores: analyticsMonitores(httpClient),
  serviceOrder: serviceOrder(httpClient),
  admReport: admReport(httpClient),
  researches: Researches(httpClient),
  chat: chat(httpClient),
  UsersSupervisors: UsersSupervisors(httpClient)
};

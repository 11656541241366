<template>
  <div class="d-inline arrow">
    <svg
      @click="handleBack"
      class="pointer"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.828 6.99968H16V8.99968H3.828L9.192 14.3637L7.778 15.7777L0 7.99968L7.778 0.22168L9.192 1.63568L3.828 6.99968Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "BackArrow",
  props: {
    color: {
      type: String,
      default: "#29334E",
    },
    goTo: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleBack() {
      if (!this.goTo) {
        this.$emit("back");
        this.$router.go(-1);
        return;
      } else {
        this.$router.push(this.goTo);
      }
    },
  },
};
</script>

<style scoped>
.arrow {
  margin-bottom: 5px;
}
</style>

<template>
  <Modal :width="600">
    <template v-slot:header>
      <div class="w-100 d-flex justify-content-between">
        <p class="contract-modal-title ml-1">
          Contratos & distratos disponíveis
        </p>
        <span @click="$emit('close')" class="material-icons-outlined pointer"
          >close</span
        >
      </div>
    </template>
    <template v-slot:body>
      <div v-if="userContracts.length > 0" class="download-options">
        <div
          class="option"
          v-for="contract in userContracts"
          :key="contract.usuario_contrato_id"
        >
          <div class="description">
            <span class="type" style="margin-right: 40px">
              {{ contract.contrato_zelador_tipo_descricao }}
            </span>
            <span class="date">
              {{ contract.usuario_contrato_data }}
            </span>
          </div>
          <div class="button">
            <a :href="contract.usuario_contrato_url" target="_blank">
              <div>Download</div>
              <span class="material-icons pl-3">download</span>
            </a>
          </div>
        </div>
      </div>
      <div class="no-contracts ml-1" v-else>
        Nenhum contrato ou distrato encontrado
      </div>
    </template>
    <template v-slot:footer>
      <Button
        name="Fechar"
        @clicked="$emit('close')"
        class="w-100 cancel-download"
        size="small"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import Button from "../../../components/Button.vue";

export default {
  name: "ContractsModal",
  components: {
    Modal,
    Button,
  },
  props: {
    userContracts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.contract-modal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #131e3b;
}

.download-options {
  .option {
    font-size: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddee3;
    padding: 8px;

    &:last-child {
      border-bottom: none;
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: space-between;
      color: #565e73;

      .type {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #131e3b;
      }

      .date {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
      }
    }

    .button {
      background: #003063;
      border-radius: 6px;
      cursor: pointer;

      a {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 20px;
        padding: 6px 8px;
        padding-left: 14px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        text-decoration: none;
        height: 100%;
      }

      span {
        background: transparent;
      }
    }
  }
}

.cancel-download {
  background: white;
  color: #131e3b;
  border: 2px solid #dddee3;
}

.no-contracts {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #565e73;
}
</style>

<template>
  <div>
    <div
      v-if="loadingData"
      style="width: 100%; display: flex; justify-content: center"
    >
      <Loading />
    </div>
    <div v-else class="big-numbers">
      <div
        v-for="number in bigNumbers"
        :key="number.atividade_id"
        class="number-card"
      >
        <div>
          <div class="title">{{ number.atividade_nome }}</div>
          <div class="number">{{ number.execucoes }}</div>
        </div>
        <img class="icon" :src="number.atividade_icon" />
      </div>
    </div>
  </div>
</template>

<script>
import services from "../../services";
import Loading from "../../components/IndexComponents/Loading.vue";

export default {
  components: {
    Loading,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    dates: {
      type: Object,
      default: () => {},
    },
  },
  mounted: async function () {
    this.loadingData = true;

    const { data } = await services.users.getUserBigNumbers({
      initialDate: this.dates.start,
      finalDate: this.dates.end,
      userId: this.user.usuario_id,
    });
    this.bigNumbers = data;
    this.loadingData = false;
  },
  data() {
    return {
      loadingData: false,
      bigNumbers: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.big-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  .number-card {
    display: flex;
    flex-direction: row;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px !important;
    color: #565e73 !important;
    padding: 24px;
    border: 1px solid lightgrey;
    border-radius: 24px;
    width: 32%;
    justify-content: space-between;
    .number {
      color: #000;
      font-size: 20px;
    }
    .title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #131e3b;
      margin-bottom: 12px;
    }
    .icon {
      width: 80px;
      height: 80px;
      padding: 12px;
      border: 1px solid lightgray;
      border-radius: 50%;
    }
  }
}
@media screen and (min-width: 554px) and (max-width: 1083px) {
  .big-numbers {
    .number-card {
      width: 48%;
    }
  }
}
@media screen and (min-width: 1px) and (max-width: 553px) {
  .big-numbers {
    .number-card {
      width: 95%;
    }
  }
}
</style>

<template>
  <div class="activity-gallery">
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("gallery") }}: {{ activityName }}</span>
    </h3>
    <div class="cards-list ml-3" v-if="activities.length > 0">
      <div v-for="(item, $index) of activities" :key="$index">
        <div class="card">
          <a @click="photoIndex = $index"
            ><img :src="item.execucao_timeline_foto"
          /></a>
          <div @click="redirectToDetails(item)" class="card-content">
            <div class="content-info">
              <div class="card-title">{{ item.tag_descricao }}</div>
              <div class="card-subtitle">
                {{ item.data_formated_created }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-3" v-else>
      <p class="page-nav p-2">{{ $t("noPhotoFound") }}</p>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner">
        <Loading />
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <CoolLightBox
      v-if="photos.length > 0"
      :items="photos"
      :index="photoIndex"
      @close="photoIndex = null"
    />
  </div>
</template>

<script>
import services from "../../services";
import BackArrow from "../../components/BackArrow.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { formatDate, formatTime } from "../../utils/date";

export default {
  name: "GalleryActivity",
  components: {
    BackArrow,
    Loading,
    CoolLightBox,
  },
  data() {
    return {
      activityId: null,
      activityName: null,
      pagina: 1,
      activities: [],
      photoIndex: null,
      photos: [],
    };
  },
  mounted: async function () {
    this.activityId = this.$route.params.id;
    const activitiesList = await services.activities.getActivities();
    const activity = activitiesList.data.find((activity) => {
      return activity.atividade_id === +this.$route.params.id;
    });
    this.activityName = activity.atividade_nome;
  },
  methods: {
    redirectToDetails(item) {
      this.$router.push({
        name: "DetalheExecucao",
        params: { execucao_uuid: item.execucao_uuid },
      });
    },
    async infiniteHandler($state) {
      const { data, errors } = await services.executions.getPhotosByActivity({
        activityId: this.activityId,
        page: this.pagina,
      });
      if (!errors) {
        if (data.length > 0) {
          this.pagina++;
          let temp = data.map((item) => {
            return {
              ...item,
              data_formated_created: `
              ${formatDate(item.execucao_data_criacao)} •
              ${formatTime(item.execucao_data_criacao)}`,
            };
          });

          temp.forEach((item, index) => {
            this.activities.push(item);
            this.photos.push({
              src: item.execucao_timeline_foto,
              title: item.atividade_nome,
              description: item.data_formated_created,
              imageIndex: index,
            });
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-gallery {
  .cards-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }

  .card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: fit-content;
    box-shadow: (0px 4px 4px rgba(160, 168, 189, 0.24));
    border-radius: 8px;

    img {
      width: 286px;
      max-width: 286px;
      border-radius: 8px 8px 0 0;
    }

    .card-content {
      padding: 24px 16px;
      width: 100%;
      .card-title {
        padding: 2px 8px;
        background: #dddee3;
        border-radius: 4px;
        color: #565e73;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 156%;
        width: fit-content;
      }

      .card-subtitle {
        font-family: "OpenSans-Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.006em;
        color: #565e73;
        margin-top: 4px;
      }
    }
  }
}
</style>

<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
@import "assets/scss/style";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

.route-enter-from {
  opacity: 0;
  transform: translateY(1000px);
}
.route-enter-active,
.route-leave-active {
  transition: all 2s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateY(1000px);
}

.c-active {
  background-color: #89cc49 !important;
}

html {
  scroll-behavior: smooth;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl:not(.c-sidebar-right) {
  margin-left: 0px !important;
}

::-webkit-scrollbar-track {
  background: #fff !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #dddee3;
}

.no-pointer {
  cursor: default !important;
}

.map-label {
  color: black;
  font-size: 10px;
  font-weight: bold;
  background: #fff;
  padding: 4px;
  border-radius: 10px;
  opacity: 0.7;
  z-index: 0;
}

.c-header-toggler {
  color: black !important;
}

.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  color: #4f0dcc !important;
}

.quillWrapper {
  border: 1px solid #4f0dcc;
  border-radius: 10px;
  color: #4f0dcc;
}

.sidebar-system {
  background-color: #003366 !important;
}

.ql-toolbar.ql-snow {
  border: 0px solid #c4c4c4 !important;
  border-bottom: 1px solid #c4c4c4 !important;
}

.ql-toolbar {
  border-radius: 10px 10px 0px 0px;
}

.ql-editor {
  color: #000 !important;
}

.ql-container {
  border-radius: 0px 0px 10px 10px;
}

.delete-functions {
  .quillWrapper .ql-snow.ql-toolbar {
    padding-top: 8px;
    padding-bottom: 4px;
    display: none !important;
  }
}

.multiselect__tag {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  background: #dddee3 !important;
  color: #131e3b !important;
}

.multiselect__single {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 206% !important;
  color: #131e3b !important;
}

.multiselect__tags {
  min-height: 44px !important;
  border-radius: 12px !important;
  border: 1px solid #c6c9d0 !important;
}

.multiselect__option--highlight,
.multiselect__option--selected {
  background: #f3f4f5 !important;
  color: #131e3b !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  color: #f3f4f5 !important;
  text-transform: uppercase;
  background-color: #f3f4f5 !important;
}

.multiselect__option--highlight::after {
  background: #61b2e5 !important;
}

.multiselect__option--selected:after {
  color: #f3f4f5 !important;
  text-transform: uppercase;
}

.multiselect__input::placeholder {
  font-family: "Open Sans" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 206% !important;
  color: #838998 !important;
}

.multiselect__option {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #565e73;
}

.multiselect__tag-icon {
  color: #131e3b !important;
  &::after {
    color: #131e3b !important;
  }
  &:hover {
    background: none !important;
    color: #131e3b !important;
  }
}

.fc-button {
  background: #61b2e5 !important;
}

.multiselect__tags {
  border: 1px solid #c4c4c4 !important;
  background: #fff;
}

.modal-title {
  color: white !important;
}

.fc-daygrid-event {
  cursor: pointer !important;
}

.fc-toolbar-title::first-letter {
  text-transform: capitalize;
}

.fc-toolbar-title {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 132%;
  color: #565e73;
}

.fc-toolbar-chunk {
  margin-left: 72px !important;
}

.fc-daygrid-day-top {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}

.fc-prev-button,
.fc-next-button {
  color: #565e73 !important;
}

.fc-timeGridDay-button {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}

.multiselect__single {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
}

.multiselect__select {
  border-radius: 12px !important;
  background: #fff !important;
}

.multiselect--disabled {
  border-radius: 12px !important;
}

.fc thead {
  height: 48px !important;
}

.form-control:disabled {
  background-color: #fff !important;
}

.card-header {
  border-color: #fff !important;
  margin-top: 10px;
}

.card {
  border: none !important;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}

.vue-star-rating-rating-text {
  display: none !important;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

.vc-pane-container {
  border-radius: 8px !important;
  box-shadow: none !important;
}

.vc-container {
  --blue-50: #8aa0b7 !important;
  --blue-100: #8aa0b7 !important;
  --blue-200: #e6eaef !important;
  --blue-300: #8aa0b7 !important;
  --blue-400: #8aa0b7 !important;
  --blue-500: #8aa0b7 !important;
  --blue-600: #8aa0b7 !important;
  --blue-700: #131e3b !important;
  --blue-800: #131e3b !important;
  --blue-900: #131e3b !important;
}

.vc-day-content .vc-focusable {
  font-family: "Open Sans" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 156% !important;
}

.vc-weekday {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.vc-day {
  font-family: "Roboto-Regular" !important;
  color: #606060 !important;
}

.vc-svg-icon {
  margin-top: 10px !important;
  color: #61b2e5 !important;
}

.vc-header {
  padding: 10px !important;
  margin-top: 10px;
}

.vc-title {
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.timeline-circle {
  top: -0.72em !important;
  left: -33.5px !important;
}

.modal-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  color: #838998;
  margin-top: 8px;
  margin-bottom: 8px !important;
}

.intern-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  line-height: 176.5%;
  color: #29334e;
}

.secondary-button {
  font-family: "OpenSans-Regular";
  background: #fff;
  border-radius: 8px;
  font-weight: 600;
  color: #003366;
  padding: 10px;
  border: 1px solid #003366;
}

.primary-button {
  font-family: "OpenSans-Regular";
  background: #003366;
  border-radius: 8px;
  font-weight: 600;
  color: #fff;
  padding: 10px;
  border: none;
}

.table-row-text {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 166.5%;
  color: #747474;
}

select {
  &:focus {
    border: 1px solid #c8e9fe !important;
  }
  border: 1px solid #c6c9d0;
  background: #fff !important;
  border-radius: 12px !important;
  height: 48px !important;
}

tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
  background-color: #bde6ff !important;
  display: flex;
  .fc-col-header-cell.fc-day {
    height: 48px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
    vertical-align: middle;
    text-align: center;
    font-size: 1rem;
  }
}

.fc-daygrid-day-top {
  display: flex !important;
  justify-content: flex-end !important;
  font-size: 1.2rem;
  padding: 16px 0 0 16px;
}

.fc-daygrid-day-number {
  color: #747474 !important;
}

.fc-daygrid-day-events {
  padding: 0 0 0 16px !important;
}

.pagetitle {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 132% !important;
  color: #131e3b !important;

  position: relative !important;
  top: 3px !important;
}

.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
  height: 30px !important;
  font-size: 1rem !important;
  color: #747474;
}

.fc-day-today {
  background-color: #bde7ff30 !important;
}

.fc-toolbar {
  display: flex !important;
  justify-content: space-between !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #bde6ff !important;
}

.fc-toolbar-chunk div {
  display: flex;
  justify-content: center;
  align-items: center;
  .fc-button.fc-prev-button,
  .fc-button.fc-next-button {
    border: none;
    color: #003366;
    font-weight: bold;
    font-size: 1rem;
    background: none !important;
    margin: 0 10px;
    &:focus {
      border: none !important;
      box-shadow: none !important;
      color: #003366;
    }
  }
}

.fc-toolbar-chunk .fc-button-group {
  .fc-button {
    background-color: #fff !important;
    color: #747474;
    border: 1px solid #c4c4c4 !important;
    text-transform: capitalize;
    font-size: 1.2rem;
    &:hover {
      color: #747474 !important;
      background: #c4c4c4 !important;
    }
  }
  .fc-button-active {
    color: #747474 !important;
    background: #ddd !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .fc-timeGridMonth-button {
    border-right: none !important;
  }
}

.fc-timegrid-slot {
  border-color: #61b2e5 !important;
  .fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame {
    color: #747474 !important;
    font-size: 1rem;
  }
}

.fc-timegrid-slot.fc-timegrid-slot-lane {
  height: 70px !important;
}

.fc-event-main {
  font-size: 1rem;
  padding: 1rem !important;
  .fc-event-main-frame {
    justify-content: center;
    .fc-event-time {
      font-weight: bold;
    }
  }
}

.c-body {
  background-color: #fff !important;
}

select.form-control:disabled {
  border-color: #dddddd !important;
  color: #dddddd !important;
}

.card-custom-border {
  box-shadow: 0px 8px 12px rgba(160, 168, 189, 0.24);
  border-radius: 16px;
}

.link-custom-color {
  font-family: "OpenSans-Regular" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #838998 !important;
}

.vc-svg-icon {
  color: rgba(0, 0, 0, 0.6) !important;
  width: 10px !important;
}

.vc-container {
  border-radius: 4px !important;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(160, 168, 189, 0.24) !important;
}

.input-label {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 10px;
  line-height: 156%;
  color: #838998;
  margin-top: 8px;
}

.modal-title-label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #565e73;
}

.delete-confirmation {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  text-align: center;
}

.sketch {
  background: #dddee3;
  color: #131e3b;
}

.finished {
  background: #d3341d;
  color: #fff;
}

.waiting {
  background: #e06914;
  color: #fff;
}

.published {
  background: #9ac625;
  color: #fff;
}

.canceled {
  background: #eda209;
  color: #131e3b;
}

.v-toast__text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.v-toast__item {
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15) !important;
  border-radius: 12px !important;
  padding: 0px !important;
}

.v-toast__item--default {
  background-color: #003063 !important;
}

.v-toast__item--error {
  background-color: #ff002e !important;
}

.v-toast__item--success {
  background-color: #8acc0d !important;
}

.v-toast__item--warning {
  background-color: #ff6b00 !important;
}

.v-toast__item--info {
  background-color: #f3f4f5 !important;
  color: #131e3b !important;
}

.v-toast__item.v-toast__item--default .v-toast__icon {
  background: url("assets/img/toasts/default.svg") no-repeat !important;
  width: 32px !important;
  margin-bottom: 3px !important;
}

.v-toast__item.v-toast__item--error .v-toast__icon {
  background: url("assets/img/toasts/error.svg") no-repeat !important;
  width: 32px !important;
}

.v-toast__item.v-toast__item--success .v-toast__icon {
  background: url("assets/img/toasts/success.svg") no-repeat !important;
  width: 32px !important;
}

.v-toast__item.v-toast__item--warning .v-toast__icon {
  background: url("assets/img/toasts/default.svg") no-repeat !important;
  width: 32px !important;
}

.v-toast__item.v-toast__item--info .v-toast__icon {
  background: url("assets/img/toasts/info.svg") no-repeat !important;
  width: 32px !important;
}

.filter-enter-active {
  animation: filter-in 0.3s;
}
.filter-leave-active {
  animation: filter-in 0.3s reverse;
}

@keyframes filter-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
</style>

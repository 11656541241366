<template>
  <div>
    <canvas ref="usersChart" id="users-chart" width="400" height="400"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";

export default {
  name: "UsersPerPeriodGraph",
  props: {
    graphData: {
      type: Array,
      default: () => [0],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.chartElement = new Chart(this.$refs.usersChart, this.chartConfigs);
    setTimeout(() => {
      this.chartElement.update();
    }, 0);
  },
  watch: {
    graphData: {
      handler(newVal) {
        this.chartConfigs.data.datasets[0].data = [];
        this.formatAndAppendData(newVal);
        if (this.chartElement) {
          this.chartElement.update();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatAndAppendData(data) {
      data.map((item) => {
        const dataItem = {
          x: item.data_cadastro,
          y: item.usuarios_dia,
        };
        this.chartConfigs.data.datasets[0].data.push(dataItem);
      });
      if (this.chartElement) {
        this.chartElement.update();
      }
    },
  },
  data() {
    return {
      chartElement: null,
      chartConfigs: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              fill: false,
              label: [],
              borderColor: "#003366",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: true,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: "DD-MM-YY",
              },
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      },
    };
  },
};
</script>

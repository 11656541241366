<template>
  <div class="statistics-wrapper ml-3">
    <div>
      <div>
        <h3 class="intern-title">
          <back-arrow />
          <span class="page-nav">Estatísticas</span>
        </h3>
        <div class="form-wrapper">
          <h5 class="filter-label mb-4">{{ $t("filterBySpecificDate") }}</h5>

          <form class="d-flex align-center form mb-5" @submit="loadStatistics">
            <span
              @click="calendarModal = !calendarModal"
              class="new-icons pointer btn-relatory mr-3 mb-1"
            >
              <svg
                width="14"
                height="14"
                viewBox="-1 1 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.333374 7.33268H13.6667V13.3327C13.6667 13.5095 13.5965 13.6791 13.4714 13.8041C13.3464 13.9291 13.1769 13.9993 13 13.9993H1.00004C0.82323 13.9993 0.65366 13.9291 0.528636 13.8041C0.403612 13.6791 0.333374 13.5095 0.333374 13.3327V7.33268ZM10.3334 1.99935H13C13.1769 1.99935 13.3464 2.06959 13.4714 2.19461C13.5965 2.31964 13.6667 2.4892 13.6667 2.66602V5.99935H0.333374V2.66602C0.333374 2.4892 0.403612 2.31964 0.528636 2.19461C0.65366 2.06959 0.82323 1.99935 1.00004 1.99935H3.66671V0.666016H5.00004V1.99935H9.00004V0.666016H10.3334V1.99935Z"
                  fill="white"
                />
              </svg>
            </span>
            <div class="absolute-box w-50" v-if="calendarModal">
              <v-date-picker
                v-model="filters.dates"
                is-range
                @input="calendarModal = false"
              />
            </div>
            <DateLabel
              :name="formatDates()"
              class="no-pointer mr-3"
              v-if="filters.dates.start && filters.dates.end"
            />

            <Button
              type="primary"
              size="small"
              :width="400"
              class="btn-position"
              name="Gerar Relatório"
              :disabled="loading"
            />
          </form>
        </div>
      </div>

      <section class="statistics">
        <h6>{{ $t("activateFunnel") }}</h6>
        <div class="graph-card">
          <FunnelGraph
            :loading="loading"
            :graphData="[
              leadsFunnelCount,
              leadsAmaFunnelCount,
              leadsAmaLPFunnelCount,
              leadsAmaFitbankFunnelCount,
              leadsVinculatedFunnelCount,
            ]"
          />
        </div>

        <ul class="nav mt-5">
          <li
            @click="handleTabChange(tab.id)"
            v-for="tab in tabs"
            :key="tab.id"
            class="nav-item"
          >
            <a
              :class="`nav-link ${tab.active ? 'active' : ''}`"
              aria-current="page"
              >{{ tab.label }}
            </a>
          </li>
        </ul>

        <div class="mt-5">
          <StatisticsTabItem
            ref="tabItem"
            :loading="loading"
            :startDate="filters.dates.start.toString()"
            :endDate="filters.dates.end.toString()"
            :usersCount="currentTabData.count"
            :tab="currentTab"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FunnelGraph from "../../components/graphs/FunnelGraph.vue";
import BackArrow from "../../components/BackArrow.vue";
import DateLabel from "../../components/DateLabel.vue";
import Button from "../../components/Button.vue";
import StatisticsTabItem from "./StatisticsTabItem.vue";
import { dateToText } from "../../utils/date";

import services from "../../services";
// import moment from "moment";

export default {
  components: {
    StatisticsTabItem,
    FunnelGraph,
    DateLabel,
    BackArrow,
    Button,
  },

  created: async function () {
    await this.loadStatistics();
    this.handleTabChange(this.currentTab);
  },

  data() {
    return {
      users: [],

      leadsCount: [],
      leadsFunnelCount: 0,

      calendarModal: false,

      leadsAmaCount: [],
      leadsAmaFunnelCount: 0,

      leadsAmaLPCount: [],
      leadsAmaLPFunnelCount: 0,

      leadsAmaFitbankCount: [],
      leadsAmaFitbankFunnelCount: 0,

      leadsVinculatedCount: [],
      leadsVinculatedFunnelCount: 0,

      tabs: [
        { id: 1, label: this.$t("registerLp"), active: true },
        { id: 2, label: this.$t("registerAmas"), active: false },
        { id: 3, label: this.$t("amasFromLp"), active: false },
        { id: 4, label: this.$t("amasWithAccount"), active: false },
        { id: 5, label: this.$t("usersVinculateWithKS"), active: false },
      ],
      currentTab: 1,
      currentTabData: {
        count: [],
      },
      filters: {
        dates: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
      },
      loading: false,
    };
  },

  methods: {
    formatToFunnelGraph(data) {
      if (data.length > 0) {
        return data.reduce((acc, item) => {
          return acc + item.usuarios_dia;
        }, 0);
      } else {
        return 0;
      }
    },

    // Aba Leads
    async loadLeadsCount() {
      await services.statistics
        .getLeadsCount(this.filters.dates)
        .then((response) => {
          this.leadsCount = response.data;
          this.leadsFunnelCount = this.formatToFunnelGraph(response.data);
        });
    },

    convertDate(date) {
      if (date) {
        return date
          .toISOString()
          .slice(0, -5)
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
      }
      return "";
    },

    // Aba AMA
    async loadAmaLeadsCount() {
      await services.statistics
        .getAmaLeadsCount(this.filters.dates)
        .then((response) => {
          this.leadsAmaCount = response.data;
          this.leadsAmaFunnelCount = this.formatToFunnelGraph(response.data);
        });
    },

    // Aba AMA Conta
    async loadAmaFitbankUsersCount() {
      await services.statistics
        .getAmaLeadsFitBankCount(this.filters.dates)
        .then((response) => {
          this.leadsAmaFitbankCount = response.data;
          this.leadsAmaFitbankFunnelCount = this.formatToFunnelGraph(
            response.data
          );
        });
    },

    // Aba ama LP
    async loadAmaLpUsersCount() {
      await services.statistics
        .getAmaLeadsLpCount(this.filters.dates)
        .then((response) => {
          this.leadsAmaLPCount = response.data;
          this.leadsAmaLPFunnelCount = this.formatToFunnelGraph(response.data);
        });
    },

    // Aba Zeladores vinculados
    async loadVinculatedUsersCount() {
      await services.statistics
        .getVinculatedUsersCount(this.filters.dates)
        .then((response) => {
          this.leadsVinculatedCount = response.data;
          this.leadsVinculatedFunnelCount = this.formatToFunnelGraph(
            response.data
          );
        });
    },

    async loadStatistics(ev) {
      ev ? ev.preventDefault() : null;
      this.loading = true;
      if (this.filters.dates) {
        await this.loadLeadsCount();
        await this.loadAmaLeadsCount();
        await this.loadAmaLpUsersCount();
        await this.loadAmaFitbankUsersCount();
        await this.loadVinculatedUsersCount();
        this.loadTabData();
      }
      this.loading = false;
    },

    formatDates() {
      if (this.filters.dates.start && this.filters.dates.end) {
        return `${dateToText(
          this.filters.dates.start.toISOString()
        )} - ${dateToText(this.filters.dates.end.toISOString())}`;
      }
    },

    loadTabData() {
      if (this.currentTab === 1) {
        this.currentTabData.count = this.leadsCount;
      } else if (this.currentTab === 2) {
        this.currentTabData.count = this.leadsAmaCount;
      } else if (this.currentTab === 3) {
        this.currentTabData.count = this.leadsAmaLPCount;
      } else if (this.currentTab === 4) {
        this.currentTabData.count = this.leadsAmaFitbankCount;
      } else if (this.currentTab === 5) {
        this.currentTabData.count = this.leadsVinculatedCount;
      }
    },

    async handleTabChange(tab) {
      this.currentTab = tab;
      this.tabs.forEach((item) => {
        if (item.active) item.active = false;
        if (item.id === tab) item.active = true;
      });
      await this.loadStatistics();
    },
  },

  watch: {
    currentTab: {
      async handler(newVal) {
        await this.handleTabChange(newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-card {
  border: 1px solid #dddee3;
  border-radius: 24px;
  padding: 48px 56px;
  border-radius: 24px;
}

.pagetitle {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 132%;
  color: #565e73;
}

.form {
  position: relative;

  label {
    font-family: "OpenSans-Regular";
    font-weight: 600;
    font-size: 12px;
    line-height: 132%;
    color: #565e73;
    margin-right: 10px;
    display: inline;
    text-transform: uppercase;
  }
}

.input-date-custom {
  width: 100%;
  min-width: 160px;
  height: 48px;
  border: 1px solid #c6c9d0;
  border-radius: 12px;
  padding: 0 10px;
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #838998;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.btn-relatory {
  width: 32px;
  height: 32px;
}

.absolute-box {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 30px;
}

.filter-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #565e73;
}

.absolute-box-initial {
  position: absolute;
  top: 40px;
  left: 5px;
  z-index: 1000;
}

.absolute-box-final {
  position: absolute;
  top: 40px;
  left: 180px;
  z-index: 1000;
}

.nav {
  border-bottom: 1px solid #dddee3;

  .nav-link {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 266%;
    color: #b0b4bd;
    cursor: pointer;
  }

  .nav-link.active {
    background: white !important;
    border-bottom: 2px solid #003366;
    color: #003366;
    padding-right: 4px;
    padding-left: 4px;
  }
}

.statistics {
  h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #565e73;
    margin-bottom: 32px;
  }
}

.btn-position {
  margin-top: 3px;
}
</style>

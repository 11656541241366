<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "200",
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },

    handleItemClick(value) {
      this.$emit("dropdown-click", value);
      this.toggleDropdown();
    },

    handleFocusOut() {
      setTimeout(() => {
        this.open = false;
      }, 200);
    },
  },
};
</script>

<template>
  <div class="condensed-wrapper">
    <div
      tabindex="0"
      class="dropdown-button"
      @focusout="handleFocusOut"
      @click="toggleDropdown"
    >
      ...
    </div>
    <div v-if="open" class="condensed-dropdown">
      <ul>
        <li
          @click="(ev) => handleItemClick(option.value)"
          v-for="(option, index) in options"
          :key="index"
          :style="`width: ${width}px;`"
        >
          {{ option.label }}
          <svg
            v-if="option.value === 'details'"
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 16H1V8H6V16ZM4 14V10H3V14H4ZM10 14V6H9V14H10ZM12 16H7V4H12V16ZM16 14V2H15V14H16ZM18 16H13V0H18V16ZM19 20H0V18H19V20Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'calendar'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 2H19C19.2652 2 19.5195 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5195 19.8946 19.2652 20 19 20H0.999969C0.734753 20 0.480399 19.8946 0.292863 19.7071C0.105326 19.5196 -3.05176e-05 19.2652 -3.05176e-05 19V3C-3.05176e-05 2.73478 0.105326 2.48043 0.292863 2.29289C0.480399 2.10536 0.734753 2 0.999969 2H4.99997V0H6.99997V2H13V0H15V2ZM13 4H6.99997V6H4.99997V4H1.99997V8H18V4H15V6H13V4ZM18 10H1.99997V18H18V10Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'delete'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM11.414 12L13.182 13.768L11.768 15.182L10 13.414L8.232 15.182L6.818 13.768L8.586 12L6.818 10.232L8.232 8.818L10 10.586L11.768 8.818L13.182 10.232L11.414 12ZM7 2V4H13V2H7Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'users'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18V13H8V18H17V11H3V18H6ZM2 9H18V6H12V2H8V6H2V9ZM1 19V11H0V5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H6V1C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V11H19V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H2C1.73478 20 1.48043 19.8946 1.29289 19.7071C1.10536 19.5196 1 19.2652 1 19Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'executions'"
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2H2V18H16V6H12V2ZM0 0.992C0 0.444 0.447 0 0.999 0H13L18 5V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993C0.730378 19.9982 0.479017 19.8931 0.293218 19.7075C0.107418 19.5219 0.00209465 19.2706 0 19.008V0.992ZM9 9.5C8.33696 9.5 7.70107 9.23661 7.23223 8.76777C6.76339 8.29893 6.5 7.66304 6.5 7C6.5 6.33696 6.76339 5.70107 7.23223 5.23223C7.70107 4.76339 8.33696 4.5 9 4.5C9.66304 4.5 10.2989 4.76339 10.7678 5.23223C11.2366 5.70107 11.5 6.33696 11.5 7C11.5 7.66304 11.2366 8.29893 10.7678 8.76777C10.2989 9.23661 9.66304 9.5 9 9.5ZM4.527 15C4.64822 13.8984 5.17168 12.8803 5.99708 12.1407C6.82248 11.4012 7.89175 10.9922 9 10.9922C10.1082 10.9922 11.1775 11.4012 12.0029 12.1407C12.8283 12.8803 13.3518 13.8984 13.473 15H4.527Z"
              fill="#565E73"
            />
          </svg>

          <svg
            v-if="option.value === 'results'"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.414 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H8.414L10.414 2ZM2 2V16H18V4H9.586L7.586 2H2ZM9 6H11V14H9V6ZM13 9H15V14H13V9ZM5 11H7V14H5V11Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'cancel'"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.29 18C8.505 18.722 8.833 19.396 9.255 20H0.993C0.730378 19.9982 0.479017 19.8931 0.293218 19.7075C0.107418 19.5219 0.00209465 19.2706 0 19.008V0.992C0 0.444 0.447 0 0.999 0H13L18 5V9.674C17.3675 9.37339 16.6932 9.17009 16 9.071V6H12V2H2V18H8.29ZM15 21C13.6739 21 12.4021 20.4732 11.4645 19.5355C10.5268 18.5979 10 17.3261 10 16C10 14.6739 10.5268 13.4021 11.4645 12.4645C12.4021 11.5268 13.6739 11 15 11C16.3261 11 17.5979 11.5268 18.5355 12.4645C19.4732 13.4021 20 14.6739 20 16C20 17.3261 19.4732 18.5979 18.5355 19.5355C17.5979 20.4732 16.3261 21 15 21ZM13.707 18.708C14.267 18.9759 14.8962 19.0635 15.5081 18.9588C16.1199 18.854 16.6842 18.5621 17.1231 18.1231C17.5621 17.6842 17.854 17.1199 17.9588 16.5081C18.0635 15.8962 17.9759 15.267 17.708 14.707L13.707 18.707V18.708ZM12.292 17.293L16.293 13.293C15.733 13.0251 15.1038 12.9375 14.4919 13.0422C13.8801 13.147 13.3158 13.4389 12.8769 13.8779C12.4379 14.3168 12.146 14.8811 12.0412 15.4929C11.9365 16.1048 12.0241 16.734 12.292 17.294V17.293Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'edit'"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 16.0001H3.414L12.728 6.68608L11.314 5.27208L2 14.5861V16.0001ZM18 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L6.243 16.0001H18V18.0001ZM12.728 3.85808L14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'user-red'"
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 13.252V15.342C9.09492 15.022 8.12628 14.9239 7.1754 15.0558C6.22453 15.1877 5.3192 15.5459 4.53543 16.1002C3.75166 16.6545 3.11234 17.3888 2.67116 18.2414C2.22998 19.094 1.99982 20.04 2 21L2.58457e-07 20.999C-0.000310114 19.7779 0.278921 18.5729 0.816299 17.4764C1.35368 16.3799 2.13494 15.4209 3.10022 14.673C4.0655 13.9251 5.18918 13.4081 6.38515 13.1616C7.58113 12.9152 8.81766 12.9457 10 13.251V13.252ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM15 16.586L17.121 14.464L18.536 15.879L16.414 18L18.536 20.121L17.121 21.536L15 19.414L12.879 21.536L11.464 20.121L13.586 18L11.464 15.879L12.879 14.464L15 16.586Z"
              fill="#DB5946"
            />
          </svg>
          <svg
            v-if="option.value === 'user-green'"
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 13.252V15.342C9.09492 15.022 8.12628 14.9239 7.1754 15.0558C6.22453 15.1877 5.3192 15.5459 4.53543 16.1002C3.75166 16.6545 3.11234 17.3888 2.67116 18.2414C2.22998 19.094 1.99982 20.04 2 21L2.58457e-07 20.999C-0.000310114 19.7779 0.278921 18.5729 0.816299 17.4764C1.35368 16.3799 2.13494 15.4209 3.10022 14.673C4.0655 13.9251 5.18918 13.4081 6.38515 13.1616C7.58113 12.9152 8.81766 12.9457 10 13.251V13.252ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM13.793 18.914L17.328 15.379L18.743 16.793L13.793 21.743L10.257 18.207L11.672 16.793L13.792 18.914H13.793Z"
              fill="#9AC625"
            />
          </svg>
          <svg
            v-if="option.value === 'user'"
            width="19"
            height="23"
            viewBox="0 0 19 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 13V15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM14 20.5L11.061 22.045L11.622 18.773L9.245 16.455L12.531 15.977L14 13L15.47 15.977L18.755 16.455L16.378 18.773L16.938 22.045L14 20.5Z"
              fill="#565E73"
            />
          </svg>

          <svg
            v-if="option.value === 'publish'"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 16.0001H3.414L12.728 6.68608L11.314 5.27208L2 14.5861V16.0001ZM18 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L6.243 16.0001H18V18.0001ZM12.728 3.85808L14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'gallery'"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.828 18L2.808 18.02L2.787 18H0.992C0.728813 17.9997 0.476497 17.895 0.290489 17.7088C0.104482 17.5226 -1.33455e-07 17.2702 0 17.007V0.993C0.00183004 0.730378 0.1069 0.479017 0.292513 0.293218C0.478126 0.107418 0.72938 0.00209465 0.992 0H19.008C19.556 0 20 0.445 20 0.993V17.007C19.9982 17.2696 19.8931 17.521 19.7075 17.7068C19.5219 17.8926 19.2706 17.9979 19.008 18H2.828ZM18 12V2H2V16L12 6L18 12ZM18 14.828L12 8.828L4.828 16H18V14.828ZM6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'contracts'"
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2H2V18H16V6H12V2ZM0 0.992C0 0.444 0.447 0 0.999 0H13L18 5V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993C0.730378 19.9982 0.479017 19.8931 0.293218 19.7075C0.107418 19.5219 0.00209465 19.2706 0 19.008V0.992ZM9 9.5C8.33696 9.5 7.70107 9.23661 7.23223 8.76777C6.76339 8.29893 6.5 7.66304 6.5 7C6.5 6.33696 6.76339 5.70107 7.23223 5.23223C7.70107 4.76339 8.33696 4.5 9 4.5C9.66304 4.5 10.2989 4.76339 10.7678 5.23223C11.2366 5.70107 11.5 6.33696 11.5 7C11.5 7.66304 11.2366 8.29893 10.7678 8.76777C10.2989 9.23661 9.66304 9.5 9 9.5ZM4.527 15C4.64822 13.8984 5.17168 12.8803 5.99708 12.1407C6.82248 11.4012 7.89175 10.9922 9 10.9922C10.1082 10.9922 11.1775 11.4012 12.0029 12.1407C12.8283 12.8803 13.3518 13.8984 13.473 15H4.527Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'performance'"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 0V16H18V18H0V0H2ZM17.293 3.293L18.707 4.707L13 10.414L10 7.415L5.707 11.707L4.293 10.293L10 4.586L13 7.585L17.293 3.293Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'deactivate'"
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 13.252V15.342C9.09492 15.022 8.12628 14.9239 7.1754 15.0558C6.22453 15.1877 5.3192 15.5459 4.53543 16.1002C3.75166 16.6545 3.11234 17.3888 2.67116 18.2414C2.22998 19.094 1.99982 20.04 2 21L2.58457e-07 20.999C-0.000310114 19.7779 0.278921 18.5729 0.816299 17.4764C1.35368 16.3799 2.13494 15.4209 3.10022 14.673C4.0655 13.9251 5.18918 13.4081 6.38515 13.1616C7.58113 12.9152 8.81766 12.9457 10 13.251V13.252ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM15 16.586L17.121 14.464L18.536 15.879L16.414 18L18.536 20.121L17.121 21.536L15 19.414L12.879 21.536L11.464 20.121L13.586 18L11.464 15.879L12.879 14.464L15 16.586Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'eye'"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 0C16.392 0 20.878 3.88 21.819 9C20.879 14.12 16.392 18 11 18C5.60803 18 1.12203 14.12 0.18103 9C1.12103 3.88 5.60803 0 11 0ZM11 16C13.0395 15.9996 15.0184 15.3068 16.6129 14.0352C18.2074 12.7635 19.3229 10.9883 19.777 9C19.3213 7.0133 18.205 5.24 16.6107 3.97003C15.0163 2.70005 13.0383 2.00853 11 2.00853C8.96173 2.00853 6.98372 2.70005 5.38941 3.97003C3.79509 5.24 2.6788 7.0133 2.22303 9C2.67713 10.9883 3.7927 12.7635 5.38717 14.0352C6.98164 15.3068 8.96056 15.9996 11 16ZM11 13.5C9.80656 13.5 8.66196 13.0259 7.81805 12.182C6.97414 11.3381 6.50003 10.1935 6.50003 9C6.50003 7.80653 6.97414 6.66193 7.81805 5.81802C8.66196 4.97411 9.80656 4.5 11 4.5C12.1935 4.5 13.3381 4.97411 14.182 5.81802C15.0259 6.66193 15.5 7.80653 15.5 9C15.5 10.1935 15.0259 11.3381 14.182 12.182C13.3381 13.0259 12.1935 13.5 11 13.5ZM11 11.5C11.6631 11.5 12.299 11.2366 12.7678 10.7678C13.2366 10.2989 13.5 9.66304 13.5 9C13.5 8.33696 13.2366 7.70107 12.7678 7.23223C12.299 6.76339 11.6631 6.5 11 6.5C10.337 6.5 9.7011 6.76339 9.23226 7.23223C8.76342 7.70107 8.50003 8.33696 8.50003 9C8.50003 9.66304 8.76342 10.2989 9.23226 10.7678C9.7011 11.2366 10.337 11.5 11 11.5Z"
              fill="#565E73"
            />
          </svg>
          <svg
            v-if="option.value === 'loop'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.463 2.43301C5.27756 0.86067 7.59899 -0.00333986 10 9.70266e-06C15.523 9.70266e-06 20 4.47701 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C18.0001 8.43163 17.5392 6.89781 16.6747 5.58927C15.8101 4.28072 14.5799 3.25517 13.1372 2.64013C11.6944 2.0251 10.1027 1.84771 8.55996 2.13003C7.0172 2.41234 5.59145 3.14191 4.46 4.22801L3.463 2.43301ZM16.537 17.567C14.7224 19.1393 12.401 20.0034 10 20C4.477 20 0 15.523 0 10C0 7.86401 0.67 5.88401 1.81 4.26001L5 10H2C1.99987 11.5684 2.46075 13.1022 3.32534 14.4108C4.18992 15.7193 5.42007 16.7449 6.86282 17.3599C8.30557 17.9749 9.89729 18.1523 11.44 17.87C12.9828 17.5877 14.4085 16.8581 15.54 15.772L16.537 17.567Z"
              fill="#565E73"
            />
          </svg>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.condensed-wrapper {
  position: relative;
  font-family: "Inter";

  .dropdown-button {
    font-size: 1.5rem;
    cursor: pointer;
    color: #131e3b;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.condensed-dropdown {
  position: absolute;
  left: -180px;
  min-width: 120px;
  background: white;
  z-index: 999;
  box-shadow: 0px 16px 24px rgba(160, 168, 189, 0.24);
  border-radius: 8px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      text-align: center;
      padding: 4px 14px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #565e73;
      &:hover {
        background-color: #e6f7fd;
      }
      svg {
        margin-left: 12px;
      }
    }

    li:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    li:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
</style>

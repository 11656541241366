<template>
  <Modal :width="350">
    <template v-slot:header>
      <div class="modal-title">
        <svg
          class="animate__animated animate__bounceIn"
          width="74"
          height="74"
          viewBox="0 0 74 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37.0007 73.6666C16.7497 73.6666 0.333984 57.2509 0.333984 36.9999C0.333984 16.7489 16.7497 0.333252 37.0007 0.333252C57.2517 0.333252 73.6673 16.7489 73.6673 36.9999C73.6673 57.2509 57.2517 73.6666 37.0007 73.6666ZM33.345 51.6666L59.2683 25.7396L54.0837 20.5549L33.345 41.2973L22.972 30.9243L17.7873 36.1089L33.345 51.6666Z"
            fill="#80BE24"
          />
        </svg>

        <div>Pronto!</div>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body">
        O distrato de
        <span style="font-weight: 700" v-if="user">{{
          user.usuario_nome
        }}</span>
        foi concluído
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-center w-100">
        <Button
          :width="110"
          size="small"
          name="Continuar"
          @clicked="() => $emit('close')"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import Button from "../../../components/Button.vue";

export default {
  name: "FinalizationModal",
  components: {
    Modal,
    Button,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #565e73 !important;

  svg {
    margin-bottom: 16px;
  }
}

.modal-body {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
  text-align: center;
}

.animate__animated {
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.animate__bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
    transform: scale3d(1.09, 1.09, 1.09);
  }

  70% {
    transform: scale3d(0.91, 0.91, 0.91);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>

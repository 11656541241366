<template>
  <div class="logs">
    <h3 class="intern-title mb-4 pb-2">
      <back-arrow />
      <span class="pagetitle pl-2"> Logs de alterações do {{contractType === 1 ? 'Contrato' : 'Distrato'}} </span>
    </h3>
    <Table :fields="fields" :hasActions="true">
      <TableRow v-for="item in logs" :key="item.contrato_zelador_log_id">
        <TableCell
          v-for="field in fields"
          :key="field.key"
          :name="field.key"
          :style="field._style"
          :sort-direction="field.sortDirection"
        >
          <template v-slot:[field.key]>
            <span class="table-row-text">{{ item[field.key] }}</span>
          </template>
          <template v-slot:contrato_zelador_log_data>
            <span class="table-row-text">{{
              formatDate(item.contrato_zelador_log_data)
            }}</span>
          </template>
          <template v-slot:acoes>
            <p
              @click="handleLogDetails(item.contrato_zelador_log_id)"
              class="view-log"
            >
              <span class="material-icons-outlined">visibility</span>
            </p>
          </template>
        </TableCell>
      </TableRow>
      <template v-if="logs.length === 0" v-slot:no-results>
        <NoResults :message="$t('noResults')" />
      </template>
    </Table>
  </div>
</template>
<script>
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import BackArrow from "../../components/BackArrow.vue";
import services from "../../services";
import { formatDate } from "../../utils/date";

export default {
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    BackArrow,
  },
  data() {
    return {
      formatDate,
      logs: [],
      fields: [
        {
          key: "contrato_zelador_log_data",
          label: this.$t("date"),
          _style: "min-width:100px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_gestor_nome",
          label: "Gestor",
          _style: "min-width:180px",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "acoes",
          label: "Ações",
          _style: "min-width:50px",
          sortable: false,
          sortDirection: null,
        },
      ],
    };
  },
  async created() {
    const type = this.$route.params.type;
    this.getContractLogs(type);
  },
  methods: {
    async getContractLogs() {
      const response = await services.contracts.getContractLogs();
      this.logs = response.data;
      if (this.contractType === 1) {
        this.logs = this.logs.filter(
          (item) => item.contrato_zelador_tipo_id === 1
        );
      } else {
        this.logs = this.logs.filter(
          (item) => item.contrato_zelador_tipo_id === 2
        );
      }
    },
    handleLogDetails(id) {
      this.$router.push({
        name: "ContratosDistratos",
        params: { contractId: id },
      });
    },
  },
  computed: {
    contractType: function () {
      return +this.$route.params.type;
    },
  },
};
</script>
<style lang="scss" scoped>
.view-log {
  cursor: pointer;
}
</style>

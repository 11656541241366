<template>
  <Modal class="modal-confirmacao" v-show="open" :width="350">
    <template v-slot:header>
      <div class="modal-title">
        <img
          src="@/assets/gif/success.gif"
          alt="sucesso"
          width="120"
          class="mb-3"
        />

        <div>Pronto!</div>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body">{{ body }}</div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-center w-100">
        <Button
          :width="110"
          size="small"
          name="Continuar"
          @clicked="$emit('close')"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Button from "../../../components/Button.vue";
import Modal from "../../../components/Modal.vue";

export default {
  name: "FinalModal",
  components: {
    Button,
    Modal,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    body: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.modal-title {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #565e73 !important;

  svg {
    margin-bottom: 16px;
  }
}

.modal-body {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565e73;
}

.animate__animated {
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.animate__bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
    transform: scale3d(1.09, 1.09, 1.09);
  }

  70% {
    transform: scale3d(0.91, 0.91, 0.91);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>

<template>
  <div class="list-container">
    <div class="d-flex flex-column list-header">
      <div @click="$emit('clicked')" class="pointer icon-position">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99999 5.58672L11.95 0.636719L13.364 2.05072L8.41399 7.00072L13.364 11.9507L11.95 13.3647L6.99999 8.41472L2.04999 13.3647L0.635986 11.9507L5.58599 7.00072L0.635986 2.05072L2.04999 0.636719L6.99999 5.58672Z"
            fill="#29334E"
          />
        </svg>
      </div>
      <p class="checkbox-title">{{ name }}</p>
    </div>

    <div v-if="activitiesData.length">
      <ul>
        <li
          v-for="activity in activitiesData"
          :key="activity.atividade_id"
          class="d-flex align-items-center list-item mb-3"
        >
          <input
            class="mr-2 input-checkbox"
            type="checkbox"
            :checked="activity.active"
            :id="activity.atividade_id"
            :name="activity.atividade_nome"
            v-model="activity.active"
          />
          <label class="checkbox-label m-0" :for="activity.atividade_id">{{
            activity.atividade_nome
          }}</label>
        </li>
      </ul>
      <PrimaryButton
        name="Filtrar Resultados"
        @clicked="sendActivitiesFilter"
        class="mt-4"
      />
    </div>

    <div v-if="tagsData.length">
      <ul class="list-tags">
        <li
          v-for="tag in tagsData"
          :key="tag.tag_id"
          class="d-flex align-items-center list-item mb-3"
        >
          <input
            class="mr-2"
            type="checkbox"
            :checked="tag.active"
            :id="tag.tag_id"
            :name="tag.tag_descricao"
            v-model="tag.active"
          />
          <label class="checkbox-label m-0" :for="tag.tag_id">{{
            tag.tag_descricao
          }}</label>
        </li>
      </ul>
      <PrimaryButton
        name="Filtrar Resultados"
        @clicked="sendTagsFilter"
        class="mt-2"
      />
    </div>

    <div v-if="bairros.length">
      <ul class="list-tags">
        <li
          v-for="bairro in bairros"
          :key="bairro.bairro_id"
          class="d-flex align-items-center list-item mb-3"
        >
          <input
            class="mr-2"
            type="checkbox"
            :checked="bairro.active"
            :id="bairro.bairro_id"
            :name="bairro.bairro_nome"
            v-model="bairro.active"
          />
          <label class="checkbox-label m-0" :for="bairro.bairro_id">{{
            bairro.bairro_nome
          }}</label>
        </li>
      </ul>
      <PrimaryButton
        name="Filtrar Resultados"
        @clicked="sendBairrosFilter"
        class="mt-2"
      />
      <PrimaryButton
        name="Limpar Filtro"
        @clicked="clearBairrosFilter"
        class="mt-2"
        classe="primary-btn-negative"
      />
    </div>
  </div>
</template>

<script>
import PrimaryButton from "../components/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },
  methods: {
    changeInput(id) {
      if (this.tagsData.length) {
        this.tagsData.forEach((item) => {
          if (item.tag_id === id) {
            item.active = !item.active;
          }
        });
      }
      if (this.activitiesData.length) {
        this.activitiesData.forEach((item) => {
          if (item.atividade_id === id) {
            item.selected = !item.selected;
          }
        });
      }
    },
    sendActivitiesFilter() {
      this.$emit("filter-by-activity", this.activitiesData);
    },
    sendTagsFilter() {
      this.$emit("filter-by-tag", this.tagsData);
    },
    sendBairrosFilter() {
      this.$emit("filter-by-neighborhood", this.bairros);
    },
    clearBairrosFilter() {
      this.$emit("clear-filter-by-neighborhood", this.bairros);
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    activitiesData: {
      type: Array,
      required: false,
      default: () => [],
    },
    tagsData: {
      type: Array,
      required: false,
      default: () => [],
    },
    bairros: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-header {
  position: relative;
}

.icon-position {
  position: absolute;
  right: 0;
}

.list-container {
  padding: 18px;
  box-shadow: 0px 4px 4px rgba(160, 168, 189, 0.24) !important;
  background-color: #fff;
  border-radius: 8px !important;
  max-width: 290px;
}

.list-tags {
  overflow-y: auto;
  max-height: 220px;
}

.checkbox-label {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: #565e73;
}

.checkbox-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.014em;
  color: #29334e;
}

.input-checkbox {
  accent-color: #003366;
}
</style>

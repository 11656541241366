<template>
  <div class="keeping-sectors">
    <div class="page-header">
      <h3 class="intern-title ml-3">
        <back-arrow />
        <span class="page-nav">{{ $t("keepingSectors") }}</span>
      </h3>

      <div
        class="d-flex justify-content-between align-items-center ml-3 mb-4 mr-3"
      >
        <div>
          <input
            v-model="filterInput"
            :placeholder="$t('searchKeepingSector')"
            :search="true"
            class="search-input"
          />
        </div>
        <router-link
          :to="{
            name: 'MapaZelador',
          }"
        >
          <Button
            color="warning"
            :name="$t('keepingSectorsMap')"
            size="small"
            type="primary"
          />
        </router-link>
      </div>
    </div>
    <transition name="bounce">
      <div v-if="!loadingData">
        <Table
          id="table"
          :fields="fields"
          :hasActions="true"
          @sort="handleSort"
        >
          <TableRow v-for="item in keepingSectors" :key="item.zeladoria_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                {{ item[field.key] }}
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="keepingSectors.length === 0" v-slot:no-results>
            <NoResults :message="$t('noResults')" />
          </template>
        </Table>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />
  </div>
</template>

<script>
import services from "../../services";
import Button from "../../components/Button.vue";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import BackArrow from "../../components/BackArrow.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import NoResults from "../../components/table/NoResults.vue";
import Loading from "../../components/IndexComponents/Loading.vue";
import { debounce } from "../../utils/debounce";

export default {
  name: "Azs",
  components: {
    Table,
    TableRow,
    TableCell,
    NoResults,
    Button,
    Loading,
    BackArrow,
    CondensedDropdown,
  },
  data() {
    return {
      date: new Date(),
      fields: [
        {
          key: "zeladoria_id",
          label: "ID",
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "zeladoria_nome",
          label: this.$t("name"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
          filter: true,
        },
        {
          key: "bairro_nome",
          label: this.$t("neighborhood"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
        {
          key: "usuario_nome",
          label: this.$t("user"),
          _style: "min-width:80px;",
          sortable: false,
          sortDirection: null,
        },
      ],
      keepingSectors: [],
      loadingData: true,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      filterArray: [],
      cidadeId: 0,
      filterInput: null,
    };
  },
  mounted: async function () {
    await this.getKeepingSectors();
    this.loadingData = false;
  },
  async function() {
    await this.getKeepingSectors(this.pagination);
    this.usertype = this.$store.state.global.user.usuario_gestor_tipo_id;
    if (this.userType !== 1) {
      this.$router.push("/");
    }
    this.loadingData = false;
  },
  watch: {
    filterInput: debounce(async function (newVal) {
      this.pagination.page = 0;
      newVal
        ? (this.pagination.filter = newVal)
        : (this.pagination.filter = null);
      await this.getKeepingSectors(this.pagination);
    }),
  },
  methods: {
    async getKeepingSectors() {
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorsNew(this.pagination);
      if (!errors) {
        this.keepingSectors = data.map((item, id) => {
          return {
            ...item,
            id,
            usuario_nome: item.usuario_nome === null ? "" : item.usuario_nome,
          };
        });
      }
    },
    async handleSort(sort) {
      this.fields.forEach((f) => {
        f.key !== sort.key
          ? (f.sortDirection = null)
          : (f.sortDirection = sort.sortDirection);
      });
      this.pagination.sortBy = sort.key;
      this.pagination.order = sort.sortDirection;
      this.pagination.page = 0;
      await this.getKeepingSectors();
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "details") {
        this.$router.push({
          name: "DetalhesZeladoria",
          params: { id: item.zeladoria_id },
        });
      }
      if (value === "edit") {
        this.$router.push({
          name: "ImagemContrato",
          params: { id: item.zeladoria_id },
        });
      }
      if (value === "gallery") {
        this.$router.push({
          name: "GaleriaZeladoria",
          params: { id: item.zeladoria_id },
        });
      }
    },
    optionsByResearch() {
      return [
        { label: "Imagem", value: "edit" },
        { label: "Detalhes", value: "details" },
        { label: "Galeria", value: "gallery" },
      ];
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } =
        await services.keepingSectors.getKeepingSectorsNew(this.pagination);
      if (!errors) {
        if (data.length > 0) {
          let temp = data;
          temp.forEach((item) => {
            this.keepingSectors.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.links {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.option-icon {
  width: 20px;
}

.keeping-sectors {
  .page-header {
    position: relative;
  }

  input {
    width: 304px;
    height: 51px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 12px;
    color: #747474;
    padding-left: 15px;
  }
}
</style>

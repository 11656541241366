<template>
  <div>
    <h3 class="intern-title ml-3">
      <back-arrow />
      <span class="page-nav">{{ $t("activities") }}</span>
    </h3>

    <transition name="bounce">
      <div v-if="!loadingData">
        <Table :fields="fields" :hasActions="true">
          <TableRow v-for="item in activities" :key="item.atividade_id">
            <TableCell
              v-for="field in fields"
              :key="field.key"
              :name="field.key"
              :style="field._style"
              :sort-direction="field.sortDirection"
            >
              <template v-slot:[field.key]>
                {{ item[field.key] }}
              </template>
              <template v-slot:foto_file>
                <img
                  style="max-width: 40px"
                  :src="item.atividade_icon"
                  alt=""
                />
              </template>
            </TableCell>
            <TableCell class="d-flex justify-content-end">
              <CondensedDropdown
                class="mt-1"
                @dropdown-click="(ev) => handleCondensedDropdownClick(ev, item)"
                :options="optionsByResearch()"
              />
            </TableCell>
          </TableRow>
          <template v-if="activities.length === 0" v-slot:no-results>
            <NoResults :message="$t('emptyActivities')" />
          </template>
        </Table>
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <Loading />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </transition>
    <Loading v-if="loadingData" />

    <Modal v-show="editModal" :width="600">
      <template v-slot:header>
        <span class="page-nav">{{ $t("editActivity") }}</span>
      </template>
      <template v-slot:body>
        <Input name="name" :label="$t('name')" v-model="editName" />
        <Input
          name="video"
          :label="$t('videoLink')"
          placeholder="https://youtube.com"
          v-model="atividade_youtube"
        />
        <Textarea :label="$t('scope')" name="scope" v-model="editScope" />
        <label class="input-label" for="name">{{ $t("relatedTags") }}</label>
        <multiselect
          v-model="valueTags"
          :noOptions="$t('emptyList')"
          selectLabel=""
          :deselectLabel="$t('remove')"
          :selectedLabel="$t('selected')"
          :options="tags"
          track-by="name"
          :placeholder="$t('selectATag')"
          :preselect-first="true"
          :multiple="true"
          label="name"
          @remove="removeTag"
        ></multiselect>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100">
          <Button
            type="outline"
            :name="$t('cancel')"
            class="w-100 mr-2"
            @clicked="(editModal = false), (atividade_youtube = '')"
          />
          <Button @clicked="saveEdit" :name="$t('save')" class="w-100 ml-2" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BackArrow from "../../components/BackArrow.vue";
import services from "../../services";
import Table from "../../components/table/Table.vue";
import TableRow from "../../components/table/TableRow.vue";
import TableCell from "../../components/table/TableCell.vue";
import NoResults from "../../components/table/NoResults.vue";
import Button from "../../components/Button.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/form/Input.vue";
import CondensedDropdown from "../../components/CondensedDropdown.vue";
import Textarea from "../../components/form/Textarea.vue";
import Loading from "../../components/IndexComponents/Loading.vue";

import { successToast } from "../../utils/toast";

export default {
  name: "Atividades",
  components: {
    Multiselect,
    BackArrow,
    Table,
    TableRow,
    TableCell,
    NoResults,
    CondensedDropdown,
    Button,
    Loading,
    Modal,
    Input,
    Textarea,
  },
  data() {
    return {
      date: new Date(),
      details: [],
      activities: [],
      editModal: false,
      editName: "",
      editScope: "",
      editId: 0,
      tags: [],
      valueTags: [],
      tagsByActivity: [],
      activityId: 0,
      atividade_youtube: "",
      loadingData: true,
      userType: null,
      pagination: {
        page: 0,
        sortBy: null,
        filter: null,
        order: null,
      },
      fields: [
        {
          key: "foto_file",
          label: this.$t("icon"),
          _style: "min-width:80px;",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "atividade_nome",
          label: this.$t("name"),
          _style: "min-width:200px",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
        {
          key: "status",
          label: this.$t("status"),
          _style: "min-width:80px;",
          filter: false,
          sortable: false,
          sortDirection: null,
        },
      ],
    };
  },
  mounted: async function () {
    await this.initAllData();
    this.loadingData = false;
  },
  methods: {
    async initAllData() {
      await this.getActivities();
      await this.getTags();
      this.userType = this.$store.state.global.user.usuario_gestor_tipo_id;
    },
    async handleEditActivity(item) {
      this.valueTags = [];
      this.tagsByActivity = [];
      this.activityId = item.atividade_id;
      this.atividade_youtube = item.atividade_youtube;
      await this.requestTagsByActivity(item.atividade_id);
      this.editName = item.atividade_nome;
      this.editScope = item.atividade_escopo;
      this.editId = item.atividade_id;
      this.editModal = true;
    },
    optionsByResearch() {
      if (this.userType === 1) {
        return [
          { label: "Execuções", value: "executions" },
          { label: "Editar", value: "edit" },
          { label: "Galeria", value: "gallery" },
        ];
      } else {
        return [
          { label: "Execuções", value: "executions" },
          { label: "Galeria", value: "gallery" },
        ];
      }
    },
    async handleCondensedDropdownClick(value, item) {
      if (value === "executions") {
        this.$router.push({
          name: "Execucoes",
          params: { atividade_id: item.atividade_id },
        });
      }
      if (value === "edit") {
        this.handleEditActivity(item);
      }
      if (value === "gallery") {
        this.$router.push({
          name: "GaleriaAtividade",
          params: { id: item.atividade_id },
        });
      }
    },
    async saveEdit() {
      let modifiedflag = false;
      const { data, errors } = await services.activities.editActivityName({
        atividade_id: this.editId,
        atividade_nome: this.editName,
        atividade_escopo: this.editScope,
        atividade_youtube: this.atividade_youtube,
      });
      if (!errors) {
        this.tagsByActivity = data;
        modifiedflag = true;
      }
      this.valueTags.map(async (item) => {
        const { data, errors } =
          await services.activities.relateActivityWithTag({
            atividade_id: this.editId,
            tag_id: item.id,
          });

        if (!errors) {
          this.tagsByActivity = data;
          modifiedflag = true;
        }
      });
      if (modifiedflag) {
        successToast(this.$t("successEditActivity"));
      }
      this.atividade_youtube = "";
      this.pagination.page = 0;
      await this.initAllData();
      this.editModal = false;
    },
    async requestTagsByActivity(id) {
      const { data, errors } = await services.tags.getTagsByActivity({
        activityId: id,
      });

      if (!errors) {
        if (data.length > 0) {
          data.map((item) => {
            this.valueTags.push({
              name: item.tag_descricao,
              id: item.tag_id,
            });
          });
        }
      }
    },
    async removeTag(item) {
      const { errors } = await services.tags.deleteActivityTag({
        activityId: this.activityId,
        tagId: item.id,
      });
      if (!errors) {
        successToast(this.$t("successRemoveLink"));
      }
    },
    async getActivities() {
      const { data, errors } = await services.activities.getAllActivitiesNew(
        this.pagination
      );
      if (!errors) {
        this.activities = data.map((item) => {
          return {
            ...item,
            status:
              item.atividade_ativa === 1
                ? this.$t("active")
                : this.$t("inactive"),
          };
        });
      }
    },
    async getTags() {
      const { data, errors } = await services.tags.getTags();

      if (!errors) {
        this.tags = data.map((item) => {
          return {
            name: item.tag_descricao,
            id: item.tag_id,
          };
        });
      }
    },
    async infiniteHandler($state) {
      this.pagination.page++;
      const { data, errors } = await services.activities.getAllActivitiesNew(
        this.pagination
      );
      if (!errors) {
        if (data.length > 0) {
          data.forEach((item) => {
            this.activities.push(item);
          });
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.label {
  margin-top: 10px;
  color: #747474;
}

.table-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;

  div {
    cursor: pointer;
  }
}
</style>
